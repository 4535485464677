import { Component } from '@angular/core';
import {AdminService} from "../services/admin.service";
import {IAdminFirm} from "../../../shared/interfaces/adminFirm";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-companymanagement',
  templateUrl: './companymanagement.component.html',
  styleUrls: ['./companymanagement.component.scss']
})

export class CompanymanagementComponent {

  private searchTerm: string;
  private search: boolean;

  constructor(private adminService: AdminService,
              private router: Router) {
    this.searchTerm = '';
    this.search = false;
    // this.route.params.subscribe(
    //   (params) => {
    //       if ( params['id'] && params['id'] !== this.adminService.selectedFirm.firmId) {
    //           this.adminService.selectedFirm = this.adminService.getFirmDataById(params['id']);
    //       }
    //   }
    // );
  }

  // private searchFirm() {
  //     this.search = false;
  //     if (this.searchTerm.length > 0) {
  //         this.adminService.getFirmData(this.searchTerm).subscribe(
  //             (response) => {
  //                 this.firmData = [];
  //                 this.firmData = response;
  //                 this.search = true;
  //             }
  //         );
  //     }
  // }

  private navigate(firm: IAdminFirm) {
      this.router.navigate(['admin', firm.firmId]);
  }
}
