import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {IDropdownItem} from './interfaces/dropdown-item';


@Component({
    selector: 'app-dropdown',
    templateUrl: 'dropdown.component.html',
    styleUrls: ['dropdown.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '(document:click)': 'onClick($event)',
    }
})
export class DropdownComponent implements OnInit, OnChanges {
    @Input() public hasSearch?: boolean;
    @Input() public placeholderText: string;
    @Input() public labelText: string;
    @Input() public items: any[];
    @Input() public alignment: string;
    @Input() public width: string;
    @Input() public translationPrefix: string;
    @Input() public preSelectedItem: any;
    @Input() public resetSelection: boolean;
    @Input() public dropdownDisabled: boolean;
    @Input() public maxChars: number;
    @Input() public fieldRequired: boolean;
    @Input() public isFilterDropdown: boolean = false;
    @Input() public isAmountDropdown: boolean = false;
    @Input() public externallySelected: any;
    @Input() public cd: ChangeDetectorRef;
    @Input() public selectedItem: (IDropdownItem | string | number);
    @Output() private onItemSelect: EventEmitter<(string | number)> = new EventEmitter();

    public searchValue: string = '';
    public showMenu: boolean = false;
    public filteredItems: any[];

    constructor(private _eref: ElementRef) {
    }

    ngOnInit() {
        if (this.preSelectedItem) {
            this.selectedItem = this.preSelectedItem;
        }
        if (this.items && this.items.length) {
            this.filteredItems = [...this.items];
        }
    }

    ngOnChanges(changes: any) {

        if (changes.placeholderText && !changes.placeholderText.firstChange) {
            this.placeholderText = changes.placeholderText.currentValue;
        }

        if (changes.preSelectedItem && !changes.preSelectedItem.firstChange) {
            // needed to reset selected item
            if (!changes.preSelectedItem.currentValue) {
                this.selectedItem = undefined;
            }
        }
    }

    public onClick(event: any) {
        if (!this._eref.nativeElement.contains(event.target)) {
            this.showMenu = false;
        }
    }

    public changeSelectedItem(item: any) {
        this.selectedItem = item;
        this.onItemSelect.emit(item);
        this.showMenu = false;
    }

    public isMultidimensionalArray(): boolean {
        return (this.items && this.items.length > 0 && this.items[0].name !== undefined);
    }

    public toggleDropdown() {
        if (!this.dropdownDisabled) {
            this.showMenu = !this.showMenu;
        }
    }

    public onSearchChange(value: string): void {
        this.filteredItems = this.items.filter(item =>
            item.displayValue.toLowerCase().includes(value.toLowerCase())
        );
    }

}
