import { Component, OnInit } from '@angular/core';
import {HelperService} from '../../services/helper.service';
import {UserService} from '../../services/user/user.service';
import {Router} from '@angular/router';
import {AuthService} from '../../../auth/services/auth.service';
import {CartService} from '../../../cart/services/cart.service';

@Component({
  selector: 'app-page-maintenance',
  templateUrl: './page-maintenance.component.html',
  styleUrls: ['./page-maintenance.component.scss']
})
export class PageMaintenanceComponent implements OnInit {

  constructor(public helperService: HelperService,
              public userService: UserService,
              public cartService: CartService,
              private router: Router) { }

  ngOnInit() {
    this.checkLogin();
  }

  private checkLogin() {
    setTimeout(() => {
      this.router.navigate(['/']);
      setTimeout(() => {
        location.reload();
      }, 1000);
    }, 30000);
  }
}
