import {Component, Input, OnInit} from '@angular/core';
import {Location} from "@angular/common";
import {toggle} from '../../../animations/general.animation';

@Component({
  selector: 'app-article-toggle',
  templateUrl: './article-toggle.component.html',
    animations: [toggle]
})
export class ArticleToggleComponent implements OnInit {
    @Input() public service: any;
    @Input() public text: boolean = false;
  constructor(private location: Location) { }

  ngOnInit() {
  }

}
