import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {TemplateService} from '../services/template.service';
import {Router} from '@angular/router';
import {ITemplatePosition} from '../interfaces/template-position';
import {UserService} from '../../../../../shared/services/user/user.service';
import {FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {HelperService} from '../../../../../shared/services/helper.service';
import {AccountRestService} from '../../../../profile/services/account.rest.service';
import {IFirm} from '../../../../../shared/interfaces/DTO/firm/firm';

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.scss']
})
export class CreateTemplateComponent implements OnInit {

  public newTemplate: ITemplatePosition;
  public firmData: IFirm;

  templateForm = new UntypedFormGroup({
      // ipc: new FormControl('', Validators.required),
      // description: new FormControl('', Validators.required),
      // quantity: new FormControl('', Validators.required),
      // price: new FormControl('', Validators.required)

  });

  constructor(public location: Location,
              public templateService: TemplateService,
              private router: Router,
              private userService: UserService,
              public helperService: HelperService,
              private accountService: AccountRestService) {}

  ngOnInit() {

      this.accountService.getFirmData().subscribe(
          (response) => {
              this.firmData = response;
          }
      );

      if (this.templateService.templateType) {
          this.newTemplate = {clubId: 0, quantityUnit: 'PIECE', sellOutNetPerPiece: undefined, type: '', quantity: 1, description: '', firmId: undefined, id: undefined, ipc: ''};

          if (this.templateService.templateType === 'work') {
              this.newTemplate.type = 'AW';
              this.newTemplate.quantityUnit = 'HOUR';
          } else {
              this.newTemplate.type = this.templateService.templateType.toUpperCase();
          }

      } else {
          this.router.navigate(['/admin/template']);
          return;
      }

      if (this.userService.allow('GLOBAL-ADMIN')) {
        this.newTemplate.clubId = Number.parseInt(this.location.path().substring(this.location.path().lastIndexOf('/') +1));
      } else {
        this.newTemplate.clubId = this.userService.accountData.clubId;
      }

      if (this.location.path().indexOf('edit') > -1) {
          let templateId = this.location.path().substring(this.location.path().lastIndexOf('/') +1);
          this.newTemplate = this.templateService.templateList.find(template => template.id === templateId);
      }
  }

  public checkValidQuantity(event: any) {
      this.newTemplate.quantity = event;
      if (this.newTemplate.quantity.toString().length > 0) {
          this.newTemplate.quantity = Number.parseFloat(this.newTemplate.quantity.toString().replace(',', '.'));
      }
  }

    public checkValidPrice(event: any) {
        this.newTemplate.sellOutNetPerPiece = event;
        if (this.newTemplate.sellOutNetPerPiece.toString().length > 0) {
            this.newTemplate.sellOutNetPerPiece = Number.parseFloat(this.newTemplate.sellOutNetPerPiece.toString().replace(',', '.'));
        }
    }

  public validate() {
    return this.newTemplate && this.newTemplate.quantity > 0;
  }
}
