<div class="maintenance-data">
    <div class="heading blue" *ngIf="location.path().endsWith('/maintenance/info/manufacturer')">
        <span routerLink="/maintenance" class="clickable" (click)="maintenanceService.lastState = ''"><i class="fa fa-th" aria-hidden="true"></i></span>
        <span routerLink="/maintenance" class="clickable">{{'CLUB_SETTINGS.NAVIGATION.MAINTENANCE' | translate}}</span>
        <span routerLink="/maintenance/info" class="clickable"><i class="fa fa-caret-right"></i>{{'MAINTENANCE.INFO' | translate}}</span>
        <span><i class="fa fa-caret-right"></i>{{'MAINTENANCE.MANUFACTURER_INFO' | translate}}</span>
        <span>
            <button class="print-btn pull-right" (click)="printIframe('tecrmi-manual')">
                <i class="fa fa-print"></i>
                <span>{{'PRINT_VIEW.PRINT' | translate}}</span>
            </button>
        </span>
    </div>

</div>
<div class="panel-content center-content"
     [ngClass]="{'print-content': maintenanceService?.selectedOption === 'MANUFACTURER_SPEC', 'setting' : !maintenanceService.showPdf}">

    <ng-container *ngIf="maintenanceService?.manualUrl">
        <iframe width="100%" height="100%" [src]="maintenanceService.manualUrl | iFrame"
                id="tecrmi-manual"
                onLoad="this.style.height=(this.contentDocument.body.scrollHeight + 30) + 'px';"></iframe>
    </ng-container>
    <div *ngIf="!maintenanceService?.manualUrl">
        <p>
            <ngb-alert [dismissible]="false" [type]="'info'">
                <i class="fa fa-times"></i>
                <span> {{'MAINTENANCE.NO_INFOS' | translate}}</span>
            </ngb-alert>
        </p>
    </div>
</div>
