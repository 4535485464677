import {Component, OnInit} from '@angular/core';
import {DatamapperService} from '../services/datamapper.service';
import {AssortmentService} from '../services/assortment.service';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app-new-assortment',
    templateUrl: './new-assortment.component.html'
})
export class NewAssortmentComponent implements OnInit {
    public state: string;
    public clubId: number;
    public firmId: number;
    public isGlobalAdmin = false;
    public paraState: number;
    public newAssortment;
    public prio = 303;
    public genArtId: { id: null };
    public dataSupplierNo: { id: null };

    constructor(public dataMapperService: DatamapperService,
                private router: Router,
                private assortmentService: AssortmentService) {

        this.newAssortment = {
            prio: 303,
            dataSupplierNo: null,
            genArtId: null,
            active: true
        }

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.considerUrl(event.url);
            }
        })
    }

    ngOnInit() {
        this.considerUrl(this.router.url);
    }

    public considerUrl(url: string) {
        if (url.includes('/assortment/supplier')) {
            this.state = 'nogenart';
            this.paraState = null;
        }
        if (url.includes('/assortment/supplier/')) {
            this.state = 'specific_brand';
            this.paraState = Number(url.slice(url.lastIndexOf('/') + 1));
        }
        if (url.includes('/assortment/article')) {
            this.state = 'articles';
            this.paraState = null;
        }
        if (url.includes('/assortment/article/')) {
            this.state = 'specific_article';
            this.paraState = Number(url.slice(url.lastIndexOf('/') + 1));
        }
    }


    public updatePrio(primaryPrio: boolean, value: number) {
        if (primaryPrio) {
            this.prio = (this.modulus(this.prio) + value);
        } else {
            this.prio = (this.prio - this.modulus(this.prio) + value);
        }
    }

    public modulus(value: number) {
        return this.assortmentService.modulus(value);
    }

    public createAssortment() {
        this.newAssortment.dataSupplierNo = (this.dataSupplierNo ? this.dataSupplierNo.id : this.paraState);
        this.newAssortment.genArtId = (this.genArtId ? this.genArtId.id : this.paraState);
        this.newAssortment.prio = this.prio;

        this.assortmentService.createAssortment(this.newAssortment);
        this.newAssortment = {
            prio: 303,
            dataSupplierNo: null,
            genArtId: null,
            active: true
        }
        this.dataSupplierNo = undefined;
        this.genArtId = undefined;
    }
}
