<td class="brand_ipc" width="25%">
    <div class="brand" *ngIf="position.brandName">{{ position.brandName }}</div>
    <div class="ipc">{{ position.ipc }}</div>
    <div class="description">{{position.description}}</div>
</td>
<td class="supplier text-center" width="20%">
    <div *ngIf="position.supplierName">{{ position.supplierName }}</div>
    <div *ngIf="position.dealerArticleNumber">{{position.dealerArticleNumber}}</div>
</td>
<td></td>
<td width="10%" class="ordered text-center">
	<span *ngIf="position.submittedWithOrder">
		<i class="fa fa-check" style="color: green;"></i>
	</span>

    <span *ngIf="!position.submittedWithOrder">
		<i class="fa fa-times"></i>
	</span>
</td>
<td width="15%" class="order-amount text-center">
    <div *ngIf="position.quantityUnit === 'PIECE'">
        {{ position.quantity }}
    </div>

    <div *ngIf="position.quantityUnit !== 'PIECE'">
        {{ position.quantity }} {{ 'CART.' + position.quantityUnit | translate }}
    </div>
</td>
<td width="15%" class="price-per-piece">
    <div class="sellout-price float-right" *ngIf="userService.sale">
        {{ position.sellOutNetPerPieceFormatted}}
    </div>
    <div class="sellout-price float-right" *ngIf="!userService.sale">
        {{ position.sellInNetPerPieceFormatted}}
    </div>
</td>

<td width="15%" class="total-price text-right" style="padding-right: 15px !important;" *ngIf="userService.sale">
    {{ position.sellOutNetTotalFormatted}}
</td>
<td width="15%" class="total-price text-right" style="padding-right: 15px !important;" *ngIf="!userService.sale">
    {{ position.sellInNetTotalFormatted}}
</td>
