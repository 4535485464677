<div class="external-tools-details">
  <div class="club-setting-title">
    <h1>
      <strong>
        <span>{{'EXTERNAL_TOOLS.' + globalAdminService?.selectedExternalTool?.name | translate}}</span>
      </strong>
    </h1>
  </div>

  <div class="row external-tool-detail-content">
    <div class="col">
      <app-checkbox [labelText]="'EXTERNAL_TOOLS.SHOW_IN_DETAILS' | translate"
                    [isChecked]="globalAdminService?.selectedExternalTool?.showInDetails"
                    (onChange)="changeShowInDetails($event)">
      </app-checkbox>
    </div>
  </div>
</div>
