import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CarService} from '../../../services/car/car.service';
import {ICar} from '../../../interfaces/DTO/car/car';
import {CarRestService} from '../../../services/car/car.rest.service';
import {HelperService} from '../../../services/helper.service';
import {CustomerDataService} from '../../../services/customerData.service';
import {UserService} from '../../../services/user/user.service';

@Component({
    selector: 'app-car-by-license-swiss',
    templateUrl: './car-by-license-swiss.component.html'
})
export class CarByLicenseSwissComponent implements OnInit, OnChanges {

    @Output() applyCars: EventEmitter<ICar[]> = new EventEmitter();
    @Input() numberplate: string;
    public showValidation = false;

    constructor(public carService: CarService,
                private carRestService: CarRestService,
                private userService: UserService,
                private customerDataService: CustomerDataService,
                public helperService: HelperService) {
    }

    ngOnInit() {
        if (this.carService.getSwissTypeNo().length > 0) {
            this.numberplate = this.carService.getSwissTypeNo();
        } else {
            this.numberplate = this.customerDataService.getTypenumber();
        }
    }

    ngOnChanges() {
        this.carService.selected = 'SWISS';
    }

    public onLicensePlateSearch() {
        if (this.numberplate.length === 6) {
            this.carService.loadCar = 'SWISS';
            this.carRestService.getCarsByTypenumber(this.numberplate).subscribe(
                (response: ICar[]) => {
                    if (response) {
                        this.carService.loadCar = undefined;
                        if (!this.userService.getCatalogMode()) {
                            this.carService.fromDMS = false;
                        }
                        this.applyCars.emit(response);
                        this.carService.typeNumber = this.numberplate;
                    } else {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_CANT_FIND_CAR_SWISS_TYPENO', 'error');
                    }
                },
                (error) => {
                    if (error.status === 409) {
                        this.carService.handleCarConflict();
                    } else {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                    }
                    this.carService.loadCar = undefined;
                }
            );
        } else {
            this.showValidation = true;
        }
    }
}
