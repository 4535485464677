import {Injectable} from '@angular/core';
import {UserService} from '../../../../../shared/services/user/user.service';
import {AdminService} from '../../../services/admin.service';
import {ITemplatePosition} from '../interfaces/template-position';
import {IClubCustomPosition} from '../../../../../shared/interfaces/DTO/club/clubCustomPosition';
import {HelperService} from '../../../../../shared/services/helper.service';

@Injectable({
    providedIn: 'root'
})
export class TemplateService {

    public templateList: IClubCustomPosition[];
    public templateType: string;

    constructor(private userService: UserService,
                private adminService: AdminService,
                private helperService: HelperService) {
    }

    public getTemplateList() {
        if (this.userService.allow('GLOBAL-ADMIN') && this.adminService.club) {
            this.adminService.getTemplatesGlobalRest(this.adminService.club.clubId).subscribe(
                (templateList: ITemplatePosition[]) => {
                    this.templateList = templateList;
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_TEMPLATE', 'error');
                });

        } else if (this.userService.allow('CLUB-ADMIN')) {
            this.adminService.getTemplatesClubRest().subscribe(
                (templateList: IClubCustomPosition[]) => {
                    this.templateList = templateList;
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_TEMPLATE', 'error');
                });
        }
    }

    public getTemplateListWithType(templateType: string) {
        if (templateType === 'all') {
            return this.templateList;
        } else {
            if (templateType === 'work') {
                templateType = 'AW';
            }

            let tempList: ITemplatePosition[] = [];

            for (let template of this.templateList) {
                if (template.type === templateType.toUpperCase()) {
                    tempList.push(template);
                }
            }

            return tempList;
        }
    }

    public createNewTemplate(newTemplate: ITemplatePosition) {
        if (newTemplate.quantity > 0) {
            newTemplate.clubId = this.adminService.getClubId();
            if (this.userService.allow('GLOBAL-ADMIN')) {
                this.adminService.createTemplateGlobalRest(newTemplate).subscribe(
                    (success) => {
                        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_SAVE_TEMPLATE', 'success');
                    },
                    (error) => {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_SAVE_TEMPLATE', 'error');
                    });
            } else if (this.userService.allow('CLUB-ADMIN')) {
                this.adminService.createTemplateClubRest(newTemplate).subscribe(
                    (success) => {
                        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_SAVE_TEMPLATE', 'success');
                    },
                    (error) => {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_SAVE_TEMPLATE', 'error');
                    });
            }
        }
    }

    public deleteTemplate(templateId: string) {
        if (this.userService.allow('GLOBAL-ADMIN')) {
            this.adminService.deleteTemplateGlobalRest(templateId).subscribe(
                (success) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_TEMPLATE', 'success');
                    this.getTemplateList();
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_TEMPLATE', 'error');
                });
        } else if (this.userService.allow('CLUB-ADMIN')) {
            this.adminService.deleteTemplateClubRest(templateId).subscribe(
                (success) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_TEMPLATE', 'success');
                    this.getTemplateList();
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_TEMPLATE', 'error');
                });
        }
    }
}
