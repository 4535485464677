<div class="global-orderhistory vinlog">
    <div class="heading blue">
        <span class="clickable" style="cursor: pointer;" routerLink="/global-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <span class="clickable">
            <i class="fa fa-caret-right"></i>
            {{'GLOBAL_ADMIN.SUPPLIER_COMMUNICATION.TITLE' | translate}}
        </span>
    </div>
    <div class="historySearchBar">
        <div class="row">
            <div class="col">
                <h1>{{'GLOBAL_ADMIN.SUPPLIER_COMMUNICATION.TITLE' | translate}}</h1>
                <div class="row">
                    <div class="col-4">
                        <app-dropdown placeholderText="{{'PRINT_VIEW.ARTICLE.SUPPLIER' | translate}}"
                                      [items]="supplierList"
                                      *ngIf="supplierList"
                                      [preSelectedItem]="supplierList[0]"
                                      (onItemSelect)="globalAdminService.communicationRequest.supplier = $event.code; validateForm()"></app-dropdown>
                    </div>
                </div>
                <div class="row" *ngIf="globalAdminService.communicationRequest.supplier">
                    <div class="col-4">
                        <app-material-form-input [label]="'ADMIN_PANEL.FIRMNAME' | translate"
                                                 [text]="globalAdminService.communicationRequest.firmName"
                                                 (textChange)="globalAdminService.communicationRequest.firmName = $event; validateForm()"
                                                 (keydown.enter)="globalAdminService.getCommunicationsList()"></app-material-form-input>
                    </div>
                    <div class="col-4">
                        <app-material-form-input [label]="'NOTIFICATION.START_DATE' | translate"
                                                 [text]="globalAdminService.communicationRequest.startDate"
                                                 (textChange)="globalAdminService.communicationRequest.startDate = $event; validateForm()"
                                                 (keydown.enter)="globalAdminService.getCommunicationsList()"></app-material-form-input>
                    </div>
                    <div class="col-4">
                        <app-material-form-input [label]="'NOTIFICATION.END_DATE' | translate"
                                                 [text]="globalAdminService.communicationRequest.endDate"
                                                 (textChange)="globalAdminService.communicationRequest.endDate = $event; validateForm()"
                                                 (keydown.enter)="globalAdminService.getCommunicationsList()"></app-material-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="base-btn success float-right"
                                (click)="globalAdminService.communicationRequest.page = 0; globalAdminService.getCommunicationsList(); globalAdminService.communicationLog = []"
                                [ngClass]="{'disabled': !validForm}">
                            <i class="fa fa-search" *ngIf="!globalAdminService.loadingCommunicationsList"></i>
                            <i class="fa fa-spinner" *ngIf="globalAdminService.loadingCommunicationsList"
                               [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"></i>
                            <span>{{'HOME.SEARCH' | translate}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="panel-content center-content white-background" style="margin-top: 200px"
         [ngClass]="{'hasResuls': globalAdminService?.resultItems?.logs?.length > 0, 'showPagination': !userService?.enableInfiniteScroll }"
         appInfiniteScroller
         (onScrollEnd)="globalAdminService.loadNext($event, false)">
        <div class="row">
            <div class="col">
                <app-default-loading-page class="margin"
                                          *ngIf="globalAdminService.loadingCommunicationsList"></app-default-loading-page>
                <ul class="item-list">
                    <app-global-communications-list-row></app-global-communications-list-row>
                </ul>
                <app-no-results *ngIf="!globalAdminService?.resultItems"></app-no-results>
            </div>
        </div>
    </div>
    <div class="global-pagination" *ngIf="!userService.enableInfiniteScroll">
        <app-pagination [page]="globalAdminService?.communicationRequest?.page +1"
                        [perPage]="globalAdminService?.communicationResult?.pageSize"
                        [count]="globalAdminService?.communicationResult?.totalElements"
                        [pagesToShow]="5"
                        (goPage)="goToPage($event)">
        </app-pagination>
    </div>
</div>
