import {Component, Input, OnInit} from '@angular/core';
import {ISetting} from '../../../../../shared/interfaces/DTO/club/setting';
import {ConfigService} from '../../../../../shared/services/config.service';

@Component({
  selector: 'app-setting-external-tool',
  templateUrl: './setting-external-tool.component.html',
  styleUrls: ['./setting-external-tool.component.scss']
})
export class SettingExternalToolComponent implements OnInit {

  @Input() public settingName: string;
  @Input() public settingList: ISetting[] = [];

  constructor(public configService: ConfigService) { }

  ngOnInit() {
  }

  public changeActive(setting: ISetting, active: boolean) {
    setting.active = active;
    this.configService.addUnsavedSetting(this.settingName);
  }

  public changeOptional(setting: ISetting, optional: boolean) {
    setting.optional = optional;
    this.configService.addUnsavedSetting(this.settingName);
  }

  public changeShowInDetails(setting: ISetting, showInDetails: boolean) {
    setting.showInDetails = showInDetails;
    this.configService.addUnsavedSetting(this.settingName);
  }

  public cdkDropListDropped($event: any) {
    this.configService.sortList(this.configService.getSettingByName(this.settingName), $event);
    this.configService.resetDynamicSettingWrapper.emit();
  }

}
