import {Component, OnInit, Input} from '@angular/core';
import {IAssemblyCategory} from "./interfaces/assembly-categorie";
import {AssemblyCategoriesService} from "../services/assembly-categories.service";
import {ArticlesService} from "../services/articles.service";
import {CarService} from "../../shared/services/car/car.service";
import {animate, query, stagger, style, transition, trigger} from "@angular/animations";
import {catAnimation} from '../../shared/animations/general.animation';
import {CustomerDataService} from '../../shared/services/customerData.service';
import {AutovistaService} from '../../graphical-part-search/services/autovista.service';
import {UserService} from '../../shared/services/user/user.service';

@Component({
    selector: 'app-assembly-categories',
    templateUrl: './assembly-categories.component.html',
    styleUrls: ['./assembly-categories.component.scss'],
    animations: [catAnimation]
})
export class AssemblyCategoriesComponent implements OnInit {
    public bodygraphics: boolean;

    constructor(
        public articlesService: ArticlesService,
        public carService: CarService,
        public assemblyCategoriesService: AssemblyCategoriesService,
        public autovistaService: AutovistaService,
        public userService: UserService,
        public customerDataService: CustomerDataService,
    ) {
    }

    ngOnInit() {
    }

    public treeFilterByChild(node: any, treeFilter: any) {
        if (node && node.children) {
            if (node.isFiltered) {
                return true;
            }
            const childs = [];
            for (const child of node.children) {
                if (treeFilter && treeFilter.articleCategoryIds &&
                    treeFilter.articleCategoryIds.indexOf(child.id) > -1) {
                    node.open = true;
                    node.isFiltered = true;
                    childs.push(child);
                }
            }

            if (childs.length > 0) {
                node.children = childs;
                return true;
            }
        }
        return false;
    }

    public selectCategory(category: IAssemblyCategory) {
        if (category.active) {
            this.assemblyCategoriesService.selectedAssemblyCategory = category;
            this.articlesService.setTreeCategory(category);
        }
    }

    public isCategorySelected(category: IAssemblyCategory) {
        return category === this.assemblyCategoriesService.selectedAssemblyCategory;
    }

}
