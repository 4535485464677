import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ICar} from '../../../interfaces/DTO/car/car';
import {CarService} from '../../../services/car/car.service';
import {HelperService} from '../../../services/helper.service';
import {AutovistaRestService} from '../../../../graphical-part-search/services/autovista.rest.service';
import {CustomerDataService} from '../../../services/customerData.service';
import {UserService} from '../../../services/user/user.service';

@Component({
  selector: 'app-car-by-natcode',
  templateUrl: './car-by-natcode.component.html',
  styleUrls: ['./car-by-natcode.component.scss']
})
export class CarByNatcodeComponent implements OnInit, OnChanges {

  @Output() applyCars: EventEmitter<ICar[]> = new EventEmitter();
  @Input() natcode: string;
  public text: any;

  constructor(public carService: CarService,
              public helperService: HelperService,
              public autovistaRestService: AutovistaRestService,
              private customerDataService: CustomerDataService,
              public userService: UserService) { }

  ngOnInit() {
    if (this.carService.getNatcode().length > 0) {
      this.natcode = this.carService.getNatcode();
    } else {
      this.natcode = this.customerDataService.getNatcode();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.natcode.currentValue === undefined) {
      this.natcode = changes.natcode.previousValue;
    }
    this.carService.selected = 'KBA_NUMBER';
  }

  public onNatcodeSearch() {
    this.carService.loadCar = 'NATCODE';
    this.autovistaRestService.getCarsByNatcode(this.natcode).subscribe(
        (cars) => {
          if (cars && cars.length > 0) {
            if (!this.userService.getCatalogMode()) {
              this.carService.fromDMS = false;
            }
            this.applyCars.emit(cars);
          } else {
            this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_CAR_FOUND', 'error');
          }
          this.carService.loadCar = undefined;
        },
        (error) => {
          if (error.status === 409) {
            this.carService.handleCarConflict();
          } else {
            this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
          }
          this.carService.loadCar = undefined;
        });
  }

}
