<div class="cropper">
    <span>{{'USER_PROFILE.CROPPER.CROP_HERE' | translate}}:</span>
    <br>
    <div class="col-10 image-container">
        <div>
            <img #image [src]="imageSource" crossorigin>
        </div>
    </div>
    <span>{{'USER_PROFILE.CROPPER.RESULT' | translate}}:</span>
    <br>
    <div class="col-10 image-container">
        <img [src]="imageDestination">
    </div>
</div>

