import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {ICredentials} from '../services/interfaces/credentials';
import {ThemeService} from '../../shared/services/theme.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {HelperService} from '../../shared/services/helper.service';


@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})

export class LoginComponent implements OnInit {

    private loginError: boolean;
    loginForm = new UntypedFormGroup({
        username: new UntypedFormControl('', Validators.required),
        password: new UntypedFormControl('', Validators.required)
    });

    public loginData: ICredentials = {
        username: '',
        password: '',
        stayLoggedIn: false
    };
    private showDeprecatedBrowserWarning: boolean = false;

    constructor(
        public authService: AuthService,
        public themeService: ThemeService,
        public helperService: HelperService
    ) {
        this.loginError = false;
    }

    ngOnInit() {

    }

    public isDisabled() {
        // return !this.loginForm.valid;
    }

    public tryLogin() {
        this.authService.login(this.loginData, this.loginData.stayLoggedIn, true);
    }

}
