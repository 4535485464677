<div class="partnermanagement">
  <div class="heading blue">
        <span class="clickable">
            <i class="fa fa-th" aria-hidden="true"></i>
        </span>
    <span *ngIf="!partnerService.getPartnerDisplayName()">{{'CLUB_ADMIN.PARTNER' | translate}}</span>
    <span *ngIf="partnerService.getPartnerDisplayName()">{{partnerService.getPartnerDisplayName()}}</span>
    <div class="float-right">
    <span class="clickable" (click)="createPartner()">
        <i class="fa fa-plus"></i>
        {{'CLUB_ADMIN.PARTNER_CREATE' | translate}}
    </span>
    </div>
  </div>
  <div class="panel-content center-content white-background" >
      <div *ngIf="partnerService?.partnerPages">
          <div class="row">
              <div class="col-2 title">
                  <span><b>{{'PARTNER.PAGE_TITLE' | translate}}</b></span>
              </div>
              <div class="col title-input">
                  <span *ngIf="!editTitle">{{partnerService?.partnerPages?.displayName}} <i class="ml-1 fa fa-pencil clickable" (click)="editTitle = true"></i></span>
                  <app-material-form-input *ngIf="editTitle" [(text)]="partnerService.partnerPages.displayName"></app-material-form-input>
                  <i class="fa fa-check clickable" *ngIf="editTitle" (click)="editTitle = false; this.newSort = true;"></i>
              </div>
          </div>
      </div>
      <div *ngIf="partnerService?.partnerPages?.partnerPages">
          <ng-container *ngFor="let pageType of getPageTypes()">
                  <app-collapsible-panel [folded]="false" [panelTitle]="'PARTNER.' + pageType | translate"
                                         *ngIf="partnerService?.partnerPages?.partnerPages[pageType]?.length > 0">

                      <div cdkDropList class="drag-list" (cdkDropListDropped)="sort($event, pageType)">
                          <div class="drag-box" *ngFor="let page of partnerService?.partnerPages?.partnerPages[pageType]" cdkDrag>
                              <div class="row">
                                  <div class="col-2">
                                      <img class="partner-logo" *ngIf="page.logoUrl.indexOf('http://') > -1 || page.logoUrl.indexOf('https://') > -1" src="{{page.logoUrl}}">
                                      <img class="partner-logo" *ngIf="page.logoUrl.indexOf('http://') < 0 && page.logoUrl.indexOf('https://') < 0" src="/assets/images/no-image.png">
                                  </div>
                                  <div class="col-8">
                                      {{page.text}}
                                  </div>
                                  <div class="col-1">
                                      <button class="icon-btn" (click)="partnerService.deletePartner(page, pageType)"><i class="fa fa-trash"></i></button>
                                  </div>
                                  <div class="col-1">
                                      <button class="icon-btn" (click)="editPartner(page, pageType)"><i class="fa fa-pencil"></i></button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </app-collapsible-panel>
          </ng-container>
      </div>

      <button class="base-btn" (click)="saveChanges()" *ngIf="newSort">{{'CLUB_ADMIN.PARTNER_MANAGEMENT.SAVE' | translate}}</button>

    <div class="alert" *ngIf="!hasPages()">
      <ngb-alert [type]="'danger'" [dismissible]="false">
        <i class="fa fa-close"></i>
        <span>{{'CLUB_ADMIN.PARTNER_MANAGEMENT.NO_PARTNER' | translate}}</span>
      </ngb-alert>
    </div>
  </div>
</div>
