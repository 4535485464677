<div class="errorHandler">
    <div class="errorPanel">

        <div class="row">
            <div class="col-2">
                <i class="fa fa-exclamation-triangle"></i>
            </div>
            <div class="col-6" *ngIf="(adminService.validationErrorFirm$ | async)?.length>=1">
                <div *ngIf="(adminService.validationErrorFirm$ | async) === 'NAME_AND_EMAIL'" class="errorText">{{'ERROR.CONFLICT_NAME_AND_EMAIL' | translate}}</div>
                <div *ngIf="(adminService.validationErrorFirm$ | async) === 'NAME'" class="errorText">{{'ERROR.CONFLICT_NAME' | translate}}</div>
                <div *ngIf="(adminService.validationErrorFirm$ | async) === 'EMAIL'" class="errorText">{{'ERROR.CONFLICT_EMAIL' | translate}}</div>
                <div *ngIf="(adminService.validationErrorFirm$ | async) === 'EXTERNAL_ID'" class="errorText">{{'ERROR.CONFLICT_EXTERNAL_ID' | translate}}</div>
            </div>
            <div class="col-6" *ngIf="(adminService.validationErrorFirm$ | async)?.length===0">
                <div class="errorCode">{{'ERROR: ' + this.errorHandlerService.errorCode}}</div>
                <div class="errorText">{{this.errorHandlerService.errorText}}</div>
            </div>
            <div class="col-4">
                <button class="errorButton" (click)="accept()">
                    <i class="fa fa-check"></i>
                    {{'PROMPTS.BUTTONS.OK' | translate}}
                </button>
                <button class="errorButton" (click)="decline()" *ngIf="hasCancel">
                    <i class="fa fa-times"></i>
                    {{'PROMPTS.BUTTONS.CANCEL' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
