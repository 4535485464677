import {Injectable} from '@angular/core';
import {OrderRestService} from '../../../../shared/services/order/order.rest.service';
import {UserService} from '../../../../shared/services/user/user.service';
import {HelperService} from '../../../../shared/services/helper.service';

@Injectable()
export class OrderHistoryService {

    public selectedHistory: string;
    public selectedCart: any;
    public cartData: any;
    public orderHistoryRequest: any;
    public loadingPlacedOrders: boolean;
    public infinityLoading: boolean;

    constructor(private orderRestService: OrderRestService,
                public userService: UserService,
                private helperService: HelperService) {

    }

    public setOrderHistoryRequest() {
        this.orderHistoryRequest = {
            startDate: '',
            endDate: '',
            page: 0,
            pageSize: 20,
            orders: [],
            totalElements: undefined,
        }
    }

    public setHistory(history?: string, cartId?: string, $event?: any) {
        this.setOrderHistoryRequest();
        this.loadingPlacedOrders = true;
        if (history) {
            this.selectedHistory = history;
            this.selectedCart = undefined;

            const now = new Date();
            let month = (now.getMonth() + 1).toString();
            if (month.length === 1) {
                month = '0' + month;
            }
            let day = now.getDate().toString();
            if (day.length === 1) {
                day = '0' + day;
            }
            this.orderHistoryRequest.endDate = now.getFullYear() + '-' + (month) + '-' + day + 'T23:59';
            let oldDay = undefined,
                oldMonth = undefined,
                startDateTemp = undefined,
                startDate = undefined
            switch (history) {
                case ('orderhistory'):
                    break;
                case ('last7'):
                    startDateTemp = new Date(Date.now() - 8.64e7 * 7);
                    break;
                case ('last14'):
                    startDateTemp = new Date(Date.now() - 8.64e7 * 14);
                    break;
                case ('last30'):
                    startDateTemp = new Date(Date.now() - 8.64e7 * 30);
                    break;
            }
            if (startDateTemp) {
                oldMonth = (startDateTemp.getMonth() + 1).toString();
                if (oldMonth.length === 1) {
                    oldMonth = '0' + oldMonth;
                }
                oldDay = startDateTemp.getDate().toString();
                if (oldDay.length === 1) {
                    oldDay = '0' + oldDay;
                }
                startDate = startDateTemp.getFullYear() + '-' + (oldMonth) + '-' + (oldDay) + 'T00:00';
                this.orderHistoryRequest.startDate = startDate;
            }
        }
        if ($event) {
            this.orderHistoryRequest.page = ($event - 1);
            this.getHistoryData(this.orderHistoryRequest, cartId);
        } else {
            if (history !== 'orderhistory') {
                this.getHistoryData(this.orderHistoryRequest, cartId);
            } else {
                this.loadingPlacedOrders = false;
            }
        }
    }

    private getHistoryData(body: any, cartId?: string) {
        this.orderRestService.getOrderList(body).subscribe(
            (response) => {
                if (this.userService.enableInfiniteScroll && this.orderHistoryRequest.orders) {
                    for (const item of response.orders) {
                        this.orderHistoryRequest.orders.push(item);
                    }
                    this.orderHistoryRequest.page = response.page;
                    this.orderHistoryRequest.totalElements = response.totalElements;
                    this.infinityLoading = false;
                } else {
                    this.orderHistoryRequest = response;
                }
                if (cartId) {
                    this.selectCartWithId(cartId);
                }
                this.loadingPlacedOrders = false;
            },
            (error) => {
                this.loadingPlacedOrders = false;
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_HISTORY', 'error');
            });
    }

    public loadNext(event: any) {
        if (event !== 0 && !this.infinityLoading) {
            if (this.userService.enableInfiniteScroll && this.orderHistoryRequest &&
                (this.orderHistoryRequest.totalElements / this.orderHistoryRequest.pageSize) > (this.orderHistoryRequest.page + 1)) {

                this.loadingPlacedOrders = true;
                this.infinityLoading = true;
                this.orderHistoryRequest.page += 1;
                this.getHistoryData(this.orderHistoryRequest);
            }
        }
    }


    public setCart(cart: any) {
        this.selectedCart = cart;
        this.getCartData();
    }

    public getCartData() {
        if (this.selectedCart) {
            this.orderRestService.getOrderDetails(this.selectedCart.id).subscribe((response) => {
                this.cartData = response;
            });
        }
    }

    public selectCartWithId(id: string) {
        if (!this.selectedCart || this.selectedCart.id !== id) {
            if (this.orderHistoryRequest) {
                for (const cart of this.orderHistoryRequest) {
                    if (cart.id === id) {
                        this.setCart(cart);
                    }
                }
            }
        }
    }

    public reset() {
        this.selectedHistory = 'orderhistory';
        this.selectedCart = undefined;
        this.orderHistoryRequest = undefined;
        this.cartData = undefined;
    }
}
