<div class="profile">
<div class="container-fluid content clean">
    <div class="left-panel no-float left">
        <div class="panel-content left-sidebar">
            <ul class="custom-tree">
                <li class="list">
                    <div class="clickable" >
                        <i class="folder fa fa-circle" routerLink="/profile" routerLinkActive="open"></i>
                    </div>
                    <span routerLink="/profile">{{ 'USER_PROFILE.MY_PROFILE' | translate }}</span>
                    <ul class="custom-tree">
                        <li class="list" >
                            <div class="clickable">
                                <i class="folder fa fa-circle" routerLink="/profile/userdata" routerLinkActive="open"></i>
                                <span routerLink="/profile/userdata" >{{ 'USER_PROFILE.LOGIN_MANAGEMENT.CHANGE_PASSWORD' | translate }}</span>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    <div [@fadeSmallAnimation]="getRouteAnimation(route)" class="center-panel no-float left">
        <router-outlet #route="outlet"></router-outlet>
    </div>
    <div class="right-panel right-sidebar left" style="width: 25%;">

    </div>
</div>
</div>
