import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {GlobalAdminService} from '../services/global-admin.service';
import {workListAnim} from '../../../shared/animations/general.animation';
import {Location} from '@angular/common';
import {UserService} from '../../../shared/services/user/user.service';
import {AdminService} from '../../club/services/admin.service';

@Component({
    selector: 'app-orderhistory',
    templateUrl: './global-orderhistory.component.html',
    animations: [workListAnim]
})
export class GlobalOrderhistoryComponent implements OnInit, AfterViewInit, OnDestroy {

    public searchTerm: string = '';
    public searchType: string = 'PLATFORM_ORDER_ID';
    public selectedClub: number = null;
    public endDate: string = '';
    public startDate: string = '';
    public searchTypes: string[] = ['PLATFORM_ORDER_ID', 'FIRM_NAME', 'REFERENCE', 'ID', 'NAME'];

    constructor(public globalAdminService: GlobalAdminService,
                public location: Location,
                public userService: UserService,
                public adminService: AdminService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.adminService.getClubs();

        this.globalAdminService.orderList = undefined;

        if (this.endDate.length === 0 || this.startDate.length === 0) {
            const now = new Date();
            let month = (now.getMonth() + 1).toString();
            if (month.length === 1) {
                month = '0' + month;
            }
            let day = now.getDate().toString();
            if (day.length === 1) {
                day = '0' + day;
            }
            this.endDate = now.getFullYear() + '-' + (month) + '-' + day;

            const oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));
            let oldMonth = (oneMonthAgo.getMonth() + 1).toString();
            if (oldMonth.length === 1) {
                oldMonth = '0' + oldMonth;
            }
            let oldDay = oneMonthAgo.getDate().toString();
            if (oldDay.length === 1) {
                oldDay = '0' + oldDay;
            }
            this.startDate = oneMonthAgo.getFullYear() + '-' + (oldMonth) + '-' + oldDay;
        }
    }

    ngAfterViewInit() {
        if (this.globalAdminService.resultItems && this.globalAdminService.resultItems.length > 0) {
            this.globalAdminService.emitItems.emit(this.globalAdminService.resultItems);
            if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges()
            }
        }
    }

    ngOnDestroy() {
        this.changeDetectorRef.detach();
        this.changeDetectorRef = undefined;
    }

    public getPlacedOrders($event?: any) {
        this.globalAdminService.resultItems = [];

        if (!$event) {
            this.globalAdminService.orderHistoryRequest = {
                clubId: null,
                firmName: '',
                name: '',
                id: '',
                platformOrderId: '',
                reference: '',
                startDate: '',
                endDate: '',
                page: 0,
                pageSize: 20,
            };
        }

        switch (this.searchType) {
            case 'PLATFORM_ORDER_ID':
                this.globalAdminService.orderHistoryRequest.platformOrderId = this.searchTerm;
                break;
            case 'FIRM_NAME':
                this.globalAdminService.orderHistoryRequest.firmName = this.searchTerm;
                break;
            case 'REFERENCE':
                this.globalAdminService.orderHistoryRequest.reference = this.searchTerm;
                break;
            case 'ID':
                this.globalAdminService.orderHistoryRequest.id = this.searchTerm;
                break;
            case 'NAME':
                this.globalAdminService.orderHistoryRequest.name = this.searchTerm;
                break;
        }
        this.globalAdminService.orderHistoryRequest.endDate = this.endDate;
        this.globalAdminService.orderHistoryRequest.startDate = this.startDate;
        this.globalAdminService.orderHistoryRequest.clubId = this.selectedClub;

        if (this.globalAdminService.orderHistoryRequest.endDate.length > 0
            && this.globalAdminService.orderHistoryRequest.endDate.indexOf('T') < 0) {
            this.globalAdminService.orderHistoryRequest.endDate += 'T23:59';
        }

        if ($event) {
            this.globalAdminService.orderHistoryRequest.page = ($event - 1);
        }

        this.globalAdminService.getPlacedOrders();
    }

    public resetFilter() {
        this.globalAdminService.newDate = undefined;
        this.globalAdminService.resultItems = [];
        this.globalAdminService.getPlacedOrders();
    }

    public setClubId($event: any) {
        this.selectedClub = $event.clubId;
        this.getPlacedOrders();

    }

    public getClubWithId(id: number) {
        if (this.adminService.clubs) {
            return this.adminService.clubs.filter(club => club.clubId === id)[0];
        }
    }

    public resetSearch() {
        this.searchTerm = '';
        this.searchType = 'PLATFORM_ORDER_ID';
        this.selectedClub = null;
        this.endDate = '';
        this.globalAdminService.resultItems = [];
        this.globalAdminService.orderList = undefined;
        this.globalAdminService.emitItems.emit([]);
        this.globalAdminService.orderHistoryRequest = {
                clubId: null,
                firmName: '',
                name: '',
                id: '',
                platformOrderId: '',
                reference: '',
                startDate: '',
                endDate: '',
                page: 0,
                pageSize: 20
        }
    }
}
