import {Component, OnInit, NgZone, Input, OnDestroy, OnChanges, SimpleChanges} from '@angular/core';
import {ArticlesService} from '../services/articles.service';
import {SearchHistoryService} from '../../shared/services/search-history.service';
import {ArticlesRestService} from '../services/articles.rest.service';
import {CarService} from '../../shared/services/car/car.service';
import {environment} from '../../../environments/environment';
import {UserService} from '../../shared/services/user/user.service';
import {ICart} from '../../shared/interfaces/cart';
import {ICartArticle} from '../../cart/interfaces/cart-article';
import {IWorkPosition} from '../interfaces/article';
import {CartService} from '../../cart/services/cart.service';
import {CartRestService} from '../../cart/services/cart.rest.service';
import {CarRestService} from '../../shared/services/car/car.rest.service';
import {AssemblyCategoriesService} from '../services/assembly-categories.service';
import {HttpClient} from '@angular/common/http';
import {Location} from '@angular/common';
import {DmsDataService} from '../../shared/services/dms-data.service';
import {HelperService} from '../../shared/services/helper.service';
import {Subscription} from 'rxjs/Rx';
import {ICartInfoResponse} from '../../shared/interfaces/DTO/cart/cartInfoResponse';

@Component({
    selector: 'app-article-details',
    templateUrl: './article-details.component.html',
    styleUrls: ['./article-details.component.scss']
})
export class ArticleDetailsComponent implements OnInit, OnDestroy, OnChanges {

    @Input() public service: any;
    @Input() public showBelongingWork: boolean;
    @Input() public showArtikelLists: boolean; // Stücklisten und Zubehörlisten


    private imageUrlPrefix: string;
    private requestQuantity: number;
    public adcTypeId: number;

    private cartPosition: ICart;
    private _toCartPosition: ICartArticle;
    private _selectedSupplier: string;

    private subscriptions: Subscription[] = [];
    public cartCount: ICartInfoResponse;

    constructor(public articlesService: ArticlesService,
                public carService: CarService,
                public cartService: CartService,
                private carRestService: CarRestService,
                public userService: UserService,
                private http: HttpClient,
                private articlesRestService: ArticlesRestService,
                public articleService: ArticlesService,
                private assemblyCategoriesService: AssemblyCategoriesService,
                private searchHistoryService: SearchHistoryService,
                private cartRestService: CartRestService,
                private _zone: NgZone,
                private dmsDataService: DmsDataService,
                public location: Location,
                public helperService: HelperService) {
        this.imageUrlPrefix = environment.baseUrl;

    }

    ngOnInit() {
        this.articlesService.selectedArticleAvailability = [];
        this.subscriptions.push(this.cartService.cartCount$.subscribe(cartCount => {
            this.cartCount = cartCount;
            this.checkPosInCart();
        }));

        this.subscriptions.push(this.service.selectedArticleChanged$.subscribe(() => {
            this.checkPosInCart();
        }));

        this.subscriptions.push(this.service.selected)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.service) {
            this.checkPosInCart();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            if (sub) {
                sub.unsubscribe();
            }});
    }

    private onDirectArticleSearch(searchString: string) {
        this.searchHistoryService.addItem(searchString);
        this.service.updateArticlesBySearchString(searchString);
    }

    private checkPosInCart() {
        if (this.service.selectedArticle) {
            if (this.cartCount && this.cartCount.positionInfos
                && this.cartCount.positionInfos.length > 0) {
                for (const info of this.cartCount.positionInfos) {
                    if (info.articleId === this.service.selectedArticle.id) {
                        this.service.selectedArticle.quantityInCart = info.quantity;
                        return;
                    }
                }
            }
        }
    }


    private toCart(cart: ICart) {
        this.cartPosition = cart;
    }

    private loadCars() {
        return this.service.getCompatiblecars();
    }

    public onCarSelection(tdTypeId: number) {
        if ( (!this.dmsDataService.isCatalogMode() || (this.dmsDataService.isCatalogMode() && this.userService.getCarChangeSettings() !== 'PER_CAR'))) {
            this.carRestService.getCarsByKTypeNumber(tdTypeId).subscribe(
                (response) => {
                    this.carService.selectCar(response[0], true);
                    this.carService.setFirstEntryInHistory(response[0]);
                    if (this.service === this.articleService) {
                        this.articleService.resetToDefaultTree();
                    }
                },
                (error) => {
                    if (error.status === 409) {
                        this.carService.handleCarConflict();
                    }
                }
            );
        }
    }

    public getCarLinkKeys() {
        if (this.service.selectedArticle && this.service.selectedArticle.carLinks) {
            return Object.keys(this.service.selectedArticle.carLinks);
        } else {
            return [];
        }
    }

    public onWorkPosStateChange(event: boolean, workPos: IWorkPosition) {
        if (event) {
            const selectedCar = this.carService.selectedCar;
            const adcTypeId = (selectedCar && selectedCar.adcTypeId) ? selectedCar.adcTypeId : 0;
            const kTypeId = (selectedCar && selectedCar.kTypeId) ? selectedCar.kTypeId : 0;
            const worktocart = {
                adcTypeId: adcTypeId,
                description: workPos.mountPositionText + ' ' + workPos.repairText,
                kTypeId: kTypeId,
                labourTime: workPos.workTime,
                ltQualColId: workPos.ltQualColId,
                mdQualColId: workPos.mdQualColId,
                workId: workPos.workId,
                itemMpId: workPos.itemMpId,
                workType: 'WORK',
                repairId: workPos.repairId,
                vin: selectedCar.vin,
                producerName: selectedCar.carProducerName,
                workPosNo: workPos.workPosNo,
                quantity: workPos.quantity,
                quantityUnit: workPos.quantityUnit
            };

            this.cartRestService.addWorkToCart(worktocart).subscribe(
                (success) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_ADD_TO_CART', 'success');
                    this.cartService.updateCartCount();
                    workPos.posIdInCart = success;
                }
            );
        } else {
            this.cartService.deletePos(workPos.posIdInCart);
        }

    }

}
