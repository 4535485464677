import {Injectable} from '@angular/core';
import {CartService} from "../../cart/services/cart.service";
import {CartRestService} from "../../cart/services/cart.rest.service";
import {Router} from "@angular/router";

@Injectable()
export class CheckoutService {
    private totalPrice: number;
    private netSum: number;
    private procurementCosts: number;
    private tax: number;

    constructor(private cartRestService: CartRestService,
                private router: Router,
                private cartService: CartService) {
    }

    public bySupplier(positions) {
        let list = {};
        var empty = true;

        for (let prod of positions) {
            if (this.orderable(prod)) {
                if (!list[prod.supplier]) {
                    list[prod.supplier] = [];
                }
                empty = false;
                list[prod.supplier].push(prod);
            }

        }
        if(empty){
            this.router.navigate(['/cart']);
        }
        return list;
    }

    public byArticle(positions) {
        let list = {};
        var empty = true;

        for (let prod of positions) {
            if (this.orderable(prod)) {
                if (!list[prod.articleId]) {
                    list[prod.articleId] = [];
                }
                empty = false;
                list[prod.articleId].push(prod);
            }

        }
        if(empty){
            this.router.navigate(['/cart']);
        }
        return list;
    }


    /*
        This method check if this product is orderable.
        Conditions:
            Must have a supplier => is a product, not a work
            has an articleId
            is checked in card
     */
    public orderable(prod) {

        return (prod.supplier && prod.articleId && prod.checked);
    }


    /*
        Calculate price for summary
        Only for display purpose!
     */
    public calcPrices(positions) {

        let prices = {
            'totalPrice': 0,
            'netSum': 0,
            'tax': 0
        }

        for (let prod of positions) {
            if (this.orderable(prod)) {
                prices.netSum += prod.sellInNetTotal;
                prices.totalPrice += prod.sellInGrossTotal;
            }
        }
        prices.tax = prices.totalPrice - prices.netSum;

        for (let deliveryCost of Object.keys(this.cartService.currentCart.cartCostDTO.deliveryCosts)) {
            prices.totalPrice += this.cartService.currentCart.cartCostDTO.deliveryCosts[deliveryCost];
        }

        return prices;
    }


}
