import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AutovistaService} from '../../services/autovista.service';
import {HelperService} from '../../../shared/services/helper.service';

@Directive({
    selector: '[appSVGOld]'
})
export class SvgOldDirective implements OnChanges {

    @Input() svgImage;
    @Input() isCarOverview: boolean;

    public initColor: string = '#545454';

    constructor(private autovistaService: AutovistaService,
                private helperService: HelperService) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.svgImage) {
            const autovista = document.getElementById('svgOldWrapper').children[0];
            if (autovista) {
                autovista.parentNode.removeChild(autovista);
            }

            setTimeout(() => {
                this.setNewSVG();
            }, 50);
        }
    }

    public setNewSVG() {
        if (this.svgImage && !this.isCarOverview) {
            document.getElementById('svgOldWrapper').insertAdjacentHTML('beforeend', this.svgImage.toString());
            this.autovistaService.svgDoc = document.getElementById('svgOldWrapper').children[0];
            this.autovistaService.svgDoc.setAttribute('id', 'svg');
            this.autovistaService.svgDoc.setAttribute('width', '100%');
            this.autovistaService.svgDoc.setAttribute('height', '100%');

            if (this.helperService.browserIsIE()) {
                this.autovistaService.svgDoc.setAttribute('style', 'margin-top: 35px; min-height: 75vh;');
            }

            const bbox = this.autovistaService.svgDoc.getBBox();

            if (!this.helperService.browserIsIE()) {
                const centerContent = document.getElementsByClassName('center-content')[0];
                const centerFactor = centerContent.clientWidth / centerContent.clientHeight;
                const bboxFactor = bbox.width / bbox.height;

                if (centerFactor > bboxFactor) {
                    const factorWidth = bbox.width * centerFactor;
                    bbox.width += factorWidth / 2;
                    bbox.x -= factorWidth / 4;
                }
            }

            this.autovistaService.svgDoc.setAttribute('viewBox', (bbox.x) + ' ' + (bbox.y)
                + ' ' + (bbox.width + 20) + ' ' + (bbox.height + 20));

            if (!this.helperService.browserIsIE()) {
                this.autovistaService.svgDoc.setAttribute('style', 'margin-top: 35px; max-height: 75vh');
            }

            if (this.helperService.browserIsEdge()) {
                this.autovistaService.svgDoc.innerHTML = this.autovistaService.svgDoc.innerHTML + ' ';
            }

            this.setInitColor();

            this.autovistaService.imageLoading = false;


            // is car overview
        } else if (this.svgImage && this.isCarOverview) {
            document.getElementById('svgOldWrapper').insertAdjacentHTML('beforeend', this.svgImage.toString());
            this.autovistaService.svgDoc = document.getElementById('svgOldWrapper').children[0];
            this.autovistaService.svgDoc.setAttribute('id', 'svg');
            this.autovistaService.svgDoc.setAttribute('width', '100%');
            this.autovistaService.svgDoc.setAttribute('height', '100%');

            if (this.helperService.browserIsIE()) {
                this.autovistaService.svgDoc.setAttribute('style', 'margin-top: 35px; min-height: 75vh;');
            }

            const bbox = this.autovistaService.svgDoc.getBBox();

            if (!this.helperService.browserIsIE()) {
                const centerContent = document.getElementsByClassName('center-content')[0];
                const centerFactor = centerContent.clientWidth / centerContent.clientHeight;
                const bboxFactor = bbox.width / bbox.height;

                if (centerFactor > bboxFactor) {
                    const factorWidth = bbox.width * centerFactor;
                    bbox.width += factorWidth / 2;
                    bbox.x -= factorWidth / 4;
                }
            }

            this.autovistaService.svgDoc.setAttribute('viewBox', (bbox.x) + ' ' + (bbox.y)
                + ' ' + (bbox.width + 20) + ' ' + (bbox.height + 20));

            if (!this.helperService.browserIsIE()) {
                this.autovistaService.svgDoc.setAttribute('style', 'margin-top: 35px; max-height: 75vh');
            }

            if (this.helperService.browserIsEdge()) {
                this.autovistaService.svgDoc.innerHTML = this.autovistaService.svgDoc.innerHTML + ' ';
            }

            this.autovistaService.imageLoading = false;
        }
    }

    public setInitColor() {
        let g = this.autovistaService.svgDoc.getElementsByTagName('g');
        for (let gChild of g) {
            let selectedColor = '#f5f5f5';
            if (gChild.getAttribute('id')) {
                let temp = gChild.getAttribute('id').replace('(PS)','');
                temp = temp.replace('_l','');
                temp = temp.replace('_r','');

                if (this.autovistaService.subGroupDetails) {
                    for (let imageData of this.autovistaService.subGroupDetails.images[this.autovistaService.selectedImageUrl]) {
                        if (imageData.id === parseInt(temp)) {
                            selectedColor = this.getMarterialColor(imageData.materialCode);
                        }
                    }
                }
            }
            if (gChild.getAttribute('fill') === '#000000') {
                gChild.setAttribute('fill', this.initColor);
            }
            let path = gChild.getElementsByTagName('path');
            for (let child of path) {
                if (child.getAttribute('fill') === '#000000') {
                    child.setAttribute('fill', this.initColor);
                }
                if (child.getAttribute('fill') === '#ffffff' || child.getAttribute('fill') === '#f5f5f5' && selectedColor) {
                    child.setAttribute('fill', selectedColor);
                }
            }
            let line = gChild.getElementsByTagName('line');
            for (let lineChild of line) {
                lineChild.setAttribute('stroke', this.initColor);
                lineChild.setAttribute('stroke-width', 5);
                lineChild.setAttribute('stroke-dasharray', 10,10);
            }
        }
    }

    public getMarterialColor(materia: string) {
        switch (materia) {
            case 'XX' :
                return '#dfe6ed';
            case 'AL' : // Aluminium
                return '#7ED3D1';
            case 'CF' : // Carbin / GFK
                return '#F37421';
            case 'EL' : // Kunststoff
                return '#F1E3BB';
            case 'GF' : // Glasfaser
                return '#7AB800';
            case 'KS' : // Kunststoff
                return '#F1E3BB';
            case 'ST' : // Stahl
                return '#5E6A71';
            case 'SI' : // Stahl innen
                return '#A5ACAF';
            case 'HS' : //  hochfestem Stahl
                return '#d2cbd3';
            case 'VS' : // sehr hochfestem Stahl
                return '#878392';
            case 'US' : // ultra hochfestem Stahl
                return '#a6b1ad';
            case '82AA8E' : // Textil
                return '#F1E3BB';
            case 'GL' : // Glas
                return '#0096C8';
            case 'MG' : // Magnesium
                return '#7ED3D1';
            case 'AW' : // Alufelgen
                return '#a6a6a6';
            default: return '#fff';
        }
    }

}
