<div class="heading blue">
    <span class="clickable" routerLink="/company-admin">
        <i class="fa fa-th" aria-hidden="true"></i>
         {{'ADMIN_PANEL.OVERVIEW' | translate}}
    </span>
    <span class="clickable" routerLink="/company-admin/user">
        <i class="fa fa-caret-right"></i>
        {{ 'USER_PROFILE.USER_MANAGEMENTS' | translate }}
    </span>
    <span>
        <i class="fa fa-caret-right"></i>
        {{userData?.userName}}
    </span>
</div>
<div class="panel-content center-content white-background">
<div class="edituser">
      <app-user-data
              [userData]="userData"
              [firmData]="companyAdminService.firmData"
              (_back)="reset()"
              (_update)="update()"
              (_updateReset)="updateReset()"
      >
      </app-user-data>
</div>
</div>
