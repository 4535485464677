import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {UserService} from '../../../shared/services/user/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class FirmDashboardComponent implements OnInit {

  public reset :any;
  constructor(private router: Router,
              public userService: UserService) { }

  ngOnInit() {
  }

}
