import {EventEmitter, Injectable} from '@angular/core';
import {UserService} from '../../../shared/services/user/user.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {IAdminFirm} from '../../../shared/interfaces/adminFirm';
import {IExternalFirmTools} from '../../../shared/interfaces/DTO/firm/external-tools';
import {ErrorHandlerService} from '../../../shared/components/error-handler/services/error-handler.service';
import {ThemeService} from '../../../shared/services/theme.service';
import {Router} from '@angular/router';
import {IClubStatistic} from '../../../shared/interfaces/DTO/club/clubStatistic';
import {IClubCustomPosition} from '../../../shared/interfaces/DTO/club/clubCustomPosition';
import {IFirmListResponse} from '../../../shared/interfaces/DTO/firm/firmListResponse';
import {IFirm, IFirmSetting} from '../../../shared/interfaces/DTO/firm/firm';
import {IAdminUpdateOrCreateFirmRequest} from '../../../shared/interfaces/DTO/admin/adminUpdateOrCreateFirmRequest';
import {ILogin} from '../../../shared/interfaces/DTO/firm/login';
import {ICountry} from '../../../shared/interfaces/DTO/firm/country';
import {ISupplierLoginData} from '../../../shared/interfaces/DTO/firm/supplierLoginData';
import {ISupplier} from '../../../shared/interfaces/DTO/firm/supplier';
import {IUpdateLoginRequest} from '../../../shared/interfaces/DTO/firm/updateLoginRequest';
import {ICreateLoginRequest} from '../../../shared/interfaces/DTO/firm/createLoginRequest';
import {IClubSetting} from '../../../shared/interfaces/DTO/club/clubSetting';
import {IClubSupplier} from '../../../shared/interfaces/DTO/admin/clubSupplier';
import {HelperService} from '../../../shared/services/helper.service';
import {error} from "protractor";

@Injectable()
export class AdminService {
    private _showOnlyActiveFirms: boolean = true;
    public selectedFirm: any;
    public step: any[3] = {0: [], 1: '', 2: false};
    public selectedItem: any;
    public club: any;
    public clubId: number;
    public clubs: any = undefined;
    public supplier: any;
    public onChangeClub: EventEmitter<number> = new EventEmitter<number>();
    public clubsLoaded: EventEmitter<number> = new EventEmitter<number>();
    public newClubSettingsSaved: EventEmitter<any> = new EventEmitter<any>();
    public clubStats: any;
    public clubStatsLoading: boolean;
    public newUserData$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public validationErrorFirm$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(public userService: UserService,
                private helperService: HelperService,
                private themeService: ThemeService,
                private http: HttpClient,
                private errorHandlerService: ErrorHandlerService,
                private router: Router) {
        this.supplier = {
            customerNo: '',
            supplier: '',
            password: '',
            username: '',
            firmId: 0,
            id: 0,
            sortNo: 0,
            active: true
        };

        this.userService.accountData$.subscribe((step) => {
            if (step) {
                this.resetClubs();
                if (this.router.url.indexOf('/admin') > -1) {
                    if (step.roles.indexOf('ROLE_GLOBAL_ADMIN') > -1) {
                        this.getClubs();
                    } else {
                        this.getClub();
                    }
                }
            }
        });
    }

    public get showOnlyActiveFirms(): boolean {
        return this._showOnlyActiveFirms;
    }

    public set showOnlyActiveFirms(value: boolean) {
        this._showOnlyActiveFirms = value;
    }

    public getClubs() {
        this.getClubsRest().subscribe((response: any[]) => {
            this.clubs = response.sort((n1, n2) => {
                if (n1.name.toLowerCase() > n2.name.toLowerCase()) {
                    return 1;
                }

                if (n1.name.toLowerCase() < n2.name.toLowerCase()) {
                    return -1;
                }

                return 0;
            });
            this.clubsLoaded.emit(1);
            if (!this.club) {
                for (const club of this.clubs) {
                    if (this.userService.accountData && club.clubId === this.userService.accountData.clubId) {
                        this.changeClub(club);
                    }
                }
            }
        });
    }

    public getClubStatisticInfos(clubId: number) {
        this.clubStats = '';
        this.clubStatsLoading = true;
        this.getClubStatisticInfosRest(clubId).subscribe(
            (response) => {
                this.clubStats = response;
                this.clubStatsLoading = false;
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_DATA', 'error');
                this.clubStatsLoading = false;
                this.router.navigate(['/global-admin/statistics']);
            }
        );
    }

    public setClubSetting(clubSettings: IClubSetting) {
        if (clubSettings && this.userService.allow('CLUB-ADMIN')) {
            this.setClubSettingsRest(clubSettings).subscribe(
                () => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                    this.newClubSettingsSaved.emit();
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_UPDATE', 'error');
                });
        }
    }

    private setClubSettingsRest(clubSettings: IClubSetting): Observable<IClubSetting> {
        return this.http.put<IClubSetting>(
            `${environment.apiUrl}/admin/club/settings`,
            clubSettings
        )
    }

    public setCountry(country: ICountry) {
        return this.http.put(
            `${environment.apiUrl}/admin/defaultcountry`,
            country
        )
    }

    public getClubStatisticInfosRest(clubId: number): Observable<IClubStatistic> {
        return this.http.get<IClubStatistic>(
            `${environment.apiUrl}/globaladmin/club/statistic/${clubId}`
        );
    }

    public getClubStatisticDownloadLinl(clubId: number, type: string) {
        if (type === 'short') {
            return `${environment.apiUrl}/globaladmin/club/statistic/export/short/${clubId}`;
        } else if (type === 'full') {
            return `${environment.apiUrl}/globaladmin/club/statistic/export/${clubId}`;
        }
    }


    public getTemplatesClubRest(): Observable<IClubCustomPosition[]> {
        return this.http.get<IClubCustomPosition[]>(
            `${environment.apiUrl}/admin/custompositions`
        );
    }

    public getTemplatesGlobalRest(clubId: number): Observable<IClubCustomPosition[]> {
        return this.http.get<IClubCustomPosition[]>(
            `${environment.apiUrl}/globaladmin/custompositions?clubId=${clubId}`
        );
    }

    public createTemplateGlobalRest(newTemplate: IClubCustomPosition) {
        return this.http.post<IClubCustomPosition>(
            `${environment.apiUrl}/globaladmin/customposition`,
            newTemplate
        );
    }

    public createTemplateClubRest(newTemplate: IClubCustomPosition) {
        return this.http.post<IClubCustomPosition>(
            `${environment.apiUrl}/admin/customposition`,
            newTemplate
        );
    }


    public deleteTemplateGlobalRest(templateId: string) {
        return this.http.delete(
            `${environment.apiUrl}/globaladmin/customposition/${templateId}`
        );
    }

    public deleteTemplateClubRest(templateId: string) {
        return this.http.delete(
            `${environment.apiUrl}/admin/customposition/${templateId}`
        );
    }

    public getFirmSettings(firmId: number): Observable<IFirmListResponse> {
        return this.http.get<IFirmListResponse>(
            `${environment.apiUrl}/admin/firm/${firmId}/settings`
        );
    }

    public setFirmSettings(firmId: number, firmSettings: any): Observable<IFirmSetting> {
        return this.http.put<IFirmSetting>(
            `${environment.apiUrl}/admin/firm/${firmId}/settings`,
            firmSettings
        );
    }

    public resetClubs() {
        this.clubs = undefined;
    }

    public getClub() {
        if (this.userService.accountData && this.userService.accountData.clubId) {
            this.clubId = this.userService.accountData.clubId;
        }
    }

    public getClubSuppliersRest(clubId: number): Observable<IClubSupplier[]> {
        return this.http.get<IClubSupplier[]>(
            `${environment.apiUrl}/admin/suppliers/${clubId}`
        );
    }

    public getAllSuppliersRest(): Observable<IClubSupplier[]> {
        return this.http.get<IClubSupplier[]>(
            `${environment.apiUrl}/admin/allsuppliers`
        );
    }

    public updateClubSuppliersRest(clubId: number, suppliers: IClubSupplier[]) {
        return this.http.put(
            `${environment.apiUrl}/admin/suppliers/${clubId}`,
            suppliers
        );
    }

    public getFirmData(searchTerm: string, clubId: number, page: number, pageSize: number, onlyActive: boolean): Observable<IFirmListResponse> {
        return this.http.get<IFirmListResponse>(
            `${environment.apiUrl}/admin/firms/${clubId}?searchTerm=${searchTerm}&pageSize=${pageSize}&page=${page}&onlyActive=${onlyActive}`
        );
    }

    public deleteFirm(firmId: any): Observable<any> {
        return this.http.delete<any>(
            `${environment.apiUrl}/globaladmin/firm/${firmId}`
        );
    }

    public getFirmDataById(firmId: number): Observable<IFirm> {
        return this.http.get<IFirm>(
            `${environment.apiUrl}/admin/firm/${firmId}`
        );
    }

    public saveFirmRest(firmData: IAdminFirm): Observable<any> {
        return this.http.put(
            `${environment.apiUrl}/admin/firm/${firmData.firmId}`,
            firmData
        );
    }

    public saveFirmSettings(firmId: number, firmData: IFirmSetting[]): Observable<any> {
        return this.http.put(
            `${environment.apiUrl}/admin/firm/${firmId}/settings`,
            firmData
        );
    }

    public createFirmRest(firmData: IAdminUpdateOrCreateFirmRequest): Observable<any> {
        return this.http.post(
            `${environment.apiUrl}/admin/firm/create`,
            firmData
        );
    }

    public getUserById(userId: number): Observable<ILogin> {
        return this.http.get<ILogin>(
            `${environment.apiUrl}/admin/login/${userId}`
        );
    }

    public deleteUser(userId: any): Observable<any> {
        return this.http.delete<any>(
            `${environment.apiUrl}/globaladmin/login/${userId}`
        );
    }

    public archiveUserFirmAdmin(userId: any): Observable<any> {
        return this.http.delete<any>(
            `${environment.apiUrl}/firmadmin/login/${userId}`
        );
    }

    public archiveUserClubAdmin(userId: any): Observable<any> {
        return this.http.delete<any>(
            `${environment.apiUrl}/admin/login/${userId}`
        );
    }

    public getRolesRest(): Observable<string[]> {
        return this.http.get<string[]>(
            `${environment.apiUrl}/admin/roles`
        );
    }

    public getStatesRest(): Observable<ICountry> {
        return this.http.get<ICountry>(
            `${environment.apiUrl}/admin/countries`
        );
    }

    public getSuppliersRest(firmId: number): Observable<ISupplierLoginData[]> {
        return this.http.get<ISupplierLoginData[]>(
            `${environment.apiUrl}/admin/firm/suppliers/${firmId}`
        );
    }

    public getUnassignedsuppliers(firmId: number): Observable<ISupplier[]> {
        return this.http.get<ISupplier[]>(
            `${environment.apiUrl}/admin/unassignedsuppliers/${firmId}`
        );
    }

    public getExternalTools(firmId: number): Observable<IExternalFirmTools[]> {
        return this.http.get<IExternalFirmTools[]>(
            `${environment.apiUrl}/admin/firm/externaltools/${firmId}`
        );
    }

    public setExternalTools(data: IExternalFirmTools): Observable<any> {
        return this.http.put(
            `${environment.apiUrl}/admin/firm/externaltools`,
            data
        );
    }

    public setUserDataRest(userData: IUpdateLoginRequest, userID: number): Observable<any> {
        return this.http.put(
            `${environment.apiUrl}/admin/login/${userID}`,
            userData
        );
    }

    public createUserRest(userData: ICreateLoginRequest): Observable<any> {
        return this.http.post(
            `${environment.apiUrl}/admin/login/create`,
            userData
        );
    }

    public deleteSupplierRest(supplier: ISupplierLoginData): Observable<any> {
        return this.http.delete(
            `${environment.apiUrl}/admin/firm/suppliers/${supplier.id}`
        );
    }

    public createSupplierRest(supplier: ISupplierLoginData): Observable<any> {
        return this.http.post(
            `${environment.apiUrl}/admin/firm/suppliers`,
            supplier
        );
    }

    public updateSupplierRest(supplier: ISupplierLoginData): Observable<any> {
        return this.http.put(
            `${environment.apiUrl}/admin/firm/suppliers`,
            supplier
        );
    }
    private handleFirmSaveError(error: any) {
        if (error?.status == 409 && error?.error?.violations) {
            if (error.error.violations.includes('NON_UNIQUE_NAME') && error.error.violations.includes('NON_UNIQUE_EMAIL')) {
                this.validationErrorFirm$.next('NAME_AND_EMAIL');
            } else if (error.error.violations.includes('NON_UNIQUE_NAME')) {
                this.validationErrorFirm$.next('NAME');
            } else if (error.error.violations.includes('NON_UNIQUE_EMAIL')) {
                this.validationErrorFirm$.next('EMAIL');
            } else if (error.error.violations.includes('NO_EMAIL')) {
                this.validationErrorFirm$.next('EMAIL');
            } else if (error.error.violations.includes('INVALID_EMAIL')) {
                this.validationErrorFirm$.next('EMAIL');
            } else if (error.error.violations.includes('NON_UNIQUE_EXTERNAL_ID')) {
                this.validationErrorFirm$.next('EXTERNAL_ID');
            }
        }
        this.helperService.showNotification('TOAST_MESSAGES.ERROR_SAVE', 'error');
        this.errorHandlerService.throwError(error.status);
    }

    public saveFirm(firmData: IAdminFirm) {
        this.saveFirmRest(firmData).subscribe(
            (success) => {
                this.validationErrorFirm$.next('');
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                this.errorHandlerService.newError = false;
                this.getFirmDataById(firmData.firmId).subscribe(
                    (firm) => {
                        this.selectedFirm = firm;
                    }
                );
            },
            (error) => {
                this.handleFirmSaveError(error);
            }
        );
    }


    public createFirm(firmData: IAdminUpdateOrCreateFirmRequest) {
        this.createFirmRest(firmData).subscribe(
            (success) => {
                this.validationErrorFirm$.next('');
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                this.errorHandlerService.newError = false;
                if (success > 0) {
                   void this.router.navigate(['admin', success]);
                }
            },
            (error) => {
                this.handleFirmSaveError(error);
            }
        );
    }

    public createUser(userData: ICreateLoginRequest) {
        return this.createUserRest(userData).subscribe(
            (user) => {
                this.newUserData$.next(user);
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                this.getFirmDataById(userData.firmId).subscribe(
                    (data) => {
                        this.selectedFirm = data;
                    }
                )
            },
            (error) => {
                this.newUserData$.next(undefined);
                this.errorHandlerService.throwError(error.status);
            }
        );
    }

    public setUserData(userData: IUpdateLoginRequest, userID: number) {
        return this.setUserDataRest(userData, userID).subscribe(
            (success) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                this.getFirmDataById(this.selectedFirm.firmId).subscribe(
                    (firm) => {
                        this.selectedFirm = firm;
                    }
                );
            },
            (error) => {
                this.errorHandlerService.throwError(error.status);
            }
        );
    }

    public createSupplier(supplier: ISupplierLoginData) {
        this.createSupplierRest(supplier).subscribe(
            (success) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                this.router.navigate(['admin/suppliers', this.selectedFirm.firmId]);
            },
            (error) => {
                this.errorHandlerService.throwError(error.status);
            }
        );
    }

    public updateSupplier(supplier: ISupplierLoginData) {
        this.updateSupplierRest(supplier).subscribe(
            (success) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                this.userService.getSuppliers(true);
                this.router.navigate(['admin/suppliers', this.selectedFirm.firmId]);
            },
            (error) => {
                this.errorHandlerService.throwError(error.status);
            }
        );
    }


    public getClubsRest(): Observable<string[]> {
        return this.http.get<string[]>(
            `${environment.apiUrl}/globaladmin/clubs`
        );
    }

    public getClubId(name?: string) {
        let clubId = 0;
        if (this.club && this.club.clubId > 0) {
            clubId = this.club.clubId;
        } else {
            if (this.userService && this.userService.accountData) {
                clubId = this.userService.accountData.clubId;
            } else {
                clubId = null;
            }
        }
        return clubId;
    }


    public changeClub(club: any) {
        if (club !== this.club) {
            this.selectedFirm = undefined;
            this.club = club;
            this.themeService.changeTheme(club.theme);
            this.onChangeClub.emit(club.clubId);
        }
    }

    public isAdmin() {
        return this.userService.allow('CLUB-ADMIN');
    }

    public GlobalAdmin() {
        return this.userService.allow('GLOBAL-ADMIN');
    }
}
