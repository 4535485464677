<div class="">
    <div class="car-history-dropdown pull-left">
        <div class="toolTipDiv" triggers="mouseenter:mouseleave" [openDelay]="tooltipDelay"
             [ngbPopover]="'CAR_HISTORY.TITLE' | translate" container="body">
            <button class="nx-dropdown-button"
                    [disabled]="!(carService?.carHistory && carService?.carHistory?.length > 0) || (dmsDataService.isCatalogMode() && getCartCreationBehavior()?.value === 'PER_CAR')"
                    (click)="showMenu = !showMenu; showInfos = false; configService.showExternalTools = false; carService.newRecallInformation = false; carService.showCarInfo = false"
                    [ngClass]="{ 'red-border': carService.selectedCar?.serviceInfoState === 'CURRENT', 'hasSelectedCar': carService?.selectedCar, 'show-external-tools': configService.getNumberOfActiveTools() > 0 }">
            <span *ngIf="carService.selectedCar && carService.selectedCar.constructionTo" class="selected">
                    {{ getNewDisplayName(carService.selectedCar) + carService.selectedCar.constructionFrom + ' ' + ('GENERAL.TO' | translate) + ' ' + carService.selectedCar.constructionTo}}
            </span>
                <span *ngIf="carService.selectedCar && !carService.selectedCar.constructionTo" class="selected">
                    {{ getNewDisplayName(carService.selectedCar) + ('GENERAL.FROM' | translate) + ' ' + carService.selectedCar.constructionFrom}}
            </span>
                <div class="text-center" *ngIf="!carService.selectedCar && carService.carHistoryLoading">
                    <i class="fa fa-spinner"
                       [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"></i>
                </div>
                <span *ngIf="!carService.selectedCar && !carService.carHistoryLoading" class="selected">
                {{ 'CAR_SELECTION.PLEASE_SELECT_CAR' | translate }}
            </span>
                <i class="fa fa-th-list"></i>
            </button>
        </div>

        <div class="info_icon" *ngIf="carService?.selectedCar">
            <div class="toolTipDiv" triggers="mouseenter:mouseleave" [openDelay]="tooltipDelay"
                 [ngbPopover]="'CAR_HISTORY.CAR_INFO' | translate" container="body">
                <i class="fa fa-info-circle"
                   (click)="carService.showCarInfo = !carService.showCarInfo; showMenu = false; carService.newRecallInformation = false; configService.showExternalTools = false; $event.stopPropagation()"></i>
            </div>
        </div>

        <div class="nx-dropdown-menu" [ngClass]="{'history': showMenu, 'can-deselect': carService?.selectedCar}"
             *ngIf="!dmsDataService.isCatalogMode() || dmsDataService.isCatalogMode() && getCartCreationBehavior()?.value !== 'PER_CAR'">
            <div class="row">
                <div class="col col-12 car-list">
                    <ul>
                        <ng-container *ngFor="let car of carService?.carHistory">
                            <li *ngIf="car?.constructionTo" (click)="onCarChange(car)">
                                {{getNewDisplayName(car) + car.constructionFrom + ' ' + ('GENERAL.TO' | translate) + ' ' + car.constructionTo}}
                            </li>
                            <li *ngIf="!car?.constructionTo" (click)="onCarChange(car)">
                                {{getNewDisplayName(car) + ('GENERAL.FROM' | translate) + ' ' + car.constructionFrom}}
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
            <div class="row" *ngIf="carService?.selectedCar">
                <div (click)="onCarDeselect()" class="deselect col">
                    <span>{{ 'CAR_SELECTION.DESELECT' | translate }}</span>
                </div>
            </div>

        </div>
        <div class="nx-dropdown-menu"
             [ngClass]="{'infos': carService.showCarInfo, 'small-info': !carService.selectedCar?.serviceInfos.length || !userService.allow('VEHICLE_CALLBACK')}"
             *ngIf="carService?.selectedCar">
            <app-car-info></app-car-info>
        </div>
        <ng-container *ngIf="configService.getNumberOfActiveTools() === 1">
            <ng-container
                    *ngIf="(configService.getFirstActiveTool().code !== 'DIGITAL_SERVICEBOOK') || (configService.getFirstActiveTool().code === 'DIGITAL_SERVICEBOOK' && carService?.selectedCar?.digitalServiceBook?.length > 0)">
                <div class="external-tool-single external-icon-wrapper {{configService.getFirstActiveTool().code}} clickable"
                     [ngClass]="{'no-car-selected': !(carService?.selectedCar)}"
                     *ngIf="configService.getNumberOfActiveTools() === 1"
                     (click)="configService.openTool(configService.getFirstActiveTool())">
                    <div class="toolTipDiv" triggers="mouseenter:mouseleave" [openDelay]="tooltipDelay"
                         [ngbPopover]="'EXTERNAL_TOOLS.' + configService.getFirstActiveTool().code | translate"
                         container="body">
                        <i class="fa"></i>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <div class="external-tools_icon" [ngClass]="{'no-car-selected': !(carService?.selectedCar)}"
             *ngIf="configService.getNumberOfActiveTools() > 1"
             (click)="configService.showExternalTools = !configService.showExternalTools; showMenu = false; carService.newRecallInformation = false; carService.showCarInfo = false">
            <div class="toolTipDiv" triggers="mouseenter:mouseleave" [openDelay]="tooltipDelay"
                 [ngbPopover]="'CLUB_ADMIN.EXTERNAL_TOOLS.TITLE' | translate" container="body">
                <i class="fa fa-window-restore"></i>
            </div>
        </div>

        <div class="external-tools-menu" [ngClass]="{'selected-car': carService?.selectedCar}"
             *ngIf="configService.showExternalTools">
            <span class="title">{{'CLUB_ADMIN.EXTERNAL_TOOLS.TITLE' | translate}}</span>
            <app-external-tools-wrapper [position]="'header'"></app-external-tools-wrapper>
        </div>

        <div class="eject_icon"
             *ngIf="carService?.selectedCar && (!dmsDataService.isCatalogMode() || dmsDataService.isCatalogMode() && getCartCreationBehavior()?.value !== 'PER_CAR')"
             (click)="navigateToCarSelection()">
            <div class="toolTipDiv" triggers="mouseenter:mouseleave" [openDelay]="tooltipDelay"
                 [ngbPopover]="'CAR_HISTORY.CAR_EJECT' | translate" container="body">
                <i class="fa fa-eject clickable eject" style="color: white"></i>
            </div>
        </div>
    </div>
</div>
