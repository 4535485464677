import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../../shared/services/user/user.service';
import {GlobalAdminService} from '../../services/global-admin.service';
import {HelperService} from '../../../../shared/services/helper.service';

@Component({
    selector: 'app-grip-order-row',
    templateUrl: './grip-order-row.component.html',
    styleUrls: ['./grip-order-row.component.scss']
})
export class GripOrderRowComponent implements OnInit {

    constructor(public userService: UserService,
                public globalAdminService: GlobalAdminService,
                public helperService: HelperService) {
    }

    ngOnInit() {

    }


    public onEnd() {
        if (this.globalAdminService.gripsOrderList && this.globalAdminService.gripsOrderList.totalElements > 0) {
            return this.userService.enableInfiniteScroll && (this.globalAdminService.gripsOrderList.totalElements / 20)
                <= (this.globalAdminService.gripsOrderList.page + 1) && !this.globalAdminService.infinityLoading;
        } else {
            return false;
        }
    }
}
