import {Component, OnInit} from '@angular/core';
import {GlobalAdminService} from '../../services/global-admin.service';
import {UserService} from '../../../../shared/services/user/user.service';
import {Router} from '@angular/router';
import {ArticlesService} from '../../../../articles/services/articles.service';
import {HelperService} from '../../../../shared/services/helper.service';

@Component({
    selector: 'app-global-order-data',
    templateUrl: './global-order-data.component.html'
})
export class GlobalOrderDataComponent implements OnInit {

    constructor(public globalAdminService: GlobalAdminService,
                public userService: UserService,
                public router: Router,
                public articlesService: ArticlesService,
                public helperService: HelperService) {

        let routerUrl: string = this.router.url;
        this.globalAdminService.getOrderDetails(routerUrl.slice(routerUrl.lastIndexOf('/') + 1));
    }

    ngOnInit() {
    }


    public getFormattedSupplierName(name: string): string {
        let suppName = '';
        Object.keys(this.globalAdminService.orderDetails.supplierInformation).map(k => {
            if (name == k) {
                suppName = this.globalAdminService.orderDetails.supplierInformation[k].supplierName;
            }
        });
        return suppName
    }

    public getDeliveryCostsKeys() {
        if (this.globalAdminService.orderDetails && this.globalAdminService.orderDetails.cartCostDTO
            && this.globalAdminService.orderDetails.cartCostDTO.deliveryCosts && !this.userService.sale) {
            return Object.keys(this.globalAdminService.orderDetails.cartCostDTO.deliveryCosts);
        } else {
            return [];
        }
    }

}
