import {Component, OnInit, Input, EventEmitter, Output, OnChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';

@Component({
    selector: 'app-cart-amount-input',
    templateUrl: './cart-amount-input.component.html',
    styleUrls: ['./cart-amount-input.component.scss']
})
export class CartAmountInputComponent implements OnInit, OnChanges {
    @Input() public initialAmount: number;
    @Input() public deactivate: boolean;
    @Input() public icon: string;
    @Input() public floatValue: boolean;
    @Input() public showCurrency: boolean | false;
    @Output() private amountChanged: EventEmitter<number> = new EventEmitter<number>();
    @Output() private onMouseClick: EventEmitter<number> = new EventEmitter<number>();
    public amount: any;
    public newAmount: number;

    constructor(private helperService: HelperService) {}

    ngOnInit() {
        if (this.initialAmount) {
            this.amount = this.initialAmount;
        }else{
            this.amount = 0;
        }
    }

    ngOnChanges(changes: any) {
        if (changes.initialAmount && !changes.initialAmount.firstChange ) {
            this.amount = changes.initialAmount.currentValue;
        }

        if (changes.deactivate && !changes.deactivate.firstChange) {
            this.deactivate = changes.deactivate.currentValue;
        }
    }

    public getSymbol() {
        return this.helperService.getCurrencySymbol(undefined);
    }

    public increaseAmount() {
        this.amount += 1;
        this.onAmountChange();
    }

    public decreaseAmount() {
        if (this.amount >= 1) {
            this.amount -= 1;
            this.onAmountChange();
        }
    }

    public onMouseClickChange() {
        this.amount = this.amount.toString().replace(',', '.');
        this.amount = Number.parseFloat(this.amount);
        this.amountChanged.emit(this.amount);
    }

    private onAmountChange() {
        this.amount = this.amount.toString().replace(',', '.');
        this.amount = Number.parseFloat(this.amount);
        this.amountChanged.emit(this.amount);
    }

}
