import {Component, OnInit} from '@angular/core';
import {TabComponent} from "./tab/tab.component";

@Component({
    selector: 'app-tabset',
    templateUrl: 'tabset.component.html',
    styleUrls: ['tabset.component.scss']
})
export class TabsetComponent implements OnInit {
    public tabs: TabComponent[] = [];
    private selectedTab: TabComponent;

    constructor() {
    }

    ngOnInit() {
    }

    addTab(tab: TabComponent) {
        let me = this;

        if (me.tabs.length === 0) {
            tab.active = true;
            me.selectedTab = tab;
        }
        me.tabs.push(tab);
    }

    selectTab(tab: TabComponent) {
        let me = this;

        me.tabs.forEach((tab) => {
            tab.active = false;
        });
        tab.active = true;
        me.selectedTab = tab;
    }

}
