<div class="login-wrapper">
    <h2>Impressum</h2>
    <h3><em>Angaben gemäß § 5 TMG:</em></h3>
    <p>N4 – Eine Unternehmensgruppe bestehend aus:<br>
        netRapid GmbH &amp; Co. KG<br>
        n-Systems GmbH &amp; Co. KG<br>
        nexnova Business GmbH &amp; Co. KG<br>
        nexnova Software GmbH &amp; Co. KG<br>
        N4 Leipzig GmbH<br><br>
        Kaiserstraße 95-101a<br>
        66133 Saarbrücken</p>
    <p>&nbsp;</p>
    <h3>Vertreten durch:</h3>
    <p>vertreten durch die jeweiligen Komplementärinnen-GmbHs (netRapid Geschäftsführungs GmbH, n-Systems
        Geschäftsführungs GmbH, nexnova Geschäftsführungs GmbH), die ihrerseits vertreten werden durch ihren
        Geschäftsführer Mesut Cengiz</p>
    <p>&nbsp;</p>
    <h3><em></em>Datenschutzbeauftragter:</h3>
    <p>Rechtsanwalt Stefan Wiesen | Mainzer Straße 161 | 66121 Saarbrücken</p>
    <p>&nbsp;</p>
    <h3>Kontakt:</h3>
    <p>phone: +49 681 / 638 97 - 0<br>
        email: info@n4.de</p>
    <p>&nbsp;</p>
    <h3>Registereintragungen:</h3>
    <p><strong>netRapid GmbH &amp; Co. KG</strong><br>
        Amtsgericht Saarbrücken HRA 10590 / USt.-ID-Nr.: DE261836569</p>
    <p><strong>n-Systems GmbH &amp; Co. KG</strong><br>
        Amtsgericht Saarbrücken, HRA 10714 / USt.-ID-Nr.: DE274485223</p>
    <p><strong> NEXNOVA Business Solutions GmbH &amp; Co. KG</strong><br>
        Amtsgericht Saarbrücken, HRA 11076 / USt.-ID-Nr.: DE278352925</p>
    <p><strong>NEXNOVA Software Solutions GmbH &amp; Co. KG</strong><br>
        Amtsgericht Saarbrücken, HRA 11075 / USt.-ID-Nr.: DE279005927</p>
    <p><strong>N4 Leipzig GmbH</strong><br>
        Amtsgericht Leipzig, HRB 35642 / USt.-ID-Nr.: DE321416615</p>
</div>
<app-auth-footer></app-auth-footer>
