<div class="assenbly">
    <div class="assembly-categories" [@catAnim]="articlesService.articlesSearchResult == undefined">
        <app-default-loading-page *ngIf="assemblyCategoriesService.loadingAssemblyCategories"></app-default-loading-page>

        <ng-container *ngIf="!(assemblyCategoriesService.selectedAssemblyCategory)">

            <!-- Grafische Teilesuche -->
            <div class="assembly-category" *ngIf="carService.getKTypeId() > 0 && userService.bodygraphicsType !== 'NONE'"
                routerLink="/graphical-part-search">

                <div class="name-wrapper">
                    {{ 'AUTOVISTA.TITLE' | translate }}
                </div>
                <div class="image-wrapper">
                    <div class="center-helper"></div>
                    <span class="fa fa-car"></span>
                </div>
                <div class="image-wrapper-hover">
                    <div class="center-helper"></div>
                    <span class="fa fa-car"></span>
                </div>
            </div>

            <div  class="assembly-category inactive"
                  *ngIf="carService.getAdcTypeId() === 0 && userService?.bodygraphicsType === 'NONE'">

                <div class="name-wrapper">
                    {{'SPAREPARTS_VIEW.BODYGRAPHICS' | translate}}
                </div>
                <div class="image-wrapper">
                    <div class="center-helper"></div>
                    <span class="fa fa-car"></span>
                </div>
                <div class="image-wrapper-hover">
                    <div class="center-helper"></div>
                    <span class="fa fa-car"></span>
                </div>
            </div>
            <div *ngFor="let category of assemblyCategoriesService.assemblyCategories" class="assembly-category"
                 (click)="selectCategory(category)"
                 [ngClass]="{ selected: isCategorySelected(category), inactive: !(category.active) }">

                <div class="name-wrapper">
                    {{ category.name }}
                </div>
                <div class="image-wrapper" [ngClass]="{'body-graphic': category.id === 2}">
                    <div class="center-helper"></div>
                    <span class="icon-{{ category.id }}"></span>
                    <!--<img src="assets/images/categories/{{ category.id }}.svg"/>-->
                </div>
                <div class="image-wrapper-hover" [ngClass]="{'body-graphic': category.id === 2}">
                    <div class="center-helper"></div>
                    <span class="icon-{{ category.id }}"></span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="assemblyCategoriesService?.selectedAssemblyCategory && articlesService.articlesSearchResult === undefined">
            <div *ngFor="let item of articlesService.articleTree">
                <div *ngIf="assemblyCategoriesService.selectedAssemblyCategory.articleCategoryIds.indexOf(item.id) > -1 || treeFilterByChild(item, assemblyCategoriesService.selectedAssemblyCategory)">
                    <h1><strong>{{item.name}}</strong></h1>
                    <div class="assembly-categories">
                        <div class="assembly-category" *ngFor="let subItem of item.children" (click)="articlesService.selectTreeItem(subItem); subItem.open = true;">
                            <div class="name-wrapper-no-image">
                                {{subItem.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
