import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../../../shared/services/user/user.service';
import {HelperService} from '../../../../shared/services/helper.service';
import {OrderHistoryService} from '../services/order-history.service';
import {GlobalAdminService} from '../../../global/services/global-admin.service';

@Component({
    selector: 'app-order-item',
    templateUrl: './order-item.component.html'
})
export class OrderItemComponent implements OnInit {

    @Input() public item: any;
    @Input() public orderList?: any;

    constructor(public userService: UserService,
                public globalAdminService: GlobalAdminService,
                public helperService: HelperService,
                public orderHistoryService: OrderHistoryService) { }

    ngOnInit() {
    }

}
