import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {ICartPosition} from '../../../cart/interfaces/cart';
import {CarService} from '../../services/car/car.service';
import {CartRestService} from '../../../cart/services/cart.rest.service';
import {CartService} from '../../../cart/services/cart.service';
import {ArticlesRestService} from '../../../articles/services/articles.rest.service';
import {UserService} from '../../services/user/user.service';
import {HelperService} from '../../services/helper.service';
import {Subscription} from 'rxjs';
import {ICalculationCost} from '../../interfaces/DTO/calculationCost';
import {ArticlesService} from "../../../articles/services/articles.service";
import {IItem} from "../../../articles/interfaces/article";

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked, OnDestroy {

    @Input() service: any;
    @Input() articleList: any;

    @Output() showScrollButtonEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('viewport', {static: true}) viewPort: ElementRef;

    @ViewChild('tabList', {static: true}) tabList: ElementRef;

    public currentPseudoarticle: IItem;
    public availability: any;
    public loadAvailability: boolean = false;
    public interval: any;
    public offset: number = 0;
    public selectedTab: any;
    public showScrollButtonBool: boolean = false;
    public byWorkIdTimer: number;
    public placeholderLoading: boolean = false;
    public loadingCalculationData: boolean | false;
    public requestedQuantity: number = null;
    private getPlaceholderCalculationSubscription: Subscription;
    private readonly THRESHOLD: number = 40;
    public countSmallerThreshold: boolean = true;
    public scrollEnd: boolean = false;
    private buttonResortScrollEndEventSubscription: Subscription;

    constructor(public carService: CarService,
                private cartRestService: CartRestService,
                public cartService: CartService,
                private articleRestService: ArticlesRestService,
                public userService: UserService,
                private changeDetectorRef: ChangeDetectorRef,
                public articlesService: ArticlesService,
                public helperService: HelperService) {
    }

    ngOnInit() {
        this.articlesService.currentPseudoArticle$.subscribe(article => {
            this.currentPseudoarticle = article;
        })

        this.buttonResortScrollEndEventSubscription = this.articlesService.scrollEndEmitter.subscribe((scrollEnd: boolean) => {
            this.scrollEnd = scrollEnd;
        });

        this.setSortBtn();
    }

    ngOnDestroy() {
        this.articlesService.currentPseudoArticle$.unsubscribe();
        this.buttonResortScrollEndEventSubscription.unsubscribe();
    }

    ngOnChanges(changes: any) {
        if (changes.articleList) {
            setTimeout(() => {
                this.placeholderLoading = false;
                this.loadingCalculationData = false;
                this.goToNextTab();
            }, 50);
        }
    }

    ngAfterViewInit() {
        this.goToNextTab();
        if (this.tabList.nativeElement.lastChild.getBoundingClientRect === 'function') {
            if (!this.isVisible(this.tabList.nativeElement.lastChild)) {
                this.offset += 10;
                this.scroll(false);
            }
        }
    }

    ngAfterViewChecked() {
        const selectedTab = this.service.selectedTab;
        if (selectedTab !== this.selectedTab) {
            this.selectedTab = selectedTab;
            if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    public addPlaceholderToCart() {
        if (!this.getSelectedPosition()) {
            this.addPlaceholderArticleToCart(this.service.selectedTab)
        } else {
            this.cartRestService.deletePos(this.getSelectedPosition().posId, 'DELETE_WITH_DEPENDENT').subscribe(
                (success) => {
                    this.addPlaceholderArticleToCart(this.service.selectedTab, true);
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_CART_POS', 'error');
                }
            );
        }
    }

    public showPseudoArticleDetails(pseudoArtikel: any) {
        this.userService.navigationHelper = 'right';
        if (!this.service.selectedArticle || this.service.selectedArticle.id !== pseudoArtikel.id) {
            this.service.expanded_right = !this.service.expanded_right;
            let dropCar = false;
            if ((pseudoArtikel.kTypeId === 0 || pseudoArtikel.adcTypeId === 0) && this.carService.selectedCar) {
                pseudoArtikel.kTypeId = this.carService.selectedCar.kTypeId;
                pseudoArtikel.adcTypeId = this.carService.selectedCar.adcTypeId;
                dropCar = true;
            }
            this.service.updateArticleDetails(pseudoArtikel.id, pseudoArtikel.kTypeId, pseudoArtikel.adcTypeId, pseudoArtikel, dropCar);
        }
    }

    public goBackToSearch() {
        this.service.selectedPseudoArticle = undefined;
        this.service.showPseudoArticle = false;
        this.service.hidePartsListPseudo = false;
        this.searchArticle();
    }

    private hasPartAmounts(article: any) {
        return article
            && article.partSets
            && article.partSets.length > 0
            && article.partSets[0].parts.length > 0
            && article.partSets[0].parts[0].partAmounts.length > 0;
    }

    public addPlaceholderArticleToCart(article: any, replace: boolean = false) {
        let quantityUnit = 'PIECE';

        if (this.service.calculationCostHolder
            && this.service.calculationCostHolder.get(this.service.selectedTab.mountPositionId)
            && this.service.calculationCostHolder.get(this.service.selectedTab.mountPositionId)) {
            quantityUnit = this.service.calculationCostHolder.get(this.service.selectedTab.mountPositionId).unit;
        }

        if (this.hasPartAmounts(article)) {
            this.placeholderLoading = true;
            const placeholder = {
                description: article.mountPositionText,
                quantity: 1,
                quantityUnit: quantityUnit,
                kTypeId: this.carService.getKTypeId(),
                adcTypeId: this.carService.getAdcTypeId(),
                itemMpId: article.mountPositionId,
                workId: article.workId,
                requestQuantity: this.service.selectedAmount.amount ? this.service.selectedAmount.amount : 1,
                sellOutNetPerPiece: article.partSets[0].parts[0].price ? article.partSets[0].parts[0].price : 0,
                quantityDescription: '',
                articleNo: '',
                vin: this.carService.getVin(),
                producerName: this.carService.getCarProducerName(),
                allowCalculatedPriceChange: (this.service.calculationCostHolder.get(article.mountPositionId) ? this.service.calculationCostHolder.get(article.mountPositionId).allowCustomerPrice : true),
                uniqueCalculationId: this.service.calculationCostHolder.get(article.mountPositionId) ? this.service.calculationCostHolder.get(article.mountPositionId).uniqueCalculationId : -1
            };

            let quantityType: any = '';
            if (article.partSets[0].parts[0].partAmounts[0].quantity.length > 0 && !(this.cartService.cartCount.calculationData)) {
                quantityType = article.partSets[0].parts[0].partAmounts[0].quantity;

                placeholder.sellOutNetPerPiece = placeholder.sellOutNetPerPiece * placeholder.requestQuantity;

                placeholder.description = placeholder.requestQuantity + ' ' + quantityType + ' ' + placeholder.description;
            } else {
                placeholder.quantity = placeholder.requestQuantity;
            }
            placeholder.vin = this.carService.getVin();
            placeholder.producerName = this.carService.getCarProducerName();

            this.cartRestService.addPlaceholder(placeholder).subscribe(
                (cartPositionId: number) => {
                    this.cartService.updateCart(false, false, false, false);
                    this.placeholderLoading = false;
                    if (!replace) {
                        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_ADD_TO_CART', 'success');
                    } else {
                        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_REPLACE_ARTICLE', 'success');
                    }
                },
                (error) => {
                    this.placeholderLoading = false;
                    if (!replace) {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_ADD_TO_CART', 'error');
                    } else {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_REPLACE_ARTICLE', 'error');
                    }
                });
        }
    }

    public searchArticle(requestQuantity?: any) {

        if (this.service.searchResult) {
            this.service.searchResult.activeFilters = [];
        }

        const articles: any = {};
        articles.requestQuantity = requestQuantity;
        articles.kTypeId = this.carService.getKTypeId();
        articles.adcTypeId = this.carService.getAdcTypeId();
        articles.itemMpId = this.service.selectedTab.mountPositionId;
        articles.linkingTargetType = 'P';
        articles.pageSize = 20;
        articles.page = 1;
        articles.workId = this.service.selectedTab.workId;
        articles.activeFilters = [];

        const aktTime = new Date().getTime();
        if (!this.byWorkIdTimer || aktTime - this.byWorkIdTimer > 500) {
            this.byWorkIdTimer = aktTime;
            this.service.updateArticlesByWorkId(articles, 1, undefined, this.selectedTab);
        }
        this.service.resultItems = [];
    }

    public getSelectedPosition(): ICartPosition {
        if (this.service.selectedTab) {
            return this.service.selectedTab.partSets[0].parts[0].position;
        } else {
            return undefined;
        }
    }

    public showPlaceholderCalculation(tab: any) {
        if (!tab) {
            return false;
        }

        return this.service.calculationCostHolder
            && this.service.calculationCostHolder.get(tab.mountPositionId)
            && this.service.calculationCostHolder.get(tab.mountPositionId) !== null;
    }

    private getPlaceholderCalculation(tab: any) {
        if (tab && !this.showPlaceholderCalculation(tab)) {
            this.loadingCalculationData = true;
            this.getPlaceholderCalculationSubscription =
                this.articleRestService.getPlaceholderCalculationRest(this.carService.selectedCar.adcTypeId, tab.workId, tab.mountPositionId).subscribe(
                    (calculationCost: ICalculationCost) => {
                        this.loadingCalculationData = false;
                        this.service.calculationCostHolder.set(tab.mountPositionId, calculationCost);
                    }, (error) => {
                        this.loadingCalculationData = false;
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET', 'error');
                    });
        }
    }

    private setSortBtn(): void {
        this.countSmallerThreshold = false;
        // leider nicht die beste Lösung, durch die abstracten Services lässt sich der searchService nicht importieren, sodass ich dort auf etwas reagieren könnte
        setTimeout(() => {
            const interval: ReturnType<typeof setInterval> = setInterval(() => {
                if (this.service?.searchResult?.count) {
                    this.countSmallerThreshold = this.service?.searchResult?.count <= this.THRESHOLD;
                    clearInterval(interval);
                }
            }, 250);
        }, 2500)
    }

    public checkSortBtn(): void {
        // nachdem der Tab gewechselt wurde, wird der disabled Button fürs sortieren geprüft
        this.setSortBtn();
    }

    public setTab(tab: any, tabElement, requestQuantity?: any) {
        if (tab.partSets && tab.partSets.length > 0 && tab.partSets[0].parts && tab.partSets.length > 0
            && tab.partSets[0].parts[0].partAmounts && tab.partSets[0].parts[0].partAmounts.length > 0) {
            this.service.selectedAmount = tab.partSets[0].parts[0].partAmounts[0];
        }
        if (this.getPlaceholderCalculationSubscription) {
            this.getPlaceholderCalculationSubscription.unsubscribe();
        }
        if (this.cartService && this.cartService.cartCount && this.cartService.cartCount.calculationData) {
            this.getPlaceholderCalculation(tab);
        }
        this.requestedQuantity = requestQuantity;
        this.service.selectedTab = tab;
        this.service.selectFirstTabEmitter.emit();
        this.moveToView(tabElement);
        this.searchArticle(requestQuantity);
        this.availability = undefined;

        if (this.hasPartAmounts(this.service.selectedTab) && this.service.selectedTab.partSets[0].parts[0].position !== null && this.getSelectedPosition().type !== 'PLACEHOLDER') {
            this.loadAvailability = true;
            const temp = {
                articleIds: [this.getSelectedPosition().articleId],
                supplier: this.userService.getRegularSupplierName(this.getSelectedPosition().supplier)
            };
            if (temp.supplier) {
                this.articleRestService.getAvailabilities(temp).subscribe((response: any) => {
                        this.availability = response[0].schedule;
                        this.loadAvailability = false;
                    },
                    (error) => {
                        this.loadAvailability = false;
                    });
            } else {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_SUPPLIER', 'error');
            }
        }
        this.articlesService.isInPseudoArticleView$.next(false);
        this.helperService.tabChanged.next(true);
    }

    public getNextTab(right: boolean) {
        for (let i = 0; i < this.service.articleList.length; i++) {
            if (this.service.articleList[i].mountPositionId === this.service.selectedTab.mountPositionId) {
                if (right && (i + 1) < this.service.articleList.length && this.hasPartAmounts(this.service.articleList[i + 1])) {
                    this.setTab(this.service.articleList[i + 1], this.tabList.nativeElement.childNodes[i + 1], this.service.articleList[i + 1].partSets[0].parts[0].partAmounts[0]);
                    return;
                }
                if (!right && i - 1 >= 0 && this.hasPartAmounts(this.service.articleList[i - 1])) {
                    this.setTab(this.service.articleList[i - 1], this.tabList.nativeElement.childNodes[i - 1], this.service.articleList[i - 1].partSets[0].parts[0].partAmounts[0]);
                    return;
                }
            }
        }
    }

    public showScrollButton() {
        const temp = this.tabList.nativeElement.getBoundingClientRect().width >= this.viewPort.nativeElement.getBoundingClientRect().width;
        if (temp !== this.showScrollButtonBool) {
            this.showScrollButtonBool = temp;
            this.showScrollButtonEmitter.emit(this.showScrollButtonBool);
            if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        }

        return this.showScrollButtonBool;
    }

    public scroll(scrollRight: boolean) {
        if (scrollRight) {
            this.tabList.nativeElement.style.transform = `translate(${-this.offset}px)`;
        } else {
            this.tabList.nativeElement.style.transform = `translate(${-this.offset}px)`;
        }
    }

    public isVisible(element: Element) {
        if (element && element.scrollHeight && element.scrollWidth) {
            const boundingElement = element.getBoundingClientRect();
            const boundingViewport = this.viewPort.nativeElement.getBoundingClientRect();
            return (boundingElement.right) <= (boundingViewport.right)
                && boundingElement.left >= (boundingViewport.left);
        }

        return false;
    }

    public moveToView(element: Element) {
        if (!this.isVisible(element)) {
            if (element && element.scrollHeight && element.scrollWidth) {
                const boundingElement = element.getBoundingClientRect();
                const boundingViewport = this.viewPort.nativeElement.getBoundingClientRect();

                if (boundingElement.right >= boundingViewport.right) {
                    this.offset -= ((boundingViewport.right) - boundingElement.right);
                    this.scroll(true);
                }

                if (boundingElement.left <= (boundingViewport.left)) {
                    this.offset -= ((boundingViewport.left) - boundingElement.left);
                    this.scroll(false);
                }
            }
        }

    }

    public goToNextTab() {
        if (this.service && this.service.articleList) {
            for (let i = 0; i < this.service.articleList.length; i++) {
                if (this.hasPartAmounts(this.service.articleList[i]) && this.service.articleList[i].partSets[0].parts[0].position === null) {
                    this.service.showModal = false;
                    this.setTab(this.service.articleList[i], this.tabList.nativeElement.childNodes[i + 1],
                        this.service.articleList[i].partSets[0].parts[0].partAmounts[0]);
                    return;
                }
            }

            if (this.hasPartAmounts(this.service.articleList[0]) && !this.service.selectedTab && this.service.articleList && this.service.articleList.length > 0) {
                this.setTab(this.service.articleList[0], this.tabList.nativeElement.childNodes[1],
                    this.service.articleList[0].partSets[0].parts[0].partAmounts[0]);
                this.service.showModal = true;
            } else {

                for (let i = 0; i < this.service.articleList.length; i++) {
                    if (this.service.articleList[i].mountPositionId === this.service.selectedTab.mountPositionId) {
                        this.setTab(this.service.articleList[i], this.tabList.nativeElement.childNodes[i + 1],
                            this.service.articleList[i].partSets[0].parts[0].partAmounts[0]);
                        this.service.showModal = true;
                        return;
                    }
                }
            }

        }
    }
}
