<div class="maintenance-data">
    <div class="heading blue">
        <span routerLink="/maintenance" class="clickable" (click)="maintenanceService.lastState = ''"><i class="fa fa-th" aria-hidden="true"></i></span>
        <span routerLink="/maintenance" class="clickable">{{'CLUB_SETTINGS.NAVIGATION.MAINTENANCE' | translate}}</span>
        <span routerLink="/maintenance/info" class="clickable"><i class="fa fa-caret-right"></i>{{'MAINTENANCE.INFO' | translate}}</span>
    </div>

</div>
<div class="panel-content center-content">
    <ng-container>
        <div class="assembly-categories">
            <div class="assembly-category" routerLink="/maintenance/info/manufacturer" *ngIf="maintenanceService.manualUrl">
                <div class="name-wrapper-no-image">
                    <span>{{ 'MAINTENANCE.MANUFACTURER_INFO' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" *ngIf="!maintenanceService.manualUrl"
                 tooltip
                 tooltipPosition="center"
                 tooltipContent="{{'CAR_HISTORY.TOOLTIP.NO_DATA' | translate}}">
                <div class="name-wrapper-no-image noData">
                    <span>{{'MAINTENANCE.MANUFACTURER_INFO' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" routerLink="/maintenance/info/specifications">
                <div class="name-wrapper-no-image">
                    <span>{{ 'MAINTENANCE.MANUFACTURER_SPEC' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" routerLink="/maintenance/info/change-interval">
                <div class="name-wrapper-no-image">
                    <span>{{ 'MAINTENANCE.CHANGE_INTERVAL' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" routerLink="/maintenance/info/service">
                <div class="name-wrapper-no-image">
                    <span>{{ 'MAINTENANCE.SERVICEPLAN' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" routerLink="/maintenance/info/warnings">
                <div class="name-wrapper-no-image">
                    <span>{{ 'CAR_HISTORY.WARNING.TITLE' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" *ngIf="configService.isActiveTool('DIGITAL_SERVICEBOOK') && carService?.selectedCar?.digitalServiceBook" (click)="carService.openServiceBook()">
                <div class="name-wrapper-no-image">
                    <span>{{ 'CART.SERVICEBOOK' | translate}}</span>
                </div>
            </div>
        </div>
    </ng-container>
</div>
