import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {CartRestService} from "../services/cart.rest.service";
import {finalize} from "rxjs/operators";
import {IRawTool} from "../interfaces/raw-tool";
import {CartService} from "../services/cart.service";
import {Subscription} from "rxjs";
import {CarService} from "../../shared/services/car/car.service";

@Component({
    selector: 'app-raw-tools',
    templateUrl: './raw-tools.component.html',
    styleUrls: ['./raw-tools.component.scss']
})
export class RawToolsComponent implements OnInit, OnDestroy {
    public loading: boolean = false;
    public rawTools: IRawTool[];
    private subscriptions: Subscription [] = [];

    constructor(private route: ActivatedRoute,
                private carService: CarService,
                private cartService: CartService,
                private cartRestService: CartRestService) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.subscriptions.push(
            this.route.queryParams
                .subscribe(params => {
                    if (params && params.adcTypeId) {
                        this.subscriptions.push(
                                this.cartRestService.getRawTools(params.adcTypeId).pipe(finalize(() => this.loading = false)).subscribe(
                                    (tools: IRawTool[]) => {
                                        this.rawTools = tools;
                                        this.cartService.rawTools = tools;
                                    }
                                ));
                        } else {
                            this.cartService.rawTools = [];
                            this.loading = false;
                        }
                    }
                ));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe()
        });
    }
}
