<div class="userdata" *ngIf="userService && userService.accountData">
    <div class="heading blue">
         <span class="clickable"  routerLink="/company-admin">
             <i class="fa fa-th" aria-hidden="true"></i>
             {{'ADMIN_PANEL.OVERVIEW' | translate}}
         </span>
        <span class="clickable" routerLink="/company-admin/profile">
            <i class="fa fa-caret-right"></i>
            {{'USER_PROFILE.MY_PROFILE' | translate}}
        </span>
    </div>
    <div class="panel-content center-content white-background">
        <app-user-password
                (onPasswordChange)="changeUserPW($event)"
                [userName]="userService.accountData.userName">
        </app-user-password>
    </div>
</div>
