import {Component, OnInit} from '@angular/core';
import {ConfigService} from '../../../../shared/services/config.service';

@Component({
    selector: 'app-cart-limit-config',
    templateUrl: './cart-limit-config.component.html',
    styleUrls: ['./cart-limit-config.component.scss']
})
export class CartLimitConfigComponent implements OnInit {

    constructor(public configService: ConfigService) {
    }

    ngOnInit() {
    }

    public getCartLimits() {
        const limitList = [];
        const setting  = this.configService.getSettingByName('CART_LIMIT');
        if (setting) {
            for (const subSetting of setting.settings) {
                limitList.push(subSetting.name);
            }
        }
        return limitList;
    }

    public getActiveForName(name: string) {
        const setting = this.configService.getSubSettingByName('CART_LIMIT', name);
        if (setting) {
            return setting.active;
        }
    }

    public setActiveForName(name: string, active: boolean) {
        const setting = this.configService.getSubSettingByName('CART_LIMIT', name);
        if (setting) {
            this.configService.addUnsavedSetting(this.configService.selectedSettingName);
            setting.active = active;
        }
    }

    public hasUnitForName(name: string) {
        const setting = this.configService.getSubSettingByName('CART_LIMIT', name);
        if (setting) {
            if (setting.unit) {
                return true;
            }
        }
        return false;
    }

    public getUnitForName(name: string) {
        const setting = this.configService.getSubSettingByName('CART_LIMIT', name);
        if (setting) {
            return setting.unit;
        }
        return false;
    }

    public setUnitForName(name: string, unit: string) {
        const setting = this.configService.getSubSettingByName('CART_LIMIT', name);
        if (setting) {
            this.configService.addUnsavedSetting(this.configService.selectedSettingName);
            setting.unit = unit;
        }
    }

    public getUnitListForName(name: string) {
        const setting = this.configService.getSubSettingByName('CART_LIMIT', name);
        if (setting) {
            return setting.unitList;
        }
    }

    public getAmountForName(name: string) {
        const setting = this.configService.getSubSettingByName('CART_LIMIT', name);
        if (setting) {
            return setting.amount.toString();
        }
    }

    public setAmountForName(name: string, amount: string) {
        const setting = this.configService.getSubSettingByName('CART_LIMIT', name);
        if (setting) {
            this.configService.addUnsavedSetting(this.configService.selectedSettingName);
            setting.amount = parseInt(amount);
        }
    }

}
