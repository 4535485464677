<div class="external-tools">
    <div class="heading blue">
    <span class="clickable">
            <i class="fa fa-th" aria-hidden="true"></i>
        </span>
        <span>{{'CLUB_ADMIN.EXTERNAL_TOOLS.TITLE' | translate}}</span>
    </div>
    <div class="panel-content center-content white-background">
        <div class="club-setting-title">
            <h1>
                <strong>
                    {{'CLUB_ADMIN.EXTERNAL_TOOLS.TITLE' | translate}}
                </strong>
            </h1>
        </div>

        <ng-container *ngIf="configService.getExternalToolsList()?.length > 0">
            <div class="list-wrapper drag-list" cdkDropList (cdkDropListDropped)="configService.sortList(configService.getExternalToolsList(), $event); configService.addUnsavedSetting('EXTERNAL_TOOLS')">
                <div class="row drag-box" *ngFor="let tool of configService.getExternalToolsList()" [ngClass]="{'selected': tool === globalAdminService.selectedExternalTool}" cdkDrag (click)="globalAdminService.selectedExternalTool = tool">
                    <div class="col-8">{{'EXTERNAL_TOOLS.' + tool.name | translate}}</div>
                    <div class="col-2">
                        <app-checkbox [isChecked]="tool.optional" [labelText]="'CLUB_ADMIN.OPTIONAL' | translate" (onChange)="tool.optional = $event; configService.addUnsavedSetting('EXTERNAL_TOOLS')"></app-checkbox>
                    </div>
                    <div class="col-2">
                        <app-checkbox [isChecked]="tool.active" [labelText]="'CLUB_ADMIN.ACTIVE' | translate" (onChange)="tool.active = $event; configService.addUnsavedSetting('EXTERNAL_TOOLS')"></app-checkbox>
                    </div>
                </div>
            </div>
        </ng-container>

        <button class="base-btn success" *ngIf="configService.hasUnsavedSettings('EXTERNAL_TOOLS')" (click)="save()">
            <i class="fa fa-check mr-2"></i>
            <span>{{'CLUB_ADMIN.PARTNER_MANAGEMENT.SAVE' | translate}}</span>
        </button>

    </div>
</div>
