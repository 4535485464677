import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CarRestService} from '../../../services/car/car.rest.service';
import {CarService} from '../../../services/car/car.service';
import {HelperService} from '../../../services/helper.service';
import {ICar} from '../../../interfaces/DTO/car/car';
import {CustomerDataService} from '../../../services/customerData.service';
import {UserService} from "../../../services/user/user.service";

@Component({
    selector: 'app-car-by-vin',
    templateUrl: './car-by-vin.component.html'
})
export class CarByVinComponent implements OnInit, OnChanges {
    @Output() applyCars: EventEmitter<ICar[]> = new EventEmitter();
    @Input() _vin: string;
    public text: any;

    constructor(private carRestService: CarRestService,
                public carService: CarService,
                private userService: UserService,
                public helperService: HelperService,
                private customerDataService: CustomerDataService) {
        this.carService.changeVin$.subscribe(vin => {
            this._vin = vin;
        })
    }

    ngOnInit() {
        if (this.carService.getVin().length > 0) {
            this._vin = this.carService.getVin();
        } else {
            this._vin = this.customerDataService.getVin();
        }
    }

    ngOnChanges() {
        this.carService.selected = 'KBA_NUMBER';
    }

    public onVinSearch() {
        this.carService.loadCar = 'VIN';
        this.carRestService.getCarsByVIN(this.carService.replaceVin(this._vin)).subscribe(
            (response: ICar[]) => {
                this.carService.loadCar = undefined;
                this.carService.fromDMS = false;
                this.applyCars.emit(response);
            },
            (error) => {
                if (error.status === 409) {
                    this.carService.handleCarConflict();
                } else {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                }
                this.carService.loadCar = undefined;
            }
        );
    }
}
