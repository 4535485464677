<div class="partner">

  <div class="content" *ngIf="(stateService.partnerContent | async) === 'LOCKED'">
    <div class="col-12 no-float">
      <div class="heading blue searchable" style="margin-left: 0px;">
        <div class="text">{{'LOCKED.TITLE' | translate}}</div>
      </div>
      <div class="panel-content center-content white-background">
        <div class="inner-content">
          <div class="row">
            <div class="col-3"></div>
            <div class="col-6">
              <app-locked></app-locked>
            </div>
            <div class="col-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sidebar-opener-wrapper" *ngIf="(stateService.partnerContent | async) === 'CONTENT'">
    <div class="sidebar-opener"></div>
  </div>
  <div class="left-panel no-float no-padding">
    <div class="panel-content left-sidebar tree-sidebar">
      <div class="inner-content no-padding">
        <ul class="custom-tree">
          <li class="list" *ngFor="let pageType of partnerService.getPartnerPageKeys()">
            <div class="clickable" (click)="selectType(pageType)">
              <i class="folder fa fa-circle" [ngClass]="{'active': selectedPageType === pageType}"></i>
            </div>
            <span (click)="selectType(pageType)">{{ 'PARTNER.' + pageType | translate}}</span>
            <ul class="custom-tree" *ngIf="pageType !== 'URL'">
              <li class="list" *ngFor="let page of partnerService.partnerPages.partnerPages[pageType]">
                <div class="clickable" (click)="openLink(page.url)">
                  <i class="folder fa fa-circle"></i>
                </div>
                <span (click)="openLink(page.url)">{{ page.text }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="center-panel no-float">
    <div class="heading blue">
      <i class="fa fa-th clickable" (click)="searchString = ''"></i>
      <span class="clickable" (click)="searchString = ''" *ngIf="!(partnerService.getPartnerDisplayName()?.length > 0)">{{'PARTNER.TITLE' | translate}}</span>
      <span class="clickable" (click)="searchString = ''" *ngIf="partnerService.getPartnerDisplayName()?.length > 0">{{partnerService.getPartnerDisplayName()}}</span>
      <span *ngIf="selectedPageType">
        <i class="fa fa-caret-right cursor-default"></i>
        <span class="cursor-default">{{'PARTNER.' + selectedPageType | translate}}</span>
      </span>
      <span class="pull-right">
        <form class="main-search-form show-search" action="">
          <input type="text"
                 class="search-input form-control"
                 placeholder="{{ 'PARTNER.SEARCH_PARTNER' | translate }}"
                 [(ngModel)]="searchString"
                 name="searchString"/>
        </form>
        <i id="search-open"
           *ngIf="!(searchString?.length > 0)"> <i class="fa fa-search"></i></i>
        <i id="search-close" class="fa fa-times" (click)="searchString = ''"
           *ngIf="searchString?.length > 0"></i>
      </span>
    </div>
    <div class="panel-content center-content white-background">
      <div class="inner-content">
        <div class="assembly-categories" *ngIf="partnerService?.partnerPages?.partnerPages">
          <div class="assembly-category" *ngFor="let page of getPageList()" (click)="openLink(page.url)">
            <ng-container *ngIf="page.logoUrl.indexOf('http://') > -1 || page.logoUrl.indexOf('https://') > -1">
              <div class="image-wrapper">
                <img class="logoUrl" src="{{page.logoUrl}}">
              </div>
              <div class="image-wrapper-hover">
                <img class="logoUrl" src="{{page.logoUrl}}">
              </div>
              <div class="name-wrapper">
                <span>{{page.text}}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="page.logoUrl.indexOf('http://') < 0 && page.logoUrl.indexOf('https://') < 0">
              <div class="name-wrapper-no-image">
                <span>{{page.text}}</span>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="alert alert-info text-center" *ngIf="searchString?.length > 0 && getPageList()?.length === 0">
          <i class="fa fa-info-circle mr-2"></i>
          <span>{{'PARTNER.NO_SEARCH_RESULT' | translate}}</span>
        </div>

        <div class="alert alert-info text-center" *ngIf="!(partnerService?.getPartnerPageKeys()?.length > 0)">
          <i class="fa fa-times mr-2"></i>
          <span>{{'PARTNER.NO_PARTNER' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="right-panel no-float no-padding">
    <div class="panel-content right-sidebar">
      <div class="inner-content no-padding"></div>
    </div>
  </div>
</div>

