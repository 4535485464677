<div class="global-orderhistory">
    <div class="heading blue">
        <span class="clickable" style="cursor: pointer;" routerLink="/global-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <span class="clickable">
            <i class="fa fa-caret-right"></i>
            {{'GLOBAL_ADMIN.GLOBAL_ORDERHISTORY.TITLE' | translate}}
        </span>
    </div>
    <div class="historySearchBar">
        <div class="row">
            <div class="col">
                <h1>{{'GLOBAL_ADMIN.GLOBAL_ORDERHISTORY.TITLE' | translate}}</h1>

                <div class="row">
                    <div class="col-6 custom-search-dropdown-input">
                        <app-dropdown [preSelectedItem]="'PLATFORM_ORDER_ID'"
                                      [translationPrefix]="'GLOBAL_ADMIN.GLOBAL_ORDERHISTORY'"
                                      [items]="searchTypes"
                                      (onItemSelect)="searchType = $event">
                        </app-dropdown>
                        <app-material-form-input
                                [label]="'GLOBAL_ADMIN.GLOBAL_ORDERHISTORY.PLEASE_ENTER_VALUE' | translate: {name: 'GLOBAL_ADMIN.GLOBAL_ORDERHISTORY.' + searchType | translate}"
                                [autofocus]="true" [(text)]="searchTerm"
                                (keydown.enter)="getPlacedOrders()">
                        </app-material-form-input>
                    </div>
                    <div class="col-3">
                        <app-dropdown [placeholderText]="'GLOBAL_ADMIN.GLOBAL_ORDERHISTORY.SELECT_CLUB' | translate"
                                      [preSelectedItem]="getClubWithId(selectedClub)"
                                      [items]="adminService.clubs"
                                      (onItemSelect)="setClubId($event)">
                        </app-dropdown>
                    </div>

                </div>
                <div class="row">
                    <div class="col-3">
                        <app-material-form-input [label]="'GLOBAL_ADMIN.GLOBAL_ORDERHISTORY.START_DATE' | translate"
                                                 [text]="startDate"
                                                 (textChange)="startDate = $event"
                                                 (keydown.enter)="getPlacedOrders()"></app-material-form-input>
                    </div>
                    <div class="col-3">
                        <app-material-form-input [label]="'GLOBAL_ADMIN.GLOBAL_ORDERHISTORY.DATE' | translate"
                                                 [text]="endDate"
                                                 (textChange)="endDate = $event"
                                                 (keydown.enter)="getPlacedOrders()"></app-material-form-input>
                    </div>
                    <div class="col-6"></div>
                </div>
                <div class="row">
                    <div class="col">
                        <button class="base-btn success float-right"
                                (click)="globalAdminService.orderHistoryRequest.page = 0; getPlacedOrders()">
                            <i class="fa fa-search"></i>
                            <span>{{'HOME.SEARCH' | translate}}</span>
                        </button>

                        <button class="base-btn float-right" (click)="resetSearch()"
                                *ngIf="globalAdminService?.orderList">
                            <span>{{'GLOBAL_ADMIN.GLOBAL_ORDERHISTORY.RESET_SEARCH' | translate}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="panel-content center-content white-background margin"
         [ngClass]="{'hasResuls': globalAdminService?.orderList?.orders?.length > 0, 'showPagination': !userService?.enableInfiniteScroll }"
         appInfiniteScroller
         (onScrollEnd)="globalAdminService.loadNext($event, false)">
        <div class="row min-height-500">
            <div class="col">
                <!-- Order List -->
                <app-default-loading-page *ngIf="globalAdminService.loadingPlacedOrders"></app-default-loading-page>
                <ul class="item-list"
                    [@workListAnim]="(globalAdminService.orderList ? globalAdminService.orderList.orders.length : 0)">
                    <app-global-order-row></app-global-order-row>
                </ul>
                <app-no-results *ngIf="globalAdminService?.orderList?.orders?.length === 0"></app-no-results>
            </div>
        </div>
    </div>
    <div class="global-pagination" *ngIf="!userService.enableInfiniteScroll">
        <app-pagination [page]="globalAdminService?.orderList?.page +1"
                        [perPage]="globalAdminService?.orderList?.pageSize"
                        [count]="globalAdminService?.orderList?.totalElements"
                        [pagesToShow]="5"
                        (goPage)="getPlacedOrders($event)">
        </app-pagination>
    </div>
</div>
