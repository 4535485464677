<div class="page-maintenance">
  <div class="center-card">
    <div class="logo {{userService?.accountData?.language}} {{cartService.getCalculation()}} {{userService?.accountData?.country}}"></div>
    <hr />
    <div class="card-content">
      <div class="text-center">
        <i class="fa fa-cog" [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"></i>
        <span>The website is currently under maintenance. Please try again later.</span>
      </div>
    </div>
  </div>
</div>
