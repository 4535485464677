<div class="car-details" *ngIf="carService?.selectedCar">

    <div class="row">
        <div class="col-12">
            <div class="car-details-header">
                <div class="car-name">
                    {{ carService.selectedCar.carProducerName + ' ' + carService.selectedCar.carModelName + ' ' + carService.selectedCar.carTypeName }}
                </div>

                <div class="row ml-0">
                    <div class="col-5">{{ 'SPAREPARTS_VIEW.CAR_DETAILS.MANUFACTURER' | translate }}</div>
                    <div class="col">{{ carService.selectedCar.carProducerName }}</div>
                </div>
                <div class="row ml-0">
                    <div class="col-5">{{ 'SPAREPARTS_VIEW.CAR_DETAILS.MODEL' | translate }}</div>
                    <div class="col">{{ carService.selectedCar.carModelName }}</div>
                </div>
                <div class="row ml-0">
                    <div class="col-5">{{ 'SPAREPARTS_VIEW.CAR_DETAILS.CONSTRUCTION_YEAR' | translate }}</div>
                    <div class="col">{{ carService.selectedCar.constructionFrom + ' - ' + (carService.selectedCar.constructionTo ? carService.selectedCar.constructionTo : ('SPAREPARTS_VIEW.CAR_DETAILS.TODAY' |translate )) }}</div>
                </div>
            </div>
        </div>
    </div>


    <div class="warning-recall"  [ngClass]="{'expired': carService.selectedCar.serviceInfoState === 'EXPIRED' }" *ngIf="carService.selectedCar?.serviceInfos?.length > 0 && userService.allow('VEHICLE_CALLBACK') && (route?.snapshot.data['state'] == 'articles' || route.snapshot.data['state'] == 'gps' || route.snapshot.data['state'] == 'technical-data' || route.snapshot.data['state'] == 'work')">

        <app-car-warning></app-car-warning>

        <div class="clearfix"></div>
    </div>

    <app-external-tools-wrapper [position]="'car-details'"></app-external-tools-wrapper>

    <app-collapsible-panel [panelTitle]="'SPAREPARTS_VIEW.CAR_DETAILS.TECHNICAL_DATA' | translate">
        <table>
            <tbody>
            <tr>
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.POWER' | translate }}</td>
                <td>{{ carService.selectedCar.carTypePowerKW + ' kW (' + carService.selectedCar.carTypePowerPS + ' PS)' }}</td>
            </tr>
            <tr>
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.FUEL_TYPE' | translate }}</td>
                <td>{{ carService.selectedCar.fuelType }}</td>
            </tr>
            <tr>
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.FUEL_PREPARATION' | translate }}</td>
                <td>{{ carService.selectedCar.fuelPreparation }}</td>
            </tr>
            <tr>
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.CAPACITY' | translate }}</td>
                <td>{{ carService.selectedCar.ccm }}</td>
            </tr>
            <tr>
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.BODY_TYPE' | translate }}</td>
                <td>{{ carService.selectedCar.bodyType }}</td>
            </tr>
            <tr>
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.DRIVE_TYPE' | translate }}</td>
                <td>{{ carService.selectedCar.driveType }}</td>
            </tr>
            <tr>
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.CYLINDER' | translate }}</td>
                <td>{{ carService.selectedCar.cylinders }}</td>
            </tr>
            <tr>
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.VALVES' | translate }}</td>
                <td>{{ carService.selectedCar.valves }}</td>
            </tr>
            <tr>
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.ENGINE_TYPE' | translate }}</td>
                <td>{{ carService.selectedCar.engineType }}</td>
            </tr>
            <tr *ngIf="dataService?.getNumberplate() !== ''">
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.NUMBER_PLATE' | translate }}</td>
                <td> {{ dataService?.getNumberplate().toUpperCase() }}</td>
            </tr>
            <tr *ngIf="dataService?.getMileage() > 0">
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.MILEAGE' | translate }}</td>
                <td> {{ dataService?.getMileage() }}</td>
            </tr>
            <tr *ngIf="dataService?.getYear()">
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.YEAR' | translate }}</td>
                <td> {{ dataService?.getYear() | date: 'd.M.yyyy' }}</td>
            </tr>
            </tbody>
        </table>
    </app-collapsible-panel>

    <app-collapsible-panel [panelTitle]="'SPAREPARTS_VIEW.CAR_DETAILS.NUMBERS' | translate">
        <table>
            <tbody>
            <tr>
                <td *ngIf="!carService.selectedCar.typeNo">{{ 'SPAREPARTS_VIEW.CAR_DETAILS.KBA_NUMBERS' | translate }}</td>
                <td *ngIf="carService.selectedCar.typeNo">{{ 'SPAREPARTS_VIEW.CAR_DETAILS.TYPE_NUMBER' | translate }}</td>
                <td>
                    <ng-container *ngIf="!carService.selectedCar.typeNo && !carService.getHsn() || !carService.getTsn || (!(carService?.getHsn() !== '') || !(carService?.getTsn() !== '')) && carService?.selectedCar?.kbaNumbers">
                        <span *ngFor="let kbaNumber of carService.selectedCar.kbaNumbers, let l = last">
						    {{ kbaNumber.replace('_', ' ') }}
                            <span *ngIf="!l">,&nbsp;</span>
					    </span>
                    </ng-container>
                    <ng-container *ngIf="carService.selectedCar.typeNo">
                        <span> {{carService.selectedCar.typeNo}}</span>
                    </ng-container>
                </td>
            </tr>
            <tr *ngIf="carService?.getVin()?.length > 0">
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.VIN' | translate }}</td>
                <td>{{ carService?.getVin()}}</td>
            </tr>
            <tr *ngIf="carService?.getVin()?.length === 0 && dataService?.getVin()?.length > 0">
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.VIN' | translate }}</td>
                <td>{{ dataService?.getVin()}}</td>
            </tr>
            <tr>
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.ENGINE_CODES' | translate }}</td>
                <td>
                    <span *ngFor="let engineCode of carService?.selectedCar?.engineCodes, let l = last">
                        {{ engineCode }}
                        <span *ngIf="!l">, </span>
                    </span>
                </td>
            </tr>
            <tr>
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.INTERNAL_NUMBER' | translate }}</td>
                <td>{{ carService?.getKTypeId()}}</td>
            </tr>
            </tbody>
        </table>
    </app-collapsible-panel>
    <app-collapsible-panel *ngIf="carService.autovistaVinResponse" [panelTitle]="'SPAREPARTS_VIEW.CAR_DETAILS.CAR_DETAILS' | translate">
        <i [routerLink]="['/technical-data']" (click)="technicalDataService.setAutoOpenDetails(true)" class="fa fa-file-pdf-o clickable car-specs"></i>
        <table>
            <tbody>
            <tr>
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.MANUFACTURER' | translate }}</td>
                <td>{{ carService.selectedCar.carProducerName }}</td>
            </tr>
            <tr>
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.MODEL' | translate }}</td>
                <td>{{ carService.selectedCar.carModelName }}</td>
            </tr>
            <tr *ngIf="carService.registrationDate">
                <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.YEAR' | translate }}</td>
                <td>{{ carService.registrationDate }}</td>
            </tr>
            </tbody>
        </table>
        <span *ngIf="carService.selectedCar.autovistaVinResponse">{{carService.selectedCar.autovistaVinResponse.repair.headmodels[0].technicalData}}</span>
    </app-collapsible-panel>

</div>
