import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from './services/admin.service';
import {fadeSmallAnimation} from '../../shared/animations/fadesmall.animation';
import {ThemeService} from '../../shared/services/theme.service';
import {UserService} from '../../shared/services/user/user.service';
import {ConfigService} from '../../shared/services/config.service';
import {CompanyAdminService} from '../company/services/company-admin.service';
import {Location} from '@angular/common';
import {ISetting} from '../../shared/interfaces/DTO/club/setting';
import {PartnerService} from '../../shared/services/partner/partner.service';
import {GlobalAdminService} from '../global/services/global-admin.service';
import {AssortmentService} from '../assortment/services/assortment.service';


@Component({
    selector: 'app-administration',
    templateUrl: './administration.component.html',
    styleUrls: ['./administration.component.scss'],
    animations: [fadeSmallAnimation]
})
export class AdministrationComponent implements OnInit, OnDestroy {

    public settings: ISetting[] = [];

    constructor(private router: Router,
                private route: ActivatedRoute,
                public userService: UserService,
                public assortmentService: AssortmentService,
                private themeService: ThemeService,
                public globalAdminService: GlobalAdminService,
                private changeDetectorRef: ChangeDetectorRef,
                public adminService: AdminService,
                public configService: ConfigService,
                public companyAdminService: CompanyAdminService,
                public partnerService: PartnerService,
                public location: Location) {
        this.adminService.onChangeClub.subscribe(() => {
            this.settings = [];
        });

        this.route.params.subscribe(
            (params) => {
                if (params['id']) {
                    this.adminService.getFirmDataById(params['id']).subscribe(
                        (firm) => {
                            this.adminService.selectedFirm = firm;
                        }
                    );
                }
            }
        );
    }

    ngOnInit() {
        this.settings = [];
        if (this.userService.allow('GLOBAL-ADMIN')) {
            this.adminService.getClubs();
        }
    }

    ngOnDestroy() {
        this.changeDetectorRef.detach();
    }

    public changeValue(value: string) {
        this.configService.selectedSetting.value = parseInt(value);
        this.configService.addUnsavedSetting(this.configService.selectedSettingName);
    }

    private navigate(path: string, firmId: number) {
        if (firmId > 0) {
            this.router.navigate([path, firmId]);
        } else {
            this.router.navigate([path]);
        }
    }

    public showNavigation() {
        this.userService.navigationHelper = 'left';
    }

    public showDetails() {
        this.userService.navigationHelper = 'right';
    }

    public changeSelected(selection: string) {
        this.configService.selectedSetting.selected = selection;
        this.configService.addUnsavedSetting(this.configService.selectedSettingName);
    }

    public resetDynamicSettingWrapper() {
        setTimeout(() => {
            this.configService.selectedSetting = undefined;
            this.configService.resetDynamicSettingWrapper.emit();
            if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        }, 50);
    }

    public getCollapsibleConfigs() {
        if (!this.settings || this.settings.length === 0) {
            Object.keys(this.configService.clubConfig.settings).forEach((settingGroup: string) => {
                this.configService.clubConfig.settings[settingGroup].forEach((setting: ISetting) => {
                    if (setting.className.indexOf('StringSelectionSetting') > 0) {
                        this.settings.push(setting);
                    }
                });
            });
        }
        return this.settings;
    }

    public getRouteAnimation(outlet) {
        return outlet.activatedRouteData.state;
    }
}
