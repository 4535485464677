<div class="car-by-license-swiss">
    <div class="row">
        <div class="col-3">
            <div class="left-label">
            </div>
        </div>
        <div class="col-9">
            <div class="row form-select-item">
                <div class="col-9 registration-ui swiss">
                    <app-material-form-input
                            [label]="'SPAREPARTS_VIEW.CAR_DETAILS.TYPE_NUMBER' | translate"
                            [disabled]="false"
                            [type]="'text'"
                            [maxLength]="6"
                            [minLength]="6"
                            [(text)]="numberplate"
                            [showValidation]="showValidation"
                            [validationErrorMessage]="'VALIDATION.ERROR_LENGTH_6' | translate"
                            [focus]="carService.selected === 'SWISS'"
                            (selected)="carService.selected = 'SWISS'"
                            (keydown.enter)="onLicensePlateSearch()">
                    </app-material-form-input>
                </div>
                <div class="col-3 mb-3">
                    <button #vin class="btn-flat h-100" [disabled]="!(carService.selected === 'SWISS')"
                            (click)="onLicensePlateSearch()">
                        <i class="fa fa-search" *ngIf="!(carService?.loadCar === 'SWISS')"></i>
                        <i class="fa fa-spinner" *ngIf="carService?.loadCar === 'SWISS'"
                           [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
