<div class="transferLog" style="overflow: auto;" (click)="globalAdminService.showTransferLogGripSupplier =false">
    <div class="transferLog-center" (click)="onEvent($event)">
        <div class="row background header-title" style="padding-top: 10px">
            <div class="col-10" style="top: 2px;">
                {{globalAdminService.orderIdGrip}}
            </div>
            <div class="col-2">
                <button class="transferLog-button" (click)="globalAdminService.showTransferLogGripSupplier =false">
                    <i class="fa fa-times"></i>
                </button>
            </div>
        </div>
        <div *ngIf="globalAdminService.currentOrder.supplierTransfers.length < 1" class="row background p-3">
            Kein Datensatz vorhanden.
        </div>
        <div *ngFor="let transfer of globalAdminService.currentOrder.supplierTransfers" class="row background p-3">
            <div *ngIf="transfer.orderId === globalAdminService.orderIdGrip" class="col">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col text-left">
                                {{transfer.orderId}}
                            </div>
                            <div class="col text-right">
                                {{transfer.supplier}}
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col">
                                <div class="request">
                                    <h1>Request</h1>
                                    <div class="row">
                                        <div class="col">
                                            <span>{{!!transfer.request ? transfer.request : 'Kein Datensatz vorhanden.'}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="response">
                                    <h1>Response</h1>
                                    <div class="row">
                                        <div class="col">
                                            <span>{{!!transfer.response ? transfer.response : 'Kein Datensatz vorhanden.'}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
