<div [@itemAnim]="(service.searchResult && service.searchResult?.items ? service.searchResult?.count : 0)"
     class="articles-search-result" [ngClass]="{'onMaintenance': location.path().indexOf('/maintenance/plan') > -1}">
    <ng-container *ngIf="userService.enableInfiniteScroll">
        <ng-container *ngFor="let article of service?.emitItems | async">
            <div class="row app-articles-row clickable" app-articles-row
                 *ngIf="(service.showPseudoArticle && !article.pseudoArticle) || (!service.showPseudoArticle)"
                 [service]="service"
                 [article]="article"
                 (click)="changeSelectedItem(article)"
                 [ngClass]="{'article-selection': location.path().indexOf('/maintenance/plan') > -1
                 || location.path().indexOf('/work') > -1,'selected': service.selectedArticle
                 && (service.selectedArticle.id === article.id && service.selectedArticle.brandName === article.brandName), 'smallArticleRow': articlesService.selectedSearchResultSize === 'small'}"
            ></div>
            <div *ngIf="article.pseudoArticle" class="row pseudo-show-articles"
                 (click)="articlesService.isInPseudoArticleView$.next(true); articlesService.currentPseudoArticle$.next(article); openPseudoArticle(article, service.selectedArticle)"
                 [ngClass]="{'selected': service.selectedArticle && (service.selectedArticle.id === article.id), 'hide': articlesService.isInPseudoArticleView$ | async}">
                <span>{{ 'SPAREPARTS_VIEW.DETAILS_PANEL.PARTS_LIST_PSEUDO' | translate}}</span>
            </div>
            <div class="row article-dropdown-row"
                 *ngIf="service.showPseudoArticle && !article.pseudoArticle && checkGenArtNumber(article)"
                 (click)="toggleAlternative(article)">
                <div class="article-dropdown">
                    <span *ngIf="article?.showAlternative">{{'ARTICLE_SELECTION.HIDE_ALTERNATIVE_WITH_ARTICLE' | translate: {'article': article?.brandName + ' ' + article?.ipc} }}</span>
                    <span *ngIf="!article?.showAlternative">{{'ARTICLE_SELECTION.SHOW_ALTERNATIVE' | translate: {'article': article?.brandName + ' ' + article?.ipc} }}</span>
                    <i class="fa fa-angle-down" *ngIf="!article?.showAlternative"></i>
                    <i class="fa fa-angle-up" *ngIf="article?.showAlternative"></i>
                </div>
            </div>
            <div class="row article-dropdown-list-row" *ngIf="article?.showAlternative">
                <i class="fa fa-spinner" *ngIf="article?.loadDirectSearchArticles"
                   [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"></i>

                <div class="alert alert-info text-center"
                     *ngIf="!(article?.directSearchResponse?.items?.length > 0) && !article?.loadDirectSearchArticles">
                    <i class="fa fa-info-circle mr-2"></i>
                    <span>{{'ARTICLE_SELECTION.NO_ALTERNATIVE_RESULT' | translate}}</span>
                </div>

                <ul *ngIf="article?.directSearchResponse && article?.directSearchResponse?.items" class="item-list"
                    [ngClass]="{'showDirectSearch': article?.directSearchResponse}">
                    <li *ngFor="let alternative of article.directSearchResponse?.items"
                        (click)="changeSelectedItem(alternative)"
                        [ngClass]="{'selected': service.selectedArticle && (service.selectedArticle.id === alternative.id && service.selectedArticle.brandName === article.brandName)}">
                        <div class="row">
                            <div class="col-3"><b>{{alternative.brandName}}</b></div>
                            <div class="col-2"><b>{{alternative.ipc}}</b></div>
                            <div class="col-4">{{alternative.description}}</div>
                            <div class="col-3 price-col" *ngIf="alternative.uvp > 0 && !userService.sale">
                                <span>{{ 'SPAREPARTS_VIEW.SEARCH_RESULTS.RECOMMENDED_PRICE' | translate }}</span>
                                <span class="price">{{alternative.uvpFormatted}}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="row article-dropdown-row" *ngIf="article?.showAlternative" (click)="toggleAlternative(article)">
                <div class="article-dropdown" *ngIf="article?.showAlternative">
                    <span *ngIf="article?.showAlternative">{{'ARTICLE_SELECTION.HIDE_ALTERNATIVE' | translate}}</span>
                    <i class="fa fa-angle-up" *ngIf="article?.showAlternative"></i>
                </div>
            </div>
        </ng-container>
        <div class="noHover row app-articles-row" *ngIf="service.infinityLoading">
            <i class="centerItem fa fa-spinner"
               [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i>
        </div>
        <div class="noHover row app-articles-row" *ngIf="onEnd()">
            <span class="centerItem">{{'SPAREPARTS_VIEW.SEARCH_RESULTS.END' | translate}}</span>
            <div class="w-100 d-flex">
                <button class="base-btn success m-auto position-relative" (click)="sortByAvailabilityAndPrice()">
                    {{'SPAREPARTS_VIEW.SEARCH_RESULTS.RESORT' | translate}}
                </button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!userService.enableInfiniteScroll">
        <ng-container *ngFor="let article of service?.searchResult?.items">
            <div class="row app-articles-row clickable" app-articles-row
                 *ngIf="(service.showPseudoArticle && !article.pseudoArticle) || (!service.showPseudoArticle)"
                 [service]="service"
                 [article]="article"
                 (click)="changeSelectedItem(article)"
                 [ngClass]="{ 'selected': service.selectedArticle && (service.selectedArticle.id === article.id && service.selectedArticle.brandName === article.brandName) }"
            ></div>
            <div *ngIf="article.pseudoArticle" class="row pseudo-show-articles"
                 (click)="openPseudoArticle(article, service.selectedArticle)"
                 [ngClass]="{'selected': service.selectedArticle && (service.selectedArticle.id === article.id && service.selectedArticle.brandName === article.brandName), 'hide': service.hidePartsListPseudo}">
                <span>{{ 'SPAREPARTS_VIEW.DETAILS_PANEL.PARTS_LIST_PSEUDO' | translate}}</span>
            </div>
        </ng-container>
    </ng-container>
</div>


<app-pagination [page]="service.searchResult?.page"
                [perPage]="service.searchResult?.pageSize"
                [count]="service.searchResult?.count"
                [pagesToShow]="5"
                *ngIf="!userService.enableInfiniteScroll && !articlesService.showPseudoArticle"
                (goPage)="goToPage($event)">
</app-pagination>
