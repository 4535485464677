<div class="row" *ngIf="!catalog">
  <div class="col-3">
    <div class="left-label">
    </div>
  </div>
  <div class="col-9">
    <div class="row form-select-item">
      <div class="col-9">
        <app-material-form-input
                [label]="'HOME.VIN' | translate"
                [disabled]="false"
                [type]="text"
                [(text)]="_vin"
                [focus]="carService.selected === 'AUTOVISTA-VIN'"
                (selected)="carService.selected = 'AUTOVISTA-VIN'"
                (keydown.enter)="onVinSearch()"
                [vinSearchCounter]="showVinCounter? vinCounter : undefined"
                [vinInfoText]="showVinInfoText ? vinInfoText : undefined">
        </app-material-form-input>
      </div>
      <div class="col-3">
        <button #autovistaVin class="btn-flat" [disabled]="!(carService.selected === 'AUTOVISTA-VIN')"
                (click)="onVinSearch()">
          <i class="fa fa-search" *ngIf="!(carService?.loadCar === 'AUTOVISTA-VIN')"></i>
          <i class="fa fa-spinner" *ngIf="carService?.loadCar === 'AUTOVISTA-VIN'"
             [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row vin-search car-selection-margin" [ngClass]="{'border-bottom': carService?.selectedCar?.fromDMS}" *ngIf="catalog">
  <div class="col-3">
    <i class="fa fa-euro"></i>
  </div>
  <div class="col-6">
    <div class="text-secondary mt-1"
         *ngIf="showVinCounter">{{'CAR_SELECTION.VIN_COUNTER' | translate:{'counter': vinCounter} }}</div>
    <div class="mt-1 text-secondary pre-line" *ngIf="showVinInfoText">{{vinInfoText}}</div>
    <app-material-form-input [placeholder]="'CAR_SELECTION.VIN_PLACEHOLDER' | translate"
                             [(text)]="_vin">
    </app-material-form-input>
  </div>
  <div class="col-3">
    <button class="base-btn" (click)="onVinSearch()">
      <ng-container *ngIf="!vinLoading">{{'CAR_SELECTION.VIN_BUTTON' | translate}}</ng-container>
      <i class="fa fa-spinner fa-pulse" *ngIf="vinLoading"></i>
    </button>
  </div>
</div>
