import {Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef} from '@angular/core';
import { IWorkItem} from '../../../work-data/interfaces/work-tree';
import { IWorkStep} from '../../../work-data/interfaces/work-step';
import {TecRmiRestService} from '../../services/tecrmi/tecrmi.rest.service';
import {CarService} from '../../services/car/car.service';
import {CartRestService} from '../../../cart/services/cart.rest.service';
import {ICartWork} from '../../../cart/interfaces/cart-work';
import {TranslateService} from '@ngx-translate/core';
import {CartService} from '../../../cart/services/cart.service';
import {WorkService} from '../../../work-data/services/work.service';
import {workListAnim} from '../../animations/general.animation';
import {UserService} from '../../services/user/user.service';


@Component({
  selector: 'app-work-item',
  templateUrl: './work-item.component.html',
  styleUrls: ['./work-item.component.scss'],
    animations: [workListAnim]
})
export class WorkItemComponent implements OnInit {
  @Input() public items: IWorkStep[];
  @Output() public onItemSelect: EventEmitter<any> = new EventEmitter<any>();
  private workPos: ICartWork;

  private selectedCheckbox: any;

  constructor(
      public userService: UserService,
      private carService: CarService,
      private tecRmiRestService: TecRmiRestService,
      public cartService: CartService,
      private translate: TranslateService,
      private cartRestServoce: CartRestService,
      public workService: WorkService,
      private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
      this.selectedCheckbox = undefined;
      this.workService.updateCheckbox.subscribe((step) => {
          if (this.selectedCheckbox) {
              this.selectedCheckbox.isChecked = true;
          }
          if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
              this.changeDetectorRef.detectChanges();
          }
      });
  }

  public selectWorkItem(item: any) {
      this.onItemSelect.emit(item);
      this.userService.setNavigationRight();
  }

  public getHighlighted() {
      const itemList = [];
      for (const item of this.items) {
          if (item.highlight) {
              itemList.push(item);
          }
      }
      return itemList;
  }

  public checkItem(item: any) {
      return this.items[this.items.indexOf(item)].posIdInCart;
  }

  public toggleWorkItem(item: any, checkbox: any) {
      if (this.checkItem(item) !== 0) {
        this.selectedCheckbox = checkbox;
        this.selectWorkItem(item);
        this.workService.removeWorkFromCart(item)
    } else {
        this.selectWorkItem(item);
        this.workService.addWorkToCart(item, checkbox, checkbox);
    }
  }

  private addWorkToCart(step: any, item: IWorkItem) {
      this.workService.addWorkToCart(step, item);
  }
}
