import {Injectable} from '@angular/core';

@Injectable()
export class SearchHistoryService {
    private MAX_ITEMS: number = 10;
    private _searchHistory: string[] = [];

    constructor() {
        if (localStorage.getItem('searchHistory')) {
            this._searchHistory = this._uniqueArray(JSON.parse(localStorage.getItem('searchHistory')));
            this._persistSearchHistory();
        }
    }

    public getSearchHistory() {
        return this._searchHistory;
    }

    public addItem(searchTag: string) {
        if (this._searchHistory.indexOf(searchTag) > -1) {
            if (this._searchHistory.indexOf(searchTag) > 0) {
                this._searchHistory.splice(this._searchHistory.indexOf('searchTag'), 1);
                this._searchHistory.unshift(searchTag);
                this._persistSearchHistory();
                return;
            }
        }else{
            if (this._searchHistory.length < this.MAX_ITEMS) {
                this._searchHistory.unshift(searchTag);
            }else{
                this._searchHistory.pop();
                this._searchHistory.unshift(searchTag);
            }

            this._persistSearchHistory();
        }
    }

    public removeItem(searchTag: string) {
        for (let i = 0; i < this._searchHistory.length; i++) {
            if (this._searchHistory[i] === searchTag) {
                this._searchHistory.splice(i,1);
            }
        }
        this._persistSearchHistory();
    }

    public clearSearchHistory() {
        this._searchHistory = [];
        this._persistSearchHistory();
    }

    private _persistSearchHistory() {
        let cleanedHistory: string[];

        if (localStorage.getItem('searchHistory')) {
            localStorage.removeItem('searchHistory');
        }
        this._searchHistory = this._uniqueArray(this._searchHistory);
        localStorage.setItem('searchHistory', JSON.stringify(this._searchHistory));
    }

    private _uniqueArray(arrArg: any[]) {
        return arrArg.filter(function(elem: any, pos: number, arr: any[]) {
            return arr.indexOf(elem) == pos;
        });
    };

}
