<div class="dashboard">
  <div class="heading blue">
        <span class="clickable">
            <i class="fa fa-th" aria-hidden="true"></i>
        </span>
    <span>{{'CLUB_ADMIN.PARTNER' | translate}}</span>
    <span *ngIf="partnerService.isCreate">
      <i class="fa fa-caret-right"></i>
      {{'CLUB_ADMIN.PARTNER_MANAGEMENT.CREATE' | translate }}
    </span>
    <span *ngIf="!partnerService.isCreate">
      <i class="fa fa-caret-right"></i>
      {{'CLUB_ADMIN.PARTNER_MANAGEMENT.EDIT' | translate }}
    </span>
  </div>
  <div class="panel-content center-content white-background">
    <div class="" *ngIf="partnerService.selectedPartner">
      <app-dropdown [placeholderText]="'PARTNER.TYPE_PLACEHOLDER' | translate"
                    (onItemSelect)="selectedType = $event" [preSelectedItem]="selectedType"
                    [translationPrefix]="'PARTNER'"
                    [items]="partnerService.partnerPages.pageTypes">
      </app-dropdown>

      <app-material-form-input [(text)]="localSelectedPartner.logoUrl"
                               [label]="'CLUB_ADMIN.PARTNER_MANAGEMENT.LOGO' | translate">
      </app-material-form-input>

      <app-material-form-input [(text)]="localSelectedPartner.text"
                               [label]="'CLUB_ADMIN.PARTNER_MANAGEMENT.TEXT' | translate">
      </app-material-form-input>

      <app-material-form-input [(text)]="localSelectedPartner.url"
                               [label]="'CLUB_ADMIN.PARTNER_MANAGEMENT.URL' | translate">
      </app-material-form-input>

      <button class="base-btn success" (click)="save()" [disabled]="!selectedType">{{'PROMPTS.BUTTONS.SAVE' | translate}}</button>
      <button class="base-btn pull-right" (click)="back()">{{'PROMPTS.BUTTONS.GO_BACK' | translate}}</button>
    </div>
  </div>
</div>
