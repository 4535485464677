<div class="wizard">
    <div class="wizard-header">
        <ul class="nav nav-justified">
            <app-chip *ngFor="let step of steps" [icon_value]="(!step.isDisabled ? 'fa-pencil' : '')"
                      [text]="step.title" (onIconClick)="goToStep(step)"
                      [chipClass]="{'active': step.isActive, 'enabled': !step.isDisabled, 'disabled_no_icon': step.isDisabled, 'completed': isCompleted}"></app-chip>
        </ul>
    </div>
    <div class="wizard-block">
        <ng-content></ng-content>
    </div>

    <div class="wizard-footer" [hidden]="isCompleted">
        <button
                type="button"
                class="base-btn float-left btn-text"
                (click)="previous()"
                [hidden]="!hasPrevStep || !activeStep.showPrev">{{ 'GENERAL.BACK' | translate }}
        </button>
        <button
                type="button"
                class="base-btn success float-right no-top-margin"
                (click)="next()"
                [disabled]="!activeStep.isValid"
                [hidden]="!hasNextStep || !activeStep.showNext">{{ 'MAINTENANCE.NEXT' | translate }}
        </button>
    </div>
</div>
