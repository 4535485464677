<div class="row ">
  <div class="col-3">
  </div>
  <div class="col-9">
    <div class="row form-select-item">
      <div class="col-9">
        <app-material-form-input
                [label]="'HOME.MOTOR_CODE' | translate"
                [disabled]="false"
                [type]="text"
                [(text)]="motorCode"
                [focus]="carService.selected === 'MOTOR_CODE'"
                (selected)="carService.selected = 'MOTOR_CODE'"
                (keydown.enter)="onMotorCodeSearch()">
        </app-material-form-input>
      </div>
      <div class="col-3">
        <button #motor class="btn-flat" [disabled]="!(carService.selected === 'MOTOR_CODE')"
                (click)="onMotorCodeSearch()">
          <i class="fa fa-search" *ngIf="!(carService?.loadCar === 'MOTOR-CODE')"></i>
          <i class="fa fa-spinner" *ngIf="carService?.loadCar === 'MOTOR-CODE'"
             [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"></i>
        </button>
      </div>
    </div>
  </div>
</div>

