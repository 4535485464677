<div class="preload-car-selection">

    <!-- kType -->
    <div class="row pre-selected-ktype-car" *ngIf="carService.dmsKTypeCar?.length > 0">
        <div class="col-3">
            <div class="left row m-0">
                <i class="fa fa-car mr-3"></i>
                <span>{{'CAR_SELECTION.TYPE.KTYPE' | translate}}</span>
                <span class="ml-1">({{carService.dmsKTypeCar[0]?.kTypeId}})</span>
            </div>
        </div>
        <div class="col-9">
            <div class="row">
                <div class="col-9">
                    <span>{{carService.dmsKTypeCar[0].displayName}}</span>
                </div>
                <div class="col-3">
                    <button class="base-btn" (click)="applyCar(carService.dmsKTypeCar[0])">
                        <span> {{('CAR_SELECTION.ENTRY_POINTS.' + configService.getEntryPointName()) | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- NatCode -->
    <div class="row pre-selected-natcode-car" *ngIf="carService.dmsNatCodeCar?.length > 0">
        <div class="col-3">
            <div class="left row m-0">
                <i class="fa fa-car mr-3"></i>
                <span>{{'CAR_SELECTION.TYPE.NATCODE' | translate}}</span>
                <span class="ml-1">({{carService.dmsNatCodeCar[0]?.natCode}})</span>
            </div>
        </div>
        <div class="col-9">
            <div class="row">
                <div class="col-9">
                    <span>{{carService.dmsNatCodeCar[0].displayName}}</span>
                </div>
                <div class="col-3">
                    <button class="base-btn" (click)="applyCar(carService.dmsNatCodeCar[0])">
                        <span> {{('CAR_SELECTION.ENTRY_POINTS.' + configService.getEntryPointName()) | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- VIN -->
    <div class="row pre-selected-vin-car" *ngIf="carService.showPreSelection">
        <div class="col-3">
            <div class="left row m-0">
                <i class="fa fa-car mr-3"></i>
                <span>VIN</span>
                <span class="ml-1">({{'CAR_SELECTION.ALLOW.FALSE' | translate}})</span>
            </div>
        </div>
<!--        If === 1 result -->
        <div class="col-9" *ngIf="carService?.preSelectedVinCar?.length === 1">
            <div class="row">
                <div class="col-9">
                    <span>{{carService.preSelectedVinCar[0].displayName}}</span>
                </div>
                <div class="col-3">
                    <button class="base-btn" (click)="applyCar(carService.preSelectedVinCar[0])">
                        <span> {{('CAR_SELECTION.ENTRY_POINTS.' + configService.getEntryPointName()) | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
<!--        If > 1 && < 5 results -->
        <div class="col-9" *ngIf="carService?.preSelectedVinCar?.length > 1 && carService?.preSelectedVinCar?.length < 6">
            <div class="row" *ngFor="let car of carService.preSelectedVinCar" (click)="applyCar(car)">
                <div class="col-9">
                    <i class="fa fa-car"></i>
                    {{car?.displayName}} {{car?.engineCodes?.length > 0 ? ' (' + car?.engineCodes?.toString() + ')' : ''}}
                </div>
                <div class="col-3">
                    <button class="base-btn">
                        <span> {{('CAR_SELECTION.ENTRY_POINTS.' + configService.getEntryPointName()) | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
<!--        > 5 results -->
        <div class="col-9" *ngIf="carService?.preSelectedVinCar?.length > 6 && carService?.modalHidden">
            <div class="row">
                <div class="col-9">
                    <span>{{'CAR_SELECTION.MULTIPLE_CARS' | translate}}</span>
                </div>
                <div class="col-3">
                    <button class="base-btn" (click)="carService.showPreselectionModal()">
                        <span> {{'CAR_SELECTION.TO_CAR_SELECTION_LIST' | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
