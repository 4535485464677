import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ISetting} from '../../../../../shared/interfaces/DTO/club/setting';
import {ConfigService} from '../../../../../shared/services/config.service';
import {UserService} from '../../../../../shared/services/user/user.service';

@Component({
    selector: 'app-dynamic-setting-drag-list',
    templateUrl: './dynamic-setting-drag-list.component.html'
})
export class DynamicSettingDragListComponent implements OnInit, OnDestroy {

    @Input() public settingList: ISetting[] = [];
    @Input() public settingName: string;

    constructor(public configService: ConfigService,
                public userService: UserService,
                private changeDetectionRef: ChangeDetectorRef) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        if (this.changeDetectionRef) {
            this.changeDetectionRef.detach();
        }
    }

    public changeActive(setting: ISetting, active: boolean) {
        setting.active = active;
        this.configService.addUnsavedSetting(this.settingName);
    }

    public cdkDropListDropped($event: any) {
        this.configService.sortList(this.settingList, $event);
        this.configService.resetDynamicSettingWrapper.emit();
    }

    public getTranslation(name: string) {
        return name[0] + name.substr(1).toLowerCase();
    }
}
