<div class="club-setting-cart-limit">
    <div class="club-setting-title">
        <h1>
            <strong>
                {{'CLUB_ADMIN.SETTING_NAME.CART_LIMIT' | translate}}
            </strong>
        </h1>
    </div>
    <ng-container *ngFor="let limit of getCartLimits()">
        <app-checkbox [labelText]="'CLUB_ADMIN.SETTINGS.' + limit | translate"
                      [isChecked]="getActiveForName(limit)"
                      (onChange)="setActiveForName(limit, $event)"></app-checkbox>
        <div class="cart-setting" *ngIf="hasUnitForName(limit) && getActiveForName(limit)">
            <app-material-form-input [text]="getAmountForName(limit)" [type]="'number'"
                                     [step]="(limit==='AMOUNT_LIMIT'? 1: 0.1)"
                                     (textChange)="setAmountForName(limit, $event)"></app-material-form-input>
            <div class="custom-dropdown clickable" *ngIf="getUnitListForName(limit).length > 0">
                <select class="clickable" [ngModel]="getUnitForName(limit)"
                        (ngModelChange)="setUnitForName(limit, $event)">
                    <option [value]="unit" *ngFor="let unit of getUnitListForName(limit)">
                        {{'CLUB_ADMIN.SETTINGS.' + unit | translate}}
                    </option>
                </select>
            </div>
        </div>
    </ng-container>

    <button class="base-btn success ml-3" (click)="configService.saveClubConfig()"
            *ngIf="configService.hasUnsavedSettings(configService.selectedSettingName)">
        <i class="fa fa-check"></i>
        {{'CLUB_ADMIN.PARTNER_MANAGEMENT.SAVE' | translate}}
    </button>
</div>
