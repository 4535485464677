import {Component, OnInit, OnDestroy} from '@angular/core';
import {UserService} from '../shared/services/user/user.service';
import {CarService} from '../shared/services/car/car.service';
import {TyreService} from '../shared/services/tyre.service';
import {catAnimation, leftTreeAnimation, workListAnim} from '../shared/animations/general.animation';
import {Subscription} from 'rxjs/Subscription';
import {CustomerDataService} from '../shared/services/customerData.service';
import {ConfigService} from '../shared/services/config.service';
import {StateService} from '../shared/services/state.service';
import {ICar} from "../shared/interfaces/DTO/car/car";
import {ITyreType} from "../technical-data/interfaces/Tyre";
import {IVehicleSpecSubGroup} from "../shared/interfaces/DTO/car/vehicleSpecSubGroup";

@Component({
    selector: 'app-tyre',
    templateUrl: './tyre.component.html',
    styleUrls: ['./tyre.component.scss'],
    animations: [leftTreeAnimation, catAnimation, workListAnim]
})
export class TyreComponent implements OnInit, OnDestroy {

    public selectedCar: ICar;
    public searchTerm: string = '';
    public tyreList: ITyreType[] = [];
    public loading: boolean = false;
    public pdf: any;
    public tyreTypes: ITyreType[] = [];
    public selectedTyreType: ITyreType;
    public tyreSpecs: IVehicleSpecSubGroup[] = [];

    private subscriptions: Subscription[] = [];
    private currentView: string = 'TYRE_INFO';

    constructor(public userService: UserService,
                public carService: CarService,
                public tyreService: TyreService,
                public customerDataService: CustomerDataService,
                public stateService: StateService,
                public configService: ConfigService) {
    }

    public ngOnInit(): void {
        this.stateService.updateAll();

        //TODO: remove after change carService to Subjects
        this.getTyreData(this.carService.getAdcTypeId());
        this.selectedCar = this.carService.selectedCar;
        //

        this.subscriptions.push(this.carService.selectedCarChanged$.subscribe(selectedCar => {
            this.selectedCar = selectedCar;

            // reset to default view and close PDF
            this.tyreService.resetPDF();
            this.currentView = 'TYRE_INFO';

            if (selectedCar?.adcTypeId) {
                this.getTyreData(selectedCar.adcTypeId);
            }
        }));

        this.subscriptions.push(this.tyreService.tyreTypes$.subscribe(tyreTypes => {
            if (tyreTypes.length > 0) {
                this.tyreTypes = tyreTypes;
                this.stateService.updateAll();
            }
        }));

        this.subscriptions.push(this.tyreService.tyres$.subscribe(tyres => {
            if (tyres.length > 0 && this.selectedTyreType) {
                this.selectedTyreType.details = tyres;
                this.tyreService.selectedTyreType$.next(this.selectedTyreType);
            }
        }));

        this.subscriptions.push(this.tyreService.tyreSpecs$.subscribe(tyreSpecs => {
            this.tyreSpecs = tyreSpecs;
        }));

        this.subscriptions.push(this.tyreService.loading$.subscribe(loading => {
            this.loading = loading;
        }));

        this.subscriptions.push(this.tyreService.pdf$.subscribe(pdf => {
            this.pdf = pdf;
        }));

        this.subscriptions.push(this.tyreService.selectedTyreType$.subscribe(selectedTyreType => {
            this.selectedTyreType = selectedTyreType;
        }));
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe()
        });
    }

    public checkView(view: string): boolean {
        return view === this.currentView;
    }

    public setView(view: string) {
        this.currentView = view;
    }

    public searchTyre(search: string): void {
        if (search && search.length >= 3) {
            search = search.toString().toLocaleUpperCase();
            this.tyreList = [];
            for (const tyre of this.tyreTypes) {
                if (tyre.abe.toString().indexOf(search) >= 0) {
                    this.tyreList.push(tyre);
                }
                if (tyre.vsn.toString().indexOf(search) >= 0) {
                    this.tyreList.push(tyre);
                }
            }
        } else {
            this.tyreList = this.tyreTypes;
        }
    }

    public resetSearch(): void {
        this.tyreList = this.tyreTypes;
        this.tyreService.resetSelectedTyreType();
        this.tyreService.resetTyres();
        this.searchTerm = '';
    }

    private getTyreData(adcTypeId: number): void {
        if (adcTypeId > 0) {
            this.tyreService.getTyreTypes(adcTypeId);
            this.tyreService.getTyreSpecs(adcTypeId);
        }
    }

    public openType(tyreType: ITyreType): void {
        this.tyreService.selectedTyreType$.next(tyreType);
        this.setView('TYRE_TABLE');
        this.tyreService.resetPDF();
        this.tyreService.getTyres(tyreType.id);
    }
}
