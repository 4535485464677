import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PartnerService} from '../../../../shared/services/partner/partner.service';
import {UserService} from '../../../../shared/services/user/user.service';
import {IPartnerPage} from '../../../../partner/interfaces/partnerPage';

@Component({
  selector: 'app-editpartner',
  templateUrl: './editpartner.component.html',
  styleUrls: ['./editpartner.component.scss']
})
export class EditpartnerComponent implements OnInit, AfterViewInit {

  public selectedType: string = '';
  public localSelectedPartner: IPartnerPage;
  constructor(private router: Router,
              public partnerService: PartnerService,
              public userService: UserService) {
  }

  ngOnInit() {
    if (!this.partnerService.partnerPages) {
      this.router.navigate(['/admin/partner']);
    }

    if (!this.partnerService.isCreate) {
      this.selectedType = this.partnerService.selectedPartnerIsURL;
    }

    this.localSelectedPartner = this.partnerService.selectedPartner;
  }

  ngAfterViewInit() {}

  public save() {
    if (this.partnerService.isCreate) {
      this.partnerService.createPartner(this.localSelectedPartner, this.selectedType);
    } else {
      this.partnerService.changePartner(this.localSelectedPartner, this.selectedType);
    }
  }

  public back() {
    this.router.navigate(['/admin/partner']);
  }

}
