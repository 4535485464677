import { Component, OnInit } from '@angular/core';
import {MaintenanceService} from '../services/maintenance.service';
import {fadeOfferToggle} from '../../shared/animations/general.animation';
import {CarService} from '../../shared/services/car/car.service';
import {UserService} from '../../shared/services/user/user.service';
import {CartService} from '../../cart/services/cart.service';

@Component({
  selector: 'app-maintenance-dashboard',
  templateUrl: './maintenance-dashboard.component.html',
  styleUrls: ['./maintenance-dashboard.component.scss'],
    animations: [fadeOfferToggle]
})
export class MaintenanceDashboardComponent implements OnInit {

  constructor(public maintenanceService: MaintenanceService,
              public cartService: CartService,
              public carService: CarService,
              public userService: UserService) { }

  ngOnInit() {
  }

}
