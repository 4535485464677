import {Component, OnInit} from '@angular/core';
import {CompanyAdminService} from '../services/company-admin.service';
import {IFirmLogin} from '../../../shared/interfaces/firm';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../club/services/admin.service';
import {UserService} from '../../../shared/services/user/user.service';
import {HelperService} from '../../../shared/services/helper.service';

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss']
})
export class UsermanagementComponent implements OnInit {

    public editUser: IFirmLogin;
    public firmId: number;
    public role: string;
    private delete = false;

  constructor(public companyAdminService: CompanyAdminService,
              public userService: UserService,
              private adminService: AdminService,
              private router: Router,
              private route: ActivatedRoute,
              private helperService: HelperService) {

  }

      ngOnInit() {
          this.firmId = 0;
          this.route.params.subscribe(
              (params) => {
                  this.firmId = params['id'];
                  if ( params['id'] ) {
                      this.adminService.getFirmDataById(params['id']).subscribe(
                          (response) => {
                              this.adminService.selectedFirm = response;
                          }
                      );
                      }
              }
          );

      }

    private navigate(user: IFirmLogin) {
      if (this.firmId > 0) {
          this.editUser = user;
          this.router.navigate(['admin/firm/user/', user.id, this.firmId]);
      } else {
          this.editUser = user;
          this.router.navigate(['company-admin/user/', user.id]);
      }
    }

    private reset() {
        if (this.firmId > 0) {
            this.router.navigate(['admin']);
        } else {
            this.router.navigate(['company-admin']);
        }
    }

    private createUser() {
      if (this.firmId > 0) {
          this.router.navigate(['admin/firm/user', 'create', this.firmId]);
      } else {
          this.router.navigate(['company-admin/user/', 'create']);
      }
    }


    public deleteLogin(userData: any, _delete: boolean, right: string) {
        switch (right) {
            case ('GLOBAL-ADMIN'):
                if (!_delete) {
                    userData.delete = true;
                } else {
                    this.adminService.deleteUser(userData.id).subscribe(
                        (success) => {
                            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
                            this.companyAdminService.updateFirmData();
                        },
                        (error) => {
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_ENTRY', 'error');
                        }
                    )
                }
                break;
            case ('CLUB-ADMIN'):
                if (!_delete) {
                    userData.delete = true;
                } else {
                    this.adminService.archiveUserClubAdmin(userData.id).subscribe(
                        (success) => {
                            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
                            this.companyAdminService.updateFirmData();
                        },
                        (error) => {
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_ENTRY', 'error');
                        }
                    )
                }
                break;
            case ('FIRM-ADMIN'):
                if (!_delete) {
                    userData.delete = true;
                } else {
                    this.adminService.archiveUserFirmAdmin(userData.id).subscribe(
                        (success) => {
                            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
                            this.companyAdminService.updateFirmData();
                        },
                        (error) => {
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_ENTRY', 'error');
                        }
                    )
                }
                break;
        }
    }

    public isGlobalAdmin() {
        return this.userService.allow('GLOBAL-ADMIN');
    }

    public isFirmAdmin() {
        return this.userService.allow('FIRM-ADMIN');
    }

    public isClubAdmin() {
        return this.userService.allow('CLUB-ADMIN');
    }
}
