import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CompanyAdminService} from '../services/company-admin.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../club/services/admin.service';
import {ErrorHandlerService} from '../../../shared/components/error-handler/services/error-handler.service';
import {UserService} from '../../../shared/services/user/user.service';
import {ISupplierLoginData} from '../../../shared/interfaces/DTO/firm/supplierLoginData';
import {ConfigService} from '../../../shared/services/config.service';
import {HelperService} from '../../../shared/services/helper.service';

@Component({
    selector: 'app-suppliers',
    templateUrl: './suppliers.component.html',
    styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {

    public suppliers: ISupplierLoginData[];
    public firmId: any;
    public selectedSupplier: ISupplierLoginData;
    public isSort: boolean = false;
    public supplierTypes = ['STANDARD', 'SPECIAL'];
    public standardSupp = []
    public specializedSupp = []
    public loadSupplier: boolean;

    constructor(public companyAdminService: CompanyAdminService,
                public modalService: NgbModal,
                public userService: UserService,
                private helperService: HelperService,
                private route: ActivatedRoute,
                public adminService: AdminService,
                private router: Router,
                public errorHandlerService: ErrorHandlerService,
                private changeDetectorRef: ChangeDetectorRef,
                public configService: ConfigService) {
        this.errorHandlerService.newError = false;

        this.companyAdminService.onSupplierChange.subscribe((change) => {
            this.updateSupplierList();
        });
    }

    ngOnInit() {
        this.errorHandlerService.newError = false;
        this.updateSupplierList();
    }

    public updateSupplierList() {
        this.loadSupplier = true;
        this.companyAdminService.getSuppliersRest().subscribe(
            (response) => {
                this.loadSupplier = false;
                this.standardSupp = [];
                this.specializedSupp = [];
                this.suppliers = response;
                for (let i = 0; i < this.suppliers.length; i++) {
                    if (!this.suppliers[i].specialized) {
                        this.standardSupp.push(this.suppliers[i])
                    } else {
                        this.specializedSupp.push(this.suppliers[i])
                    }
                }
                for (let i = 0; i < this.standardSupp.length; i++) {
                    this.standardSupp[i].sortNo = i;
                }

                for (let i = 0; i < this.specializedSupp.length; i++) {
                    this.specializedSupp[i].sortNo = i + this.standardSupp.length;
                }


                if (this.suppliers[0].sortNo === 0) {
                    for (let i = 0; i < this.suppliers.length; i++) {
                        this.suppliers[i].sortNo = i + 1;
                    }
                }
                this.changeDetectorRef.markForCheck();
                this.checkSelectedLocalStorageSupplier();
            }, (error) => {
                this.loadSupplier = false;
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_SUPPLIER', 'error');
            });
    }

    public checkSelectedLocalStorageSupplier(): void {
        if (localStorage.getItem('supplier')) {
            for (const supplier of this.suppliers) {
                if (supplier.supplier === localStorage.getItem('supplier')) {
                    return;
                }
            }
        }

        if (this.suppliers?.length > 0) {
            localStorage.setItem('supplier', this.suppliers[0].supplier);
        }
    }

    public swapSuppliers(event: any, typ: string) {
        if (event.container.id === event.previousContainer.connectedTo) {
            if (typ === 'STANDARD') {
                for (let i = 0; i < this.standardSupp.length; i++) {
                    this.standardSupp[i].specialized = false;
                }
            } else {
                for (let i = 0; i < this.specializedSupp.length; i++) {
                    this.specializedSupp[i].specialized = true;
                }
            }
        }
        this.suppliers = this.standardSupp.concat(this.specializedSupp);
        for (let i = 0; i < this.suppliers.length; i++) {
            this.suppliers[i].sortNo = i + 1;
        }
    }

    public hasInactiveSupplier() {
        if (this.suppliers) {
            for (const supp of this.suppliers) {
                if (!supp.active) {
                    return true;
                }
            }
        }
    }

    public hasActiveSupplier() {
        if (this.suppliers) {
            for (const supp of this.suppliers) {
                if (supp.active) {
                    return true;
                }
            }
        }
    }

    public delete(_delete: boolean, supp: any) {
        if (!_delete) {
            supp.delete = true;
        } else {
            if (this.firmId > 0) {
                this.adminService.deleteSupplierRest(supp).subscribe(
                    (success) => {
                        this.updateSupplierList();
                        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                        return true;
                    },
                    (error) => {
                        this.errorHandlerService.throwError(error.status);
                    }
                );
            } else {
                this.companyAdminService.deleteSupplierRest(supp).subscribe(
                    (success) => {
                        this.updateSupplierList();
                        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                        return true;
                    },
                    (error) => {
                        this.errorHandlerService.throwError(error.status);
                    }
                );
            }
        }
    }

    public createSupplier() {
        this.companyAdminService.supplier = {
            customerNo: '',
            supplier: '',
            password: '',
            username: '',
            firmId: 0,
            id: '',
            sortNo: this.suppliers.length + 1,
            active: true
        };
        this.router.navigate(['company-admin/suppliers', 0]);
    }

    public saveSupplier() {
        this.isSort = false;
        this.companyAdminService.updateAllSuppliersRest(this.suppliers).subscribe(
            (success) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_UPDATE', 'error');
            }
        );
    }

    public reset() {
        if (this.firmId > 0) {
            this.router.navigate(['admin']);
        } else {
            this.router.navigate(['company-admin']);
        }
    }

    public navigate(supp: ISupplierLoginData) {
        this.companyAdminService.supplier = supp;
        this.router.navigate(['company-admin/suppliers', supp.id]);
    }
}
