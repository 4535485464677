import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ICart} from '../../interfaces/DTO/cart/cart';
import {IOrderLite, ICartOrderListWrapper} from '../../interfaces/DTO/order/order';
import {IOrderSearchRequest} from '../../interfaces/DTO/order/orderSearchRequest';
import {ICartOrderListRequest} from '../../interfaces/DTO/cart/cartOrderListRequest';

@Injectable()
export class OrderRestService {

    constructor(private http: HttpClient) {
    }

    public getOrderList(orderlist: IOrderSearchRequest): Observable<any> {
        return this.http.post<IOrderLite>(
            `${environment.apiUrl}/order/list`,
            orderlist
        )
    }

    public getOrderDetails(orderId: string): Observable<ICart> {
        return this.http.get<ICart>(
            `${environment.apiUrl}/order/details/${orderId}`
        );
    }

    public getOrderListForCartRest(orderListRequest: ICartOrderListRequest): Observable<ICartOrderListWrapper> {
        return this.http.post<ICartOrderListWrapper>(
            `${environment.apiUrl}/order/list`,
            orderListRequest
        )
    }
}
