import {AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ISetting} from '../../../../shared/interfaces/DTO/club/setting';
import {ConfigService} from '../../../../shared/services/config.service';

@Component({
  selector: 'app-dynamic-setting-wrapper',
  templateUrl: './dynamic-setting-wrapper.component.html',
  styleUrls: ['./dynamic-setting-wrapper.component.scss']
})
export class DynamicSettingWrapperComponent implements OnInit, OnDestroy, AfterContentChecked {

  public selectedSetting: ISetting[];

  constructor(public router: Router,
              public configService: ConfigService,
              private changeDetectionRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.getSelectedSettingName();
    this.getSelectedSetting();
    this.configService.resetDynamicSettingWrapper.subscribe(() => {this.reset()});
    this.configService.changeClubConfig.subscribe(() => {this.getSelectedSetting()});
  }

  ngOnDestroy() {
    if (this.changeDetectionRef) {
      this.changeDetectionRef.detach();
    }
  }
  ngAfterContentChecked(): void {
    this.changeDetectionRef.detectChanges();
  }

  public isDynamicSetting() {
    return this.configService.selectedSettingName !== 'TECDOC'
        && this.configService.selectedSettingName !== 'LANGUAGE'
        && this.configService.selectedSettingName !== 'CART_LIMIT'
        && this.configService.selectedSettingName !== 'COUNTRY'
        && this.configService.selectedSettingName !== 'SUPPLIER'
        && this.configService.selectedSettingName !== 'GLOBAL_FIRM_LOGO';
  }

  public reset() {
    this.getSelectedSettingName();
    this.getSelectedSetting();
    if (this.changeDetectionRef && !this.changeDetectionRef['destroyed']) {
      this.changeDetectionRef.detectChanges();
    }
  }

  public getSelectedSettingName() {
    this.configService.selectedSettingName = this.router.url.substr(this.router.url.lastIndexOf('/') + 1);
  }

  public getSelectedSetting() {
    if (this.configService.clubConfig) {
      this.selectedSetting = this.configService.getSettingByName(this.configService.selectedSettingName)
          ? this.configService.getSettingByName(this.configService.selectedSettingName).settings : undefined;
      if (!this.selectedSetting) {
        // this.router.navigate(['/admin/config/']);
      }
    }
  }

  public getSettingDTOList() {
    const list = [];
    if (this.selectedSetting) {
      for (const setting of this.selectedSetting) {
        if (setting && setting.className && (setting.className.endsWith('.SettingDTO')
            || setting.className.endsWith('.StringSelectionSettingDTO')
            || setting.className.endsWith('.NumberValueSettingDTO')
            || setting.className.endsWith('.IndexedStringMultiSelectionSettingDTO')
            || setting.className.endsWith('.MultipleSettingDTO'))) {
          list.push(setting);
        }
      }
    } else {
      this.getSelectedSetting();
    }
    return list;
  }

  public getExternalToolDTOList() {
    const list = [];
    if (this.selectedSetting) {
      for (const setting of this.selectedSetting) {
        if (setting && setting.className && setting.className.endsWith('.ExternalToolSettingDTO')) {
          list.push(setting);
        }
      }
    } else {
      this.getSelectedSetting();
    }

    return list;
  }
}
