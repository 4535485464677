import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AutovistaService} from './services/autovista.service';
import {UserService} from '../shared/services/user/user.service';
import {HelperService} from '../shared/services/helper.service';
import {CarService} from '../shared/services/car/car.service';
import {IAutovistaCar, IAutovistaDetails} from '../articles/interfaces/autovista';
import {BodygraphicsService} from './services/bodygraphics.service';
import {ConfigService} from '../shared/services/config.service';
import {StateService} from '../shared/services/state.service';
import {distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'app-graphical-part-search',
  templateUrl: './graphical-part-search.component.html',
  styleUrls: ['./graphical-part-search.component.scss']
})
export class GraphicalPartSearchComponent implements OnInit, OnDestroy, AfterViewInit {

  private interval: any;

  constructor(public autovistaService: AutovistaService,
              public userService: UserService,
              public helperService: HelperService,
              public carService: CarService,
              private ref: ChangeDetectorRef,
              public stateService: StateService,
              private bodygraphicsService: BodygraphicsService,
              public configService: ConfigService) {
      this.stateService.updateGraphicalPartSearchContent();
      this.ref.detach();
      this.interval = setInterval(() => {
          if (this.ref && !this.ref['destroyed']) {
              this.ref.detectChanges();
          }
      }, 500);

      this.autovistaService.selectedArticleChange.subscribe((selectedArticle: IAutovistaDetails) => {
          this.autovistaService.selectedAutovistaDetails = selectedArticle;
          this.userService.setNavigationRight();
          if (this.ref && !this.ref['destroyed']) {
              this.ref.detectChanges();
          }
      });

      this.carService.selectedCarChanged$.pipe(distinctUntilChanged()).subscribe((car) => {
          if (this.userService.bodygraphicsType === 'AUTOVISTA') {
              this.autovistaService.getCarList();
          }
      });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
      if (this.userService.bodygraphicsType === 'AUTOVISTA') {
          this.autovistaService.getCarList();
      }
  }

  ngOnDestroy() {
    this.ref.detach();
    this.ref = undefined;
    clearInterval(this.interval);
  }
    public showNavigation() {
        this.userService.setNavigationLeft();
        // if (this.ngbTabSet && this.ngbTabSet.tabs) {
        //     this.ngbTabSet.select(this.ngbTabSet.tabs.first.id);
        // }
    }

    public showFilter() {
        this.userService.setNavigationLeft();
        // if (this.hasFilter() && this.ngbTabSet && this.ngbTabSet.tabs) {
        //     this.userService.setNavigationLeft();
        //     this.ngbTabSet.select(this.ngbTabSet.tabs.last.id);
        // }
    }

    public hasFilter() {
        return (this.autovistaService.hasfilter);
    }
    public getSelectedName() {
        return this.autovistaService.dsCodes.find(dsCode => this.autovistaService.selected === dsCode.code);
    }

    public getCarOverView() {
      if (this.userService.bodygraphicsType === 'AUTOVISTA') {
            const car = this.helperService.recursiveDeepCopy(this.autovistaService.selectedCar);
            if (car && this.autovistaService.selected) {
                this.autovistaService.hasfilter = false;
                this.autovistaService.filter = false;
                this.autovistaService.imageLoading = true;
                this.autovistaService.selected = undefined;
                this.autovistaService.getCarOverview(car);
            }
          this.autovistaService.selectedGroup = undefined;
          this.autovistaService.selectedSubgroup = undefined;
          this.autovistaService.loadSubGroupDetails = false;
          this.autovistaService.subGroupDetails = undefined;
          this.autovistaService.oldXML = undefined;

      } else {
          this.bodygraphicsService.resetPartSearch.emit();
      }
    }

    public convertDate(date: string) {
        return date.substring(4) + '.' + date.substring(0, 4);
    }
}
