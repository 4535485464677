import {Component, Input, OnInit} from '@angular/core';
import {ArticlesService} from '../../../articles/services/articles.service';
import {IArticlesWorkId} from '../../../articles/interfaces/articlesWorkId';
import {Location} from "@angular/common";
import {bounceIn} from '../../animations/sidebar';

@Component({
    selector: 'app-articles-for-work',
    templateUrl: './articles-for-work.component.html',
    styleUrls: ['./articles-for-work.component.scss'],
    animations: [bounceIn]
})
export class ArticlesForWorkComponent implements OnInit {

  @Input() public service: any;
  public byWorkIdTimer: number;


    public articles: IArticlesWorkId = {adcTypeId: 0,  workId: 0, pageSize: 0, linkingTargetType: '' , itemMpId: 0, kTypeId: 0, page: 1};
    public hasArticles: boolean;

  constructor(public articlesService: ArticlesService,
              private location: Location) {}


  ngOnInit() {
  }


  public getArticles(articlesPos: any) {
    this.articles.kTypeId = this.service.kTypeId;
    this.articles.adcTypeId = this.service.adcTypeId;
    this.articles.itemMpId = articlesPos.mountPositionId;
    this.articles.linkingTargetType = 'P';
    this.articles.pageSize = 20;
    this.articles.page = 1;
    this.articles.workId = articlesPos.workId;

    const aktTime: number = new Date().getTime();
    if (!this.byWorkIdTimer || aktTime - this.byWorkIdTimer > 500) {
      this.byWorkIdTimer = aktTime;
      this.service.updateArticlesByWorkId(this.articles);
    }
    this.service.resultItems = [];
    this.service.selectOption('ARTIKEL');
  }
}
