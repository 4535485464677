<div class="sso">
    <div class="error-box">
        <div>
            <h1><strong>{{'TOAST_MESSAGES.ERROR_SSO_TITLE' | translate}}</strong></h1>
            <ngb-alert [dismissible]="false" type="danger">{{'TOAST_MESSAGES.ERROR_SSO_TEXT' | translate}}</ngb-alert>
            <hr>
            <a href="{{rediretUri}}">
                {{'GENERAL.BACK' | translate}}</a>
        </div>
    </div>
</div>
