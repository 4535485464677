<div class="articles-for-work">
    <div class="tabsetShadow">
        <ngb-tabset class="articleTabSet">
            <ngb-tab title="{{'SPAREPARTS_VIEW.ASSEMBLIES' | translate}}">
                <ng-template ngbTabContent>
                    <br>
                    <ul class="custom-tree">
                        <li class="list">
                            <div class="clickable" routerLink="{{location.path().replace('/article','')}}">
                                <i class="folder fa fa-circle"></i>
                                <span>
                                  {{'CLUB_SETTINGS.NAVIGATION.'+service.i18n_title | translate}}
                            </span>
                            </div>
                            <ul>
                                <ng-container *ngFor="let pos of service?.posByCar">
                                    <li class="list" *ngFor="let article of pos.partGroup" (click)="getArticles(article)">
                                        <div class="clickable">
                                            <i class="folder fa fa-circle"></i>
                                            <span>{{article.mountPositionText}}</span>
                                        </div>
                                    </li>
                                </ng-container>
                            </ul>
                        </li>

                    </ul>
                </ng-template>
            </ngb-tab>
            <!--Filter-->
            <ngb-tab [disabled]="!(service?.searchResult?.possibleFilters?.length > 0)">
                <ng-template ngbTabTitle>
                    <div [@bounceIn]="service?.searchResult?.possibleFilters?.length > 0">
                        <i class="fa fa-filter" *ngIf="service?.searchResult?.possibleFilters?.length > 0"></i>
                        <span style="margin-left: 5px;">{{'SPAREPARTS_VIEW.FILTER' | translate}}</span>
                        <span *ngIf="service?.searchResult?.possibleFilters?.length > 0">(<span>{{service?.searchResult?.possibleFilters?.length}}</span>)</span>
                    </div>
                </ng-template>
                <ng-template ngbTabContent>
                    <div class="filterTab">
                        <ng-container *ngFor="let groups of service?.searchResult?.possibleFilters">
                            <app-collapsible-panel [folded]="service?.searchResult?.possibleFilters !== 1 && !(service.foldedCollapsePanel.indexOf(groups.groupName) > -1)" (onChangeFolded)="service.toggleFolded($event, groups.groupName)" [panelTitle]="groups.groupName | translate" *ngIf="groups.groupId === 'BRAND'">
                                <div *ngFor="let filter of groups.filters">
                                    <app-checkbox [isChecked]="service.findFilter(filter, true)" [labelText]="filter.displayValue" (onChange)="service.toggleActiveFilter(filter)"></app-checkbox>
                                </div>
                            </app-collapsible-panel>
                        </ng-container>
                        <ng-container *ngFor="let groups of service?.searchResult?.possibleFilters">
                            <app-collapsible-panel [folded]="!(service.foldedCollapsePanel.indexOf(groups.groupName) > -1)" (onChangeFolded)="service.toggleFolded($event, groups.groupName)" [panelTitle]="groups.groupName" *ngIf="groups.groupId !== 'BRAND'">
                                <div *ngFor="let filter of groups.filters">
                                    <app-checkbox [isChecked]="service.findFilter(filter, true)" [labelText]="filter.displayValue" (onChange)="service.toggleActiveFilter(filter)"></app-checkbox>
                                </div>
                            </app-collapsible-panel>
                        </ng-container>
                        <button class="btn-reset-filter" (click)="service.resetFilter()">
                            <span>{{'SPAREPARTS_VIEW.FILTER_RESET' | translate}}</span>
                        </button>
                    </div>
                </ng-template>
            </ngb-tab>
        </ngb-tabset>
    </div>
    <div class="tabsetDisabled" *ngIf="!(service.hasArticles)">
        <ngb-tabset class="articleTabSet">
            <ngb-tab title="{{'GENERAL.ARTICLE' | translate}}">
                <ng-template ngbTabContent>

                </ng-template>
            </ngb-tab>
        </ngb-tabset>
    </div>
</div>

