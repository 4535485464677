<div class="car-by-license-spain">
  <div class="row">
    <div class="col-3">
      <div class="left-label">
      </div>
    </div>
    <div class="col-9">
      <div class="row form-select-item">
        <div class="col-9 registration-ui es">
          <app-material-form-input
                  [label]="'HOME.NUMBER_PLATE' | translate"
                  [disabled]="false"
                  [type]="'text'"
                  [(text)]="numberplate"
                  [focus]="carService.selected === 'ES'"
                  (selected)="carService.selected = 'ES'"
                  (keydown.enter)="onLicensePlateSearch()">
          </app-material-form-input>
        </div>
        <div class="col-3">
          <button #vin class="btn-flat" [disabled]="!(carService.selected === 'ES')"
                  (click)="onLicensePlateSearch()">
            <i class="fa fa-search" *ngIf="!(carService?.loadCar === 'ES')"></i>
            <i class="fa fa-spinner" *ngIf="carService?.loadCar === 'ES'"
               [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
