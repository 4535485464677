import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../services/user/user.service';
import {CartService} from '../../../cart/services/cart.service';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent implements OnInit {

  private sections = [
      {name: 'MAINTENANCE', route: '/maintenance'},
      {name: 'TECHNICAL_DATA', route: '/technical-data'},
      {name: 'WORK', route: '/work'},
      {name: 'TYRE', route: '/tyre'},
  ];

  public selectedSection: string = 'DEFAULT';

  constructor(private router: Router,
              public userService: UserService,
              public cartService: CartService) {

  }

  ngOnInit() {
    for (const section of this.sections) {
      if (this.router.url.indexOf(section.route) > -1) {
        this.selectedSection = section.name;
      }
    }
  }

}
