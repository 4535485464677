import {ICartArticle} from '../../cart/interfaces/cart-article';
import { EventEmitter, Output, Directive } from '@angular/core';
import {SearchService} from './search.service';
import {ArticlesRestService} from '../../articles/services/articles.rest.service';
import {CarService} from './car/car.service';
import {UserService} from './user/user.service';
import {CompanyAdminService} from '../../admin/company/services/company-admin.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ConfigService} from './config.service';
import {finalize} from "rxjs/operators";

@Directive()
export abstract class ArticlesDetailsService extends SearchService {

    public supplier: any[] = [];
    public selectedArticleAvailability: any;
    public additionalArticleDetails: any;
    public additionalDetailsLoading: boolean = false;
    public loadCars: boolean = false;
    public requestQuantity: number = 1;
    private _toCartPosition;
    private _selectedSupplier;
    public sellOutGrossPerPiece: any;
    public selectedImage: any;

    @Output() onChange: EventEmitter<any> = new EventEmitter();

    constructor(private abstractArticlesRestService: ArticlesRestService,
                private abstractCarService: CarService,
                private abstractUserService: UserService,
                private abstractCompanyAdminSerivce: CompanyAdminService,
                private translateService: TranslateService,
                private toastrService: ToastrService,
                private configService: ConfigService) {
        super(abstractCarService, abstractArticlesRestService, abstractUserService, abstractCompanyAdminSerivce, translateService, toastrService, configService);
    }

    public updateArticleDetails(id: any, kTypeId: any, adcTypeId: any, item: any, dropCar: boolean, selectedType = 'AFTER_MARKET') {
        this.selectedArticle = undefined;
        this.selectedArticleAvailability = [];
        this.additionalArticleDetails = undefined;
        this.additionalDetailsLoading = true;
        if (selectedType === 'AFTER_MARKET') {
            this.abstractArticlesRestService.getArticleDetails(
                id,
                kTypeId,
                adcTypeId,
                item.workId,
                item.itemMpId,
                dropCar
            ).pipe(finalize(() =>  this.additionalDetailsLoading = false)).subscribe(
                (response) => {
                    this.handleResponse(response, item)
                }
            );
        } else if (selectedType === 'OE') {
            this.abstractArticlesRestService.getOeArticleDetails(
                id,
                item.brandName,
                kTypeId,
                adcTypeId,
                item.workId,
                item.itemMpId,
                dropCar
            ).pipe(finalize(() =>  this.additionalDetailsLoading = false)).subscribe(
                (response) => {
                    this.handleResponse(response, item)
                }
            );
        }
    }

    public handleResponse(response: any, item: any) {
        this.expanded_right = true;
        this.selectedArticle = response;
        if (this.selectedArticle) {
            if (item.requestQuantity) {
                this.selectedArticle.requestQuantity = item.requestQuantity;
            }
            this.getCompatiblecars();
        }
        this.onChange.emit(null);
    }

    public filterImages() {
        const temp = [];

        for (const image of this.selectedArticle.images) {
            if (this.isImage(image.name)) {
                temp.push(image);
            }
        }

        return temp;
    }

    public isImage(name: string) {
        if (name === undefined) {
            return false;
        }
        return name.toLowerCase().search('bmp') > -1 || name.toLowerCase().search('jpg') > -1 || name.toLowerCase().search('jpeg') > -1;
    }

    private selectSupplier (item: any) {
        this.sellOutGrossPerPiece = item.sellOutGrossPerPiece;
        const quantity = (this.requestQuantity > 0 ? this.requestQuantity : 1);
        const selectedArticle: any = this.selectedArticle,
            newArticle: ICartArticle = {
                articleId: selectedArticle.id,
                brandId: selectedArticle.brandId,
                brandName: selectedArticle.brandName,
                description: selectedArticle.description,
                ean: selectedArticle.ean,
                ipc: selectedArticle.ipc,
                quantity: quantity,
                sellOutNetPerPiece: item.sellInNetPerPiece,
                sellInNetTotalFormatted: item.sellInNetTotalFormatted,
                adcTypeId: selectedArticle.adcTypeId,
                kTypeId: selectedArticle.kTypeId,
                supplier: item.supplier
            };
        this._toCartPosition = newArticle;
        this._selectedSupplier = item.supplier;
    }

    public getCompatiblecars() {
        if (this.selectedArticle && !this.selectedArticle.carLinks) {
            this.loadCars = true;
            if (this.supplierType === 'OE') {
                this.abstractArticlesRestService.getCompatiblecarsOe(this.selectedArticle.ipc).subscribe(
                    (response) => {
                        this.setCarLinks(response)
                    }
                );
            } else {
                this.abstractArticlesRestService.getCompatiblecars(this.selectedArticle.id).subscribe(
                    (response) => {
                       this.setCarLinks(response)
                    }
                );
            }

        } else {
            return this.selectedArticle.carLinks;
        }
    }

    public setCarLinks(response: any) {
        this.loadCars = false;
        if (this.selectedArticle) {
            this.selectedArticle.carLinks = response;
            return this.selectedArticle.carLinks;
        } else {
            return undefined;
        }
    }
}
