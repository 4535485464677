import { Component, OnInit, Input } from '@angular/core';
import {CarService} from '../../services/car/car.service';
import {HelperService} from '../../services/helper.service';
import {UserService} from '../../services/user/user.service';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-external-tools-wrapper',
  templateUrl: './external-tools-wrapper.component.html'
})
export class ExternalToolsWrapperComponent implements OnInit {

  @Input() position: string;

  constructor(public userService: UserService,
              public configService: ConfigService,
              public carService: CarService,
              public helperService: HelperService) { }

  ngOnInit() {
  }
}
