import {EventEmitter, Injectable} from '@angular/core';
import {AutovistaRestService} from './autovista.rest.service';
import {UserService} from '../../shared/services/user/user.service';
import {IAutovistaCar, IAutovistaDetails} from '../../articles/interfaces/autovista';
import {IAccountData} from '../../shared/services/user/interfaces/account-data';
import {CarService} from '../../shared/services/car/car.service';
import {HelperService} from '../../shared/services/helper.service';

@Injectable()
export class AutovistaService {

    public carList: IAutovistaCar[];
    private language: string;
    private country: string;
    public XML: string;
    public _active: string = undefined;
    public showMainGroupImage: boolean = false;
    public mainGroupId: number;
    public zoneCode: number;
    public callOut: number;
    public selectedImage: any;
    public mainGroup: string;
    public detailImage: any;
    public svg: any;
    public selected: any;
    public imageLoading: boolean = false;
    public selectedCar: IAutovistaCar;
    public selectedImageCode: any;
    public criterias: any;
    public detailCallOuts: any;
    public selectCarPrompt: boolean;
    private clicked: string;
    public mainGroupImage: any;
    public pictogram: any;
    public pictogramXML: any;
    public selectedOePart: any;
    public selectedArticle: IAutovistaDetails;
    public selectedArticleChange: EventEmitter<IAutovistaDetails> = new EventEmitter<IAutovistaDetails>();
    public svgChangeEmitter: EventEmitter<string> = new EventEmitter<string>();
    public dsCodes: any;
    public mcGroups: any;
    public svgDoc: any;
    public filter: any;
    public hasfilter: boolean = false;
    public selectedFilter: Map<string, any> = new Map<string, any>();
    public loadDetails: boolean = false;
    public svgHolder: any;
    public loadCarList: boolean = false;
    public selectedGroups: any;
    public selectedAutovistaDetails: IAutovistaDetails;
    public showBodygraphics: boolean;
    public subGroupDetails: any;
    public selectedFilterMap: Map<string, any> = new Map<string, any>();

    public selectedSubgroup: any;
    public selectedGroup: any;
    public loadSubGroupDetails: boolean = false;
    public oldXML: string;
    public selectedId: any;
    public selectedImageUrl: string;
    public showCarOverviewImage: boolean = true;

    public SVGHoverColor: string = '#C71A57';
    public oeSuppliers: any;
    public oeAvailability: any;
    public loadDefaultFilter: boolean = true;
    public selectedDsCode: string;

    constructor(private autovistaRest: AutovistaRestService,
                private userService: UserService,
                private carService: CarService,
                private helperService: HelperService) {
        this.loadOeSupplier();

        this.carService.selectedCarChanged$.subscribe((car) => {
            this.reset();
            this.showBodygraphics = false;
            this.loadDefaultFilter = true;
            this.selectedFilter = new Map<string, any>();
            this.selectedFilterMap = new Map<string, any>();
        });

        let me = this;
        /*
            PREDEFINED SVG IMAGE MANIPULATION
         */
        window['AGV_OBJECT_MOUSEOVER'] = 'AGV_OBJECT_MOUSEOVER';
        window['AGV_OBJECT_MOUSEOUT'] = 'AGV_OBJECT_MOUSEOUT';
        window['AGV_OBJECT_MOUSECLICK'] = 'AGV_OBJECT_MOUSECLICK';
        /*
            inline Methods from SVG
         */
        window['notifyOwner'] = function (a: any, _object: string) {
            // this._active = me._active;
            let ps = false;
            let f = document.querySelectorAll('[objecttype="PS"]');
            if (f.length > 0) {
                ps = true;
            }
            if (a == window['AGV_OBJECT_MOUSECLICK']) {
                if (!me.showCarOverviewImage) {
                    let temp = _object.replace('(PS)', '');
                    temp = temp.replace('_l', '');
                    temp = temp.replace('_r', '');
                    me.selectedId = parseInt(temp);
                } else {
                    let temp: string = _object.replace('(GPS)', '');
                    temp = temp.substring(temp.indexOf('-') + 1);
                    me.selectedId = temp;
                }
                me.clicked = _object.replace('(GPS)', '');
                if (ps) {
                    if (me._active && me._active != _object.split('(')[0]) {
                        const shape = document.getElementById(me._active);
                        if (me._active && me._active != _object.split('(')[0] && shape) {

                            let backdrop: any = shape.getElementsByTagName('g');
                            if (backdrop && me.mcGroups && me.mcGroups.length > 0 && !me.showCarOverviewImage) {
                                for (let g of backdrop) {
                                    if (!g.backdrop) {
                                        let path = g.getElementsByTagName('path');
                                        let i = 0;
                                        let color = '#545454';
                                        for (let child of path) {
                                            child.setAttribute('stroke', 'none');
                                            child.setAttribute('stroke-width', child.getAttribute('stroke-width-old'));
                                            if (i == 0 && !child.getAttribute('backdrop')) {
                                                child.setAttribute('fill', color);
                                            }
                                            if (child.getAttribute('fill') == me.SVGHoverColor && !child.getAttribute('backdrop')) {
                                                child.setAttribute('fill', color);
                                            }
                                            i++;
                                        }
                                    }
                                }
                                me._active = _object.split('(')[0];
                                return;
                            }

                            let childs: any = shape.getElementsByTagName('path');
                            for (let child of childs) {
                                if (child.getAttribute('outline-element')) {
                                    if (child.getAttribute('stroke-old')) {
                                        child.setAttribute('stroke', child.getAttribute('stroke-old'));
                                        child.setAttribute('stroke-width', child.getAttribute('stroke-width-old'));
                                    }
                                }
                            }
                            let polygonChilds: any = shape.getElementsByTagName('polygon');
                            for (let child of polygonChilds) {
                                if (child.getAttribute('stroke-old')) {
                                    child.setAttribute('stroke', child.getAttribute('stroke-old'));
                                    child.setAttribute('stroke-width', child.getAttribute('stroke-width-old'));
                                }
                            }
                        }
                    }
                    me._active = _object.split('(')[0];
                } else {
                    me._active = undefined;
                }
            }
            if (a == window['AGV_OBJECT_MOUSEOVER']) {
                const _id = _object.split('(');
                const shape = document.getElementById(_id[0]);

                if (me._active != _id[0]) {

                    // Open Tooltip
                    if (me.selectedImage) {
                        for (let callout of me.selectedImage.callouts) {
                            if (_id[0].toString() === callout.id.toString() && !document.getElementById(_id[0].toString() + '_tooltip')) {
                                me.addTooltip(_id[0].toString(), callout);
                            }
                        }
                    }

                    let color = me.SVGHoverColor;

                    let backdrop: any = shape.getElementsByTagName('g');
                    if (backdrop && me.mcGroups && me.mcGroups.length > 0 && !me.showCarOverviewImage) {
                        for (let g of backdrop) {
                            if (!g.backdrop) {
                                let path = g.getElementsByTagName('path');
                                let i = 0;
                                for (let child of path) {
                                    if (child.getAttribute('stroke')) {
                                        child.setAttribute('stroke-old', child.getAttribute('stroke'));
                                        child.setAttribute('stroke', color);
                                        child.setAttribute('stroke-width-old', child.getAttribute('stroke-width'));
                                        child.setAttribute('stroke-width', (ps ? '1' : '.75'));
                                        if (i == 0 && !child.getAttribute('backdrop')) {
                                            child.setAttribute('fill', color);
                                        }
                                        if (child.getAttribute('fill') === '#545454' && !child.getAttribute('backdrop')) {
                                            child.setAttribute('fill', color);
                                        }
                                        i++;
                                    }
                                }
                            }
                            if (g.getAttribute('objecttype') === 'FRAME') {
                                let lines = g.getElementsByTagName('line');
                                for (let line of lines) {
                                    line.setAttribute('stroke', me.SVGHoverColor);
                                    line.setAttribute('stroke-width', 5);
                                    line.setAttribute('stroke-dasharray', 10, 10);
                                }
                            }
                        }
                        return;
                    }


                    let pathChilds: any = shape.getElementsByTagName('path');
                    for (let child of pathChilds) {
                        if (child.getAttribute('outline-element')) {
                            if (child.getAttribute('stroke')) {
                                child.setAttribute('stroke-old', child.getAttribute('stroke'));
                                child.setAttribute('stroke', color);
                                child.setAttribute('stroke-width-old', child.getAttribute('stroke-width'));
                                child.setAttribute('stroke-width', (ps ? '1.5' : '.75'));
                            }
                        }
                    }
                    let polygonChilds: any = shape.getElementsByTagName('polygon');
                    for (let child of polygonChilds) {

                        if (child.getAttribute('stroke')) {
                            child.setAttribute('stroke-old', child.getAttribute('stroke'));
                            child.setAttribute('stroke', color);
                            child.setAttribute('stroke-width-old', child.getAttribute('stroke-width'));
                            child.setAttribute('stroke-width', (ps ? '1.5' : '.75'));
                        }
                    }
                    let gChilds: any = shape.getElementsByTagName('g');
                    if (gChilds.length > 1) {
                        let polyGChilds: any = gChilds[1].getElementsByTagName('polyline');
                        for (let child of polyGChilds) {
                            if (child.getAttribute('stroke') && child.getAttribute('outline-element')) {
                                child.setAttribute('stroke-old', child.getAttribute('stroke'));
                                child.setAttribute('stroke', color);
                                child.setAttribute('stroke-width-old', child.getAttribute('stroke-width'));
                                child.setAttribute('stroke-width', (ps ? '1.5' : '.75'));
                            }
                        }
                    }
                }
            }

            if (a == window['AGV_OBJECT_MOUSEOUT']) {
                const _id = _object.split('(');
                const shape = document.getElementById(_id[0]);

                // if Open Tooltip
                if (me.selectedImage) {
                    for (let callout of me.selectedImage.callouts) {
                        if (_id[0].toString() === callout.id.toString()) {
                            me.removeTooltip(_id[0].toString());
                        }
                    }
                }

                if (me._active != _id[0]) {

                    let color = '#545454';
                    let backdrop: any = shape.getElementsByTagName('g');
                    if (backdrop && me.mcGroups && me.mcGroups.length > 0 && !me.showCarOverviewImage) {
                        for (let g of backdrop) {
                            if (!g.backdrop) {
                                let path = g.getElementsByTagName('path');
                                let i = 0;
                                for (let child of path) {
                                    if (child.getAttribute('stroke-old')) {
                                        child.setAttribute('stroke', 'none');
                                        child.setAttribute('stroke-width', child.getAttribute('stroke-width-old'));
                                        if (i == 0 && !child.getAttribute('backdrop')) {
                                            child.setAttribute('fill', color);
                                        }
                                        if (child.getAttribute('fill') == me.SVGHoverColor && !child.getAttribute('backdrop')) {
                                            child.setAttribute('fill', color);
                                        }
                                        i++;
                                    }
                                }
                            }
                            if (g.getAttribute('objecttype') === 'FRAME') {
                                let lines = g.getElementsByTagName('line');
                                for (let line of lines) {
                                    line.setAttribute('stroke', color);
                                    line.setAttribute('stroke-width', 5);
                                    line.setAttribute('stroke-dasharray', 10, 10)
                                }
                            }
                        }
                        return;
                    }


                    let pathChilds: any = shape.getElementsByTagName('path');
                    for (let child of pathChilds) {
                        if (child.getAttribute('stroke-old')) {
                            child.setAttribute('stroke', child.getAttribute('stroke-old'));
                            child.setAttribute('stroke-width', child.getAttribute('stroke-width-old'));
                        }
                    }

                    let polygonChilds: any = shape.getElementsByTagName('polygon');
                    for (let child of polygonChilds) {
                        if (child.getAttribute('stroke-old')) {
                            child.setAttribute('stroke-width', child.getAttribute('stroke-width-old'));
                            child.setAttribute('stroke', child.getAttribute('stroke-old'));
                        }
                    }

                    let gChilds: any = shape.getElementsByTagName('g');
                    if (gChilds.length > 1) {
                        let polyGChilds: any = gChilds[1].getElementsByTagName('polyline');
                        for (let child of polyGChilds) {
                            if (child.getAttribute('stroke-old')) {
                                child.setAttribute('stroke-width', child.getAttribute('stroke-width-old'));
                                child.setAttribute('stroke', child.getAttribute('stroke-old'));
                            }
                        }
                    }
                }
            }
        };
        window['clickZoom'] = function (Event: Event) {
            if (me.dsCodes && me.dsCodes.length > 0) {
                me.selectMainGroupEvent(Event);
            } else if (me.mcGroups && me.mcGroups.length > 0) {
                me.selectedOldMainGroupEvent(Event);
            }
        };

        if (!this.userService.accountData) {
            this.userService.accountData$.subscribe((accountData: IAccountData) => {
                if (accountData) {
                    this.language = accountData.language.toUpperCase();
                    this.country = accountData.country.toUpperCase();
                }
            });
        } else {
            this.language = this.userService.accountData.language.toUpperCase();
            this.country = this.userService.accountData.country.toUpperCase();
        }
    }

    public updateSvg(button: string) {
        this.svgChangeEmitter.emit(button);
    }

    private loadOeSupplier() {
        this.autovistaRest.getOeSuppliers().subscribe(
            (suppliers) => {
                this.oeSuppliers = suppliers;
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_OE_SUPPLIERS', 'error');
            });
    }

    public addTooltip(id: string, callout: any) {
        if (callout && callout.partNames && callout.partNames.length > 0) {
            const b = document.getElementById(id).getBoundingClientRect();
            const iDiv = document.createElement('div');
            iDiv.id = id + '_tooltip';
            iDiv.className = ' ' + 'in av-tooltip';
            iDiv.setAttribute('x', '-3em');
            iDiv.setAttribute('dx', '1em');
            iDiv.setAttribute('style', 'top:' + (b.top - 15 - (callout.partNames.length * 17)) + 'px;left:' + (b.left - 50) + 'px;');
            iDiv.innerHTML = '';
            for (const name of callout.partNames) {
                iDiv.innerHTML = iDiv.innerHTML + name + ' </br> ';
            }
            iDiv.innerHTML = iDiv.innerHTML + ' <div class="arrow"></div> ';
            document.getElementById('svgWrapper').appendChild(iDiv);
        }
    }

    public removeTooltip(id: string) {
        const _tooltip = document.getElementById(id + '_tooltip');
        if (_tooltip) {
            document.getElementById('svgWrapper').removeChild(_tooltip);
        }
    }

    public reset() {
        this.carList = [];
        this.mcGroups = [];
        this.XML = undefined;
        this.pictogramXML = undefined;
        this.showMainGroupImage = false;
        this.selectedImage = undefined;
        this.selectedCar = undefined;
        this.selectedImageCode = undefined;
        this.selectCarPrompt = false;
        this.selectedOePart = false;
        this.selectedArticle = undefined;
        this.svg = undefined;
        this.selected = undefined;
        this.dsCodes = [];
        this.filter = [];
        this.hasfilter = false;
        this.imageLoading = false;
        this.selectedGroup = undefined;
        this.selectedSubgroup = undefined;
        this.loadSubGroupDetails = false;
        this.subGroupDetails = undefined;
        this.oldXML = undefined;
        this.svgDoc = undefined;
    }

    private selectMainGroupEvent(Event: any) {
        this.showMainGroupImage = false;
        let doc = Event.currentTarget;
        let parent = doc.parentNode;
        if (parent.id.startsWith('DS')) {
            this.mainGroupId = parseFloat(parent.id.substr(parent.id.length - 3));
            this.selectMainGroup(parent.id);
            return;
        } else if (parent.id && parent.id != '') {
            this.zoneCode = parent.id;
            this.callOut = parent.id;
            for (let callout of this.selectedImage.callouts) {
                if (parent.id.toString() === callout.id.toString()) {
                    this.getSelectedOePartDetails(callout.id);
                    return;
                }
            }
        }
    }

    private selectedOldMainGroupEvent(event: any) {
        setTimeout(() => {
            if (this.showCarOverviewImage) {
                this.mcGroups.forEach((group) => {
                    if (group.id === this.selectedId.substring(0, 1)) {
                        this.selectedGroup = group;
                        group.subGroups.forEach((subGroup) => {
                            if (subGroup.id === this.selectedId) {
                                this.selectedSubgroup = subGroup;
                                this.showCarOverviewImage = false;
                            }
                        });
                    }
                });
                if (!this.selectedSubgroup) {
                    this.selectedGroup = this.selectedId;
                    this.selectedSubgroup = {id: this.selectedId};
                }
                this.getSubGroupDetails()
            } else {
                this.getSelectedOePartDetails(this.selectedId);
            }
        }, 100);
    }

    public collectFilter() {
        let selectedFilter: any[] = [];
        for (let key in this.selectedImage.filter) {
            for (let crit of this.selectedImage.filter[key]) {
                if (crit.active) {
                    selectedFilter.push(crit.criteriaCode);
                }
            }
        }
        return selectedFilter;
    }

    public changeCriteria() {
        this.deselectOePartDetails();

        for (let callout of this.selectedImage.callouts) {
            if (callout.showCriteriaCodes.length === 0 &&
                callout.hideCriteriaCodes.length === 0) {
                let g = this.svgDoc.getElementById(callout.id);
                if (g) {
                    g.setAttribute('visibility', 'true');
                }
            } else {
                let show = (callout.showCriteriaCodes).length === 0;
                for (let criteriaCode of callout.showCriteriaCodes) {
                    if (this.hasCriteriaFilter(criteriaCode)) {
                        show = true;
                    }
                }
                for (let criteriaCode of callout.hideCriteriaCodes) {
                    if (this.hasCriteriaFilter(criteriaCode)) {
                        show = false;
                    }
                }
                let g = this.svgDoc.getElementById(callout.id);
                if (g) {
                    g.setAttribute('visibility', (show ? 'true' : 'hidden'));
                }
            }
        }

        if (this.helperService.browserIsEdge()) {
            this.svgDoc.innerHTML = this.svgDoc.innerHTML + ' ';
        }
    }

    public hasCriteriaFilter(criteria: any) {
        let found = false;

        this.selectedFilter.forEach((value) => {
            if (value === criteria) {
                found = true;
            }
        });

        return found;
    }

    public getSelectedOePartDetails(id: any) {
        if (id) {
            this.loadDetails = true;
            this.selectedOePart = id;
            let vin = '';
            const vistaVinCodes: string[] = [];
            if (this.carService.getAutoVistaVinResponse() && this.carService.getAutoVistaVinResponse().specification) {
                this.carService.getAutoVistaVinResponse().specification.esacos.forEach(code => vistaVinCodes.push(code.codes[0]));
            }

            if (this.carService.getVin()) {
                vin = this.carService.getVin()
            }

            this.selectedAutovistaDetails = undefined;
            setTimeout(() => {
                this.autovistaRest.getPartDetail(this.selectedCar.natCode, this.selectedCar.ikeCode, this.selectedImageCode, id, this.carService.getKTypeId(), this.carService.getAdcTypeId(), vistaVinCodes, vin).subscribe(
                    (partDetail: any) => {
                        this.selectedArticle = partDetail;
                        this.selectedArticle.id = id;
                        this.selectedArticleChange.emit(this.selectedArticle);
                        this.loadDetails = false;
                    }
                );
            }, 50);
        }

        if (!this.selectedArticle && !id) {
            this.deselectOePartDetails();
            return;
        }
        return this.selectedArticle;
    }

    public deselectOePartDetails() {
        if (this._active) {
            const shape = document.getElementById(this._active);
            if (shape) {
                let childs: any = shape.getElementsByTagName('path');
                for (let child of childs) {
                    if (child.getAttribute('outline-element')) {
                        if (child.getAttribute('stroke-old')) {
                            child.setAttribute('stroke', child.getAttribute('stroke-old'));
                            child.setAttribute('stroke-width', child.getAttribute('stroke-width-old'));
                        }
                    }
                }
                let polygonChilds: any = shape.getElementsByTagName('polygon');
                for (let child of polygonChilds) {
                    if (child.getAttribute('stroke-old')) {
                        child.setAttribute('stroke', child.getAttribute('stroke-old'));
                        child.setAttribute('stroke-width', child.getAttribute('stroke-width-old'));
                    }
                }
            }
        }

        this._active = undefined;
        this.selectedOePart = undefined;
        this.selectedArticle = undefined;
    }

    public selectMainGroup(dsCode: any) {
        if (this.selectedFilter.size > 0) {
            this.selectedFilterMap.set(this.selectedDsCode, this.selectedFilter);
            this.selectedFilterMap.set(this.selectedDsCode + '_filter', this.filter);
        }
        this.selectedDsCode = dsCode;
        this.loadDefaultFilter = !this.selectedFilterMap.has(dsCode);
        this.imageLoading = true;
        this.hasfilter = false;
        this.resetZone();
        this.showMainGroupImage = false;

        this.autovistaRest.getCarDetail(this.selectedCar.ikeCode, dsCode, this.loadDefaultFilter, this.carService.getVin()).subscribe(
            (carDetail: any) => {
                if (Object.keys(carDetail.detailImages).length > 0) {
                    this.mainGroup = dsCode;
                    this.selected = dsCode;
                    this.selectedGroup = dsCode;
                    this.svg = undefined;
                    this.buildDetail(carDetail);
                    this.svgHolder = carDetail;
                    Object.keys(carDetail.detailImages).forEach((temp: any) => {
                        this.getImageXML(carDetail.detailImages[temp].url);
                    });
                } else {
                    this.helperService.showNotification('TOAST_MESSAGES.INFO_EMPTY_DETAILIMAGES', 'error');
                    this.imageLoading = false;
                }
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                this.imageLoading = false;
            });
    }

    private buildDetail(data: any) {
        this.detailImage = data.detailImages;
        this.selectedFilter = new Map<string, any>();
        Object.keys(this.detailImage).forEach((key) => {
            this.selectedImage = this.detailImage[key];
            this.selectedImageCode = key;
            this.filter = this.detailImage[key].filter;
            if (!this.loadDefaultFilter) {
                this.selectedFilter = this.selectedFilterMap.get(this.selectedDsCode);
                this.filter = this.selectedFilterMap.get(this.selectedDsCode + '_filter');
                this.hasfilter = true;
            } else {
                Object.keys(this.filter).forEach((filterKey) => {
                    this.hasfilter = true;
                    this.filter[filterKey].forEach((filter) => {
                        if (filter.active) {
                            this.selectedFilter.set(filterKey, filter.criteriaCode);
                        }
                    });
                });
            }
        });
        if (Object.keys(this.detailImage).length > 1) {
            Object.keys(this.selectedImage.filter).forEach((crit: string) => {
                this.selectedImage.filter[crit].active = true;
            });
        }
    }

    public getImages() {
        if (this.svgHolder) {
            return Object.keys(this.svgHolder.detailImages);
        } else {
            return [];
        }
    }

    public resetZone() {
        this.selectCarPrompt = false;
        this.mainGroup = undefined;
        this.callOut = undefined;
        this.zoneCode = undefined;
        this.detailImage = undefined;
        this.detailCallOuts = undefined;
        this.showMainGroupImage = true;
        this.criterias = undefined;
        this.selected = undefined;
    }

    public getCarList() {
        if (!this.selectedCar || this.selectedCar.kTypeId !== this.carService.getKTypeId()) {
            this.carList = [];
            this.selectCarPrompt = true;
            this.loadCarList = true;
            this.autovistaRest.getCarList(this.carService.getKTypeId(), this.carService.getVin()).subscribe(
                (carList: IAutovistaCar[]) => {
                    this.loadCarList = false;
                    this.carList = carList;
                    if (this.carList && this.carList.length === 1) {
                        this.imageLoading = true;
                        this.getCarOverview(this.carList[0]);
                    }
                },
                (error) => {
                    this.loadCarList = false;
                });
        } else {
            this.showBodygraphics = true;
        }
    }

    public getCarOverview(car: any) {
        this.selectCarPrompt = false;
        this.selected = undefined;
        this.mainGroup = undefined;
        this.loadSubGroupDetails = false;
        this.selectedGroup = undefined;
        this.selectedSubgroup = undefined;
        this.mcGroups = [];
        this.subGroupDetails = undefined;
        this.autovistaRest.getCarOverview(car.natCode, car.ikeCode).subscribe(
            (carOverview: any) => {
                if (carOverview) {
                    this.dsCodes = carOverview.dsCodes;
                    this.mcGroups = carOverview.mcGroups;
                    this.imageLoading = false;
                    this.showCarOverviewImage = !(this.dsCodes && this.dsCodes.length > 0);

                    this.getImageXML(carOverview.name, false, car);
                } else {
                    this.imageLoading = false;
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                }
            },
            (error) => {
                this.imageLoading = false;
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
            });
    }

    public getImageXML(file: any, forPictogram: boolean = false, car?: any, old: boolean = false) {
        this.autovistaRest.getImageXML(file).subscribe((XML: string) => {
            if (!forPictogram) {
                if (!old) {
                    this.XML = this.replaceXMLHeader(XML);
                } else {
                    this.oldXML = this.replaceXMLHeader(XML);
                }

                this.showBodygraphics = true;
                if (car) {
                    this.selectedCar = car;
                }
                this.selectedAutovistaDetails = undefined;
            } else {
                this.pictogramXML = this.replaceXMLHeader(XML);
            }
        })
    }

    public replaceXMLHeader(XML: string) {
        return XML.replace('<?xml version="1.0" encoding="UTF-8" standalone="no"?>', '');
    }

    public getAdditionalPartsKeys() {
        if (this.subGroupDetails) {
            return Object.keys(this.subGroupDetails.additionalParts);
        } else {
            return 0
        }
    }

    public getSubGroupDetails() {
        this.loadSubGroupDetails = true;
        this.showCarOverviewImage = false;
        this.autovistaRest.getSubGroupDetails(this.selectedCar.natCode, this.selectedCar.ikeCode, this.selectedSubgroup.id).subscribe(
            (response) => {
                this.subGroupDetails = response;
                this.loadSubGroupDetails = false;
            },
            (error) => {
                this.loadSubGroupDetails = false;
            });
    }
}
