<div class="log">
  <div class="heading blue">
    <span class="clickable" routerLink="/admin">
      <i class="fa fa-th" aria-hidden="true"></i>
      {{'ADMIN.LOG.TITLE' | translate}}
    </span>
  </div>
  <div class="panel-content center-content white-background">
    <app-default-loading-page *ngIf="loadingData"></app-default-loading-page>
    <table class="simple-table" *ngIf="logData?.length > 0 && !loadingData">
      <thead>
        <tr>
          <th>{{'USER_PROFILE.USER_MANAGEMENT.USERNAME' | translate}}</th>
          <th>{{'GENERAL.DATE' | translate}}</th>
          <th>{{'ADMIN.LOG.CHANGE' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let log of logData">
          <td>{{log.editor}}</td>
          <td>{{log.date | date:'dd.MM.yyyy'}}</td>
          <td>
            <div class="changeDiv" *ngFor="let change of log.diff">
              <span>
                {{change}}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="alert alert-info text-center" *ngIf="(!logData || logData.length === 0) && !loadingData">
      <i class="fa fa-info-circle mr-2"></i>
      <span>{{'ADMIN.LOG.NO_DATA' | translate}}</span>
    </div>
  </div>
</div>
