import {Component, OnInit} from '@angular/core';
import {ConfigService} from '../../../../shared/services/config.service';
import {UserService} from '../../../../shared/services/user/user.service';
import {AdminService} from '../../services/admin.service'
import {HelperService} from '../../../../shared/services/helper.service';
import {ISetting} from '../../../../shared/interfaces/DTO/club/setting';

@Component({
    selector: 'app-language-config',
    templateUrl: './language-config.component.html'
})
export class LanguageConfigComponent implements OnInit {

    public languageConfig: ISetting;

    constructor(
        public configService: ConfigService,
        public userService: UserService,
        public adminService: AdminService,
        public helperService: HelperService) {
            this.configService.changeClubConfig.subscribe(() => {
                this.getLanguageConfig();
            });
    }

    ngOnInit() {
        this.getLanguageConfig();
    }

    private getLanguageConfig() {
        this.languageConfig = this.configService.getSubSettingByName('LANGUAGE', 'LANGUAGES');
    }

    public get defaultLanguage() {
        for (const lang of this.languageConfig.selected) {
            if (lang.second) {
                return lang.first;
            }
        }
    }

    public set defaultLanguage(language: string) {
        for (const possible of this.languageConfig.selected) {
            possible.second = (possible.first === language);
        }
        this.configService.getSubSettingByName('LANGUAGE', 'LANGUAGES').selected = this.languageConfig.selected;
        this.configService.addUnsavedSetting(this.configService.selectedSettingName);
    }

    public isCheckedLanguage(language: string) {
        for (const lang of this.languageConfig.selected) {
            if (lang.first === language) {
                return true;
            }
        }

        return false;
    }

    public togglePossible(language, event: any) {
        if (event.target.checked) {
            this.languageConfig.selected.push({first: language, second: false});
        } else if (this.languageConfig.selected.length <= 1) {
            event.target.checked = true;
        } else {
            for (let i = 0; i < this.languageConfig.selected.length; i++) {
                if (this.languageConfig.selected[i].first === language) {
                    this.languageConfig.selected.splice(i, 1);
                }
            }
        }
        this.configService.getSubSettingByName('LANGUAGE', 'LANGUAGES').selected = this.languageConfig.selected;
        this.configService.addUnsavedSetting(this.configService.selectedSettingName);
    }


}
