<div class="row form-select-group">
  <div class="col-3">
    <div class="left-label">
      {{ 'HOME.KBA_NUMBER' | translate }}
    </div>
  </div>
  <div class="col-9">
    <div class="row">
      <div class="col-9">
        <div class="row" *ngIf="userService.accountData">
          <div class="col-6 kba-padding-hsn">
            <app-material-form-input
                    #hsn
                    [label]="'HOME.HSN_PLACEHOLDER' | translate"
                    [disabled]="false"
                    [type]="text"
                    [(text)]="_hsn"
                    [focus]="(!carService.selected || (carService.selected === 'KBA_NUMBER' && (!_hsn || _hsn.length < 4)))"
                    (selected)="carService.selected = 'KBA_NUMBER'"
                    (keydown.enter)="onHsnTsnSearch()">
            </app-material-form-input>
          </div>

          <div class="col-6 kba-padding-tsn">
            <app-material-form-input
                    #tsn
                    [label]="'HOME.TSN_PLACEHOLDER' | translate"
                    [disabled]="false"
                    [type]="text"
                    [(text)]="_tsn"
                    [focus]="(carService.selected === 'KBA_NUMBER' && _hsn && _hsn.length === 4 )"
                    (selected)="carService.selected = 'KBA_NUMBER'"
                    (keydown.enter)="onHsnTsnSearch()">
            </app-material-form-input>
          </div>
        </div>
      </div>
      <div class="col-3">
        <button class="btn-flat" #hsnTsn (click)="onHsnTsnSearch()" tabindex="3">
          <i class="fa fa-search" *ngIf="!(carService?.loadCar === 'KBA-NUMBER')"></i>
          <i class="fa fa-spinner" *ngIf="carService?.loadCar === 'KBA-NUMBER'"
             [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"></i>
        </button>
      </div>
    </div>

  </div>
</div>
