<div class="global-edit-country">
    <div class="heading blue">
        <span class="clickable" style="cursor: pointer;" routerLink="/global-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <span class="clickable" routerLink="/global-admin/country">
            <i class="fa fa-caret-right"></i>
            {{'GLOBAL_ADMIN.COUNTRY_CONFIG.TITLE' | translate}}
        </span>
        <span class="">
            <i class="fa fa-caret-right"></i>
            {{globalAdminService?.selectedCountry?.name}}
        </span>
    </div>
    <div class="global-notifications-content panel-content center-content white-background">
        <h1>{{'GLOBAL_ADMIN.COUNTRY_CONFIG.EDIT.TITLE' | translate}}</h1>

        <div class="row">
            <div class="col">
                <app-material-form-input [(text)]="globalAdminService.selectedCountry.name"
                                         [label]="'GLOBAL_ADMIN.COUNTRY_CONFIG.EDIT.NAME' | translate">
                </app-material-form-input>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <app-material-form-input [(text)]="globalAdminService.selectedCountry.currency"
                                         [label]="'GLOBAL_ADMIN.COUNTRY_CONFIG.EDIT.CURRENCY' | translate">
                </app-material-form-input>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <app-material-form-input [(text)]="globalAdminService.selectedCountry.iso31661Numeric"
                                         [label]="'GLOBAL_ADMIN.COUNTRY_CONFIG.EDIT.ISO_NUMERIC' | translate">
                </app-material-form-input>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <app-material-form-input [(text)]="globalAdminService.selectedCountry.vat"
                                         [type]="'number'"
                                         [label]="'GLOBAL_ADMIN.COUNTRY_CONFIG.VAT' | translate">
                </app-material-form-input>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <app-material-form-input [(text)]="globalAdminService.selectedCountry.iso31661Alpha2"
                                         [maxLength]="2"
                                         [label]="'GLOBAL_ADMIN.COUNTRY_CONFIG.EDIT.ISO_ALPHA_TWO' | translate">
                </app-material-form-input>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <app-material-form-input [(text)]="globalAdminService.selectedCountry.iso31661Alpha3"
                                         [maxLength]="3"
                                         [label]="'GLOBAL_ADMIN.COUNTRY_CONFIG.EDIT.ISO_ALPHA_THREE' | translate">
                </app-material-form-input>
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                <span class="default-language">{{'GLOBAL_ADMIN.COUNTRY_CONFIG.EDIT.DEFAULT_LANGUAGE' | translate}}</span>
            </div>
            <div class="col-10">
                <app-dropdown [preSelectedItem]="globalAdminService.selectedCountry.defaultLanguage"
                              [items]="languageList"
                              (onItemSelect)="globalAdminService.selectedCountry.defaultLanguage = $event">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <button class="base-btn success" *ngIf="showSaveButton()" (click)="save()">
                    <i class="fa fa-check mr-2"></i>
                    {{'GLOBAL_ADMIN.COUNTRY_CONFIG.EDIT.SAVE' | translate}}
                </button>
            </div>
            <div class="col-6">
                <button class="base-btn float-right" routerLink="/global-admin/country">
                    {{'GENERAL.BACK' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
