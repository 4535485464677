<div class="tyre-info">
    <div class="panel-content center-content "
         [ngClass]="{'print-content': pdf && location.path().endsWith('/cart/tyre')}">
        <div class="maintenance-data_title">
            <button class="base-btn pull-right"
                    (click)="tyreService.printPdf(false, selectedTyreType, tyreSpecs, selectedCar)"
                    *ngIf="pdf">
                <i class="fa fa-print"></i> {{ 'PRINT_VIEW.PRINT' | translate }}
            </button>

            <button class="base-btn pull-right"
                    (click)="tyreService.downloadPdf(false, selectedTyreType, tyreSpecs, selectedCar)" *ngIf="pdf">
                <i class="fa fa-save"></i> {{ 'PRINT_VIEW.GENERATE_PDF' | translate }}
            </button>
            <h1 *ngIf="!pdf">
                <strong>{{ 'MAINTENANCE.INFO' | translate}}</strong>
            </h1>
            <h1 *ngIf="pdf">
                <strong>{{ 'MAINTENANCE.INFO' | translate }}</strong>
            </h1>
        </div>
        <app-default-loading-page *ngIf="loading"></app-default-loading-page>
        <div class="print-preview" *ngIf="pdf && !loading" style="top: 0!important;">
            <pdf-viewer
                    [src]="pdf"
                    [autoresize]="false"
                    [render-text]="false"
                    [show-all]="true"
                    style="display: block;"
            ></pdf-viewer>
        </div>
        <ng-container *ngIf="!pdf && !loading">
            <div class="row" *ngFor="let specs of tyreSpecs" class="striped-table">
                <div class="col">
                    <div class="row">
                        <div class="col headerType">
                            <span class="selection title">{{specs.name}}</span>
                        </div>
                    </div>
                    <div class="row" *ngFor="let subSpecs of specs.specItems">
                        <div class="col">
                            {{subSpecs.mountPositionText}}
                        </div>
                        <div class="col">
                            <div *ngFor="let specValues of subSpecs.specValues">
                                {{specValues.valueText}} {{specValues.quantityText}} {{specValues.qualifierText}}
                                <span *ngIf="specValues.additionText">{{specValues.additionalText}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="spacer"></div>
                </div>
            </div>
        </ng-container>
    </div>
</div>


