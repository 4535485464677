import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {CartService} from "../../cart/services/cart.service";
import {UserService} from "../../shared/services/user/user.service";
import {environment} from "../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {MaintenanceService} from "../../maintenance/services/maintenance.service";
import {WorkService} from "../../work-data/services/work.service";
import {ConfigService} from '../../shared/services/config.service';
import {CarService} from '../../shared/services/car/car.service';
import {ThemeService} from '../../shared/services/theme.service';
import {PartnerService} from '../../shared/services/partner/partner.service';
import {CompanyAdminService} from '../../admin/company/services/company-admin.service';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html'
})
export class HeaderComponent implements OnInit, AfterViewInit {
    public imageUrlPrefix: string;
    public burgerOpen: boolean = false;
    public firmSettings: any;
    public showResponsiveMenu: boolean = false;
    private resizeTimeout: any;

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.calculateWidthForScreen();
    }

    @ViewChild('fullWidth') fullWidth: ElementRef;
    @ViewChild('navigationMenuWidth') navigationMenuWidth: ElementRef;
    @ViewChild('logoWidth') logoWidth: ElementRef;
    @ViewChild('userMenuWidth') userMenuWidth: ElementRef;

    constructor(
        public cartService: CartService,
        public carService: CarService,
        public userService: UserService,
        public maintenanceService: MaintenanceService,
        public workService: WorkService,
        public route: ActivatedRoute,
        public location: Location,
        public configService: ConfigService,
        public themeService: ThemeService,
        public partnerService: PartnerService,
        public companyAdminService: CompanyAdminService
    ) {
        this.imageUrlPrefix = environment.baseUrl;
    }

    ngAfterViewInit() {
        this.calculateWidthForScreen();
    }

    private calculateWidthForScreen(): void {
        const fullWidth = this.fullWidth.nativeElement.offsetWidth;
            const navigationMenuWidth = this.navigationMenuWidth.nativeElement.offsetWidth;
            const userMenuWidth = this.userMenuWidth.nativeElement.offsetWidth;
            const logoWidth = this.logoWidth.nativeElement.offsetWidth;
            const spaceWidth = fullWidth - userMenuWidth - logoWidth - 25;
            this.showResponsiveMenu = spaceWidth <= navigationMenuWidth;
    }

    ngOnInit() {
        this.companyAdminService.firmSettingEmitter.subscribe((response) => {
            this.firmSettings = response;
            if (this.firmSettings && this.firmSettings.startInSaleMode.value === true) {
                this.userService.sale = true;
            }
        });
    }

}
