import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ICarProducer} from '../../interfaces/DTO/car/carProducer';
import {ICarModel} from '../../interfaces/DTO/car/carModel';
import {ICarType} from '../../interfaces/DTO/car/carType';
import {ICar, ICarNumberplate} from '../../interfaces/DTO/car/car';
import {CustomerDataService} from '../customerData.service';

@Injectable()
export class CarRestService {

    constructor(private http: HttpClient,
                private customerDataService: CustomerDataService) {
    }

    public getCarProducers(vehicleType: string = 'ALL'): Observable<ICarProducer[]> {
        return this.http.get<ICarProducer[]>(
            `${environment.apiUrl}/tecdoc/car/v2/producers/${vehicleType}`
        );
    }

    public getCarModelsByProducerId(producerId: number, vehicleType: string = 'ALL'): Observable<ICarModel[]> {
        return this.http.get<ICarModel[]>(
            `${environment.apiUrl}/tecdoc/car/v2/models/${producerId}/${vehicleType}`
        );
    }

    public getCarTypesByProducerIdAndModelId(producerId: number, modelId: number, vehicleType: string = 'ALL'): Observable<ICarType[]> {
        return this.http.get<ICarType[]>(
            `${environment.apiUrl}/tecdoc/car/v2/types/${producerId}/${modelId}/${vehicleType}`
        );
    }

    public getCarsByNumberplate(numberplate: string): Observable<ICar[] | ICarNumberplate> {
        return this.http.get<ICar[] | ICarNumberplate>(
            `${environment.apiUrl}/numberplate/${numberplate}`
        )
    }

    public getCarsByTypenumber(typenumber: string): Observable<ICar[]> {
        return this.http.get<ICar[]>(
            `${environment.apiUrl}/typenumber/${typenumber}`
        )
    }

    public getCarsByKTypeNumber(kTypeNumber: number): Observable<ICar[]> {
        return this.http.get<ICar[]>(
            `${environment.apiUrl}/tecdoc/car/byktypeid/${kTypeNumber}`
        );
    }

    public getCarsByHsnTsn(hsn: string, tsn: string): Observable<ICar[]> {
        if (hsn && tsn) {
            return this.http.get<ICar[]>(
                `${environment.apiUrl}/tecdoc/car/byhsntsn/${hsn}/${tsn}`
            );
        } else {
            return  throwError(' no hsn/tsn');
        }
    }

    public getCarsByMotorcode(motorCode: string): Observable<ICar[]> {
        return this.http.get<ICar[]>(
            `${environment.apiUrl}/tecdoc/car/bymotorcode/${motorCode}`
        );
    }

    public getCarsByVIN(vin: string): Observable<ICar[]> {
        if (this.customerDataService.getHsn() && this.customerDataService.getTsn()) {
            return this.http.get<ICar[]>(
                `${environment.apiUrl}/tecdoc/car/byVin/${vin}?hsn=${this.customerDataService.getHsn()}&tsn=${this.customerDataService.getTsn()}`,
            );
        } else {
            return this.http.get<ICar[]>(
                `${environment.apiUrl}/tecdoc/car/byVin/${vin}`,
            );
        }
    }

    public addToCarHistory(car: ICar) {
        const params = {
            adcTypeId: car.adcTypeId,
            displayName: car.displayName,
            hsn: car.hsn,
            kTypeId: car.kTypeId,
            tsn: car.tsn,
            valid: car.valid,
            vsn: car.vsn,
            vin: car.vin,
            natCode: car.natCode,
            ikeCode: car.ikeCode
        };
        return this.http.post(
            `${environment.apiUrl}/tecdoc/car/select.do`, params
        );
    }

    public getCarHistory(): Observable<ICar[]> {
        return this.http.get<ICar[]>(
            `${environment.apiUrl}/session/carhistory`
        );
    }

}
