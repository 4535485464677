<div class="template">
  <div class="heading blue">
    <span class="clickable" (click)="adminService.club = undefined" routerLink="/admin">
      <i class="fa fa-th" aria-hidden="true"></i>
    </span>
    <span class="clickable" routerLink="/admin/template">{{'TEMPLATE.TITLE' | translate}}</span>
    <span *ngIf="templateService.templateType">
      <i class="fa fa-caret-right"></i>
      <span class="clickable" routerLink="/admin/template/{{templateService.templateType}}">{{'TEMPLATE.' + templateService.templateType.toUpperCase() | translate}}</span>
    </span>
    <button class="dropdown-btn float-right" (click)="showTemplateMenu = !showTemplateMenu" [ngClass]="{'opened': showTemplateMenu}">
      <i class="fa fa-plus"></i>
      <span>{{'TEMPLATE.CREATE' | translate}}</span>
    </button>
    <ul class="elements" *ngIf="showTemplateMenu">
      <li (click)="createTemplate('article')">
        <span>{{'TEMPLATE.ARTICLE' | translate}}</span>
      </li>
      <li (click)="createTemplate('work')">
        <span>{{'TEMPLATE.WORK' | translate}}</span>
      </li>
      <li (click)="createTemplate('text')">
        <span>{{'TEMPLATE.TEXT' | translate}}</span>
      </li>
    </ul>
  </div>
  <div class="panel-content center-content white-background" (click)="showTemplateMenu = false">
    <div class="assembly-categories" *ngIf="!templateService.templateType && location.path().endsWith('/admin/template')">
      <div class="assembly-category" routerLink="/admin/template/all">
        <div class="name-wrapper-no-image">
          <span>{{'TEMPLATE.ALL' | translate}}</span>
        </div>
      </div>
      <div class="assembly-category" routerLink="/admin/template/article">
        <div class="name-wrapper-no-image">
          <span>{{'TEMPLATE.ARTICLE' | translate}}</span>
        </div>
      </div>
      <div class="assembly-category" routerLink="/admin/template/work">
        <div class="name-wrapper-no-image">
          <span>{{'TEMPLATE.WORK' | translate}}</span>
        </div>
      </div>
      <div class="assembly-category" routerLink="/admin/template/text">
        <div class="name-wrapper-no-image">
          <span>{{'TEMPLATE.TEXT' | translate}}</span>
        </div>
      </div>
    </div>

    <div class="templateList" *ngIf="templateService.templateType">
      <table class="simple-table">
        <thead>
          <tr>
            <th>{{'TEMPLATE.TABLE.NUMBER' | translate}}</th>
            <th>{{'TEMPLATE.TABLE.NAME' | translate}}</th>
            <th>{{'TEMPLATE.TABLE.QUANTITY' | translate}}</th>
            <th *ngIf="templateService.templateType === 'all'">{{'TEMPLATE.TABLE.TYPE' | translate}}</th>
            <th>{{'TEMPLATE.TABLE.PRICE' | translate}}</th>
            <th>{{'TEMPLATE.TABLE.OPTIONS' | translate}}</th>
          </tr>
        </thead>
        <tbody *ngIf="templateService?.templateList?.length > 0">
          <tr *ngFor="let template of templateService.getTemplateListWithType(templateService.templateType)">
            <td>{{template.ipc}}</td>
            <td>{{template.description}}</td>
            <td>{{template.quantity}} {{'CART.' + template.quantityUnit.toUpperCase() | translate}}</td>
            <td *ngIf="templateService.templateType === 'all'">{{'TEMPLATE.' + template.type | translate}}</td>
            <td>{{template.sellOutNetPerPiece | currency:helperService?.getCurrency(template):symbol:'.2-2'}}</td>
            <td>
              <i class="fa fa-pencil" routerLink="/admin/template/edit/{{template.id}}"></i>
              <i class="fa fa-trash" (click)="templateService.deleteTemplate(template.id)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
