<div class="tyre">
    <div class="content clean sidebar-opener-wrapper" *ngIf="(stateService.tyreContent | async) === 'CONTENT'">
        <!-- TOGGLE FOR TABLET VIEW -->
        <div class="sidebar-opener" [ngClass]="userService?.getNavigation()">
            <ul>
                <li (click)="userService?.toggleNavigation()">
                    <i class="fa fa-chevron-right"></i>
                </li>
            </ul>
        </div>

        <!-- LEFT PANEL -->
        <div class="left-panel no-float" [ngClass]="userService?.getNavigation()">
            <div class="panel-content left-sidebar">
                <ul class="custom-tree" [@leftTreeAnim]>

                    <!-- INFORMATION -->
                    <li class="list">
                        <div class="clickable" [ngClass]="{'active': checkView('TYRE_INFO')}"
                             (click)="setView('TYRE_INFO')">
                            <i class="folder fa fa-circle" [ngClass]="{'open': checkView('TYRE_INFO')}"></i>
                            <span>{{'MAINTENANCE.INFO' | translate}}</span>
                        </div>
                    </li>

                    <!-- TYRE SEARCH -->
                    <li class="list">
                        <div (click)="resetSearch(); setView('TYRE_SEARCH')"
                             [ngClass]="{'active': checkView('TYRE_SEARCH')}">
                            <i class="folder fa fa-circle"
                               [ngClass]="{'open': checkView('TYRE_SEARCH') || checkView('TYRE_TABLE')}"></i>
                            <span>{{'TYRE.TYRESEARCH' | translate}}</span>
                        </div>
                        <ul class="custom-tree" *ngIf="checkView('TYRE_SEARCH') || checkView('TYRE_TABLE')">
                            <li class="list" *ngFor="let category of tyreTypes">
                                <div (click)="openType(category)"
                                     [ngClass]="{'active': category?.id === selectedTyreType?.id}">
                                    <i class="folder fa fa-circle"
                                       [ngClass]="{'open': checkView('TYRE_TABLE') && category?.id === selectedTyreType?.id}"></i>
                                    <span>{{'TYRE.ABE' | translate}}: {{category?.abe}} | {{'TYRE.VSN' | translate}}
                                        : {{category?.vsn}}</span>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>

        <!-- CENTER PANEL -->
        <div class="center-panel no-float" [ngClass]="userService?.getNavigation()">
            <!-- HEADER BAR -->
            <div class="heading blue">
                <span (click)="resetSearch()" class="clickable">
                    <i class="fa fa-th" aria-hidden="true"></i>
                    <span>{{'TYRE.TYRE' | translate}}</span>
                </span>
                <span class="clickable" *ngIf="checkView('TYRE_INFO')">
                    <i class="fa fa-caret-right"></i>
                    <span>{{'MAINTENANCE.INFO' | translate}}</span>
                </span>
                <span class="clickable" *ngIf="checkView('TYRE_SEARCH')">
                    <i class="fa fa-caret-right"></i>
                    <span>{{'TYRE.TYRESEARCH' | translate}}</span>
                </span>
                <span *ngIf="checkView('TYRE_TABLE')">
                    <i class="fa fa-caret-right 1"></i>
                    {{'TYRE.ABE' | translate}}: {{ selectedTyreType?.abe}}
                    | {{'TYRE.VSN' | translate}}: {{ selectedTyreType?.vsn}}
                </span>
                <!-- PRINT -->
                <span *ngIf="checkView('TYRE_INFO') || checkView('TYRE_TABLE')">
                    <button class="print-btn pull-right"
                            (click)="tyreService.createPdf(false, selectedTyreType, tyreSpecs, selectedCar)"
                            *ngIf="!pdf && checkView('TYRE_INFO')">
                        <i class="fa fa-file-pdf-o"></i>
                        <span>{{'PRINT_VIEW.TITLE' | translate}}</span>
                    </button>

                    <button class="print-btn pull-right"
                            (click)="tyreService.createPdf(true, selectedTyreType, tyreSpecs, selectedCar)"
                            *ngIf="!pdf && checkView('TYRE_TABLE')">
                        <i class="fa fa-file-pdf-o"></i>
                        <span>{{'PRINT_VIEW.TITLE' | translate}}</span>
                    </button>

                    <button class="print-btn pull-right" (click)="tyreService.resetPDF()"
                            *ngIf="pdf">
                        <i class="fa fa-close"></i>
                        <span>{{'PRINT_VIEW.CLOSE' | translate}}</span>
                    </button>
                </span>
            </div>

            <!-- TYRE INFO -->
            <div class="panel-content center-content print-content" *ngIf="checkView('TYRE_INFO')"
                 [ngClass]="{'setting' : !pdf}">
                <app-tyre-information></app-tyre-information>
            </div>

            <!-- TYRE TABLE -->
            <div class="panel-content center-content print-content"
                 *ngIf="selectedTyreType && checkView('TYRE_TABLE')"
                 [ngClass]="{'setting' : !pdf}">
                <app-tyre-details></app-tyre-details>
            </div>

            <!-- TYRE SEARCH -->
            <div class="panel-content center-content white-background" *ngIf="checkView('TYRE_SEARCH')">
                <div class="search">
                    <div class="search-field">
                        <app-material-form-input [type]="'text'" [label]="'TYRE.SEARCHTERM' | translate"
                                                 [text]="searchTerm"
                                                 (textChange)="searchTerm = $event; searchTyre(searchTerm)">
                        </app-material-form-input>
                        <i class="fa fa-search clickable" (click)="searchTyre(searchTerm)"></i>
                    </div>

                    <div class="search-result">
                        <ul class="item-list" [@workListAnim]="(tyreList ? tyreList.length : 0)">
                            <ng-container *ngFor="let item of tyreList">
                                <li (click)="openType(item)">
                                    <i class="fa fa-cogs" aria-hidden="true"></i>
                                    <span class="item-name">{{'TYRE.ABE' | translate}}: {{item.abe}}
                                        | {{'TYRE.VSN' | translate}}: {{item.vsn}}</span>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- RIGHT PANEL -->
        <div class="right-panel no-float" [ngClass]="userService?.getNavigation()">
            <div class="panel-content right-sidebar">
                <div class="inner-content">
                    <app-car-details></app-car-details>
                </div>
            </div>
        </div>
    </div>

    <app-content-overlay [showContent]="stateService.tyreContent | async" [newRoute]="'/tyre'"></app-content-overlay>
</div>
