import { Component, OnInit } from '@angular/core';
import {AdminService} from "../../services/admin.service";

@Component({
  selector: 'app-firm-settings',
  templateUrl: './firm-settings.component.html',
  styleUrls: ['./firm-settings.component.scss']
})
export class FirmSettingsComponent implements OnInit {

  constructor(public adminService: AdminService) { }

  ngOnInit() {
  }

}
