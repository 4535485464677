import {EventEmitter, Injectable, Output} from '@angular/core';
import {UserService} from '../shared/services/user/user.service';
import {CarService} from '../shared/services/car/car.service';
import {TecRmiRestService} from '../shared/services/tecrmi/tecrmi.rest.service';
import {TranslateService} from '@ngx-translate/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import {PdfService} from '../shared/services/pdf.service';
import {Router} from '@angular/router';
import {HelperService} from '../shared/services/helper.service';
import {ConfigService} from '../shared/services/config.service';


@Injectable()
export class TechnicalDataService {

    @Output() pdfResult = new EventEmitter();
    selectedsubChild: any;
    nxDiag: any;
    public adcTypeId: number;
    public nxDetails: any;
    public nxDetailsTitle: any;
    public nxDiagSub: any;
    public nxDiagFolder: any;
    public manual: any;
    public selectedItem: any;
    public selectedCar: any;
    public showPdf: boolean = false;
    public pdfLoading: boolean = false;
    public pdf: any;
    public selectedCat: any;
    public selectedChildCat: string;
    public selectedDataCat: string;
    public selectedChild: string;
    public nxManualUrl: any;
    public isDiagnosis: boolean;
    public isCarDetails: boolean;
    public category: any;
    public autoOpenDetails: boolean;

    public openCarDetailsEquipmentEmitter: EventEmitter<any> = new EventEmitter<any>();
    public autoOpenDetailsEmitter: EventEmitter<any> = new EventEmitter<any>();

    constructor(public userService: UserService,
                public carService: CarService,
                public pdfService: PdfService,
                private router: Router,
                private tecRmiRestService: TecRmiRestService,
                public helperService: HelperService,
                public configService: ConfigService,
                private translateService: TranslateService) {
    }
    public setAutoOpenDetails(value: boolean) {
        this.autoOpenDetails = value;
        this.autoOpenDetailsEmitter.emit(this.autoOpenDetails);
    }
    public openCarDetailsEquipment() {
        this.selectedCat = undefined;
        this.selectedDataCat = undefined;
        this.selectedItem = undefined;
        this.selectedChildCat = undefined;
        this.selectedChild = '';
        this.nxDetails = undefined;
        this.nxManualUrl = undefined;
        this.isDiagnosis = false;
        this.isCarDetails = true;
        this.showPdf = false;

        this.openCarDetailsEquipmentEmitter.emit(this.category);
    }
    public generatePdf() {
        let details = this.nxDetails,
            bodyElements: any[] = [],
            docDefinition: any = this.pdfService.buildDocDefinition(false, this.carService.selectedCar);

        for (var i = 0; i < details.length; i++) {

            let translated = details[i].name;
            translated = this.translateService.instant(translated);

            docDefinition.content.push(
                {
                    text: translated,
                    margin: [0, 10, 0, 10],
                    fontSize: 12
                }
            );


            var spechText = '';
            for (var o = 0; o < details[i].specItems.length; o++) {

                for (var county = 0; county < details[i].specItems[o].specValues.length; county++) {

                    spechText = spechText + details[i].specItems[o].specValues[county].valueText;
                    spechText = spechText + ' ' + details[i].specItems[o].specValues[county].quantityText;
                    spechText = spechText + ' ' + details[i].specItems[o].specValues[county].qualifierText;
                    if (details[i].specItems[o].specValues.length > county + 1) {
                        spechText = spechText + '\n ';
                    }
                }
                translated = this.translateService.instant(details[i].specItems[o].mountPositionText);
                bodyElements.push([
                    {text: translated, alignment: 'left'},
                    {text: spechText, alignment: 'left'}
                ]);
                spechText = '';
            }

            docDefinition.content.push(
                {
                    style: 'cartTable',
                    table: {
                        widths: ['*', '*'],
                        body: bodyElements
                    }
                }
            );
            bodyElements = [];
        }

        this.showPdf = true;
        return docDefinition;
    }

    public createPdf() {
        this.pdfResult.emit({pdfShow: true, pdfLoading: true});

        this.getPdf(this.generatePdf()).then(
            (pdf: any) => {
                this.pdfLoading = false;
                this.pdf = pdf;
                this.pdfResult.emit({pdf: pdf, pdfLoading: false});
            },
            (error: any) => {
                console.log(error);
                this.pdfLoading = false;
                this.pdfResult.emit({pdfLoading: false});
            }
        );
    }

    public downloadPdf() {
        return pdfMake.createPdf(this.generatePdf()).download();
    }

    public printPdf() {
        return pdfMake.createPdf(this.generatePdf()).print();
    }

    public getPdf(docDefinition: any) {
        return <any>new Promise(function (resolve: any, reject: any) {
            pdfMake.createPdf(docDefinition, 'filename').getBase64(function (pdfBase64: any) {
                const raw = atob(pdfBase64);
                const uint8Array = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    uint8Array[i] = raw.charCodeAt(i);
                }
                resolve(uint8Array);
            });
        });
    }

}
