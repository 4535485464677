import {Component, EventEmitter, Input, OnInit, Output, OnChanges, ChangeDetectorRef, SimpleChanges} from '@angular/core';
import {CarRestService} from '../../../services/car/car.rest.service';
import {CarService} from '../../../services/car/car.service';
import {UserService} from '../../../services/user/user.service';
import {HelperService} from '../../../services/helper.service';
import {ICar} from '../../../interfaces/DTO/car/car';
import {timestamp} from 'rxjs/operators';

@Component({
    selector: 'app-car-by-kba',
    templateUrl: './car-by-kba.component.html'
})
export class CarByKbaComponent implements OnInit, OnChanges {

    public _hsn: string;
    public _tsn: string;
    public text: any;
    @Output() applyCars: EventEmitter<ICar[]> = new EventEmitter();
    @Input() hsn: string;
    @Input() tsn: string;

    constructor(public carService: CarService,
                private carRestService: CarRestService,
                public userService: UserService,
                private cdRef: ChangeDetectorRef,
                public helperService: HelperService) {
    }

    ngOnInit() {
        if (this.cdRef && !this.cdRef['destroyed']) {
            this.cdRef.detectChanges();
        }
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes && changes.hsn) {
            this._hsn = this.hsn;
        }

        if (changes && changes.tsn) {
            this._tsn = this.tsn;
        }

        this.carService.selected = 'KBA_NUMBER';
        this.cdRef.detectChanges();
    }

    public onHsnTsnSearch() {
        this.carService.loadCar = 'KBA-NUMBER';
        this.carRestService.getCarsByHsnTsn(this._hsn, this._tsn).subscribe(
            (response: ICar[]) => {
                this.carService.loadCar = undefined;
                if (!response || response.length === 0) {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_CANT_FIND_CAR', 'error');
                } else {
                    if (!this.userService.getCatalogMode()) {
                        this.carService.fromDMS = false;
                    }
                    this.applyCars.emit(response);
                }
            },
            (error) => {
                if (error.status === 409) {
                    this.carService.handleCarConflict();
                } else {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                }
                this.carService.loadCar = undefined;
            }
        );
    }
}
