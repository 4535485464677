import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ICustomPositions, IPositionFormPosition} from '../interfaces/position-form';
import {CartRestService} from '../services/cart.rest.service';
import {CartService} from '../services/cart.service';
import {ErrorHandlerService} from '../../shared/components/error-handler/services/error-handler.service';
import {Router} from '@angular/router';
import {ConfigService} from '../../shared/services/config.service';
import {CarService} from '../../shared/services/car/car.service';
import {AccountRestService} from '../../admin/profile/services/account.rest.service';
import {IFirm} from '../../shared/interfaces/DTO/firm/firm';
import {HelperService} from '../../shared/services/helper.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {IGenArt} from '../../shared/interfaces/IGenArt';
import {finalize} from 'rxjs/operators';
import {DataGenartRestService} from '../../shared/services/data-genart.rest.service';
import {IWageRates} from "../../shared/interfaces/wage-rate";

@Component({
    selector: 'app-add-custom-article',
    templateUrl: './add-custom-article.component.html',
    styleUrls: ['./add-custom-article.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class AddCustomArticleComponent implements OnInit {
    public wageRates: IWageRates = <IWageRates>{};
    public mockLoadingForDom: boolean = false;
    public freePosition: IPositionFormPosition;
    public customPositions: ICustomPositions[];
    public customPositionsAW: ICustomPositions[];
    public customPositionsARTICLE: ICustomPositions[];
    public customPositionsTEXT: ICustomPositions[];
    public activePosition: any;
    public number: number;
    public firmData: IFirm;
    public genArts: IGenArt[] = [];
    public genArtValidationError: boolean = false;
    public genArtLoading: boolean;
    // FormGroups
    public customArticlesForm: UntypedFormGroup;
    private selectedGenartElement: string = '';

    constructor(private cartRestService: CartRestService,
                private dataGenArtRestService: DataGenartRestService,
                public cartService: CartService,
                public errorHandlerService: ErrorHandlerService,
                private router: Router,
                public configService: ConfigService,
                public carService: CarService,
                public accountService: AccountRestService,
                public helperService: HelperService) {
        this.freePosition = {
            description: '',
            genericArticleIds: [],
            ipc: '',
            quantity: 0,
            quantityUnit: '',
            save: false,
            sellOutNetPerPiece: 0,
            type: '',
            id: undefined
        }
    }

    ngOnInit() {
        this.cartRestService.getWageRateForCart().subscribe((wageRates: IWageRates) => {
                this.wageRates = wageRates;
            },
            () => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET_WAGE_RATES', 'error');
            });

        this.genArtLoading = true;
        this.dataGenArtRestService.getGenArtsRest().pipe(finalize(() => {
            this.genArtLoading = false;
        })).subscribe(genarts => {
            this.genArts = genarts;
        }, () => this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET', 'error'))


        this.customArticlesForm = new UntypedFormGroup({
            ipcForm: new UntypedFormControl(''),
            descriptionForm: new UntypedFormControl(''),
            quantityForm: new UntypedFormControl('')
        });

        this.errorHandlerService.newError = false;
        this.activePosition = null;
        this.loadCustomPosition();
        this.accountService.getFirmData().subscribe(
            (response) => {
                this.firmData = response;
            }
        );
    }


    public checkValid($event: any) {
        this.freePosition.quantity = $event;
        if (this.freePosition.quantity.toString().length > 0) {
            this.freePosition.quantity = Number.parseFloat(this.freePosition.quantity.toString().replace(',', '.'));
        }
    }


    public findGenArtById(item: number): string {
        const found = this.genArts.find(element => element.ids.includes(item));
        if (found.description) {
            return found.description
        } else {
            return ''
        }
    }


    public setGenArt(genart: string) {
        if (genart && genart.length) {
            const groupedElement = this.genArts.find(element => element.description == genart);
            if (groupedElement && groupedElement.ids) {
                this.selectedGenartElement = groupedElement.description;
                groupedElement.ids.forEach(id => this.freePosition.genericArticleIds.push(id));
                this.genArtValidationError = false;
            } else {
                this.selectedGenartElement = '';
                this.genArtValidationError = true
            }
        } else {
            this.selectedGenartElement = '';
            this.genArtValidationError = false;
            this.freePosition.genericArticleIds = [];
        }
    }


    public loadCustomPosition() {
        this.customPositionsARTICLE = [];
        this.customPositionsAW = [];
        this.customPositionsTEXT = [];
        this.customPositions = [];
        let me = this;

        this.cartRestService.getCustomPositions('AW').subscribe(
            (response => {
                this.customPositionsAW = response;
                this.customPositionsAW.forEach(function (pos) {
                    me.customPositions.push(pos);
                });
            }),
            (error => {
                this.errorHandlerService.throwError(error.status)
            })
        );
        this.cartRestService.getCustomPositions('ARTICLE').subscribe(
            (response => {
                this.customPositionsARTICLE = response;
                this.customPositionsARTICLE.forEach(function (pos) {
                    me.customPositions.push(pos);
                });
            }),
            (error => {
                this.errorHandlerService.throwError(error.status)
            })
        );
        this.cartRestService.getCustomPositions('TEXT').subscribe(
            (response => {
                this.customPositionsTEXT = response;
                this.customPositionsTEXT.forEach(function (pos) {
                    me.customPositions.push(pos);
                });
            }),
            (error => {
                this.errorHandlerService.throwError(error.status)
            })
        );
    }

    public takeValue(customPosition: ICustomPositions[]): void {
        for (const position of customPosition) {
            if (position.id === parseInt(this.activePosition)) {
                this.freePosition.sellOutNetPerPiece = position.sellOutNetPerPiece;
                this.freePosition.quantity = position.quantity;
                this.freePosition.type = position.type;
                this.freePosition.description = position.description;
                this.freePosition.ipc = position.ipc;
                this.freePosition.quantityUnit = position.quantityUnit;
                this.freePosition.id = position.id;
                this.freePosition.vin = this.carService.getVin();
                this.freePosition.producerName = this.carService.getCarProducerName();


                if (position.genericArticleIds && position.genericArticleIds.length > 0) {
                    const name = this.findGenArtById(position?.genericArticleIds[0]);
                    document.getElementById('genart').setAttribute('value', name);
                    this.setGenArt(name);
                }

                if (position.clubId) {
                    this.freePosition.clubId = position.clubId;
                } else {
                    this.freePosition.clubId = undefined;
                }
            }
        }
    }

    public selectType(positionType: string) {
        if (positionType === 'ARTICLE') {
            this.mockLoadingForDom = true;
            setTimeout(() => {
                this.mockLoadingForDom = false;
            }, 250);
        }

        if (positionType !== this.freePosition.type) {
            this.freePosition.sellOutNetPerPiece = 0;
            this.freePosition.quantity = 0;
            this.freePosition.description = '';
            this.freePosition.ipc = '';
            this.activePosition = null;

            this.freePosition.type = positionType;

            if (positionType === 'AW') {
                this.freePosition.quantityUnit = 'HOUR';
            } else {
                this.freePosition.quantityUnit = 'PIECE';
            }
        }
    }

    public deleteValue() {
        for (const position of this.customPositions) {
            if (position.id === parseInt(this.activePosition)) {
                this.cartRestService.deleteCustomPosition(position.id).subscribe(
                    (success) => {
                        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
                        this.loadCustomPosition();
                    });
            }
        }
    }

    public validate() {
        if (this.freePosition && this.freePosition.type && this.freePosition?.type === 'ARTICLE') {
            return this.freePosition.quantity > 0 && (this.freePosition.ipc && this.freePosition.ipc.length > 0);
        } else {
            return this.freePosition.quantity > 0 && (this.freePosition.ipc && this.freePosition.ipc.length > 0) && (this.freePosition.description && this.freePosition.description.length > 0);
        }
    }

    public addToCart() {
        if (this.freePosition.quantity > 0) {
            if (this.freePosition.save) {
                this.freePosition.id = undefined;
            }

            !this.freePosition.description && this.selectedGenartElement && (this.freePosition.description = this.selectedGenartElement)

            this.freePosition.sellOutNetPerPiece = Number(this.freePosition.sellOutNetPerPiece.toString().replace(',', '.'));
            this.cartRestService.addFreePositionToCart(this.freePosition).subscribe(
                (success) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                    this.cartService.updateCart(false, false, false, false);
                    this.loadCustomPosition();
                    this.router.navigate(['/cart']);
                },
                (error) => {
                    this.errorHandlerService.throwError(error.status)
                })
        }
    }


}
