<div class="cart-article-selection">
    <div class="heading blue">
    <span class="clickable" routerLink="/cart">
      <i class="fa fa-th open" routerLink="/cart"></i>
      <span>{{ 'CART.TITLE' | translate }}</span>
    </span>
        <span class="cursor-default">
      <i class="fa fa-caret-right mr-2"></i>
      <span>{{cartService?.currentCart?.name}}</span>
    </span>
    </div>

    <div class="panel-content center-content">
        <div class="flex-container" [ngClass]="{'browserIsIE': helperService.browserIsIE()}">

            <div class="alert alert-success" *ngIf="cartService.getRequiredItemCount() === 0">
                <i class="fa fa-check mr-2"></i>
                <span class="success-text">{{'CART.ALL_ARTICLES_SELECTED' | translate}}</span>
                <button class="base-btn float-right" routerLink="/cart">
                    <span>{{'CART.BACK_TO_CART' | translate}}</span>
                </button>
            </div>

            <div class="static-content" [ngClass]="{'noScrollButton': !showScrollButton}">
                <div class="tabs" [ngClass]="{'browserIsIE': helperService.browserIsIE()}">
                    <button *ngIf="showScrollButton()" id="scrollButton" class="tabSetScrollButton left"
                            (click)="getNextTab(false)"><i
                            class="fa fa-angle-left"></i></button>
                    <div class="viewport" [ngClass]="{'align-left': !showScrollButton()}" #viewport>
                        <ul class="tabList" id="tabList" #tabList>
                            <li class="listItem"
                                [ngClass]="{'selected': selectedTab?.genericArticleNumber === element?.genericArticleNumber, 'finished': element?.referencedPosId > -1}"
                                (click)="setTab(element, tabList.childNodes[i + 1], element.quantity)"
                                *ngFor="let element of cartService?.currentCart?.requiredItems; index as i">
          <span>
              <span *ngIf="element.quantity > 1">
                  {{element.quantity + 'x '}}
              </span>
              {{element.genericArticleName}}
          </span>
                            </li>
                        </ul>
                    </div>
                    <button *ngIf="showScrollButton()" class="tabSetScrollButton right" (click)="getNextTab(true)"><i
                            class="fa fa-angle-right"></i></button>
                </div>
                <div class="tabContent">
                    <div class="innerTabContent">
                        <span><b>{{'ARTICLE_SELECTION.SELECTED_ARTICLE' | translate}}</b></span>

                        <div class="row">
                            <div class="col-5">

                                <!-- no article selected -->
                                <ng-container *ngIf="selectedTab?.referencedPosId < 0">
                                    <span>{{'ARTICLE_SELECTION.NO_SELECTED_ARTICLE' | translate}}</span>
                                    <br/>
                                    <b> {{selectedTab?.quantity}}
                                        <span>
                    {{'CART.' + selectedTab?.quantityUnit | translate}}
                  </span>
                                        <span *ngIf="!(selectedTab?.quantityUnit?.length > 0)">
                            {{'CART.PIECE' | translate}}
                        </span>
                                        {{'ARTICLE_SELECTION.NEEDED_SMALL'| translate}}
                                    </b>
                                </ng-container>

                                <div class="row" *ngIf="cartService.showRetryButton">
                                    <div class="col">
                                        <button class="base-btn"
                                                (click)="searchArticle(selectedTab.genericArticleNumber, selectedTab.associatedCar, cartService.requestQuantity)">
                                            <i class="fa fa-refresh mr-2"></i>
                                            <span>{{'MAINTENANCE.RETRY_SEARCH' | translate}}</span>
                                        </button>
                                    </div>
                                </div>

                                <ng-container *ngIf="selectedTab && selectedTab?.referencedPosId > -1">
                                    <div class="row">
                                        <div class="col-5">
                                            <span>{{'CART.MANUFACTURER' | translate}}</span>
                                        </div>
                                        <div class="col-7">
                                            <span>{{getArticleByPosId(selectedTab.referencedPosId)?.brandName}}</span>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-5">
                                            <span>{{'CART.DESCRIPTION' | translate}}</span>
                                        </div>
                                        <div class="col-7">
                                            <span>{{getArticleByPosId(selectedTab.referencedPosId)?.description}}</span>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-5">
                                            <span>{{'CART.ARTICLE_NUMBER' | translate}}</span>
                                        </div>
                                        <div class="col-7">
                                            <span>{{getArticleByPosId(selectedTab.referencedPosId)?.articleId}}</span>
                                        </div>
                                    </div>
                                </ng-container>

                            </div>
                            <div class="col-7">
                                <div class="row">
                                    <div class="col-6">
                                        <ng-container *ngIf="selectedTab?.referencedPosId > -1">
                                            <div class="row">
                                                <div class="col-6">
                                                    <span>{{'TEMPLATE.TABLE.QUANTITY' | translate}}</span>
                                                </div>
                                                <div class="col-6">
                                                    <span>{{getSelectedPosition()?.quantity}}</span>
                                                    <span> {{'GENERAL.UNITS.' + getSelectedPosition()?.quantityUnit | translate}}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-6">
                                                    <span>{{ userService.sale? ('CART.SINGLE_PRICE_SALE' | translate ): ('CART.SINGLE_PRICE' | translate) }}</span>
                                                </div>
                                                <div class="col-6">
                                                    <span *ngIf="userService.sale">{{getSelectedPosition()?.sellInNetPerPieceFormatted}}</span>
                                                    <span *ngIf="!userService.sale">{{getSelectedPosition()?.sellOutNetPerPieceFormatted}}</span>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-6">
                                                    <span>{{'ARTICLE_SELECTION.NEEDED' | translate}}</span>
                                                </div>
                                                <div class="col-6">
                          <span>
                            {{selectedTab?.quantity}}
                              <span *ngIf="selectedTab?.quantityUnit?.length > 0">
                              {{'CART.' + selectedTab?.quantityUnit | translate}}
                            </span>
                            <span *ngIf="!(selectedTab?.quantityUnit?.length > 0)">
                              {{'CART.PIECE' | translate}}
                            </span>
                          </span>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col">
                                                <button *ngIf="(!getSelectedPosition() && !cartService.isOrder()) || (getSelectedPosition()?.type !== 'PLACEHOLDER') && cartService?.isNewestCart() && !cartService.isOrder()"
                                                        class="base-btn placeholder-button"
                                                        (click)="addPlaceholderToCart()">
                                                    <span *ngIf="!placeholderLoading">{{'ARTICLE_SELECTION.PLACEHOLDER_BUTTON' | translate}}</span>
                                                    <i class="fa fa-spinner"
                                                       [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"
                                                       *ngIf="placeholderLoading"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="getSelectedPosition()?.supplier">
                                            <div class="col">
                                                <img class="supplier_logos" [supplier]="getSelectedPosition()?.supplier"
                                                     [logo-color]="true"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pseudo-article" *ngIf="cartService?.showPseudoArticle && cartService?.selectedPseudoArticle">
                        <div class="row wrapper-row">
                            <div class="col-4">
                                <img class="image" src="{{cartService?.selectedPseudoArticle?.previewImageUrl}}">
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col">
                                        <span class="description">{{cartService?.selectedPseudoArticle?.description}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <span class="brand">{{cartService?.selectedPseudoArticle?.brandName}} {{cartService?.selectedPseudoArticle?.ipc}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col-12">
                                        <button class="base-btn" (click)="showPseudoArticleDetails(cartService.selectedPseudoArticle)">{{'ARTICLE_SELECTION.SHOW_DETAILS' | translate}}</button>
                                    </div>
                                </div>
                                <div class="row spacer">
                                    <div class="col-12">
                                        <button class="base-btn" (click)="goBackToSearch()">{{'ARTICLE_SELECTION.BACK_TO_SEARCH' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="scroll-content" appInfiniteScroller (onScrollEnd)="cartService.loadNext($event)"
                 [ngClass]="{'articlesLoading': cartService.articlesLoading}">
                <app-articles-search-result [service]="cartService" *ngIf="!cartService.articlesLoading || cartService.infinityLoading"></app-articles-search-result>
                <app-default-loading-page *ngIf="cartService.articlesLoading && !cartService.infinityLoading"></app-default-loading-page>
                <div class="alert alert-info"
                     *ngIf="!cartService.articlesLoading && !(cartService?.searchResult?.items?.length > 0) && !cartService.showRetryButton">
                    <i class="fa fa-info-circle mr-2"></i>
                    <span>{{'CART.NO_ARTICLES' | translate}}</span>
                </div>

                <div class="alert alert-danger"
                     *ngIf="cartService.showRetryButton">
                    <i class="fa fa-times mr-2"></i>
                    <span>{{'TOAST_MESSAGES.ERROR_SEARCH_ARTICLES' | translate}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
