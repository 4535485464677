<div class="maintenance-data">
    <div class="heading blue">
     <span class="clickable" routerLink="/maintenance">
      <i class="fa fa-th" aria-hidden="true"></i>
    </span>
        <span routerLink="/maintenance"
              class="clickable">
        {{'CLUB_SETTINGS.NAVIGATION.MAINTENANCE' | translate}}
    </span>
    </div>
</div>
<div class="panel-content center-content">
    <div *ngIf="carService.selectedCar.serviceInfos?.length > 0 && userService.allow('VEHICLE_CALLBACK')">
        <div class="ml-2 mr-3 alert alert-info">
            <div class="clearfix"></div>
            <div class="row">
                <div class="col-9 text-left">
                    <i class="fa fa-warning"></i>
                    {{'GENERAL.SHOW_RECALL_INFORMATION' | translate}}
                </div>
                <div class="col-3 text-right clickable" routerLink="/maintenance/info/warnings"
                     (click)="maintenanceService.setSelectedCarInfos(carService.selectedCar.serviceInfos)">
                    <strong>{{'GENERAL.SHOW_INFORMATION' | translate}} <i class="fa fa-angle-right"></i></strong>
                </div>
            </div>
        </div>
    </div>
    <div class="assembly-categories">
        <div *ngIf="(maintenanceService?.maintenance?.mdAddItemMpKorWorksDTO?.length > 0) && (maintenanceService?.maintenance?.mdItemKorWorksDTO?.length > 0) && !cartService.isOrder()"
             class="assembly-category" routerLink="/maintenance/plan">
            <div class="name-wrapper-no-image">
                <span>{{ 'MAINTENANCE.MAIN_WORK' | translate}}</span>
            </div>
        </div>
        <div class="assembly-category" *ngIf="!(maintenanceService?.maintenance?.mdAddItemMpKorWorksDTO?.length > 0) || !(maintenanceService?.maintenance?.mdItemKorWorksDTO?.length > 0)"
             tooltip
             tooltipPosition="center"
             tooltipContent="{{'CAR_HISTORY.TOOLTIP.NO_DATA' | translate}}">
            <div class="name-wrapper-no-image noData">
                <span>{{ 'MAINTENANCE.MAIN_WORK' | translate}}</span>
            </div>
        </div>
        <div class="assembly-category" routerLink="/maintenance/suggestion" *ngIf="maintenanceService?.gearBox?.length > 0 && !cartService.isOrder()">
            <div class="name-wrapper-no-image">
                <span>{{ 'MAINTENANCE.SUGGESTION' | translate}}</span>
            </div>
        </div>
        <div class="assembly-category" *ngIf="!(maintenanceService?.gearBox?.length > 0)"
             tooltip
             tooltipPosition="center"
             tooltipContent="{{'CAR_HISTORY.TOOLTIP.NO_DATA' | translate}}">
            <div class="name-wrapper-no-image noData">
                <span>{{ 'MAINTENANCE.SUGGESTION' | translate}}</span>
            </div>
        </div>
        <div class="assembly-category" routerLink="/maintenance/info">
            <div class="name-wrapper-no-image">
                <span>{{ 'MAINTENANCE.INFO' | translate}}</span>
            </div>
        </div>
        <div class="assembly-category" routerLink="/maintenance/hints" *ngIf="maintenanceService?.manual?.length > 0">
            <div class="name-wrapper-no-image">
                <span>{{'SPAREPARTS_VIEW.CAR_DETAILS.MAINTENANCE_NOTES' | translate}}</span>
            </div>
        </div>
        <div class="assembly-category" *ngIf="maintenanceService?.manual?.length === 0"
             tooltip
             tooltipPosition="center"
             tooltipContent="{{'CAR_HISTORY.TOOLTIP.NO_DATA' | translate}}">
            <div class="name-wrapper-no-image noData">
                <span>{{'SPAREPARTS_VIEW.CAR_DETAILS.MAINTENANCE_NOTES' | translate}}</span>
            </div>
        </div>
    </div>
</div>


