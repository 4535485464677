import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../shared/services/user/user.service';
import {CarService} from '../shared/services/car/car.service';
import {TecRmiRestService} from '../shared/services/tecrmi/tecrmi.rest.service';
import {CartRestService} from '../cart/services/cart.rest.service';
import {TranslateService} from '@ngx-translate/core';
import {CartService} from '../cart/services/cart.service';
import {MaintenanceService} from './services/maintenance.service';
import {ActivatedRoute,  Router} from '@angular/router';
import {Location} from '@angular/common';
import {bounceIn, sidebar} from '../shared/animations/sidebar';
import {fadeAnimation} from '../shared/animations/fade.animation';
import {CustomerDataService} from '../shared/services/customerData.service';
import {ArticlesService} from '../articles/services/articles.service';
import {ConfigService} from '../shared/services/config.service';
import {HelperService} from '../shared/services/helper.service';
import {StateService} from '../shared/services/state.service';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    animations: [sidebar, fadeAnimation, bounceIn]
})
export class MaintenanceComponent implements OnInit, AfterViewInit, AfterViewChecked {

    private right_sidebar: string;

    @ViewChild('tabSet', {static: true}) ngbTabSet;

    constructor(public userService: UserService,
                public carService: CarService,
                private tecRmiRestService: TecRmiRestService,
                private translate: TranslateService,
                private cartRestServoce: CartRestService,
                public cartService: CartService,
                public configService: ConfigService,
                private location: Location,
                private route: ActivatedRoute,
                public stateService: StateService,
                public router: Router,
                public articlesService: ArticlesService,
                public maintenanceService: MaintenanceService,
                public dataService: CustomerDataService,
                private changeDetectorRef: ChangeDetectorRef,
                public helperService: HelperService) {

        this.stateService.updateMaintenanceContent();

        route.url.subscribe(() => {
            this.right_sidebar = route.snapshot.children[0].data['state'];
        });

        this.maintenanceService.selectFirstTabEmitter.subscribe(() => {
            this.selectFirstTab();
        });
    }

    ngOnInit() {
        if (this.maintenanceService.lastState !== '') {
            this.router.navigate([this.maintenanceService.lastState]);
        }
        this.maintenanceService.startUpCheck();
        this.maintenanceService.getPosByCart();
        this.maintenanceService.selectedArticle = undefined;

    }

    ngAfterViewInit() {

        this.cartService.onCartChange.subscribe((step) => {
            if (this.ngbTabSet && this.ngbTabSet.tabs) {
                this.selectFirstTab();
            }
        });

        this.maintenanceService.onToggleState.subscribe((step) => {
           if (this.ngbTabSet && this.ngbTabSet.tabs) {
               this.selectFirstTab();
           }
        });

        if (this.maintenanceService.activeMaintenanceStep) {

            this.maintenanceService.showAdditionalWork = true;
        }

        if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    public showNavigation() {
        this.userService.setNavigationLeft();
        if (this.ngbTabSet && this.ngbTabSet.tabs) {
            this.ngbTabSet.select(this.ngbTabSet.tabs.first.id);
        }
    }



    public showFilter() {
        if (this.hasFilter() && this.ngbTabSet && this.ngbTabSet.tabs) {
            this.userService.setNavigationLeft();
            this.ngbTabSet.select(this.ngbTabSet.tabs.last.id);
        }
    }

    public hasFilter() {
        return (this.maintenanceService.searchResult && this.maintenanceService.searchResult.possibleFilters && this.maintenanceService.searchResult.possibleFilters.length > 0);
    }

    ngAfterViewChecked() {
        if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    public selectFirstTab() {
        if (this.ngbTabSet && this.ngbTabSet.tabs) {
            this.ngbTabSet.select(this.ngbTabSet.tabs.first.id);
        }
    }

    public availableMaintenance() {
        let temp = true;

        if (!(this.carService.getAdcTypeId())) {
            temp = false;
        }

        if (this.maintenanceService.loadAvailable) {
            temp = false;
        }

        if (!this.maintenanceService.isAvailable) {
            temp = false;
        }

        if (this.maintenanceService.loadMaintenance) {
            temp = false;
        }

        if (!(this.maintenanceService.maintenance && this.maintenanceService.maintenance.mdItemKorWorksDTO
            && this.maintenanceService.maintenance.mdItemKorWorksDTO.length > 0)) {
            temp = false;
        }

        return temp;
    }

}
