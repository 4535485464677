<div class="maintenance-service-info">
  <div class="maintenance-data">
    <div class="heading blue" *ngIf="location.path().indexOf('/maintenance') > -1">
      <span routerLink="/maintenance" class="clickable" (click)="maintenanceService.lastState = ''"><i class="fa fa-th" aria-hidden="true"></i></span>
      <span routerLink="/maintenance" class="clickable">{{'CLUB_SETTINGS.NAVIGATION.MAINTENANCE' | translate}}</span>
      <span routerLink="/maintenance/info" class="clickable"><i class="fa fa-caret-right"></i>{{'MAINTENANCE.INFO' | translate}}</span>
      <span routerLink="/maintenance/info/service" ><i class="fa fa-caret-right"></i>{{'MAINTENANCE.SERVICEPLAN' | translate}}</span>
      <span>
      <button class="print-btn pull-right" (click)="printIframe('serviceplan')" *ngIf="maintenanceService?.serviceplanUrl && maintenanceService?.posByCar?.length > 0">
        <i class="fa fa-print"></i>
        <span>{{'PRINT_VIEW.PRINT' | translate}}</span>
      </button>
        <button class="print-btn pull-right" (click)="showMail = !showMail" *ngIf="configService.getActiveForName('EXTENDED_SETTINGS', 'MAILING_SERVICE_PLAN') && maintenanceService?.serviceplanUrl && maintenanceService?.posByCar?.length > 0">
          <i class="fa fa-envelope-o"></i>
          <span>{{'PROMPTS.BUTTONS.AS_MAIL' | translate}}</span>
        </button>
    </span>
    </div>

  </div>
    <div class="panel-content center-content" *ngIf="location.path().endsWith('/service') || location.path().endsWith('/service?fromPlan=true')">
        <div class="mail" [ngClass]="{'show': showMail}">
            <span>{{'MAIL.MAIL' | translate}}</span><br/>
            <app-material-form-input [label]="'E-Mail'" [text]="userService?.accountData?.email" (textChange)="mail = $event"></app-material-form-input>
            <button class="base-btn" (click)="showMail = false">{{'PROMPTS.BUTTONS.CANCEL' | translate}}</button>
            <button class="base-btn success" (click)="maintenanceService?.getServiceplan(); maintenanceService.sendPerMail(mail);  showMail = false">{{'MAIL.ACCEPT_AND_SEND' | translate}}</button>
        </div>
    </div>
  <div class="panel-content center-content"
       [ngClass]="{'print-content': maintenanceService?.selectedOption === 'MANUFACTURER_SPEC', 'setting' : !maintenanceService.showPdf}">
    <ng-container>
      <div class="maintenance-data_title">
        <h1>
          <strong>{{ 'MAINTENANCE.SERVICEPLAN' | translate}}</strong>
        </h1>
      </div>
      <div class="row" *ngIf="maintenanceService?.localDataHolder">
        <div class="col-6">
          <div class="row">
            <div class="col">
              <span class="headerType">{{'PRINT_VIEW.CUSTOMER_DATA' | translate}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-material-form-input [text]="maintenanceService?.getCustomerFirstName()"
                                       [disabled]="cartService.isOrder()"
                                       (textChange)="maintenanceService.setCustomerFirstName($event)"
                                       [label]="'PRINT_VIEW.FORM.FIRSTNAME' | translate"></app-material-form-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-material-form-input [text]="maintenanceService?.getCustomerLastName()"
                                       [disabled]="cartService.isOrder()"
                                       (textChange)="maintenanceService.setCustomerLastName($event)"
                                       [label]="'PRINT_VIEW.FORM.LASTNAME' | translate"></app-material-form-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-material-form-input [text]="maintenanceService?.getOrderNumber()"
                                       [disabled]="cartService.isOrder()"
                                       (textChange)="maintenanceService?.setOrderNumber($event)"
                                       [label]="'PRINT_VIEW.FORM.ORDER_NO' | translate"
                                       [(ngModel)]="customerDataService?.getDataHolder().reference"
                                       name="orderNumber" ngDefaultControl
                                       ></app-material-form-input>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <app-material-form-input [text]="maintenanceService?.getCustomerPhone()"
                                       [disabled]="cartService.isOrder()"
                                       (textChange)="maintenanceService?.setCustomerPhone($event)"
                                       [label]="'USER_PANEL.PHONE' | translate"></app-material-form-input>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col">

              <span class="headerType">{{'PRINT_VIEW.CAR_DATA' | translate}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-material-form-input [text]="maintenanceService?.getNumberplate()"
                                       [disabled]="cartService.isOrder()"
                                       (textChange)="maintenanceService.setNumberplate($event)"
                                       [label]="'PRINT_VIEW.FORM.NUMBER_PLATE' | translate" ></app-material-form-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-material-form-input [text]="maintenanceService?.getMileage()"
                                       [type]="'number'"
                                       [disabled]="cartService.isOrder()"
                                       (textChange)="maintenanceService.setMileage($event)"
                                       [label]="'PRINT_VIEW.FORM.MILEAGE' | translate"></app-material-form-input>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col">
              <app-checkbox [labelText]="'MAINTENANCE.SHOW_LETTER_HEAD' | translate" (onChange)="maintenanceService.showLetterHead = $event" [isChecked]="maintenanceService.showLetterHead"></app-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <app-checkbox [labelText]="'MAINTENANCE.SHOW_CAR_GRAPHIC' | translate" (onChange)="maintenanceService.showCarGraphic = $event" [isChecked]="maintenanceService.showCarGraphic"></app-checkbox>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col">
              <button class="base-btn" (click)="maintenanceService?.getServiceplan()"
                      [ngClass]="{'disabled': (!(maintenanceService?.posByCar?.length > 0) && location.path().indexOf('/maintenance') > -1)}">
                <i class="fa fa-refresh"
                   [ngClass]="{'fa-pulse': (!helperService.browserIsIE() && !helperService.browserIsEdge())
                   && maintenanceService.loadServiceplan && showServicePlanCheck()}"></i>
                <span>{{'MAINTENANCE.UPDATE' | translate}}</span>
              </button>
              <button *ngIf="routeFromServicePlan" class="base-btn ml-3" routerLink="/maintenance/plan">
                <i class="fa fa-backward mb-1"></i>
                <span>{{'MAINTENANCE.MAIN_WORK' | translate}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr *ngIf="maintenanceService?.serviceplanUrl">
      <div class="row" *ngIf="showServicePlanCheck()">
        <div class="col" style="height: 800px">
          <iframe width="100%" height="100%" [src]="maintenanceService?.serviceplanUrl | iFrame"
                  id="serviceplan"
                  onLoad="this.style.height=(this.contentDocument.body.scrollHeight + 30) + 'px';">
          </iframe>
        </div>
      </div>
      <div *ngIf="!showServicePlanCheck()" class="mt-4">
        <p>
          <ngb-alert [dismissible]="false" [type]="'danger'">
            <i class="fa fa-times"></i>
            <span> {{'MAINTENANCE.NO_SELECTED_MAINTENANCE' | translate}}</span>
          </ngb-alert>
        </p>
      </div>
    </ng-container>
  </div>
</div>

