<div class="global-dashboard">
    <div class="heading blue">
        <span class="clickable" style="cursor: pointer;" routerLink="/global-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
    </div>
    <div class="panel-content center-content white-background">
        <div class="assembly-categories">
            <div class="assembly-category" routerLink="/global-admin/import">
                <div class="name-wrapper-no-image">
                    <span>{{'GLOBAL_ADMIN.GLOBAL_MAPPING_IMPORT' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" routerLink="/global-admin/orderhistory">
                <div class="name-wrapper-no-image">
                    <span>{{'GLOBAL_ADMIN.GLOBAL_ORDERHISTORY.TITLE' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" routerLink="/global-admin/griphistory">
                <div class="name-wrapper-no-image">
                    <span>{{'GLOBAL_ADMIN.GLOBAL_GRIPHISTORY' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" routerLink="/global-admin/country">
                <div class="name-wrapper-no-image">
                    <span>{{'GLOBAL_ADMIN.COUNTRY_CONFIG.TITLE' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" routerLink="/global-admin/notifications">
                <div class="name-wrapper-no-image">
                    <span>{{'GLOBAL_ADMIN.NOTIFICATIONS.TITLE' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" routerLink="/global-admin/club">
                <div class="name-wrapper-no-image">
                    <span>{{'GLOBAL_ADMIN.GLOBAL_CLUB.TITLE' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" routerLink="/global-admin/statistics">
                <div class="name-wrapper-no-image">
                    <span>{{'GLOBAL_ADMIN.STATISTICS' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" routerLink="/global-admin/vinlog">
                <div class="name-wrapper-no-image">
                    <span>{{'GLOBAL_ADMIN.VIN_LOG.TITLE' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" routerLink="/global-admin/communicationslist">
                <div class="name-wrapper-no-image">
                    <span>{{'GLOBAL_ADMIN.SUPPLIER_COMMUNICATION.TITLE' | translate}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
