import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ITyreType} from "../../technical-data/interfaces/Tyre";
import {environment} from "../../../environments/environment";
import {ITyre} from "../interfaces/DTO/tyre/tyre";
import {HttpClient} from "@angular/common/http";
import {IVehicleSpecSubGroup} from "../interfaces/DTO/car/vehicleSpecSubGroup";

@Injectable()
export class TyreRestService {
    constructor(private http: HttpClient) {
    }

    public getTyreTypesRest(adcTypeId: number): Observable<ITyreType[]> {
        if (adcTypeId > 0) {
            return this.http.get<ITyreType[]>(
                `${environment.apiUrl}/tecrmi/tyre/types?adcTypeId=${adcTypeId}`
            );
        }
    }

    public getTyresRest(tyreTypeId: number): Observable<ITyre[]> {
        return this.http.get<ITyre[]>(
            `${environment.apiUrl}/tecrmi/tyre/${tyreTypeId}`
        );
    }

    public getTyreSpecsRest(adcTypeId: number): Observable<IVehicleSpecSubGroup[]>  {
        return this.http.get<IVehicleSpecSubGroup[]>(
            `${environment.apiUrl}/tecrmi/vehicle-spec/rim-tyre-info?adcTypeId=${adcTypeId}`
        );
    }
}
