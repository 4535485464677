import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {CustomerDataService} from '../../../services/customerData.service';
import {IDmsCarHolder} from '../../../interfaces/DTO/car/dmsCarHolder';
import {CarService} from '../../../services/car/car.service';
import {ICar} from '../../../interfaces/DTO/car/car';
import {Router} from '@angular/router';
import {ConfigService} from '../../../services/config.service';
import {AutovistaRestService} from '../../../../graphical-part-search/services/autovista.rest.service';

@Component({
  selector: 'app-preload-car-selection',
  templateUrl: './preload-car-selection.component.html',
  styleUrls: ['./preload-car-selection.component.scss']
})
export class PreloadCarSelectionComponent implements OnInit {
  @Output() applyCars: EventEmitter<any> = new EventEmitter();

  public carHolder: Map<string, IDmsCarHolder> = new Map<string, IDmsCarHolder>();

  constructor(
      public dataService: CustomerDataService,
      public carService: CarService,
      public router: Router,
      public configService: ConfigService,
      public autovistaRestService: AutovistaRestService,
      public customerDataService: CustomerDataService) {}

  ngOnInit() {}

  public applyCar(car: ICar) {
    if (car) {
      this.carService.selectCar(car);
      if (this.configService.getEntryPoint()) {
        this.router.navigate([this.configService.getEntryPoint()]);
      } else {
        this.router.navigate(['/articles']);
      }
      if (this.carService.carHistory) {
        this.carService.addHistoryEntry(car);
      } else {
        this.carService.toCarHistory = car;
      }
    }
  }
}
