<div class="user-notifications">
    <div class="heading blue">
        <span class="clickable"  routerLink="/company-admin">
             <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
         </span>
        <span class="clickable" routerLink="/company-admin/profile">
            <i class="fa fa-caret-right"></i>
            {{'USER_PROFILE.MY_NOTIFICATIONS' | translate}}
        </span>

        <span class="reload-notifications float-right clickable"
              *ngIf="!externalNotificationService?.loadOwnNotifications"
              (click)="reloadNotifications()">
            <i class="fa fa-repeat mr-2"></i>
            <span>{{'USER_PROFILE.RELOAD_NOTIFICATIONS' | translate}}</span>
        </span>

        <span class="reload-notifications float-right"
              *ngIf="externalNotificationService?.loadOwnNotifications">
            <i class="fa fa-spinner" [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"></i>
        </span>
    </div>
    <div class="panel-content center-content">
        <div class="row">
            <div class="col-12">
                <h1>{{'USER_PROFILE.MY_NOTIFICATIONS' | translate}}</h1>
            </div>
        </div>
        <ng-container *ngIf="externalNotificationService?.notificationHolder?.list?.length > 0">
            <div class="row notification-row {{message.type}}" *ngFor="let message of externalNotificationService.notificationHolder?.list">
                <div class="col-11 notification-message">
                    <span>{{message.description}}</span>
                </div>
                <div class="col-1 notification-option text-center clickable"
                     *ngIf="!externalNotificationService.isNotificationHidden(message.id)"
                     (click)="externalNotificationService.hideNotification(message)">
                    <i class="fa fa-eye-slash"></i>
                </div>
                <div class="col-1 notification-option text-center clickable"
                     *ngIf="externalNotificationService.isNotificationHidden(message.id)"
                     (click)="externalNotificationService.showNotification(message)">
                    <i class="fa fa-eye"></i>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!(externalNotificationService?.notificationHolder?.list?.length > 0)">
            <div class="alert alert-info text-center">
                <i class="fa fa-info-circle mr-2"></i>
                <span>{{'NOTIFICATION.NO_NOTIFICATION' | translate}}</span>
            </div>
        </ng-container>
    </div>
</div>
