import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-content-overlay',
  templateUrl: './content-overlay.component.html',
  styleUrls: ['./content-overlay.component.scss']
})
export class ContentOverlayComponent implements OnInit {

  @Input() showContent: string;
  @Input() newRoute: string;

  constructor() { }

  ngOnInit() {
  }

}
