<div class="heading blue">
    <span class="clickable" routerLink="/admin">
        <i class="fa fa-th" aria-hidden="true"></i>
         {{'ADMIN_PANEL.OVERVIEW' | translate}}
    </span>
    <span class="clickable" (click)="reset()">
          <i class="fa fa-caret-right"></i>
          {{ 'USER_PROFILE.USER_MANAGEMENTS' | translate }}
      </span>
    <span *ngIf="userData">
        <i class="fa fa-caret-right"></i>
        {{userData?.userName}}
    </span>
</div>
<div class="panel-content center-content white-background">
  <div class="edituser">
    <app-user-data
            [userData]="userData"
            [firmData]="adminService.selectedFirm"
            (_back)="reset()"
            (_update)="update()"
            (_updateReset)="updateReset()"
    >
    </app-user-data>
  </div>
</div>
