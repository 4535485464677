import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: 'checkbox.component.html',
    styleUrls: ['checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
    @Input() public labelText: string;
    @Input() public isChecked: boolean = false;
    @Input() public isDisabled: boolean = false;
    @Input() public type: string = "checkbox";
    @Output() private onChange: EventEmitter<boolean> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    public onValueChange(event: any) {
        this.isChecked = !this.isChecked;
        this.onChange.emit(event.target.checked);
    }

}
