import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html'
})
export class MessageComponent implements OnInit {

  @Input() title: string;
  @Input() textFirst: string;
  @Input() textSecond: string;
  @Input() btn: string[];
  @Input() hasNameInput: boolean | false;

  @Output() nameOutput: EventEmitter<string> = new EventEmitter<string>();
  @Output() onBtnClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onModalClose: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  public btnClick(button: string, index: number, event: any) {
    this.stopPropagation(event);
    const temp = {buttonName: button, index: index};
    this.onBtnClick.emit(temp);
  }

  public stopPropagation(event: any) {
    event.stopPropagation();
  }

  public modalClose($event?: any) {
    if (!$event || $event.keyCode === 27) {
      this.onModalClose.emit(true);
    }
  }
}
