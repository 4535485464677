<div class="filter">
  <div id="filterBar" class="filterBar" *ngIf="service?.searchResult?.activeFilters?.length > 0">
    <div class="row">
      <div class="col">
        <div class="chipBar" [ngClass]="{'open': openFilterBar}">
          <ng-container *ngIf="!openFilterBar">
            <ng-container *ngFor="let filter of service?.searchResult?.activeFilters">
              <app-chip *ngIf="!filter.fixedFilter"
                        [text]="filter?.displayValue"
                        (onIconClick)="service.toggleActiveFilter(filter)">
              </app-chip>
              <app-chip *ngIf="filter.fixedFilter"
                        [text]="filter?.displayValue" [chipClass]="'disabled_no_icon'"
                        tooltip
                        tooltipPosition="bottom_filter"
                        tooltipContent="{{'WORK.FILTER_TOOLTIP' | translate}}">
              </app-chip>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="openFilterBar">
            <div class="filterType" *ngFor="let filterType of service?.filterGroupNames">
              <div>{{filterType}}:</div>
              <ng-container *ngFor="let filter of service?.searchResult?.activeFilters">
                <app-chip *ngIf="filter.groupName === filterType && !filter.fixedFilter"
                          [text]="filter?.displayValue"
                          (onIconClick)="service.toggleActiveFilter(filter)">
                </app-chip>
                <app-chip *ngIf="filter.groupName === filterType && filter.fixedFilter"
                          [text]="filter?.displayValue" [chipClass]="'disabled_no_icon'"
                          tooltip
                          tooltipPosition="bottom_filter"
                          tooltipContent="{{'WORK.FILTER_TOOLTIP' | translate}}">
                </app-chip>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div class="toggleIcon clickable" (click)="toggleFilterBar()">
          <i class="fa fa-angle-down" *ngIf="!openFilterBar"></i>
          <i class="fa fa-angle-up" *ngIf="openFilterBar"></i>
        </div>
      </div>
    </div>
  </div>
</div>
