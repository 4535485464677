<div class="assembly-categories">
    <div class="assembly-category" routerLink="supplier">
        <div class="name-wrapper-no-image">
            <span>{{'ASSORTMENT.SUPPLIER' | translate}}</span>
        </div>
    </div>
    <div class="assembly-category" routerLink="article">
        <div class="name-wrapper-no-image">
            <span>{{'ASSORTMENT.ARTICLE' | translate}}</span>
        </div>
    </div>
</div>
