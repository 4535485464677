import { Component, OnInit } from '@angular/core';
import {GlobalAdminService} from '../../../services/global-admin.service';
import {UserService} from '../../../../../shared/services/user/user.service';
import {Router} from '@angular/router';
import {ArticlesService} from '../../../../../articles/services/articles.service';
import {HelperService} from '../../../../../shared/services/helper.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-global-communications-list-data',
  templateUrl: './global-communications-list-data.component.html'
})
export class GlobalCommunicationsListDataComponent implements OnInit {

  constructor(public globalAdminService: GlobalAdminService,
              public userService: UserService,
              public router: Router,
              public articlesService: ArticlesService,
              public helperService: HelperService,
              public translate: TranslateService) {

      const routerUrl: string = this.router.url;
  }

  public copy(doc: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = doc;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.helperService.showNotification('Inhalt in die Zwischenablage kopiert!', 'success');
  }

  ngOnInit() {
    if (!this.globalAdminService.communicationLog) {
      this.router.navigate([this.router.url.substring(0, this.router.url.lastIndexOf('/'))]);
    }
  }

}
