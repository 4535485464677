import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assortment-no-results',
  templateUrl: './assortment-no-results.component.html'
})
export class AssortmentNoResultsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
