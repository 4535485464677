import { Component, OnInit } from '@angular/core';
import {MaintenanceService} from '../../services/maintenance.service';
import {fadeOfferToggle} from '../../../shared/animations/general.animation';
import {HelperService} from '../../../shared/services/helper.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {CartService} from '../../../cart/services/cart.service';

@Component({
  selector: 'app-maintenance-service-specs',
  templateUrl: './maintenance-service-specs.component.html',
  styleUrls: ['./maintenance-service-specs.component.scss'],
    animations: [fadeOfferToggle]
})
export class MaintenanceServiceSpecsComponent implements OnInit {

  constructor(public maintenanceService: MaintenanceService,
              public helperService: HelperService,
              public location: Location,
              private router: Router,
              private cartService: CartService) { }

  ngOnInit() {
      if (this.location.path().indexOf('/maintenance') > -1) {
          this.maintenanceService.lastState = '/maintenance/info/specifications';
      } else if (this.location.path().indexOf('/cart') > -1 && !this.cartService.showDocument) {
          this.router.navigate(['/cart']);
      }
    this.maintenanceService.pdf = undefined;
    this.maintenanceService.showPdf = false;
  }

}
