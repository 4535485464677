import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class VinRestService {

    constructor(private http: HttpClient) {
    }

    public getVinCounter(): Observable<number> {
        return this.http.get<number>(
            `${environment.apiUrl}/billedsearch/vincounter`
        )
    }
}
