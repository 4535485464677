import {Component, OnInit} from '@angular/core';
import {MaintenanceService} from '../../services/maintenance.service';
import {CustomerDataService} from '../../../shared/services/customerData.service';
import {HelperService} from '../../../shared/services/helper.service';
import {UserService} from '../../../shared/services/user/user.service';
import {Location} from '@angular/common';
import {CarService} from '../../../shared/services/car/car.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigService} from '../../../shared/services/config.service';
import {CartService} from '../../../cart/services/cart.service';

@Component({
    selector: 'app-maintenance-service-info',
    templateUrl: './maintenance-service-info.component.html',
    styleUrls: ['./maintenance-service-info.component.scss']
})
export class MaintenanceServiceInfoComponent implements OnInit {

    public showMail: boolean = false;
    public mail: string = '';
    public routeFromServicePlan: boolean;

    constructor(public maintenanceService: MaintenanceService,
                public dataService: CustomerDataService,
                public helperService: HelperService,
                public userService: UserService,
                public location: Location,
                public configService: ConfigService,
                private carService: CarService,
                private router: Router,
                private route: ActivatedRoute,
                public cartService: CartService,
                public customerDataService: CustomerDataService) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.routeFromServicePlan = params.fromPlan && params.fromPlan === 'true';
        })

        if (this.location.path().indexOf('/maintenance') > -1) {
            this.maintenanceService.getServiceplan();
        }

        if (!this.maintenanceService.serviceplanUrl && this.location.path().endsWith('/cart/service')) {
            this.router.navigate(['/cart'])
        }

        this.maintenanceService.localDataHolder = this.helperService.recursiveDeepCopy(this.dataService.getDataHolder());

    }

    public printIframe(frameId: string) {
        const frame = window.frames[frameId];
        if (!frame) {
            return;
        }

        if (this.helperService.browserIsIE()) {
            frame.document.execCommand('print', false, null);
        } else {
            const frames: any = document.getElementById(frameId);
            frames.contentWindow.focus();
            frames.contentWindow.print();
        }
    }

    public showServicePlanCheck() {
        if (this.location.path().indexOf('/maintenance') > -1) {
            return this.maintenanceService?.serviceplanUrl && this.maintenanceService.posByCar?.length > 0;
        } else {
            return this.maintenanceService?.serviceplanUrl;
        }
    }

}
