import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {AdminService} from '../../services/admin.service';
import {IEditLog} from '../../../../shared/interfaces/adminFirm';
import {UserService} from '../../../../shared/services/user/user.service';
import {HelperService} from '../../../../shared/services/helper.service';

@Component({
    selector: 'app-log',
    templateUrl: './log.component.html',
    styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {

    public logData: IEditLog[];
    public loadingData: boolean = false;

    constructor(private location: Location,
                private router: Router,
                private adminService: AdminService,
                private userService: UserService,
                private helperService: HelperService) {
    }

    ngOnInit() {
        if (this.userService.allow('CLUB-ADMIN') || this.userService.allow('GLOBAL-ADMIN')) {
            this.loadingData = true;
            const firmId: number = parseInt(this.location.path().substring(this.location.path().lastIndexOf('/') + 1));
            this.getLogData(firmId);
        } else {
            this.router.navigate(['/admin']);
        }
    }

    private getLogData(firmId: number) {
        this.adminService.getFirmDataById(firmId).subscribe(
            (firmData) => {
                this.loadingData = false;
                this.logData = firmData.editLog.reverse();
            },
            (error) => {
                this.loadingData = false;
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_LOG', 'error');
            });
    }

}
