<div class="message" (click)="modalClose()" (keydown)="modalClose($event)">
  <div class="inner-modal" (click)="stopPropagation($event)">
    <div class="header-title">
      <span>{{title}}</span>
      <i class="fa fa-times" (click)="modalClose()"></i>
    </div>
    <div class="row">
      <div class="col">
        <span class="textFirst">{{textFirst}}</span><br>
        <span class="textSecond">{{textSecond}}</span>
      </div>
    </div>
    <div class="row" *ngIf="hasNameInput">
      <div class="col-3"></div>
      <div class="col-6">
        <app-material-form-input [autofocus]="true" [type]="'input'" [label]="'PROMPTS.MESSAGE.NEW_CART.CART_NAME' | translate" (textChange)="nameOutput.emit($event)"></app-material-form-input>
      </div>
      <div class="col-3"></div>
    </div>
    <div class="row">
      <div class="col buttonBar">
        <button class="base-btn float-right" *ngFor="let button of btn; index as i" (click)="btnClick(btn[i], i, $event)">
          <span>{{button}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
