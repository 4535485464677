import {Component, OnInit} from '@angular/core';
import {CartService} from '../services/cart.service';
import {IPosition} from '../../shared/interfaces/DTO/cart/position';

@Component({
    selector: 'app-check-genart',
    templateUrl: './check-genart.component.html'
})
export class CheckGenartComponent implements OnInit {
    private checkGenArt = [82, 188, 854];
    public toCheck: any[];

    constructor(public cartService: CartService) {
        if (this.cartService.currentCart.positions.length > 0) {
            this.checkPairing(this.cartService.currentCart.positions);
        }
    }

    ngOnInit() {
    }

    private checkPairing(positions: IPosition[]) {
        const pairs = [];
        for (const pos of positions) {
            if (
                pos.type === 'SPAREPART' &&
                pos.associatedCar &&
                pos.associatedCar.kTypeId &&
                pos.genericArticleIds.length > 0 &&
                this.checkGenArt.indexOf(pos.genericArticleIds[0]) > -1
            ) {
                if (pairs[pos.associatedCar.kTypeId] && pairs[pos.associatedCar.kTypeId][pos.genericArticleIds[0]]) {
                    pairs[pos.associatedCar.kTypeId][pos.genericArticleIds[0]].quantity = pairs[pos.associatedCar.kTypeId][pos.genericArticleIds[0]].quantity + pos.quantity;
                } else {
                    if (!pairs[pos.associatedCar.kTypeId]) {
                        pairs[pos.associatedCar.kTypeId] = [];
                    }
                    pairs[pos.associatedCar.kTypeId][pos.genericArticleIds[0]] = {
                        name: pos.description,
                        quantity: pos.quantity
                    };
                }
            } else if (
                pos.type === 'SPAREPART' &&
                pos.genericArticleIds.length > 0 &&
                this.checkGenArt.indexOf(pos.genericArticleIds[0]) > -1
            ) {
                if (pairs[0] && pairs[0][pos.genericArticleIds[0]]) {
                    pairs[0][pos.genericArticleIds[0]].quantity = pairs[0][pos.genericArticleIds[0]].quantity + pos.quantity;
                } else {
                    if (!pairs[0]) {
                        pairs[0] = [];
                    }
                    pairs[0][pos.genericArticleIds[0]] = {
                        name: pos.description,
                        quantity: pos.quantity
                    };
                }
            }
        }
        this.toCheck = [];
        let carName = '';
        if (pairs.length > 0) {
            for (const car in pairs) {
                carName = '';
                for (const _car of this.cartService.currentCart.cars) {
                    if (JSON.stringify(_car.car.kTypeId) === JSON.stringify(car)) {
                        carName = _car.car.carProducerName + ' ' + _car.car.carModelName;
                    }
                }

                if (car === '0') {
                    carName = undefined;
                }

                for (const genArt in pairs[car]) {
                    if (pairs[car][genArt].quantity % 2 !== 0) {
                        this.toCheck.push(
                            {
                                name: pairs[car][genArt].name,
                                carName: carName,
                                car: car
                            }
                        )
                    }
                }
            }
        }
    }
}
