import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-debounce-input',
  templateUrl: './debounce-input.component.html',
  styleUrls: ['./debounce-input.component.scss']
})
export class DebounceInputComponent implements OnInit, OnDestroy {

  @ViewChild('inputField', {static: true}) inputField;
  @Input() value: any | ' ';
  @Input() delay: number | 400;
  @Input() type: string | 'text';
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  public inputValue: string;
  private inputValueChangeSubscription: Subscription;
  private inputValueChange: Subject<string> = new Subject<string>();

  constructor() {}

  ngOnInit() {
      this.inputValueChangeSubscription = this.inputValueChange
          .pipe(
              debounceTime(this.delay),
              distinctUntilChanged()
          )
          .subscribe(newText => {
              this.inputValue = newText;
              this.onChange.emit(newText);
          });
  }

  ngOnDestroy() {
      this.inputValueChangeSubscription.unsubscribe();
  }
}
