<div class="autovista" id="autovista">
    <div class="panel-content center-content white-background headerPanel">
        <div class="headerBar">
            <i class="fa fa-car"></i>
            <span class="carType" *ngIf="autovistaService?.selectedCar?.name">{{autovistaService?.selectedCar?.name}}
                <span class="no-padding" *ngIf="autovistaService?.selectedCar?.numberOfDoors">
                    ({{autovistaService?.selectedCar?.numberOfDoors}}{{'AUTOVISTA.DOORS' | translate}})
                </span> {{autovistaService?.selectedCar?.transmissionType}}</span>
            <span class="carType" *ngIf="!autovistaService?.selectedCar?.name">
                <i class="fa fa-spinner" [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"></i>
            </span>
            <button class="carButton" (click)="autovistaService.selectCarPrompt = true"><i>{{'PROMPTS.BUTTONS.CHANGE' | translate}} </i>  <i class="fa fa-pencil"></i></button>
        </div>
    </div>

    <!-- New -->
    <div class="panel-content center-content white-background svgPanel" [ngClass]="{'show-with-footer': autovistaService.getImages().length > 1}" *ngIf="autovistaService?.dsCodes?.length > 0">

        <div class="svgController">
            <i class="fa fa-arrow-left" (click)="autovistaService.updateSvg('left')"></i>
            <i class="fa fa-arrow-right" (click)="autovistaService.updateSvg('right')"></i>
            <i class="fa fa-arrow-up" (click)="autovistaService.updateSvg('up')"></i>
            <i class="fa fa-arrow-down" (click)="autovistaService.updateSvg('down')"></i>
            <i class="fa fa-plus" (click)="autovistaService.updateSvg('plus')"></i>
            <i class="fa fa-minus" (click)="autovistaService.updateSvg('minus')"></i>
            <i class="fa fa-times" (click)="autovistaService.updateSvg('reset')"></i>
        </div>

        <app-default-loading-page *ngIf="autovistaService?.imageLoading"></app-default-loading-page>
        <div class="svg" id="svgWrapper" appSVG [svgImage]="autovistaService.XML"></div>
    </div>

    <!-- Old -->
    <div class="panel-content center-content white-background" style="margin-top:25px; z-index: 101" *ngIf="autovistaService?.mcGroups?.length > 0">
        <app-default-loading-page *ngIf="autovistaService?.loadSubGroupDetails"></app-default-loading-page>


        <ng-container *ngIf="autovistaService.showCarOverviewImage">
            <div class="svg" id="svgWrapper" appSVG [svgImage]="autovistaService.XML"></div>
        </ng-container>

        <ng-container *ngIf="!autovistaService.showCarOverviewImage">
            <ng-container *ngIf="!autovistaService?.selectedGroup && !autovistaService.oldXML">
                <div class="assembly-categories">
                    <div class="assembly-category" *ngFor="let group of autovistaService?.mcGroups" (click)="autovistaService.selectedGroup = group">
                        <div class="name-wrapper-no-image">
                            <span>{{group.name}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="autovistaService?.selectedGroup && !autovistaService.selectedSubgroup && !autovistaService.oldXML">
                <div class="assembly-categories">
                    <div class="assembly-category" *ngFor="let subgroup of autovistaService?.selectedGroup.subGroups" (click)="autovistaService.selectedSubgroup = subgroup; autovistaService.getSubGroupDetails()">
                        <div class="name-wrapper-no-image">
                            <span>{{subgroup.name}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>


        <ng-container *ngIf="(getSubGroupImageKeys()?.length > 0 || autovistaService?.getAdditionalPartsKeys()?.length > 0) && autovistaService.selectedGroup && autovistaService.selectedSubgroup && !autovistaService.oldXML">
            <div class="assembly-categories" id="assembly-categories" *ngIf="getSubGroupImageKeys()?.length > 0">
                <div class="assembly-category" *ngFor="let imageKey of getSubGroupImageKeys()" (click)="selectSVG(imageKey)">
                    <div class="image-wrapper">
                        <div class="center-helper"></div>
                        <img style="height: 100%; width: auto;" src="{{imageKey}}.png">
                    </div>
                    <div class="image-wrapper-hover">
                        <div class="center-helper"></div>
                        <img style="height: 100%; width: auto;" src="{{imageKey}}.png">
                    </div>
                </div>
            </div>

            <hr *ngIf="getSubGroupImageKeys()?.length > 0 && autovistaService?.getAdditionalPartsKeys()?.length > 0"/>

            <div class="assembly-categories" *ngIf="autovistaService?.getAdditionalPartsKeys()?.length > 0">
                <div class="assembly-category" [ngClass]="{'selectedCategory': autovistaService.selectedArticle === autovistaService?.subGroupDetails?.additionalParts[groupKey]}" *ngFor="let groupKey of autovistaService.getAdditionalPartsKeys()" (click)="selectAdditionalPart(autovistaService?.subGroupDetails.additionalParts[groupKey])">
                    <div class="name-wrapper-no-image">
                        <span>{{autovistaService?.subGroupDetails?.additionalParts[groupKey]?.content[0].standardGroup[0].name}}</span>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="autovistaService.oldXML">
            <div class="svg" id="svgOldWrapper" appSVGOld [svgImage]="autovistaService.oldXML"></div>
        </ng-container>
    </div>

    <div class="panel-content center-content white-background footerPanel" *ngIf="autovistaService.getImages().length > 1 && !autovistaService.imageLoading">
        <div class="footerBar" *ngIf="autovistaService.selected">
            <ng-container *ngFor="let imageNumber of autovistaService.getImages()">
                <app-checkbox *ngIf="getLabelText(imageNumber)"
                              [type]="'radio'"
                              (onChange)="switchToImage(imageNumber)"
                              [labelText]="getLabelText(imageNumber)"
                              [isChecked]="getIsChecked(imageNumber)">
                </app-checkbox>
            </ng-container>
        </div>
    </div>
</div>
