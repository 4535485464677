import {AfterContentInit, AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements AfterContentInit {

    @Input() public documentList: any = [];
    @Input() public selectedImage: any;
    @Input() public title: string;
    @Output() public closeGallery: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    @HostListener('document:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
        if (event.keyCode === 39) {
            this.getNextImage(false);
        }
        if (event.keyCode === 37) {
            this.getNextImage(true);
        }
        if (event.keyCode === 27) {
            this.closeGallery.emit();
        }
    }

    ngAfterContentInit() {

        if (!this.selectedImage) {
            this.selectedImage = this.documentList[0];
        }
    }

    public onEvent(event: any) {
        if (this.documentList && this.documentList.length > 1) {
            event.stopPropagation();
        }
    }

    public setImage(image: any) {
        this.selectedImage = image;
    }

    public getNextImage(left: boolean) {

        const temp = this.findImage(this.selectedImage);

        if (left) {
            if (temp === 0) {
                this.setImage(this.documentList[this.documentList.length - 1]);
            } else {
                this.setImage(this.documentList[temp - 1]);
            }
        } else {
            if (temp === this.documentList.length - 1) {
                this.setImage(this.documentList[0]);
            } else {
                this.setImage(this.documentList[temp + 1]);
            }
        }
    }

    private findImage(image: any) {
        for (let i = 0; i < this.documentList.length; i++) {
            if (image.url === this.documentList[i].url) {
                return i;
            }
        }
    }

}
