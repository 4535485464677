import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {IVehicleSpecSubGroup} from '../../interfaces/DTO/car/vehicleSpecSubGroup';
import {IWorkPositionContainer} from '../../interfaces/DTO/work/workPositionContainer';
import {IWorkTreeBodyGroup} from '../../interfaces/DTO/work/workTreeBodyGroup';
import {IManualMainGroup} from '../../interfaces/DTO/manualMainGroup';
import {ITreeItem} from '../../interfaces/DTO/treeItem';
import {IDiagnosticMainGroup} from '../../interfaces/DTO/diagnosticMainGroup';
import {IMdSubGroupWorks} from '../../interfaces/DTO/mdSubGroupWorks';
import {IComponentLocationMainGrp} from '../../interfaces/DTO/componentLocationMainGrp';
import {IFuseRelayComponent} from '../../interfaces/DTO/fuseRelayComponent';
import {IManual} from '../../interfaces/DTO/manual';
import {ICooGroupQualCol} from '../../interfaces/DTO/cooGroupQualCol';
import {IMdServiceProposal} from '../../interfaces/DTO/mdServiceProposal';
import {IWorkProposalContainer} from '../../interfaces/DTO/work/workProposalContainer';
import {ICartLabourWithParts} from '../../interfaces/DTO/cart/cartLabourWithParts';
import {IMaintenancePlanMailRequest} from '../../interfaces/DTO/maintenancePlanMailRequest';
import {ILinkItem} from '../../interfaces/DTO/linkItem';

@Injectable()
export class TecRmiRestService {

    constructor(private http: HttpClient) {
    }

    public getMaintenanceSteps(adcTypeId: number, itemMpId: number, korId: number, mdQualColId: number): Observable<IWorkPositionContainer[]> {
        return this.http.get<IWorkPositionContainer[]>(
            `${environment.apiUrl}/tecrmi/maintenance/work-steps?adcTypeId=${adcTypeId}&mountPositionId=${itemMpId}&korId=${korId}&mdQualColId=${mdQualColId}&repairId=${korId}`
        );
    }

    public getWorkData(adcTypeId: number): Observable<IWorkTreeBodyGroup[]> {
        return this.http.get<IWorkTreeBodyGroup[]>(
            `${environment.apiUrl}/tecrmi/work-values/tree?adcTypeId=${adcTypeId}`
        );
    }

    public getOperatingMaterials(adcTypeId: number): Observable<IVehicleSpecSubGroup[]> {
        return this.http.get<IVehicleSpecSubGroup[]>(
            `${environment.apiUrl}/tecrmi/vehicle-spec/operating-materials?adcTypeId=${adcTypeId}`
        );
    }

    public getTechnicalData(adcTypeId: number, mainGroupId: number): Observable<IVehicleSpecSubGroup[]> {
        return this.http.get<IVehicleSpecSubGroup[]>(
            `${environment.apiUrl}/tecrmi/vehicle-spec/main-groups/${mainGroupId}?adcTypeId=${adcTypeId}`
        );
    }

    public getBodygraphicsNavigation(adcTypeId: number): Observable<ITreeItem[]> {
        return this.http.get<ITreeItem[]>(
            `${environment.apiUrl}/tecrmi/manual/bodygraphics/navigation?adcTypeId=${adcTypeId}`
        );
    }

    public getBodygraphicsOverview(adcTypeId: number, qualcol: any) {
        return `${environment.apiUrl}/tecrmi/manual/bodygraphics/vehicle?adcTypeId=${adcTypeId}&bodyQualColId=${qualcol.qualColId}`;
    }

    public getDiagnosticTree(adcTypeId: number, componentTypeId: number): Observable<IDiagnosticMainGroup[]> {
        return this.http.get<IDiagnosticMainGroup[]>(
            `${environment.apiUrl}/tecrmi/diagnostic-values/tree?adcTypeId=${adcTypeId}&componentTypeId=${componentTypeId}`
        );
    }

    public getDiagnosticManual(adcTypeId: number, data: any) {
        return `${environment.apiUrl}/tecrmi/diagnostic-values/manual?componentTypeId=${data.componentTypeId}&systemQualifierId=${data.systemQualifierId}&qualifierId=${data.qualifierId}&mountPositionId=${data.mountPositionId}&adcTypeId=${adcTypeId}`;
    }

    public getTechnicalcManual(adcTypeId: number, manualId: any) {
        return `${environment.apiUrl}/tecrmi/manual/technical?manualId=${manualId}&adcTypeId=${adcTypeId}`;
    }


    public getMaintenanceFullTree(adcTypeId: number, qualColId: number): Observable<IMdSubGroupWorks> {
        return this.http.get<IMdSubGroupWorks>(
            `${environment.apiUrl}/tecrmi/maintenance/data?adcTypeId=${adcTypeId}&mdQualColId=${qualColId}`
        );
    }

    public getCartMaintenancePositionByCar(adcTypeId: number, kTypeId: number, mdQualColId: number, vin?: string): Observable<ICartLabourWithParts[]> {
        if (vin) {
            return this.http.get<ICartLabourWithParts[]>(
                `${environment.apiUrl}/tecrmi/maintenance/cartMaintenancePositionsByCar?adcTypeId=${adcTypeId}&mdQualColId=${mdQualColId}&kTypeId=${kTypeId}&vin=${vin}`
            )
        } else {
            return this.http.get<ICartLabourWithParts[]>(
                `${environment.apiUrl}/tecrmi/maintenance/cartMaintenancePositionsByCar?adcTypeId=${adcTypeId}&mdQualColId=${mdQualColId}&kTypeId=${kTypeId}`
            )
        }
    }

    public getMaintenanceManualTree(adcTypeId: number): Observable<IManualMainGroup[]> {
        return this.http.get<IManualMainGroup[]>(
            `${environment.apiUrl}/tecrmi/manual/maintenance/tree?adcTypeId=${adcTypeId}`
        );
    }

    public getWiringData(adcTypeId: number, manualId: any) {
        return `${environment.apiUrl}/tecrmi/manual/wiring?manualId=${manualId}&adcTypeId=${adcTypeId}`;
    }

    public getComfortWiringData(adcTypeId: number, manualId: any) {
        return `${environment.apiUrl}/tecrmi/manual/comfortwiring?manualId=${manualId}&adcTypeId=${adcTypeId}`;
    }

    public getComponentLocationTree(adcTypeId: number): Observable<IComponentLocationMainGrp[]> {
        return this.http.get<IComponentLocationMainGrp[]>(
            `${environment.apiUrl}/tecrmi/component-location/tree?adcTypeId=${adcTypeId}`
        );
    }

    public getComponentLocation(adcTypeId: number, manualId: any) {
        return `${environment.apiUrl}/tecrmi/component-location?manualId=${manualId}&adcTypeId=${adcTypeId}`;
    }

    public getFuse(adcTypeId: number): Observable<any> {
        return this.http.get(
            `${environment.apiUrl}/tecrmi/fuse-relay/available?adcTypeId=${adcTypeId}`
        );
    }

    public getFuseComponents(adcTypeId: number): Observable<IFuseRelayComponent[]> {
        return this.http.get<IFuseRelayComponent[]>(
            `${environment.apiUrl}/tecrmi/fuse-relay/components?adcTypeId=${adcTypeId}`
        );
    }

    public getFuseOverview(adcTypeId: number, componentTypeId: number) {
        return `${environment.apiUrl}/tecrmi/fuse-relay/overview?adcTypeId=${adcTypeId}&componentTypeId=${componentTypeId}`;
    }

    public getFuseConfiguraiton(adcTypeId: number, componentTypeId: number, mountPositionId: string): Observable<any> {
        return this.http.get(
            `${environment.apiUrl}/tecrmi/fuse-relay/configuration?adcTypeId=${adcTypeId}&componentTypeId=${componentTypeId}&mountPositionId=${mountPositionId}`
        );
    }

    public getFuseManual(adcTypeId: number, componentTypeId: number, boxConfigurationId: number, buildPeriodQualifierId: number): Observable<any> {
        return this.http.get(
            `${environment.apiUrl}/tecrmi/fuse-relay/configuration?adcTypeId=${adcTypeId}&componentTypeId=${componentTypeId}&boxConfigurationId=${boxConfigurationId}&buildPeriodQualifierId=${buildPeriodQualifierId}`
        );
    }

    public getTechnicalLinkedManual(adcTypeId: number): Observable<IManual[]> {
        return this.http.get<IManual[]>(
            `${environment.apiUrl}/tecrmi/maintenance/linked-manuals?adcTypeId=${adcTypeId}`
        );
    }


    public getCarOverview(adcTypeId: number, manualId: number) {
        return `${environment.apiUrl}/tecrmi/manual/technical?adcTypeId=${adcTypeId}&manualId=${manualId}`;
    }

    public getGearBoxCodes(adcTypeId: number): Observable<string[]> {
        return this.http.get<string[]>(
            `${environment.apiUrl}/tecrmi/maintenance/possible-gearbox-codes?adcTypeId=${adcTypeId}`
        );
    }

    public getCooGroups(adcTypeId: number, gearBoxCode: string, initialRegistrationDate: string, emissionStandard: number): Observable<ICooGroupQualCol[]> {
        return this.http.get<ICooGroupQualCol[]>(
            `${environment.apiUrl}/tecrmi/maintenance/proposal-coo-groups?adcTypeId=${adcTypeId}&gearBoxCode=${gearBoxCode}&initialRegistrationDate=${initialRegistrationDate}`
        );
    }

    public getServiceProposal(adcTypeId: number, mdBodyQualColId: number, cooId: number, mileage: number, initialRegistrationDate: string, emissionStandard: number): Observable<IMdServiceProposal> {
        return this.http.get<IMdServiceProposal>(
            `${environment.apiUrl}/tecrmi/maintenance/service-proposal?adcTypeId=${adcTypeId}&mdBodyQualColId=${mdBodyQualColId}&cooId=${cooId}&mileage=${mileage}&initialRegistrationDate=${initialRegistrationDate}`
        );
    }

    public setServiceProposal(kTypeId: number, adcTypeId: number, mdBodyQualColId: number, cooId: number, mileage: number, initialRegistrationDate: string, emissionStandard: number, next: boolean, carProducerName: string, vin: string, mainWorkId: number): Observable<IWorkProposalContainer> {
        return this.http.get<IWorkProposalContainer>(
            `${environment.apiUrl}/tecrmi/maintenance/select-service-proposal?kTypeId=${kTypeId}&adcTypeId=${adcTypeId}&mdBodyQualColId=${mdBodyQualColId}&cooId=${cooId}&mileage=${mileage}&initialRegistrationDate=${initialRegistrationDate}&next=${next}&producerName=${carProducerName}&vin=${vin}&mainWorkId=${mainWorkId}`
        );
    }

    public getCartLabourPositionByCar(adcTypeId: number, kTypeId: number, ltQualColId: number, vin?: string): Observable<ICartLabourWithParts[]> {
        if (vin) {
            return this.http.get<ICartLabourWithParts[]>(
                `${environment.apiUrl}/tecrmi/work-values/cartLabourPositionsByCar?adcTypeId=${adcTypeId}&ltQualColId=${ltQualColId}&kTypeId=${kTypeId}&vin=${vin}`
            );
        } else {
            return this.http.get<ICartLabourWithParts[]>(
                `${environment.apiUrl}/tecrmi/work-values/cartLabourPositionsByCar?adcTypeId=${adcTypeId}&ltQualColId=${ltQualColId}&kTypeId=${kTypeId}`
            );
        }
    }

    public getWorkStepsBySubGroup(adcTypeId: number, ltQualColId: number, subGroupId: number): Observable<IWorkPositionContainer[]> {
        return this.http.get<IWorkPositionContainer[]>(
            `${environment.apiUrl}/tecrmi/work-values/steps-bysubgroupviarequest?adcTypeId=${adcTypeId}&ltQualColId=${ltQualColId}&subGroupId=${subGroupId}`
        )
    }

    public getWorkPlan(kTypeId: number, adcTypeId: number, customer: string, numberplate: string, mileage: any, mdQualColId: number, ordernumber: string, customerPhone: string, showLetterHead: boolean, showCarGraphic: boolean, backTranslation: string) {
        const url = `${environment.apiUrl}/tecrmi/maintenance/work-plan?kTypeId=${kTypeId}&adcTypeId=${adcTypeId}&customer=${encodeURIComponent(customer)}
        &numberplate=${encodeURIComponent(numberplate)}&mileage=${mileage}&mdQualColId=${mdQualColId}
        &ordernumber=${encodeURIComponent(ordernumber)}&phone=${encodeURIComponent(customerPhone)}&showLetterHead=${showLetterHead}&showCarGraphic=${showCarGraphic}&backButtonText=${encodeURIComponent(backTranslation)}`;
        return url;
    }


    public getTecRMICalculation(kTypeId: number, adcTypeId: number, customer: string, numberplate: string, mileage: any, ordernumber: string, phone: string, showLetterHead: boolean) {
        const url = `${environment.apiUrl}/tecrmi/calculation?kTypeId=${kTypeId}&adcTypeId=${adcTypeId}&customer=${encodeURIComponent(customer)}&numberplate=${encodeURIComponent(numberplate)}
        &mileage=${mileage}&phone=${encodeURIComponent(phone)}&ordernumber=${encodeURIComponent(ordernumber)}&showLetterHead=${showLetterHead}`
        return url;
    }

    public sendWorkPlanByMail(serviceplan: IMaintenancePlanMailRequest) {
        return this.http.post(
            `${environment.apiUrl}/tecrmi/maintenance/send-work-plan-by-mail`,
            serviceplan
        );
    }

    public getWorkPlanAsHTML(kTypeId: number, adcTypeId: number, customer: string, numberplate: string, mileage: string, mdQualColId: number, ordernumber: string) {
        const url = `${environment.apiUrl}/tecrmi/maintenance/work-plan?kTypeId=${kTypeId}&adcTypeId=${adcTypeId}&customer=${encodeURIComponent(customer)}&numberplate=${encodeURIComponent(numberplate)}&mileage=${mileage}&mdQualColId=${mdQualColId}&ordernumber=${encodeURIComponent(ordernumber)}`;
        return this.http.get(url, {responseType: 'text'});
    }

    public getManualAvailable(adcTypeId: number) {
        return this.http.get(
            `${environment.apiUrl}/tecrmi/manual/maintenance/available?adcTypeId=${adcTypeId}`
        );
    }

    public getChangeIntervals(adcTypeId: number): Observable<IVehicleSpecSubGroup[]> {
        return this.http.get<IVehicleSpecSubGroup[]>(
            `${environment.apiUrl}/tecrmi/vehicle-spec/change-intervals?adcTypeId=${adcTypeId}`
        )
    }

    public getWorkManuals(adcTypeId: number, workId: number): Observable<ILinkItem[]> {
        return this.http.get<ILinkItem[]>(`
            ${environment.apiUrl}/tecrmi/manual/technical/linked-technical-manuals-for-work?workId=${workId}&adcTypeId=${adcTypeId}`
        );
    }

    public getTechnicalDataCategoriesRest(): Observable<string[]> {
        return this.http.get<string[]>(
            `${environment.apiUrl}/tecrmi/technical-details/categories`
        );
    }
}
