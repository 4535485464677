<app-collapsible-panel panelTitle="{{ userService.sale? ('SPAREPARTS_VIEW.AVAILABILITY_SALE' | translate ): ('SPAREPARTS_VIEW.AVAILABILITY' | translate) }}" class="striped-panel"
                       noPadding="true"
                       *ngIf="service"
                       [@visible]="service.supplier.length">
    <div class="striped-panel availability-panel" ngOnChange="">
        <div class="row quantity-change">
            <div class="col-6 col-xl-4">
                <span class="font-weight-bold">{{ 'CART.AMOUNT' | translate }}</span>
            </div>
            <div class="col-6 col-xl-3">
                <app-debounce-input [value]="service.requestQuantity" [type]="'number'" [delay]="400"
                                    (onChange)="inputLoadAvailability($event)"></app-debounce-input>
            </div>
        </div>
        <ng-container>

            <ng-container *ngFor="let item of service?.supplier; index as i">
                <div class="row rowTable px-2 clickable"
                     [ngClass]="{
                            'selected': isSelected[i] && (item?.supplier && item?.supplier === service._toCartPosition?.supplier),
                            'unselected': (service._toCartPosition?.supplier && service._toCartPosition?.supplier != item?.supplier)
                         }"
                     (click)="selectSupplier(item, i)"
                     *ngIf="item">
                    <ng-container>
                        <!--availability-->
                        <div class=" a-availability">
                            <i *ngIf="item?.loading || !item.schedule" class="fa fa-spinner fa-fw"
                               [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i>
                            <div *ngFor="let avail of item?.schedule" [ngClass]="avail.availability"
                                 style="min-width: 50px; overflow: hidden; text-overflow: ellipsis; line-height: initial">
                                <span class="icon"></span>
                                <span *ngIf="!item?.loading && avail.availability != 'SPECIAL_ORDER'">
                    <span *ngIf="avail.quantity > 0 && avail.quantity != service.requestQuantity">{{ avail.quantity }}</span>
                    <span *ngIf="avail.depotName"> {{ avail.depotName }}</span>
                     <span *ngIf="!avail.depotName && avail.tour"> {{ avail.tour }}</span>
                    <span *ngIf="avail.deliveryDate"> {{ avail.deliveryDate }}</span>
                    <span *ngIf="avail.deliveryTime"> {{ avail.deliveryTime }}</span>
                </span>
                                <span *ngIf="avail.availability == 'SPECIAL_ORDER'"> {{ 'SUPPLIERS.SPECIAL_ORDER_INFO' | translate }}</span>
                                <span *ngIf="!avail.depotName && avail.availability != 'SPECIAL_ORDER'"
                                      class="availability-line"
                                      [ngClass]="{'light' :  (avail.availability != 'AVAILABLE' && avail.availability != 'ASAP' && avail.availability != 'SPECIAL_ORDER' && avail.availability != 'OWN_STOCK' && avail.availability != 'SHIPPING') } ">
                {{ 'SUPPLIERS.' + avail.availability | translate }}
              </span>
                                <span *ngIf="item?.supplier && item?.supplier === service._toCartPosition?.supplier && avail.tour && avail.depotName">
                <br>{{ avail.tour }}
              </span>
                            </div>
                        </div>
                        <!--supplier-->
                        <div class="a-logo">
                            <img *ngIf="item?.supplier" class="img-fluid" [supplier]="item?.supplier"
                                 [logo-color]="(item?.schedule != undefined)"
                                 title="{{findSupplierName(item?.supplier)}}"/>
                        </div>

                        <div class=" a-price text-right">
                            <ng-container>
                                <i *ngIf="!item?.schedule" class="fa fa-spinner fa-fw"
                                   [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i>
                                <span class="price"
                                      *ngIf="!item?.loading && !userService.sale && item?.sellInNetPerPiece && item?.sellInNetPerPiece > 0">
                                            {{ item?.sellInNetPerPieceFormatted }}
                                          </span>
                                <span class="price"
                                      *ngIf="userService.sale && !item?.loading && item?.sellOutNetPerPiece && item?.sellOutNetPerPiece > 0">
                                            {{ item?.sellOutNetPerPieceFormatted }}
                                          </span>
                            </ng-container>
                        </div>
                        <ng-container
                                *ngIf="!isSelected[i] || !((item && item.supplier) && ( item?.supplier === service._toCartPosition?.supplier))">
                            <div class="a-add-to-cart-btn"
                                 *ngIf="!cartService.isOrder() && !(item?.schedule?.length && item.schedule[0].availability && (item.schedule[0].availability === 'NOT_AVAILABLE'  || item.schedule[0].availability === 'INVALID_CREDENTIALS'  || item.schedule[0].availability === 'CONNECTION_BROKEN')) ">
                                <ng-container *ngIf="!showLoadingForCartBtn">
                                    <button *ngIf="cartService?.isNewestCart()" [@submit]="toCartLoading"
                                            [disabled]="toCartLoading === 1"
                                            (click)="isSelected[i] = false; changeItem(item, service._toCartPosition?.supplier);checkReplacement(service._toCartPosition, $event); $event.stopPropagation()"
                                            class="base-btn add-to-cart-btn">
                                        <span *ngIf="toCartLoading === 0">
                                                <i class="fa fa-shopping-cart"></i>
                                            <span class="responsiv-hidden-quantity">+{{ service.requestQuantity }}</span>
                                        </span>
                                        <span *ngIf="toCartLoading === 1 && isSelected[i]">
                                        <i class="fa fa-spinner font-color"
                                           [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i> </span>
                                        <span *ngIf="toCartLoading === 2 ">
                                            <i class="fa fa-exclamation-triangle"></i>
                                            <span class="responsiv-hidden-quantity">+{{ service.requestQuantity }}</span></span>
                                    </button>
                                </ng-container>
                                <ng-container *ngIf="showLoadingForCartBtn">
                                    <div class="text-right">
                                    <span>
                                        <i class="fa fa-spinner font-color"
                                           [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i>
                                    </span>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>

                        <div class="row a-order">
                            <div class="a-price-area text-right">
                                <div [ngClass]="{'a-supplier-vpe-area': (!userService.sale && item?.sellInNetPerPieceFormatted) || (userService.sale && item?.sellOutNetPerPieceFormatted )}">
                                    <span>
                                      <span class="mr-2 ml-3">{{ 'CART.SUPPLIER_PACKING_UNIT_SHORT' | translate }}</span>
                                      <span class="highlight-color">{{ item?.supplierVpe ? item.supplierVpe : '-' }}</span>
                                    </span>
                                    <br>
                                </div>
                                <span>
                                        <span *ngIf="!userService.sale && item?.sellInNetPerPieceFormatted">
                                          <span class="mr-2 ml-3">{{ 'CART.SINGLE_PRICE' | translate }}</span>
                                          <span class="highlight-color">{{ item?.sellInNetPerPieceFormatted }}</span>
                                        </span>
                                        <span *ngIf="userService.sale && item?.sellOutNetPerPieceFormatted">
                                          <span class="mr-2 ml-3">{{ 'CART.SINGLE_PRICE_SALE' | translate }}</span>
                                          <span class="highlight-color">{{ item?.sellOutNetPerPieceFormatted }}</span>
                                        </span>
                                      </span>
                                <div class="a-total-price"
                                     *ngIf="item?.requestedQuantity > 1 && (item?.sellOutNetTotalFormatted || getTotalPrice())">
                                        <span *ngIf="!userService.sale && getTotalPrice()">
                                          <span class="mr-2 ml-3">
                                              {{ userService.sale ? ('CART.TOTAL_PRICE_SALE' | translate) : ('CART.TOTAL_PRICE' | translate) }}
                                          </span>
                                          <span class="highlight-color">{{ getTotalPrice() }}</span>
                                        </span>
                                    <span *ngIf="userService.sale && item?.sellOutNetTotalFormatted">
                                          <span class="mr-2 ml-3">
                                              {{ userService.sale ? ('CART.TOTAL_PRICE_SALE' | translate) : ('CART.TOTAL_PRICE' | translate) }}
                                          </span>
                                          <span class="highlight-color">{{ item?.sellOutNetTotalFormatted }}</span>
                                        </span>
                                </div>
                            </div>
                            <div class="a-button-area text-right" *ngIf="!cartService.isOrder()">
                                <button *ngIf="cartService?.isNewestCart()" [@submit]="toCartLoading"
                                        [disabled]="toCartLoading === 1"
                                        (click)="checkReplacement(service._toCartPosition, $event)"
                                        class="base-btn in-cart-btn" [ngClass]="{'error': toCartLoading === 2}">
                                        <span *ngIf="toCartLoading === 0"><i
                                                class="fa fa-shopping-cart"></i>
                                            <span class="responsiv-hidden-quantity">+{{ service.requestQuantity }}</span>
                                        </span>
                                    <span *ngIf="toCartLoading === 1"><i class="fa fa-spinner font-color"
                                                                         [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i> </span>
                                    <span *ngIf="toCartLoading === 2 "><i
                                            class="fa fa-exclamation-triangle"></i>
                                        <span class="responsiv-hidden-quantity">+{{ service.requestQuantity }}</span>
                                    </span>
                                </button>
                            </div>
                        </div>

                    </ng-container>
                </div>
            </ng-container>
            <div class="row no-supplier-row">
                <div class="col-8">
                    <span>{{ 'ARTICLE_SELECTION.NO_SUPPLIER' | translate }}</span>
                </div>
                <div class="col-4">
                    <button class="base-btn" (click)="addArticleWithoutSupplier()">
                        <i class="fa fa-shopping-cart" *ngIf="!loadAddToCart"></i>
                        <i class="fa fa-spinner"
                           [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"
                           *ngIf="loadAddToCart"></i>
                        <span class="responsiv-hidden-quantity">+{{ service.requestQuantity }}</span>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</app-collapsible-panel>



