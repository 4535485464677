import {Injectable, EventEmitter, Output} from '@angular/core';
import {ArticlesService} from '../../articles/services/articles.service';
import {Router} from '@angular/router';


@Injectable()
export class BodygraphicsService {

    public resetPartSearch: EventEmitter<any> = new EventEmitter<any>();

    constructor(private articlesService: ArticlesService,
                private router: Router) {
        if (window.addEventListener) {
            window.addEventListener('message', this.receiveMessage.bind(this), false);
        } else {
            (<any>window).attachEvent('onmessage', this.receiveMessage.bind(this));
        }
    }

    public receiveMessage: any = (event: any) => {

        if (event && event.data) {
            if (event.data.url) {
            } else {
                if (event.data.length) {
                    this.router.navigate(['/articles']);
                    this.articlesService.updateTreeBySelectedGenArt(event.data);
                }
            }
        }
    }
}
