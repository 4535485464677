import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../services/admin.service';
import {IFirmLogin} from '../../../../shared/interfaces/firm';
import {UserService} from '../../../../shared/services/user/user.service';
import {HelperService} from '../../../../shared/services/helper.service';

@Component({
    selector: 'app-firm-user',
    templateUrl: './firm-user.component.html'
})
export class FirmUserComponent implements OnInit {

    constructor(private router: Router,
                public userService: UserService,
                private route: ActivatedRoute,
                private helperService: HelperService,
                public adminService: AdminService) {

    }

    ngOnInit() {
        this.route.params.subscribe(
            (params) => {
                if (!this.adminService.selectedFirm || this.adminService.selectedFirm.firmId !== params['id']) {
                    this.adminService.getFirmDataById(params['id']).subscribe(
                        (firm) => this.adminService.selectedFirm = firm
                    )
                }
            }
        );

        if (this.adminService.step[0].length === 0 || !this.adminService.selectedFirm) {
            void this.router.navigate(['admin']);
        }
    }

    public navigate(user: IFirmLogin) {
        void this.router.navigate(['admin/firm/user/', user.id, this.adminService.selectedFirm.firmId]);
    }

    public reset() {
        void this.router.navigate(['admin']);
    }

    public createUser() {
        void this.router.navigate(['admin/firm/user', '0', this.adminService.selectedFirm.firmId]);
    }

    private removeUserFromList(user: any) {
        if (this.adminService && this.adminService.selectedFirm && this.adminService.selectedFirm.loginList) {
            for (let i = 0; i < this.adminService.selectedFirm.loginList.length; i++) {
                if (this.adminService.selectedFirm.loginList[i].id === user.id) {
                    this.adminService.selectedFirm.loginList.splice(i, 1);
                    return;
                }
            }
        }
    }

    public deleteLogin(userData: any, _delete: boolean, right: string) {
        switch (right) {
            case ('GLOBAL-ADMIN'):
                if (!_delete) {
                    userData.delete = true;
                } else {
                    this.adminService.deleteUser(userData.id).subscribe(
                        (success) => {
                            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
                            this.removeUserFromList(userData);
                        },
                        (error) => {
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_ENTRY', 'error');
                        }
                    )
                }
                break;
            case ('CLUB-ADMIN'):
                if (!_delete) {
                    userData.delete = true;
                } else {
                    this.adminService.archiveUserClubAdmin(userData.id).subscribe(
                        (success) => {
                            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
                            this.removeUserFromList(userData);
                        },
                        (error) => {
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_ENTRY', 'error');
                        }
                    )
                }
                break;
            case ('FIRM-ADMIN'):
                if (!_delete) {
                    userData.delete = true;
                } else {
                    this.adminService.archiveUserFirmAdmin(userData.id).subscribe(
                        (success) => {
                            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
                            this.removeUserFromList(userData);
                        },
                        (error) => {
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_ENTRY', 'error');
                        }
                    )
                }
                break;
        }
    }

    public isGlobalAdmin() {
        return this.userService.allow('GLOBAL-ADMIN');
    }

    public isFirmAdmin() {
        return this.userService.allow('FIRM-ADMIN');
    }

    public isClubAdmin() {
        return this.userService.allow('CLUB-ADMIN');
    }
}
