import {Component, OnInit} from '@angular/core';
import {ICountry} from 'app/shared/interfaces/DTO/firm/country';
import {Router} from '@angular/router';
import {GlobalAdminService} from '../services/global-admin.service';
import {HelperService} from '../../../shared/services/helper.service';
import { AdminRestService } from 'app/shared/services/admin.rest.service';

@Component({
    selector: 'app-global-country-config',
    templateUrl: './global-country-config.component.html',
    styleUrls: ['./global-country-config.component.scss']
})
export class GlobalCountryConfigComponent implements OnInit {

    public countryList: ICountry[];
    public loadCountryList: boolean;

    constructor(private adminRestService: AdminRestService,
                private helperService: HelperService,
                private globalAdminService: GlobalAdminService,
                private router: Router) {
    }

    ngOnInit() {
        this.loadCountryList = true;
        this.adminRestService.getCountryList().subscribe(
            (countryList: ICountry[]) => {
                this.loadCountryList = false;
                this.countryList = countryList;
            },
            (error) => {
                this.loadCountryList = false;
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_COUNTRY_LIST', 'error');
            });
    }

    public selectCountry(country: ICountry) {
        if (country) {
            this.globalAdminService.selectedCountry = country;
            this.router.navigate(['/global-admin/country/edit']);
        }
    }

    public createNewCountry() {
        const country: ICountry = {
            currency: '',
            id: null,
            iso31661Alpha2: '',
            iso31661Alpha3: '',
            iso31661Numeric: '',
            name: 'NEW',
            vat: null,
            defaultLanguage: 'de'
        }
        this.globalAdminService.selectedCountry = country;
        this.router.navigate(['/global-admin/country/edit']);
    }

}
