import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {ArticlesService} from './services/articles.service';
import {UserService} from '../shared/services/user/user.service';
import {CarService} from '../shared/services/car/car.service';
import {AssemblyCategoriesService} from './services/assembly-categories.service';
import {bounceIn, sidebar} from '../shared/animations/sidebar';
import {collapsibleOpen} from '../shared/animations/general.animation';
import {fromEvent, Subscription} from 'rxjs';
import {HelperService} from '../shared/services/helper.service';
import {ConfigService} from '../shared/services/config.service';
import {SearchHistoryService} from '../shared/services/search-history.service';
import {Router} from '@angular/router';
import {AutovistaService} from '../graphical-part-search/services/autovista.service';
import {StateService} from '../shared/services/state.service';

@Component({
    selector: 'app-articles',
    templateUrl: './articles.component.html', // sidebar
    styleUrls: ['./articles.component.scss'],
    host: {
        '(document:click)': 'onOutsideClick($event)'
    },
    animations: [sidebar, bounceIn, collapsibleOpen]
})
export class ArticlesComponent implements OnInit, AfterViewInit, OnDestroy {
    public availabilityArray = [
        {availability: 'AVAILABLE', sortNo: 1},
        {availability: 'NOT_AVAILABLE', sortNo: 2},
        {availability: 'SHIPPING', sortNo: 3},
        {availability: 'ASAP', sortNo: 4},
        {availability: 'BACKORDER', sortNo: 5},
        {availability: 'CONNECTION_BROKEN', sortNo: 6},
        {availability: 'SPECIAL_ORDER', sortNo: 7},
        {availability: 'INVALID_CREDENTIALS', sortNo: 8},
        {availability: 'OWN_STOCK', sortNo: 9}
    ].sort((a, b) => a.sortNo - b.sortNo);
    public showLegend: boolean = false;
    public filtersExpanded: boolean = false;
    public selectedGenArts: any;
    public searchString: any;
    public showArticleSize: boolean = false;
    public showSearchResultSupplier: boolean = false;
    public openFilterBar: boolean = false;
    public countSmallerThreshold: boolean = true;
    public scrollEnd: boolean = false;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private readonly THRESHOLD: number = 40;
    private panelContentOffsetTop: number;
    private searchTimeStamp: Date;
    private debounceTime: number = 400;
    private intervall: any;
    private offset: number = 5;
    private updateFilterBarSubscriber: Subscription;
    private copyParams: any;
    private copyItem: any;

    private buttonResortScrollEndEventSubscription: Subscription;

    @ViewChild('t3', {static: true}) ngbTabSet;
    @ViewChild('searchinput', {static: true}) searchInput;
    @ViewChild('overlay') overlay: ElementRef;


    @HostListener('document:click', ['$event'])
    onClickOutside(event: MouseEvent): void {
        if (this.overlay && this.overlay.nativeElement && !this.overlay.nativeElement.contains(event.target)) {
            this.showLegend = false;
        }
    }

    constructor(public articlesService: ArticlesService,
                public stateService: StateService,
                public assemblyCategoriesService: AssemblyCategoriesService,
                public userService: UserService,
                private _eref: ElementRef,
                public carService: CarService,
                public helperService: HelperService,
                private ref: ChangeDetectorRef,
                public configService: ConfigService,
                public searchHistoryService: SearchHistoryService,
                public router: Router,
                public autovistaService: AutovistaService) {
        this.stateService.updateArticleContent();
        this.articlesService.emitLastSearchParams.subscribe((lastSearchParams) => {
            if (lastSearchParams.length !== 0 &&
                lastSearchParams !== '' &&
                lastSearchParams !== this.copyParams &&
                !this.articlesService.selectedArticle) {
                this.copyParams = lastSearchParams;
            }

            // nachdem die letzten search params übermittelt wurden ( was bei jeder Suche passiert), wird der disabled Button fürs sortieren geprüft
            const interval: ReturnType<typeof setInterval> = setInterval(() => {
                if (this.articlesService?.searchResult?.count) {
                    this.countSmallerThreshold = this.articlesService?.searchResult?.count <= this.THRESHOLD;
                    clearInterval(interval);
                }
            }, 250);
        });
        this.articlesService.emitItem.subscribe((item) => {
            if (item.length !== 0 &&
                item !== '' &&
                item !== this.copyParams &&
                !this.articlesService.selectedArticle) {
                this.copyItem = item;
            }
        });

        this.ref.detach();
        this.intervall = setInterval(() => {
            if (this.ref && !this.ref['destroyed']) {
                this.ref.detectChanges();
            }
        }, 500);

        this.articlesService.treeCategoryChanged$.subscribe(category => this.onTreeCategoryChanged(category));
        this.articlesService.updateFilterBar.subscribe(() => {
            setTimeout(() => this.update(), 70);
            this.articlesService.getFilterGroupNames();
        });

    }

    ngOnInit(): void {
        this.articlesService.onReset.subscribe(
            (next) => {
                if (this.ngbTabSet && this.ngbTabSet.tabs) {
                    this.ngbTabSet.select('ngb-tab-0');
                }
                if (!this.articlesService.searchOpen) {
                    this.clearFilter();
                }

            }
        );
        if (!this.carService.selectedCar) {
            this.assemblyCategoriesService.updateAssemblyCategories(0);
        }

        this.updateFilterBarSubscriber = this.articlesService.updateFilterBar.subscribe(() => {
            setTimeout(() => this.update(), 70);
            this.articlesService.getFilterGroupNames();
        });

        this.buttonResortScrollEndEventSubscription = this.articlesService.scrollEndEmitter.subscribe((scrollEnd: boolean) => {
            this.scrollEnd = scrollEnd;
        });
    }

    public sortFiltersBySortNoAndAlphabetical(): void {
        if ((this.articlesService?.searchResult?.possibleFilters)) {
            this.articlesService.searchResult.possibleFilters.sort((a, b) => {
                if (a.sortNo !== b.sortNo) {
                    return a.sortNo - b.sortNo;
                }
                return a.groupName.localeCompare(b.groupName);
            });
        }
    }

    ngOnDestroy() {
        this.buttonResortScrollEndEventSubscription.unsubscribe();
        this.ref.detach();
        clearInterval(this.intervall);
        this.articlesService.searchOpen = false;
        this.updateFilterBarSubscriber.unsubscribe();
    }

    public onOutsideClick(event: any): void {
        if (!this._eref.nativeElement.contains(event.target)) {
            this.showSearchResultSupplier = false;
            this.showArticleSize = false;
        }
    }

    public resetQuickFilter() {
        this.articlesService.updateArticleSearch(1, []);
    }

    public setArticleSize(newSize: string): void {
        this.articlesService.setSelectedSearchResultSize(newSize);
        this.showArticleSize = false;
    }

    public setSearchResultSupplier(type: string, supplier: string): void {
        if (type === 'AFTER_MARKET') {
            this.articlesService.selectedSearchResultSupplier = supplier;
        } else {
            this.articlesService.selectedSearchResultOeSupplier = supplier;
        }

        this.articlesService.getAavailability(true, type);
        this.showSearchResultSupplier = false;
    }

    public selectFilter(event: any) {
        const temp = [];

        for (const filter of this.articlesService.searchResult.activeFilters) {
            if (filter.groupId !== event.groupId) {
                temp.push(filter);
            }
        }

        this.articlesService.searchResult.activeFilters = temp;
        this.articlesService.searchResult.activeFilters.push(event);
        this.articlesService.updateArticleSearch(1, this.articlesService.searchResult.activeFilters, this.articlesService.searchResult.searchForAllArticles);
    }

    public getSelectedFilterValue(groupId: string) {
        if (groupId) {
            for (const filter of this.articlesService.searchResult.activeFilters) {
                if (filter.groupId === groupId) {
                    return filter;
                }
            }
        }
    }

    public showPseudoArticleDetails(pseudoArtikel: any) {
        this.userService.navigationHelper = 'right';
        if (!this.articlesService.selectedArticle || this.articlesService.selectedArticle.id !== pseudoArtikel.id) {
            this.articlesService.expanded_right = !this.articlesService.expanded_right;
            let dropCar = false;
            if ((pseudoArtikel.kTypeId === 0 || pseudoArtikel.adcTypeId === 0) && this.carService.selectedCar) {
                pseudoArtikel.kTypeId = this.carService.selectedCar.kTypeId;
                pseudoArtikel.adcTypeId = this.carService.selectedCar.adcTypeId;
                dropCar = true;
            }
            this.articlesService.updateArticleDetails(pseudoArtikel.id, pseudoArtikel.kTypeId, pseudoArtikel.adcTypeId, pseudoArtikel, dropCar);
        }
    }

    public toggleFilterBar() {
        this.openFilterBar = !this.openFilterBar;
        setTimeout(() => {
                this.update();
            },
            70);
    }

    public articlesSearch() {
        if (this.searchString && this.searchString.toString().trim().length > 0) {
            this.articlesService.searchOpen = true;
            this.resetCategory();
        } else {
            this.articlesService.searchOpen = false;
        }
    }

    ngAfterViewInit() {
        const panelContent = document.getElementById('panelContent');
        if (panelContent) {
            this.panelContentOffsetTop = panelContent.offsetTop + this.offset;

            if (this.searchInput) {
                const eventStream = fromEvent(this.searchInput.nativeElement, 'keyup');

                eventStream.subscribe((input) => {
                    const me = this;
                    this.searchTimeStamp = new Date();
                    setTimeout(function () {
                        if (new Date().getTime() - me.searchTimeStamp.getTime() > me.debounceTime) {
                            me.articlesSearch();
                        }
                    }, this.debounceTime);
                });
            }
        }
    }

    public update() {
        const panelContent = document.getElementById('panelContent');
        if (this.articlesService.searchResult && this.articlesService.searchResult.activeFilters.length > 0) {

            if (this.ref && !this.ref['destroyed']) {
                this.ref.detectChanges();
            }

            panelContent.style.top = (this.panelContentOffsetTop +
                document.getElementById('filterBar').offsetHeight + this.offset).toString() + 'px';

        } else {
            if (panelContent) {
                if (this.ref && !this.ref['destroyed']) {
                    this.ref.detectChanges();
                }
                if (this.panelContentOffsetTop) {
                    panelContent.style.top = this.panelContentOffsetTop.toString() + 'px';
                }
            }
        }
    }

    public onTreeNodeClick(event: any) {
        this.articlesService.articlesLoading = false;
        this.articlesService.searchOpen = false;
        this.articlesService.selectedPseudoArticle = undefined;
        this.articlesService.showPseudoArticle = false;
        if (event.node !== undefined) {
            this.articlesService.selectTreeItem(event.node.data);
        } else {
            this.articlesService.selectTreeItem(event);
        }

    }


    public focusInput(searchinput) {
        searchinput.focus();
    }

    public showNavigation() {
        this.userService.setNavigationLeft();
        if (this.ngbTabSet && this.ngbTabSet.tabs) {
            this.ngbTabSet.select(this.ngbTabSet.tabs.first.id);
        }
    }

    public showFilter() {
        if (this.hasFilter() && this.ngbTabSet && this.ngbTabSet.tabs) {
            this.userService.setNavigationLeft();
            this.ngbTabSet.select(this.ngbTabSet.tabs.last.id);
        }
    }

    public hasFilter() {
        return (this.articlesService.searchResult && this.articlesService.searchResult.possibleFilters && this.articlesService.searchResult.possibleFilters.length > 0);
    }

    public resetCategory() {
        this.articlesService.articlesSearchResult = undefined;
        this.articlesService.searchResult = undefined;
        this.articlesService.showPseudoArticle = false;
        this.articlesService.selectedPseudoArticle = undefined;
        this.articlesService.resetToDefaultTree();
        this.selectedGenArts = false;
        if (this.ngbTabSet && this.ngbTabSet.tabs) {
            this.ngbTabSet.select('ngb-tab-0');
        }
        if (this.panelContentOffsetTop > 0) {
            this.update();
        }
    }

    public fullReset() {
        this.userService.setNavigationDefault();
        this.articlesService.expanded_right = false;
        this.articlesService.searchOpen = false;
        this.articlesService.bodygraphics = false;
        this.assemblyCategoriesService.selectedAssemblyCategory = undefined;
        this.articlesService.articlesLoading = false;
        this.articlesService.selectedPseudoArticle = undefined;
        this.articlesService.showPseudoArticle = false;
        this.resetCategory();
        this.clearFilter();
        if (this.panelContentOffsetTop > 0) {
            this.update();
        }
    }


    private onTreeCategoryChanged(category) {
        if (category) {
            this.articlesService.searchOpen = false;
            this.articlesService.treeFilter = category;
            this.articlesService.selectedPseudoArticle = undefined;
            this.articlesService.showPseudoArticle = false;
        } else {
            this.clearFilter();
        }
    }

    private clearFilter() {
        this.articlesService.searchOpen = false;
        this.articlesService.treeFilter = undefined;
        this.assemblyCategoriesService.selectedAssemblyCategory = undefined;
        this.articlesService.selectedPseudoArticle = undefined;
        this.articlesService.showPseudoArticle = false;
    }


    public goBackToSearch() {
        if (this.copyParams.searchString) {
            this.articlesService.directSearchValue = this.copyParams.searchString;
            this.articlesService.hidePartsListPseudo = false;
            this.articlesService.resetToDefaultTree();
            this.searchHistoryService.addItem(this.articlesService.directSearchValue);

            this.articlesService.updateArticlesBySearchString(this.articlesService.directSearchValue);
            this.router.navigate(['/articles']);
        } else {
            this.articlesService.selectedCategory = this.copyItem;
            this.articlesService.directSearchValue = this.copyParams.searchString;
            this.articlesService.resetToDefaultTree();
            this.articlesService.selectTreeItem(this.copyItem);
            this.router.navigate(['/articles']);
        }
    }
}
