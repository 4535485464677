<div class="heading blue">
    <span class="clickable" routerLink="/company-admin">
        <i class="fa fa-th" aria-hidden="true"></i>
        {{'ADMIN_PANEL.OVERVIEW' | translate}}
    </span>
    <span class="clickable"  routerLink="/company-admin/suppliers">
        <i class="fa fa-caret-right"></i>
        {{'USER_PROFILE.SUPPLIER_MANAGEMENT' | translate}}
    </span>
    <span *ngIf="companyAdminService.supplier?.supplier">
        <i class="fa fa-caret-right"></i>
        {{companyAdminService.supplier?.supplierName}}
    </span>
</div>
<div class="panel-content center-content white-background">
    <app-firm-supplier
            [_supplier]="companyAdminService.supplier"
            [unassignedSupplier]="unassignedSupplier"
            [firmId]="userService.accountData?.firmId"
            (_back)="back()"
            (_save)="save($event)">
    </app-firm-supplier>
</div>

