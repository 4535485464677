import {Component, OnInit, Input} from '@angular/core';
import {TabsetComponent} from "../tabset.component";

@Component({
    selector: 'app-tab',
    templateUrl: 'tab.component.html',
    styleUrls: ['tab.component.scss']
})
export class TabComponent implements OnInit {
    @Input() private tabTitle: string;
    public active: boolean = false;

    constructor(public tabs: TabsetComponent) {
        tabs.addTab(this);
    }

    ngOnInit() {
    }

}
