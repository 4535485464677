import {Component, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../services/admin.service';
import {IAdminFirm} from '../../../../shared/interfaces/adminFirm';

@Component({
    selector: 'app-editcompany',
    templateUrl: './editcompany.component.html',
    styleUrls: ['./editcompany.component.scss']
})

@Output('admin')
export class EditcompanyComponent implements OnInit {
    private firmID_params: any;

    public showFirmData = false;

    constructor(private route: ActivatedRoute,
                public adminService: AdminService,
                private router: Router) {
    }

    ngOnInit() {
        this.route.params.subscribe(
            (params) => {
                this.firmID_params = params['id'];
                if (!this.adminService.selectedFirm || this.adminService.selectedFirm.firmId !== params['id']) {
                    this.adminService.getFirmDataById(params['id']).subscribe(
                        (firm) => {
                            setTimeout(() => {
                                    this.adminService.selectedFirm = firm;
                                    this.showFirmData = true;
                                },
                                30);


                        });
                }
            }
        );
    }

    public save(firm: IAdminFirm) {
        this.adminService.saveFirm(firm);
    }
}
