import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assortment-view',
  templateUrl: './assortment-view.component.html'
})
export class AssortmentViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
