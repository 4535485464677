import {Component, OnInit} from '@angular/core';
import {AdminService} from '../club/services/admin.service';
import {NavigationStart, Router} from '@angular/router';
import {DatamapperService} from './services/datamapper.service';
import {AssortmentService} from './services/assortment.service';
import {UserService} from '../../shared/services/user/user.service';

@Component({
    selector: 'app-assortment',
    templateUrl: './assortment.component.html'
})
export class AssortmentComponent implements OnInit {
    public supplierId: number;
    public genArtId: number;

    constructor(public adminService: AdminService,
                public userService: UserService,
                public assortmentService: AssortmentService,
                public dataMapperService: DatamapperService,
                public router: Router) {

        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.checkStateRouteBased(event.url);
            }
        });
    }

    public checkStateRouteBased(url: string) {
        if (url.includes('supplier')) {
            this.supplierId = parseInt(url.substring(url.lastIndexOf('/') + 1));
        }
        if (url.includes('article')) {
            this.genArtId = parseInt(url.substring(url.lastIndexOf('/') + 1));
        }

        if (url.includes('/company-admin/assortment')) {
            this.assortmentService.stateClubOrFirm.next('ownfirm');
        }
        if (url.includes('/admin/firm/assortment')) {
            this.assortmentService.stateClubOrFirm.next('firm');
        }
        if (url.includes('/admin/assortment')) {
            this.assortmentService.stateClubOrFirm.next('club');
        }
    }

    ngOnInit() {
        this.dataMapperService.getData();
        const url = this.router.url;
        this.checkStateRouteBased(url);
    }
}
