import { Component, OnInit } from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {IAdminUpdateOrCreateFirmRequest} from '../../../../shared/interfaces/DTO/admin/adminUpdateOrCreateFirmRequest';

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html'
})
export class CreateCompanyComponent implements OnInit {

  public firm: IAdminUpdateOrCreateFirmRequest;
  constructor(public adminService: AdminService) {}


  ngOnInit() {
  }

  public save(firm: IAdminUpdateOrCreateFirmRequest) {
      this.adminService.createFirm(firm);
  }
}
