import {Component, OnInit, Input} from '@angular/core';
import {ICar} from "../../services/car/interfaces/car";
import {CarService} from "../../services/car/car.service";
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-car-warning',
    templateUrl: './car-warning.component.html',
    styleUrls: ['./car-warning.component.scss']
})
export class CarWarningComponent implements OnInit {

    public selectedItem: ICar;

    constructor(public carService: CarService,
                private datePipe: DatePipe) {

    }
    ngOnInit() {
    }
}
