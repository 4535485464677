import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IFirm} from '../interfaces/DTO/firm/firm';
import {IFirmLogin} from '../interfaces/firm';
import {IUpdateLoginRequest} from '../interfaces/DTO/firm/updateLoginRequest';
import {ICreateLoginRequest} from '../interfaces/DTO/firm/createLoginRequest';
import {IFirmAdminUpdateFirmRequest} from '../interfaces/DTO/firm/firmAdminUpdateFirmRequest';
import {IClubSetting} from '../interfaces/DTO/club/clubSetting';
import {IClubDataSupplierRestrictions, IDataSupplier} from '../interfaces/DTO/club/clubDataSupplierRestrictions';
import {ICountry} from '../interfaces/DTO/firm/country';
import {IExternalFirmTools} from '../interfaces/DTO/firm/external-tools';
import {IPossibleExternalTool} from '../interfaces/possible-external-tool';

@Injectable()
export class AdminRestService {

    constructor(private http: HttpClient) {
    }

    public getFirm(): Observable<IFirm> {
        return this.http.get<IFirm>(
            `${environment.apiUrl}/firmadmin/firm`
        );
    }

    public getUserByID(userID: number): Observable<IFirmLogin> {
        return this.http.get<IFirmLogin>(
            `${environment.apiUrl}/firmadmin/login/${userID}`
        );
    }

    public setUserByID(userData: IUpdateLoginRequest, userID: number): Observable<any> {
        return this.http.put(
            `${environment.apiUrl}/firmadmin/login/${userID}`,
            userData
        );
    }

    public createUser(userData: ICreateLoginRequest): Observable<any> {
        return this.http.post(
            `${environment.apiUrl}/firmadmin/login/create`, userData
        );
    }

    public updateFirm(firmData: IFirmAdminUpdateFirmRequest): Observable<any> {
        return this.http.put(
            `${environment.apiUrl}/firmadmin/firm`,
            firmData
        );
    }

    public getExternalTools(firmId: number): Observable<IExternalFirmTools[]> {
        return this.http.get<IExternalFirmTools[]>(
            `${environment.apiUrl}/admin/firm/externaltools/${firmId}`
        );
    }

    public getPossibleExternalTools(firmId: number): Observable<IPossibleExternalTool[]> {
        return this.http.get<IPossibleExternalTool[]>(
            `${environment.apiUrl}/admin/firm/unassignedexternaltools/${firmId}`
        );
    }

    public deleteExternalTool(toolId: number) {
        return this.http.delete(
            `${environment.apiUrl}/admin/firm/externaltools/${toolId}`
        )
    }

    public updateExternalTool(tool: IExternalFirmTools): Observable<IExternalFirmTools> {
        return this.http.put<IExternalFirmTools>(
            `${environment.apiUrl}/admin/firm/externaltools/${tool.firmId}`,
            tool
        );
    }

    public createExternalTool(tool: IExternalFirmTools): Observable<IExternalFirmTools> {
        return this.http.post<IExternalFirmTools>(
            `${environment.apiUrl}/admin/firm/externaltools`,
            tool
        )
    }

    public getCountryList(): Observable<ICountry[]> {
        return this.http.get<ICountry[]>(
            `${environment.apiUrl}/admin/country/list`
        );
    }

    public addOrUpdateCountry(country: ICountry) {
        return this.http.post(
            `${environment.apiUrl}/admin/country`,
            country
        )
    }
}
