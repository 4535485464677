<ng-container *ngIf="!loading">
    <div class="panel-content center-content">
        <div *ngFor="let pos of rawTools" class="row mb-5">
            <div class="col">
                <div class="row">
                    <div class="col headerType border-bottom">
                        <span class="selection title"><b>{{pos?.itemMpText}} {{pos?.korText}} {{' (' + pos?.qualColText + ')'}}</b></span>
                    </div>
                </div>
                <div class="row p-1" *ngFor="let tool of pos?.rawTools;let l = last; let odd = odd"
                     [ngClass]="{'border-bottom': !l, 'bg-light': odd}">
                    <div class="col-3 raw-tool-image d-flex justify-content-start align-items-center">
                        <img *ngIf="tool?.imageName" src="{{tool?.imageName}}" alt="{{tool?.rawToolName}}">
                    </div>
                    <div class="col d-flex justify-content-start align-items-center">
                        {{tool?.rawToolName}}<b>&nbsp; ({{tool?.rawToolNumber}})</b>
                    </div>
                </div>
                <div class="spacer"></div>
            </div>
        </div>
    </div>
</ng-container>

<app-default-loading-page *ngIf="loading"></app-default-loading-page>
