import {EventEmitter, Injectable, NgZone} from '@angular/core';
import {CartRestService} from './cart.rest.service';
import {IOrderListItem} from '../../shared/services/order/interfaces/order-list-item';
import {OrderRestService} from '../../shared/services/order/order.rest.service';
import {ArticlesService} from '../../articles/services/articles.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {UserService} from '../../shared/services/user/user.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {CarService} from '../../shared/services/car/car.service';
import {CustomerDataService} from '../../shared/services/customerData.service';
import {ICart} from '../../shared/interfaces/DTO/cart/cart';
import {ICartInfoResponse} from '../../shared/interfaces/DTO/cart/cartInfoResponse';
import {CarPosition, ICartPosition, ICartRequest, ICartResponse} from '../interfaces/cart';
import {ICartOrderListWrapper, IEndCustomerData} from '../../shared/interfaces/DTO/order/order';
import {ICartLite} from '../../shared/interfaces/DTO/cart/cartLite';
import {DomSanitizer} from '@angular/platform-browser';
import {CarRestService} from '../../shared/services/car/car.rest.service';
import {ICartOrderListRequest} from '../../shared/interfaces/DTO/cart/cartOrderListRequest';
import {HelperService} from '../../shared/services/helper.service';
import {environment} from '../../../environments/environment';
import {ArticlesDetailsService} from '../../shared/services/articles-details.service';
import {ArticlesRestService} from '../../articles/services/articles.rest.service';
import {CompanyAdminService} from '../../admin/company/services/company-admin.service';
import {IItem} from '../../articles/interfaces/article';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ConfigService} from 'app/shared/services/config.service';
import {IRawTool} from "../interfaces/raw-tool";

@Injectable()
export class CartService extends ArticlesDetailsService {
    public rawTools: IRawTool[];
    public currentCart: ICart;
    public cartCount: ICartInfoResponse;
    public cartEmpty: boolean = true;
    public orderDetails: ICart;
    public currentPreviewOrderId: string;
    public showCustomArticle: boolean;

    public selectedOrderSum: number;
    public selectedOrderCount: number;

    public cartList: ICartLite[];
    public selectedOrder: IOrderListItem;
    public orderList: IOrderListItem[];
    public checkAndRetry: boolean = false;
    public cartNeedsUpdate: boolean = false;
    public showSellinPrices: boolean = false;
    public defaultAcquisitionType: string;
    public lastSelectedCart: string;
    public compositeWorkTime: boolean;

    public backLink: string = '/cart';

    public activeHistory: string;

    public checkedCount: number = 0;
    public checkedSum: number = 0;
    public totalDeliveryCosts: number = 0;
    public checkedVat: number = 0;

    public history: boolean = false;

    public onCompleteOrder: EventEmitter<any> = new EventEmitter<any>();
    public onItemRemove: EventEmitter<any> = new EventEmitter<any>();
    public onCartChange: EventEmitter<any> = new EventEmitter<any>();
    public hasNewCartList: EventEmitter<any> = new EventEmitter<any>();
    public hasNewCartOrderList: EventEmitter<any> = new EventEmitter<any>();
    public dateEmitter: EventEmitter<any> = new EventEmitter<any>();
    public resetTabsEmitter: EventEmitter<any> = new EventEmitter<any>();
    public dmsCartEmitter: EventEmitter<any> = new EventEmitter<any>();

    // loading indicators
    public cartLoading: boolean = false;
    public cartListLoading: boolean = false;
    public orderListLoading: boolean;

    public cartName: string;

    public showCartMessage: boolean = false;

    public cardInitialized: EventEmitter<ICart> = new EventEmitter<ICart>();

    public showDocument: CarPosition;
    public holdCart: boolean = false;

    public editPosition: any;
    public showDocumentWithLink: any;

    public cartPage: number = 0;
    public cartPageSize: number = 20;
    public cartResponse: ICartResponse;
    public updateCartPrice: boolean = false;

    public showCloseCartWarning: boolean = false;
    public cartOrderList: ICartOrderListWrapper;
    public loadCartOrderList: boolean = false;
    public lastSelectedCartIsOrder: boolean;
    public previewCart: ICart;

    public showSupplierSelection: boolean = false;
    public selectedSupplier: string;
    public dealerArticleNumber: string;
    public dealerBrandIdentifier: string;

    public userSupps: any[];
    public supplier: any;
    public loadSuppliers: boolean = false;

    public userOeSupps: any[];
    public oeSuppliers: any;
    public loadOeSuppliers: boolean = false;

    public selectedPseudoArticle: IItem;
    public showPseudoArticle = false;
    public hidePartsListPseudo: false;

    public showNotOrderableBannerBool: boolean = false;

    private composideWorkTimeSubscription: Subscription;
    private cartInitialized: boolean = false;

    public cartCount$: BehaviorSubject<ICartInfoResponse> = new BehaviorSubject<ICartInfoResponse>(undefined);

    constructor(
        private cartRestService: CartRestService,
        private orderRestService: OrderRestService,
        private articlesService: ArticlesService,
        private userService: UserService,
        private location: Location,
        private router: Router,
        private zone: NgZone,
        private toastr: ToastrService,
        private translate: TranslateService,
        private articlesRestService: ArticlesRestService,
        private companyAdminService: CompanyAdminService,
        private carService: CarService,
        private customerDataService: CustomerDataService,
        private helperService: HelperService,
        private carRestService: CarRestService,
        private sanitizer: DomSanitizer,
        private config: ConfigService
    ) {
        super(articlesRestService, carService, userService, companyAdminService, translate, toastr, config);

        this.compositeWorkTime = false;
        this.showCustomArticle = false;

        this.userService.accountData$.subscribe(
            (step) => {
                if (step) {
                    this.updateCartCount();
                    this.updateCart(false, false, false, false);
                    this.cartOrderList = undefined;
                }
            }
        );

        this.onCartChange.subscribe(
            () => {
                this.showNotOrderableBanner();
            }
        );

        this.carService.selectedCarChanged$.subscribe((newCar) => {
            this.userService.selectedCar = newCar;
            if ((this.currentCart && this.currentCart.cars && this.currentCart.cars.length > 0)
                && newCar && ((this.currentCart.cars[0].car.kTypeId !== newCar.kTypeId)
                    || (this.currentCart.cars[0].car.adcTypeId !== newCar.adcTypeId)) && !newCar.keepCart) {
                switch (this.userService.getCarChangeSettings()) {
                    case 'PER_CAR':
                        this.createCart();
                        break;
                    case 'ASK':
                        this.cartName = '';
                        this.showCartMessage = true;
                        break;
                }
            }
        });
    }

    // Cart

    public selectArticle(position: ICartPosition) {
        if (!this.isOrder() && !this.isPreview()) {
            this.selectedArticle = position;
            this.showSupplierSelection = true;
            if (position.type === 'SPAREPART') {
                this.getAvailability();
            } else if (position.type === 'OE_ARTICLE') {
                this.getOeAvailability();
            }
        }
    }

    public getLoading() {
        if (this.selectedArticle) {
            return this.selectedArticle.type === 'SPAREPART' ? this.loadSuppliers : this.loadOeSuppliers;
        }
    }

    public getAvailabilities() {
        if (this.selectedArticle) {
            return this.selectedArticle.type === 'SPAREPART' ? this.supplier : this.oeSuppliers;
        }
    }

    public selectCarFromCart() {
        if (this.currentCart && this.currentCart.cars && this.currentCart.cars.length === 1) {
            this.carService.selectCar(this.currentCart.cars[0].car);
            this.router.navigate(['/articles']);
        }
    }

    public getOeAvailability() {
        this.loadOeSuppliers = true;
        this.articlesRestService.getOeSuppliers().subscribe(
            (oeSuppliers) => {
                this.loadOeSuppliers = false;
                this.userOeSupps = oeSuppliers;
                this.oeSuppliers = [];
                for (let x = 0; x < oeSuppliers.length; x++) {
                    this.oeSuppliers[x] = {};
                    this.oeSuppliers[x].supplier = oeSuppliers[x];
                    this.oeSuppliers[x].loading = true;
                }

                if (this.showSupplierSelection && this.userOeSupps && this.userOeSupps.indexOf(this.selectedArticle.supplier) > -1) {
                    this.selectedSupplier = this.selectedArticle.supplier;
                } else {
                    this.selectedSupplier = undefined;
                }

                this.startOeAvailabilityFlux();
            }
        )
    }

    public startOeAvailabilityFlux() {
        const me = this;
        const observable = Observable.create(observer => {

            const eventSource = new EventSource(environment.apiUrl +
                '/article/oeavailability?articleId=' + encodeURIComponent(this.selectedArticle.articleId) +
                '&requestedQuantity=' + this.selectedArticle.quantity + '&uvp=' + this.selectedArticle.sellOutNetPerPiece);

            eventSource.onmessage = x => {
                observer.next(x.data);
            };
            eventSource.onerror = x => {
                eventSource.close();
            };

            return () => {
                eventSource.close();
            };
        });

        observable.subscribe({
            next: guid => {
                this.zone.run(() => {
                    const temp = JSON.parse(guid);
                    if (this.oeSuppliers[this.userOeSupps.findIndex(e => e.supplier === temp.supplier)]
                        && this.oeSuppliers[this.userOeSupps.findIndex(e => e.supplier === temp.supplier)] !== temp
                        && this.oeSuppliers[this.userOeSupps.findIndex(e => e.supplier === temp.supplier)].supplier.supplier === temp.supplier) {
                        this.oeSuppliers[this.userOeSupps.findIndex(e => e.supplier === temp.supplier)] = temp;
                    }
                });
            },
            error: err => console.error(err)
        });
    }

    public getAvailability() {
        this.loadSuppliers = true;
        this.userService.getSuppliersList().subscribe(
            (response) => {
                this.loadSuppliers = false;
                this.userSupps = response;
                this.supplier = [];
                for (let x = 0; x < response.length; x++) {
                    this.supplier[x] = {};
                    this.supplier[x].supplier = response[x];
                    this.supplier[x].loading = true;
                }

                if (this.showSupplierSelection && this.userSupps && this.userSupps.indexOf(this.selectedArticle.supplier) > -1) {
                    this.selectedSupplier = this.selectedArticle.supplier;
                } else {
                    this.selectedSupplier = undefined;
                }

                this.startAvailabailityFlux();
            },
            (error) => {
                this.loadSuppliers = false;
            });
    }

    public startAvailabailityFlux() {
        const me = this;
        const observable = Observable.create(observer => {
            const eventSource = new EventSource(environment.apiUrl +
                '/article/availability?articleId=' + encodeURIComponent(this.selectedArticle.articleId) +
                '&requestedQuantity=' + this.selectedArticle.quantity);
            eventSource.onmessage = x => {
                observer.next(x.data);
            };
            eventSource.onerror = x => {
                eventSource.close();
            };

            return () => {
                eventSource.close();
            };
        });

        observable.subscribe({
            next: guid => {
                this.zone.run(() => {
                    const temp = JSON.parse(guid);
                    if (this.supplier[this.userSupps.findIndex(e => e.supplier === temp.supplier)]
                        && this.supplier[this.userSupps.findIndex(e => e.supplier === temp.supplier)] !== temp
                        && this.supplier[this.userSupps.findIndex(e => e.supplier === temp.supplier)].supplier.supplier === temp.supplier
                        && temp.articleId === this.selectedArticle.articleId) {
                        this.supplier[this.userSupps.findIndex(e => e.supplier === temp.supplier)] = temp;
                    }
                });
            },
            error: err => console.error(err)
        });
    }

    public resetChangeSupplier() {
        this.showSupplierSelection = false;
        this.selectedSupplier = undefined;
        this.selectedArticle = undefined;
    }

    public closeCurrentCart() {
        const list = [];
        for (const position of this.currentCart.positions) {
            if (position.supplier && position.articleId && position.checked) {
                list.push(position.posId);
            }
        }
        this.cartRestService.sendOrder( list, this.userService.accountData.email, this.currentCart.name).subscribe(
            (success) => {
                this.showCloseCartWarning = false;
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_CLOSE_CART', 'success');
            },
            (error) => {
                this.showCloseCartWarning = false;
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_CLOSE_CART', 'error');
            });
    }

    public cartMessageHandler($event: any) {
        if ($event === true) {
            this.showCartMessage = false;
            return;
        }
        switch ($event.index) {
            case 0:
                if (this.cartCount.positionCount > 0) {
                    this.createCart(this.cartName);
                }
                this.showCartMessage = false;
                break;
            case 1:
                this.showCartMessage = false;
                this.holdCart = true;
                break;
        }
    }

    public getRequiredItemCount() {
        let count = 0;
        if (this.currentCart) {
            for (const item of this.currentCart.requiredItems) {
                if (item.referencedPosId < 0) {
                    count++;
                }
            }
        }

        return count;
    }

    public isNewestCart() {
        return !(this.currentCart && this.currentCart.successor && (this.currentCart.successor.length > 0));
    }

    public loadNext(event: any) {
        if (event !== 0 && !this.infinityLoading && this.searchResult) {
            if (this.userService.enableInfiniteScroll && (this.searchResult.count / 20) > this.searchResult.page) {
                this.infinityLoading = true;
                this.updateArticleSearch(this.searchResult.page + 1, this.searchResult.activeFilters, this.searchResult.searchForAllArticles);
            }
        }

    }

    public setPseudoArticle(article: IItem) {
        if (article && article.pseudoArticle) {
            this.showPseudoArticle = true;
            this.selectedPseudoArticle = article;
        }
    }

    public getCartContent(cartId: string) {
        if (cartId) {
            this.cartLoading = true;
            this.cartRestService.getCartContentRest(cartId).subscribe(
                (cart: ICart) => {
                    this.cartLoading = false;
                    this.currentCart = cart;
                    this.cartEmpty = (this.currentCart && this.currentCart.positions && this.currentCart.positions.length === 0);
                },
                (error) => {
                    this.cartLoading = false;
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_SELECT_CART', 'error');
                });
        }
    }

    public setComment(comment: string) {
        if (comment) {
            this.cartRestService.setCartCommentRest(comment).subscribe(
                (success) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_SAVE', 'success');
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_SAVE', 'error');
                });
        }
    }

    public updateCartDeliveryCosts() {
        this.updateCartPosCount();
        setTimeout(() => {
            const positionList: any = {
                articlePosition: {}
            };

            for (let i = 0; i < this.currentCart.positions.length; i++) {
                positionList.articlePosition[this.currentCart.positions[i].posId.toString()]
                    = this.currentCart.positions[i].selected.toString();

                if (i === (this.currentCart.positions.length - 1)) {
                    this.updateCartPrice = true;
                    this.cartRestService.updateCartDeliveryCostsRest(positionList).subscribe(
                        (deliveryCosts: any) => {
                            this.currentCart.cartCostDTO = deliveryCosts;
                            this.updateCartPrice = false;
                        }, (error) => {
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET', 'error');
                            this.updateCartPrice = false;
                        });
                }
            }
        }, 50);
    }

    public hasDeliveryCostsError() {
        if (!this.isOrder() && this.currentCart && this.currentCart.cartCostDTO.deliveryCosts) {
            return Object.keys(this.currentCart.cartCostDTO.deliveryCosts).some(supplierName => {
                const supplierDeliveryCosts = this.currentCart.cartCostDTO.deliveryCosts[supplierName];
                return supplierDeliveryCosts.brokenConnection || supplierDeliveryCosts.value === null;
            });
        }
        return false;
    }

    public sanitizeUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url)
    }

    public updateCart(isOrder: boolean, noEmit?: boolean, List?: boolean, fromIntern: boolean = false, orderId = null, isPreview: boolean = false) {
        this.updateCartPrice = false;
        this.reset();
        this.previewCart = null;
        return new Promise((resolve) => {
            this.cartLoading = true;
            if (((!this.currentCart || !this.currentCart.lockedOrderAsCart) || !isOrder) && !isPreview) {
                this.cartRestService.getCart().subscribe(
                    (response: ICart) => {
                        if (response) {
                            this.cartHandler(response, noEmit, List, fromIntern);
                            this.onCartChange.emit();
                        }
                    },
                    (error) => {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET_CART', 'error');
                    });
            } else if (isOrder) {
                this.orderRestService.getOrderDetails(orderId).subscribe(
                    (order: ICart) => {
                        this.cartHandler(order, noEmit, List, fromIntern);
                    },
                    (error) => {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET_ORDER', 'error');
                    });
            } else if (isPreview) {
                this.currentPreviewOrderId = orderId;
                this.orderRestService.getOrderDetails(orderId).subscribe(
                    (order: ICart) => {
                        if (this.isOrder()) {
                            this.currentCart = null;
                        }
                        this.previewCart = order;
                        this.cartLoading = false;
                    },
                    (error) => {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET_ORDER', 'error');
                    });
            }
        });
    }

    public updateCartPosCount() {
        this.checkedCount = 0;
        if (this.currentCart && this.currentCart.positions.length > 0) {
            for (let i = 0; i < this.currentCart.positions.length; i++) {
                if (this.currentCart.positions[i].selected && this.currentCart.positions[i].orderable) {
                    this.checkedCount += (this.currentCart.positions[i].preOrderedQuantity > 0 ? (this.currentCart.positions[i].quantity - this.currentCart.positions[i].preOrderedQuantity) : this.currentCart.positions[i].quantity);
                }
            }
        }
    }

    public updateCartCount(noEmit?: boolean) {
        if (!noEmit) {
            this.onCartChange.emit();
        }

        this.cartRestService.getCartCount().subscribe(
            (response: any) => {
                if (this.isOrder() && this.cartCount) {
                    this.cartCount.associatedCarCount = response.associatedCarCount;
                    this.cartCount.calculationData = response.calculationData;
                    this.cartCount.endCustomerData = response.endCustomerData;
                    this.cartCount.lockedOrderAsCart = response.lockedOrderAsCart;
                    this.cartCount.positionCount = response.positionCount;
                    if (!this.cartCount.previousCartId) {
                        this.cartCount.previousCartId = response.previousCartId
                    }
                } else {
                    this.cartCount = response;
                    this.cartCount$.next(response);
                }

                this.setCustomerData(this.cartCount.endCustomerData);
            }
        );
    }

    public getCalculation() {
        if (this.cartCount && this.cartCount.calculationData && this.cartCount.calculationData.name) {
            return this.cartCount.calculationData.name;
        } else {
            return '';
        }
    }

    public setCustomerData(endCustomerData: IEndCustomerData) {
        if (endCustomerData) {
            this.dateEmitter.emit(endCustomerData.registrationDate);
            this.customerDataService.setCustomerEmail(endCustomerData.email);
            this.customerDataService.setCustomerFirstName(endCustomerData.firstName);
            this.customerDataService.setCustomerLastName(endCustomerData.lastName);
            this.customerDataService.setMileage(endCustomerData.mileage);
            this.customerDataService.setNumberplate(endCustomerData.numberplate);
            this.customerDataService.setCustomerZip(endCustomerData.zip);
            this.customerDataService.setCustomerCity(endCustomerData.city);
            this.customerDataService.setCustomerAddress(endCustomerData.street);
            this.customerDataService.setCustomerPhone(endCustomerData.phone);
            this.customerDataService.setHsn(endCustomerData.hsn);
            this.customerDataService.setTsn(endCustomerData.tsn);
            this.customerDataService.setVsn(endCustomerData.vsn);
            this.customerDataService.setVin(endCustomerData.vin);
            this.customerDataService.setKTypeId(endCustomerData.kTypeId);
            this.customerDataService.setYear(endCustomerData.registrationDate);
            this.customerDataService.setOrderNumber(endCustomerData.reference);
            this.customerDataService.setServiceDate(endCustomerData.serviceDate);
        }
    }

    // Cart list

    public updateCartList(selectNewCart?: boolean) {
        this.cartListLoading = true;
        const cartRequest: ICartRequest = {page: this.cartPage, pageSize: this.cartPageSize};
        this.cartRestService.getCartList(cartRequest).subscribe(
            (response) => {
                this.cartListLoading = false;
                this.cartList = response.cartLiteDTOS.reverse();
                this.cartResponse = response;
                this.hasNewCartList.emit(this.cartList);
                if (selectNewCart && response && response.cartLiteDTOS.length > 0) {
                    this.selectCart(response.cartLiteDTOS[0].id, false, false);
                }
            }
        );
    }

    public hasOrderableArticles() {
        if (this.currentCart && this.currentCart.positions.length > 0) {
            for (const position of this.currentCart.positions) {
                if (position.orderable) {
                    return true;
                }
            }

            return false;
        } else {
            return false;
        }
    }

    public selectCart(cartId: string, fromIntern: boolean = false, isOrder: boolean, isPreview: boolean = false) {
        this.cartRestService.selectCart(cartId).subscribe(
            (success) => {
                this.updateCart(isOrder, false, true, fromIntern, cartId, isPreview);
                this.selectedOrder = undefined;
                this.lastSelectedCart = cartId;
                this.lastSelectedCartIsOrder = isOrder;
                this.articlesService.resetAll();
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_SELECT_CART', 'error');
                this.updateCartList();
            }
        );
    }

    public createCart(cartName?: string) {
        this.cartRestService.createCart().subscribe(
            (success) => {
                if (cartName) {
                    // check if name exists
                    let exists = false;

                    if (this.cartList) {
                        exists = this.cartList.some(cart => cart.name === this.cartName);
                    }

                    if (!exists) {
                        this.renameCart(cartName, success.cartId);
                    }
                }

                this.updateCart(false, false, false, false);
                this.cartEmpty = true;
            }
        )
    }

    public clearCart(basketSessionId: string) {
        this.cartRestService.clearCartRest(basketSessionId).subscribe(
            (cart: ICart) => {
                this.currentCart = cart;
                this.updateCartCount();
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_CLEAR_CART', 'success');
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_CLEAR_CART', 'error');
            });
    }

    public renameCart(name: string, id?: string) {
        let currentId: string;

        if (!name) {
            return;
        }

        if (id) {
            currentId = id;
        } else {
            currentId = this.currentCart.id;
        }

        this.cartRestService.renameCart(currentId, name).subscribe(
            (success) => {
                this.updateCartList();
                if (name) {
                    this.currentCart.name = name;
                }
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_RENAME_CART', 'success');
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_RENAME_CART', 'error');
            }
        )
    }

    public deleteCart(cartId: string, event?: any) {
        if (event) {
            event.stopPropagation();
        }
        this.cartRestService.deleteCart(cartId).subscribe(
            (sucess) => {
                if (this.currentCart.id === cartId) {
                    this.currentCart = undefined;
                    this.cartEmpty = true;
                    this.cartCount.positionCount = 0;
                }
                this.onCartChange.emit(null);
                this.updateCart(true, false, false, false);
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_CART', 'success');
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_CART', 'error');
            }
        );
    }


    // Order

    public updateOrderList(timeperiod?: any) {
        this.orderListLoading = true;
        this.activeHistory = timeperiod;
        this.selectedOrder = undefined;
        this.history = true;
        this.currentCart = undefined;
        this.orderRestService.getOrderList(timeperiod).subscribe(
            (response) => {
                this.orderList = response.reverse();
                this.orderListLoading = false;
            }
        );
    }

    public isOrder(): boolean {
        return (this.currentCart && this.currentCart.lockedOrderAsCart);
    }

    public isPreview(): boolean {
        return (
            this.previewCart &&
            Array.isArray(this.previewCart.positions) &&
            this.previewCart.positions.length > 0
        );
    }

    public updateOrderListForCart(page: number, pageSize: number, startDate?: string | '', endDate?: string | '') {
        this.loadCartOrderList = true;
        const orderSearchRequest: ICartOrderListRequest = {
            page: page,
            pageSize: pageSize,
            startDate: startDate,
            endDate: endDate
        }
        this.orderRestService.getOrderListForCartRest(orderSearchRequest).subscribe(
            (cartOrderList: ICartOrderListWrapper) => {
                this.cartOrderList = cartOrderList;
                this.loadCartOrderList = false;
                this.hasNewCartOrderList.emit(cartOrderList.orders);
            },
            (error) => {
                this.loadCartOrderList = false;
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CART_ORDERS', 'error');
            });
    }

    public resetCart() {
        this.currentCart = undefined;
    }

    public resetFullOrder() {
        this.currentCart = undefined;
        this.selectedOrder = undefined;
        this.activeHistory = undefined;
    }

    public selectOrder(order: IOrderListItem) {
        this.selectedOrder = order;
        this.router.navigate(['/cart/history']);
        this.updateOrderDetails();
    }

    public deselectOrder() {
        this.selectedOrder = undefined;

        if (this.lastSelectedCart) {
            this.selectCart(this.lastSelectedCart, false, this.lastSelectedCartIsOrder);
        }
    }

    public updateOrderDetails() {
        if (this.selectedOrder) {
            this.orderRestService.getOrderDetails(this.selectedOrder.id).subscribe(
                (response) => {
                    this.orderDetails = response;
                    this.currentCart = response;
                    this.cartEmpty = (this.currentCart && this.currentCart.positions && this.currentCart.positions.length === 0);
                    this.calcSelectedOrderPrices();
                }
            )
        }
    }

    public calcSelectedOrderPrices() {
        this.selectedOrderSum = 0;
        this.selectedOrderCount = 0;

        if (this.orderDetails) {
            for (let i = 0; i < this.orderDetails.positions.length; i++) {
                this.selectedOrderCount += 1;
                this.selectedOrderSum += this.orderDetails.positions[i].sellInNetTotal;
            }
        }
    }


    // Positions

    public deletePos(posId: number) {
        this.cartRestService.deletePos(posId, 'DELETE_WITH_DEPENDENT').subscribe(
            (success) => {
                this.updateCart(true, false, false, false);
                this.onItemRemove.emit(null);
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_CART_POS', 'success');
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_CART_POS', 'error');
            }
        );
    }

    public replacePos(replacement: any, cartPosition: any) {
        this.cartRestService.deletePos(cartPosition.posId, 'DELETE_WITH_DEPENDENT').subscribe(
            (success) => {
                this.cartRestService.addSparepartToCart(replacement).subscribe(
                    () => {
                        this.updateCart(false, false, false, false);
                        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_REPLACE_ARTICLE', 'success');
                    },
                    (error) => {
                        this.updateCart(false, false, false, false);
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_REPLACE_ARTICLE', 'error');
                    }
                )
            },
            (error) => {
                this.updateCart(false, false, false, false);
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_REPLACE_ARTICLE', 'error');
            }
        );
    }

    public changePos(acquisitionType: string, posId: number, amount: number, newPrice?: number, description?: string, ipc?: string, supplier?: string, dealerArticleNumber?: string, dealerBrandIdentifier?: string, additionalName?: string, quantityUnit?: string) {
        this.cartRestService.changePos(acquisitionType, posId, amount, newPrice, description, ipc, supplier, dealerArticleNumber, dealerBrandIdentifier, additionalName, quantityUnit).subscribe(
            (response) => {
                this.updateCart(false, false, false, false);
            },
            (error) => {
            }
        );
    }

    // Misc

    public reset() {
        this.showCustomArticle = false;
    }

    public switchToCart() {
        if (this.isOrder()) {
            this.resetTabsEmitter.emit();
            this.selectCart(this.cartCount.previousCartId, true, false)
        }
    }


    private showNotOrderableBanner() {
        if (this.currentCart) {
            this.showNotOrderableBannerBool = false;
            for (const position of this.currentCart.positions) {
                if (!position.orderable && position.supplier && position.quantity > position.preOrderedQuantity) {
                    this.showNotOrderableBannerBool = true;
                    return;
                }
            }
        }
    }

    private toggleCompositeWorkTime() {
        this.cartRestService.toggleCompositeWorkTime().subscribe(
            (response) => {
                this.compositeWorkTime = response.useCompositeWorkTimes;
            }
        );
    }

    private cartHandler(cart: ICart, noEmit: boolean, list: boolean, fromIntern: boolean) {
        this.cartLoading = false;
        if (cart.id) {
            this.currentCart = cart;
            if (fromIntern) {
                this.holdCart = false;
                if (this.currentCart.cars && this.currentCart.cars.length === 1) {
                    this.carService.selectCar(this.currentCart.cars[0].car);
                }
            }

            if (!fromIntern) {
                this.carService.showDMSLoader = false;
                if (this.currentCart.cars && this.currentCart.cars.length === 1) {

                    if (!this.carService.selectedCar) {
                        this.carService.selectCar(this.currentCart.cars[0].car);
                        this.dmsCartEmitter.emit(this.currentCart.cars[0].car);
                    }

                    if (this.carService.selectedCar && this.carService.selectedCar.adcTypeId !== this.currentCart.cars[0].car.adcTypeId
                        || this.carService.selectedCar.kTypeId !== this.currentCart.cars[0].car.kTypeId) {
                        if (this.userService.getCarChangeSettings() === 'ASK' && !this.holdCart) {
                            this.showCartMessage = true;
                        } else if (this.userService.getCarChangeSettings() === 'PER_CAR') {
                            this.carService.selectCar(this.currentCart.cars[0].car);
                        }
                    }
                }
            }

            if (this.currentCart.cars && this.currentCart.cars.length === 0 && this.carService.selectedCar
                && this.currentCart.count > 0 && this.userService.getCarChangeSettings() === 'PER_CAR') {
                this.carService.selectCar(null);
            }

            this.cardInitialized.emit(this.currentCart);
            this.cartEmpty = (this.currentCart && this.currentCart.positions && this.currentCart.positions.length === 0);
            this.updateCartPosCount();
            this.lastSelectedCart = cart.id;
        } else if (!this.isOrder) {
            this.cartEmpty = true;
        }
        this.updateCartCount(noEmit);
        if (!list) {
            this.updateCartList();
        }
    }


    async buildPdfForRawTool(details: IRawTool[]) {
        let me = this;
        let docDefinition = {
            content: [],
            header: function (page: any, pages: any) {
                return [
                    {
                        text: me.translate.instant('PRINT_VIEW.PAGE') + ': ' + page.toString() + ' / ' + pages,
                        fontSize: 7,
                        alignment: 'right',
                        margin: [40, 20]
                    }
                ]
            },
            pageMargins: [57, 40, 40, 120],
        };

        let car = this.carService.selectedCarWithRawTools;
        if (car) {
            if (!car.constructionTo || car.constructionTo.trim().length === 0) {
                car.constructionTo = me.translate.instant('SPAREPARTS_VIEW.CAR_DETAILS.TODAY');
            }
            if (this.userService.getFirmLogo() && this.userService.getFirmLogo() !== '') {
                const address_data = [
                    {
                        image: this.userService.getFirmLogo(),
                        width: 150,
                        margin: [370, 0, 0, 10]
                    },
                    {
                        text: me.translate.instant('PRINT_VIEW.CAR') + ' ',
                        margin: [0, 1, 0, 0],
                        fontSize: 12
                    },
                    {
                        text: car?.displayName,
                        margin: [0, 10, 0, 10],
                        fontSize: 14
                    }
                ];


                docDefinition.content.push(address_data);
            } else {
                const address_data = [
                    {
                        text: me.translate.instant('PRINT_VIEW.CAR') + ' ',
                        margin: [0, 1, 0, 0],
                        fontSize: 12
                    },
                    {
                        text: car?.displayName,
                        margin: [0, 10, 0, 10],
                        fontSize: 14
                    }
                ];
                docDefinition.content.push(address_data);
            }
        }

        let bodyElements: any[] = [];

        for (let i = 0; i < details.length; i++) {
            docDefinition.content.push(
                {
                    text: details[i]?.itemMpText + ' ' + details[i]?.korText + ' ' + details[i]?.qualColText,
                    margin: [0, 10, 0, 10],
                    fontSize: 12
                }
            );

            bodyElements = [];
            for (let o = 0; o < details[i].rawTools.length; o++) {
                bodyElements.push([
                    {text: details[i]?.rawTools[o]?.rawToolName, alignment: 'left'},
                    {text: details[i]?.rawTools[o]?.rawToolNumber, alignment: 'left'},
                ]);
            }
            if (bodyElements) {
                docDefinition.content.push(
                    {
                        style: 'cartTable',
                        table: {
                            widths: ['*', '*'],
                            body: bodyElements
                        }
                    }
                );
            }
        }

        return new Promise((resolve) => {
            resolve(docDefinition)
        })
    }
}
