<div class="global-dashboard">
    <div class="heading blue">
        <span class="clickable" routerLink="/global-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <span class="clickable" routerLink="/global-admin/statistics">
          <i class="fa fa-caret-right"></i>
            {{'GLOBAL_ADMIN.STATISTICS' | translate}}
        </span>
        <span *ngIf="club">
          <i class="fa fa-caret-right"></i>
           {{club?.name}}
        </span>
    </div>
    <div class="panel-content center-content white-background">
        <app-default-loading-page *ngIf="adminService.clubStatsLoading"></app-default-loading-page>

        <h1><strong>{{club?.name}}</strong></h1>
        <div class="row" *ngIf="adminService.clubStats?.firmCount > -1">
            <div class="col-3">{{'GLOBAL_ADMIN.STATISTIC.FIRM_ALL' | translate }}</div>
            <div class="col-3 strong">{{adminService.clubStats?.firmCount}}</div>
        </div>
        <div class="row" *ngIf="adminService.clubStats?.activeFirmCount > -1">
            <div class="col-3">{{'GLOBAL_ADMIN.STATISTIC.FIRM_ACTIVE' | translate}}</div>
            <div class="col-3 strong">{{adminService.clubStats?.activeFirmCount}}</div>
        </div>
        <div class="row" *ngIf="activatedStatistc?.count > -1">
            <div class="col-3">{{'GLOBAL_ADMIN.STATISTIC.FIRM_FREE_MONTH' | translate}}</div>
            <div class="col-3 strong">{{activatedStatistc?.count}}</div>
        </div>
        <div class="row" *ngIf="adminService.clubStats?.firmWithActualCartOrOrderCount > -1">
            <div class="col-3">{{ 'GLOBAL_ADMIN.STATISTIC.FIRM_WITHORDER' | translate}}</div>
            <div class="col-3 strong">{{adminService.clubStats?.firmWithActualCartOrOrderCount}}</div>
        </div>
        <div class="row" *ngIf="adminService.clubStats?.actualOrderCount > -1">
            <div class="col-3">{{'GLOBAL_ADMIN.STATISTIC.ORDER_ALL' | translate}}</div>
            <div class="col-3 strong">{{adminService.clubStats?.actualOrderCount}}</div>
        </div>
        <ng-container *ngIf="globalAdminService.vinStatistics">
            <hr />
            <div class="row">
                <div class="col-3">{{'GLOBAL_ADMIN.STATISTIC.VIN_STATISTIC_ALL' | translate}}</div>
                <div class="col-3 strong" *ngIf="!globalAdminService.loadStatistics">{{globalAdminService.vinCount}}</div>
                <div class="col-3 strong" *ngIf="globalAdminService.loadStatistics"><i class="fa fa-spinner"
                 [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"></i></div>
                <div class="col-6">
                    <input class="dateInput" [max]="newDate" [placeholder]="'GLOBAL_ADMIN.STATISTIC.THIS_MONTH' | translate" [owlDateTime]="dt1" [selectMode]="'range'" [owlDateTimeTrigger]="dt1">
                    <i class="fa fa-calendar" [owlDateTimeTrigger]="dt1"></i>
                    <owl-date-time #dt1 pickerType="calendar" startView="year" (afterPickerClosed)="getVinRequestsForDate(dt1)"></owl-date-time>
                </div>
            </div>
            <ng-container *ngIf="!globalAdminService.loadStatistics">
                <div class="row" *ngFor="let firm of globalAdminService.vinStatistics.responses">
                    <div class="col-3">{{firm.firmName}}</div>
                    <div class="col-3">{{getFullCount(firm)}}</div>
                </div>
            </ng-container>
        </ng-container>


        <div class="row">
            <div class="col-12">
                <hr/>
                <h1>{{'GLOBAL_ADMIN.STATISTIC.DOWNLOADS' | translate}}</h1>
            </div>
        </div>

        <div class="row" >
            <div class="col-3">{{'GLOBAL_ADMIN.STATISTIC.DETAILS_SHORT' | translate}}</div>
            <div class="col-3"><a href="{{adminService.getClubStatisticDownloadLinl(club?.clubId, 'short')}}" target="_blank"><i class="fa fa-download"></i> {{'GLOBAL_ADMIN.STATISTIC.DOWNLOAD' | translate}}</a></div>
        </div>
        <div class="row" >
            <div class="col-3">{{'GLOBAL_ADMIN.STATISTIC.DETAILS_FULL' | translate }}</div>
            <div class="col-3"><a href="{{adminService.getClubStatisticDownloadLinl(club?.clubId, 'full')}}" target="_blank"><i class="fa fa-download"></i> {{'GLOBAL_ADMIN.STATISTIC.DOWNLOAD' | translate}}</a></div>
        </div>
        <div class="row" *ngIf="globalAdminService.vinStatistics && !globalAdminService.loadStatistics">
            <div class="col-3">{{'GLOBAL_ADMIN.STATISTIC.VIN_STATISTIC' | translate}}</div>
            <div class="col-3"><a href="{{autovistaRestService.getAutovistaVinStatisticCSVRest(getVinCSVStatistc())}}" target="_blank"><i class="fa fa-download"></i> {{'GLOBAL_ADMIN.STATISTIC.DOWNLOAD' | translate}}</a></div>
        </div>

    </div>
</div>
