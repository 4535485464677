import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CartService} from '../services/cart.service';
import {Router} from '@angular/router';
import {HelperService} from '../../shared/services/helper.service';
import {Subscription} from 'rxjs';
import {ArticlesRestService} from '../../articles/services/articles.rest.service';
import {CarService} from '../../shared/services/car/car.service';
import {TranslateService} from '@ngx-translate/core';
import {IRequiredItem} from '../../shared/interfaces/DTO/cart/cart';
import {IPosition} from '../../shared/interfaces/DTO/cart/position';
import {UserService} from '../../shared/services/user/user.service';
import {CartRestService} from '../services/cart.rest.service';

@Component({
  selector: 'app-article-selection',
  templateUrl: './article-selection.component.html',
  styleUrls: ['./article-selection.component.scss']
})
export class ArticleSelectionComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('viewport', {static: true})
  public viewPort: ElementRef;

  @ViewChild('tabList', {static: true})
  public tabList: ElementRef;

  public showScrollButtonBool: boolean = false;
  public selectedAmount: number;
  public availability: any;
  public interval: any;
  public offset: number = 0;
  public selectedTab: IRequiredItem;
  public byWorkIdTimer: number;
  public placeholderLoading: boolean = false;
  private cartChangeSubscription: Subscription;

  constructor(public cartService: CartService,
              private router: Router,
              public helperService: HelperService,
              private changeDetectorRef: ChangeDetectorRef,
              private articleRestService: ArticlesRestService,
              public userService: UserService,
              private carService: CarService,
              private translate: TranslateService,
              private cartRestService: CartRestService) {}

  ngOnInit() {
    if (!this.cartService.currentCart || this.cartService.currentCart.requiredItems.length === 0) {
      this.router.navigate(['/cart']);
    }

    this.cartChangeSubscription = this.cartService.onCartChange.subscribe(() => {
      setTimeout(() => {
        this.placeholderLoading = false;
        if (this.tabList) {
          this.goToNextTab();
        }
      }, 50);
    });
  }

  ngAfterViewInit() {
    this.goToNextTab();

    if (this.tabList.nativeElement.lastChild.getBoundingClientRect === 'function') {
      if (!this.isVisible(this.tabList.nativeElement.lastChild)) {
        this.offset += 10;
        this.scroll(false);
      }
    }
  }

  ngOnDestroy() {
    this.changeDetectorRef.detach();
    this.cartChangeSubscription.unsubscribe();
  }

  public addPlaceholderToCart() {
    if (!this.getSelectedPosition()) {
      this.addPlaceholderArticleToCart(this.selectedTab)
    } else {
      this.cartRestService.deletePos(this.getSelectedPosition().posId, 'DELETE_WITH_DEPENDENT').subscribe(
          (response) => {
            this.addPlaceholderArticleToCart(this.selectedTab, true);
          },
          (error) => {
            this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_CART_POS', 'error');
          }
      );
    }
  }

  public goBackToSearch() {
    this.cartService.selectedPseudoArticle = undefined;
    this.cartService.showPseudoArticle = false;
    this.cartService.hidePartsListPseudo = false;
    this.searchArticle(this.selectedTab.genericArticleNumber, this.selectedTab.associatedCar, this.selectedTab.quantity);
  }

  public addPlaceholderArticleToCart(article: any, replace: boolean = false) {

      this.placeholderLoading = true;
      const placeholder = {
        description: article.genericArticleName,
        quantity: article.quantity,
        quantityUnit: article.quantityUnit ? article.quantityUnit : 'PIECE',
        kTypeId: article.associatedCar.kTypeId ? article.associatedCar.kTypeId : this.carService.getKTypeId(),
        adcTypeId: article.associatedCar.adcTypeId ? article.associatedCar.adcTypeId : this.carService.getAdcTypeId(),
        itemMpId: 0,
        workId: 0,
        requestQuantity: this.selectedTab.quantity ? this.selectedTab.quantity : 1,
        sellOutNetPerPiece: 0,
        quantityDescription: '',
        genericArticleNumber: article.genericArticleNumber,
        articleNo: '',
        vin: article.associatedCar.vin ? article.associatedCar.vin : this.carService.getVin(),
        producerName: '',
        allowCalculatedPriceChange: true,
        uniqueCalculationId: -1
      };

      let quantityType: any = '';

      if (article.quantityUnit.length > 0) {
        this.translate.get('CART.' + article.quantityUnit).subscribe(
            (translation: string) => {
          quantityType = translation;
        });

        placeholder.sellOutNetPerPiece = placeholder.sellOutNetPerPiece * placeholder.requestQuantity;

        placeholder.description = placeholder.requestQuantity + ' ' + quantityType + ' ' + placeholder.description;
      } else {
        placeholder.quantity = placeholder.requestQuantity;
      }
      placeholder.vin = article.associatedCar.vin ? article.associatedCar.vin : this.carService.getVin();
      placeholder.producerName = '';

      this.cartRestService.addPlaceholder(placeholder).subscribe(
          (cartPositionId: number) => {
            this.cartService.updateCart(false, false, false, false);
            this.placeholderLoading = false;
            if (!replace) {
              this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_ADD_TO_CART', 'success');
            } else {
              this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_REPLACE_ARTICLE', 'success');
            }
          },
          (error) => {
            this.placeholderLoading = false;
            if (!replace) {
              this.helperService.showNotification('TOAST_MESSAGES.ERROR_ADD_TO_CART', 'error');
            } else {
              this.helperService.showNotification('TOAST_MESSAGES.ERROR_REPLACE_ARTICLE', 'error');
            }
          });
  }

  public getArticleByPosId(posId: number) {
    if (this.cartService.currentCart && this.cartService.currentCart.positions) {
      for (const position of this.cartService.currentCart.positions) {
        if (position.posId === posId) {
          return position;
        }
      }
    }

    return undefined;
  }

  public scroll(scrollRight: boolean) {
    if (scrollRight) {
      this.tabList.nativeElement.style.transform = `translate(${-this.offset}px)`;
    } else {
      this.tabList.nativeElement.style.transform = `translate(${-this.offset}px)`;
    }
  }

  public isVisible(element: any) {
    const boundingElement = element.getBoundingClientRect();
    const boundingViewport = this.viewPort.nativeElement.getBoundingClientRect();
    return (boundingElement.right) <= (boundingViewport.right)
        && boundingElement.left >= (boundingViewport.left);
  }

  public moveToView(element: any) {
    if (!this.isVisible(element)) {
      const boundingElement = element.getBoundingClientRect();
      const boundingViewport = this.viewPort.nativeElement.getBoundingClientRect();

      if (boundingElement.right >= boundingViewport.right) {
        this.offset -= ((boundingViewport.right) - boundingElement.right);
        this.scroll(true);
      }

      if (boundingElement.left <= (boundingViewport.left)) {
        this.offset -= ((boundingViewport.left) - boundingElement.left);
        this.scroll(false);
      }
    }

  }

  public searchArticle(genericArticleNumber: number, car?: any, requestQuantity?: any) {

    if (this.cartService.searchResult) {
      this.cartService.searchResult.activeFilters = [];
    }

    const aktTime = new Date().getTime();
    if (!this.byWorkIdTimer || aktTime - this.byWorkIdTimer > 500) {
      this.byWorkIdTimer = aktTime;
      this.cartService.updateTreeBySelectedGenArt([genericArticleNumber], 1, undefined, false, car);
    }
    this.cartService.resultItems = [];
  }

  public getSelectedPosition(): IPosition {
    if (this.selectedTab && this.selectedTab.referencedPosId > -1) {
      for (const pos of this.cartService.currentCart.positions) {
        if (pos.posId === this.selectedTab.referencedPosId) {
          return pos;
        }
      }
    } else {
      return undefined;
    }
  }

  public showPseudoArticleDetails(pseudoArtikel: any) {
    this.userService.navigationHelper = 'right';
    if (!this.cartService.selectedArticle || this.cartService.selectedArticle.id !== pseudoArtikel.id) {
      this.cartService.expanded_right = !this.cartService.expanded_right;
      let dropCar = false;
      if ((pseudoArtikel.kTypeId === 0 || pseudoArtikel.adcTypeId === 0) && this.carService.selectedCar) {
        pseudoArtikel.kTypeId = this.carService.selectedCar.kTypeId;
        pseudoArtikel.adcTypeId = this.carService.selectedCar.adcTypeId;
        dropCar = true;
      }
      this.cartService.updateArticleDetails(pseudoArtikel.id, pseudoArtikel.kTypeId, pseudoArtikel.adcTypeId, pseudoArtikel, dropCar);
    }
  }

  public setTab(tab: any, tabElement, requestQuantity?: any) {

    this.selectedAmount = tab.amount;

    this.cartService.requestQuantity = requestQuantity;
    this.selectedTab = tab;
    this.moveToView(tabElement);
    this.searchArticle(tab.genericArticleNumber, tab.associatedCar, requestQuantity);
    this.availability = undefined;

    if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }

  public getNextTab(right: boolean) {
    for (let i = 0; i < this.cartService.currentCart.requiredItems.length; i++) {
      if (this.cartService.currentCart.requiredItems[i].itemId === this.selectedTab.itemId) {
        if (right && (i + 1) < this.cartService.currentCart.requiredItems.length) {
          this.setTab(this.cartService.currentCart.requiredItems[i + 1],
              this.tabList.nativeElement.childNodes[i + 2], this.cartService.currentCart.requiredItems[i + 1].quantity);
          return;
        }

        if (!right && i - 1 >= 0) {
          this.setTab(this.cartService.currentCart.requiredItems[i - 1],
              this.tabList.nativeElement.childNodes[i], this.cartService.currentCart.requiredItems[i + 1].quantity);
          return;
        }
      }
    }
  }

  public showScrollButton() {
    const temp = this.tabList.nativeElement.getBoundingClientRect().width >= this.viewPort.nativeElement.getBoundingClientRect().width;
    if (temp !== this.showScrollButtonBool) {
      this.showScrollButtonBool = temp;
      if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
        this.changeDetectorRef.detectChanges();
      }
    }

    return this.showScrollButtonBool;
  }

  public goToNextTab() {
    if (this.cartService && this.cartService.currentCart && this.cartService.currentCart.requiredItems) {
      for (let i = 0; i < this.cartService.currentCart.requiredItems.length; i++) {
        if (this.cartService.currentCart.requiredItems[i].referencedPosId === -1) {
          this.cartService.showPseudoArticle =  false;
          this.cartService.selectedPseudoArticle = undefined;
          this.setTab(this.cartService.currentCart.requiredItems[i], this.tabList.nativeElement.childNodes[i + 1],
              this.cartService.currentCart.requiredItems[i].quantity);
          return;
        }
      }

      this.setTab(this.cartService.currentCart.requiredItems[0],
          this.tabList.nativeElement.childNodes[1], this.cartService.currentCart.requiredItems[0].quantity);
      return;
    }
  }
}
