<div class="work-details" *ngIf="service.selectedItem && service.selectedItem.itemMpText" [@detailAnim]="service.selectedItem">
    <div class="work_info">
        <h1><strong>{{service.selectedItem.itemMpText}}</strong> {{service.selectedItem.korText}}</h1>
        <div>
            <span class="itemQualifiertText">{{service.selectedItem.qualColText}}</span>
            <span class="float-right">{{service.selectedItem.quantity}} {{'GENERAL.VALUES.' + service.selectedItem.quantityUnit | translate}}</span>
        </div>

        <ng-container *ngIf="service?.selectedItem.optionalExclusivePositions?.mdOptExclWorkPos?.length > 0">
            <div class="work_set_option">{{'WORKING_VALUES.CONTAINS' | translate}}</div>
            <div class="row" *ngFor="let pos of service?.selectedItem.optionalExclusivePositions?.mdOptExclWorkPos">
                <div class="col">
                    <span>{{pos.mountPositionText}} {{pos.repairText}}</span>
                </div>
                <div class="col-4">
                    <span class="float-right">{{pos.quantity}} {{'GENERAL.VALUES.' + pos.quantityUnit | translate}}</span>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="service?.selectedItem?.workPositions?.length > 0">
            <div class="work_set_option">{{'WORKING_VALUES.CONTAINS' | translate}}</div>
            <div class="row" *ngFor="let pos of service.selectedItem.workPositions">
                <div class="col">
                    <span>{{pos.mountPositionText}} {{pos.repairText}}</span>
                </div>
                <div class="col-4">
                    <span class="float-right">{{pos.quantity}} {{'GENERAL.VALUES.' + pos.quantityUnit | translate}}</span>
                </div>
            </div>
        </ng-container>

        <app-collapsible-panel *ngIf="service?.selectedItem?.documents?.length > 0" [panelTitle]="'SPAREPARTS_VIEW.DOCUMENTS' | translate">
            <div class="row document" [ngClass]="{'active': document === service?.showDocumentWithLink}" *ngFor="let document of service?.selectedItem?.documents" (click)="service.showDocumentWithLink = document">
                <div class="col-1">
                   <i class="fa fa-file-pdf-o"></i>
                </div>
                <div class="col-10">
                    <span>{{document.text}}</span>
                </div>
            </div>
        </app-collapsible-panel>
    </div>
</div>


<div class="work-details" *ngIf="service.selectedItem && service.selectedItem.mountPositionText" [@detailAnim]="service.selectedItem">
    <div class="work_info">
        <h1><strong>{{service.selectedItem.mountPositionText}}</strong> {{service.selectedItem.repairText}}</h1>
        <span class="itemQualifiertText">{{service.selectedItem.itemQualifierText}}</span>
        <span class="float-right">{{service.selectedItem.quantity}} {{'GENERAL.VALUES.' + service.selectedItem.quantityUnit | translate}}</span>

        <ng-container *ngIf="service?.selectedItem?.workPositions?.length > 0">
            <div class="work_set_option">{{'WORKING_VALUES.CONTAINS' | translate}}</div>
            <div class="row" *ngFor="let pos of service.selectedItem.workPositions">
                <div class="col">
                    <span>{{pos.mountPositionText}} {{pos.repairText}}</span>
                </div>
                <div class="col-4">
                    <span class="float-right">{{pos.quantity}} {{'GENERAL.VALUES.' + pos.quantityUnit | translate}}</span>
                </div>
            </div>
        </ng-container>

        <app-collapsible-panel *ngIf="service?.selectedItem?.documents?.length > 0" [panelTitle]="'SPAREPARTS_VIEW.DOCUMENTS' | translate">
            <div class="document" [ngClass]="{'active': document === service.showDocumentWithLink}" *ngFor="let document of service?.selectedItem?.documents" (click)="service.showDocumentWithLink = document">
                <i class="fa fa-file-pdf-o"></i>
                <span>{{document.text}}</span>
            </div>
        </app-collapsible-panel>
    </div>
</div>
