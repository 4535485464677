<div class="cart-amount-input">
    <div class="amount-controls" *ngIf="!floatValue && !deactivate" style="float: left;">
        <i (click)="decreaseAmount()" class="fa fa-minus-square-o"></i>
    </div>
    <div class="value-input">
        <i *ngIf="icon" class="fa {{ icon }}"></i>
        <input *ngIf="floatValue" type="text" #floatInput step="0.05" [ngModel]="amount.toFixed(2)" [disabled]="deactivate"
               (keyup.enter)="amount = floatInput.value; onAmountChange()" (change)="amount = floatInput.value; onMouseClickChange()">
        <span class="currency" *ngIf="showCurrency">{{getSymbol()}}</span>
        <input *ngIf="!floatValue" type="text" #textInput [ngModel]="amount" [disabled]="deactivate"
               (change)="amount = textInput.value; onMouseClickChange()"
               (keyup.enter)="amount = textInput.value; onAmountChange()">
    </div>
    <div class="amount-controls" *ngIf="!floatValue && !deactivate">
        <i (click)="increaseAmount()" class="fa fa-plus-square-o"></i>
    </div>
</div>


