<div class="login-wrapper">

  <div class="login">
    <h1><strong> {{ 'LOGIN.RESET_PASSWORD' | translate }}</strong></h1>

    <ngb-alert type="success" [dismissible]="false" *ngIf="success">{{ 'LOGIN.RESET_PASSWORD_MSG' | translate }}</ngb-alert>


    <div>
      <app-material-form-input
              [label]="'LOGIN.USERNAME' | translate"
              [disabled]="false"
              [type]="'text'"
              [(text)]="username"
              (keydown.enter)="resetLogin()">
      </app-material-form-input>
    </div>

    <div>
      <button class="reset-btn"  (click)="resetLogin()">
        {{ 'LOGIN.RESET_PASSWORD' | translate }}
      </button>
    </div>
    <hr>
    <a href="#/login" >
     <i class="fa fa-arrow-left"></i> {{ 'LOGIN.TITLE' | translate }}
    </a>
  </div>

</div>

<app-auth-footer></app-auth-footer>
