import {Component, OnInit, Input, ElementRef, HostListener} from '@angular/core';
import {CarService} from "../../../../shared/services/car/car.service";
import {UserService} from "../../../../shared/services/user/user.service";
import {CustomerDataService} from '../../../../shared/services/customerData.service';
import {ConfigService} from '../../../../shared/services/config.service';
import {GlobalAdminService} from '../../../../admin/global/services/global-admin.service';

@Component({
    selector: 'app-car-info',
    templateUrl: './car-info.component.html',
    styleUrls: ['./car-info.component.scss'],
})
export class CarInfoComponent {

    constructor(
        public carService: CarService,
        public userService: UserService,
        public configService: ConfigService,
        public customerDataService: CustomerDataService,
        public globalAdminService: GlobalAdminService
    ) {
    }

}
