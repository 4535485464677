import { Component, OnInit } from '@angular/core';
import {CartService} from '../../services/cart.service';
import {HelperService} from '../../../shared/services/helper.service';
import {IPosition} from '../../../shared/interfaces/DTO/cart/position';

@Component({
  selector: 'app-profit-check',
  templateUrl: './profit-check.component.html',
  styleUrls: ['./profit-check.component.scss']
})
export class ProfitCheckComponent implements OnInit {

  public articleList: IPosition[];

  constructor(public cartService: CartService,
              public helperService: HelperService) { }

  ngOnInit() {
    this.articleList = [];
    this.getArticles();
  }

  public getArticles() {
    if (this.cartService && this.cartService.currentCart && this.cartService.currentCart.positions) {
      for (const position of this.cartService.currentCart.positions) {
        if (position.type === 'SPAREPART' && position.sellInNetTotal >= position.sellOutNetTotal) {
          this.articleList.push(position);
        }
      }
    }
  }

  public onPriceChange(newPrice: number, position:any) {
    if (newPrice >= 0 && newPrice !== position.sellOutNetPerPiece) {
      position.sellOutNetPerPiece = newPrice;
      this.cartService.changePos(position.acquisitionType, position.posId, position.quantity, newPrice);
    }
  }
}
