import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ICar, ICarNumberplate} from '../../../interfaces/DTO/car/car';
import {CarService} from '../../../services/car/car.service';
import {CarRestService} from '../../../services/car/car.rest.service';
import {HelperService} from '../../../services/helper.service';
import {CustomerDataService} from '../../../services/customerData.service';
import {UserService} from '../../../services/user/user.service';

@Component({
    selector: 'app-car-by-license-uk',
    templateUrl: './car-by-license-uk.component.html',
    styleUrls: ['./car-by-license-uk.component.scss']
})
export class CarByLicenseUkComponent implements OnChanges {

    @Output() applyCars: EventEmitter<ICar[]> = new EventEmitter();
    @Input() numberplate: string;

    constructor(public carService: CarService,
                private carRestService: CarRestService,
                public helperService: HelperService,
                private customerDataService: CustomerDataService,
                public userService: UserService) {
    }


    ngOnChanges(): void {
        this.carService.selected = 'UK';
    }

    public onLicensePlateSearch() {
        this.carService.loadCar = 'UK';
        this.customerDataService.setNumberplate(this.numberplate);
        this.carRestService.getCarsByNumberplate(this.numberplate).subscribe(
            (response: (ICar[] | ICarNumberplate)) => {
                if (this.carService.isCarArray(response)) {
                    if (response.length > 0) {
                        if (!this.userService.getCatalogMode()) {
                            this.carService.fromDMS = false;
                        }
                        this.applyCars.emit(response);
                    }
                } else if (this.carService.isCarNumberplate(response)) {
                    if(!response.foundCars && !response.vinsWithoutCarInfos) {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_CANT_FIND_CAR_NUMBERPLATE', 'error');
                    }
                    if(response.foundCars.length > 0) {
                        if (!this.userService.getCatalogMode()) {
                            this.carService.fromDMS = false;
                        }
                        this.applyCars.emit(response.foundCars);
                    }
                    if (response.vinsWithoutCarInfos.length === 1) {
                        this.carService.changeVin$.emit(response.vinsWithoutCarInfos[0]);
                        this.helperService.showNotification('TOAST_MESSAGES.INFO_CANT_FIND_CAR_NUMBERPLATE_BUT_VIN', 'info');
                    }
                    if (response.vinsWithoutCarInfos.length > 1) {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_CANT_FIND_CAR_NUMBERPLATE', 'error');
                    }
                } else {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_CANT_FIND_CAR_NUMBERPLATE', 'error');
                }
                this.carService.loadCar = undefined;
            },
            (error) => {
                if (error.status === 409) {
                    this.carService.handleCarConflict();
                } else {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                }
                this.carService.loadCar = undefined;
            }
        );
    }

}
