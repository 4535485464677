import {AfterContentInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';

@Directive({
    selector: '[appInfiniteScroller]'
})
export class InfiniteScrollerDirective implements AfterContentInit, OnDestroy {

    public scrollEvent$;
    private Subscriptions: Subscription[] = [];

    @Input() private scrollLength: number = 300;
    @Output() private onScrollEnd: EventEmitter<any> = new EventEmitter();

    constructor(public elm: ElementRef) {
        this.scrollEvent$ = fromEvent(this.elm.nativeElement, 'scroll');
    }

    ngAfterContentInit() {
        this.registerScrollEvent();
    }

    ngOnDestroy() {
        this.Subscriptions.forEach(sub => {
            sub.unsubscribe();
        })
    }

    private registerScrollEvent() {
        this.Subscriptions.push(this.scrollEvent$.subscribe(
            (step) => {
                if ((step.target.scrollHeight - (step.target.clientHeight + step.target.scrollTop)) < this.scrollLength) {
                    this.onScrollEnd.emit(step.target.scrollTop);
                }
            }));
    }
}
