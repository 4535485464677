import {Directive, Input, EventEmitter, Inject, ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
    selector: '[onFocus]'
})
export class FocusDirective implements OnInit {
    @Input('onFocus') focusEvent: EventEmitter<boolean>;

    constructor(
        @Inject(ElementRef) private element: ElementRef,
        private renderer: Renderer2
    ) {
    }

    ngOnInit() {
        this.focusEvent.subscribe(event => {
            this.renderer.setProperty(this.element.nativeElement, 'focus', []);
        });
    }
}
