<div class="heading blue">
    <span class="clickable" routerLink="/company-admin">
        <i class="fa fa-th" aria-hidden="true"></i>
        {{'ADMIN_PANEL.OVERVIEW' | translate}}
    </span>
    <span class="clickable" routerLink="/company-admin/settings">
        <i class="fa fa-caret-right"></i>
        {{'USER_PROFILE.SETTINGS' | translate}}
    </span>
    <span>
        <i class="fa fa-caret-right"></i>
        {{'USER_PANEL.HOURLY_RATE' | translate}}
    </span>
</div>

<div class="panel-content center-content white-background">

    <div class="firmdataCSS" *ngIf="firmData">
        <div class="row">
            <!-- Linke Tabelle -->
            <div class="col-12">
                <div class="row">
                    <div class="col headerType">
                        <h1>{{'USER_PANEL.HOURLY_RATE' | translate}}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-material-form-input [type]="'number'" [step]="0.01" [label]="'USER_PANEL.HOURLY_RATE' | translate"
                                                 [(text)]="firmData.hourlyWageRate"></app-material-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-material-form-input [type]="'number'" [step]="0.01" [label]="'USER_PANEL.HOURLY_RATE_CHASSIS' | translate"
                                                 [(text)]="firmData.hourlyWageRateChassis"></app-material-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-material-form-input [type]="'number'"
                                                 [step]="0.01"
                                                 [label]="'USER_PANEL.HOURLY_RATE_ELECTRONIC' | translate"
                                                 [(text)]="firmData.hourlyWageRateElectronic"></app-material-form-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <app-material-form-input [type]="'number'" [step]="0.01" [label]="'USER_PANEL.HOURLY_RATE_MECHANIC' | translate"
                                                 [(text)]="firmData.hourlyWageRateMechanic"></app-material-form-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col button">
                <button class="base-btn success" (click)="saveFirm()">
                    <i class="fa fa-check"></i>
                    <span>{{ 'PROMPTS.BUTTONS.SAVE_CHANGES' | translate }}</span>
                </button>
            </div>


        </div>
    </div>
    <app-error-handler *ngIf="errorService.newError"></app-error-handler>
</div>
