import {Component, OnInit} from '@angular/core';
import {ErrorHandlerService} from '../../../../shared/components/error-handler/services/error-handler.service';
import {CompanyAdminService} from '../../services/company-admin.service';
import {UserService} from '../../../../shared/services/user/user.service';
import {Location} from '@angular/common';
import {AdminService} from '../../../club/services/admin.service';
import {HelperService} from '../../../../shared/services/helper.service';

@Component({
    selector: 'app-advanced-settings',
    templateUrl: './advanced-settings.component.html',
    styleUrls: ['./advanced-settings.component.scss']
})
export class AdvancedSettingsComponent implements OnInit {

    public firmSettings: any;
    private firmId: number;
    private fromExtern: boolean = false;

    constructor(public errorService: ErrorHandlerService,
                public companyAdminService: CompanyAdminService,
                private helperService: HelperService,
                public userService: UserService,
                public location: Location,
                private adminService: AdminService) {
    }

    ngOnInit() {

        this.errorService.hasError = false;

        if (this.location.path().endsWith('advanced')) {
            this.companyAdminService.getFirmSettings().subscribe((firmSettings) => {
                this.firmSettings = firmSettings;
            });
            this.fromExtern = false;
        } else {
            this.firmId = parseInt(this.location.path().substring(this.location.path().lastIndexOf('/') + 1));
            this.adminService.getFirmSettings(this.firmId).subscribe((firmSettings) => {
                this.firmSettings = firmSettings;
            });
            this.fromExtern = true;
        }
    }

    public getExportType() {
        return this.firmSettings['exportType'];
    }

    public getPreExportType(value) {
        return this.getExportType().value === value;
    }

    public setExportType($event) {
        this.getExportType().value = $event;
        this.companyAdminService.hasChanges = true;
    }

    public getCartCreationBehavior() {
        return this.firmSettings['cartCreationBehaviour'];
    }

    public getPreOrderingBehavior() {
        return this.firmSettings['catalogOrderMode'];
    }

    public getAutovistaAllowChargeable() {
        return this.firmSettings['autovistaVinSearchAllowChargeable'];
    }

    public setAutovistaAllowChargeable(value: boolean) {
        if (this.firmSettings['autovistaVinSearchAllowChargeable']) {
            this.firmSettings['autovistaVinSearchAllowChargeable'].value = value;
            this.companyAdminService.hasChanges = true;
        }
    }

    public getLabourUnitType() {
        return this.firmSettings['labourUnitType'];
    }

    public isCheckedLabourUnitType(value: any) {
        return this.getLabourUnitType().value === value;
    }

    public setLabourUnitType(value: any) {
        if (this.firmSettings['labourUnitType']) {
            this.firmSettings['labourUnitType'].value = value;
            this.companyAdminService.hasChanges = true;
        }
    }

    public getPreCartCreationBehavior(value: any) {
        return this.getCartCreationBehavior().value === value;
    }

    public getPreOrderingValues(value: any) {
        return this.getPreOrderingBehavior().value === value;
    }

    public setCartCreationBehavior($event) {
        this.getCartCreationBehavior().value = $event;
        this.companyAdminService.hasChanges = true;
    }

    public setPreorderingValues($event) {
        this.getPreOrderingBehavior().value = $event;
        this.companyAdminService.hasChanges = true;
    }

    public checkState(key: string) {
        if (this.firmSettings && this.firmSettings[key]) {
            return this.firmSettings[key].value;
        } else {
            return false;
        }
    }

    public changeValue(event: any, key: string) {
        Object.keys(this.firmSettings).forEach((setting: string) => {
            if (setting === key) {
                this.firmSettings[setting].value = event;
                this.companyAdminService.hasChanges = true;
            }
        });
    }


    public saveChanges() {
        if (this.fromExtern) {
            this.adminService.setFirmSettings(this.firmId, this.firmSettings).subscribe(
                (success) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                    this.companyAdminService.hasChanges = false;
                    this.userService.accountData.settings['cartCreationBehaviour'].value =
                        this.firmSettings['cartCreationBehaviour'].value;
                },
                (error) => {
                    this.errorService.throwError(error.status);
                });
        } else {
            this.companyAdminService.updateSettings(this.firmSettings).subscribe(
                (success) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                    this.companyAdminService.hasChanges = false;
                    this.userService.accountData.settings['cartCreationBehaviour'].value =
                        this.firmSettings['cartCreationBehaviour'].value;
                },
                (error) => {
                    this.errorService.throwError(error.status);
                }
            );
        }
    }
}
