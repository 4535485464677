import {Component, Input} from '@angular/core';
import {MaintenanceService} from '../../services/maintenance.service';
import {CarService} from '../../../shared/services/car/car.service';
import {CustomerDataService} from '../../../shared/services/customerData.service';
import {Location} from '@angular/common';
import {CartService} from '../../../cart/services/cart.service';
import {DmsDataService} from '../../../shared/services/dms-data.service';
import {ConfigService} from '../../../shared/services/config.service';
import {UserService} from '../../../shared/services/user/user.service';

@Component({
    selector: 'app-maintenance-header',
    templateUrl: './maintenance-header.component.html',
    styleUrls: ['./maintenance-header.component.scss']
})
export class MaintenanceHeaderComponent {

    @Input() hideAdditionalMaintenance: boolean = false;

    constructor(public maintenanceService: MaintenanceService,
                public carservice: CarService,
                public dataService: CustomerDataService,
                public location: Location,
                public configService: ConfigService,
                public dmsDataService: DmsDataService,
                public cartService: CartService,
                public userService: UserService) {
    }

    public getAdditionalWorkTime(): number {
        let workTime = 0;
        this.maintenanceService.posByCar.forEach((group) => {
            workTime += group.position.quantity;
        });
        return Math.round(workTime * 100) / 100;
    }

    public getAdditionalWorkUnit(): string {
        return this.maintenanceService?.posByCar[0]?.position?.quantityUnit;
    }
}
