import {Injectable} from '@angular/core';
import {UserService} from './user/user.service';
import {getCurrencySymbol} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class HelperService {
    public tabChanged: Subject<boolean> = new Subject<boolean>();

    constructor(private userService: UserService,
        private translate: TranslateService,
        private http: HttpClient,
        private toastr: ToastrService) {
    }

    public recursiveDeepCopy(obj, hash = new WeakMap()) {
        if (Object(obj) !== obj) return obj; // primitives
        if (hash.has(obj)) return hash.get(obj); // cyclic reference
        const result = obj instanceof Set ? new Set(obj) // See note about this!
            : obj instanceof Map ? new Map(Array.from(obj, ([key, val]) =>
                    [key, this.recursiveDeepCopy(val, hash)]))
                : obj instanceof Date ? new Date(obj)
                    : obj instanceof RegExp ? new RegExp(obj.source, obj.flags)
                        // ... add here any specific treatment for other classes ...
                        // and finally a catch-all:
                        : obj.constructor ? new obj.constructor()
                            : Object.create(null);
        hash.set(obj, result);
        return Object.assign(result, ...Object.keys(obj).map(
            key => ({ [key]: this.recursiveDeepCopy(obj[key], hash) }) ));
    }

    // TODO: Use this instead, as soon as Project is updated to Node 17, Typescript 4.7, and @types/node v17.0.29:
    // public recursiveDeepCopy(objectToClone: any): any {
    //     let newObject = structuredClone(objectToClone);
    // }


    public browserIsIE(): number | false {
        const ua = window.navigator.userAgent;
        if (ua.indexOf('Trident/') > 0) {
            const rv = ua.indexOf('rv:');
            return parseInt((ua.substring(rv + 3).toString(), ua.indexOf('.', rv).toString()), 10);
        }
        if (ua.indexOf('MSIE ') > 0) {
            const msie = ua.indexOf('MSIE ');
            return parseInt((ua.substring(msie + 5).toString(), ua.indexOf('.', msie).toString()), 10);
        }
        return false;
    }

    public browserIsEdge(): number | false {
        const ua = window.navigator.userAgent;
        if (ua.indexOf('Edge/') > 0) {
            const edge = ua.indexOf('Edge/');
            return parseInt((ua.substring(edge + 5).toString(), ua.indexOf('.', edge).toString()), 10);
        }
        return false;
    }

    public getCurrency(article: any): string {
        let currency = 'EUR';

        if (article && article.currency) {
            currency = article.currency;
        } else if (this.userService?.accountData?.currency) {
            currency = this.userService.accountData.currency;
        }
        return currency;
    }

    public getCurrencySymbol(article: any): string {
        return getCurrencySymbol(this.getCurrency(article), 'narrow');
    }

    public showNotification(text: string, type: string, title: string = '', translate: boolean = true, options?) {
        if (this.userService.accountData && this.userService.accountData.userName) {
            if (translate) {
                this.translate.get(text).subscribe((msg) => {
                    this.showNotification(msg, type, title, false);
                });
            } else {
                switch (type) {
                    case 'error':
                        this.toastr.error(text, title, options);
                        break;
                    case 'success':
                        this.toastr.success(text, title, options);
                        break;
                    case 'info':
                        this.toastr.info(text, title, options);
                        break;
                    case 'warning':
                        this.toastr.warning(text, title, options);
                }
            }
        }
    }

    public returnSupplierName(code: string, list: any): string {
        const found = list.find(element => element.code === code);
        return found ? found.name : '';
    }
}
