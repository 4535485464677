import {EventEmitter, Injectable} from '@angular/core';
import {ICar, ICarNumberplate} from '../interfaces/DTO/car/car';
import {UserService} from './user/user.service';
import {CarRestService} from './car/car.rest.service';
import {IDmsCarHolder} from '../interfaces/DTO/car/dmsCarHolder';
import {ConfigService} from './config.service';
import {AutovistaRestService} from '../../graphical-part-search/services/autovista.rest.service';
import {HelperService} from './helper.service';
import {IVinCarWrapper, IVinSearchResponse} from '../interfaces/DTO/car/vinSearchResponse';
import {ICarModel} from './car/interfaces/car-model';
import {ICarProducer} from './car/interfaces/car-producer';
import {ICarType} from './car/interfaces/car-type';
import {CarService} from './car/car.service';
import {DmsDataService} from "./dms-data.service";

@Injectable({
    providedIn: 'root'
})
export class DmsCarService {
    public carHolder: Map<string, IDmsCarHolder> = new Map<string, IDmsCarHolder>();
    public carSelectionSetting: string[] = [];
    public isTecDocVinSearch: boolean;
    public carModels: ICarModel[] = [];
    public carProducers: ICarProducer[] = [];
    public carTypes: ICarType[] = [];
    public selectedProducer = null;
    public selectedModel = null;
    public selectedType = null;
    private natCode: string;
    private ikeCode: string;
    private _vin: string;
    public directAcceptCar: EventEmitter<string> = new EventEmitter<string>();
    public vinCarSetEmitter: EventEmitter<ICar> = new EventEmitter<ICar>();
    public lastSelectionType: string = '';
    public vehicleType: string = 'PC';

    constructor(private userService: UserService,
                private carRestService: CarRestService,
                private helperService: HelperService,
                private configService: ConfigService,
                private carService: CarService,
                private autovistaRestService: AutovistaRestService) {
        this.userService.accountData$.subscribe((step) => {
            if (step) {
                this.reset();
            }
        });

        this.carService.selectedCarChanged$.subscribe((car: ICar) => {
            if (this.lastSelectionType && this.lastSelectionType.length > 0) {
                this.getCarForType(this.lastSelectionType).cars = [car];
            }
        });
    }

    private reset() {
        this.carHolder = new Map<string, IDmsCarHolder>();
    }

    public getCars(dmsData: any) {
        this.createCarSelectionSettingList();

        if (dmsData && this.userService.getCatalogMode()) {

            if (dmsData.hsn && dmsData.tsn && this.configService.getActiveForNameByMode('CAR_SELECTION', 'KBA', 'CATALOG')) {
                this.getKBACar(dmsData.hsn, dmsData.tsn);
            }
            if (dmsData.vin && this.configService.getActiveForNameByMode('CAR_SELECTION', 'VIN', 'CATALOG')) {
                this.getVinCar(dmsData.vin);
            }
            if (dmsData.numberplate && this.configService.getActiveForNameByMode('CAR_SELECTION', 'NUMBERPLATE', 'CATALOG')) {
                this.getNumberplateCar(dmsData.numberplate);
            }
            if (dmsData.typenumber && this.configService.getActiveForNameByMode('CAR_SELECTION', 'SWISS_TYPENO', 'CATALOG')) {
                this.getTypenumberCar(dmsData.typenumber);
            }
            if (dmsData.kTypeId && this.configService.getActiveForNameByMode('CAR_SELECTION', 'KTYPE', 'CATALOG')) {
                this.getKTypeCar(dmsData.kTypeId);
            }
            if (dmsData.natcode && this.configService.getActiveForNameByMode('CAR_SELECTION', 'NATCODE', 'CATALOG')) {
                this.getNatCodeCar(dmsData.natcode);
            }
        }

        if (this.carSelectionSetting.includes('MANUALLY')) {
            this.getCarProducers();
        }
    }

    public createCarSelectionSettingList() {
        this.carSelectionSetting = [];
        const settings = this.configService.getCarSelectionSettingForMode('CATALOG');

        if (settings) {
            for (const setting of settings) {
                this.carSelectionSetting.push(setting.name);
            }
        }
    }

    public changeVehicleType(vehicleType: string) {
        this.vehicleType = vehicleType;
        this.getCarProducers();
    }


    private getKTypeCar(kTypeId: number) {
        this.carHolder.set('KTYPE', {
            status: 'LOADING',
            hasConflict: false,
            kTypeId: kTypeId
        });

        this.carRestService.getCarsByKTypeNumber(this.carHolder.get('KTYPE').kTypeId).subscribe(
            (response: ICar[]) => {
                if (response === null) {
                    this.carHolder.set('KTYPE', {
                        status: 'ERROR',
                        hasConflict: false,
                        kTypeId: kTypeId
                    });
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_TECDOC_RESPONSE', 'error');
                } else {
                    if (!this.userService.getCatalogMode()) {
                        this.carService.fromDMS = false;
                    }
                    this.carHolder.set('KTYPE', {
                        status: 'SUCCESS',
                        hasConflict: false,
                        kTypeId: kTypeId,
                        cars: response,
                    });
                }
            },
            (error) => {
                if (error.status === 409) {
                    this.carHolder.set('KTYPE', {
                        status: 'CONFLICT',
                        hasConflict: true,
                        kTypeId: kTypeId
                    });
                } else {
                    this.carHolder.set('KTYPE', {
                        status: 'ERROR',
                        hasConflict: false,
                        kTypeId: kTypeId
                    });
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                }
            }
        );
    }

    private getCarProducers() {
        this.carHolder.set('MANUALLY', {
            status: 'LOADING',
            vin: '',
            hasConflict: false,
            allow: false
        })

        this.carProducers = [];
        this.carModels = [];
        this.carTypes = [];

        this.carRestService.getCarProducers(this.vehicleType).subscribe(
            (producers: ICarProducer[]) => {
                this.carProducers = producers
                this.carHolder.set('MANUALLY', {
                    status: 'MANUALLY',
                    vin: '',
                    hasConflict: false,
                    allow: false
                })
            },
            (error) => {
                this.carHolder.set('MANUALLY', {
                    status: 'ERROR',
                    vin: '',
                    hasConflict: false,
                    allow: false
                })
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
            });
    }

    public getCarModels() {
        this.carModels = [];
        this.carTypes = [];

        this.carRestService.getCarModelsByProducerId(this.selectedProducer, this.vehicleType).subscribe(
            (models: ICarModel[]) => {
                this.carModels = models;
            },
            (error) => {
                this.carHolder.set('MANUALLY', {
                    status: 'ERROR',
                    vin: '',
                    hasConflict: false,
                    allow: false
                })
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
            });
    }

    public getCarTypes() {
        this.carTypes = [];

        this.carRestService.getCarTypesByProducerIdAndModelId(this.selectedProducer, this.selectedModel, this.vehicleType).subscribe(
            (types: ICarType[]) => {
                this.carTypes = types;
            },
            (error) => {
                this.carHolder.set('MANUALLY', {
                    status: 'ERROR',
                    vin: '',
                    hasConflict: false,
                    allow: false
                })
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
            });
    }

    public getCarByManuallySelection() {
        this.carRestService.getCarsByKTypeNumber(this.selectedType).subscribe(
            (cars: ICar[]) => {
                if (cars.length > 0) {
                    this.carHolder.set('MANUALLY', {
                        status: 'SUCCESS',
                        vin: '',
                        cars: cars,
                        hasConflict: false,
                        allow: false
                    });
                    this.directAcceptCar.emit('MANUALLY');
                } else {
                    this.carHolder.set('MANUALLY', {
                        status: 'ERROR',
                        vin: '',
                        hasConflict: false,
                        allow: false
                    })
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                }
            },
            (error) => {
                if (error.status === 409) {
                    this.carHolder.set('MANUALLY', {
                        status: 'CONFLICT',
                        vin: '',
                        hasConflict: true,
                        allow: false
                    })
                } else {
                    this.carHolder.set('MANUALLY', {
                        status: 'ERROR',
                        vin: '',
                        hasConflict: false,
                        allow: false
                    })
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                }
            }
        )
    }

    public checkSelection(vinCar: IVinSearchResponse): IVinCarWrapper {
        const vinCarWrapper: IVinCarWrapper = {
            vinCar: undefined,
            typeId: -1,
            vin: undefined,
            natCode: undefined,
            ikeCode: undefined
        };

        if (vinCar.carDtos && vinCar.carDtos.length > 0) {
            vinCarWrapper.vinCar = vinCar.carDtos;
            for (const car of vinCarWrapper.vinCar) {
                car.fromVIN = true;
            }
            vinCarWrapper.typeId = 0;
        } else if (vinCar.vinSelection
            && vinCar.vinSelection.specification
            && vinCar.vinSelection.specification.vehicles
            && vinCar.vinSelection.specification.vehicles.length > 1) {
            if (vinCar.vinSelection.specification.vehicles.length === 1) {
                this.getVinCar(this._vin, true, this.natCode, this.ikeCode);
                vinCarWrapper.typeId = 3;
            } else {
                vinCarWrapper.ikeCode = this.ikeCode;
                vinCarWrapper.vin = vinCar.vinSelection.vin;
                vinCarWrapper.vinCar = vinCar.vinSelection.specification.vehicles;
                vinCarWrapper.typeId = 2;
                vinCarWrapper.errorCode = vinCar.errorCode;
            }

        } else if (vinCar.vinSelection
            && vinCar.vinSelection.repair
            && vinCar.vinSelection.repair.headmodels
            && vinCar.vinSelection.repair.headmodels.length > 0) {
            if (!this.natCode && vinCar.vinSelection.specification && vinCar.vinSelection.specification.vehicles.length > 0) {
                this.natCode = vinCar.vinSelection.specification.vehicles[0].typeETGCode;
            }
            if (vinCar.vinSelection.repair.headmodels.length === 1) {
                this.getVinCar(this._vin, true, this.natCode, this.ikeCode);
                vinCarWrapper.typeId = 3;
            } else {
                vinCarWrapper.natCode = this.natCode;
                vinCarWrapper.vin = vinCar.vinSelection.vin;
                vinCarWrapper.vinCar = vinCar.vinSelection.repair.headmodels;
                vinCarWrapper.typeId = 1;
                vinCarWrapper.errorCode = vinCar.errorCode;
            }
        }

        return vinCarWrapper;
    }

    public getVinCar(vin: string, allow: boolean = false, typeETGCode?: string, code?: string) {
        if (vin) {
            this._vin = vin;
            this.carHolder.set('VIN', {
                status: 'LOADING',
                vin: vin,
                allow: allow,
                hasConflict: false,
                natCode: typeETGCode,
                ikeCode: code
            });


            if (this.userService.getSubSettingForName('CAR_SELECTION', 'VIN').selected === 'TECRMI') {
                this.isTecDocVinSearch = true;
                this.carRestService.getCarsByVIN(this.carService.replaceVin(vin)).subscribe(
                    (cars: ICar[]) => {
                        this.carHolder.set('VIN', {
                            status: 'SUCCESS',
                            vin: vin,
                            cars: cars,
                            hasConflict: false,
                            allow: allow
                        });
                    },
                    (error) => {
                        if (error.status === 409) {
                            this.carHolder.set('VIN', {
                                status: 'CONFLICT',
                                vin: vin,
                                hasConflict: true,
                                allow: allow
                            });
                        } else {
                            this.carHolder.set('VIN', {
                                status: 'ERROR',
                                vin: vin,
                                hasConflict: false,
                                allow: allow
                            });
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                        }

                    });
            } else {
                this.isTecDocVinSearch = false;
                this.autovistaRestService.getAutovistaVinRest(this.carService.replaceVin(vin), allow, typeETGCode, code).subscribe(
                    (cars: IVinSearchResponse) => {
                        if (cars.carDtos && cars.carDtos.length > 0 && cars.errorCode === 0) {
                            this.carHolder.set('VIN', {
                                status: 'SUCCESS',
                                vin: vin,
                                hasConflict: false,
                                cars: cars.carDtos,
                                allow: allow
                            });
                            this.vinCarSetEmitter.emit(cars.carDtos[0]);
                        } else if (cars.errorCode === -1) {
                            if (!allow) {
                                this.carHolder.set('VIN', {
                                    status: 'NOT_FREE',
                                    vin: vin,
                                    hasConflict: false,
                                    cars: cars.carDtos,
                                    allow: allow
                                });
                                this.helperService.showNotification('TOAST_MESSAGES.ERROR_NOT_FREE', 'error');
                            } else {
                                this.setFromVin(cars.carDtos);
                                this.carHolder.set('VIN', {
                                    status: 'SUCCESS',
                                    vin: vin,
                                    hasConflict: false,
                                    cars: cars.carDtos,
                                    allow: allow
                                });
                            }

                        } else if (cars.errorCode === -2) {
                            this.setFromVin(cars.carDtos);
                            this.carHolder.set('VIN', {
                                status: 'SUCCESS',
                                vin: vin,
                                hasConflict: false,
                                cars: cars.carDtos,
                                allow: allow
                            });

                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_NOT_FREE', 'error');
                        } else if (cars.errorCode < -2) {
                            this.setFromVin(cars.carDtos);
                            this.carHolder.set('VIN', {
                                status: 'SUCCESS',
                                vin: vin,
                                hasConflict: false,
                                cars: cars.carDtos,
                                allow: allow
                            });

                            if (cars.errorCode === -5) {
                                this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_AUTOVISTA_CHARGEABLE', 'error');
                            } else {
                                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                            }

                        }
                        if (cars.errorCode === 0 && cars.vinSelection) {
                            this.carService.loadCar = undefined;
                            switch (this.checkSelection(cars).typeId) {
                                case 0:
                                    this.carHolder.set('VIN', {
                                        status: 'SUCCESS',
                                        vin: vin,
                                        hasConflict: false,
                                        cars: cars.carDtos,
                                        allow: allow
                                    });
                                    break;
                                case 1:
                                    const ikeCodeCar = this.checkSelection(cars);
                                    this.carHolder.set('VIN', {
                                        status: 'SELECT_CAR',
                                        vin: vin,
                                        hasConflict: false,
                                        cars: ikeCodeCar.vinCar,
                                        natCode: ikeCodeCar.natCode,
                                        ikeCode: ikeCodeCar.ikeCode,
                                        allow: allow
                                    });
                                    break;
                                case 2:
                                    const natCodeCar = this.checkSelection(cars);
                                    this.carHolder.set('VIN', {
                                        status: 'SELECT_CAR',
                                        vin: vin,
                                        hasConflict: false,
                                        cars: natCodeCar.vinCar,
                                        natCode: natCodeCar.natCode,
                                        ikeCode: natCodeCar.ikeCode,
                                        allow: allow
                                    });
                                    break;
                                case -1:
                                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                                    break;
                                default:
                            }
                            if (cars.errorCode !== 0 && cars.errorMessage !== null && this.userService.allow('GLOBAL-ADMIN')) {
                                if (this.carHolder.get('VIN').status === 'LOADING') {
                                    this.setFromVin(cars.carDtos);
                                    this.carHolder.set('VIN', {
                                        status: 'SUCCESS',
                                        vin: vin,
                                        hasConflict: false,
                                        cars: cars.carDtos,
                                        allow: allow
                                    });
                                }
                                this.helperService.showNotification(('Error: ' + cars.errorCode + ' ' + cars.errorMessage), 'error');
                            }
                        }
                    },
                    (error) => {
                        if (error.status === 409) {
                            this.carHolder.set('VIN', {
                                status: 'CONFLICT',
                                vin: vin,
                                hasConflict: true,
                                allow: allow
                            });
                        } else {
                            this.carHolder.set('VIN', {
                                status: 'ERROR',
                                vin: vin,
                                hasConflict: false,
                                allow: allow
                            });
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_CAR_FOUND', 'error');
                        }
                    });
            }
        } else {
            this.carHolder.set('VIN', {
                status: 'ERROR',
                vin: vin,
                hasConflict: false,
                allow: allow
            })
        }
    }

    private setFromVin(cars: any) {
        for (const car of cars) {
            car.fromVIN = true;
        }
    }

    public getKBACar(hsn: string, tsn: string) {
        if (hsn && tsn) {
            this.carHolder.set('KBA', {
                status: 'LOADING',
                hsn: hsn,
                hasConflict: false,
                tsn: tsn,
            });

            this.carRestService.getCarsByHsnTsn(hsn, tsn).subscribe(
                (cars: ICar[]) => {
                    if (cars && cars.length > 0) {
                        this.carHolder.set('KBA', {
                            status: 'SUCCESS',
                            hsn: hsn,
                            tsn: tsn,
                            hasConflict: false,
                            cars: cars
                        });
                    } else {
                        this.carHolder.set('KBA', {
                            status: 'ERROR',
                            hsn: hsn,
                            tsn: tsn,
                            hasConflict: false,
                            cars: cars
                        });
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                    }
                },
                (error) => {
                    if (error.status === 409) {
                        this.carHolder.set('KBA', {
                            status: 'CONFLICT',
                            hsn: hsn,
                            hasConflict: false,
                            tsn: tsn,
                        });
                    } else {
                        this.carHolder.set('KBA', {
                            status: 'ERROR',
                            hsn: hsn,
                            hasConflict: false,
                            tsn: tsn,
                        });
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_CAR_FOUND', 'error');
                    }
                });
        } else {
            this.carHolder.set('KBA', {
                status: 'ERROR',
                hsn: hsn,
                hasConflict: false,
                tsn: tsn,
            });
            this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_CAR_FOUND', 'error');
        }
    }


    public getTypenumberCar(typenumber: string) {
        if (typenumber) {
            this.carHolder.set('SWISS_TYPENO', {
                status: 'LOADING',
                hasConflict: false,
                typenumber: typenumber,
            });

            this.carRestService.getCarsByTypenumber(typenumber).subscribe(
                (cars: ICar[]) => {
                    this.carHolder.set('SWISS_TYPENO', {
                        status: 'SUCCESS',
                        hasConflict: false,
                        typenumber: typenumber,
                        cars: cars
                    });
                },
                (error) => {
                    if (error.status === 409) {
                        this.carHolder.set('SWISS_TYPENO', {
                            status: 'CONFLICT',
                            hasConflict: true,
                            typenumber: typenumber
                        });

                    } else {
                        this.carHolder.set('SWISS_TYPENO', {
                            status: 'ERROR',
                            hasConflict: false,
                            typenumber: typenumber
                        });
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_CAR_FOUND', 'error');
                    }
                });
        } else {
            this.carHolder.set('SWISS_TYPENO', {
                status: 'ERROR',
                hasConflict: false,
                typenumber: typenumber
            });
        }
    }

    public getNumberplateCar(numberplate: string) {
        if (numberplate) {
            this.carHolder.set('NUMBERPLATE', {
                status: 'LOADING',
                hasConflict: false,
                numberplate: numberplate
            });

            this.carRestService.getCarsByNumberplate(numberplate).subscribe(
                (res: ICarNumberplate) => {
                    if (res.foundCars.length > 0) {
                        this.carHolder.set('NUMBERPLATE', {
                            status: 'SUCCESS',
                            hasConflict: false,
                            numberplate: numberplate,
                            cars: res.foundCars
                        });
                    } else {
                        this.carHolder.set('NUMBERPLATE', {
                            status: 'ERROR',
                            hasConflict: false,
                            numberplate: numberplate
                        });
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_CAR_FOUND', 'error');
                    }
                },
                (error) => {
                    if (error.status === 409) {
                        this.carHolder.set('NUMBERPLATE', {
                            status: 'CONFLICT',
                            hasConflict: true,
                            numberplate: numberplate
                        });
                    } else {
                        this.carHolder.set('NUMBERPLATE', {
                            status: 'ERROR',
                            hasConflict: false,
                            numberplate: numberplate
                        });
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_CAR_FOUND', 'error');
                    }
                });
        } else {
            this.carHolder.set('NUMBERPLATE', {
                status: 'ERROR',
                hasConflict: false,
                numberplate: numberplate
            });

            this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_CAR_FOUND', 'error');
        }
    }

    public getNatCodeCar(natcode: string) {
        if (natcode) {
            this.carHolder.set('NATCODE', {
                status: 'LOADING',
                hasConflict: false,
                natCode: natcode
            });

            this.autovistaRestService.getCarsByNatcode(natcode).subscribe(
                (cars: ICar[]) => {
                    if (cars.length > 0) {
                        this.carHolder.set('NATCODE', {
                            status: 'SUCCESS',
                            hasConflict: false,
                            natCode: natcode,
                            cars: cars
                        });
                    } else {
                        this.carHolder.set('NATCODE', {
                            status: 'ERROR',
                            hasConflict: false,
                            natCode: natcode
                        });
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_CAR_FOUND', 'error');
                    }
                },
                (error) => {
                    if (error.status === 409) {
                        this.carHolder.set('NATCODE', {
                            status: 'CONFLICT',
                            hasConflict: true,
                            natCode: natcode
                        });
                    } else {
                        this.carHolder.set('NATCODE', {
                            status: 'ERROR',
                            hasConflict: false,
                            natCode: natcode
                        });
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_CAR_FOUND', 'error');
                    }
                }
            );
        } else {
            this.carHolder.set('NATCODE', {
                status: 'ERROR',
                hasConflict: false,
                natCode: natcode
            });

            this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_CAR_FOUND', 'error');
        }
    }


    public getCarForType(type: string) {
        if (type && this.carHolder && this.carHolder.size > 0) {
            return this.carHolder.get(type);
        } else {
            return undefined;
        }
    }

    public getParamForType(type: string) {
        if (this.carHolder.get(type)) {
            switch (type) {
                case 'VIN':
                    return this.carHolder.get(type).vin;
                case 'KBA':
                    return this.carHolder.get(type).hsn + ' ' + this.carHolder.get(type).tsn;
                case 'NUMBERPLATE':
                    return this.carHolder.get(type).numberplate;
                case 'SWISS_TYPENO':
                    return this.carHolder.get(type).typenumber;
                case 'KTYPE':
                    return this.carHolder.get(type).kTypeId;
            }
        }
    }
}
