<div class="transferLog" (click)="closeTransferLog.emit()">
  <div class="transferLog-center" (click)="onEvent($event)">
    <div class="row background header-title" style="padding-top: 10px">
      <div class="col-10" style="top: 2px;">
        <span>{{title}}</span>
      </div>
      <div class="col-2">
        <button class="transferLog-button" (click)="closeTransferLog.emit()">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    <div class="row background">
      <div class="col">
        <div class="row" *ngFor="let item of transferLog">
          <div class="col">
            <div class="row">
              <div class="col">
                <div class="request">
                  <h1>Request</h1>
                  <div class="row">
                    <div class="col">
                      {{item.request}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="response">
                  <h1>Response</h1>
                  <div class="row">
                    <div class="col">
                      {{item.response}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
