<div class="articles">
    <div class="content sidebar-opener-wrapper" *ngIf="(stateService.articleContent | async) === 'CONTENT'">
        <div class="sidebar-opener" [ngClass]="userService?.getNavigation()">
            <ul>
                <li (click)="showNavigation()">
                    {{ 'GENERAL.NAVIGATION.NAVIGATION' | translate }}
                </li>
                <li (click)="showFilter()" [ngClass]="{'disabled':!hasFilter()}">
                    {{ 'GENERAL.NAVIGATION.FILTER' | translate }}
                </li>
                <li (click)="userService?.toggleNavigation()">
                    <i class="fa fa-chevron-right"></i>
                </li>
            </ul>
        </div>
        <div class="left-panel no-float no-padding" [ngClass]="userService?.getNavigation()">
            <div class="assembly-categories-wrapper">
                <div class="assembly-categories-tree large-content">
                    <div class="panel-content left-sidebar tree-sidebar">
                        <div class="inner-content no-padding">
                            <ngb-tabset #t3>
                                <ngb-tab title="{{'SPAREPARTS_VIEW.ASSEMBLIES' | translate}}">
                                    <ng-template ngbTabContent>
                                        <app-default-loading-page
                                                *ngIf="articlesService.treeLoading"></app-default-loading-page>

                                        <div class="padding-top h-100" *ngIf="!articlesService.treeLoading">
                                            <ul class="custom-tree" *ngIf="!filtersExpanded">
                                                <button *ngIf="articlesService.treeFilter" (click)="fullReset()"
                                                        class="btn-reset-filter">
                                                    <span class="pull-left">{{ 'SPAREPARTS_VIEW.SHOW_ALL_ASSEMBLIES' | translate }}</span>
                                                    <i class="fa fa-times mr-2"></i>
                                                </button>

                                                <app-left-tree
                                                        [firstNode]="true"
                                                        [nodeTree]="articlesService.articleTree"
                                                        (onActivate)="onTreeNodeClick($event)"
                                                        [nodeActive]="articlesService.selectedCategory?.id"
                                                        [mainOnlyOpen]="true"
                                                        [treeFilter]="articlesService.treeFilter">
                                                </app-left-tree>

                                            </ul>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                                <!--Filter-->
                                <ngb-tab [disabled]="!articlesService?.searchResult">
                                    <ng-template ngbTabTitle>
                                        <div [@bounceIn]="articlesService?.searchResult?.possibleFilters?.length > 0" (click)="sortFiltersBySortNoAndAlphabetical()">
                                            <i class="fa fa-filter"
                                               *ngIf="articlesService?.searchResult?.possibleFilters?.length > 0"></i>
                                            <span style="margin-left: 5px;">{{ 'SPAREPARTS_VIEW.FILTER' | translate }}</span>
                                            <span *ngIf="articlesService?.searchResult?.possibleFilters?.length > 0">(<span>{{ articlesService?.searchResult?.possibleFilters?.length }}</span>)</span>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbTabContent>
                                        <div class="button-header">
                                            <button class="base-btn mr-3 float-right"
                                                    *ngIf="articlesService?.searchResult?.possibleFilters?.length > 0 || articlesService?.searchResult?.searchForAllArticles"
                                                    (click)="articlesService.resetFilter()">
                                                <i class="fa fa-times mr-2"></i>
                                                {{ 'SPAREPARTS_VIEW.FILTER_RESET' | translate }}
                                            </button>
                                            <button class="base-btn mr-3 float-right"
                                                    *ngIf="!configService.getAccountDataSettingActive('settings', 'defaultSearchForAllArticles')"
                                                    (click)="articlesService.showAllArticles()">
                                                <i class="fa fa-eye mr-2"></i>
                                                <span>{{ 'SPAREPARTS_VIEW.SHOW_ALL' | translate }}</span>
                                            </button>
                                            <button class="base-btn mr-3 float-right"
                                                    [ngClass]="{'primary pulse': articlesService.filterChanged }"
                                                    [disabled]="articlesService.articlesLoading || articlesService.infinityLoading"
                                                    (click)="articlesService.updateFilter()">
                                                <i class="fa fa-check mr-2"></i>
                                                <span>{{ 'GENERAL.UPDATE' | translate }}</span>
                                            </button>
                                        </div>
                                        <div class="scroll-content">
                                            <ng-container
                                                    *ngFor="let groups of articlesService?.searchResult?.possibleFilters">
                                                <app-collapsible-panel
                                                        [folded]="articlesService?.searchResult?.possibleFilters?.length !== 1 && !(articlesService.foldedCollapsePanel.indexOf(groups.groupName) > -1)"
                                                        (onChangeFolded)="articlesService.toggleFolded($event, groups.groupName)"
                                                        [panelTitle]="groups.groupName | translate"
                                                        *ngIf="groups.groupId === 'BRAND'">
                                                    <div class="filterVar" *ngFor="let filter of groups.filters">
                                                        <app-checkbox [isChecked]="articlesService.checkFilter(filter)"
                                                                      [labelText]="filter.displayValue"
                                                                      (onChange)="articlesService.toggleActiveFilter(filter)"></app-checkbox>
                                                    </div>
                                                </app-collapsible-panel>
                                            </ng-container>
                                            <ng-container
                                                    *ngFor="let groups of articlesService?.searchResult?.possibleFilters">
                                                <app-collapsible-panel
                                                        [folded]="!(articlesService.foldedCollapsePanel.indexOf(groups.groupName) > -1)"
                                                        (onChangeFolded)="articlesService.toggleFolded($event, groups.groupName)"
                                                        [panelTitle]="groups.groupName"
                                                        *ngIf="groups.groupId !== 'BRAND'">
                                                    <div class="filterVar" *ngFor="let filter of groups.filters">
                                                        <app-checkbox
                                                                [isChecked]="articlesService.findFilter(filter, true)"
                                                                [labelText]="filter.displayValue"
                                                                (onChange)="articlesService.toggleActiveFilter(filter)">
                                                        </app-checkbox>
                                                    </div>
                                                </app-collapsible-panel>
                                            </ng-container>

                                            <div class="alert alert-info text-center"
                                                 *ngIf="!(articlesService?.searchResult?.possibleFilters?.length > 0)">
                                                <i class="fa fa-info-circle mr-2"></i>
                                                <span>{{ 'PROMPTS.MESSAGE.NO_FILTER' | translate }}</span>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngb-tab>

                            </ngb-tabset>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
                <div class="assembly-categories-filter" style="display: none;"
                     [ngClass]="{ 'large-content': filtersExpanded, 'small-content': !filtersExpanded }">

                    <div class="heading left blue clickable" (click)="filtersExpanded = !filtersExpanded">
                        <div class="text">{{ 'SPAREPARTS_VIEW.FILTER' | translate }}</div>
                        <div class="expand-compress-icon">
                            <i class="fa"
                               [ngClass]="{ 'fa-compress': filtersExpanded, 'fa-expand': !filtersExpanded }"></i>
                        </div>
                    </div>

                    <div class="panel-content left-sidebar">
                        <div class="inner-content no-padding">
                            <!--<nx-car-parts-filters items="vm.articlesSearchResult.$filters"></nx-car-parts-filters>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="center-panel no-float" [ngClass]="userService?.getNavigation()">
            <div class="heading blue">
                <span (click)="fullReset()" class="clickable">
                    <i class="fa fa-th"></i>
                    {{ 'SPAREPARTS_VIEW.ASSEMBLIES' | translate }}
                </span>
                <!-- Pseudo Artikel -->
                <span *ngIf="articlesService?.showPseudoArticle && articlesService?.selectedPseudoArticle">
                    <i class="fa fa-caret-right"></i>
                    {{ articlesService?.selectedPseudoArticle?.description }}
                    <span *ngIf="articlesService?.selectedPseudoArticle?.ipc">({{ articlesService?.selectedPseudoArticle?.ipc }}
                        )</span>
                </span>
                <span *ngIf="articlesService?.lastSearchParams?.searchType === 'direct' && articlesService?.searchResult !== undefined">
                    <!-- Anfrage über die Suchleiste -->
                     <i class="fa fa-caret-right"></i>
                    {{ articlesService?.lastSearchParams?.searchString }}
                    <i class="fa fa-caret-right"></i>
                    <span *ngIf="articlesService.searchResult?.count === 1">{{ articlesService.searchResult?.count + ' ' + ('SPAREPARTS_VIEW.ARTICLE' | translate) }} </span>
                    <span *ngIf="articlesService.searchResult?.count > 1">{{ articlesService.searchResult?.count + ' ' + ('SPAREPARTS_VIEW.ARTICLES' | translate) }} </span>

                </span>
                <span *ngIf="articlesService?.lastSearchParams?.searchType == 'side' && articlesService?.searchResult != undefined">
                    <!-- Anfrage über die Seitenleiste -->
                    <i class="fa fa-caret-right"></i>
                    ...
                    <i class="fa fa-caret-right"></i>
                    <span class="name-limit">{{ articlesService?.lastSearchParams?.name }}</span>
                    <i class="fa fa-caret-right"></i>
                    <span *ngIf="articlesService.searchResult?.count === 1">{{ articlesService.searchResult?.count + ' ' + ('SPAREPARTS_VIEW.ARTICLE' | translate) }} </span>
                    <span *ngIf="articlesService.searchResult?.count > 1">{{ articlesService.searchResult?.count + ' ' + ('SPAREPARTS_VIEW.ARTICLES' | translate) }} </span>
                </span>
                <span class="pull-right search-bar">
                     <form class="main-search-form show-search" action="">
                        <input type="text"
                               #searchinput
                               class="search-input form-control"
                               placeholder="{{ 'SPAREPARTS_VIEW.SEARCH_FOR_GENART' | translate }}"
                               [(ngModel)]="searchString" (keydown.enter)="articlesSearch()"
                               (click)="articlesSearch()"
                               name="searchString"/>
                      </form>
                    <i id="search-open"
                       (click)="articlesService.searchOpen = !articlesService.searchOpen; resetCategory(); focusInput(searchinput)"
                       *ngIf="!articlesService.searchOpen"> <i class="fa fa-search"></i></i>
                    <i id="search-close" class="fa fa-times"
                       (click)="articlesService.searchOpen = !articlesService.searchOpen; selectedGenArts = false; searchString = ''"
                       *ngIf="articlesService.searchOpen"></i>
                </span>
                <div class="articleSizeWrapper d-inline-block float-right">
                    <div class="articleSize" *ngIf="articlesService?.searchResult?.items"
                         (click)="showArticleSize = !showArticleSize">
                        <i class="fa fa-list"></i>
                        <span class="mr-2" *ngIf="articlesService.selectedSearchResultSize">
                            {{ 'GENERAL.' + articlesService.selectedSearchResultSize.toUpperCase() | translate }}
                        </span>
                        <span clasS="mr-2" *ngIf="!articlesService.selectedSearchResultSize">
                            {{ 'GENERAL.PLEASE_SELECT' | translate | ellipsis: 10 }}
                        </span>
                    </div>
                    <div class="dropdown-list" *ngIf="showArticleSize">
                        <ul>
                            <li (click)="setArticleSize('big')">
                                <span>{{ 'GENERAL.BIG' | translate }}</span>
                            </li>
                            <li (click)="setArticleSize('normal')">
                                <span>{{ 'GENERAL.NORMAL' | translate }}</span>
                            </li>
                            <li (click)="setArticleSize('small')">
                                <span>{{ 'GENERAL.SMALL' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="searchResultSupplierWrapper d-inline-block float-right">
                    <div class="searchResultSupplier"
                         *ngIf="userService?.suppliers?.length > 0 && articlesService.selectedSearchResultSupplier
                         && articlesService.searchResult?.items"
                         (click)="showSearchResultSupplier = !showSearchResultSupplier">
                        <i class="fa fa-truck"></i>
                        <!-- AFTER MARKET -->
                        <span class="mr-2"
                              *ngIf="articlesService.supplierType === 'AFTER_MARKET' && articlesService.selectedSearchResultSupplier">
                            {{ helperService.returnSupplierName(articlesService.selectedSearchResultSupplier, userService.getRegularSuppliersName(userService.suppliers)) | ellipsis: 10 }}
                        </span>
                        <span class="mr-2"
                              *ngIf="articlesService.supplierType === 'AFTER_MARKET' && !articlesService.selectedSearchResultSupplier">
                            {{ 'GENERAL.PLEASE_SELECT' | translate | ellipsis: 10 }}
                        </span>

                        <!-- OE -->
                        <span class="mr-2"
                              *ngIf="articlesService.supplierType === 'OE' && articlesService.selectedSearchResultOeSupplier">
                            {{ helperService.returnSupplierName(articlesService.selectedSearchResultOeSupplier, userService.getRegularSuppliersName(autovistaService.oeSuppliers)) | ellipsis: 10 }}
                        </span>
                        <span class="mr-2"
                              *ngIf="articlesService.supplierType === 'OE' && !articlesService.selectedSearchResultOeSupplier">
                            {{ 'GENERAL.PLEASE_SELECT' | translate | ellipsis: 10 }}
                        </span>
                    </div>
                    <div class="dropdown-list" *ngIf="showSearchResultSupplier">
                        <!-- AFTER MARKET -->
                        <ng-container *ngIf="articlesService.supplierType === 'AFTER_MARKET'">
                            <ul>
                                <li *ngFor="let supp of userService.getRegularSuppliersName(userService.suppliers)"
                                    (click)="setSearchResultSupplier('AFTER_MARKET', supp.code)">
                                    <span>{{ supp.name }}</span>
                                </li>
                            </ul>
                        </ng-container>

                        <!-- OE -->
                        <ng-container *ngIf="articlesService.supplierType === 'OE'">
                            <ul>
                                <li *ngFor="let supp of userService.getRegularSuppliersName(autovistaService.oeSuppliers)"
                                    (click)="setSearchResultSupplier('OE', supp.code)">
                                    <span>{{ supp.name }}</span>
                                </li>
                            </ul>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div id="filterBar" class="filterBar" *ngIf="articlesService?.searchResult?.activeFilters.length > 0">
                <div class="row">
                    <div class="col">
                        <div class="chipBar" [ngClass]="{'open': openFilterBar}">
                            <ng-container *ngIf="!openFilterBar">
                                <app-chip *ngFor="let filter of articlesService?.searchResult?.activeFilters"
                                          [text]="filter?.displayValue"
                                          (onIconClick)="articlesService.toggleActiveFilter(filter)"></app-chip>
                            </ng-container>
                            <ng-container *ngIf="openFilterBar">
                                <div class="filterType" *ngFor="let filterType of articlesService?.filterGroupNames">
                                    <div>{{ filterType }}:</div>
                                    <ng-container *ngFor="let filter of articlesService?.searchResult?.activeFilters">
                                        <app-chip *ngIf="filter.groupName === filterType" [text]="filter?.displayValue"
                                                  (onIconClick)="articlesService.toggleActiveFilter(filter)"></app-chip>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                        <div class="toggleIcon clickable" (click)="toggleFilterBar()">
                            <i class="fa fa-angle-down" *ngIf="!openFilterBar"></i>
                            <i class="fa fa-angle-up" *ngIf="openFilterBar"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel-content center-content white-background pseudo-article"
                 *ngIf="articlesService?.showPseudoArticle && articlesService?.selectedPseudoArticle">
                <div class="inner-content">
                    <div class="row">
                        <div class="col-4">
                            <img class="image" src="{{articlesService?.selectedPseudoArticle?.previewImageUrl}}">
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col">
                                    <span class="description">{{ articlesService?.selectedPseudoArticle?.description }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <span class="brand">{{ articlesService?.selectedPseudoArticle?.brandName }} {{ articlesService?.selectedPseudoArticle?.ipc }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-12">
                                    <button class="base-btn"
                                            (click)="showPseudoArticleDetails(articlesService.selectedPseudoArticle)">{{ 'ARTICLE_SELECTION.SHOW_DETAILS' | translate }}
                                    </button>
                                </div>
                            </div>
                            <div class="row spacer">
                                <div class="col-12">
                                    <button class="base-btn"
                                            (click)="goBackToSearch()">{{ 'ARTICLE_SELECTION.BACK_TO_SEARCH' | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="panel-content center-content white-background quick-filter" *ngIf="!articlesService.articlesLoading
            && articlesService.searchResult != undefined && articlesService.searchResult?.possibleFilters?.length > 0">
                <div class="inner-content">
                    <div class="row">
                        <span class="first-text">{{ 'ARTICLE_SELECTION.QUICKFILTER_FIRST_TEXT' | translate }}</span>
                        <app-dropdown [isFilterDropdown]="true"
                                      [hasSearch]=" true"
                                      [placeholderText]="articlesService.searchResult.possibleFilters[0].groupName | translate"
                                      (onItemSelect)="selectFilter($event)"
                                      [preSelectedItem]="getSelectedFilterValue(articlesService.searchResult.possibleFilters[0].groupId)"
                                      [items]="articlesService.searchResult.possibleFilters[0].filters">
                        </app-dropdown>
                        <ng-container *ngIf="articlesService.getIndexForFilterByGroupId(100) > 0">
                            <span class="second-text">{{ 'ARTICLE_SELECTION.QUICKFILTER_SECOND_TEXT' | translate }}</span>
                            <app-dropdown [isFilterDropdown]="true"
                                          [placeholderText]="articlesService.searchResult.possibleFilters[articlesService.getIndexForFilterByGroupId(100)].groupName | translate"
                                          (onItemSelect)="selectFilter($event)"
                                          [preSelectedItem]="getSelectedFilterValue(articlesService.searchResult.possibleFilters[articlesService.getIndexForFilterByGroupId(100)].groupId)"
                                          [items]="articlesService.searchResult.possibleFilters[articlesService.getIndexForFilterByGroupId(100)].filters">
                            </app-dropdown>
                        </ng-container>
                        <button *ngIf="articlesService?.searchResult?.activeFilters?.length > 0" class="base-btn"
                                (click)="resetQuickFilter()">
                            {{ 'SPAREPARTS_VIEW.FILTER_RESET' | translate }}
                        </button>
                    </div>
                    <div class="row legend-resort-row mt-2">
                        <div class="col-8">
                            <div class="d-flex align-items-center h-100">
                                <span class="clickable" (click)="showLegend= true">
                                    <i class="fa fa-info-circle mr-2"></i>
                                    {{ 'SPAREPARTS_VIEW.LEGEND' | translate }}
                                </span>
                            </div>
                        </div>
                        <div class="col-4 d-flex justify-content-end" *ngIf="userService.enableInfiniteScroll">
                            <div triggers="mouseenter:mouseleave"
                                 class="toolTipDiv"
                                 placement="top-right"
                                 [ngbPopover]="popContent" container="body">
                                <button [disabled]="(countSmallerThreshold || !scrollEnd) && (!countSmallerThreshold)"
                                        (click)="articlesService.resortEmitterNewPageNeeded.emit(!scrollEnd)"
                                        class="base-btn success resort-btn">
                                    {{ 'SPAREPARTS_VIEW.SEARCH_RESULTS.RESORT' | translate }}
                                </button>
                                <ng-template #popContent>
                                    <div>
                                        {{ countSmallerThreshold ? ('ARTICLE_SELECTION.INFO_THRESHOLD' | translate) : ('ARTICLE_SELECTION.INFO_THRESHOLD_DISABLED' | translate) }}
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="panelContent" class="panel-content center-content white-background change-center-height"
                 appInfiniteScroller
                 (onScrollEnd)="articlesService?.searchResult?.isAccessorieslist ? '' : articlesService.loadNext($event)"
                 [ngClass]="{'show-pseudo-article': articlesService?.showPseudoArticle && articlesService?.selectedPseudoArticle,
                 'show-quick-filter': articlesService?.searchResult?.possibleFilters?.length > 0 && !articlesService.articlesLoading}">
                <!-- die Methode onScrollEnd wurde um eine Bediengung erweitert: Wenn es eine Zubehörliste ist,
                soll kein neuer Searchcall ausgeführt werden, da die Liste schon alle Einträge hat -->
                <app-default-loading-page
                        *ngIf="articlesService.resultItems.length === 0 && articlesService.articlesLoading"></app-default-loading-page>

                <div class="inner-content smaller-height">
                    <app-default-loading-page
                            *ngIf="articlesService.showSortLoading$ | async"></app-default-loading-page>
                    <div *ngIf="articlesService.searchResult !=undefined && articlesService.searchResult?.count == 0">
                        <app-no-results></app-no-results>
                    </div>
                    <div *ngIf="articlesService.selectedCategory == undefined && !articlesService.searchResult && !articlesService?.articlesLoading">
                        <app-search-box (_onSearch)="onTreeNodeClick($event)"
                                        [(searchOpen)]="articlesService.searchOpen"
                                        [searchString]="searchString" [searchIn]="articlesService.articleTree"
                                        class="overflow-off"></app-search-box>
                        <app-assembly-categories [ngClass]="{'move': articlesService.searchOpen}"
                                                 class="search-box-swing"></app-assembly-categories>
                    </div>
                    <div *ngIf="articlesService?.selectedCategory || articlesService?.selectedGenArt || articlesService.searchResult">
                        <div>
                            <app-default-loading-page
                                    *ngIf="articlesService.articlesLoading"></app-default-loading-page>
                            <app-articles-search-result [service]="articlesService"
                                                        *ngIf="!articlesService.articlesLoading"></app-articles-search-result>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="right-panel no-float" [ngClass]="userService?.getNavigation()"
             [@sidebar]="( articlesService.expanded_right ? 'in': '' )">
            <div class="panel-content right-sidebar detailWidth">
                <app-default-loading-page *ngIf="articlesService.additionalDetailsLoading"></app-default-loading-page>

                <div class="inner-content">
                    <app-article-details [showBelongingWork]="true" [showArtikelLists]="true"
                                         [service]="articlesService"></app-article-details>
                    <app-car-details
                            *ngIf="!articlesService.selectedArticle && carService.selectedCar"></app-car-details>
                    <ng-container *ngIf="!articlesService.selectedArticle && !carService.selectedCar">
                        <div class="right-panel-empty">
                            <span>{{ 'SPAREPARTS_VIEW.DETAILS' | translate }}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <app-content-overlay [showContent]="stateService.articleContent | async"></app-content-overlay>

    <ng-container *ngIf="articlesService.showGallery">
        <app-gallery [title]="articlesService.selectedArticle.description"
                     [selectedImage]="articlesService.selectedImage" [documentList]="articlesService.filterImages()"
                     (closeGallery)="articlesService.showGallery = false"></app-gallery>
    </ng-container>
</div>


<div class="legend-overlay" *ngIf="showLegend">
    <div class="conf-modal " #overlay>
        <div class="modal-header p-3">
            <div class="d-flex p-1 align-items-center">
                <i class="fa fa-info-circle mx-2"></i>
                <h1 class="m-0">{{ 'SPAREPARTS_VIEW.LEGEND' | translate }}</h1>
            </div>
        </div>
        <div class="modal-content p-3">
            <div class="alert alert-info">
                {{ 'SPAREPARTS_VIEW.LEGEND_TEXT' | translate }}
            </div>

            <div *ngFor="let item of availabilityArray;" class="availability mt-2 ml-2" [ngClass]="item.availability">
                <div class="icon"></div>
                <span class="text border-left ml-3 pl-3 ">{{ 'AVAILABILITY_STATES.' + item.availability | translate }}</span>
            </div>
        </div>
        <div class="modal-footer p-3">
            <div class="base-btn" (click)="showLegend= false">{{ 'PROMPTS.BUTTONS.CLOSE' | translate }}</div>
        </div>
    </div>
</div>
