import {Component, ElementRef, OnInit} from '@angular/core';
import {Location} from '@angular/common'
import {TemplateService} from './services/template.service';
import {UserService} from '../../../../shared/services/user/user.service';
import {AdminService} from '../../services/admin.service';
import {Router} from '@angular/router';
import {HelperService} from '../../../../shared/services/helper.service';

@Component({
    selector: 'app-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.scss'],
    host: {
        '(document:click)': 'onOutsideClick($event)',
    }
})
export class TemplateComponent implements OnInit {

    public showTemplateMenu: boolean = false;

    constructor(public location: Location,
                public templateService: TemplateService,
                private userService: UserService,
                public adminService: AdminService,
                private _eref: ElementRef,
                private router: Router,
                public helperService: HelperService) {
    }

    ngOnInit() {
        this.userService.accountData$.subscribe(() => {
            if (this.templateService.templateType) {
                this.templateService.getTemplateList();
            }
        });

        if (!this.location.path().endsWith('/template')) {
            this.templateService.templateType = this.location.path().substring(this.location.path().lastIndexOf('/') + 1);
            this.templateService.getTemplateList();
        } else {
            this.templateService.templateType = undefined;
        }
    }

    public getCreateLink() {
        if (this.userService.allow('GLOBAL-ADMIN') && this.adminService.club) {
            return '/admin/template/create/' + this.templateService.templateType + '/' + this.adminService.club.clubId;
        } else {
            return '/admin/template/create/' + this.templateService.templateType + '/';
        }
    }

    public createTemplate(templateType: string) {
        this.templateService.templateType = templateType;
        this.router.navigate([this.getCreateLink()]);
    }

    private onOutsideClick(event: any) {
        if (!this._eref.nativeElement.contains(event.target)) {
            this.showTemplateMenu = false;
        }
    }
}
