<div class="club-notifications">
    <div class="heading blue">
        <span class="clickable">
            <i class="fa fa-th" aria-hidden="true"></i>
        </span>
        <span>{{'CLUB_ADMIN.NOTIFICATIONS' | translate}}</span>
    </div>
    <div class="panel-content center-content white-background">
        <div class="club-setting-title">
            <h1>
                <strong>
                    {{'CLUB_ADMIN.CREATE_NOTIFICATION' | translate}}
                </strong>
            </h1>
        </div>

        <div class="create-notification" *ngIf="notificationTypes && notificationTypes.length > 0">
            <div class="row">
                <div class="col-3">
                    <app-dropdown [items]="notificationTypes"
                                  (onItemSelect)="selectedNotificationType = $event"
                                  [translationPrefix]="'NOTIFICATION.TYPE'"
                                  [placeholderText]="'USER_PROFILE.PLEASE_SELECT' | translate">
                    </app-dropdown>
                </div>
                <div class="col-7">
                    <app-material-form-input [label]="'NOTIFICATION.LABEL' | translate"
                                             (textChange)="notificationDescription = $event">
                    </app-material-form-input>
                </div>
                <div class="col-2 text-center">
                    <app-checkbox [isChecked]="showDirect"
                                  (onChange)="showDirect = $event"
                                  [labelText]="'NOTIFICATION.SHOW_DIRECT' | translate">
                    </app-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-1 text-center notification-label">
                    <span>{{'NOTIFICATION.START_DATE' | translate}}</span>
                </div>
                <div class="col-5">
                    <form class="form-inline">
                        <div class="form-group">
                            <div class="input-group">
                                <input class="form-control" autofocus placeholder="dd.mm.yyyy" name="dp1" [ngModel]="dateFrom"
                                       (ngModelChange)="dateFrom = $event"
                                       ngbDatepicker #d1="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-1 text-center notification-label">
                    <span>{{'NOTIFICATION.END_DATE' | translate}}</span>
                </div>
                <div class="col-5">
                    <form class="form-inline">
                        <div class="form-group">
                            <div class="input-group">
                                <input class="form-control" autofocus placeholder="dd.mm.yyyy" name="dp2" [ngModel]="dateTo"
                                       (ngModelChange)="dateTo = $event"
                                       ngbDatepicker #d2="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row" *ngIf="notificationDescription?.length > 0 && selectedNotificationType">
                <div class="col">
                    <button class="base-btn success" (click)="createNewNotification()">
                        <i class="fa fa-check mr-2"></i>
                        <span>{{'NOTIFICATION.ADD_NOTIFICATION' | translate}}</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="club-setting-title">
            <h1>
                <strong>
                    {{'CLUB_ADMIN.ACTIVE_NOTIFICATIONS' | translate}}
                </strong>
            </h1>
        </div>

        <div class="active-notifications">
            <ng-container *ngIf="notificationHolder?.length > 0">
                <div class="row notification-row {{notification.type}}" *ngFor="let notification of getNotificationsForThisClub()">
                    <div class="col-11 notification-message">
                        <span>{{notification.description}}</span>
                    </div>
                    <div class="col-1 notification-option text-center clickable" (click)="deleteNotification(notification)">
                        <i class="fa fa-trash"></i>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!(getNotificationsForThisClub()?.length > 0)">
                <div class="alert alert-info text-center">
                    <i class="fa fa-info-circle mr-2"></i>
                    <span>{{'NOTIFICATION.NO_NOTIFICATION' | translate}}</span>
                </div>
            </ng-container>
        </div>
    </div>
</div>
