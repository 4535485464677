import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CartService} from '../services/cart.service';
import {ICartListItem} from '../interfaces/cart-list-item';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {PdfService} from '../../shared/services/pdf.service';
import {UserService} from '../../shared/services/user/user.service';
import {ConfigService} from '../../shared/services/config.service';
import {CarService} from '../../shared/services/car/car.service';
import {ICartLite} from '../../shared/interfaces/DTO/cart/cartLite';
import {IOrderLite} from '../../shared/interfaces/DTO/order/order';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-cart-list',
    templateUrl: './cart-list.component.html',
    styleUrls: ['./cart-list.component.scss']
})
export class CartListComponent implements OnInit, OnDestroy, AfterViewInit {
    private newCartName: string;
    private selectedCart: any;
    public cartList: ICartLite[];
    public cartOrderList: IOrderLite[];
    public searchString: string = '';
    public orderSearchString: string = '';
    private cartListSubscriber: Subscription;
    private cartOrderListSubscriber: Subscription
    private lastOrder: IOrderLite;
    private lastCart: ICartListItem;

    @ViewChild('tabs', {static: true}) tabs;

    constructor(
        public cartService: CartService,
        public pdfService: PdfService,
        private router: Router,
        public location: Location,
        public userService: UserService,
        public configService: ConfigService,
        private carService: CarService
    ) {
    }

    ngOnInit() {
        this.cartService.updateCartList();
        this.cartService.updateOrderListForCart(0, 20);
        this.cartList = this.cartService.cartList;
        if (this.cartService.cartOrderList && this.cartService.cartOrderList.orders) {
            this.cartOrderList = this.cartService.cartOrderList.orders;
        }
        this.cartListSubscriber = this.cartService.hasNewCartList.subscribe((cartList) => {
            this.cartList = cartList;
            this.searchString = '';
            if (this.cartService.currentCart) {
                this.lastCart = {
                    created: undefined,
                    id: this.cartService.currentCart.id,
                    name: this.cartService.currentCart.name
                }
            }
        });
        this.cartOrderListSubscriber = this.cartService.hasNewCartOrderList.subscribe((cartOrderList) => {
            this.cartOrderList = cartOrderList;
            this.orderSearchString = '';
        });
        this.cartService.resetTabsEmitter.subscribe(() => {
            this.tabs.select('cart-tab');
        });
    }

    ngOnDestroy() {
        this.cartListSubscriber.unsubscribe();
        this.cartOrderListSubscriber.unsubscribe();
    }

    ngAfterViewInit() {
        if (this.cartService.isOrder()) {
            if (this.cartService.previewCart) {
                this.lastOrder = {
                    created: undefined,
                    id: this.cartService.previewCart.id,
                    loginId: 0,
                    loginName: '',
                    name: this.cartService.previewCart.name,
                    platformOrderId: '',
                    reference: ''
                }
            }
            this.tabs.select('orders-tab');
        }
        if (this.cartService.currentCart) {
            this.lastCart = {
                created: undefined,
                id: this.cartService.currentCart.id,
                name: this.cartService.currentCart.name
            }
        }
    }

    public createNewCart(): void {
        this.cartService.createCart();
    }

    public onTabChange() {
        if (this.tabs.activeId === 'orders-tab') {
            if (this.lastCart) {
                this.selectCart(this.lastCart);
            }
        } else if (this.tabs.activeId === 'cart-tab') {
            if (this.lastOrder) {
                this.showOrderPreview(this.lastOrder);
            }
        }
    }

    public getCartPage(page: number) {
        this.cartService.cartPage = (page - 1);
        this.cartService.updateCartList();
    }

    public getCartOrderPage(page: number) {
        this.cartService.updateOrderListForCart(page - 1, this.cartService.cartOrderList.pageSize,
            this.cartService.cartOrderList.startDate, this.cartService.cartOrderList.endDate);
    }

    public reduceCartTree(searchString: string) {
        this.searchString = searchString;
        if (this.searchString.length > 0) {
            const tempCart: ICartLite[] = [];

            this.cartService.cartList.forEach(function (cart) {
                if (cart.name.toUpperCase().indexOf(searchString.toUpperCase()) > -1) {
                    tempCart.push(cart);
                }
            });

            this.cartList = tempCart;
        } else {
            this.cartList = this.cartService.cartList;
        }
    }

    public reduceCartOrderTree(orderSearchString: string) {
        this.orderSearchString = orderSearchString;
        if (this.orderSearchString.length > 0) {
            const tempCart: IOrderLite[] = [];

            this.cartService.cartOrderList.orders.forEach(function (order) {
                if (order?.name?.toUpperCase().indexOf(orderSearchString.toUpperCase()) > -1) {
                    tempCart.push(order);
                }
            });

            this.cartOrderList = tempCart;
        } else {
            this.cartOrderList = this.cartService.cartOrderList.orders;
        }
    }

    public selectCart(cart: ICartListItem) {
        this.carService.ejectCar();
        this.selectedCart = cart;
        this.lastCart = cart;
        this.cartService.history = false;
        this.router.navigate(['/cart']);
        this.newCartName = cart.name;
        this.cartService.selectCart(cart.id, true, false);
    }

    public selectOrderCart(order: IOrderLite) {
        this.carService.ejectCar();
        this.selectedCart = order;
        this.cartService.history = false;
        this.router.navigate(['/cart']);
        this.newCartName = order.name;
        this.cartService.selectCart(order.id, true, true);
    }

    public showOrderPreview(order: IOrderLite) {
        this.cartService.previewCart = null;
        this.lastOrder = order;
        this.cartService.switchToCart();
        this.cartService.updateCart(false, false, true, true, order.id, true);
    }

}
