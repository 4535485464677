import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit} from '@angular/core';
import {MaintenanceService} from '../services/maintenance.service';
import {ArticlesService} from '../../articles/services/articles.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {detailAnim, itemAnimation, itemSelected} from '../../shared/animations/general.animation';
import {CartService} from '../../cart/services/cart.service';
import {HelperService} from "../../shared/services/helper.service";
import {UserService} from "../../shared/services/user/user.service";
import {DmsDataService} from '../../shared/services/dms-data.service';
import {ConfigService} from '../../shared/services/config.service';
import {AutovistaService} from "../../graphical-part-search/services/autovista.service";

@Component({
    selector: 'app-maintenance-data',
    templateUrl: './maintenance-data.component.html',
    styleUrls: ['./maintenance-data.component.scss'],
    host: {
        '(document:click)': 'onOutsideClick($event)'
    },
    animations: [detailAnim, itemAnimation, itemSelected]
})
export class MaintenanceDataComponent implements OnInit, AfterViewInit, AfterViewChecked {

    @Input() public service: any;
    private panelContentOffsetTop: number;
    public showScrollButton: boolean = false;

    public showAdditionalWork: boolean;
    public activeMaintenanceStep: any;
    public selectedTab: any;
    public tabChanged: boolean = false;

    public showArticleSize: boolean = false;
    public showSearchResultSupplier: boolean = false;

    constructor(public maintenanceService: MaintenanceService,
                public articlesService: ArticlesService,
                public location: Location,
                public route: ActivatedRoute,
                private _eref: ElementRef,
                public router: Router,
                public dmsDataService: DmsDataService,
                public cartService: CartService,
                public helperService: HelperService,
                public userService: UserService,
                public configService: ConfigService,
                public autovistaService: AutovistaService,
                private changeDetectorRef: ChangeDetectorRef) {

        this.maintenanceService.updateFilterBar.subscribe(() => {
            this.maintenanceService.getFilterGroupNames();
        });

        this.helperService.tabChanged.subscribe(change => {
            // workaround for MSP 3683,
            // durch ein ngIf wird das Searchresult aus dem DOM entfernt, dadurch wird im Infinitescroller der NgOnDestroy ausgelöst, welches die Scrollsubscriptions beendet.
            this.tabChanged = true;
            setTimeout(() => {
                this.tabChanged = false;
            }, 250)
        })
    }

    ngOnInit(): void {

        this.maintenanceService.lastState = '/maintenance/plan';
    }

    ngAfterViewInit(): void {
        if (document.getElementById('panelContent')) {
            this.panelContentOffsetTop = document.getElementById('panelContent').offsetTop + 5;
        }
    }

    ngAfterViewChecked(): void {
        let showAdditionalWork = this.maintenanceService.showAdditionalWork;
        let activeMaintenanceStep = this.maintenanceService.activeMaintenanceStep;
        let selectedTab = this.maintenanceService.selectedTab;

        if (showAdditionalWork !== this.showAdditionalWork) {
            this.showAdditionalWork = showAdditionalWork;
            if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        }

        if (activeMaintenanceStep !== this.activeMaintenanceStep) {
            this.activeMaintenanceStep = activeMaintenanceStep;
            if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        }

        if (selectedTab !== this.selectedTab) {
            this.selectedTab = selectedTab;
            if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    public setArticleSize(newSize: string): void {
        this.articlesService.setSelectedSearchResultSize(newSize);
        this.showArticleSize = false;
    }

    public setSearchResultSupplier(type: string, supplier: string): void {
        if (type === 'AFTER_MARKET') {
            this.maintenanceService.selectedSearchResultSupplier = supplier;
        } else {
            this.maintenanceService.selectedSearchResultOeSupplier = supplier;
        }

        this.maintenanceService.getAavailability(true, type);
        this.showSearchResultSupplier = false;
    }

    public onOutsideClick(event: any): void {
        if (!this._eref.nativeElement.contains(event.target)) {
            this.showSearchResultSupplier = false;
            this.showArticleSize = false;
        }
    }

    public printIframe(frameId: string) {
        const frame = window.frames[frameId];
        if (!frame) {
            return;
        }

        if (this.helperService.browserIsIE()) {
            frame.document.execCommand('print', false, null);
        } else {
            const frames: any = document.getElementById(frameId);
            frames.contentWindow.focus();
            frames.contentWindow.print();
        }
    }

    public toggleAdditionalWork(work: any, event: boolean): void {
        this.userService.setNavigationRight();
        if (event) {
            this.maintenanceService.addWorkToCart(work, true);
            this.maintenanceService.showModal = false;
        } else {
            this.maintenanceService.removeWorkFromCart(this.maintenanceService.isChecked(work));
            this.maintenanceService.selectedArticle = undefined;
            this.maintenanceService.showAdditionalWork = true;
            this.maintenanceService.showModal = false;
        }
    }
}
