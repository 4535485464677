import {Component, OnInit} from '@angular/core';
import {FirmAdminRestService} from '../../../shared/services/firm-admin.rest.service';
import {IExternalFirmTools} from '../../../shared/interfaces/DTO/firm/external-tools';
import {ConfigService} from '../../../shared/services/config.service';
import {Router} from '@angular/router';
import {AdminRestService} from '../../../shared/services/admin.rest.service';
import {AdminService} from '../../club/services/admin.service';
import {finalize} from 'rxjs/operators';
import {HelperService} from '../../../shared/services/helper.service';
import {Location} from '@angular/common';
import {UserService} from '../../../shared/services/user/user.service';

@Component({
    selector: 'app-external-tools',
    templateUrl: './external-firm-tools.component.html',
    styleUrls: ['./external-firm-tools.component.scss']
})
export class ExternalFirmToolsComponent implements OnInit {

    public externalTools: IExternalFirmTools[];
    public showDeleteTool: string;

    constructor(private firmAdminRest: FirmAdminRestService,
                private helperService: HelperService,
                public configService: ConfigService,
                private userService: UserService,
                private router: Router,
                private adminRestService: AdminRestService,
                public location: Location,
                private adminService: AdminService) {
    }

    ngOnInit() {
        if (!this.configService.selectedExternalTool) {
            this.getExternalTools();
        }
    }

    public editTool(tool: IExternalFirmTools) {
        this.configService.selectedExternalTool = tool;
        if (!this.configService.externalToolAsAdmin) {
            this.router.navigate(['/company-admin/tools/edit']);
        } else {
            this.router.navigate(['/admin/tools/edit']);
        }

    }

    private getExternalTools() {
        const request = this.configService.externalToolAsAdmin ?
            this.adminRestService.getExternalTools(this.adminService.selectedFirm.firmId)
            : this.firmAdminRest.getExternalTools();

        request.subscribe(
            (externalTools: IExternalFirmTools[]) => {
                this.externalTools = externalTools;
            }, (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET', 'error');
            });
    }

    public deleteTool(tool: IExternalFirmTools) {
        const request = this.configService.externalToolAsAdmin ?
            this.adminRestService.deleteExternalTool(tool.id)
            : this.firmAdminRest.deleteExternalTool(tool.id);

        request
            .pipe(finalize(
                () => {
                    this.showDeleteTool = undefined;
                    this.getExternalTools();
                }))
            .subscribe(
                (success) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE', 'success');
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE', 'error');
                });
    }
}
