<div class="graphicalPartSearch">
  <ng-container *ngIf="(stateService.graphicalPartSearchContent | async) === 'CONTENT'">
    <div class="content sidebar-opener-wrapper">
      <div class="sidebar-opener" [ngClass]="userService?.getNavigation()">
        <ul>
          <li (click)="showNavigation()">
            {{ 'GENERAL.NAVIGATION.NAVIGATION' | translate }}
          </li>
          <li (click)="showFilter()" [ngClass]="{'disabled':!hasFilter()}">
            {{ 'AUTOVISTA.EQUIPMENT' | translate }}
          </li>
          <li (click)="userService?.toggleNavigation()">
            <i class="fa fa-chevron-right"></i>
          </li>
        </ul>
      </div>
      <!-- Left Panel -->
      <div class="left-panel no-float no-padding"  [ngClass]="userService?.getNavigation()">
        <div class="panel-content left-sidebar tree-sidebar">
          <div class="inner-content no-padding">
            <app-autovista-left-tree *ngIf="userService.bodygraphicsType === 'AUTOVISTA'"></app-autovista-left-tree>
            <ng-container *ngIf="userService.bodygraphicsType === 'TEC_RMI'">
              <ul class="custom-tree">
                <li class="list" (click)="getCarOverView()">
                  <i class="folder fa fa-circle open"></i>
                  <span>{{'AUTOVISTA.TITLE' | translate}}</span>
                </li>
              </ul>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- Center Panel -->
      <div class="center-panel no-float"  [ngClass]="userService?.getNavigation()">
        <div class="heading blue">
          <span class="clickable" (click)="getCarOverView()">
            <i class="fa fa-th"></i>
            <span>{{'AUTOVISTA.TITLE' | translate}}</span>
          </span>
          <span class="clickable" (click)="getCarOverView()" *ngIf="autovistaService?.showBodygraphics && autovistaService?.selectedCar && !(autovistaService?.mcGroups?.length > 0)">
            <i class="fa fa-caret-right"></i>
            <span>{{'AUTOVISTA.CAR_OVERVIEW' | translate}}</span>
          </span>
          <span *ngIf="autovistaService?.showBodygraphics && autovistaService?.selected && !(autovistaService?.mcGroups?.length > 0)" style="cursor: default">
            <i class="fa fa-caret-right"></i>
            <span>{{getSelectedName()?.name}}</span>
          </span>

          <span *ngIf="autovistaService?.showBodygraphics && autovistaService?.selectedCar && autovistaService?.mcGroups?.length > 0 && autovistaService?.selectedGroup?.name" (click)="autovistaService.selectedSubgroup = undefined; autovistaService.oldXML = undefined; autovistaService.subGroupDetails = undefined">
            <i class="fa fa-caret-right"></i>
            <span class="clickable">{{autovistaService.selectedGroup.name}}</span>
          </span>

          <span *ngIf="autovistaService?.showBodygraphics && autovistaService?.selectedCar && autovistaService?.mcGroups?.length > 0 && autovistaService?.selectedSubgroup?.name" (click)="autovistaService.oldXML = undefined">
            <i class="fa fa-caret-right"></i>
            <span class="clickable">{{autovistaService.selectedSubgroup.name}}</span>
          </span>
        </div>

        <app-autovista *ngIf="autovistaService.showBodygraphics && userService.bodygraphicsType === 'AUTOVISTA'"></app-autovista>
        <app-bodygraphics *ngIf="carService.getAdcTypeId() > 0 && userService.bodygraphicsType === 'TEC_RMI'"></app-bodygraphics>

        <div class="panel-content center-content white-background" *ngIf="!autovistaService.showBodygraphics
        && userService.bodygraphicsType === 'AUTOVISTA' && autovistaService.imageLoading">
          <app-default-loading-page *ngIf="autovistaService?.imageLoading"></app-default-loading-page>
        </div>

        <div class="panel-content center-content white-background" *ngIf="!autovistaService.showBodygraphics
        && !autovistaService.imageLoading && userService.bodygraphicsType === 'AUTOVISTA' && !autovistaService.selectedCar">
          <app-default-loading-page *ngIf="autovistaService?.imageLoading"></app-default-loading-page>
            <div class="alert alert-info text-center">
              <i class="fa fa-info-circle mr-2"></i>
              <span>{{'AUTOVISTA.NO_SELECTED_VARIANT' | translate}}</span>
            </div>
            <button class="base-btn float-right" (click)="autovistaService.getCarList()">{{'AUTOVISTA.SHOW_VARIANTS' | translate}}</button>
        </div>
      </div>

      <!-- Right Panel -->
      <div class="right-panel no-float"  [ngClass]="userService?.getNavigation()">
        <div class="panel-content right-sidebar" [ngClass]="{'white-background': autovistaService.showBodygraphics && autovistaService.selectedAutovistaDetails}">
          <div class="inner-content">
            <app-default-loading-page *ngIf="autovistaService.loadDetails && !autovistaService.selectedAutovistaDetails"></app-default-loading-page>
            <app-autovista-details *ngIf="autovistaService.showBodygraphics && autovistaService.selectedAutovistaDetails"></app-autovista-details>
            <app-car-details *ngIf="carService.selectedCar && !(autovistaService.selectedAutovistaDetails)"></app-car-details>
          </div>
        </div>
      </div>
    </div>

    <!-- Autovista carList popup -->
    <div class="autovista-carList" *ngIf="userService.bodygraphicsType === 'AUTOVISTA' && autovistaService.selectCarPrompt" (click)="autovistaService.selectCarPrompt = false;">
      <div class="carList" [ngClass]="{'info': !(autovistaService?.carList?.length > 0)}" (click)="$event.stopPropagation()">
        <div class="header">
          <i class="fa fa-car"></i>
          <span class="title" *ngIf="autovistaService?.carList?.length > 0 || autovistaService?.loadCarList">{{ 'AUTOVISTA.SELECT_CAR' | translate}}</span>
          <span class="title" *ngIf="!(autovistaService?.carList?.length > 0) && !autovistaService.loadCarList">{{ 'AUTOVISTA.INFORMATION' | translate }}</span>

          <i class="fa fa-times" (click)="autovistaService.selectCarPrompt = false"></i>
        </div>
        <app-default-loading-page class="modal-spinner" *ngIf="autovistaService.loadCarList"></app-default-loading-page>

        <ul *ngIf="autovistaService?.carList?.length > 0">
          <li class="list" *ngFor="let car of autovistaService.carList">
                                <span class="car" (click)="this.autovistaService.selected = undefined;
                                autovistaService.selectedCar = undefined;
                                autovistaService.imageLoading = true;
                                autovistaService.XML = undefined;
                                autovistaService.oldXML = undefined;
                                autovistaService.getCarOverview(car)">
                                  {{car.name}} <span class="no-padding" *ngIf="car.numberOfDoors">({{car.numberOfDoors}}{{'AUTOVISTA.DOORS' | translate}})</span> {{car.transmissionType}}
                                  <div class="text-right d-inline-block float-right">
                                    {{convertDate(car.constructionFrom)}}
                                    <ng-container *ngIf="car.constructionTo">
                                      - {{convertDate(car.constructionTo)}}
                                    </ng-container>
                                    <ng-container *ngIf="!car.constructionTo">
                                      - {{'SPAREPARTS_VIEW.CAR_DETAILS.TODAY' | translate}}
                                    </ng-container>
                                  </div>
                                </span>
          </li>
        </ul>

        <div class="noResult text-center" *ngIf="!(autovistaService?.carList?.length > 0) && !autovistaService.loadCarList">
          <div class="alert alert-info text-center">
            <i class="fa fa-info-circle mr-2"></i>
            {{ 'AUTOVISTA.NOT_AVAIABLE' | translate }}
          </div>

          <button class="base-btn" (click)="autovistaService.selectCarPrompt = false">{{ 'PROMPTS.BUTTONS.OK' | translate }}</button>
        </div>
      </div>
    </div>
  </ng-container>

  <app-content-overlay [showContent]="stateService.graphicalPartSearchContent | async" [newRoute]="'/graphical-part-search'"></app-content-overlay>
</div>
