import {Component, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalAdminService} from '../services/global-admin.service';
import {AdminService} from '../../club/services/admin.service';
import {IVinResponses, IVinStatisticRequest} from '../interfaces/vinStatistic';
import {GlobalAdminRestService} from '../services/global-admin.rest.service';
import {HelperService} from '../../../shared/services/helper.service';
import {IActivatedStatistic, IActivatedStatisticRequest} from '../interfaces/activatedStatistic';
import {AutovistaRestService} from '../../../graphical-part-search/services/autovista.rest.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-statistics-club',
    templateUrl: './statistics-club.component.html',
    styleUrls: ['./statistics-club.component.scss']
})
export class StatisticsClubComponent implements OnDestroy {
    @ViewChild('dt1', {static: true}) owlDatePicker: any;

    public club: any;
    public selectedFrom: Date;
    public selectedTo: Date;
    private newDate: Date;
    public activatedStatistc: IActivatedStatistic;
    private clubsLoadingSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public adminService: AdminService,
        public globalAdminService: GlobalAdminService,
        private globalAdminRestService: GlobalAdminRestService,
        public autovistaRestService: AutovistaRestService,
        public helperService: HelperService) {
        this.newDate = new Date();
        this.route.params.subscribe(
            (params) => {
                this.globalAdminService.selectedClubId = +params['id'];
                this.getClub();
            }
        );
        if (!this.adminService.clubs) {
            this.clubsLoadingSubscription = this.adminService.clubsLoaded.subscribe(
                (val) => {
                    this.getClub();
                }
            );
        }
    }


    ngOnDestroy() {
        this.globalAdminService.selectedClubId = null;
        if (this.clubsLoadingSubscription) {
            this.clubsLoadingSubscription.unsubscribe();
        }
    }

    public getFreeMonthStatistic() {
        const activatedStatisticRequest: IActivatedStatisticRequest = {
            clubId: this.globalAdminService.selectedClubId,
            skipMonths: 2
        };

        this.globalAdminRestService.getFreeMonthStatisticRest(activatedStatisticRequest).subscribe(
            (activatedStatistic: IActivatedStatistic) => {
                this.activatedStatistc = activatedStatistic;
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET_FREEMONTH_STATISTIC', 'error');
            })
    }

    public getVinRequestsForDate(date: any) {
        if (date && date._selecteds && date._selecteds.length > 0) {
            const statistic: IVinStatisticRequest = {
                clubId: this.club.clubId,
                from: date._selecteds[0].getFullYear() + '-' + this.checkBinary(date._selecteds[0].getMonth() + 1) + '-01',
                to: date._selecteds[1].getFullYear() + '-' + this.checkBinary(date._selecteds[1].getMonth() + 1) + '-'
                    + this.getLastDayOfMonth(date._selecteds[1].getFullYear(), date._selecteds[1].getMonth() + 1).getDate()
            };

            this.selectedFrom = date._selecteds[0];
            this.selectedTo = date._selecteds[1];
            this.globalAdminService.getAutovistaVinStatistic(statistic);
        }
    }

    public getVinCSVStatistc() {
        const statistic: IVinStatisticRequest = {
            clubId: this.club.clubId,
            from: this.selectedFrom.getFullYear() + '-' + this.checkBinary(this.selectedFrom.getMonth() + 1) + '-01',
            to: this.selectedTo.getFullYear() + '-' + this.checkBinary(this.selectedTo.getMonth() + 1) + '-'
                + this.getLastDayOfMonth(this.selectedTo.getFullYear(), this.selectedTo.getMonth() + 1).getDate()
        };

        return statistic;
    }

    public getFullCount(firm: IVinResponses) {
        let count = 0;

        for (const month of firm.monthlyRequests) {
            count += month.count;
        }

        return count;
    }

    private getLastDayOfMonth(year: number, month: number) {
        return new Date(year, month, 0);
    }

    private checkBinary(number: number) {
        return number >= 10 ? number.toString() : '0' + number;
    }

    private getClub() {
        if (this.adminService.clubs) {
            for (const _club of this.adminService.clubs) {
                if (_club.clubId === this.globalAdminService.selectedClubId) {
                    this.club = _club;
                    this.adminService.getClubStatisticInfos(this.club.clubId);
                    this.getFreeMonthStatistic();

                    if (this.owlDatePicker && this.owlDatePicker._selecteds && this.owlDatePicker._selecteds.length > 0) {
                        this.getVinRequestsForDate(this.owlDatePicker);
                    } else {
                        const statistic: IVinStatisticRequest = {
                            clubId: this.club.clubId,
                            from: this.newDate.getFullYear() + '-' + this.checkBinary(this.newDate.getMonth() + 1) + '-01',
                            to: this.newDate.getFullYear() + '-' + this.checkBinary(this.newDate.getMonth() + 1) + '-'
                                + this.getLastDayOfMonth(this.newDate.getFullYear(), this.newDate.getMonth() + 1).getDate()
                        };
                        this.selectedFrom = new Date(statistic.from);
                        this.selectedTo = new Date(statistic.to);
                        this.globalAdminService.getAutovistaVinStatistic(statistic);
                    }
                }
            }
        }
    }

}
