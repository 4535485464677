import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../../shared/services/config.service';
import { GlobalAdminService } from 'app/admin/global/services/global-admin.service';

@Component({
  selector: 'app-external-tools',
  templateUrl: './external-tools.component.html',
  styleUrls: ['./external-tools.component.scss']
})
export class ExternalToolsComponent implements OnInit {

  constructor(public configService: ConfigService,
              public globalAdminService: GlobalAdminService) { }

  ngOnInit() {
  }

  public save() {
    this.configService.saveClubConfig();
  }

}
