<div class="order-data">
  <div class="heading blue" >
    <span class="clickable" (click)="orderHistoryService.reset()" routerLink="/company-admin/orderhistory">
      <i class="fa fa-th"></i>
      <span>{{'CART.ORDER_HISTORY' | translate}}</span>
    </span>
    <span class="clickable" (click)="orderHistoryService.setHistory(orderHistoryService?.selectedHistory)" routerLink="/company-admin/orderhistory/{{orderHistoryService?.selectedHistory}}" *ngIf="orderHistoryService?.selectedHistory !== 'orderhistory'">
      <i class="fa fa-caret-right"></i>
      <span>{{'CART.HISTORY.' + orderHistoryService?.selectedHistory?.toUpperCase() | translate}}</span>
    </span>
    <span class="clickable">
      <i class="fa fa-caret-right"></i>
      <span>{{orderHistoryService?.selectedCart?.name}} ({{ orderHistoryService?.selectedCart?.created | date:'dd.MM.yyyy' }})</span>
    </span>
  </div>
  <div class="panel-content center-content">

    <div class="table-container" [ngClass]="{ 'big-header': articlesService.showSellinPrices }" *ngIf="orderHistoryService?.cartData">
      <h1 class="orderDataHeader" *ngIf="orderHistoryService?.cartData?.reference">
        {{ 'CART.REFERENCE' | translate }}: <small>{{ orderHistoryService?.cartData?.reference }}</small>
      </h1>
      <h1 class="orderDataHeader" *ngIf="orderHistoryService?.cartData?.platformOrderId">
        {{'ORDER.ORDERNUMBER' | translate}} <small>{{orderHistoryService?.cartData?.platformOrderId}}</small>
      </h1>
      <h1 class="orderDataHeader" *ngIf="orderHistoryService?.cartData?.loginName">
        {{'SUPPLIERS.USERNAME' | translate}}: <small>{{orderHistoryService?.cartData?.loginName}}
        <span *ngIf="userService.allow('GLOBAL-ADMIN')">(ID: {{orderHistoryService?.cartData?.loginId}})</span></small>
      </h1>

    <table class="simple-table">
      <thead>
      <tr>
        <th width="25%">
          <div>{{ 'CART.MANUFACTURER' | translate }}</div>
          <div>{{ 'CART.ARTICLE_NUMBER' | translate }}</div>
          {{ 'CART.DESCRIPTION' | translate }}
        </th>
        <th width="20%" class="text-center">
          <div>{{'USER_PANEL.SUPPLIER' | translate}}</div>
        </th>
        <th></th>
        <th width="10%" class="text-center">{{ 'CART.ORDERED' | translate }}</th>
        <th width="15%" class="text-center">{{ 'CART.AMOUNT' | translate }}</th>
        <th width="15%" class="text-right">
          <div>{{ userService.sale? ('CART.SINGLE_PRICE_SALE' | translate ): ('CART.SINGLE_PRICE' | translate) }}</div>
        </th>
        <th width="15%" class="text-right">
            {{ userService.sale ? ('CART.TOTAL_PRICE_SALE' | translate) : ('CART.TOTAL_PRICE' | translate) }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr app-order-history-row [position]="position" *ngFor="let position of orderHistoryService?.cartData?.positions"></tr>
      <tr>
        <td colspan="6" class="text-right">
          {{ 'CART.SUM_NET' | translate }}
        </td>
        <td class="text-right"  style="padding-right: 15px !important;" *ngIf="userService.sale">
            {{ orderHistoryService?.cartData?.cartCostDTO.sellOutNetTotalFormatted}}
        </td>
          <td class="text-right"  style="padding-right: 15px !important;" *ngIf="!userService.sale">
              {{ orderHistoryService?.cartData?.cartCostDTO.sellInNetTotalFormatted}}
          </td>
      </tr>
      <tr>
        <td colspan="6" class="text-right">
          {{ 'CART.VAT' | translate }} {{ orderHistoryService?.cartData?.cartCostDTO.vat }} %
        </td>
        <td class="text-right"  style="padding-right: 15px !important;" *ngIf="userService.sale">
            {{ orderHistoryService?.cartData?.cartCostDTO.sellOutVatTotalTotalFormatted}}
        </td>
          <td class="text-right"  style="padding-right: 15px !important;" *ngIf="!userService.sale">
              {{ orderHistoryService?.cartData?.cartCostDTO.sellInVatTotalFormatted}}
          </td>
      </tr>
      <tr *ngIf="getDeliveryCostsKeys()?.length > 0 && !userService.sale">
        <td colspan="6" class="text-right">{{'CART.DELIVERY_COSTS' | translate}}</td><td></td>
      </tr>
      <tr *ngFor="let deliveyCosts of getDeliveryCostsKeys()">
        <td colspan="6" class="text-right">
          {{'SUPPLIERS.' + deliveyCosts | translate}}
        </td>
        <td class="text-right">
            {{orderHistoryService?.cartData?.cartCostDTO?.deliveryCosts[deliveyCosts]?.valueFormmatted}}
        </td>
      </tr>
      <tr>
        <td colspan="6" class="text-right">
          {{ 'CART.SUM' | translate }}
        </td>
        <td class="text-right"  style="padding-right: 15px !important;" *ngIf="userService.sale">
            {{ orderHistoryService?.cartData?.cartCostDTO.sellOutGrossTotalFormatted}}
        </td>
          <td class="text-right"  style="padding-right: 15px !important;" *ngIf="!userService.sale">
              {{ orderHistoryService?.cartData?.cartCostDTO.sellInGrossTotalFormatted}}
          </td>
      </tr>
      </tbody>
    </table>
    </div>
  </div>
</div>
