<div class="logo {{userService?.accountData?.language}} {{cartService.getCalculation()}} {{userService?.accountData?.country}}">
    <div class="heading blue">
    <span class="clickable" routerLink="/company-admin">
        <i class="fa fa-th" aria-hidden="true"></i>
        {{'ADMIN_PANEL.OVERVIEW' | translate}}
    </span>
        <span class="clickable" routerLink="/company-admin/settings">
        <i class="fa fa-caret-right"></i>
            {{'USER_PROFILE.SETTINGS' | translate}}
    </span>
        <span>
        <i class="fa fa-caret-right"></i>
            {{'USER_PROFILE.FIRM_LOGO' | translate}}
    </span>
    </div>
    <div class="panel-content center-content white-background">
        <div class="firmdataCSS" *ngIf="firmData">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col headerType">
                            <h1>{{'USER_PROFILE.FIRM_LOGO' | translate}}</h1>
                        </div>
                    </div>
                    <div class="row" *ngIf="configService.getActiveForName('GLOBAL_FIRM_LOGO', 'GLOBAL_FIRM_LOGO')">
                        <div class="col">
                            <span class="subHeader">{{'USER_PROFILE.LOGO_SETTINGS' | translate}}</span>
                            <hr/>
                        </div>
                    </div>
                    <div class="row spacer" *ngIf="configService.getActiveForName('GLOBAL_FIRM_LOGO', 'GLOBAL_FIRM_LOGO')">
                        <div class="col">
                            <span>
                                {{'USER_PROFILE.GLOBAL_LOGO_DISCLAIMER' | translate}}
                            </span>
                        </div>
                    </div>
                    <div class="row spacer" *ngIf="!configService.getActiveForName('GLOBAL_FIRM_LOGO', 'GLOBAL_FIRM_LOGO')">
                        <div class="col-12">
                            <div class="row">
                                <div class="col">
                                    <app-image-cropper *ngIf="imgUrl" [src]="imgUrl" [parent]="'logo'"></app-image-cropper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!configService.getActiveForName('GLOBAL_FIRM_LOGO', 'GLOBAL_FIRM_LOGO')">
                        <div class="col-6">
                            <form [formGroup]="form" (ngSubmit)="saveFirm()">
                                <div class="form-group">
                                    <input type="file" id="avatar" accept="image/png, image/jpeg" (change)="onFileChange($event)" class="file-input" #fileInput>
                                </div>
                                {{'USER_PROFILE.LOGO_SIZE' | translate : {width: 200, height: 100, size: 1.5} }} <br/><br/>
                            </form>
                        </div>
                        <div class="col-6" *ngIf="firmData?.firmLogo?.value && !imgUrl">
                            <img id="currentLogo" [src]="[firmData.firmLogo.value]">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="subHeader">{{'USER_PROFILE.FOOTNOTE' | translate}}</span>
                            <hr/>
                        </div>
                    </div>
                    <div class="row spacer">
                        <div class="col">
                            <div class="text-right">{{'USER_PROFILE.FOOTNOTE_SYMBOLS' | translate:{'length': getFootNote()?.value?.length ? getFootNote()?.value?.length : 0, 'maxLength': maxLength} }}</div>
                            <app-material-form-input [maxLength]="maxLength" [placeholder]="'USER_PROFILE.FOOTNOTE_PLACEHOLDER' | translate: {'maxLength': maxLength}" [text]="getFootNote()?.value" (textChange)="setFootNote($event)"></app-material-form-input>
                        </div>
                    </div>
                    <button *ngIf="companyAdminService.hasLogoChanges" (click)="saveFirm()" type="submit" [disabled]="loading" class="base-btn success" ><i class="fa fa-spinner fa-fw" [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}" *ngIf="loading"></i> <i class="fa fa-check" *ngIf="!loading"></i> {{ 'PROMPTS.BUTTONS.SAVE_CHANGES' | translate }} </button>
                </div>
            </div>
        </div>
        <app-error-handler *ngIf="errorService.newError"></app-error-handler>
    </div>
</div>
