import {Component, OnInit, Input} from '@angular/core';
import {ICartPosition} from "../../../../cart/interfaces/cart";
import {UserService} from '../../../../shared/services/user/user.service';
import {HelperService} from '../../../../shared/services/helper.service';

@Component({
    selector: '[app-order-history-row]',
    templateUrl: 'order-row.component.html',
    styleUrls: ['order-row.component.scss']
})
export class OrderRowComponent implements OnInit {
    @Input() public position: ICartPosition;

    public symbol: any;
    constructor(public userService: UserService,
                public helperService: HelperService) {
    }

    ngOnInit() {
    }

}
