import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CarRestService} from '../../../services/car/car.rest.service';
import {CarService} from '../../../services/car/car.service';
import {HelperService} from '../../../services/helper.service';
import {ICar} from '../../../interfaces/DTO/car/car';
import {UserService} from '../../../services/user/user.service';

@Component({
    selector: 'app-car-by-ktypeid',
    templateUrl: './car-by-ktypeid.component.html'
})
export class CarByKtypeidComponent implements OnInit, OnChanges {
    @Output() applyCars: EventEmitter<ICar[]> = new EventEmitter();
    @Input() kTypeId: number;
    public text: any;

    constructor(private carRestService: CarRestService,
                public carService: CarService,
                public helperService: HelperService,
                public userService: UserService) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.kTypeId.currentValue === undefined) {
            this.kTypeId = changes.kTypeId.previousValue;
        }
        this.carService.selected = 'KBA_NUMBER';
    }

    public onTecDocSearch() {
        this.carService.loadCar = 'KTYPE';
        this.carRestService.getCarsByKTypeNumber(this.kTypeId).subscribe(
            (response: ICar[]) => {
                if (response === null) {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_TECDOC_RESPONSE', 'error');
                } else {
                    this.carService.loadCar = undefined;
                    if (!this.userService.getCatalogMode()) {
                        this.carService.fromDMS = false;
                    }
                    this.applyCars.emit(response);
                }
            },
            (error) => {
                if (error.status === 409) {
                    this.carService.handleCarConflict();
                } else {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                }
                this.carService.loadCar = undefined;
            }
        );
    }
}

