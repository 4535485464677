import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {GlobalAdminService} from '../services/global-admin.service';
import {workListAnim} from '../../../shared/animations/general.animation';
import {Location} from '@angular/common';
import {UserService} from '../../../shared/services/user/user.service';
import {AdminService} from '../../club/services/admin.service';
import {HelperService} from '../../../shared/services/helper.service';

@Component({
    selector: 'app-vinlog',
    templateUrl: './global-vinlog.component.html',
    animations: [workListAnim]
})
export class GlobalVinlogComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(public globalAdminService: GlobalAdminService,
                public location: Location,
                public userService: UserService,
                public adminService: AdminService,
                private changeDetectorRef: ChangeDetectorRef,
                private helperService: HelperService) {
    }

    @ViewChild('contentDocumentsUl', {static: true}) contentDocumentsUl: ElementRef;

    ngOnInit() {
        this.adminService.getClubs();
    }

    ngAfterViewInit() {
        if (this.globalAdminService.vinLogResult && this.globalAdminService.vinLogResult.length > 0) {
            this.globalAdminService.emitItems.emit(this.globalAdminService.vinLogResult);
            if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges()
            }
        }
    }

    ngOnDestroy() {
        this.changeDetectorRef.detach();
        this.changeDetectorRef = undefined;
    }

    public getVinLog($event?: any) {
        this.globalAdminService.loadingVinLog = true;
        this.globalAdminService.resultItems = [];
        if ($event) {
            this.globalAdminService.vinLogRequest = $event.trim();
        }
        this.globalAdminService.getVinLog();
    }
}
