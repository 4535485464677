import {Component, OnInit} from '@angular/core';
import {PartnerService} from "../../../shared/services/partner/partner.service";
import {Router} from "@angular/router";
import {UserService} from "../../../shared/services/user/user.service";
import {AdminService} from "../services/admin.service";
import {IPartnerPage} from '../../../partner/interfaces/partnerPage';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-partnermanagement',
  templateUrl: './partnermanagement.component.html',
  styleUrls: ['./partnermanagement.component.scss']
})
export class PartnermanagementComponent implements OnInit {

  public newSort: boolean = false;
  public editTitle: boolean = false;
  constructor(public partnerService: PartnerService,
              private router: Router,
              public userService: UserService,
              public adminService: AdminService) { }

  ngOnInit() {
    this.partnerService.getPartner();
  }

  public sort(event: CdkDragDrop<string[]>, pageType: string) {
    this.newSort = true;
    moveItemInArray(this.partnerService.partnerPages.partnerPages[pageType], event.previousIndex, event.currentIndex);

  }

  public hasPages() {
    if (this.partnerService.partnerPages && this.partnerService.partnerPages.partnerPages) {
      for (const pageType of this.getPageTypes()) {
        if (this.partnerService.partnerPages && this.partnerService.partnerPages.partnerPages[pageType].length > 0) {
          return true;
        }
      }
    }

    return false;
  }

  public getPageTypes() {
    if (this.partnerService.partnerPages && this.partnerService.partnerPages.partnerPages) {
      return Object.keys(this.partnerService.partnerPages.partnerPages);
    }
  }

  public createPartner() {
    this.partnerService.selectedPartner = {logoUrl: '', position: 0, text: '', url: ''};
    this.partnerService.isCreate = true;
    this.router.navigate(['/admin/partner/config'])
  }

  public editPartner(partner: IPartnerPage, pageType: any) {
      this.partnerService.selectedPartnerIsURL = pageType.toString();
      this.partnerService.selectedPartner = partner;
      this.partnerService.isCreate = false;
      this.router.navigate(['/admin/partner/config'])
  }

  public saveChanges() {
    this.partnerService.sortPartner(true);
    this.newSort = false;
  }
}
