<div class="collapsible-panel card">

    <div class="collapsible-panel-header" (click)="toggleFolded()">
        <div class="icon">
            <i *ngIf="folded" class="fa fa-bold fa-angle-down"></i>
            <i *ngIf="!folded" class="fa fa-bold fa-angle-up"></i>
        </div>
        <div class="text">{{ panelTitle }} <b *ngIf="additionalTitle && additionalTitle != 'Standard'">{{additionalTitle}}</b></div>
        <div class="clearfix"></div>
    </div>

    <div class="collapsible-panel-content" [@open]="(folded ? 'true' : 'false')" [ngClass]="{ 'without-padding': noPadding }">
        <ng-content ></ng-content>
    </div>

</div>
