import {Component, OnDestroy, OnInit} from '@angular/core';
import {TyreService} from '../../shared/services/tyre.service';
import {fadeOfferToggle} from '../../shared/animations/general.animation';
import {Subscription} from "rxjs/Subscription";
import {ITyreType} from "../../technical-data/interfaces/Tyre";
import {CarService} from "../../shared/services/car/car.service";
import {ICar} from "../../shared/interfaces/DTO/car/car";
import {IVehicleSpecSubGroup} from "../../shared/interfaces/DTO/car/vehicleSpecSubGroup";

@Component({
  selector: 'app-tyre-details',
  templateUrl: './tyre-details.component.html',
  styleUrls: ['./tyre-details.component.scss'],
    animations: [fadeOfferToggle]
})
export class TyreDetailsComponent implements OnInit, OnDestroy {

  public pdf: any;
  public loading: boolean = false;
  public selectedTyreType: ITyreType;
  public selectedCar: ICar;
  public tyreSpecs: IVehicleSpecSubGroup[] = [];

  private subscriptions: Subscription[] = []
  constructor(public tyreService: TyreService,
              private carService: CarService) { }

  public ngOnInit() {
    this.subscriptions.push(this.tyreService.pdf$.subscribe(pdf => {
      this.pdf = pdf;
    }));

    this.subscriptions.push(this.tyreService.loading$.subscribe(loading => {
      this.loading = loading;
    }));

    this.subscriptions.push(this.tyreService.selectedTyreType$.subscribe(selectedTyreType => {
      this.selectedTyreType =selectedTyreType;
    }));

    this.subscriptions.push(this.tyreService.tyreSpecs$.subscribe(tyreSpecs => {
      this.tyreSpecs = tyreSpecs;
    }));

    this.subscriptions.push(this.carService.selectedCarChanged$.subscribe(selectedCar => {
      this.selectedCar = selectedCar;
    }));

    //TODO: remove after change selectedCarChanged$ to subject
    this.selectedCar = this.carService.selectedCar;
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }
}
