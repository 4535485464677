import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/Rx';
import {IAutovistaCar} from '../../shared/interfaces/DTO/autovista/autovistaCar';
import {IOverviewImage} from '../../shared/interfaces/DTO/overviewImage';
import {ICarDetailGraphics} from '../../shared/interfaces/DTO/autovista/carDetailGraphics';
import {IListOfSide} from '../../shared/interfaces/DTO/autovista/listOfSide';
import {IMastercodeSubGroup} from '../../shared/interfaces/DTO/autovista/mastercodeSubGroup';
import {IVinSearchResponse} from '../../shared/interfaces/DTO/car/vinSearchResponse';
import {IFirmVinStatistics} from '../../shared/interfaces/DTO/firm/firmVinStatistics';
import {IVinStatisticRequest, IVinStatisticResponse} from '../../admin/global/interfaces/vinStatistic';
import {CustomerDataService} from '../../shared/services/customerData.service';
import {ICar} from '../../shared/interfaces/DTO/car/car';

@Injectable()
export class AutovistaRestService {


    constructor(private http: HttpClient,
                private customerDataService: CustomerDataService) {
    }

    public getCarList(kTypeId: number, vin?: string): Observable<IAutovistaCar[]> {
        if (!vin) {
            return this.http.get<IAutovistaCar[]>(
                `${environment.apiUrl}/autovista/carlist/${kTypeId}`
            );
        } else {
            return this.http.get<IAutovistaCar[]>(
                `${environment.apiUrl}/autovista/carlist/${kTypeId}?vin=${vin}`
            );
        }

    }

    public getCarsByNatcode(natcode: string) {
        return this.http.get<ICar[]>(
            `${environment.apiUrl}/autovista/carlist/byNatcode/${natcode}`
        );
    }

    public getCarOverview(natCode: string, ikeCode: string): Observable<IOverviewImage> {
        return this.http.get<IOverviewImage>(
            `${environment.apiUrl}/autovista/caroverview/${natCode}/${ikeCode}`
        );
    }

    public getImageXML(file: any) {
        return this.http.get(`/${file}`, {responseType: 'text'});
    }

    public getCarDetail(ikeCode: string, dsCode: any, defaultFilter: boolean, vin?: string): Observable<ICarDetailGraphics> {
        if (vin) {
            return this.http.get<ICarDetailGraphics>(
                `${environment.apiUrl}/autovista/cardetail/${ikeCode}/${dsCode}?vin=${vin}&defaultFilter=${defaultFilter}`
            );
        } else {
            return this.http.get<ICarDetailGraphics>(
                `${environment.apiUrl}/autovista/cardetail/${ikeCode}/${dsCode}?defaultFilter=${defaultFilter}`
            );
        }
    }

    public getPartDetail(natCode: string, ikeCode: string, imageCode: any, calloutId: any, kTypeId: number = 0, adcTypeId: number = 0, critCodes: any[], vin: string): Observable<IListOfSide> {
        return this.http.get<IListOfSide>(
            `${environment.apiUrl}/autovista/partdetail/${natCode}/${ikeCode}/${imageCode}/${calloutId}?kTypeId=${kTypeId}&adcTypeId=${adcTypeId}&criteriaCodes=${critCodes}&vin=${vin}`
        );
    }

    public getSubGroupDetails(natCode: string, ikeCode: string, subGroup: any): Observable<IMastercodeSubGroup> {
        return this.http.get<IMastercodeSubGroup>(
            `${environment.apiUrl}/autovista/subgroupdetail/${natCode}/${ikeCode}/${subGroup}`
        )
    }

    public getAutovistaVinRest(vin: string, allowChargeable: boolean, natCode?: string, ikeCode?: string): Observable<IVinSearchResponse> {
        if (vin) {
            if (natCode) {
                if (ikeCode) {
                    return this.http.get<IVinSearchResponse>(
                        `${environment.apiUrl}/autovista/car/byVin/${vin}/${allowChargeable}?natCode=${natCode}&ikeCode=${ikeCode}`
                    );
                } else {
                    return this.http.get<IVinSearchResponse>(
                        `${environment.apiUrl}/autovista/car/byVin/${vin}/${allowChargeable}?natCode=${natCode}`
                    );
                }

            } else {
                if (ikeCode) {
                    return this.http.get<IVinSearchResponse>(
                        `${environment.apiUrl}/autovista/car/byVin/${vin}/${allowChargeable}?ikeCode=${ikeCode}`
                    );
                } else {
                    if (this.customerDataService.getHsn() && this.customerDataService.getTsn()) {
                        return this.http.get<IVinSearchResponse>(
                            `${environment.apiUrl}/autovista/car/byVin/${vin}/${allowChargeable}?hsn=${this.customerDataService.getHsn()}&tsn=${this.customerDataService.getTsn()}`,
                        );
                    } else {
                        return this.http.get<IVinSearchResponse>(
                            `${environment.apiUrl}/autovista/car/byVin/${vin}/${allowChargeable}`
                        );
                    }
                }
            }
        }
    }

    public getOeSuppliers() {
        return this.http.get(
            `${environment.apiUrl}/session/oesuppliers`
        )
    }

    public getFirmVinStatisticRest(firmId: number): Observable<IFirmVinStatistics> {
        if (firmId && firmId > -1) {
            return this.http.get<IFirmVinStatistics>(
                `${environment.apiUrl}/autovista/statistic/firm/${firmId}`
            );
        }
    }

    public getAutovistaVinStatisticRest(statistic: IVinStatisticRequest) {
        return this.http.post<IVinStatisticResponse>(
            `${environment.apiUrl}/autovista/statistic`,
            statistic
        )
    }

    public getAutovistaVinStatisticCSVRest(statistic: IVinStatisticRequest) {
        return `${environment.apiUrl}/autovista/statistic/csv?clubId=${statistic.clubId}&from=${statistic.from}&to=${statistic.to}`;
    }
}
