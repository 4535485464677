import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {ConfigService} from '../../../shared/services/config.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(private router: Router,
              public configService: ConfigService) { }

  ngOnInit() {
    this.configService.getClubConfig()
  }
    public navigate(path: string) {
        this.router.navigate([path]);
    }
}
