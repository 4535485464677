<div class="profit-check row" style="margin-top: 2rem;" *ngIf="articleList?.length > 0">
  <div class="col alert alert-info">
    <div class="text-center">
      <b>{{ 'PRINT_VIEW.OFFER_NO_PROFIT_TITLE' | translate }}</b><br/>
      {{ 'PRINT_VIEW.OFFER_NO_PROFIT' | translate }}
    </div>
  </div>

  <table class="simple-table ">
    <thead>
    <tr>
      <th class="normalText">
        {{ 'GENERAL.ARTICLE' | translate }}
      </th>
      <th class="normalText">
      </th>
      <th width="100px" class="text-left">{{ 'CART.AMOUNT' | translate }}</th>
      <th width="15%" class="priceText text-center">{{'CART.SELLIN' | translate}}</th>
      <th width="15%" class="priceText text-center">
        <div>{{'CART.SELLOUT' | translate}} <i class="fa fa-pencil-square-o"></i></div>
      </th>
    </tr>
    </thead>
    <tbody *ngIf="!cartService.cartEmpty">
    <ng-container *ngFor="let position of articleList">
      <tr>
        <td class="brand_ipc">
          <span class="brand">{{ position.brandName }}</span>
          &nbsp;<i class="ipc">{{ position.description }}</i>
        </td>
        <td class="brand_ipc">
          <div class="brand">{{ position.ipc }}</div>
        </td>
        <td class="amount">
          <span> {{position.quantity}} {{ 'CART.' + position.quantityUnit | translate }}</span>
        </td>
        <td class="price-per-piece text-center">
          {{ position.sellInNetPerPieceFormatted}}
        </td>
        <td class="total-price priceText text-center">
          <div class="sellout-price">
            <app-cart-amount-input style="display: flex; padding-left: 50px;"
                                   [initialAmount]="position.sellOutNetPerPiece"
                                   [showCurrency]="true"
                                   (amountChanged)="onPriceChange($event, position)"
                                   (onMouseClick)="onPriceChange($event, position)"
                                   [floatValue]="true"></app-cart-amount-input>
          </div>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>
