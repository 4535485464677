import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../shared/services/user/user.service';
import {AccountRestService} from '../services/account.rest.service';
import {IUpdateEmailRequest} from '../../../shared/interfaces/DTO/updateEmailRequest';
import { Validators } from '@angular/forms';
import {HelperService} from '../../../shared/services/helper.service';

@Component({
    selector: 'app-email',
    templateUrl: './email.component.html',
})
export class EmailComponent implements OnInit {
    public email: string;
    public emailIsValid: boolean;

    constructor(public userService: UserService,
                private accountRestService: AccountRestService,
                private helperService: HelperService) {
    }

    ngOnInit() {
    }

    public changeEmail() {
        if (this.isValidMail()) {
            const emailRequest: IUpdateEmailRequest = {newEmail: this.email, valid: true};
            this.accountRestService.setNewEmail(emailRequest).subscribe(
                (response) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_SAVE_EMAIL', 'success');
                    this.userService.accountData.email = this.email;
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_SAVE_EMAIL', 'error');
                });
        } else {
            this.helperService.showNotification('TOAST_MESSAGES.ERROR_EMAIL_NOT_VALID', 'error');
        }
    }

    private isValidMail() {
        if (!this.email || this.email.trim().length === 0) {
            return false;
        }

        if (this.email.indexOf('@') < 0) {
            return false;
        }

        const tempAt = this.email.substring(this.email.indexOf('@') + 1);
        if (tempAt.indexOf('.') < 0) {
            return false;
        }

        const tempDot = this.email.substring(this.email.lastIndexOf('.') + 1);

        if (tempDot.trim().length === 0) {
            return false;
        }

        return true;
    }
}
