<div class="no-data">
    <div class="card">
        <div class="logo {{userService?.accountData?.language}} {{cartService.getCalculation()}} {{userService?.accountData?.country}}"></div>
        <div class="alert alert-danger text-center">
            <i>{{'CAR_SELECTION.NO_TECDOC_DATA' | translate}}</i>
            <div class="cardBody">
                <ng-container *ngIf="selectedSection === 'MAINTENANCE'">

                </ng-container>
                <ng-container *ngIf="selectedSection === 'WORK'">

                </ng-container>
                <ng-container *ngIf="selectedSection === 'TYRE'">

                </ng-container>
                <ng-container *ngIf="selectedSection === 'TECHNICAL_DATA'">

                </ng-container>
            </div>
        </div>
    </div>
</div>
