import {animate, keyframes, style, transition, trigger} from '@angular/animations';

export const sidebar =
        trigger('sidebar', [
            transition('out => in',
                animate('300ms', keyframes([
                        style({opacity: '0',   offset: 0}),
                        style({opacity: '0',   offset: 0.2}),
                        style({opacity: '1',   offset: 1})
                    ])
                )),
            transition('in => out',
                animate('300ms', keyframes([
                        style({opacity: '1',   offset: 0}),
                        style({opacity: '.2',   offset: 0.2}),
                        style({opacity: '0',   offset: 1})
                    ])
                ))
        ]);
export const bounceIn =
    trigger('bounceIn', [
    transition('* => true', [
        animate(300, keyframes([
            style({transform: 'scale(1)'}),
            style({transform: 'scale(1.4)'}),
            style({transform: 'scale(1)'})
        ]))
    ])
    ]);
