<div class="vin-search-wrapper">
  <app-car-by-autovista-vin
          *ngIf="isActiveVin('AUTOVISTA') && userService.showAutovistaVin()"
          [_vin]="_vin"
          [catalog]="catalog"
          (applyCars)="applyCars.emit($event)"
          (selectVinCar)="selectVinCar.emit($event)">
  </app-car-by-autovista-vin>

  <app-car-by-vin
          *ngIf="isActiveVin('TECRMI')"
          [_vin]="_vin"
          (applyCars)="applyCars.emit($event)">
  </app-car-by-vin>
</div>
