import {Component} from '@angular/core';
import {fadeSmallAnimation} from "../../shared/animations/fadesmall.animation";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    animations: [fadeSmallAnimation]
})

export class ProfileComponent {

    constructor() {
    }

    public getRouteAnimation(outlet) {
        return outlet.activatedRouteData.state;
    }
}
