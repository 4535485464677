import {Component, OnInit} from '@angular/core';
import {CarService} from "../../services/car/car.service";
import {UserService} from "../../services/user/user.service";
import {ActivatedRoute, Router} from '@angular/router';
import {CustomerDataService} from '../../services/customerData.service';
import {ConfigService} from '../../services/config.service';
import {TechnicalDataService} from "../../../technical-data/technical-data.service";
import {GlobalAdminService} from '../../../admin/global/services/global-admin.service';

@Component({
    selector: 'app-car-details',
    templateUrl: './car-details.component.html',
    styleUrls: ['./car-details.component.scss']
})
export class CarDetailsComponent implements OnInit {

    public numberplate: string;

    constructor(
        public carService: CarService,
        public userService: UserService,
        public configService: ConfigService,
        public route: ActivatedRoute,
        public dataService: CustomerDataService,
        public technicalDataService: TechnicalDataService,
        public globalAdminService: GlobalAdminService,
        public customerDataService: CustomerDataService
    ) {

    }

    ngOnInit() {
    }

    public getYear() {
        const date = new Date(this.customerDataService.getDataHolder().year);
        if (date instanceof Date && !isNaN(date.valueOf())) {
            return date;
        }
    }
}
