import {Component, OnInit} from '@angular/core';
import {UserService} from "../shared/services/user/user.service";
import {AuthService} from "../auth/services/auth.service";
// import {Response} from "@angular/http";
import {Router} from "@angular/router";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }



}
