import {Component, OnDestroy, OnInit} from '@angular/core';
import {AssortmentService} from '../services/assortment.service';
import {Router} from '@angular/router';
import {DatamapperService} from '../services/datamapper.service';
import {IAssortment} from '../interfaces/assortment';
import {from} from 'rxjs';
import {groupBy, map} from 'rxjs/operators';

@Component({
    selector: 'app-assortment-article',
    templateUrl: './assortment-article.component.html'
})
export class AssortmentArticleComponent implements OnInit, OnDestroy {

    public loading: boolean = true;
    public showNoResults: boolean = false;
    public clubAssortments: IAssortment[] = [];
    public firmAssortments: IAssortment[] = [];
    public groupedFirmAssortments: number [] = [];
    public groupedClubAssortments: number[] = [];
    public state: string;
    public showGrouped: boolean = true;

    constructor(public assortmentService: AssortmentService,
                public dataMapperService: DatamapperService,
                public router: Router) {
    }


    ngOnInit() {
        this.assortmentService.assortmentSubscriptions.push(this.assortmentService.stateClubOrFirm.subscribe(state => this.state = state));
        this.assortmentService.getAssortments('withGenart');
        this.assortmentService.assortmentSubscriptions.push(this.assortmentService.assortments.subscribe(
            items => {
                this.loading = true;
                this.firmAssortments = [];
                this.clubAssortments = [];
                setTimeout(() => {
                    if (items) {
                        if (this.state === 'club') {
                            this.clubAssortments = items;
                        }
                        if (this.state === 'firm' || this.state === 'ownfirm') {
                            items.forEach(item => {
                                if (item.firmId !== null) {
                                    this.firmAssortments.push(item);
                                } else {
                                    this.clubAssortments.push(item);
                                }
                            })
                        }
                        this.showNoResults = false;
                    }
                    if (items && items.length === 0) {
                        this.showNoResults = true;
                    }
                    this.loading = false;
                    this.groupList();
                }, 300)
            }));

        this.assortmentService.assortmentSubscriptions.push(this.assortmentService.refreshBehavior.subscribe(bool => {
            if (bool === true) {
                setTimeout(() => {
                    this.assortmentService.getAssortments('withGenart');
                }, 300)
                this.assortmentService.refreshBehavior.next(false);
            }
        }));
    }


    ngOnDestroy(): void {
        this.assortmentService.cleanSubscriptions();
    }

    public groupList(event?: any) {
        if (event) {
            this.showGrouped = !!event.target.checked;
        }

        if (this.showGrouped) {
            this.groupedFirmAssortments = [];
            from(this.firmAssortments)
                .pipe(
                    groupBy(
                        assortment => assortment.genArtId,
                        item => item),
                    map(group => group.key)
                )
                .subscribe(data => {
                    this.groupedFirmAssortments.push(data);
                });

            this.groupedClubAssortments = [];
            from(this.clubAssortments)
                .pipe(
                    groupBy(
                        assortment => assortment.genArtId),
                    map(group => group.key)
                )
                .subscribe(data => {
                    this.groupedClubAssortments.push(data);
                });
        }
    }

    public existInFirm(genArt: number, firmAssortments): boolean {
        if (firmAssortments && firmAssortments.length > 0) {
            return (!firmAssortments.find(item => (item === genArt)));
        }
        return true;
    }


    public updatePriority(id: string, primaryPrio: boolean, value: number, overWrite: boolean) {
        this.assortmentService.updatePriority(id, primaryPrio, value, overWrite);
    }

    public updateAssortment(id: string, overWrite: boolean) {
        this.assortmentService.updateAssortment(id, overWrite);
    }

    public deleteAssortment(id: string) {
        this.assortmentService.deleteAssortment(id);
    }

    public modulus(value: number) {
        return this.assortmentService.modulus(value);
    }

    public setGenArtId(event: any) {
        this.router.navigate([this.router.url + '/' + event.item.id]);
    }

}
