<div *ngIf="globalAdminService.gripsOrderList && globalAdminService.currentOrder">
    <div *ngFor="let orderId of globalAdminService.currentOrder.orderIds" class="m-3">
        <app-collapsible-panel [panelTitle]="orderId">
            <div class="row p-2">
                <div class="col">Datum</div>
                <div class="col">{{globalAdminService.currentOrder.created | date: 'dd.MM.yyyy'}}</div>
            </div>
            <div class="row p-2">
                <div class="col">
                    <button class="base-btn w-100 mt-1" (click)="globalAdminService.orderIdGrip = orderId; globalAdminService.showTransferLogGrip = true;">Grips Log</button>
                    <button class="base-btn w-100 mt-1" (click)="globalAdminService.orderIdGrip = orderId; globalAdminService.showTransferLogGripSupplier = true;">Transfer Log</button>
                </div>
            </div>
        </app-collapsible-panel>
    </div>
</div>
