import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ErrorHandlerService} from "./services/error-handler.service";
import {AdminService} from "../../../admin/club/services/admin.service";

@Component({
    selector: 'app-error-handler',
    templateUrl: './error-handler.component.html',
    styleUrls: ['./error-handler.component.scss']
})

export class ErrorHandlerComponent {

    @Output() onBtnPress: EventEmitter<any> = new EventEmitter();
    @Input() hasCancel: boolean = false;

    private errorContainer: any;

    constructor(public errorHandlerService: ErrorHandlerService,
                public adminService: AdminService) {
    }


    public accept() {
        this.errorHandlerService.newError = false;
        this.errorContainer = {errorCode: this.errorHandlerService.errorCode, btnString: 'OK'};
        this.onBtnPress.emit(this.errorContainer);
    }

    public decline() {
        this.errorHandlerService.newError = false;
        this.errorContainer = {errorCode: this.errorHandlerService.errorCode, btnString: 'CANCEL'};
        this.onBtnPress.emit(this.errorContainer);
    }
}
