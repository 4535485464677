<div class="global-order-row">
  <ng-container>
    <ng-container *ngFor="let item of globalAdminService?.communicationResult?.logs | keyvalue">
      <li (click)="globalAdminService.setCommunicationLog(item)"
          routerLink="/global-admin/communicationslist/{{item.key}}">
        <div class="row">
          <div class="col-1 d-flex justify-content-center align-self-center">

          </div>
          <div class="col-11">
            <div class="row">
              <div class="col">
                <span class="order-name">{{item.value?.created | date : 'dd.MM.yyy hh:mm:ss'}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Datum</span>
              </div>
              <div class="col-4">
                <span>{{item.value?.created | date : 'dd.MM.yyy'}}</span>
              </div>
              <div class="col-2">
                <span>Uhrzeit</span>
              </div>
              <div class="col-4">
                <span>{{item.value?.created | date : 'hh:mm:ss'}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Club Name</span>
              </div>
              <div class="col-4">
                <span>{{item.value?.clubName}}</span>
              </div>
              <div class="col-2">
                <span>Firm Name</span>
              </div>
              <div class="col-4">
                <span>{{item.value?.firmName}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Login Name</span>
              </div>
              <div class="col-4">
                <span>{{item.value?.loginName}}</span>
              </div>
              <div class="col-2">
                <span>Method</span>
              </div>
              <div class="col-4">
                <span>{{item.value?.method}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Supplier</span>
              </div>
              <div class="col-10">
                <span>{{item.value?.supplierName}}</span>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <span>Request / Response</span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ng-container>
    <div class="noHover row app-articles-row" *ngIf="globalAdminService.infinityLoading">
      <i class="centerItem fa fa-spinner" [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i>
    </div>
    <div class="noHover row app-articles-row" *ngIf="onEnd()">
      <span class="centerItem">{{'SPAREPARTS_VIEW.SEARCH_RESULTS.END' | translate}}</span>
    </div>
  </ng-container>
</div>

