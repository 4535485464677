<div class="external-tools-wrapper {{position}}">
    <ng-container *ngFor="let tool of userService.activeExternalTools">
        <ng-container *ngIf="(tool.code === 'DIGITAL_SERVICEBOOK' && carService?.selectedCar?.digitalServiceBook) || (tool.code !== 'DIGITAL_SERVICEBOOK')">
            <div class="row external-icon-wrapper clickable {{tool.code}}"
                 *ngIf="(position !== 'car-details' || position === 'car-details' && tool.showInDetails)"
                 (click)="configService.openTool(tool)">
                <div class="col-2" *ngIf="configService.loadExternalTool !== tool.code">
                    <i class="fa"></i>
                </div>
                <div class="col-2 external-tool-icon" *ngIf="configService.loadExternalTool === tool.code">
                    <i class="fa" [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"></i>
                </div>
                <div class="col-10">
                    <span>{{'EXTERNAL_TOOLS.' + tool.code | translate}}</span>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
