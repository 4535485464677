<div class="technical-data">

    <div class="content clean sidebar-opener-wrapper" *ngIf="(stateService.technicalDataContent | async) === 'CONTENT'">
        <div class="sidebar-opener" [ngClass]="userService?.getNavigation()">
            <ul>
                <li (click)="userService?.toggleNavigation()">
                    <i class="fa fa-chevron-right"></i>
                </li>
            </ul>
        </div>

        <div class="left-panel no-float" [ngClass]="userService?.getNavigation()">

            <div class="panel-content left-sidebar">
                <ul class="custom-tree">
                    <ng-container *ngFor="let category of categories">
                        <li class="list" *ngIf="category"
                            [ngClass]="{'active': category === selectedCat?.qualifier,
                            'disabled': !categoryContent.get(category) || categoryContent.get(category)?.data?.length === 0}">
                            <div (click)="openCat(categoryContent.get(category))"
                                 *ngIf="categoryContent.get(category)?.data?.length > 0">
                                <i class="folder fa fa-circle"
                                   [ngClass]="{'open' : category === technicalDataService.selectedCat?.qualifier}"></i>
                                <span>{{'TECHNICALINFOS.NAV.' + category | translate}}</span>
                            </div>
                            <div *ngIf="categoryContent.get(category) && !categoryContent.get(category)?.data">
                                <i class="folder fa fa-circle"></i>
                                <span>{{'TECHNICALINFOS.NAV.' + category | translate}}
                                    <i class="fa fa-spinner"
                                       [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"></i>
                                </span>
                            </div>
                            <div *ngIf="categoryContent.get(category)?.data?.length === 0">
                                <i class="folder fa fa-circle"></i>
                                <span class="noData" tooltip tooltipPosition="bottom"
                                      tooltipContent="{{'CAR_HISTORY.TOOLTIP.NO_DATA' | translate}}">
                                {{'TECHNICALINFOS.NAV.' + category | translate}}
                                    <i class="fa fa-spinner" *ngIf="!categoryContent.get(category)"
                                       [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"></i>
                                </span>
                            </div>
                            <ul *ngIf="selectedCat?.qualifier === category">
                                <li class="list" *ngFor="let subCat of selectedCat?.data">
                                    <div (click)="openCatData(subCat, selectedCat?.qualifier !== 'DIAGNOSTIC' ? subCat.name : 'DIAGNOSTIC')"
                                         [ngClass]="{'active': subCat?.name === technicalDataService.selectedDataCat?.name || subCat?.name === technicalDataService.selectedChild }">
                                        <i class="folder fa fa-circle"
                                           [ngClass]="{'open' : subCat?.name === technicalDataService.selectedDataCat?.name || subCat?.name === technicalDataService.selectedChild}"></i>
                                        <span>{{subCat.name}}</span>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>

        <div class="center-panel no-float" [ngClass]="userService?.getNavigation()">
            <div class="heading blue">
                <!--Drucken von IFrame-->
                <span class="headerPrint" *ngIf="nxManualUrl">
                    <button class="print-btn pull-right" (click)="printIframe()">
                        <i class="fa fa-print"></i>
                        <span>{{'PRINT_VIEW.PRINT' | translate}}</span>
                    </button>
                </span>

                <!--Drucken von HTMLToPDF-->
                <span class="headerPrint"
                      *ngIf="!nxManualUrl && (nxDetails || technicalDataService.nxDetails) && (selectedDataCat || technicalDataService.selectedDataCat) && !isDiagnosis">
                    <button class="print-btn pull-right" (click)="technicalDataService.createPdf()"
                            *ngIf="!technicalDataService.showPdf">
                        <i class="fa fa-file-pdf-o"></i>
                        <span>{{ 'PRINT_VIEW.TITLE' | translate }}</span>
                    </button>
                    <button class="print-btn pull-right"
                            (click)="technicalDataService.showPdf = !technicalDataService.showPdf;"
                            *ngIf="technicalDataService.showPdf">
                        <i class="fa fa-close"></i>
                        <span>{{'PRINT_VIEW.CLOSE' | translate}}</span>
                    </button>
                </span>

                <span (click)="resetSelectedCategory()" class="clickable">
					<i class="fa fa-th" aria-hidden="true"></i>
                    <span>{{'CLUB_SETTINGS.NAVIGATION.TECHNICAL_DATA' | translate}}</span>
				</span>
                <span class="clickable" *ngIf="technicalDataService.selectedCat && !technicalDataService.isCarDetails"
                      (click)="openCat(technicalDataService.selectedCat)">
                    <i class="fa fa-caret-right 1"></i>
                    {{'TECHNICALINFOS.NAV.' + technicalDataService.selectedCat?.qualifier | translate}}
                </span>
                <span class="clickable" *ngIf="technicalDataService.selectedCat && technicalDataService.isCarDetails">
                    <i class="fa fa-caret-right 1"></i>
                    {{'TECHNICALINFOS.NAV.' + technicalDataService.selectedCat?.qualifier | translate}}
                </span>
                <span class="clickable"
                      *ngIf="technicalDataService.selectedDataCat && !technicalDataService.isCarDetails"
                      (click)="openCatData(technicalDataService.selectedDataCat)">
                    <i class="fa fa-caret-right 2"></i>
                    {{technicalDataService.selectedDataCat?.name}}
                </span>
                <span class="clickable" *ngIf="technicalDataService.selectedChildCat"
                      (click)="openCatData(technicalDataService.selectedChildCat)">
                    <i class="fa fa-caret-right 3"></i>
                    {{selectedChild}}
                </span>
                <span class="clickable" *ngIf="isDiagnosis" (click)="openDiagnosticCat(nxDiag, 'DIAGNOSIS')">
                    <i class="fa fa-caret-right 4"></i>
                    {{selectedChild}}
                </span>
                <span class="clickable" *ngIf="isDiagnosis && selectedsubChild">
                    <i class="fa fa-caret-right 5"></i>
                    {{selectedsubChild}}
                </span>

                <span class="pull-right clickable" *ngIf="nxManualUrl" (click)="backInFrame(nxManualUrl)"
                      style="padding-right: 10px;">
                    <i class="fa fa-backward"></i>
                </span>
            </div>

            <div class="panel-content center-content white-background"
                 *ngIf="technicalDataService.nxManualUrl && !technicalDataService.nxDetails && !technicalDataService.isCarDetails">
                <iframe width="100%" onLoad="this.style.height=(this.contentDocument.body.scrollHeight + 30) + 'px';"
                        [src]="technicalDataService.nxManualUrl | iFrame" id="tecrmi-manual"></iframe>
            </div>

            <!--Data Table-->
            <span>{{!technicalDataService.nxManualUrl}} </span>
            <span>{{technicalDataService.nxDetails | json}}</span>
            <span>{{technicalDataService.selectedDataCat | json}}</span>
            <span>{{!technicalDataService.isDiagnosis}}</span>
            <div class="panel-content center-content print-content"
                 *ngIf="!technicalDataService.nxManualUrl && technicalDataService.nxDetails && technicalDataService.selectedDataCat && !technicalDataService.isDiagnosis"
                 [ngClass]="{ 'setting' : !technicalDataService.showPdf}">
                <div class="technical-data_title">
                    <button class="base-btn pull-right" (click)="technicalDataService.printPdf()"
                            *ngIf="technicalDataService.showPdf && !helperService.browserIsIE() && !helperService.browserIsEdge()">
                        <i class="fa fa-print"></i> {{ 'PRINT_VIEW.PRINT' | translate }}
                    </button>

                    <button class="base-btn pull-right" (click)="technicalDataService.downloadPdf()"
                            *ngIf="technicalDataService.showPdf">
                        <i class="fa fa-save"></i> {{ 'PRINT_VIEW.GENERATE_PDF' | translate }}
                    </button>

                    <h1 *ngIf="!technicalDataService.showPdf">
                        <strong>{{ 'TECHNICALINFOS.NAV.' + technicalDataService.selectedCat.qualifier | translate }}</strong>
                        <span>{{'  ' + technicalDataService.nxDetailsTitle}}</span>
                    </h1>
                    <h1 *ngIf="technicalDataService.showPdf">
                        <strong>{{ 'TECHNICALINFOS.NAV.' + technicalDataService.selectedCat.qualifier | translate }}</strong>
                        <span>{{'  ' + technicalDataService.nxDetailsTitle}}</span>
                    </h1>
                </div>

                <div class="print-preview" *ngIf="technicalDataService.showPdf" style="top: 0!important;">
                    <app-default-loading-page *ngIf="pdfLoading"></app-default-loading-page>
                    <pdf-viewer
                            [src]="pdf"
                            [autoresize]="false"
                            [render-text]="false"
                            [show-all]="true"
                            style="display: block;"
                    ></pdf-viewer>
                </div>

                <ng-container *ngIf="!technicalDataService.showPdf">
                    <table *ngFor="let detail of technicalDataService.nxDetails" class="striped-table">
                        <tr class="headerType">
                            <span class="selection title">{{detail.name | translate}}</span>
                        </tr>
                        <tr *ngFor="let subItems of detail?.specItems">
                            <td>
                                {{subItems.mountPositionText | translate}}
                            </td>
                            <td>
                                <div *ngFor="let specValues of subItems.specValues">
                                    {{specValues.valueText}} {{specValues.quantityText}} {{specValues.qualifierText}}
                                    <span *ngIf="specValues.additionText">{{specValues.additionalText}}</span>
                                </div>
                            </td>
                        </tr>
                        <div class="spacer"></div>
                    </table>
                </ng-container>
            </div>
            <div class="panel-content center-content white-background"
                 *ngIf="!technicalDataService.nxManualUrl && !nxDetails">
                <!--category-->
                <ng-container
                        *ngIf="!technicalDataService.selectedCat && !technicalDataService.selectedDataCat && !technicalDataService.selectedChildCat && !technicalDataService.isDiagnosis && !technicalDataService.isCarDetails">
                    <div class="assembly-categories" [@catAnim]="''">
                        <ng-container *ngFor="let category of categories">
                            <div class="assembly-category"
                                 *ngIf="categoryContent.get(category)?.data?.length > 0"
                                 (click)="openCat(categoryContent.get(category))"
                                 [ngClass]="{'selected': category === selectedItem}">
                                <div class="name-wrapper-no-image">
                                    {{'TECHNICALINFOS.NAV.' + category | translate}}
                                </div>
                            </div>
                            <div class="assembly-category"
                                 *ngIf="categoryContent.get(category) && !categoryContent.get(category)?.data">
                                <div class="name-wrapper-no-image">
                                    {{'TECHNICALINFOS.NAV.' + category | translate}}
                                    <i class="fa fa-spinner"
                                       [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"></i>
                                </div>
                            </div>
                            <div class="assembly-category"
                                 tooltip tooltipPosition="center"
                                 tooltipContent="{{'CAR_HISTORY.TOOLTIP.NO_DATA' | translate}}"
                                 *ngIf="categoryContent.get(category)?.data?.length === 0"
                                 [ngClass]="{'selected': category === selectedItem}">
                                <div class="name-wrapper-no-image noData">
                                    {{'TECHNICALINFOS.NAV.' + category | translate}}
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <!-- subCategory -->
                <ng-container
                        *ngIf="selectedCat && technicalDataService.selectedCat && !technicalDataService.selectedDataCat && !technicalDataService.selectedChildCat && !technicalDataService.isDiagnosis && !technicalDataService.isCarDetails">
                    <div class="assembly-categories" [@catAnim]="''">
                        <div class="assembly-category"
                             *ngFor="let subCategory of selectedCat.data"
                             (click)="openCatData(subCategory, selectedCat.qualifier)">
                            <div class="name-wrapper-no-image">
                                {{subCategory?.name}}
                            </div>
                        </div>
                    </div>
                </ng-container>

                <app-car-details-equipment *ngIf="carService.selectedCar.vin"></app-car-details-equipment>

                <!-- subFolder -->
                <ng-container *ngIf="selectedChildCat && !technicalDataService.isCarDetails">
                    <div *ngFor="let childCat of selectedChildCat.subGroups">
                        <div class="headerType">
                            <span class="selection title">{{childCat.name}} test</span>
                        </div>
                        <ul class="item-list" [@workListAnim]="childCat?.manualItems?.length">
                            <ng-container *ngFor="let childItems of childCat.manualItems">
                                <li (click)="openCatData(childItems, selectedItem)"
                                    *ngIf="childItems.manuals.length < 2">
                                        <span (click)="openCatData(childItems, selectedItem)" class="clickable">
                                            <i class="fa fa-file-text-o"></i>
                                            <span>{{childItems.text}}</span>
                                            <span *ngIf="childItems.manuals[0].qualifierText !== 'Standard'">{{' ' + childItems.manuals[0].qualifierText}}</span>
                                        </span>
                                </li>
                                <ng-container *ngIf="childItems.manuals.length >= 2">
                                    <ng-container *ngFor="let childManuals of childItems.manuals">
                                        <li (click)="openCatData(childManuals, selectedItem)">
                                                <span class="clickable"
                                                      (click)="openCatData(childManuals, selectedItem)">
                                                    <i class="fa fa-file-text-o"></i>
                                                    <span>{{childItems.text}}</span>
                                                    <span *ngIf="childManuals.qualifierText !=='Standard'"><strong>{{' ' + childManuals.qualifierText}}</strong></span>
                                                </span>
                                        </li>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ul>
                        <div class="spacer"></div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="right-panel no-float" [ngClass]="userService?.getNavigation()">
            <div class="panel-content right-sidebar">
                <div class="inner-content">
                    <app-car-details></app-car-details>
                </div>
            </div>
        </div>
    </div>

    <app-content-overlay [showContent]="stateService.technicalDataContent | async"
                         [newRoute]="'/technical-data'"></app-content-overlay>

</div>
