<div class="row">
    <div class="col-3">
        <div class="left-label">
        </div>
    </div>
    <div class="col-9">
        <div class="row form-select-item">
            <div class="col-9 registration-ui">
              <app-material-form-input
                      [label]="'HOME.NUMBER_PLATE' | translate"
                      [disabled]="false"
                      [type]="text"
                      [(text)]="numberplate"
                      [focus]="carService.selected === 'VWE'"
                      (selected)="carService.selected = 'VWE'"
                      (keydown.enter)="onVweSearch()">
                </app-material-form-input>
            </div>
            <div class="col-3">
                <button #vin class="btn-flat" [disabled]="!(carService.selected === 'VWE')"
                        (click)="onVweSearch()">
                    <i class="fa fa-search" *ngIf="!(carService?.loadCar === 'VWE')"></i>
                    <i class="fa fa-spinner" *ngIf="carService?.loadCar === 'VWE'"
                       [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"></i>
                </button>
            </div>
        </div>
    </div>
</div>
