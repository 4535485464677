import {Component, OnInit} from '@angular/core';
import {GlobalAdminService} from '../../services/global-admin.service';
import {PdfService} from '../../../../shared/services/pdf.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-grip-order-details',
    templateUrl: './grip-order-details.component.html',
    styleUrls: ['./grip-order-details.component.scss']
})
export class GripOrderDetailsComponent implements OnInit {


    constructor(public globalAdminService: GlobalAdminService,
                public pdfService: PdfService,
                public router: Router) {
    }

    ngOnInit() {

    }

}
