<div class="global-dashboard">
  <div class="heading blue">
        <span class="clickable" style="cursor: pointer;" routerLink="/global-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <span >
          <i class="fa fa-caret-right"></i>
            {{'GLOBAL_ADMIN.STATISTICS' | translate}}
        </span>
  </div>
  <div class="panel-content center-content white-background">
    <div class="assembly-categories">
      <div class="assembly-category" *ngFor="let club of adminService?.clubs" routerLink="/global-admin/statistics/{{club.clubId}}">
        <div class="name-wrapper-no-image">
          <span>{{club.name}}</span>
        </div>
      </div>

    </div>
  </div>
</div>
