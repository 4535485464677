import { Component, OnInit } from '@angular/core';
import {WorkService} from '../services/work.service';
import {Router} from '@angular/router';
import {CartService} from '../../cart/services/cart.service';
import {DmsDataService} from '../../shared/services/dms-data.service';
import {ConfigService} from '../../shared/services/config.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-work-header',
  templateUrl: './work-header.component.html',
  styleUrls: ['./work-header.component.scss']
})
export class WorkHeaderComponent implements OnInit {

  constructor(public workService: WorkService,
              public router: Router,
              public dmsDataService: DmsDataService,
              public configService: ConfigService,
              public cartService: CartService,
              public location: Location) { }

  ngOnInit() {
  }

  public getAdditionalWorkTime(): number {
    let workTime = 0;
    this.workService.posByCar.forEach((group) => {
      workTime += group.position.quantity;
    });
    return Math.round(workTime * 100) / 100;
  }

  public getQuantityUnit(): string {
    return this.workService?.posByCar[0]?.position?.quantityUnit;
  }
}
