import {inject, Injectable} from '@angular/core';
import * as Stomp from 'webstomp-client';
import {Message} from 'webstomp-client';
import {Observable, Subject} from 'rxjs';
import {UserService} from "../shared/services/user/user.service";

@Injectable({
    providedIn: 'root',
})

export class NotificationService {
    //Services
    private readonly userService: UserService = inject(UserService);

    //Variables
    private client: Stomp.Client;
    private stompSubject: Subject<Message> = new Subject<Message>();
    private readonly reconnectTimeout: number = 120000;
    private readonly maxReconnectAttempts: number = 5;
    private readonly heartBeatTime: number = 20000;
    private readonly destination: string = '/user/queue/current';
    private reconnectAttempts: number = 0;

    public connectAndSubscribe(): void {
        if (this.client && (this.client.connected)) return;

        const brokerURL: string = `wss://${window.location.hostname}:${window.location.port}/api/notification`;
        const socket: WebSocket = new WebSocket(brokerURL);
        this.client = Stomp.over(socket, {
            heartbeat: {incoming: this.heartBeatTime, outgoing: this.heartBeatTime},
        });

        if (!this.userService.allow('GLOBAL-ADMIN')) {
            this.client.debug = () => {
            };
        }

        this.client.connect({}, frame => {
            this.client.subscribe(this.destination, message => {
                this.stompSubject.next(message);
            });
        }, error => {
            if (this.reconnectAttempts < this.maxReconnectAttempts) {
                setTimeout(() => {
                    this.reconnectAttempts++;
                    this.connectAndSubscribe();
                }, this.reconnectTimeout);
            }
        });
    }

    public get messages(): Observable<Message> {
        return this.stompSubject.asObservable();
    }

    public disconnect(): void {
        if (this.client && this.client.connected) {
            this.client.disconnect(() => {
            });
        }
    }
}
