<div class="show-supplier-selection"
     (click)="cartService.resetChangeSupplier()">
  <div class="center-wrapper" (click)="stopPropagation($event)">
    <div class="header">
      <i class="fa fa-th"></i>
      <span class="title">{{'CART.SUPPLIER_SELECTION' | translate}}</span>
      <i class="fa fa-times" (click)="cartService.showSupplierSelection = false"></i>
    </div>
    <div class="supplier-change-content">
      <div class="loading-supplier text-center" *ngIf="cartService.getLoading()">
        <i class="fa fa-spinner"
           [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"></i>
      </div>
      <div class="supplier-change-list" *ngIf="!cartService.getLoading()">
        <div class="row supplier-row clickable" *ngFor="let supplier of cartService.getAvailabilities()" (click)="setSupplier(supplier)">
          <div class="col-2 supplier-icon d-inline-block"><img [supplier]="supplier.supplier"
                                                               [logo-color]="!supplier?.loading"></div>
          <div class="col-4 price text-center d-inline-block">
            <span *ngIf="userService.sale">{{supplier.sellOutNetPerPieceFormatted}}</span>
            <span *ngIf="!userService.sale">{{supplier.sellInNetPerPieceFormatted}}</span>
          </div>
          <div class="col-5 availability d-inline-block">
            <div class=" a-availability">
              <i *ngIf="supplier?.loading" class="fa fa-spinner fa-fw"
                 [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i>
              <div *ngFor="let avail of supplier?.schedule" [ngClass]="avail.availability"
                   style="min-width: 50px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                <span class="icon"></span>
                <span *ngIf="!supplier?.loading && avail.availability != 'SPECIAL_ORDER'">
                                    <span *ngIf="avail.quantity > 0">{{avail.quantity}}</span>
                                    <span *ngIf="avail.depotName"> {{avail.depotName}}</span>
                                    <span *ngIf="avail.deliveryDate"> {{avail.deliveryDate}}</span>
                                    <span *ngIf="avail.deliveryTime"> {{avail.deliveryTime}}</span>
                                </span>
                <span *ngIf="avail.availability == 'SPECIAL_ORDER'"> {{ 'SUPPLIERS.SPECIAL_ORDER_INFO' | translate}}</span>
                <span class="info-text"
                      *ngIf="!avail.depotName && avail.availability != 'SPECIAL_ORDER'"
                      [ngClass]="{'light' :  (avail.availability != 'AVAILABLE'
                                      && avail.availability != 'ASAP' && avail.availability != 'SPECIAL_ORDER'
                                      && avail.availability != 'OWN_STOCK' && avail.availability != 'SHIPPING') }">
                                    {{ 'SUPPLIERS.' + avail.availability | translate}}
                                </span>
              </div>
            </div>
          </div>
          <div class="col-1 checkbox text-center d-inline-block">
            <input [type]="'radio'"
                   class="float-right"
                   [checked]="cartService.selectedSupplier === supplier.supplier">
          </div>
        </div>
      </div>
      <div class="button-row">
        <button class="base-btn change-supplier"
                (click)="selectSupplierForArticle(undefined, undefined, undefined)">{{'CART.NO_SUPPLIER' | translate}}</button>
        <button class="base-btn success select-supplier" [disabled]="cartService.getLoading() || !cartService.selectedSupplier"
                (click)="selectSupplierForArticle(cartService.selectedSupplier, cartService.dealerArticleNumber, cartService.dealerBrandIdentifier)">{{'CART.SELECT_SUPPLIER' | translate}}</button>
      </div>
    </div>
  </div>
</div>
