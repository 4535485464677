<div class="setting-list">

    <div class="club-setting-title">
        <h1>
            <strong>
                {{'CLUB_ADMIN.SETTING_NAME.' + settingName | translate}}
            </strong>
        </h1>
    </div>

    <ng-container *ngIf="isTabbedSetting()">
        <ngb-tabset class="setting-tabset" (tabChange)="resetSettingDetailsSelection()">
            <ngb-tab *ngFor="let tab of getTabNames()" title="{{'GENERAL.MODE.' + tab | translate}}">
                <ng-template ngbTabContent>
                    <app-dynamic-setting-drag-list [settingList]="getSettingListByTabName(tab)" [settingName]="settingName"></app-dynamic-setting-drag-list>
                </ng-template>
            </ngb-tab>
        </ngb-tabset>
    </ng-container>
    <ng-container *ngIf="!isTabbedSetting()">
        <app-dynamic-setting-drag-list [settingList]="settingList" [settingName]="settingName"></app-dynamic-setting-drag-list>
    </ng-container>

    <div class="button-row" *ngIf="configService.hasUnsavedSettings(settingName)">
        <button class="base-btn success" (click)="configService.saveClubConfig()">
            <i class="fa fa-check mr-2"></i>
            <span>{{'GENERAL.SAVE_CHANGES' | translate}}</span>
        </button>
    </div>
</div>
