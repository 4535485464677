import {
    AfterViewInit,
    Component
} from '@angular/core';
import {HelperService} from '../../shared/services/helper.service';
import {AutovistaService} from '../services/autovista.service';

@Component({
    selector: 'app-autovista',
    templateUrl: './autovista.component.html',
    styleUrls: ['./autovista.component.scss']
})
export class AutovistaComponent{

    constructor(public autovistaService: AutovistaService,
                public helperService: HelperService) {}

    public switchToImage(imageNumber: string) {
        if (this.autovistaService.selectedImageCode.toString() !== imageNumber) {
            this.autovistaService.imageLoading = true;
            this.autovistaService.getImageXML(this.autovistaService.svgHolder.detailImages[imageNumber].url);
            this.autovistaService.selectedImage = this.autovistaService.svgHolder.detailImages[imageNumber];
            this.autovistaService.filter = this.autovistaService.svgHolder.detailImages[imageNumber].filter;
            this.autovistaService.selectedImageCode = imageNumber;
        }
    }

    public getIsChecked(imageNumber: any) {
        return this.autovistaService.selectedFilter.get('NO_COMPAT')
            === this.autovistaService.svgHolder.detailImages[imageNumber].filter.NO_COMPAT[0].criteriaCode;
    }

    public getLabelText(imageNumber: any) {
        if (this.autovistaService.svgHolder.detailImages[imageNumber].filter
            && this.autovistaService.svgHolder.detailImages[imageNumber].filter.NO_COMPAT
            && this.autovistaService.svgHolder.detailImages[imageNumber].filter.NO_COMPAT.length > 0) {
            return this.autovistaService.svgHolder.detailImages[imageNumber].filter.NO_COMPAT[0].name;
        } else {
            return undefined;
        }
    }

    public selectAdditionalPart(part: any) {
        this.autovistaService.selectedArticle = part;
        this.autovistaService.selectedArticleChange.emit(this.autovistaService.selectedArticle);
    }

    public selectSVG(imageUrl: string) {
        this.autovistaService.getImageXML((imageUrl + '.svgz'), false, this.autovistaService.selectedCar, true);
        this.autovistaService._active = undefined;
        this.autovistaService.selectedImageCode = imageUrl.substring(imageUrl.lastIndexOf('/')+ 1);
        this.autovistaService.selectedImageUrl = imageUrl;
    }

    public getSubGroupImageKeys() {
        if (this.autovistaService.subGroupDetails) {
            return Object.keys(this.autovistaService.subGroupDetails.images);
        } else {
            return [];
        }
    }
}
