<div class="row">
  <div class="col-12">
    <h1>{{'USER_PROFILE.LOGIN_MANAGEMENT.CHANGE_PASSWORD' | translate}}</h1>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col">
        <app-material-form-input
                [label]="'USER_PROFILE.USER_MANAGEMENT.USERNAME' | translate"
                [disabled]="true"
                [(text)]="userName"></app-material-form-input>
      </div>
    </div>
    <form name="passwordForm" [formGroup]="passwordForm">

    <div class="row">
      <div class="col">
        <app-material-form-input
                [disabled]="(userService.authMethode$ | async) === 'N4SSO'"
                [label] = "'USER_PROFILE.PASSWORD' | translate"
                [type] = "'password'"
                [validationErrorMessage]="'VALIDATION.ERROR_PASSWORD_CHANGE' | translate"
                [showValidation]="true"
                [(text)] = "newPassword">
        </app-material-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-material-form-input
                [disabled]="(userService.authMethode$ | async) === 'N4SSO'"
                [label]="'USER_PROFILE.PASSWORD_REPEAT' | translate"
                [type] = "'password'"
                [validationErrorMessage]="'VALIDATION.ERROR_PASSWORD_CHANGE' | translate"
                [showValidation]="true"
                [showError]="newPassword !== repeatPass"
                [(text)]="repeatPass">
        </app-material-form-input>
      </div>
    </div>
    </form>
  </div>

</div>
<div class="row">
  <div class="col">
    <button class="base-btn success" (click)="changeUserPW()"
            [disabled]="(userService.authMethode$ | async) === 'N4SSO'">
      <i class="fa fa-check"></i>
      {{'USER_PROFILE.CHANGE_PASSWORD' | translate}}
    </button>
  </div>
</div>
<app-error-handler *ngIf="errorHandlerService.newError"></app-error-handler>
