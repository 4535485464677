import {Injectable} from '@angular/core';


@Injectable()
export class MessageHandlerService {
    public hasMessage = false;

    public errorCode: string = '';
    public titleText: string = '';
    public textFirst: string = '';
    public textSecond: string = '';
    public btn: string[] = [];

    constructor() {}

    public reset() {
        this.hasMessage = false;
        this.errorCode = '';
        this.titleText = '';
        this.textFirst = '';
        this.textSecond = '';
        this.btn = [];
    }

    public showErrorMessage(status: any, errorObject?: any) {
        this.reset();
        if (status < 600 && status > 499) {
            this.hasMessage = true;
            this.errorCode = status;
            this.titleText = 'Fehler ' + this.errorCode;
            this.textFirst = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.';
            this.textSecond = 'Sollte der Fehler weiterhin bestehen, wenden Sie sich bitte an Ihren Serviceadministarator.';
            this.btn = ['OK'];
        }
    }

}
