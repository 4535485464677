import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    EventEmitter,
    AfterViewChecked,
    ElementRef
} from '@angular/core';
import {UserService} from '../shared/services/user/user.service';
import {CarService} from '../shared/services/car/car.service';
import {TecRmiRestService} from '../shared/services/tecrmi/tecrmi.rest.service';
import {IWorkItem} from './interfaces/work-tree';
import {TranslateService} from '@ngx-translate/core';
import {WorkService} from './services/work.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {catAnimation} from '../shared/animations/general.animation';
import {bounceIn, sidebar} from '../shared/animations/sidebar';
import {CartService} from '../cart/services/cart.service';
import {Subscription} from 'rxjs/Rx';
import {ArticlesService} from '../articles/services/articles.service';
import {HelperService} from '../shared/services/helper.service';
import {IGroup, ISubGroup, IWorkTreeBodyGroup} from '../shared/interfaces/DTO/work/workTreeBodyGroup';
import {ConfigService} from '../shared/services/config.service';
import {StateService} from '../shared/services/state.service';
import {AutovistaService} from "../graphical-part-search/services/autovista.service";

@Component({
    selector: 'app-work-data',
    templateUrl: 'work-data.component.html',
    styleUrls: ['./work-data.component.scss'],
    host: {
        '(document:click)': 'onOutsideClick($event)'
    },
    animations: [catAnimation, sidebar, bounceIn]
})
export class WorkDataComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {

    public adcTypeId: number;
    public nxDetails: any;
    public selectedQualifier: string;
    public selectedGroup: any = [{'id': 0}];
    public selectedItem: string;
    public _selectedItem: IWorkItem;
    public subGroup: any;
    public selectedWork: any;
    public selectedName: string;
    public searching: boolean;
    public searchOpen: boolean;
    public work: IWorkTreeBodyGroup[];
    public workLoading: boolean = false;
    public searchString: string;
    public showScrollButton: boolean = false;
    public showArticleSize: boolean = false;
    public showSearchResultSupplier: boolean = false;
    private mountPosIds: number[];
    private workDataSubscription: Subscription;
    private carsubscription: Subscription;
    private searchTimeStamp: Date;
    private eventStream: EventEmitter<any> = new EventEmitter<any>();
    private debounceTime: number = 400;

    @ViewChild('t6', {static: true}) ngbTabSet;
    @ViewChild('searchBox', {static: true}) searchBox;
    @ViewChild('workSearchInput', {static: true}) searchInput;

    constructor(public userService: UserService,
                public carService: CarService,
                public autovistaService: AutovistaService,
                private translate: TranslateService,
                private _eref: ElementRef,
                private tecRmiRestService: TecRmiRestService,
                public location: Location,
                public route: ActivatedRoute,
                public workService: WorkService,
                public router: Router,
                private changeDetectorRef: ChangeDetectorRef,
                private cartService: CartService,
                public articlesService: ArticlesService,
                public helperService: HelperService,
                public stateService: StateService,
                public configService: ConfigService) {
        this.searching = false;
        this.stateService.updateWorkContent();
        this.workService.selectFirstTabEmitter.subscribe(() => {
            this.selectFirstTab();
        });

    }

    ngOnInit() {
        this.workService.selectedOption = '';
        this.workService.loadWorkList = false;
        this.workService.selectedItem = undefined;
        this.searching = false;
        this.workLoading = undefined;
        this.workService.expanded_right = false;
        this.startupCheck();
        this.workService.getPosByCart();
        this.workService.showDependencyError = false;
        this.carsubscription = this.carService.selectedCarChanged$.subscribe(state => this.startupCheck());
    }

    ngOnDestroy() {
        if (this.workDataSubscription) {
            this.workDataSubscription.unsubscribe();
        }
        if (this.carsubscription) {
            this.carsubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.cartService.onCartChange.subscribe((step) => {
            if (this.ngbTabSet && this.ngbTabSet.tabs) {
                this.selectFirstTab();
            }
        });
        if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }

        this.eventStream.subscribe((input) => {
            const me = this;
            this.searchTimeStamp = new Date();
            setTimeout(function () {
                if (new Date().getTime() - me.searchTimeStamp.getTime() > me.debounceTime) {
                    me.workSearch();
                }
            }, this.debounceTime);
        });
    }

    public setArticleSize(newSize: string): void {
        this.articlesService.setSelectedSearchResultSize(newSize);
        this.showArticleSize = false;
    }

    public setSearchResultSupplier(type: string, supplier: string): void {
        if (type === 'AFTER_MARKET') {
            this.workService.selectedSearchResultSupplier = supplier;
        } else {
            this.workService.selectedSearchResultOeSupplier = supplier;
        }

        this.workService.getAavailability(true, type);
        this.showSearchResultSupplier = false;
    }

    public onOutsideClick(event: any): void {
        if (!this._eref.nativeElement.contains(event.target)) {
            this.showSearchResultSupplier = false;
            this.showArticleSize = false;
        }
    }

    public printIframe(frameId: string) {
        const frame = window.frames[frameId];
        if (!frame) {
            return;
        }

        if (this.helperService.browserIsIE()) {
            frame.document.execCommand('print', false, null);
        } else {
            const frames: any = document.getElementById(frameId);
            frames.contentWindow.focus();
            frames.contentWindow.print();
        }
    }

    public showNavigation() {
        this.userService.setNavigationLeft();
        if (this.ngbTabSet && this.ngbTabSet.tabs) {
            this.ngbTabSet.select(this.ngbTabSet.tabs.first.id);
        }
    }

    public showFilter() {
        if (this.hasFilter() && this.ngbTabSet && this.ngbTabSet.tabs) {
            this.userService.setNavigationLeft();
            this.ngbTabSet.select(this.ngbTabSet.tabs.last.id);
        }
    }

    public hasFilter() {
        return (this.workService.searchResult &&
            this.workService.searchResult.possibleFilters &&
            this.workService.searchResult.possibleFilters.length > 0
        );
    }

    ngAfterViewChecked() {
        if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    public selectFirstTab() {
        if (this.ngbTabSet && this.ngbTabSet.tabs) {
            this.ngbTabSet.select(this.ngbTabSet.tabs.first.id);
        }
    }

    public toggleShowScrollButton(event: boolean) {
        if (event !== this.showScrollButton) {
            this.showScrollButton = event;
            if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        }
    }

    public onWorkSearch($event: any) {
        this.eventStream.emit($event);
    }

    public workSearch() {
        this.searchOpen = this.searchString && this.searchString.toString().trim().length > 0;
    }

    public loading() {
        if (this.workLoading) {
            return true;
        }
        return false;
    }

    public resetSearchBox(searchBox: any) {
        if (searchBox && searchBox.searchResult) {
            searchBox.searchResult = [];
        }
    }

    private focusInput(searchinput) {
        searchinput.focus();
    }

    public resetSearchFilter() {
        this.searchString = '';
        this.searchBox.searchResult = [];
        this.setWorkItems(this.subGroup, this.subGroup.name);
    }

    private startupCheck() {
        const selectedCar = this.carService.selectedCar,
            adcTypeId = (selectedCar && selectedCar.adcTypeId) ? selectedCar.adcTypeId : 0;

        this.adcTypeId = adcTypeId;
        this.nxDetails = false;
        this.selectedQualifier = undefined;

        this.updateSelectedCar();
    }

    public reset() {
        this.workService.selectedItems = undefined;
        this.selectedGroup = undefined;
        this.workService.expanded_right = false;
        this.selectedItem = '';
        this.workService.removeWork();
        this.searching = false;
        this.workService.selectedOption = '';
        this.workService.searchResult = undefined;
        this.searchString = '';
        if (this.searchBox) {
            this.searchBox.searchResult = [];
        }
        this.workService.showDocumentWithLink = undefined;
        this.searchOpen = false;
    }

    public updateSelectedCar() {
        this.searching = false;
        this.workService.expanded_right = false;
        const selectedCar = this.carService.selectedCar,
            adcTypeId = (selectedCar && selectedCar.adcTypeId) ? selectedCar.adcTypeId : 0;
        this.adcTypeId = adcTypeId;
        if (this.adcTypeId > 0) {
            this.getWorkData();
        }
    }

    public search(searchString: any) {
        this.selectedName = '';
        this.selectedName = searchString.target.value;
        this.workService.expanded_right = false;
        if (this.selectedName && this.selectedName.length >= 2) {
            this.searching = true;
            const re = new RegExp(this.selectedName.toLowerCase().replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), 'g');
            this.selectedWork = [];
            const list = this.work[0];
            for (const group of list.groups) {
                for (const subGroup of group.subGroups) {
                    for (const item of subGroup.items) {
                        if (item.mountPositionText.toLowerCase().search(re) !== -1) {
                            this.selectedWork.push(item);
                        }
                    }
                }
            }
            if (this.selectedWork.length === 0) {
                this.selectedWork = false;
            } else {
                // this.selectedId = 0;
            }
        }
        if (!this.selectedName || this.selectedName.length === 0) {
            this.searching = false;
        }
    }


    public getWorkData() {
        if (this.userService.allow('WORK-DATA')) {
            this.workLoading = true;
            this.workDataSubscription = this.tecRmiRestService.getWorkData(this.adcTypeId).subscribe(
                (response) => {
                    this.work = response;
                    this.workLoading = false;
                    if (this.work && this.work.length > 0) {
                        this.selectedQualifier = this.work[0].displayName;
                        this.workService.selectedItems = undefined;
                        this.selectedGroup = undefined;
                        this.selectedItem = '';
                        this.stateService.changeWorkContent('CONTENT');
                    } else {
                        this.stateService.changeWorkContent('EMPTY_VEHICLE_DATA');
                    }
                },
                (error) => {
                    if (error.status === 502) {
                        this.stateService.changeWorkContent('LOCKED');
                    }
                }
            );
        }
    }

    public setWorkQualifier() {
        if (this.selectedQualifier === this.work[1].displayName) {
            this.selectedQualifier = this.work[0].displayName;
        } else {
            this.selectedQualifier = this.work[1].displayName;
        }

        this.workService.selectedItems = undefined;
        this.selectedGroup = undefined;
        this.selectedItem = '';
        this.searching = false;
        this.searchOpen = false;
    }

    public setWorkGroup(group: IGroup, noRoute?: boolean) {
        this.workService.selectedItem = undefined;
        this.workService.showDocumentWithLink = undefined;
        if (!noRoute) {
            this.mountPosIds = [];
            this.router.navigate(['/work']);
        }
        this.workService.selectedOption = '';
        if (this.selectedGroup === group) {
            this.selectedGroup = undefined;
            this.workService.selectedItems = undefined;
            this.selectedItem = '';
        } else {
            this.selectedGroup = group;
            this.selectedItem = '';
            this.workService.selectedItems = undefined;
            this.searching = false;
            this.searchOpen = false;
        }
    }

    public setWorkItems(workStep: ISubGroup, name: string, noRoute?: boolean) {

        this.workService.loadWorkList = true;
        this.workService.showDocumentWithLink = undefined;

        if (this.searchBox) {
            this.searchBox.searchResult = []
        }

        this.tecRmiRestService.getWorkStepsBySubGroup(this.adcTypeId, workStep.ltQualColId, workStep.id).subscribe((response) => {
            this.workService.selectedItems = response;
            this.workService.loadWorkList = false;
            if (noRoute) {
                for (const item of this.workService.selectedItems) {
                    if (this.mountPosIds.indexOf(item.itemMpId) > -1) {
                        item.highlight = true;
                    }
                }
            }
        });

        this.workService.selectedItem = undefined;
        if (!noRoute) {
            this.mountPosIds = [];
            this.router.navigate(['/work']);
        }
        this.workService.selectedOption = '';
        this.selectedItem = name;
        this._selectedItem = undefined;
        this.searching = false;
        this.searchOpen = false;
        this.workService.resultItems = [];
    }

    public itemSelected(item: any) {
        this.workService.expanded_right = true;
        this.workService.setSelectedItem(item);
    }

    public searchItemSelected(value: any) {
        this.mountPosIds = [];

        for (const item of value.subGroup.items) {
            this.mountPosIds.push(item.mountPositionId);
        }

        for (const tree of this.work) {
            for (const group of tree.groups) {
                if (group.id === value.group.id) {
                    this.setWorkGroup(group, true);
                    for (const subGroup of group.subGroups) {
                        if (subGroup.id === value.subGroup.id) {
                            this.subGroup = subGroup;
                            this.setWorkItems(subGroup, subGroup.name, true);
                        }
                    }
                }
            }
        }

    }

    public resetSearchBoxSearchResult() {
        if (this.searchBox) {
            this.searchBox.searchResult = [];
        }
    }
}
