import {AfterViewInit, Component, ComponentRef, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CarRestService} from '../../../services/car/car.rest.service';
import {ICarModel} from '../../../services/car/interfaces/car-model';
import {ICarType} from '../../../services/car/interfaces/car-type';
import {ICarProducer} from '../../../services/car/interfaces/car-producer';
import {Subscription} from 'rxjs/Rx';
import {ICarSearchFields} from '../interfaces/car-search-fields';
import {CarService} from '../../../services/car/car.service';
import {ICar} from '../../../interfaces/DTO/car/car';

@Component({
    selector: 'app-car-by-model',
    templateUrl: './car-by-model.component.html'
})
export class CarByModelComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() selected;
    @Output() applyCars: EventEmitter<ICar[]> = new EventEmitter();
    public carModels: ICarModel[] = [];
    public carProducers: ICarProducer[] = [];
    public carTypes: ICarType[] = [];
    public vehicleType: string;

    private updateCarProducersTime: Date;

    public carProducersSearchSubscription: Subscription;
    public carModelsSearchSubscription: Subscription;
    public carTypesSearchSubscription: Subscription;
    public carSearchFields: ICarSearchFields = {
        hsn: '',
        tsn: '',
        producer: null,
        model: null,
        type: null,
        vin: '',
        motorCode: '',
        tecDocNumber: null
    };

    constructor(private carRestService: CarRestService,
                public carService: CarService) {
        this.vehicleType = 'PC';
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.updateCarProducers();
    }

    ngOnChanges() {
        this.carService.selected = 'KBA_NUMBER';
    }

    public onVehicleTypeChange(vehicleType: string) {
        this.vehicleType = vehicleType;
        this.updateCarProducers();
    }

    public updateCarProducers() {


        this.carProducers = [];
        this.carModels = [];
        this.carTypes = [];

        this.carProducersSearchSubscription = this.carRestService.getCarProducers(this.vehicleType).subscribe(
            (response: ICarProducer[]) => {
                this.carProducers = response;
                for (let i = 0; i < this.carProducers.length; i++) {
                    if (this.carProducers[i].fav) {
                        this.carProducers[i].favName = 'Favoriten';
                    } else {
                        this.carProducers[i].favName = 'weitere ...';
                    }
                }
            }
        );
    }

    public updateCarModels() {
        this.carModels = [];
        this.carTypes = [];
        this.carModelsSearchSubscription = this.carRestService.getCarModelsByProducerId(
            this.carSearchFields.producer,
            this.vehicleType
        ).subscribe(
            (response: ICarModel[]) => this.carModels = response
        );
    }

    public updateCarTypes() {
        this.carTypes = [];
        this.carTypesSearchSubscription = this.carRestService.getCarTypesByProducerIdAndModelId(
            this.carSearchFields.producer,
            this.carSearchFields.model,
            this.vehicleType
        ).subscribe(
            (response: ICarType[]) => this.carTypes = response
        );
    }

    public onCarProducerSelect() {
        this.carModels = [];
        this.carSearchFields.model = null;
        this.updateCarModels();
    }

    public onCarModelSelect() {
        this.carTypes = [];
        this.carSearchFields.type = null;
        this.updateCarTypes();
    }


    public onCarTypeSelect() {
        this.carRestService.getCarsByKTypeNumber(this.carSearchFields.type).subscribe(
            (response: ICar[]) => {
                this.applyCars.emit(response);
            }, (error) => {
                if (error.status === 409) {
                    this.carService.handleCarConflict();
                }
            });
    }
}
