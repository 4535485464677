import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {CheckoutService} from '../../service/checkout.service';

@Component({
    selector: 'app-retry',
    templateUrl: './retry.component.html'
})
export class RetryComponent implements OnInit {
    @Output() public ok: EventEmitter<any> = new EventEmitter<any>();
    @Input() public errorType: string;

    constructor(public checkoutService: CheckoutService) {
    }

    ngOnInit() {
    }

    public okButton() {
        this.ok.emit();
    }

}
