import {Component, OnInit} from '@angular/core';
import {CompanyAdminService} from '../services/company-admin.service';
import {AdminService} from '../../club/services/admin.service';
import {ErrorHandlerService} from '../../../shared/components/error-handler/services/error-handler.service';
import {UserService} from '../../../shared/services/user/user.service';
import {AccountRestService} from '../../profile/services/account.rest.service';

@Component({
    selector: 'app-firmdata',
    templateUrl: './firmdata.component.html'
})

export class FirmdataComponent implements OnInit {

    public firmData: any;

    constructor(public companyAdminService: CompanyAdminService,
                private errorService: ErrorHandlerService,
                public userService: UserService,
                private accountService: AccountRestService,
                public adminService: AdminService) {
        this.accountService.getFirmData().subscribe(
            (response) => {
                this.firmData = response;
            }
        );
    }

    ngOnInit() {
        this.errorService.newError = false;
    }


}
