<div class="maintenance-data">
  <div class="heading blue" *ngIf="location.path().endsWith('/maintenance/info/warnings')">
    <span routerLink="/maintenance" class="clickable" (click)="maintenanceService.lastState = ''"><i class="fa fa-th" aria-hidden="true"></i></span>
    <span routerLink="/maintenance" class="clickable">{{'CLUB_SETTINGS.NAVIGATION.MAINTENANCE' | translate}}</span>
    <span routerLink="/maintenance/info" class="clickable"><i class="fa fa-caret-right"></i>{{'MAINTENANCE.INFO' | translate}}</span>
    <span routerLink="/maintenance/info/warnings" ><i class="fa fa-caret-right"></i>{{'CAR_HISTORY.WARNING.TITLE' | translate}}</span>
    <span>
      <button class="print-btn pull-right" (click)="createPdf()" *ngIf="!maintenanceService.showPdf && maintenanceService?.selectedCarInfos?.length > 0">
        <i class="fa fa-file-pdf-o"></i>
        <span>{{'PRINT_VIEW.TITLE' | translate}}</span>
      </button>
      <button class="print-btn pull-right" (click)="maintenanceService.showPdf = !maintenanceService.showPdf" *ngIf="maintenanceService.showPdf">
        <i class="fa fa-close"></i>
        <span>{{'PRINT_VIEW.CLOSE' | translate}}</span>
      </button>
    </span>
  </div>

</div>
<div class="panel-content center-content print-content"
     [ngClass]="{'setting' : !maintenanceService.showPdf}">
  <button class="base-btn pull-right" (click)="printPdf()"  *ngIf="maintenanceService.showPdf && !helperService.browserIsEdge() && !helperService.browserIsIE()">
    <i class="fa fa-print"></i> {{ 'PRINT_VIEW.PRINT' | translate }}
  </button>

  <button class="base-btn pull-right" (click)="downloadPdf()" *ngIf="maintenanceService.showPdf">
    <i class="fa fa-save"></i> {{ 'PRINT_VIEW.GENERATE_PDF' | translate }}
  </button>

  <h1><strong>{{'CAR_HISTORY.WARNING.TITLE' | translate}}</strong></h1>

  <ng-container *ngIf="!maintenanceService.showPdf">
    <div *ngFor="let serviceInfo of maintenanceService?.selectedCarInfos">
      <h5><strong>{{serviceInfo.headline}}</strong><br/>
        <small class="classification {{serviceInfo.classification}}">
          {{'SERVICE_INFO_TYPES.' + serviceInfo.classification.toUpperCase() | translate}}<br/>
          <i class="valid-date {{serviceInfo.classification}}">{{'CAR_HISTORY.WARNING.TIME_FROM_TO' | translate:{'timeFrom': serviceInfo.validFrom, 'timeTo': serviceInfo.validTo} }}</i>
        </small>
      </h5>
      <div class="info-text">{{serviceInfo.text}}</div>
      <hr/>
    </div>
  </ng-container>

  <div class="alert alert-info text-center" role="alert" *ngIf="maintenanceService?.selectedCarInfos?.length === 0"> <i class="fa fa-info-circle mr-2"></i> {{'GENERAL.NO_RESULTS' | translate}}</div>

  <div class="print-preview" *ngIf="maintenanceService.showPdf" style="top: 0!important;" >
    <app-default-loading-page *ngIf="maintenanceService.pdfLoading"></app-default-loading-page>

    <pdf-viewer
            [src]="maintenanceService.pdf"
            [autoresize]="false"
            [render-text]="false"
            [show-all]="true"
            style="display: block;"
    ></pdf-viewer>
  </div>
</div>
