import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {GlobalAdminService} from '../services/global-admin.service';
import {Location} from '@angular/common';
import {UserService} from '../../../shared/services/user/user.service';
import {AdminService} from '../../club/services/admin.service';
import {workListAnim} from '../../../shared/animations/general.animation';

@Component({
    selector: 'app-global-griphistory',
    templateUrl: './global-griphistory.component.html',
    styleUrls: ['./global-griphistory.component.scss'],
    animations: [workListAnim]
})
export class GlobalGriphistoryComponent implements OnInit, AfterViewInit {

    public searchTypes: string[] = ['ORDER_ID', 'EXTERNAL_ID', 'FIRM_ID', 'FIRM_NAME', 'REFERENCE'];
    public searchType: string = 'ORDER_ID';
    public searchTerm: string = '';
    public selectedClub: number = null;
    public endDate: string = '';
    public startDate: string = '';

    constructor(public globalAdminService: GlobalAdminService,
                public location: Location,
                public userService: UserService,
                public adminService: AdminService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.adminService.getClubs();

        this.globalAdminService.gripsOrderList = undefined;

        if (this.endDate.length === 0 || this.startDate.length === 0) {
            const now = new Date();
            let month = (now.getMonth() + 1).toString();
            if (month.length === 1) {
                month = '0' + month;
            }
            let day = now.getDate().toString();
            if (day.length === 1) {
                day = '0' + day;
            }
            this.endDate = now.getFullYear() + '-' + (month) + '-' + day;

            const oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));
            let oldMonth = (oneMonthAgo.getMonth() + 1).toString();
            if (oldMonth.length === 1) {
                oldMonth = '0' + oldMonth;
            }
            let oldDay = oneMonthAgo.getDate().toString();
            if (oldDay.length === 1) {
                oldDay = '0' + oldDay;
            }
            this.startDate = oneMonthAgo.getFullYear() + '-' + (oldMonth) + '-' + oldDay;
        }
    }

    ngAfterViewInit() {
        if (this.globalAdminService.resultGripItems && this.globalAdminService.resultGripItems.length > 0) {
            this.globalAdminService.emitItems.emit(this.globalAdminService.resultGripItems);
            if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges()
            }
        }
    }


    public getPlacedGripOrders($event?: any) {
        this.globalAdminService.resultGripItems = [];

        if (!$event) {
            this.globalAdminService.gripHistoryRequest = {
                clubId: null,
                firmName: '',
                firmId: null,
                name: '',
                id: '',
                reference: '',
                startDate: '',
                endDate: '',
                externalId: '',
                page: 0,
                pageSize: 20
            }
        }


        switch (this.searchType) {
            case 'ORDER_ID':
                this.globalAdminService.gripHistoryRequest.orderId = this.searchTerm;
                break;
            case 'EXTERNAL_ID':
                this.globalAdminService.gripHistoryRequest.externalId = this.searchTerm;
                break;
            case 'FIRM_ID':
                this.globalAdminService.gripHistoryRequest.firmId = parseInt(this.searchTerm);
                break;
            case 'FIRM_NAME':
                this.globalAdminService.gripHistoryRequest.firmName = this.searchTerm;
                break;
            case 'REFERENCE':
                this.globalAdminService.gripHistoryRequest.reference = this.searchTerm;
                break;
        }

        this.globalAdminService.gripHistoryRequest.clubId = this.selectedClub;
        this.globalAdminService.gripHistoryRequest.startDate = this.startDate;
        this.globalAdminService.gripHistoryRequest.endDate = this.endDate;

        if (this.globalAdminService.gripHistoryRequest.endDate.length > 0
            && this.globalAdminService.gripHistoryRequest.endDate.indexOf('T') < 0) {
            this.globalAdminService.gripHistoryRequest.endDate += 'T23:59';
        }

        if (this.globalAdminService.gripHistoryRequest.startDate.length > 0
            && this.globalAdminService.gripHistoryRequest.startDate.indexOf('T') < 0) {
            this.globalAdminService.gripHistoryRequest.startDate += 'T23:59';
        }

        if ($event) {
            this.globalAdminService.gripHistoryRequest.page = ($event - 1);
        }

        this.globalAdminService.getPlacedGripOrders();
    }


    public setClubId($event: any) {
        this.selectedClub = $event.clubId;
        this.getPlacedGripOrders();

    }

    public getClubWithId(id: number) {
        if (this.adminService.clubs) {
            return this.adminService.clubs.filter(club => club.clubId === id)[0];
        }
    }

    public resetSearch() {
        this.globalAdminService.resultGripItems = [];
        this.globalAdminService.gripsOrderList = undefined;
        this.globalAdminService.emitItems.emit([]);
        this.selectedClub = null;
        this.searchTerm = '';
        this.globalAdminService.gripHistoryRequest = {
            clubId: null,
            firmName: '',
            firmId: null,
            name: '',
            id: '',
            reference: '',
            startDate: '',
            endDate: '',
            externalId: '',
            page: 0,
            pageSize: 20
        }
    }
}
