import { Injectable } from '@angular/core';
import {IAllNotification, ICreateNotification, INotificationWrapper} from '../interfaces/DTO/notification/notification';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExternalNotificationRestService {

  constructor(private http: HttpClient) { }

  public getAllOwnNotificationsRest(): Observable<INotificationWrapper> {
    return this.http.get<INotificationWrapper>(
        `${environment.apiUrl}/notification/own`
    );
  }

  public getAllNotificationsRest(): Observable<IAllNotification[]> {
    return this.http.get<IAllNotification[]>(
        `${environment.apiUrl}/notification/all`
    );
  }

  public getNotificationTypesRest(): Observable<string[]> {
    return this.http.get<string[]>(
        `${environment.apiUrl}/notification/types`
    );
  }

  public getNotificationCriteriaRest(): Observable<string[]> {
    return this.http.get<string[]>(
        `${environment.apiUrl}/notification/criteria`
    );
  }

  public deleteNotificationRest(notificationId: number) {
    return this.http.delete(
        `${environment.apiUrl}/notification/${notificationId}`
    );
  }

  public createNotificationRest(notification: ICreateNotification) {
    return this.http.put(
        `${environment.apiUrl}/notification`,
        notification
    );
  }

  public getPossibleClubs() {
    return this.http.get(
        `${environment.apiUrl}/notification/possibleClubs`
    );
  }
}
