<div class="retry-overlay" (click)="okButton()"></div>
<div class="conf-modal center warning" >
  <div class="title-icon">
    <i class="fa fa-warning"></i>
  </div>
  <div class="title-text"><h1>{{'PROMPTS.MESSAGE.RETRY' | translate}}</h1></div>
  <p>{{'TOAST_MESSAGES.ERROR_' + errorType | translate}}</p>
  <div class="modal-footer">
    <div class="base-btn" (click)="okButton()">{{'PROMPTS.BUTTONS.OK' | translate}}</div>
  </div>
</div>
