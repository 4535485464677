import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {CarService} from '../../../shared/services/car/car.service';
import {ArticlesService} from '../../../articles/services/articles.service';
import {Router} from '@angular/router';
import {AssemblyCategoriesService} from '../../../articles/services/assembly-categories.service';
import {Subscription} from 'rxjs';
import {UserService} from '../../../shared/services/user/user.service';
import {DmsDataService} from '../../../shared/services/dms-data.service';
import {PdfService} from '../../../shared/services/pdf.service';
import {CartService} from '../../../cart/services/cart.service';
import {ConfigService} from '../../../shared/services/config.service';
import {HelperService} from '../../../shared/services/helper.service';
import {CompanyAdminService} from '../../../admin/company/services/company-admin.service';
import {ICar} from '../../../shared/interfaces/DTO/car/car';
import {TranslateService} from '@ngx-translate/core';
import {CustomerDataService} from '../../../shared/services/customerData.service';
import {AdminService} from '../../../admin/club/services/admin.service';
import {CartRestService} from '../../../cart/services/cart.rest.service';
import {GlobalAdminService} from '../../../admin/global/services/global-admin.service';

@Component({
    selector: 'app-car-history',
    templateUrl: './car-history.component.html',
    host: {
        '(document:click)': 'outSideClick($event)',
    },
})
export class CarHistoryComponent implements OnInit, OnDestroy {
    public showMenu: boolean = false;
    public showInfos: boolean = false;
    public selectedItem: ICar;
    public currentUrl: string;
    private routerSubscription: Subscription;
    public tooltipDelay: number = 300;
    public hsn: number;
    public tsn: string;

    constructor(
        private router: Router,
        public dmsDataService: DmsDataService,
        public carService: CarService,
        public userService: UserService,
        private pdfService: PdfService,
        public configService: ConfigService,
        public articleService: ArticlesService,
        public assemblyCategoriesService: AssemblyCategoriesService,
        private _eref: ElementRef,
        public helperService: HelperService,
        private cartService: CartService,
        private translate: TranslateService,
        public companyAdminService: CompanyAdminService,
        public customerDataService: CustomerDataService,
        public adminService: AdminService,
        public cartRestService: CartRestService,
        public globalAdminService: GlobalAdminService
    ) {
    }

    private outSideClick(event): void {
        if (!this._eref.nativeElement.contains(event.target)) {
            this.configService.showExternalTools = false;
            this.showMenu = false;
        }
    }

    public navigateToCarSelection(): void {
        const path: string = '';
        this.carService.ejectCar();
        if ((this.cartService.currentCart && this.cartService.currentCart.count > 0)
            && (this.userService.getCarChangeSettings() === 'PER_CAR')) {
            this.cartService.createCart();
        }
        this.articleService.resetAll();
        void this.router.navigate([path]);
        this.assemblyCategoriesService.updateAssemblyCategories(0);
        this.articleService.resetToDefaultTree();
    }

    ngOnInit(): void {
        this.carService.updateCarHistory();
        this.currentUrl = this.router.url;
        this.routerSubscription = this.router.events.subscribe((event: any) => {
            if (event?.url) {
                this.currentUrl = event.url;
            }
        });
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }

    public onCarChange(car: ICar): void {
        this.dmsDataService.dmsData = '';
        this.pdfService.updateDmsData();
        this.carService.selectCar(car);
        this.customerDataService.resetDataHolder();
        this.showMenu = false;
        switch (this.currentUrl) {
            case '/':
                if (this.configService.getEntryPoint()) {
                    void this.router.navigate([this.configService.getEntryPoint()])
                } else {
                    void this.router.navigate(['/articles']);
                }
                break;
            default:
                void this.router.navigate(['/articles']);
                break;
        }
    }

    public getCartCreationBehavior(): boolean {
        if (this.userService.accountData && this.userService.accountData.settings) {
            for (const setting in this.userService.accountData.settings) {
                if (setting === 'cartCreationBehaviour') {
                    return this.userService.accountData.settings[setting];
                }
            }
            return false;
        }
    }

    public getNewDisplayName(car): string {
        const newDisplayName = car.carProducerName + ' ' + car.carModelName + ' ' + car.carTypeName + ' ' + '(' + car.carTypePowerKW
            + this.translate.instant('CAR_HISTORY.TOOLTIP.LABELS.KW') + '/' + car.carTypePowerPS
            + this.translate.instant('CAR_HISTORY.TOOLTIP.LABELS.HP') + ') '
        return newDisplayName;
    }


    public onCarDeselect(): void {
        this.dmsDataService.dmsData = '';
        this.pdfService.updateDmsData();
        this.carService.ejectCar();
        this.articleService.resetAll();
        this.articleService.resetToDefaultTree();
        this.assemblyCategoriesService.updateAssemblyCategories(0);
        void this.router.navigate(['/articles']);
    }

}
