import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {PartnerService} from "../shared/services/partner/partner.service";
import {ErrorHandlerService} from "../shared/components/error-handler/services/error-handler.service";
import {ConfigService} from '../shared/services/config.service';
import {IPartnerPageCollection} from './interfaces/partnerPage';
import {StateService} from '../shared/services/state.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

    public hasError: any;
    public selectedPageType: any;
    public searchString: string = '';

  constructor(public partnerService: PartnerService,
              public errorHandlerService: ErrorHandlerService,
              public stateService: StateService,
              public configService: ConfigService) {

      this.stateService.updatePartnerContent();

      this.partnerService.hasNewPartnerData.subscribe((partnerData: IPartnerPageCollection) => {
          if (this.partnerService.getPartnerPageKeys() && this.partnerService.getPartnerPageKeys().length > 0) {
              this.selectedPageType = this.partnerService.getPartnerPageKeys()[0];
          }
      });
  }

  ngOnInit() {
      this.partnerService.getPartner();
      this.errorHandlerService.newError = false;

      if (this.partnerService.getPartnerPageKeys() && this.partnerService.getPartnerPageKeys().length > 0) {
          this.selectedPageType = this.partnerService.getPartnerPageKeys()[0];
      }
  }

  public getPageList() {
      if (this.partnerService.partnerPages && this.partnerService.partnerPages.partnerPages) {
          if (this.searchString && this.searchString.length > 0) {
              const reducedList = [];
              for (const page of this.partnerService.partnerPages.partnerPages[this.selectedPageType]) {
                  if (page.text.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1) {
                      reducedList.push(page);
                  }
              }

              return reducedList;
          } else {
              return this.partnerService.partnerPages.partnerPages[this.selectedPageType];
          }
      }
  }

  public selectType(pageType: any) {
      if (pageType) {
          this.selectedPageType = pageType;
      }
  }

    public openLink(url: string) {
    try {
        if (url.indexOf('http://') < 0 && url.indexOf('https://') < 0) {
            window.open('http://' + url);
        } else {
            window.open(url);
        }
    } catch (e) {
      this.errorHandlerService.throwStringError(e.toString(), 404);
    }
  }

}
