<div class="tabs-panel">
    <ul>
        <li *ngFor="let tab of tabs" (click)="selectTab(tab)">
            {{ tab.tabTitle }}
            <div class="active-icon">
                <i class="fa fa-caret-up" *ngIf="selectedTab === tab"></i>
            </div>
        </li>
    </ul>
    <div class="tab-content">
        <ng-content></ng-content>
    </div>
</div>
