import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {GlobalAdminService} from '../services/global-admin.service';
import {workListAnim} from '../../../shared/animations/general.animation';
import {Location} from '@angular/common';
import {UserService} from '../../../shared/services/user/user.service';
import {AdminService} from '../../club/services/admin.service';
import {HelperService} from '../../../shared/services/helper.service';
import {CompanyAdminService} from '../../company/services/company-admin.service';
import {GlobalAdminRestService} from '../services/global-admin.rest.service';

@Component({
    selector: 'app-communications-list',
    templateUrl: './global-communications-list.component.html',
    animations: [workListAnim]
})
export class GlobalCommunicationsListComponent implements OnInit, AfterViewInit, OnDestroy {

    public supplierList: any[];
    public validForm: boolean = false;

    constructor(public globalAdminService: GlobalAdminService,
                private globalAdminRestService: GlobalAdminRestService,
                public location: Location,
                public userService: UserService,
                public adminService: AdminService,
                public companyAdminService: CompanyAdminService,
                private changeDetectorRef: ChangeDetectorRef,
                private helperService: HelperService) {
    }


    ngOnInit() {
        this.globalAdminService.resultItems = [];
        this.adminService.getClubs();

        if (this.globalAdminService.communicationRequest.endDate.length === 0 || this.globalAdminService.communicationRequest.startDate.length === 0) {
            const now = new Date();
            let month = (now.getMonth() + 1).toString();
            if (month.length === 1) {
                month = '0' + month;
            }
            let day = now.getDate().toString();
            if (day.length === 1) {
                day = '0' + day;
            }
            this.globalAdminService.communicationRequest.endDate = now.getFullYear() + '-' + (month) + '-' + day;

            const oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));
            let oldMonth = (oneMonthAgo.getMonth() + 1).toString();
            if (oldMonth.length === 1) {
                oldMonth = '0' + oldMonth;
            }
            let oldDay = oneMonthAgo.getDate().toString();
            if (oldDay.length === 1) {
                oldDay = '0' + oldDay;
            }
            this.globalAdminService.communicationRequest.startDate = oneMonthAgo.getFullYear() + '-' + (oldMonth) + '-' + oldDay;
        }
        this.supplierList = [];
        this.adminService.getAllSuppliersRest().subscribe(
            (data) => {
                for (const supplier of data) {
                    this.supplierList.push({name: supplier['name'], code: supplier['code']});
                }
            }
        );
    }

    ngAfterViewInit() {
        if (this.globalAdminService.vinLogResult && this.globalAdminService.vinLogResult.length > 0) {
            this.globalAdminService.emitItems.emit(this.globalAdminService.vinLogResult);
            if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges()
            }
        }
    }

    ngOnDestroy() {
        this.changeDetectorRef.detach();
        this.changeDetectorRef = undefined;
    }

    public validateForm() {
        if (this.globalAdminService.communicationRequest.supplier && this.globalAdminService.communicationRequest.startDate && this.globalAdminService.communicationRequest.endDate) {
            this.validForm = true;
        } else {
            this.validForm = false;
        }
    }
    public goToPage(event: any) {
        this.globalAdminService.communicationRequest.page = event - 1;
        this.globalAdminService.getCommunicationsList();
    }
}
