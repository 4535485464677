import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/Rx';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() service: any;

  public openFilterBar = false;
  private updateFilterBarSubscriber: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
      if (!this.updateFilterBarSubscriber) {
          this.updateFilterBarSubscriber = this.service.updateFilterBar.subscribe(() => {
              this.service.getFilterGroupNames();
          });
      }
  }

  ngAfterViewInit() {
      if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
          this.changeDetectorRef.detectChanges();
      }
  }

    ngOnDestroy() {
        this.changeDetectorRef.detach();
        this.changeDetectorRef = undefined;
        this.updateFilterBarSubscriber.unsubscribe();
    }

    public toggleFilterBar() {
        this.service.updateFilterBar.emit();
        this.openFilterBar = !this.openFilterBar;
    }
}
