<div class="club-setting-lanuage">
    <div class="club-setting-title">
        <h1>
            <strong>
                {{'CLUB_ADMIN.SETTING_NAME.COUNTRY' | translate}}
            </strong>
        </h1>
    </div>

    <!-- Default Country -->
    <div class="col-12 col-sm-6">
        <div class="row">
            <div class="col p-1 headerType">
                <h1 class="selection title">{{'CLUB_ADMIN.COUNTRY.DEFAULT_COUNTRY' | translate}}</h1>
            </div>
        </div>
        <div class="row">
            <div class="col p-1">
                <div class="custom-dropdown" *ngIf="countrysLoaded">
                    <select (change)="toggleDefault($event.target.value)" [(ngModel)]="defaultCountry.id">
                        <option *ngFor="let country of possibleCountrys" [value]="country.id"
                        >{{('GENERAL.COUNTRYS.' + (country.iso31661Alpha2 | uppercase) | translate)}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <button *ngIf="configService.hasUnsavedSettings(this.configService.selectedSettingName)" class="base-btn success left"
            (click)="configService.saveCountry(clubId, newDefault)">
        <i class="fa fa-check"></i>
        {{'CLUB_ADMIN.PARTNER_MANAGEMENT.SAVE' | translate}}
    </button>
</div>
