import {trigger, animate, transition, style, query, group, state} from '@angular/animations';

export const fadeSmallAnimation =

    trigger('fadeSmallAnimation', [
        transition( '* <=> *', [

            query(':enter, :leave', style({ position: 'fixed', width: '50%'})
                , { optional: true }),
            group([
                query(':enter', [
                    style({ opacity: 0 }),
                    animate('0.35s', style({ opacity: 1 }))
                ], { optional: true }),
                query(':leave', [
                    style({ opacity: 1 }),
                    animate('0.35s', style({ opacity: 0 }))
                ], { optional: true }),
            ])


        ]),
    ]);
