import {Component, OnInit} from '@angular/core';
import {workListAnim} from '../../../shared/animations/general.animation';
import {HelperService} from '../../../shared/services/helper.service';
import {UserService} from '../../../shared/services/user/user.service';
import {AdminService} from '../../club/services/admin.service';
import {GlobalAdminRestService} from '../services/global-admin.rest.service';
import {IClubSetting} from '../../../shared/interfaces/DTO/club/clubSetting';
import {AdminRestService} from '../../../shared/services/admin.rest.service';

@Component({
    selector: 'app-club',
    templateUrl: './global-club.component.html',
    animations: [workListAnim]
})
export class GlobalClubComponent implements OnInit {

    public defaultCountry = 'DE';
    public defaultLanguage = 'de';
    public possibleLanguages: any = [];
    public theme = '';
    public possibleCountries: any = [];
    public clubSettings: IClubSetting[];
    public language = '';
    public languagesLoaded = false;
    public countriesLoaded = false;
    public compareLang: any = [];
    public testOnly: boolean = false;
    public clubName: string = '';
    public selectedCountries = [];
    public selectedLanguages = [];

    constructor(private globalAdminRestService: GlobalAdminRestService,
                private adminRestService: AdminRestService,
                private userService: UserService,
                private adminService: AdminService,
                private helperService: HelperService) {

    }

    ngOnInit() {
        this.globalAdminRestService.getLanguages().subscribe(
            (response) => {
                this.possibleLanguages = response;
                this.languagesLoaded = true;
            }
        );

        this.adminRestService.getCountryList().subscribe(
            (response) => {
                this.possibleCountries = response;
                this.countriesLoaded = true;
            });
    }

    public toggleDefaultLanguage(event) {
        this.defaultLanguage = event.target.value;
    }

    public togglePossibleLanguage(language, ev: any) {
        if (ev.target.checked) {
            this.selectedLanguages.push(language);
        } else {
            let i = this.selectedLanguages.indexOf(language);
            this.selectedLanguages.splice(i, 1);
        }
    }

    public toggleDefaultCountry(event) {
        this.defaultCountry = event.target.value;
    }

    public togglePossibleCountry(country, ev: any) {
        if (ev.target.checked) {
            this.selectedCountries.push(country.iso31661Alpha2);
        } else {
            let i = this.selectedCountries.indexOf(country);
            this.selectedCountries.splice(i, 1);
        }
    }

    public toggleTheme(event: any) {
        this.theme = event.target.value;
    }

    createClub() {
        this.globalAdminRestService.createClubRest(this.clubName, !this.testOnly, this.selectedLanguages, this.selectedCountries, this.defaultCountry, this.defaultLanguage, this.theme).subscribe(
            (success) => {
                this.helperService.showNotification('GLOBAL_ADMIN.GLOBAL_CLUB.SUCCESS', 'success');
                },
            (error) => {
                this.helperService.showNotification('GLOBAL_ADMIN.GLOBAL_CLUB.ERROR', 'error');
            });
    }
}
