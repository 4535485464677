<div class="base-btn success" *ngIf="!text" [@change]="service.articleCount">
    <div
         *ngIf="service.hasArticles"
         routerLink="{{(location.path().endsWith('/article') ? location.path().replace('/article','') : (location.path().startsWith('/maintenance') ? '/maintenance/article' : '/work/article'))}}"
         class="router">
        <i [ngClass]="{'icon-playlist_add': !location.path().endsWith('/article'),'icon-settings_backup_restore': location.path().endsWith('/article')}"></i>
        <span class="count" *ngIf="!location.path().endsWith('/article')">
    {{(service.articleCount > 0 ? service.articleCount : '')}}
  </span>
        <span class="description" *ngIf="!location.path().endsWith('/article')">
    {{'GENERAL.ARTICLE' | translate}}
  </span>
        <span class="description" *ngIf="location.path().endsWith('/article')">
    {{'GENERAL.BACK' | translate}}
  </span>
    </div>
</div>


<div *ngIf="service.hasArticles && text"
     routerLink="{{(location.path().endsWith('/article') ? location.path().replace('/article','') :  (location.path().startsWith('/maintenance') ? '/maintenance/article' : '/work/article'))}}"
     class="card article-for-work clickable">
    <b>{{'WORK.WORK_DETAILS.ARTICLES_NEEDED' | translate}}</b>
        <hr/>
        <div class="row">
            <div class="col-11">
                <strong>
                    <span>{{'WORK.WORK_DETAILS.SHOW_ARTICLES' | translate}}</span>
                </strong>
            </div>
            <div class="col-1">
                <i class="fa fa-angle-right"></i>
            </div>
        </div>
</div>
