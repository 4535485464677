import { Component, OnInit } from '@angular/core';
import {OrderHistoryService} from '../services/order-history.service';
import {PdfService} from '../../../../shared/services/pdf.service';
import {CartService} from '../../../../cart/services/cart.service';
import {Router} from '@angular/router';
import {UserService} from '../../../../shared/services/user/user.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  constructor(public orderHistoryService: OrderHistoryService,
              public pdfService: PdfService,
              public cartService: CartService,
              public router: Router,
              public userService: UserService) {
  }

  ngOnInit() {
  }
}
