<div class="mb-5">
    <fieldset class="material-form-input">
        <input id="genArtSearch" type="text"
               placeholder="{{'ASSORTMENT.ARTICLE_SEARCH' | translate}}"
               (selectItem)="setGenArtId($event)"
               [ngbTypeahead]="dataMapperService.searchGenArt"
               [inputFormatter]="dataMapperService.formatterGenArt"
               [resultFormatter]="dataMapperService.formatterGenArt"
               [container]="'body'"
               [editable]='false'/>
        <hr>
        <i class="fa fa-search search-icon"></i>
    </fieldset>
</div>
<app-assortment-no-results *ngIf="showNoResults"></app-assortment-no-results>
<app-sceleton-list *ngIf="loading"></app-sceleton-list>


<div class="row mb-3" *ngIf="!showNoResults">
    <div class="col">
        <input type="checkbox" [checked]="showGrouped"
               (change)="groupList($event)"/>
        <label class="checkboxLabel">{{('ASSORTMENT.GROUPED'| translate)}}</label>
    </div>
</div>

<ng-container *ngIf="showGrouped">
    <ng-container *ngIf="groupedFirmAssortments && groupedFirmAssortments.length > 0  && !loading"
                  [ngTemplateOutlet]="groupedAssortments"
                  [ngTemplateOutletContext]="{assortments: groupedFirmAssortments, type: 'firm'}">
    </ng-container>
    <div *ngIf="(state==='firm' || state === 'ownfirm' )&& clubAssortments && clubAssortments.length > 0  && !loading">
        {{'ASSORTMENT.PRESET' | translate}}:
    </div>
    <ng-container *ngIf="groupedClubAssortments && groupedClubAssortments.length > 0  && !loading"
                  [ngTemplateOutlet]="groupedAssortments"
                  [ngTemplateOutletContext]="{assortments: groupedClubAssortments, type: 'club', class: (state==='firm' || state === 'ownfirm' )? 'deactivated-assortments': ''}">
    </ng-container>
</ng-container>


<ng-container *ngIf="!showGrouped">
    <ng-container *ngIf="firmAssortments && firmAssortments.length > 0  && !loading" [ngTemplateOutlet]="assortments"
                  [ngTemplateOutletContext]="{assortments: firmAssortments, type: 'firm'}">
    </ng-container>
    <div *ngIf="(state==='firm' || state === 'ownfirm' )&& clubAssortments && clubAssortments.length > 0  && !loading">
        {{'ASSORTMENT.PRESET' | translate}}:
    </div>
    <ng-container *ngIf="clubAssortments && clubAssortments.length > 0  && !loading" [ngTemplateOutlet]="assortments"
                  [ngTemplateOutletContext]="{assortments: clubAssortments, type: 'club', class: (state==='firm' || state === 'ownfirm' )? 'deactivated-assortments': ''}">
    </ng-container>
</ng-container>


<ng-template let-assortments="assortments" let-type="type" let-class="class" #assortments>
    <div class="" [ngClass]="{'deactivated-assortments': (class === 'deactivated-assortments')}">
        <div class="list-wrapper drag-list">
            <ng-container *ngFor="let assortment of assortments; let i= index;">
                <div class="row drag-box"
                     *ngIf="((type === 'club' &&  (state==='firm' || state === 'ownfirm' ))? assortmentService.existsAlreadyInFirm(assortment, firmAssortments, 'genartId&dataSupplierNo'): true)">
                    <div class="col-9 d-flex align-items-center">
                        <div class="btn-group">
                            <button (click)="updatePriority(assortment.id, true, 100, (type === 'club' &&  (state==='firm' || state === 'ownfirm' )))"
                                    [ngClass]="{'base-btn': true, 'active': (assortment.prio <200) }">A
                            </button>
                            <button (click)="updatePriority(assortment.id, true, 200, (type === 'club' &&  (state==='firm' || state === 'ownfirm' )))"
                                    [ngClass]="{'base-btn': true, 'active': (assortment.prio >200 && assortment.prio <300) }">
                                B
                            </button>
                            <button (click)="updatePriority(assortment.id, true,300, (type === 'club' &&  (state==='firm' || state === 'ownfirm' )))"
                                    [ngClass]="{'base-btn': true, 'active': (assortment.prio >300 && assortment.prio <400) }">
                                C
                            </button>
                        </div>
                        <div class="btn-group ml-3 mr-3">
                            <button (click)="updatePriority(assortment.id, false,1, (type === 'club' &&  (state==='firm' || state === 'ownfirm' )))"
                                    [ngClass]="{'base-btn': true, 'active':(modulus(assortment.prio) === 1) }">1
                            </button>
                            <button (click)="updatePriority(assortment.id, false,2,(type === 'club' &&  (state==='firm' || state === 'ownfirm' )))"
                                    [ngClass]="{'base-btn': true, 'active':(modulus(assortment.prio) === 2) }">2
                            </button>
                            <button (click)="updatePriority(assortment.id, false,3,(type === 'club' &&  (state==='firm' || state === 'ownfirm' )))"
                                    [ngClass]="{'base-btn': true, 'active':(modulus(assortment.prio) === 3) }">3
                            </button>
                        </div>
                        <span>{{dataMapperService.mapGenArt(assortment.genArtId)}}
                            - {{dataMapperService.mapDataSupplier(assortment.dataSupplierNo)}}</span>
                        <span [routerLink]="['/'+assortmentService.checkRoute(router.url)+ '/assortment/article', assortment.genArtId]"
                              class="info-text ml-auto">{{'ASSORTMENT.PRIORITIZE_ASSEMBLIE' | translate}}</span>
                    </div>

                    <div class="col-1">
                        <i *ngIf="!assortment.active && class !== 'deactivated-assortments'"
                           class="fa fa-trash float-right"
                           (click)=" assortment.delete = !assortment.delete"></i>
                    </div>

                    <div class="col-2">
                        <app-checkbox [isChecked]="assortment.active"
                                      [labelText]="'ASSORTMENT.ACTIVE' | translate"
                                      (onChange)="assortment.active = $event; updateAssortment(assortment.id, (type === 'club' &&  (state==='firm' || state === 'ownfirm' )))"></app-checkbox>
                    </div>
                    <div *ngIf="assortment.delete" class="col-12 delete-confirmation">
                        <div class="alert alert-danger m-2">
                            <b> {{dataMapperService.mapDataSupplier(assortment.dataSupplierNo)}} </b> {{'GENERAL.DELETE' | translate}}
                            <br/><br/>
                            <button class="base-btn "
                                    (click)="assortment.delete = !assortment.delete">{{'PROMPTS.BUTTONS.CANCEL' | translate}}</button>
                            <button class="base-btn error pull-right no-top-margin"
                                    (click)="deleteAssortment(assortment.id)"> {{'PROMPTS.BUTTONS.DELETE' | translate}} </button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>


<ng-template let-assortments="assortments" let-type="type" let-class="class" #groupedAssortments>
    <div class="" [ngClass]="{'deactivated-assortments': (class === 'deactivated-assortments'), 'grouped': true}">
        <div class="list-wrapper drag-list">
            <ng-container *ngFor="let assortment of assortments; let i= index;">
                <div class="row drag-box"
                     *ngIf="((type === 'club' &&  (state==='firm' || state === 'ownfirm' ))? existInFirm(assortment, groupedFirmAssortments ): true)">
                    <div class="col-9 d-flex align-items-center">
                        <div class="btn-group">
                            <button class="base-btn">
                                A
                            </button>
                            <button class="base-btn">
                                B
                            </button>
                            <button class="base-btn">
                                C
                            </button>
                        </div>
                        <div class="btn-group ml-3 mr-3">
                            <button class="base-btn">
                                1
                            </button>
                            <button class="base-btn">
                                2
                            </button>
                            <button class="base-btn">
                                3
                            </button>
                        </div>
                        <span>{{dataMapperService.mapGenArt(assortment)}}</span>
                        <span [routerLink]="['/'+assortmentService.checkRoute(router.url)+ '/assortment/article', assortment]"
                              class="info-text ml-auto">{{'ASSORTMENT.PRIORITIZE_ASSEMBLIE' | translate}}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>