import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import {HelperService} from '../../../../shared/services/helper.service';

@Component({
  selector: 'app-oe-supplier',
  templateUrl: './global-oe-supplier.component.html'
})
export class GlobalOeSupplierComponent implements OnInit {
  public suppliers: any;
  public supplier = 'PARTSPOINT';
  public fileToUpload: any;
  public uploadingProgressing: boolean;
  public uploadProgress: number;
  public responseMSG: any;

  constructor(private http: HttpClient,
              public helperService: HelperService) {

    this.suppliers = ['OEMOCK', 'MOCK', 'MOCK2', 'MOCK3', 'MOCK4', 'BUSCH', 'COLER', 'COLER_NORD', 'HEISE_UND_KLATTE', 'HENNIG', 'KOLB_UND_SOERGEL',
      'KUEBLBECK', 'LORSCH', 'SPRINGER', 'WERTHENBACH', 'WITTICH', 'WUETSCHNER', 'HEIL_UND_SOHN', 'MATTHIES',
      'PARTSPOINT', 'PV_GROUP', 'STAHLGRUBER', 'TROST', 'WESSELS_MUELLER', 'WM_NL', 'WM_AT', 'WM_4FLEET', 'COPARTS_TEST', 'KNOLL', 'KLOSTERMANN', 'SBS',
      'TEUFEL', 'AUTOTEILE_JAKOBS', 'FLAURAUD', 'STAHLGRUBER_CZ', 'AUTOPARTNER_PL', 'INTERCARS_PL', 'INTERCARS_CZ', 'DAPARTO_GIGANT', 'DAPARTO_PARSCON',
      'DAPARTO_CARONDO', 'ELIT', 'ELIT_PL', 'GOGLAS', 'WUETSCHNER_DE', 'WUETSCHNER_AT', 'HOSTETTLER', 'AUTO_DISTRIBUTION', 'ACR_GROUP', 'WERTHENBACH_DIREKT',
      'PROFI_PARTS', 'HEISE_UND_KLATTE_DIREKT', 'SPRINGER_DIREKT', 'WITTICH_DIREKT', 'HENNIG_DIREKT', 'LORCH_DE', 'LORCH_AT', 'LORCH_CH', 'VIEROL_AG',
      'DERENDINGER_CH', 'DISTRIGO', 'NEIMCKE', 'JM_AUTODILY', 'DERENDINGER_AT', 'GREENCAR', 'BIRNER_AT']
        .sort();
  }

  ngOnInit() {
  }

  public handleFileInput(files: any) {
    this.fileToUpload = files.item(0);
  }

  public handleSubmit(event: any, fileToUpload) {

    const fileUploadSub = this.postFile(fileToUpload).subscribe(
        event => this.handleProgress(event),
        error => {
          this.fileToUpload = null;
          this.uploadingProgressing = false;
          this.responseMSG = error.statusText;
        },
        () => {
          this.responseMSG = 'Upload erfolgreich!';
          this.fileToUpload = null;
          this.uploadingProgressing = false;
        });

  }

  public postFile(fileToUpload: File ): Observable<any> {
    const apiCreateEndpoint = '/api/admin/performOEMappingImport/' + this.supplier;
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    const req = new HttpRequest('POST', apiCreateEndpoint, formData, {
      reportProgress: true // for progress data
    });

    return this.http.request(req);
  }

  private handleProgress(event) {

    this.uploadingProgressing = false;

    if (event.type === HttpEventType.DownloadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round(100 * event.loaded / event.total)
    }

    if (event.type === HttpEventType.UploadProgress) {
      this.uploadingProgressing = true;
      this.uploadProgress = Math.round(100 * event.loaded / event.total)
    }

    if (event.type === HttpEventType.Response) {
      this.uploadingProgressing = false;
    }
  }
}
