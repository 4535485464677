<div id="search-container" [ngClass]="{'opacity-off':!searchOpen}" *ngIf="searchOpen">

    <div class="searchResult" *ngIf="searchResult">
        <h1>
            <strong>{{'HOME.SEARCH_RESULT' | translate }}</strong>
            <span>{{selectedName}}</span>
        </h1>

        <div class="alert alert-info text-center" *ngIf="searchResult?.length === 0 || (searchResult[0]?.groups?.length === 0 && searchResult?.length === 1)">
            <i class="fa fa-info-circle mr-2"></i>
            <span>{{'ARTICLE_SELECTION.GROUP_SEARCH_NO_RESULT' | translate}}</span>
        </div>

        <ul class="item-list" *ngIf="!isWork">
            <li *ngFor="let result of searchResult | cat" (click)="selectResult(result.value)"
                [ngClass]="{'active': active == result.value}">
                <i class="fa fa-search"></i> {{result.value.name}} {{ result.value.mountPositionText }} {{
                result.value.repairText }}
            </li>
        </ul>
        <ul class="item-list" *ngIf="isWork">
            <ng-container *ngFor="let result of searchResult">
                <ng-container *ngFor="let group of result.groups">
                    <li *ngFor="let subGroup of group.subGroups" (click)="selectResults(group, subGroup)">
                        <i class="fa fa-search"></i> <strong>{{group.name}}</strong> <i class="fa fa-caret-right"></i> <strong>{{subGroup.name}}</strong>
                        <ul *ngFor="let item of subGroup.items">
                            <i class="fa fa-caret-right"></i> {{item.mountPositionText}} {{item.repairText}}
                        </ul>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </div>
</div>
<div class="clearfix"></div>
