import { Component, OnInit } from '@angular/core';
import {ThemeService} from "../../shared/services/theme.service";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-reset-pw',
  templateUrl: './reset-pw.component.html',
  styleUrls: ['./reset-pw.component.scss']
})
export class ResetPwComponent implements OnInit {
    public username: string;
    public success: boolean = false;

  constructor(
      private authService: AuthService,
      private themeService: ThemeService) {


  }

  ngOnInit() {
  }

    public resetLogin () {
    this.authService.resetPW(this.username).subscribe(
        (success) => {
          this.success = true;
        }
    )
  }
}
