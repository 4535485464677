import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {CartService} from '../../../cart/services/cart.service';

@Component({
  selector: 'app-locked',
  templateUrl: './locked.component.html',
  styleUrls: ['./locked.component.scss']
})
export class LockedComponent implements OnInit {

  constructor(public userService: UserService,
              public cartService: CartService) { }

  ngOnInit() {
  }

}
