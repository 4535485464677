<div class="retry-overlay" routerLink="/cart"></div>
<div class="conf-modal center success">
    <div class="title-icon">
        <i class="fa fa-check"></i>
    </div>
    <div class="title-text"><h1>{{'PROMPTS.MESSAGE.SUCCESS' | translate}}</h1></div>
    <p>{{'PROMPTS.MESSAGE.SUCCESS_TEXT' | translate}}</p>
    <p class="pb-0">{{'CART.PACKING_SLIP' | translate}}</p>
    <hr class="m-2">
    <p class="mailInput">
        <app-material-form-input [label]="'ORDER.CONFIRMATION_MAIL' | translate"
                                 [type]="'email'"
                                 [disabled]="false"
                                 [(text)]="mail">
        </app-material-form-input>
    </p>
    <div class="d-flex flex-row justify-content-center align-items-center" style="height: 50px;">
        <div class="p-2">
            <a class="order-btn packing-slip text-center text-decoration-none"
               href="{{getPackingSlip()}}" target="_blank">
                <span>{{'CART.OPEN_PACKING_SLIP' | translate}}</span>
            </a>
        </div>
        <div class="p-2">
            <button class="order-btn text-center" [disabled]="!mail" (click)="triggerEmail()">
                <i *ngIf="sendEmailLoader" class="fa fa-spinner" [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i>
                <span  *ngIf="!sendEmailLoader">{{'CART.SEND_PACKING_SLIP' | translate}}</span>
            </button>
        </div>
    </div>
    <div class="modal-footer">
        <div class="base-btn" routerLink="/cart">{{'PROMPTS.BUTTONS.OK' | translate}}</div>
    </div>
</div>
