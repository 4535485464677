import {AfterViewInit, Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import {ArticlesService} from '../articles/services/articles.service';
import {CartService} from './services/cart.service';
import {UserService} from '../shared/services/user/user.service';
import {DmsDataService} from '../shared/services/dms-data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CartRestService} from './services/cart.rest.service';
import {DatePipe, Location} from '@angular/common';
import {PdfService} from '../shared/services/pdf.service';
import {CarService} from '../shared/services/car/car.service';
import {ICar} from '../shared/services/car/interfaces/car';
import {MaintenanceService} from '../maintenance/services/maintenance.service';
import {HelperService} from '../shared/services/helper.service';
import {TecRmiRestService} from '../shared/services/tecrmi/tecrmi.rest.service';
import {CustomerDataService} from '../shared/services/customerData.service';
import {CarPosition} from './interfaces/cart';
import * as pdfMake from 'pdfmake/build/pdfmake';
import {TyreService} from '../shared/services/tyre.service';
import {ConfigService} from '../shared/services/config.service';
import {IMaintenancePlanMailRequest} from '../shared/interfaces/DTO/maintenancePlanMailRequest';
import {TechnicalDataService} from '../technical-data/technical-data.service';
import {IFixedPrices} from './interfaces/fixedPrices';
import {IAddFixpricePositionRequest} from '../shared/interfaces/DTO/cart/addFixpricePositionRequest';
import {TranslateService} from '@ngx-translate/core';
import {AdminService} from '../admin/club/services/admin.service';
import {ArticlesDetailsService} from '../shared/services/articles-details.service';
import {ArticlesRestService} from '../articles/services/articles.rest.service';
import {CompanyAdminService} from '../admin/company/services/company-admin.service';
import {ToastrService} from 'ngx-toastr';
import {ICart} from "../shared/interfaces/DTO/cart/cart";

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '(document:click)': 'onOutsideClick($event)',
    }
})
export class CartComponent extends ArticlesDetailsService implements OnInit, AfterViewInit {
    public mail: string = '';
    public showMail: boolean = false;
    private mailTime: Date;

    public showSave: boolean = false;
    private cartInput: any;
    public servicePlanCar: ICar;
    public selectedCarPosition: CarPosition;
    public documentPdf: any;
    public showCloseCart: boolean = false;
    public showCloseCartButton: boolean = false;

    constructor(public articlesService: ArticlesService,
                public cartService: CartService,
                public userService: UserService,
                public router: Router,
                public pdfService: PdfService,
                public location: Location,
                public cartRestService: CartRestService,
                public route: ActivatedRoute,
                public carService: CarService,
                public maintenanceService: MaintenanceService,
                public dmsDataService: DmsDataService,
                public adminService: AdminService,
                public helperService: HelperService,
                private tecRmiRestService: TecRmiRestService,
                private customerDataService: CustomerDataService,
                private translate: TranslateService,
                private toastr: ToastrService,
                private articlesRestService: ArticlesRestService,
                private companyAdmin: CompanyAdminService,
                private _eref: ElementRef,
                private datePipe: DatePipe,
                public tyreService: TyreService,
                public technicalDataService: TechnicalDataService,
                public companyAdminService: CompanyAdminService,
                public config: ConfigService) {
        super(articlesRestService, carService, userService, companyAdmin, translate, toastr, config);
    }

    ngOnInit() {
        const isOrder = (this.cartService.currentCart && this.cartService.currentCart.lockedOrderAsCart
            || this.cartService.lastSelectedCartIsOrder)
        this.cartService.updateCart(isOrder, false, false, false);
        this.cartService.history = false;
        this.cartService.showDocumentWithLink = undefined;

        const closeCartSetting = this.userService.getSubSettingForName('EXTENDED_SETTINGS', 'ALLOW_CLOSE_CART');
        if (closeCartSetting?.active) {
            this.showCloseCartButton = true;
        }
    }

    ngAfterViewInit() {
        this.cartInput = document.getElementById('d');
    }

    public closeCart() {
        this.showCloseCart = true;
        this.cartRestService.closeCart(this.cartService.currentCart.name).subscribe(
            (success) => {
                this.cartService.showCloseCartWarning = false;
                this.showCloseCart = false;
                this.cartService.updateCartList(true);
                this.cartService.updateOrderListForCart(0, 20);
            },
            (error) => {
                this.cartService.showCloseCartWarning = false;
                this.showCloseCart = false;
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_CLOSE_CART', 'error');
            })
    }

    private validMail(mail: string) {
        if (mail.indexOf('@') < 0) {
            return false;
        }

        const tempAt = mail.substring(mail.indexOf('@') + 1);
        if (tempAt.indexOf('.') < 0) {
            return false;
        }

        const tempDot = mail.substring(mail.lastIndexOf('.') + 1);
        if (tempDot.trim().length === 0) {
            return false;
        }

        return true;
    }

    public sendPerMail(mail: string) {

        if (!this.mailTime || new Date().getTime() - this.mailTime.getTime() > 500) {

            this.mailTime = new Date();

            if (!mail || mail.trim().length === 0) {
                mail = this.userService.accountData.email;
            }

            if (this.validMail(mail)) {
                const serviceplan: IMaintenancePlanMailRequest = {
                    kTypeId: this.carService.getKTypeId(),
                    adcTypeId: this.carService.getAdcTypeId(),
                    customer: this.customerDataService.getCustomerFirstName() + ' ' + this.customerDataService.getCustomerLastName(),
                    numberplate: this.customerDataService.getNumberplate(),
                    mileage: (this.customerDataService.getMileage() ? this.customerDataService.getMileage() : '').toString(),
                    mdQualColId: 0,
                    ordernumber: this.customerDataService.getOrderNumber().toString(),
                    showLetterHead: this.maintenanceService.showLetterHead,
                    showCarGraphic: this.maintenanceService.showCarGraphic,
                    sendTo: mail,
                    phone: ''
                };

                this.tecRmiRestService.sendWorkPlanByMail(serviceplan).subscribe(
                    (success) => {
                        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_SEND_MAIL', 'success');
                    },
                    (error) => {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_SEND_MAIL', 'error');
                    }
                );
            } else {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_MAIL_NOT_VALID', 'error');
            }
        }
    }

    private onOutsideClick(event: any) {
        if (event.target.className.indexOf('servicePlanButton') < 0) {
            this.selectedCarPosition = undefined
        }
    }

    public generatePdf() {

        const details = this.cartService.showDocument.car;
        let bodyElements: any[] = [];
        const docDefinition: any = this.pdfService.buildDocDefinition(false, this.carService.selectedCar);

        for (const detail of details.serviceInfos) {
            docDefinition.content.push(
                {
                    text: detail.headline,
                    margin: [0, 10, 0, 0],
                    fontSize: 12
                }
            );
            docDefinition.content.push(
                {
                    text: detail.validFrom + ' - ' + detail.validTo,
                    margin: [0, 0, 0, 10],
                    fontSize: 8
                }
            );
            bodyElements.push([
                {text: detail.text, alignment: 'left'}
            ]);
            docDefinition.content.push(
                {
                    style: 'cartTable',
                    table: {
                        widths: ['*'],
                        body: bodyElements
                    }
                }
            );
            bodyElements = [];
        }

        return docDefinition;
    }

    public createPdf() {
        this.maintenanceService.showPdf = true;
        this.maintenanceService.pdfLoading = true;
        this.getPdf(this.generatePdf()).then(
            (pdf: any) => {
                this.maintenanceService.pdfLoading = false;
                this.maintenanceService.pdf = pdf;
            },
            (error: any) => {
                console.warn(error);
                this.maintenanceService.pdfLoading = false;
            }
        );
    }

    public downloadPdf() {
        return pdfMake.createPdf(this.generatePdf()).download();
    }

    public printPdf() {
        return pdfMake.createPdf(this.generatePdf()).print();
    }

    public printBlob() {
        window.open(this.documentPdf).print();
    }

    public getPdf(docDefinition: any) {
        return <any>new Promise(function (resolve: any, reject: any) {
            pdfMake.createPdf(docDefinition, 'filename').getBase64(function (pdfBase64: any) {
                const raw = atob(pdfBase64);
                const uint8Array = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    uint8Array[i] = raw.charCodeAt(i);
                }
                resolve(uint8Array);
            });
        });
    }

    public createRawToolsPdf(): void {
        this.cartService.buildPdfForRawTool(this.cartService.rawTools).then(
            (data: any) => {
                this.openPrintDialog(data);
            },
            (error: any) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_CREATING_PDF', 'error')
            }
        );
    }

    public openPrintDialog(data: any): any {
        return pdfMake.createPdf(data).print();
    }

    public hasFocus() {
        return document.activeElement === this.cartInput;
    }

    public checkCartName(name: string) {
        this.showSave = this.cartService.currentCart.name !== name;
    }

    public renameCart(newName: any) {
        this.cartService.renameCart(newName, this.cartService.currentCart.id);
        this.showSave = false;
    }

    public deleteCart(cartId: string) {
        this.cartService.deleteCart(cartId);
    }

    public checkout() {
        this.router.navigate(['/checkout']);
    }

    public toggleCompositeWorkTime() {
        this.cartService.currentCart = undefined;
        this.cartRestService.toggleCompositeWorkTime().subscribe(
            (response) => {
                this.cartService.currentCart = response;
                this.cartService.onCartChange.emit();
                this.customerDataService.resetDataHolder();
            }
        );
    }

    public getDeliveryCostsKeys(cart: ICart) {
        if (cart && cart.cartCostDTO.deliveryCosts) {
            return Object.keys(cart.cartCostDTO.deliveryCosts);
        } else {
            return [];
        }
    }

    public hasDeliveryCosts(cart: ICart) {
        let hasDeliveryCosts = false;

        this.getDeliveryCostsKeys(cart).forEach((key) => {
            if (cart.cartCostDTO.deliveryCosts[key].value > 0) {
                hasDeliveryCosts = true;
                return;
            }
        });

        return hasDeliveryCosts;
    }

    public stopPropagation($event: any) {
        $event.stopPropagation();
    }

    public showOption(option: string, car?: any, document?: any) {
        switch (option) {
            case 'serviceplan':
                this.cartService.showDocument = this.selectedCarPosition;
                this.selectedCarPosition = undefined;
                this.showServicePlan(this.cartService.showDocument.car);
                break;
            case 'info':
                this.cartService.showDocument = this.selectedCarPosition;
                this.selectedCarPosition = undefined;
                this.showInfo();
                break;
            case 'specs':
                this.cartService.showDocument = this.selectedCarPosition;
                this.selectedCarPosition = undefined;
                this.showSpecs();
                break;
            case 'change':
                this.cartService.showDocument = this.selectedCarPosition;
                this.selectedCarPosition = undefined;
                this.showChange();
                break;
            case 'recall':
                this.cartService.showDocument = this.selectedCarPosition;
                this.selectedCarPosition = undefined;
                this.showRecall();
                break;
            case 'tyre':
                this.cartService.showDocument = this.selectedCarPosition;
                this.selectedCarPosition = undefined;
                this.showTyre();
                break;
            case 'servicebook':
                window.open(car.serviceBook.toString());
                break;
            case'document':
                this.cartService.showDocument = this.selectedCarPosition;
                this.showDocument(document);
                break;
            case 'cardetails':
                this.cartService.showDocument = this.selectedCarPosition;
                this.showCarDetails();
                break;
            case 'calculation':
                this.cartService.showDocument = this.selectedCarPosition;
                this.showCalculationsHTML(this.cartService.showDocument.car);
                break;
            case 'rawtool':
                this.cartService.showDocument = this.selectedCarPosition;
                this.selectedCarPosition = undefined;
                this.carService.selectedCarWithRawTools = car.car;
                this.router.navigate(['cart/raw-tools'], {queryParams: car.car});
                break;
        }
    }

    public showServicePlan(car: ICar) {
        this.servicePlanCar = car;
        this.maintenanceService.serviceplanUrl = this.tecRmiRestService.getWorkPlan(this.servicePlanCar.kTypeId,
            this.servicePlanCar.adcTypeId,
            this.customerDataService.getCustomerFirstName() + ' ' + this.customerDataService.getCustomerLastName(),
            this.customerDataService.getNumberplate(),
            (this.customerDataService.getMileage() ? this.customerDataService.getMileage() : ''),
            0,
            this.customerDataService.getOrderNumber(), this.customerDataService.getCustomerPhone(),
            this.maintenanceService.showLetterHead, this.maintenanceService.showCarGraphic,
            this.translate.instant('GENERAL.BACK'));
        this.router.navigate(['/cart/service']);
    }

    public showDocument(document: any) {
        if (document.type === 'PDF') {
            this.cartRestService.getRawPdf(document.url).subscribe((response: any) => {
                const blob = new Blob([response], {type: 'application/pdf'});
                this.documentPdf = URL.createObjectURL(blob);
                this.cartService.showDocumentWithLink = document;
            });
        } else {
            this.cartService.showDocumentWithLink = document;
        }
    }

    public showInfo() {
        this.maintenanceService.getManual(this.cartService.showDocument.car);
        this.router.navigate(['/cart/info']);
    }

    public showCalculationsHTML(car: ICar) {
        this.servicePlanCar = car;
        this.maintenanceService.tecRMICalculationUrl = this.tecRmiRestService.getTecRMICalculation(this.servicePlanCar.kTypeId,
            this.servicePlanCar.adcTypeId,
            this.customerDataService.getCustomerFirstName() + ' ' + this.customerDataService.getCustomerLastName(),
            this.customerDataService.getNumberplate(),
            (this.customerDataService.getMileage() ? this.customerDataService.getMileage() : ''),
            this.customerDataService.getOrderNumber(), this.customerDataService.getCustomerPhone(), this.maintenanceService.showLetterHead);

        this.router.navigate(['/cart/calculation']);
    }

    public showSpecs() {
        this.maintenanceService.getMaintenanceSpec(this.cartService.showDocument.car);
        this.router.navigate(['/cart/specs']);
    }

    public showCarDetails() {
        this.maintenanceService.getMaintenanceSpec(this.cartService.showDocument.car);
        this.router.navigate(['/cart/cardetails']);
    }

    public showChange() {
        this.maintenanceService.getChangeInterval(this.cartService.showDocument.car);
        this.router.navigate(['/cart/change']);
    }

    public showRecall() {
        if (this.cartService.showDocument.car.serviceInfos.length > 0
            && typeof this.cartService.showDocument.car.serviceInfos[0].validFrom === 'number') {
            for (let i = 0; i < this.cartService.showDocument.car.serviceInfos.length; i++) {
                this.cartService.showDocument.car.serviceInfos[i].validFrom = this.datePipe.transform(
                    new Date(this.cartService.showDocument.car.serviceInfos[i].validFrom), 'dd.MM.yyyy'
                );
                this.cartService.showDocument.car.serviceInfos[i].validTo = this.datePipe.transform(
                    new Date(this.cartService.showDocument.car.serviceInfos[i].validTo), 'dd.MM.yyyy'
                );
                this.cartService.showDocument.car.serviceInfos[i].classification
                    = this.cartService.showDocument.car.serviceInfos[i].classification.toUpperCase();
            }
        }
        this.maintenanceService.setSelectedCarInfos(this.cartService.showDocument.car.serviceInfos);
        this.router.navigate(['/cart/recall']);
    }

    public showTyre() {
        this.router.navigate(['/cart/tyre']);
    }

    public printIframe(frameId: string) {
        const frame = window.frames[frameId];
        if (!frame) {
            return;
        }

        if (this.helperService.browserIsIE()) {
            frame.document.execCommand('print', false, null);
        } else {
            const frames: any = document.getElementById(frameId);
            frames.contentWindow.focus();
            frames.contentWindow.print();
        }
    }

    public toggleShowElements(car: CarPosition) {
        if (this.selectedCarPosition && this.selectedCarPosition === car) {
            this.selectedCarPosition = undefined;
        } else if (!this.selectedCarPosition || this.selectedCarPosition !== car) {
            this.selectedCarPosition = car;
        }
    }


    public toggleFixedPriceInCart(position: IFixedPrices, setInCart: boolean) {

        if (setInCart) {

            const fixpricePosition: IAddFixpricePositionRequest = {
                adcTypeId: this.carService.getAdcTypeId(),
                articleNo: position.articleNo,
                description: position.description,
                kTypeId: this.carService.getKTypeId(),
                quantity: position.quantity,
                quantityUnit: position.quantityUnit,
                sellOutNetPerPiece: position.sellOutNetPerPiece,
                vin: this.carService.getVin(),
                producerName: undefined,
                uniqueCalculationId: position.uniqueCalculationId,
                allowCalculatedPriceChange: position.allowCustomerPrice,
                vatExempt: position.vatExempt,
            };

            this.cartRestService.addFixpricePositionRest(fixpricePosition).subscribe(
                (success) => {
                    this.cartService.updateCart(false, false, false, false);
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_ADD_FIXPRICE_POSITION', 'success');
                }, (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_ADD_FIXPRICE_POSITION', 'error');
                }
            );
        } else {
            this.cartRestService.deletePos(position.posIdInCart).subscribe(
                (success) => {
                    this.cartService.updateCart(false, false, false, false);
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_REMOVE_FIXPRICE_POSITION', 'success');
                }, (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_REMOVE_FIXPRICE_POSITION', 'error');
                }
            );
        }
    }

    public selectOrderCart() {
        this.carService.ejectCar();
        this.cartService.history = false;
        this.router.navigate(['/cart']);
        this.cartService.selectCart(this.cartService.previewCart.id, true, true);
    }
}
