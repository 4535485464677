<div class="login-nav">
  <a href="#/login">
    LogIn
  </a>
  <a href="#/imprint">
    Impressum
  </a>
  <a href="#/disclaimer">
    Datenschutzerklärung
  </a>

</div>
