import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CarRestService} from '../../../services/car/car.rest.service';
import {CarService} from '../../../services/car/car.service';
import {HelperService} from '../../../services/helper.service';
import {ICar} from '../../../interfaces/DTO/car/car';
import {UserService} from '../../../services/user/user.service';

@Component({
  selector: 'app-car-by-motorcode',
  templateUrl: './car-by-motorcode.component.html'
})
export class CarByMotorcodeComponent implements OnInit, OnChanges {

  @Output() applyCars: EventEmitter<ICar[]> = new EventEmitter();
    motorCode: any;
    public text: any;
  constructor(private carRestService: CarRestService,
              public carService: CarService,
              public helperService: HelperService,
              public userService: UserService) { }

  ngOnInit() {
  }
    ngOnChanges() {
        this.carService.selected = 'KBA_NUMBER';
    }
    public onMotorCodeSearch() {
      this.carService.loadCar = 'MOTOR-CODE';
        this.carRestService.getCarsByMotorcode(this.motorCode).subscribe(
            (response: ICar[]) => {
                this.carService.loadCar = undefined;
                if (!this.userService.getCatalogMode()) {
                    this.carService.fromDMS = false;
                }
                this.applyCars.emit(response);
            },
            (error) => {
                if (error.status === 409) {
                    this.carService.handleCarConflict();
                } else {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                }
                this.carService.loadCar = undefined;
            }
        );
    }
}
