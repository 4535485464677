import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {IGenArt} from '../interfaces/IGenArt';

@Injectable({
    providedIn: 'root'
})
export class DataGenartRestService {

    constructor(private http: HttpClient) {
    }


    public getGenArtsRest(): Observable<IGenArt[]> {
        return this.http.get<IGenArt[]>(
            `${environment.apiUrl}/tecdoc/groupedgenarts`
        );
    }
}
