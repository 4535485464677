<div class="hints">
  <div class="heading blue" (click)="maintenanceService.resetSelectedUrl()">
    <span routerLink="/maintenance" class="clickable" (click)="maintenanceService.lastState = ''; maintenanceService.resetSelectedUrl()"><i class="fa fa-th" aria-hidden="true"></i></span>
    <span (click)="maintenanceService.resetSelectedUrl()" routerLink="/maintenance" class="clickable">{{'CLUB_SETTINGS.NAVIGATION.MAINTENANCE' | translate}}</span>
    <span (click)="maintenanceService.resetSelectedUrl()" routerLink="/maintenance/hints" class="clickable"><i class="fa fa-caret-right"></i>{{'TECHNICALINFOS.NAV.MAINTENANCE' | translate}}</span>
    <span class="clickable" (click)="maintenanceService.resetSelectedUrl()" routerLink="/maintenance/hints/{{selectedManual.id}}" *ngIf="selectedManual"><i class="fa fa-caret-right"></i>{{selectedManual?.name}}</span>
    <span class="headerPrint" *ngIf="maintenanceService.selectedUrl">
      <button class="print-btn pull-right" (click)="printIframe()">
        <i class="fa fa-print"></i>
        <span>{{'PRINT_VIEW.PRINT' | translate}}</span>
      </button>
    </span>
  </div>
  <div class="panel-content center-content">
    <ng-container *ngIf="location.path().endsWith('/hints')">
      <div class="assembly-categories">
        <div class="assembly-category" *ngFor="let manual of maintenanceService?.manual"
             routerLink="/maintenance/hints/{{manual.id}}">
          <div class="name-wrapper-no-image">
            <span>{{manual.name}}</span>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!location.path().endsWith('/hints')">
      <div *ngFor="let childCat of selectedManual?.subGroups | cat">
        <div class="headerType">
          <span class="selection title">{{childCat.value.name}}</span>
        </div>
        <ul class="item-list" [@detailAnim]="childCat?.value.manualItems">
          <ng-container *ngFor="let childItems of childCat.value.manualItems | cat">
            <li (click)="maintenanceService.openCatData(childItems.value.manuals[0])" *ngIf="childItems.value.manuals.length < 2">
              <span class="clickable">
                <i class="fa fa-file-text-o"></i>
                <span>{{childItems.value.text}}</span>
                <span *ngIf="childItems.value.manuals[0].qualifierText !== 'Standard'">{{childItems.value.manuals[0].qualifierText}}</span>
              </span>
            </li>
            <ng-container *ngIf="childItems.value.manuals.length >= 2">
              <ng-container *ngFor="let childManuals of childItems.value.manuals | cat">
                <li (click)="maintenanceService.openCatData(childManuals.value)">
                  <span class="clickable">
                    <i class="fa fa-file-text-o"></i>
                    <span>{{childItems.value.text}}</span>
                    <span *ngIf="childManuals.value.qualifierText !=='Standard'"><strong>{{' ' + childManuals.value.qualifierText}}</strong></span>
                  </span>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
        </ul>
        <div class="spacer"></div>
      </div>
    </ng-container>
  </div>
  <div class="panel-content center-content white-background" *ngIf="maintenanceService.selectedUrl">
    <iframe width="100%" onLoad="this.style.height=(this.contentDocument.body.scrollHeight + 30) + 'px';" [src]="maintenanceService.selectedUrl | iFrame" id="tecrmi-manual"></iframe>
  </div>
</div>

