<div class="club-setting-tecdoc">

    <div class="club-setting-title">
      <h1>
        <strong>
          {{'CLUB_ADMIN.SETTING_NAME.TECDOC' | translate}}
        </strong>
      </h1>
    </div>

    <ng-container *ngIf="configService.clubConfig">
      <div class="row">
        <div class="col-7">
          <button class="base-btn resetButton" (click)="reset()"><span><i class="fa fa-history"></i>{{'LOGIN.RESET' | translate}}</span></button>
          <app-checkbox [isChecked]="getDataSupplierActive()"
                        [labelText]="'ADMIN_PANEL.ACTIVE' | translate"
                        (onChange)="setDataSupplierActive($event)">
          </app-checkbox>
        </div>
        <div class="col-5">
          <app-toggle-switch [isChecked]="getSelectedListType() === 'BLACK_LIST'"
                             [textLeft]="'Whitelist'" [textRight]="'Blacklist'"
                             (onChange)="setListType($event)"></app-toggle-switch>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <div class="dataSupplier">
            <app-material-form-input [(text)]="localDataSuppliersInput"
                                     (textChange)="localDataSuppliersList = reduceList(getDataSuppliers(), $event)"
                                     [placeholder]="'TYRE.SEARCHTERM' | translate" >
            </app-material-form-input>
            <i class="fa fa-times" (click)="localDataSuppliersList = getDataSuppliers(); localDataSuppliersInput = ''"
               *ngIf="localDataSuppliersInput?.length > 0">
            </i>
            <div class="dataSupplierBox">
              <ng-container *ngFor="let dataSupplier of localDataSuppliersList">
                <div class="dataSupplierItem" *ngIf="!isInRestrictionList(dataSupplier)"
                     (click)="selectedDataSupplier = dataSupplier"
                     [ngClass]="{'selected': selectedDataSupplier && selectedDataSupplier === dataSupplier}">
                  <span>{{dataSupplier?.second}}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-2">
          <button class="base-btn arrowButton" (click)="removeRestriction()"><i class="fa fa-angle-left"></i></button>
          <button class="base-btn arrowButton float-right" (click)="addRestriction()"><i class="fa fa-angle-right"></i></button>
        </div>
        <div class="col-5">
          <div class="restriction">
            <app-material-form-input [(text)]="localRestrictionsInput"
                                     (textChange)="localRestrictions = reduceList(getSelectedDataSuppliers(), $event)"
                                     [placeholder]="'TYRE.SEARCHTERM' | translate">
            </app-material-form-input>
            <i class="fa fa-times" (click)="localRestrictions = getSelectedDataSuppliers(); localRestrictionsInput = ''"
               *ngIf="localRestrictionsInput?.length > 0">
            </i>
            <div class="restrictionBox">
              <div class="restrictionItem" *ngFor="let restriction of localRestrictions"
                   (click)="selectedRestriction = restriction"
                   [ngClass]="{'selected': selectedRestriction && selectedRestriction === restriction}">
                <span>{{restriction.second}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row customId">
        <div class="col">
          <div class="club-setting-title">
            <h1>
              <strong>
                {{'CLUB_ADMIN.CUSTOM_ID' | translate}}
              </strong>
            </h1>
          </div>

          <app-checkbox [isChecked]="getUseOnlyArticlesWithCustomIdActive()"
                        [labelText]="'CLUB_ADMIN.CUSTOM_ID_TEXT' | translate"
                        (onChange)="setUseOnlyArticlesWithCustomIdActive($event);
                        configService.addUnsavedSetting(configService.selectedSettingName)">
          </app-checkbox>
        </div>
      </div>
    </ng-container>

  <div class="row customId">
    <div class="col">
      <div class="club-setting-title">
        <h1>
          <strong>
            {{'CLUB_ADMIN.SEARCH_ALL' | translate}}
          </strong>
        </h1>
      </div>

      <app-checkbox [isChecked]="configService.getSearchForAllArticlesActive()"
                    [labelText]="'CLUB_ADMIN.SEARCH_ALL_TEXT' | translate"
                    (onChange)="configService.setSearchForAllArticlesActive($event);
                        configService.addUnsavedSetting(configService.selectedSettingName)">
      </app-checkbox>
    </div>
  </div>

    <button class="base-btn success ml-3" (click)="configService.saveClubConfig()"
            *ngIf="configService.hasUnsavedSettings(configService.selectedSettingName)">
      <i class="fa fa-check"></i>
      {{'CLUB_ADMIN.PARTNER_MANAGEMENT.SAVE' | translate}}
    </button>
</div>
