import {Component, Input} from '@angular/core';
import {CheckoutService} from '../../service/checkout.service';
import {CartRestService} from '../../../cart/services/cart.rest.service';
import {UserService} from '../../../shared/services/user/user.service';
import {HelperService} from '../../../shared/services/helper.service';
import {finalize} from 'rxjs/operators';
import {Router} from "@angular/router";

@Component({
    selector: 'app-success',
    templateUrl: './success.component.html'
})
export class SuccessComponent {

    @Input() public orderId: string;
    public mail: string;
    public sendEmailLoader: boolean = false;


    constructor(public checkoutService: CheckoutService,
                public router: Router,
                public userService: UserService,
                public helperService: HelperService,
                public cartRestService: CartRestService,
    ) {
    }

    ngOnInit() {
        if (this.userService.accountData && this.userService.accountData.email) {
            this.mail = this.userService.accountData.email;
        }
    }

    public getPackingSlip() {
        return '/api/documents/packing/download/' + this.orderId;
    }

    public triggerEmail() {
        this.sendEmailLoader = true;
        this.cartRestService.packingSlipByMail(this.orderId, this.mail).pipe(finalize(() => {
            this.sendEmailLoader = false;
        })).subscribe((res) => {
            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_SEND_MAIL', 'success');
        }, (error) => {
            if (error.status == 412) {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_EMAIL_NOT_VALID', 'error');
            } else {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_SEND_MAIL', 'error');
            }
        })
    }
}
