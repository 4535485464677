import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
    selector: 'app-material-form-input',
    templateUrl: './material-form-input.component.html',
    styleUrls: ['./material-form-input.component.scss']
})
export class MaterialFormInputComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() label: string;
    @Input() text: string | number;
    @Input() autofocus: boolean | false;
    @Input() type: string | 'text';
    @Output() private textChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() public selected: EventEmitter<any> = new EventEmitter<any>();
    @Input() disabled: boolean | false;
    @Input() focus: boolean | false;
    @Input() step: number | 0.01;
    @Input() pattern?: string;
    @Input() placeholder: string;
    @Input() maxLength: number | 524288;
    @Input() minLength: number | 0;
    @Input() validationErrorMessage: string | '';
    @Input() showValidation: boolean | false;
    @Input() showError: boolean | false;
    @Input() validators: Validators | undefined;
    @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() vinSearchCounter?: number;
    @Input() vinInfoText?: string;

    @ViewChild('inputField', {static: true}) inputField: ElementRef;

    public form: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder,
                private router: Router) {
    }


    ngOnChanges() {
        if (this.focus) {
            this.inputField.nativeElement.focus();
        }

        this.ngOnInit();
    }

    ngAfterViewInit() {
        if (this.autofocus) {
            this.inputField.nativeElement.focus();
        }
    }

    public canAutocomplete() {
        if (this.router.url.endsWith('/login')) {
            return 'true';
        } else {
            return 'new-password';
        }
    }

    ngOnInit() {
        this.form = new UntypedFormGroup({
            _value: new UntypedFormControl(this.text, this.validators)
        });

        if (this.disabled) {
            this.form.disable();
        }
    }


    public onModelChange() {
        const value = this.form.get('_value').value;
        this.textChange.emit(value);
        this.selected.emit(null);
        this.isValid.emit(this.form.valid);
        this.showError = this.form.invalid;
    }

}
