import {IArticlesWorkId} from '../../articles/interfaces/articlesWorkId';
import {IArticleTreeItem} from '../../articles/interfaces/article-tree-item';
import {IArticleListSearchResponse} from '../interfaces/DTO/article/articleListSearchResponse';
import {EventEmitter} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ISupplier} from '../interfaces/DTO/firm/supplier';
import {finalize, take} from "rxjs/operators";


export abstract class SearchService {
    /* Responsive Panel */

    public expanded_right = false;

    /* ================ */
    public selectedArticleHolder: any;
    public searchResult: any;
    public emitItems: EventEmitter<any> = new EventEmitter<any>();
    public articlesLoading: boolean = true;
    public resultItems: any = [];
    public lastSearchParams: any;
    public emitLastSearchParams: EventEmitter<any> = new EventEmitter<any>();
    public selectedCategory: IArticleTreeItem;
    public selectedGenArt: any;
    public infinityLoading = false;
    public workObject: any = {};

    public showGallery = false;
    public selectedImage: any;

    public updateFilterBar: EventEmitter<any> = new EventEmitter<any>();

    public activeSubscription: Subscription;
    public activeArticleSearch: Subscription;

    public activeByIdSubscription: Subscription;

    public availability: Map<string, any> = new Map();
    private pageContent: any;
    private eventStream: Subscription;

    public supplierType: string = 'AFTER_MARKET';
    public oeSuppliers: any;
    private supplierList: ISupplier[];

    public showRetryButton: boolean = false;

    public selectedArticleChanged$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

    public partList: any;
    public availabilityDone: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private abstractSearchCarService,
                private abstractSearchArticleRestService,
                private abstractSearchUserService,
                private abstractCompanyAdminService,
                private abstractTranslateService,
                private abstractToastrService,
                private abstractConfigService) {

        this.abstractSearchUserService.suppliers$.subscribe((supplier) => {
            if (supplier.length) {
                const supplierFromLocalStorage = this.getSupplierFromLocalStorage();

                for (let i = 0; i < supplier.length; i++) {
                    if (supplier[i].supplier === supplierFromLocalStorage) {
                        this.selectedSearchResultSupplier = supplierFromLocalStorage;
                        return;
                    } else if (i === (supplier.length - 1)) {
                        this.selectedSearchResultSupplier = supplier[0].supplier;
                        return;
                    }
                }
            }
        });

        this.reset();
        this.abstractSearchCarService.selectedCarChanged$.subscribe(
            (step) => {
                this.expanded_right = false;
                this.reset();
            }
        );
    }

    public get selectedSearchResultSupplier() {
        if (!localStorage.getItem('supplier') && this.supplierList && this.supplierList.length > 0) {
            this.selectedSearchResultSupplier = this.supplierList[0].name;
        }
        return localStorage.getItem('supplier');
    }

    public set selectedArticle(selectedArticle) {
        this.selectedArticleHolder = selectedArticle;
        this.selectedArticleChanged$.next(selectedArticle);
    }

    public get selectedArticle() {
        return this.selectedArticleHolder;
    }

    public set selectedSearchResultSupplier(supplier: string) {
        localStorage.setItem('supplier', supplier);
    }

    public getSupplierFromLocalStorage() {
        return localStorage.getItem('supplier');
    }

    public get selectedSearchResultOeSupplier() {
        if (!localStorage.getItem('supplier') && this.supplierList && this.supplierList.length > 0) {
            this.selectedSearchResultSupplier = this.supplierList[0].name;
        }
        return localStorage.getItem('oeSupplier');
    }

    public getOeSupplierFromLocalStorage() {
        return localStorage.getItem('oeSupplier');
    }

    public set selectedSearchResultOeSupplier(oeSupplier: string) {
        localStorage.setItem('oeSupplier', oeSupplier);
    }

    public getAavailability(full: boolean = false, searchType: string = 'AFTER_MARKET') {
        if ((this.abstractSearchUserService.suppliers && this.abstractSearchUserService.suppliers.length > 0) || searchType === 'OE') {
            const articleList = [];
            if (full) {
                this.availability = new Map();
            }
            if (this.abstractSearchUserService.enableInfiniteScroll) {
                if (full) {
                    this.addToArticleList(this.resultItems, articleList);
                } else {
                    this.addToArticleList(this.pageContent, articleList);
                }
            } else {
                this.addToArticleList(this.searchResult.items ? this.searchResult.items : this.searchResult, articleList);
            }

            if (articleList.length > 0) {
                articleList.forEach((articleId) => {
                    this.availability.set(articleId, '');
                });
                if (searchType === 'AFTER_MARKET') {
                    const temp = {articleIds: articleList, supplier: this.selectedSearchResultSupplier};
                    if (temp.supplier) {
                        this.abstractSearchArticleRestService.getAvailabilities(temp).pipe(finalize(() => {
                            this.availabilityDone.emit(true);
                        }))
                            .subscribe((response) => {
                                if (response) {
                                    response.forEach((article) => {
                                        this.availability.set(article.articleId, article);
                                    });
                                }
                            });
                    }
                } else if (searchType === 'OE') {
                    if (!this.selectedSearchResultOeSupplier) {
                        this.abstractSearchArticleRestService.getOeSuppliers().pipe(finalize(() => {
                            this.availabilityDone.emit(true);
                        })).subscribe(
                            (suppliers) => {
                                this.selectedSearchResultOeSupplier = suppliers[0]?.supplier
                                const localStorageSupplier = this.getOeSupplierFromLocalStorage();

                                for (const sup of suppliers) {
                                    if (sup.supplier === localStorageSupplier) {
                                        this.selectedSearchResultOeSupplier = localStorageSupplier;
                                    }
                                }

                                this.oeSearch(articleList);
                            })
                    } else {
                        this.oeSearch(articleList);
                    }
                }
            }
        } else {
            this.availabilityDone.emit(true);
        }
    }

    private oeSearch(articleList: any) {
        const temp = {articleIds: articleList, supplier: this.selectedSearchResultOeSupplier};
        this.abstractSearchArticleRestService.getOeAvailabilities(temp).subscribe((response) => {
            if (response) {
                response.forEach((article) => {
                    this.availability.set(article.articleId, article);
                });
            }
        }, (error) => {
            articleList.forEach((articleId) => {
                const article = {articleId: articleId, supplier: 'OEMOCK', schedule: [{availability: 'NOT_AVAILABLE'}]}
                this.availability.set(articleId, article);
            })
        })
    }


    private addToArticleList(items: any[], articleList: any[]) {
        items.forEach((item) => {
            if (!item.pseudoArticle) {
                articleList.push(this.buildArticleListItem(item));
            }
        });
    }

    private buildArticleListItem(item: any): string {
        return item.id;
    }


    public reset() {
        this.selectedArticle = undefined;
        this.searchResult = undefined;
        this.articlesLoading = false;
    }

    public emitLastSearch() {
        if (this.resultItems) {
            this.emitItems.emit(this.resultItems);
        }
    }

    // update by Accessories
    public getAccessoriesListlist() {
        this.articlesLoading = true;

        if (this.abstractSearchUserService.enableInfiniteScroll) {
            this.infinityLoading = true;
        }

        this.abstractSearchArticleRestService.getArticleAccessorieslist(this.selectedArticle.id,
            this.selectedArticle.kTypeId, this.selectedArticle.adcTypeId).subscribe(
            (response) => {
                this.searchResult = response;
                this.searchResult.isAccessorieslist = true;
                this.selectedCategory = response;
                this.lastSearchParams = response;
                this.emitLastSearchParams.emit(this.lastSearchParams);
                this.pageContent = [];
                this.resultItems = [];

                this.articlesLoading = false;
                this.infinityLoading = false;

                if (this.abstractSearchUserService.enableInfiniteScroll && response && response.items) {
                    for (const item of response.items) {
                        this.resultItems.push(item);
                        this.pageContent.push(item);
                    }
                    this.emitItems.emit(this.resultItems);
                }
                this.getAavailability();
            }
        );
    }

    // update by Article PartList
    public updateByArticlePartlist(): Promise<boolean> {
        this.expanded_right = false;
        this.articlesLoading = true;

        if (this.abstractSearchUserService.enableInfiniteScroll) {
            this.infinityLoading = true;
        }
        return new Promise<boolean>((resolve, reject) => {
            this.abstractSearchArticleRestService.getArticlePartlist(this.selectedArticle.id,
                this.selectedArticle.kTypeId, this.selectedArticle.adcTypeId).subscribe(
                (response) => {
                    this.searchResult = response;
                    this.selectedCategory = response;
                    this.lastSearchParams = response;
                    this.emitLastSearchParams.emit(this.lastSearchParams);
                    this.pageContent = [];
                    this.resultItems = [];

                    this.articlesLoading = false;
                    this.infinityLoading = false;

                    if (this.abstractSearchUserService.enableInfiniteScroll && response && response.items) {
                        for (const item of response.items) {
                            this.resultItems.push(item);
                            this.pageContent.push(item);
                        }

                        this.emitItems.emit(this.resultItems);
                    }
                    this.getAavailability();
                    this.partList = response.items;
                    resolve(true);
                },
                error => {
                    reject(false)
                }
            )
        });
    }

    // updateArticleSearch
    public updateArticleSearch(page?: number, filter?: any, searchForAllArticles?: boolean, selectedType = 'AFTER_MARKET') {
        if (this.abstractConfigService.getAccountDataSettingActive('settings', 'defaultSearchForAllArticles')) {
            searchForAllArticles = true
        }
        if (page === 1) {
            this.availability = new Map();
        }

        this.expanded_right = false;
        const p = this.lastSearchParams;

        if (!p) {
            return;
        }

        if (this.lastSearchParams.searchedByString) {
            const searchString = this.lastSearchParams.searchString;
            if (!filter) {
                filter = [];
            }
            if (!searchForAllArticles && this.abstractCompanyAdminService.firmSettings['defaultSearchForAllArticles']) {
                searchForAllArticles = this.abstractCompanyAdminService.firmSettings['defaultSearchForAllArticles'].value;
            }
            if (this.lastSearchParams.searchgenArtNumbers) {
                this.updateArticlesByOeSearchString(searchString, this.lastSearchParams.searchgenArtNumbers, filter, searchForAllArticles, page);
            } else {
                this.updateArticlesBySearchString(searchString, filter, searchForAllArticles, page, selectedType);
            }

        } else if (this.lastSearchParams.searchType === 'genart') {
            this.updateTreeBySelectedGenArt(this.selectedGenArt, page, filter, searchForAllArticles);
        } else if (this.lastSearchParams.searchType === 'work') {
            this.updateArticlesByWorkId(this.workObject, page, filter);
        } else {
            this.updateTreeBySelectedCategory(page, filter, searchForAllArticles);
        }
    }

    public updateArticlesByWorkId(articles: IArticlesWorkId, page?: number, filter?: any, selectedTab?: any) {
        if (this.abstractConfigService.getAccountDataSettingActive('settings', 'defaultSearchForAllArticles')) {
            articles.searchForAllArticles = true
        }
        if (this.abstractSearchUserService.enableInfiniteScroll && page && page > 1) {
            this.infinityLoading = true;
        }
        this.showRetryButton = false;
        if (page === 1) {
            this.availability = new Map();
        }

        if (this.activeByIdSubscription && !this.activeByIdSubscription.closed) {
            this.activeByIdSubscription.remove(this.activeByIdSubscription);
            this.activeByIdSubscription.unsubscribe();
        }

        this.expanded_right = false;
        // this.resetAnimation();
        if (page !== articles.page) {
            articles.page = page;
        }

        this.selectedArticle = undefined;

        if (!this.abstractSearchUserService.enableInfiniteScroll
            || this.abstractSearchUserService.enableInfiniteScroll && (page === 1 || page === undefined)) {
            this.articlesLoading = true;
        }
        if (filter) {
            articles.activeFilters = filter;
        }
        this.activeByIdSubscription = this.abstractSearchArticleRestService.getArticleByWorkId(articles).subscribe(
            (response) => {
                response.requestQuantity = (articles.requestQuantity ? articles.requestQuantity : undefined);
                if (this.searchResult !== response) {
                    this.showRetryButton = false;
                    this.searchResult = response;
                    this.workObject.requestQuantity = response.requestQuantity;
                    this.workObject.kTypeId = articles.kTypeId;
                    this.workObject.adcTypeId = articles.adcTypeId;
                    this.workObject.itemMpId = articles.itemMpId;
                    this.workObject.linkingTargetType = articles.linkingTargetType;
                    this.workObject.page = articles.page;
                    this.workObject.pageSize = articles.pageSize;
                    this.workObject.workId = articles.workId;
                    this.workObject.activeFilters = filter;

                    this.selectedCategory = response;
                    this.lastSearchParams = response;
                    this.emitLastSearchParams.emit(this.lastSearchParams);
                    this.lastSearchParams.searchType = 'work';
                    this.pageContent = [];


                    if (articles.activeFilters.length && articles.activeFilters !== filter) {
                        this.resultItems = []
                    }

                    for (const item of response.items) {
                        this.resultItems.push(item);
                        this.pageContent.push(item);
                    }

                    setTimeout(() => {
                        this.articlesLoading = false;
                        this.infinityLoading = false;
                        this.emitItems.emit(this.resultItems);
                        this.updateFilterBar.emit();
                    }, 500);
                }


                if (articles.requestQuantity && this.searchResult && this.searchResult.items) {
                    this.searchResult.items.forEach((item) => {
                        item.requestQuantity = articles.requestQuantity;
                    });
                }
                this.getAavailability();
            },
            (error) => {
                this.showRetryButton = true;
                this.articlesLoading = false;
                this.infinityLoading = false;
                this.abstractTranslateService.get('TOAST_MESSAGES.ERROR_SEARCH_ARTICLES').subscribe((msg) => {
                    this.abstractToastrService.error(msg, '');
                });
            }
        );
    }

    public updateArticlesBySearchString(searchString: string, filter?: any[], searchForAllArticles?: boolean, page: number = 1, searchType = 'AFTER_MARKET') {
        this.abstractConfigService.checkInfiniteScroll();
        if (this.abstractConfigService.getAccountDataSettingActive('settings', 'defaultSearchForAllArticles')) {
            searchForAllArticles = true
        }
        this.lastSearchParams = [];
        this.emitLastSearchParams.emit(this.lastSearchParams);

        if (page === 1) {
            this.availability = new Map();
        }

        if (!searchForAllArticles && this.abstractCompanyAdminService.firmSettings['defaultSearchForAllArticles']) {
            searchForAllArticles = this.abstractCompanyAdminService.firmSettings['defaultSearchForAllArticles'].value;
        }

        if (searchString) {

            this.expanded_right = false;

            if (!this.abstractSearchUserService.enableInfiniteScroll || this.abstractSearchUserService.enableInfiniteScroll
                && (page === 1 || page === undefined)) {
                this.articlesLoading = true;
            }

            if (this.abstractSearchUserService.enableInfiniteScroll && page && page > 1) {
                this.infinityLoading = true;
            }

            if (searchType === 'AFTER_MARKET') {
                this.abstractSearchArticleRestService.directSearchArticles(searchString, filter, searchForAllArticles, page).subscribe(
                    (response) => {
                        this.articlesLoading = false;
                        this.infinityLoading = false;
                        this.supplierType = searchType;
                        this.applyResult(response, searchString, page, 'AFTER_MARKET', false);
                    },
                    (error) => {
                        this.articlesLoading = false;
                        this.infinityLoading = false;
                        this.abstractTranslateService.get('TOAST_MESSAGES.ERROR_SEARCH_ARTICLES').subscribe((msg) => {
                            this.abstractToastrService.error(msg, '');
                        });
                    }
                );
            } else if (searchType === 'OE') {
                this.abstractSearchUserService.setUsageRest('OE_ARTICLE').pipe(take(1)).subscribe(() => {
                });
                this.abstractSearchArticleRestService.oeDirectSearch(searchString, page).subscribe(
                    (response) => {
                        this.articlesLoading = false;
                        this.infinityLoading = false;
                        this.supplierType = searchType;
                        this.applyResult(response, searchString, page, 'OE');
                    },
                    (error) => {
                        this.articlesLoading = false;
                        this.infinityLoading = false;
                        this.abstractTranslateService.get('TOAST_MESSAGES.ERROR_SEARCH_ARTICLES').subscribe((msg) => {
                            this.abstractToastrService.error(msg, '');
                        });
                    }
                );
            }
        }
    }

    public applyResult(response: IArticleListSearchResponse, searchString: string, page: number, searchType: string = 'AFTER_MARKET', noAvailabilities?: boolean) {
        if (this.abstractSearchUserService.enableInfiniteScroll && this.searchResult && page > 1) {
            this.searchResult.page = response.page;
            this.searchResult.activeFilters = response.activeFilters;
            this.searchResult.possibleFilters = response.possibleFilters;
            this.searchResult.searchForAllArticles = response.searchForAllArticles;
        } else {
            this.searchResult = response;
            this.resultItems = [];
        }
        this.lastSearchParams = {
            searchedByString: true,
            searchString: searchString,
            adcTypeId: undefined,
            kTypeId: undefined,
            searchForAllArticles: response['all'],
            linkingTargetType: undefined,
            genericArticleIds: undefined,
            searchType: 'direct'
        };
        this.emitLastSearchParams.emit(this.lastSearchParams);

        this.pageContent = [];
        for (const item of response.items) {
            this.resultItems.push(item);
            this.pageContent.push(item);
        }
        this.articlesLoading = false;
        this.infinityLoading = false;
        this.emitItems.emit(this.resultItems);
        this.updateFilterBar.emit();
        if (!noAvailabilities) {
            this.getAavailability(undefined, searchType);
        }
    }

    // Update by OE Number with GenArt Number
    public updateArticlesByOeSearchString(searchString: string[], genArtNumbers: number[], filter?: any[], searchForAllArticles?: boolean, page: number = 1) {
        if (this.abstractConfigService.getAccountDataSettingActive('settings', 'defaultSearchForAllArticles')) {
            searchForAllArticles = true
        }
        this.lastSearchParams = [];
        this.emitLastSearchParams.emit(this.lastSearchParams);
        if (page === 1) {
            this.availability = new Map();
        }

        if (!searchForAllArticles && this.abstractCompanyAdminService.firmSettings['defaultSearchForAllArticles']) {
            searchForAllArticles = this.abstractCompanyAdminService.firmSettings['defaultSearchForAllArticles'].value;
        }

        if (searchString) {

            this.expanded_right = false;

            if (!this.abstractSearchUserService.enableInfiniteScroll || this.abstractSearchUserService.enableInfiniteScroll
                && (page === 1 || page === undefined)) {
                this.articlesLoading = true;
            }

            if (this.abstractSearchUserService.enableInfiniteScroll && page && page > 1) {
                this.infinityLoading = true;
            }

            const currentCar = this.abstractSearchCarService.selectedCar;
            let carId: number;
            let adcTypeId: number;

            carId = (currentCar && currentCar.kTypeId ? currentCar.kTypeId : 0); // 0 returns universal parts
            adcTypeId = (currentCar && currentCar.adcTypeId ? currentCar.adcTypeId : 0);

            this.abstractSearchUserService.setUsageRest('OE_ARTICLE').pipe(take(1)).subscribe(() => {
            });
            this.abstractSearchArticleRestService.oeSearchArticles(searchString, genArtNumbers, carId, adcTypeId, filter, searchForAllArticles, page).subscribe(
                (response) => {
                    if (this.abstractSearchUserService.enableInfiniteScroll && this.searchResult && page > 1) {
                        this.searchResult.page = response.page;
                        this.searchResult.activeFilters = response.activeFilters;
                        this.searchResult.possibleFilters = response.possibleFilters;
                        this.searchResult.searchForAllArticles = response.searchForAllArticles;
                    } else {
                        this.searchResult = response;
                        this.resultItems = [];
                    }
                    this.lastSearchParams = {
                        searchedByString: true,
                        searchString: searchString,
                        searchgenArtNumbers: genArtNumbers,
                        adcTypeId: adcTypeId,
                        kTypeId: carId,
                        searchForAllArticles: response.all,
                        linkingTargetType: undefined,
                        genericArticleIds: undefined,
                        searchType: 'oe'
                    };
                    this.emitLastSearchParams.emit(this.lastSearchParams);

                    this.pageContent = [];

                    for (const item of response.items) {
                        this.resultItems.push(item);
                        this.pageContent.push(item);
                    }
                    this.articlesLoading = false;
                    this.infinityLoading = false;
                    this.emitItems.emit(this.resultItems);
                    this.updateFilterBar.emit();
                    this.getAavailability();
                },
                (error) => {
                    this.articlesLoading = false;
                    this.infinityLoading = false;
                    this.abstractTranslateService.get('TOAST_MESSAGES.ERROR_SEARCH_ARTICLES').subscribe((msg) => {
                        this.abstractToastrService.error(msg, '');
                    });
                }
            );
        }
    }

    // Update by GenArt
    public updateTreeBySelectedGenArt(genArt: any, page?: number, filter?: any[], searchForAllArticles?: boolean, car?: any) {
        if (this.abstractConfigService.getAccountDataSettingActive('settings', 'defaultSearchForAllArticles')) {
            searchForAllArticles = true
        }
        if (page === 1) {
            this.availability = new Map();
        }

        if (this.activeArticleSearch) {
            this.activeArticleSearch.unsubscribe();
        }

        this.expanded_right = false;
        if (genArt) {
            this.selectedGenArt = genArt;
            let currentCar = undefined;
            if (!car) {
                currentCar = this.abstractSearchCarService.selectedCar;
            } else {
                currentCar = car;
            }

            let carId: number;
            let adcTypeId: number;

            carId = (currentCar && currentCar.kTypeId ? currentCar.kTypeId : 0); // 0 returns universal parts
            adcTypeId = (currentCar && currentCar.adcTypeId ? currentCar.adcTypeId : 0);

            const linkingTargetType = 'P';

            // this.resetAnimation();

            this.articlesLoading = true;
            this.showRetryButton = false;
            this.activeArticleSearch = this.abstractSearchArticleRestService.searchArticles(carId, adcTypeId,
                linkingTargetType, genArt, filter, searchForAllArticles, (page ? page : 1)).subscribe(
                (response) => {
                    this.searchResult = response;
                    if (this.abstractSearchUserService.enableInfiniteScroll && this.searchResult && page > 1) {
                        this.searchResult.page = response.page;
                        this.searchResult.activeFilters = response.activeFilters;
                        this.searchResult.possibleFilters = response.possibleFilters;
                        this.searchResult.searchForAllArticles = response.searchForAllArticles;
                    } else {
                        this.searchResult = response;
                        this.resultItems = [];
                    }
                    this.lastSearchParams = {
                        searchedByString: false,
                        searchString: undefined,
                        adcTypeId: adcTypeId,
                        kTypeId: carId,
                        searchForAllArticles: response.all,
                        linkingTargetType: (this.selectedCategory
                        && this.selectedCategory.linkingTargetType ? this.selectedCategory.linkingTargetType : linkingTargetType),
                        genericArticleIds: genArt,
                        searchType: 'genart'
                    };
                    this.emitLastSearchParams.emit(this.lastSearchParams);

                    this.pageContent = [];
                    for (const item of response.items) {
                        this.resultItems.push(item);
                        this.pageContent.push(item);
                    }


                    this.articlesLoading = false;
                    this.infinityLoading = false;
                    this.emitItems.emit(this.resultItems);
                    this.updateFilterBar.emit();
                    this.getAavailability();
                },
                (error) => {
                    this.showRetryButton = true;
                    this.articlesLoading = false;
                    this.infinityLoading = false;
                    this.abstractTranslateService.get('TOAST_MESSAGES.ERROR_SEARCH_ARTICLES').subscribe((msg) => {
                        this.abstractToastrService.error(msg, '');
                    });
                }
            );
        }
    }


    // Update by Selected NodeId
    public updateTreeBySelectedCategory(page?: number, filter?: any, searchForAllArticles?: boolean) {
        if (this.abstractConfigService.getAccountDataSettingActive('settings', 'defaultSearchForAllArticles')) {
            searchForAllArticles = true
        }
        if (page === 1 || page === undefined) {
            this.availability = new Map();
        }

        if (this.activeArticleSearch) {
            this.activeArticleSearch.unsubscribe();
        }

        if (!searchForAllArticles && this.abstractCompanyAdminService.firmSettings['defaultSearchForAllArticles']) {
            searchForAllArticles = this.abstractCompanyAdminService.firmSettings['defaultSearchForAllArticles'].value;
        }

        this.expanded_right = false;
        if (this.selectedCategory && !this.articlesLoading) {

            // this.resetAnimation();

            if (!this.abstractSearchUserService.enableInfiniteScroll
                || this.abstractSearchUserService.enableInfiniteScroll && (page === 1 || page === undefined)) {
                this.articlesLoading = true;
            }
            if (this.abstractSearchUserService.enableInfiniteScroll && page && page > 1) {
                this.infinityLoading = true;
            } else {
                this.articlesLoading = true;
            }


            this.activeArticleSearch = this.abstractSearchArticleRestService.searchArticles(this.selectedCategory.kTypeId,
                this.selectedCategory.adcTypeId, this.selectedCategory.linkingTargetType,
                this.selectedCategory.genericArticleIds, filter, searchForAllArticles, (page ? page : 1)).subscribe(
                (response) => {
                    /*
                     duration of loading animation.
                     required to reset the scroll position
                     */
                    if (this.articlesLoading) {
                        setTimeout(() => {
                            this.articlesLoading = false;
                            this.infinityLoading = false;
                        }, 500);
                    }
                    if (this.selectedCategory) {
                        if (this.abstractSearchUserService.enableInfiniteScroll && this.searchResult && page > 1) {
                            this.searchResult.page = response.page;
                            this.searchResult.activeFilters = response.activeFilters;
                            this.searchResult.possibleFilters = response.possibleFilters;
                            this.searchResult.searchForAllArticles = response.searchForAllArticles;
                            if (this.lastSearchParams && this.lastSearchParams.requestQuantity) {
                                response.items.forEach((item) => {
                                    item.requestQuantity = this.lastSearchParams.requestQuantity;
                                })
                            }
                        } else {
                            this.searchResult = response;
                            this.resultItems = [];
                        }
                        this.lastSearchParams = {
                            searchedByString: false,
                            searchString: undefined,
                            adcTypeId: this.selectedCategory.adcTypeId,
                            kTypeId: this.selectedCategory.kTypeId,
                            searchForAllArticles: response.all,
                            linkingTargetType: this.selectedCategory.linkingTargetType,
                            genericArticleIds: this.selectedCategory.genericArticleIds,
                            name: this.selectedCategory.name,
                            searchType: 'side',
                            requestQuantity: (this.lastSearchParams ? this.lastSearchParams.requestQuantity : 1)
                        };
                        this.emitLastSearchParams.emit(this.lastSearchParams);
                        this.pageContent = [];

                        for (const item of response.items) {
                            this.resultItems.push(item);
                            this.pageContent.push(item);
                        }
                        this.infinityLoading = false;
                        this.emitItems.emit(this.resultItems);
                        this.updateFilterBar.emit();
                        this.getAavailability();
                    }
                },
                (error) => {
                    this.articlesLoading = false;
                    this.infinityLoading = false;
                    this.abstractTranslateService.get('TOAST_MESSAGES.ERROR_SEARCH_ARTICLES').subscribe((msg) => {
                        this.abstractToastrService.error(msg, '');
                    });
                }
            );
        }
    }
}
