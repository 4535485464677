import {Injectable, OnInit} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {environment} from '../../../environments/environment';
import {IArticlesFilter} from '../interfaces/articles-filter';
import {HttpClient} from '@angular/common/http';
import {ITecDocTreeNode} from '../../shared/interfaces/DTO/tecDocTreeNode';
import {IArticleListSearchResponse} from '../../shared/interfaces/DTO/article/articleListSearchResponse';
import {IArticleDetails} from '../../shared/interfaces/DTO/article/articleDetails';
import {IQuickSearchElement} from '../../shared/interfaces/DTO/article/quickSearchElement';
import {ISearchByWorkIdRequest} from '../../shared/interfaces/DTO/article/searchByWorkIdRequest';
import {IAvailabilityListRequest} from '../../shared/interfaces/DTO/article/availabilityListRequest';
import {IRetailerAvailabilityDetails} from '../../shared/interfaces/DTO/retailerAvailabilityDetails';
import {ICalculationCost} from '../../shared/interfaces/DTO/calculationCost';
import {ICar} from '../../shared/interfaces/DTO/car/car';

@Injectable()
export class ArticlesRestService implements OnInit {

    constructor(private http: HttpClient) {
    }
    ngOnInit() {

    }

    public getTreeData(kTypeId: number, adcTypeId: number): Observable<ITecDocTreeNode[]> {
        return this.http.get<ITecDocTreeNode[]>(
            `${environment.apiUrl}/tecdoc/tree/${kTypeId}/${adcTypeId}`
        )
    }

    public searchArticles(kTypeId: number,
                          adcTypeId: number,
                          linkingTargetType: string,
                          genArtIds: number[],
                          filters: IArticlesFilter[],
                          searchForAllArticles: boolean,
                          page: number = 1,
                          pageSize: number = 20): Observable<IArticleListSearchResponse> {


        return this.http.post<IArticleListSearchResponse>(
            `${environment.apiUrl}/tecdoc/articles/search`,
            {
                activeFilters: filters || [],
                kTypeId: kTypeId,
                adcTypeId: adcTypeId,
                linkingTargetType: linkingTargetType,
                genArtIds: genArtIds || [],
                page: page,
                pageSize: pageSize,
                searchForAllArticles: searchForAllArticles
            }
        );
    }

    public directSearchArticles(
        searchString: string,
        filters: IArticlesFilter[] = [],
        searchForAllArticles: boolean,
        page: number = 1,
        pageSize: number = 20
    ): Observable<IArticleListSearchResponse> {
        return this.http.post<IArticleListSearchResponse>(
            `${environment.apiUrl}/tecdoc/articles/directsearch`,
            {
                activeFilters: filters,
                searchString: searchString,
                searchForAllArticles: searchForAllArticles,
                page: page,
                pageSize: pageSize
            }
        );
    }

    public oeSearchArticles(
        searchString: string,
        genArtIds: string,
        kTypeId: number,
        adcTypeId: number,
        filters: IArticlesFilter[] = [],
        searchForAllArticles: boolean,
        page: number = 1,
        pageSize: number = 20
    ): Observable<IArticleListSearchResponse> {
        return this.http.post<IArticleListSearchResponse>(
            `${environment.apiUrl}/tecdoc/articles/oesearch`,
            {
                activeFilters: filters,
                searchTerms: searchString,
                genArtIds: genArtIds,
                kTypeId: kTypeId,
                adcTypeId: adcTypeId,
                searchForAllArticles: searchForAllArticles,
                page: page,
                pageSize: pageSize
            }

        );
    }


    public oeDirectSearch(
        searchString: string,
        page: number = 1,
        pageSize: number = 20
    ): Observable<IArticleListSearchResponse> {
        return this.http.post<IArticleListSearchResponse>(
            `${environment.apiUrl}/oepart/search`,
            {
                page: page,
                pageSize: pageSize,
                searchTerms: [ searchString ]
            }
        );
    }

    public getPlaceholderCalculationRest(adcTypeId: number, workId: number, itemMpId: number): Observable<ICalculationCost> {
        return this.http.get<ICalculationCost>(
            `${environment.apiUrl}/calculation/placeholder?adcTypeId=${adcTypeId}&workId=${workId}&itemMpId=${itemMpId}`
        );
    }

    public getArticleDetails(articleId: string, kTypeId: number, adcTypeId: number, workId: number, itemMpId: number, dropCar: boolean): Observable<IArticleDetails> {
        return this.http.get<IArticleDetails>(
            `${environment.apiUrl}/tecdoc/article?articleId=${encodeURIComponent(articleId)}&kTypeId=${kTypeId}&adcTypeId=${adcTypeId}&workId=${workId}&itemMpId=${itemMpId}&dropCar=${dropCar}`
        );
    }

    public getOeArticleDetails(articleId: string, brandName: string, kTypeId: number, adcTypeId: number, workId: number, itemMpId: number, dropCar: boolean): Observable<IArticleDetails> {
        return this.http.get<IArticleDetails>(
            `${environment.apiUrl}/oepart/details?articleId=${encodeURIComponent(articleId)}&brandName=${brandName}&kTypeId=${kTypeId}&adcTypeId=${adcTypeId}&workId=${workId}&itemMpId=${itemMpId}&dropCar=${dropCar}`
        );
    }

    public getArticleAccessorieslist(articleId: string, kTypeId: number, adcTypeId: number): Observable<IArticleListSearchResponse> {
        return this.http.get<IArticleListSearchResponse>(
            `${environment.apiUrl}/tecdoc/accessorieslist?mainArticleId=${encodeURIComponent(articleId)}&kTypeId=${kTypeId}&adcTypeId=${adcTypeId}`
        );
    }

    public getArticlePartlist(articleId: string, kTypeId: number, adcTypeId: number): Observable<IArticleListSearchResponse> {
        return this.http.get<IArticleListSearchResponse>(
            `${environment.apiUrl}/tecdoc/partlist?mainArticleId=${encodeURIComponent(articleId)}&kTypeId=${kTypeId}&adcTypeId=${adcTypeId}`
        );
    }

    public getCompatiblecars(articleId: string): Observable<any> {
        return this.http.get(
            `${environment.apiUrl}/tecdoc/compatiblecars?articleId=${encodeURIComponent(articleId)}`
        );
    }
    public getCompatiblecarsOe(articleId: string): Observable<any> {
        return this.http.get(
            `${environment.apiUrl}/oepart/compatiblecars?articleId=${encodeURIComponent(articleId)}`
        );
    }

    public getAssemblyCategories(kTypeId: number): Observable<IQuickSearchElement> {
        return this.http.get<IQuickSearchElement>(
            `${environment.apiUrl}/tecdoc/quicksearch/${kTypeId}`
        );
    }

    public getArticleByWorkId(artikelWorkId: ISearchByWorkIdRequest): Observable<IArticleListSearchResponse> {
        return this.http.post<IArticleListSearchResponse>(
            `${environment.apiUrl}/tecdoc/articles/by-item-mp-ids`,
            artikelWorkId
        );
    }

    public getAvailabilities(articleList: IAvailabilityListRequest): Observable<IRetailerAvailabilityDetails> {
        return this.http.post<IRetailerAvailabilityDetails>(
            `${environment.apiUrl}/article/availabilities`,
            articleList
        );
    }

    public getOeAvailabilities(articleList: IAvailabilityListRequest): Observable<IRetailerAvailabilityDetails> {
        return this.http.post<IRetailerAvailabilityDetails>(
            `${environment.apiUrl}/article/oeavailabilities`,
            articleList
        );
    }

    public getOeSuppliers(): Observable<any> {
        return this.http.get(
            `${environment.apiUrl}/session/oesuppliers`
        )
    }
}
