  <li [@leftTreeAnim]="(nodeTree ? nodeTree.length : 0)"
      *ngFor="let node of nodeTree | cat"
      [ngClass]="{'list': !treeFilter || (treeFilter && treeFilter.articleCategoryIds.indexOf(node.value.id) > -1) || treeFilterByChild(node, treeFilter)}">
    <ng-container *ngIf="!treeFilter || (treeFilter && treeFilter.articleCategoryIds.indexOf(node.value.id) > -1) || treeFilterByChild(node, treeFilter)">
      <div [ngClass]="{'active' : node.value.id == nodeActive}">
        <!--<i class="file fa fa-file" *ngIf="!node.value.children" (click)="onClickActivate(node.value, mainOnlyOpen)"></i>-->
        <i class="folder fa fa-circle" *ngIf="node.value.children" [ngClass]="{'open' : node.value.open && node.value.children , '' : !node.value.open && node.value.children}"
           (click)="node.value.open = toggle(node.value.open, node.value.children);"></i>
        <span (click)="onClickActivate(node.value, mainOnlyOpen); node.value.open = true" [ngClass]="{'file-label' : !node.value.children}">
          {{node.value.name}}
        </span>
      </div>
      <ul *ngIf="node.value.open && node.value.children && node.value.children.length > 0 || (treeFilter && treeFilter.articleCategoryIds.indexOf(node.value.id) > -1)">
        <app-left-tree
                [firstNode]="false"
                [nodeTree]="node.value.children"
                (onActivate)="onClickActivate($event)"
                [nodeActive]="nodeActive"
                [mainOnlyOpen]="false"
        ></app-left-tree>
      </ul>
    </ng-container>
  </li>
