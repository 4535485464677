import { Component, OnInit } from '@angular/core';
import {ExternalNotificationService} from '../../../shared/services/external-notification.service';
import {HelperService} from '../../../shared/services/helper.service';

@Component({
  selector: 'app-user-notifcations',
  templateUrl: './user-notifcations.component.html',
  styleUrls: ['./user-notifcations.component.scss']
})
export class UserNotifcationsComponent implements OnInit {

  constructor(public externalNotificationService: ExternalNotificationService,
              public helperService: HelperService) { }

  ngOnInit() {
  }

  public reloadNotifications() {
    this.externalNotificationService.getAllOwnNotifications();
  }

}
