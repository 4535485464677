<div class="direct-search">
    <div class="search-field" [ngClass]="{ 'opened': showSearchHistory }">
        <app-dropdown [placeholderText]="'HEADER.SEARCH_TYPES.TITLE' | translate"
                      [preSelectedItem]="'AFTER_MARKET'"
                      [translationPrefix]="'HEADER.SEARCH_TYPES'"
                      [items]="articles.searchTypes"
                      [selectedItem]="articles.supplierType"
                      (onItemSelect)="changeSelectedType($event)">
        </app-dropdown>

        <input type="text" class="form-control query"
               [(ngModel)]="articles.directSearchValue"
               (keyup.enter)="onDirectSearch()"
               placeholder="{{ 'HEADER.SEARCH_FIELD_PLACEHOLDER' | translate }}"
               (focus)="showSearchHistory = true">
        <i class="fa fa-search" (click)="onDirectSearch()"></i>
       <div class="search-history" *ngIf="searchHistoryService.getSearchHistory() && searchHistoryService.getSearchHistory().length > 0 && showSearchHistory">
            <ul>
                <li *ngFor="let item of searchHistoryService.getSearchHistory()" (click)="onHistoryArticleSearch(item)">
                    {{ item }}
                </li>
            </ul>

        </div>
    </div>

</div>
