import {Component, OnInit} from '@angular/core';
import {ExternalNotificationRestService} from '../../../shared/services/external-notification.rest.service';
import {IAllNotification, ICreateNotification} from '../../../shared/interfaces/DTO/notification/notification';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {Dateformat} from '../../../shared/dateformat';
import {HelperService} from '../../../shared/services/helper.service';

@Component({
    selector: 'app-global-notifications',
    templateUrl: './global-notifications.component.html',
    styleUrls: ['./global-notifications.component.scss'],
    providers: [{provide: NgbDateParserFormatter, useClass: Dateformat}]
})
export class GlobalNotificationsComponent implements OnInit {

    public notificationTypes: string[];
    public notificationCriteria: string[];
    public selectedNotificationType: string;
    public notificationDescription: string;
    public showDirect: boolean = false;
    public selectedCriteria: string;
    public selectedClubs: any[] = [];
    public notificationHolder: IAllNotification[];
    public possibleClubs: Map<number, string> = new Map<number, string>()
    public possibleClubsArray: any[];
    public loadPossibleClubs: boolean = false;

    // clubs restrictions
    public localClubInput: string = '';1
    public restrictionClubInput: string = '';
    public localClubsList: any[] = [];
    public localRestrictionList: any[] = [];
    public selectedClub: any;
    public selectedRestriction: any;
    public dateTo: any;
    public dateFrom: any;

    constructor(private externalNotificationRestService: ExternalNotificationRestService,
                private helperService: HelperService) {
    }

    ngOnInit() {

        this.getAllMessages();

        this.externalNotificationRestService.getNotificationTypesRest().subscribe(
            (types: string[]) => {
                this.notificationTypes = types;
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_NOTIFICATION_TYPES', 'error');
            });

        this.externalNotificationRestService.getNotificationCriteriaRest().subscribe(
            (criteria: string[]) => {
                this.notificationCriteria = criteria;
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.LOAD_NOTIFICATION_CRITERIA', 'error');

            });

        this.loadPossibleClubs = true;
        this.externalNotificationRestService.getPossibleClubs().subscribe(
            (clubs) => {
                this.possibleClubsArray = [];

                Object.keys(clubs).forEach((key) => {
                    this.possibleClubs.set(parseInt(key), clubs[key]);
                    this.possibleClubsArray.push({name: clubs[key], clubId: key})
                });

                this.localClubsList = this.possibleClubsArray;
                this.localRestrictionList = this.selectedClubs;

                this.loadPossibleClubs = false;
            }, (error) => {
                this.loadPossibleClubs = false;
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET_POSSIBLE_CLUBS', 'error');
            });
    }

    public reduceList(list: any, searchTerm: string) {
        if (list && searchTerm && searchTerm.length > 0) {
            return list.filter(club => club.name.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1 || club.clubId.toString() === club);
        } else {
            return list;
        }
    }

    public isInRestrictionList(club: any) {
        let isInList = false;

        if (this.selectedClubs) {
            this.selectedClubs.forEach((localClub) => {
                if (localClub.clubId === club.clubId) {
                    isInList = true;
                }
            });
        }

        return isInList;
    }

    public removeRestriction() {
        if (this.selectedRestriction) {
            this.selectedClubs.splice(this.selectedClubs.indexOf(this.selectedRestriction), 1);
            this.localRestrictionList = this.reduceList(this.selectedClubs, this.restrictionClubInput);
        }
    }

    public addRestriction() {
        if (this.selectedClub && !this.isInRestrictionList(this.selectedClub)) {
            this.selectedClubs.push(this.selectedClub);
            this.restrictionClubInput = '';
            this.localRestrictionList = this.reduceList(this.selectedClubs, this.restrictionClubInput);
        }
    }

    private getAllMessages() {
        this.externalNotificationRestService.getAllNotificationsRest().subscribe(
            (notifications: IAllNotification[]) => {
                this.notificationHolder = notifications;
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_NOTIFICATIONS', 'error');
            });
    }

    public getClubRestrictions() {
        if (this.selectedClubs && this.selectedClubs.length > 0) {
            const clubRestriction = [];
            for (const club of this.selectedClubs) {
                clubRestriction.push(club.clubId);
            }
            return clubRestriction;
        } else {
            return [];
        }
    }

    public getStartDate() {
        if (this.dateFrom && typeof this.dateFrom !== 'string' && this.dateFrom.day && this.dateFrom.month && this.dateFrom.year) {
            return this.dateFrom.year + '-' +
                (this.dateFrom.month < 10 ? '0' + this.dateFrom.month : this.dateFrom.month) + '-' +
                (this.dateFrom.day < 10 ? '0' + this.dateFrom.day : this.dateFrom.day);
        } else {
            return '';
        }
    }

    public getEndDate() {
        if (this.dateTo && typeof this.dateTo !== 'string' && this.dateTo.day && this.dateTo.month && this.dateTo.year) {
            return this.dateTo.year + '-' +
                (this.dateTo.month < 10 ? '0' + this.dateTo.month : this.dateTo.month) + '-' +
                (this.dateTo.day < 10 ? '0' + this.dateTo.day : this.dateTo.day);
        } else {
            return '';
        }
    }

    public createNewNotification() {
        const createNotification: ICreateNotification = {
            type: this.selectedNotificationType,
            description: this.notificationDescription,
            active: true,
            showDirect: this.showDirect,
            criterion: this.selectedCriteria,
            clubRestriction: this.getClubRestrictions(),
            start: this.getStartDate(),
            expires: this.getEndDate()
        }
        this.externalNotificationRestService.createNotificationRest(createNotification).subscribe(
            (success) => {
                this.getAllMessages();
                this.selectedCriteria = undefined;
                this.selectedNotificationType = undefined;
                this.showDirect = false;
                this.notificationDescription = '';
                this.selectedClubs = [];
                this.localClubInput = '';
                this.restrictionClubInput = '';
                this.localRestrictionList = this.reduceList(this.selectedClubs, this.restrictionClubInput);
                this.localClubsList = this.reduceList(this.possibleClubsArray, this.localClubInput);
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_CREATE_NOTIFICATION', 'success');
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_CREATE_NOTIFICATION', 'error');
            });
    }

    public deleteNotification(notification: IAllNotification) {
        if (notification) {
            this.externalNotificationRestService.deleteNotificationRest(notification.id).subscribe(
                (success) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_NOTIFICATION', 'success');
                    this.getAllMessages();
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_NOTIFICATION', 'error');
                });
        }
    }

}
