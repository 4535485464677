import {UserService} from './user/user.service';
import {AdminService} from '../../admin/club/services/admin.service';
import {EventEmitter, Injectable} from '@angular/core';
import {AccountRestService} from '../../admin/profile/services/account.rest.service';
import {AdminRestService} from './admin.rest.service';
import {IClubSetting, ISettingWrapper} from '../interfaces/DTO/club/clubSetting';
import {GlobalAdminRestService} from '../../admin/global/services/global-admin.rest.service';
import {IExternalFirmTools} from '../interfaces/DTO/firm/external-tools';
import {CarService} from './car/car.service';
import {HelperService} from './helper.service';
import {IPossibleExternalTool} from '../interfaces/possible-external-tool';
import {CustomerDataService} from './customerData.service';
import {CartRestService} from '../../cart/services/cart.rest.service';
import {ISetting} from '../interfaces/DTO/club/setting';
import {moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Injectable()
export class ConfigService {

    public changeClubConfig: EventEmitter<any> = new EventEmitter<any>();
    public resetDynamicSettingWrapper: EventEmitter<any> = new EventEmitter<any>();
    public selectedExternalTool: IExternalFirmTools;
    public externalToolAsAdmin: boolean = false;
    public showExternalTools: boolean = false;
    public loadExternalTool: string = undefined;
    private clubId: number;
    public clubConfig: any = {
        clubId: undefined,
        clubKey: undefined,
        settings: []
    };
    private unsavedSettings: string[] = [];
    public selectedSetting: any;
    public selectedSettingName: string;
    public selectedSettingIsSortable: boolean;
    private readonly ENTRY_POINTS: string[] = [
        "INDEX",
        "CART",
        "ARTICLES",
        "ARTICLE",
        "TECHNICAL_DATA",
        "WORK",
        "MAINTENANCE",
        "TYRE",
        "TYRES",
        "GRAPHICAL_PART_SEARCH",
        "PARTNER",
        "N4_DIA",
        "MACS_DIA"
    ];
    private readonly ENTRY_POINT_ROLES: string[] = [
        "MAINTENANCE-DATA",
        "WORK-DATA",
        "TECHNICAL-DATA",
        "ARTICLE",
        "TYRE"
    ];
    private readonly ENTRY_POINT_URLS: { [key: string]: string } = {
        "INDEX": "",
        "CART": "cart",
        "ARTICLE": "articles",
        "ARTICLES": "articles",
        "TECHNICAL_DATA": "technical-data",
        "WORK": "work",
        "MAINTENANCE": "maintenance",
        "TYRE": "tyre",
        "TYRES": "tyre",
        "GRAPHICAL_PART_SEARCH": "graphical-part-search",
        "PARTNER": "partner",
        "N4_DIA": "",
        "MACS_DIA": ""
    };

    constructor(private userService: UserService,
                private adminService: AdminService,
                private adminRestService: AdminRestService,
                private accountRestService: AccountRestService,
                private globalAdminRestService: GlobalAdminRestService,
                private helperService: HelperService,
                private carService: CarService,
                private customerDataService: CustomerDataService,
                private cartRestService: CartRestService) {


        this.userService.accountData$.subscribe(
            (step) => {
                if (step) {
                    this.clubConfig.clubId = step.clubId;
                    if (step.clubSettings) {
                        this.clubConfig = step.clubSettings;

                        this.setGraphicalPartSearchConfig();

                        this.changeNameForLocalTranslation()
                    } else {
                        this.getClubConfig();
                    }
                }
                this.sortClubConfig();
            }
        );

        this.adminService.newClubSettingsSaved.subscribe(
            (clubSettings: IClubSetting) => {
                this.clubConfig = clubSettings;
            });

        this.adminService.onChangeClub.subscribe(
            (club_Id) => {
                this.clubConfig = {
                    clubId: undefined,
                    clubKey: undefined,
                    settings: []
                };
                this.clearUnsavedSettings();
                this.getClubConfigById(club_Id);
            }
        );
    }

    public setSelectedSetting(setting: any) {
        if (this.isSelectableSetting(setting)) {
            this.selectedSetting = undefined;
            setTimeout(() => {
                this.userService.navigationHelper = 'right';
                this.selectedSetting = setting;
            }, 50);
        } else {
            this.userService.navigationHelper = 'left';
            this.selectedSetting = undefined;
        }
    }

    public isSelectableSetting(setting: any) {
        if (setting.className.toString().includes('NumberValueSettingDTO')) {
            return true;
        } else {
            return setting && setting.selectionClass;
        }
    }

    public getCarSelectionSettingForMode(mode: string) {
        const settings = this.getSettingByName('CAR_SELECTION').settings;
        const list: ISetting[] = [];

        if (settings) {
            for (const setting of settings) {
                if (setting.clubSettingListDTOS && setting.clubSettingListDTOS[mode]) {
                    list.push(setting.clubSettingListDTOS[mode]);
                }
            }
        }

        return list.sort(function (a, b) {
            return a.sortId - b.sortId;
        });
    }

    public getSelectionForName(settingName: string, subSettingName: string): string[] {
        const subSetting = this.getSubSettingByName(settingName, subSettingName);

        if (subSetting) {
            return subSetting.selection;
        }
    }

    public getSelectedForName(settingName: string, subSettingName: string): string {
        const subSetting = this.getSubSettingByName(settingName, subSettingName);

        if (subSetting) {
            return subSetting.selected;
        }
    }

    public setSelectedForName(settingName: string, subSettingName: string, selected: string) {
        const subSetting = this.getSubSettingByName(settingName, subSettingName);

        if (subSetting) {
            subSetting.selected = selected;
            this.addUnsavedSetting(settingName);
        }
    }

    public getValueForName(settingName: string, subSettingName: string): string {
        const subSetting = this.getSubSettingByName(settingName, subSettingName);

        if (subSetting) {
            return subSetting.value;
        }
    }

    public setValueForName(settingName: string, subSettingName: string, value: string) {
        const subSetting = this.getSubSettingByName(settingName, subSettingName);

        if (subSetting) {
            subSetting.value = value;
            this.addUnsavedSetting(settingName);
        }
    }

    public getActiveForNameByMode(settingName: string, subSettingName: string, mode: string): boolean {
        const subSetting = this.getSubSettingByName(settingName, subSettingName);

        if (subSetting && subSetting.clubSettingListDTOS && subSetting.clubSettingListDTOS[mode]) {
            return subSetting.clubSettingListDTOS[mode].active;
        }

        return false;
    }

    public getActiveForName(settingName: string, subSettingName: string): boolean {
        const subSetting = this.getSubSettingByName(settingName, subSettingName);

        if (subSetting) {
            return subSetting.active;
        }
        return false;
    }

    public setActiveForName(settingName: string, subSettingName: string, active: boolean) {
        const subSetting = this.getSubSettingByName(settingName, subSettingName);

        if (subSetting) {
            subSetting.active = active;
            this.addUnsavedSetting(settingName);
        }
    }

    public getSettingByName(name: string) {
        if (this.clubConfig && this.clubConfig.settings) {
            for (const setting of this.clubConfig.settings) {
                if (setting.first === name) {
                    return setting.second;
                }
            }

        } else {
            return undefined;
        }
    }

    public getSubSettingByName(settingName: string, subSettingName: string) {
        const setting = this.getSettingByName(settingName);
        if (setting) {
            for (const subSetting of setting.settings) {
                if (subSetting.name === subSettingName) {
                    return subSetting;
                }
            }
        } else {
            return undefined;
        }
    }

    public getAccountDataSettingActive(type = 'settings', key: string) {
        if (key && (type === 'settings' || type === 'firmSettings' || type === 'clubSetting')) {
            return JSON.parse(this.userService.accountData[type][key].value)
        } else {
            return undefined
        }
    }

    public getSearchForAllArticlesActive() {
        const setting = this.getSubSettingByName('TECDOC', 'SEARCH_FOR_ALL_ARTICLES');

        if (setting) {
            return setting.active;
        }
    }

    public setSearchForAllArticlesActive(active: boolean) {
        const setting = this.getSubSettingByName('TECDOC', 'SEARCH_FOR_ALL_ARTICLES');

        if (setting) {
            setting.active = active;
        }
    }


    private setGraphicalPartSearchConfig() {
        const setting = this.getSubSettingByName('NAVIGATION', 'GRAPHICAL_PART_SEARCH');
        if (setting) {
            this.userService.bodygraphicsType = setting.selected;
            this.getActiveForName('EXTENDED_SETTINGS', 'INFINITY_SCROLL');
        }
    }

    public isAutovistaVinSearch() {
        const setting = this.getSubSettingByName('NAVIGATION', 'VIN_SEARCH');

        if (setting) {
            return setting.selected;
        }

        return undefined;
    }

    public canRootToPage(page: string) {
        return this.getActiveForName('NAVIGATION', page);
    }


    public sortList(sortList: any, event: any) {
        if (event.container.id === event.previousContainer.id) {
            moveItemInArray(sortList, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex)
        }
        for (let i = 0; i < sortList.length; i++) {
            if (sortList[i].sortId) {
                sortList[i].sortId = i + 1;
            } else if (sortList[i].sortNo) {
                sortList[i].sortNo = i + 1;
            }
        }
        return sortList;
    }

    private checkEntryPointRights(entry: string): boolean {
        const role = entry.replace('_', '-');
        if (this.ENTRY_POINT_ROLES.includes(role)) {
            return this.userService.allow(role);
        }
        return true; // If role does not need to be checked, allow by default
    }

    public getEntryPoint(): string {
        const entryPointName = this.getEntryPointName();
        return this.translateSelectedEntryPoint(entryPointName);
    }

    public translateSelectedEntryPoint(entryPoint: string): string {
        return this.ENTRY_POINT_URLS[entryPoint] || 'articles';
    }

    public getEntryPointName(): string {
        const dmsEntryPoint = this.customerDataService.getEntryPoint();
        let entryPoint: string;

        if (dmsEntryPoint && this.ENTRY_POINTS.includes(dmsEntryPoint)) {
            entryPoint = dmsEntryPoint;
        } else {
            entryPoint = this.getSubSettingByName('ENTRY_POINT', 'ENTRY_POINT').selected;
        }

        if (this.checkEntryPointRights(entryPoint)) {
            return entryPoint;
        } else {
            return 'ARTICLES';
        }
    }

    public getClubConfig() {
        this.accountRestService.getClubConfig().subscribe((response: IClubSetting) => {
                this.clubConfig = response;
                this.changeClubConfig.emit();
                this.setLinks();
                this.setGraphicalPartSearchConfig();
                this.changeNameForLocalTranslation();
                this.sortClubConfig();
                this.checkInfiniteScroll();
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CLUB_SETTINGS', 'error');
            });
    }

    public getExternalToolsList(): ISetting[] {
        return this.getSettingByName('EXTERNAL_TOOLS').settings ? this.getSettingByName('EXTERNAL_TOOLS').settings : [];
    }

    public checkInfiniteScroll() {
        this.userService.enableInfiniteScroll = this.getActiveForName('EXTENDED_SETTINGS', 'INFINITY_SCROLL');
    }

    public getNumberOfActiveTools(): number {
        if (this.userService.activeExternalTools && this.userService.activeExternalTools.length > 0) {
            return this.userService.activeExternalTools.length;
        } else {
            return 0;
        }
    }

    public isActiveTool(name: string) {
        if (this.userService?.activeExternalTools?.length > 0) {
            for (const tool of this.userService.activeExternalTools) {
                if (tool.code === name) {
                    return true;
                }
            }
        }


        return false;
    }

    public openTool(tool: IPossibleExternalTool) {
        switch (tool.code) {
            case 'DIGITAL_SERVICEBOOK':
                this.showExternalTools = false;
                window.open(this.carService.selectedCar.digitalServiceBook);
                break;
            case 'MACS_DIA':
                if (this.carService.selectedCar) {
                    this.openExternalTool('MACS_DIA');
                } else {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_CAR_SELECTED', 'info');
                }
                break;
            case 'N4_DIA':
                if (this.carService.selectedCar) {
                    this.openExternalTool('N4_DIA');
                } else {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_CAR_SELECTED', 'info');
                }
                break;
            case 'WUERTH_IT':
                this.openExternalTool('WUERTH_IT');
                break;
        }
    }

    private openExternalTool(qualifier: string) {
        return this.cartRestService.openExternalTool((this.createRequestBodyForQualifier(qualifier))).subscribe(
            (response) => {
                this.loadExternalTool = undefined;
                window.open(response, '_blank')
            }, (error) => {
                this.loadExternalTool = undefined;
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET', 'error');
            });
    }

    private createRequestBodyForQualifier(qualifier: string) {
        this.loadExternalTool = qualifier;
        let hsn = ''
        let tsn = '';
        if (this.carService?.selectedCar?.kbaNumbers) {
            hsn = this.carService.selectedCar.kbaNumbers[0].split('_')[0];
            tsn = this.carService.selectedCar.kbaNumbers[0].split('_')[1];
        }

        return {
            'qualifier': qualifier,
            'kTypeId': this.carService.getKTypeId(),
            'adcTypeId': this.carService.getAdcTypeId(),
            'vin': this.carService.getVin(),
            'hsn': hsn,
            'tsn': tsn,
            'mileage': this.customerDataService.getMileage(),
            'firstName': this.customerDataService.getCustomerFirstName(),
            'lastName': this.customerDataService.getCustomerLastName()
        };
    }

    public getFirstActiveTool(): IPossibleExternalTool {
        if (this.userService.activeExternalTools && this.userService.activeExternalTools.length > 0) {
            return this.userService.activeExternalTools[0];
        }

        return undefined;
    }

    // for i18n translation
    private changeNameForLocalTranslation() {

        const navigation = this.getSettingByName('NAVIGATION');

        if (navigation) {
            for (const nav of navigation.settings) {
                nav.name = nav.name.toUpperCase();

                if (nav.name === 'TYRE') {
                    nav.name = 'WHEELS';
                }
                if (nav.name === 'WORK_DATA') {
                    nav.name = 'WORKING_VALUES';
                }
            }

            this.setLinks();
        }
    }

    public getClubConfigById(clubId: number) {
        this.clubId = clubId;
        this.globalAdminRestService.getClubConfigById(clubId).subscribe(
            (response) => {
                this.clubConfig = response;
                this.changeClubConfig.emit();
                this.sortClubConfig();
                this.changeNameForLocalTranslation();
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CLUB_SETTINGS', 'error');
            });
    }

    public sortClubConfig() {
        setTimeout(() => {
            if (this.clubConfig) {
                this.clubConfig.settings.forEach((setting: ISettingWrapper) => {
                    if (setting.second.sortable) {
                        setting.second.settings.sort(function (a, b) {
                            return a.sortId - b.sortId;
                        });
                    }
                });
            }
        }, 50);
    }

    public setLinks() {
        if (this.clubConfig) {
            for (const nav of this.getSettingByName('NAVIGATION').settings) {
                switch (nav.name) {
                    case 'ARTICLE':
                        nav.link = '/articles';
                        break;
                    case 'TECHNICAL_DATA':
                        nav.link = '/technical-data';
                        break;
                    case 'WORKING_VALUES':
                        nav.link = '/work';
                        break;
                    case 'MAINTENANCE':
                        nav.link = '/maintenance';
                        break;
                    case 'WHEELS':
                        nav.link = '/tyre';
                        break;
                    case 'PARTNER':
                        nav.link = '/partner';
                        break;
                    case 'GRAPHICAL_PART_SEARCH':
                        nav.link = '/graphical-part-search';
                        break;
                }
            }
        }
    }

    public hasUnsavedSettings(setting: string) {
        for (const name of this.unsavedSettings) {
            if (name === setting) {
                return true;
            }
        }

        return false;
    }

    public addUnsavedSetting(setting: string) {
        if (!this.hasUnsavedSettings(setting)) {
            this.unsavedSettings.push(setting);
        }
    }

    public removeUnsavedSetting(setting: string) {
        for (let i = 0; i < this.unsavedSettings.length; i++) {
            if (this.unsavedSettings[i] === setting) {
                this.unsavedSettings.splice(i, 1);
                return;
            }
        }
    }

    public clearUnsavedSettings() {
        this.unsavedSettings = [];
    }

    public saveClubConfig() {
        this.clearUnsavedSettings();
        if (this.userService.allow('GLOBAL-ADMIN')) {
            this.globalAdminRestService.saveClubConfig(this.clubConfig).subscribe((clubSettings: IClubSetting) => {
                if (this.getValueForName('GLOBAL_FIRM_LOGO', 'GLOBAL_FIRM_LOGO')) {
                    this.userService.firmLogo = this.getValueForName('GLOBAL_FIRM_LOGO', 'GLOBAL_FIRM_LOGO');
                }
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                this.clubConfig = clubSettings;
                this.userService.accountData.clubSettings.settings = this.clubConfig.settings;
                this.changeClubConfig.emit();
                this.setLinks();
                this.sortClubConfig();
            }, (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_UPDATE', 'error');
            });
        } else if (this.userService.allow('CLUB-ADMIN')) {
            this.adminService.setClubSetting(this.clubConfig);
        }
    }

    public allowOffers() {
        return (this.getActiveForName('EXTENDED_SETTINGS', 'OFFERS') && !this.userService.getCatalogMode());
    }

    public saveLanguage(clubId: number, possibleLanguages: any) {
        this.removeUnsavedSetting('LANGUAGE');
        if (clubId) {
            if (this.userService.allow('GLOBAL-ADMIN')) {
                this.globalAdminRestService.putLanguageUpdate(clubId, possibleLanguages).subscribe(
                    (success) => {
                        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                    });
            } else if (this.userService.allow('CLUB-ADMIN')) {
                this.adminService.setClubSetting(this.clubConfig);
                this.getClubConfig();
            }
        }
    }

    public saveCountry(clubId: number, country: any) {
        this.removeUnsavedSetting(this.selectedSettingName);
        if (clubId) {
            if (this.userService.allow('GLOBAL-ADMIN')) {
                this.globalAdminRestService.putCountryUpdate(clubId, country).subscribe((response) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                });
            }
        } else if (this.userService.allow('CLUB-ADMIN')) {
            this.adminService.setCountry(country).subscribe((response) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
            });
        }
    }

    public deleteRestricton() {
        if (this.userService.allow('GLOBAL-ADMIN')) {
            this.globalAdminRestService.deleteRestrictionForClub(this.clubId).subscribe(
                (success) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_RESTRICTION', 'success');
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_TEMPLATE', 'error');
                }
            );
        }
    }
}
