import {Component, OnInit, ViewChild, Input, ElementRef, EventEmitter, AfterViewInit} from '@angular/core';
import Cropper from 'cropperjs'
import {CompanyAdminService} from '../../../services/company-admin.service';
import {TranslateService} from "@ngx-translate/core";
import {ConfigService} from '../../../../../shared/services/config.service';



@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html'
})

export class ImageCropperComponent implements OnInit, AfterViewInit {
    public imageDestination: string;
    private cropper: Cropper;


    public result: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('image', {static: true}) public imageElement: ElementRef;
    @Input('src') public imageSource: string;
    @Input('parent') public parentComponent: string;

    constructor(
        private companyAdminService: CompanyAdminService,
        private translate: TranslateService,
        private configService: ConfigService
    ) {
        this.imageDestination = '';
    }
    public ngOnInit(): void {

    }
    public ngAfterViewInit() {
        this.cropper = new Cropper(this.imageElement.nativeElement, {
            zoomable: false,
            scalable: false,
            minCropBoxWidth: 200,
            minCropBoxHeight: 100,
            aspectRatio: 2,
            crop: () => {
                const canvas = this.cropper.getCroppedCanvas();
                this.imageDestination = canvas.toDataURL('image/png');
                switch (this.parentComponent) {
                    case 'logo':
                        this.companyAdminService.currentLogo = canvas.toDataURL('image/png');
                        this.companyAdminService.hasLogoChanges = true;
                        break;
                    case 'config':
                        this.configService.setValueForName('GLOBAL_FIRM_LOGO', 'GLOBAL_FIRM_LOGO', canvas.toDataURL('image/png'));
                        break;
                }
            }
        })
    }

}
