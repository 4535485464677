import {Component, OnDestroy, OnInit} from '@angular/core';
import {MaintenanceService} from '../services/maintenance.service';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs/Rx';
import {detailAnim} from '../../shared/animations/general.animation';
import {IManualMainGroup} from '../../shared/interfaces/DTO/manualMainGroup';
@Component({
  selector: 'app-hints',
  templateUrl: './hints.component.html',
    animations: [detailAnim]
})
export class HintsComponent implements OnInit, OnDestroy {

  public selectedManual: IManualMainGroup;
  private manualSubscription: Subscription;
  private paramsID: number;

  constructor(public location: Location,
              private route: ActivatedRoute,
              public maintenanceService: MaintenanceService) { }

  ngOnInit() {

    this.maintenanceService.lastState = '/maintenance/hints';

    if (!this.maintenanceService.manual) {
      this.maintenanceService.getMaintenanceManualTree();
    }

    this.manualSubscription = this.maintenanceService.onLoadedManuals.subscribe(
        (step) => {
          if (this.paramsID && ((this.selectedManual && this.selectedManual.id !== this.paramsID) || !this.selectedManual)) {
            this.selectManual(this.paramsID);
          }
        }
    );

    if (!this.location.path().endsWith('/hints')) {
        this.route.params.subscribe(
            (params) => {
              this.paramsID = params['id'];

              if (!this.selectedManual || this.selectedManual.id !== this.paramsID) {
                  this.selectManual(this.paramsID);
              }
            }
        );
    }
  }

  public printIframe() {
      const frame = window.frames['tecrmi-manual'];
      if (!frame) {
          return;
      }
      const frames: any = document.getElementById('tecrmi-manual');
      frames.contentWindow.focus();
      frames.contentWindow.print();
  }

  ngOnDestroy() {
    this.manualSubscription.unsubscribe();
  }

  private selectManual(id: number) {
    if (this.maintenanceService.manual && this.maintenanceService.manual.length > 0) {
      for (const manual of this.maintenanceService.manual) {
        if (manual.id - id == 0) {
          this.selectedManual = manual;
        }
      }
    }
  }
}
