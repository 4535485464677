<div class="heading blue">
        <span class="clickable" routerLink="/company-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
    <span>
    <i class="fa fa-caret-right"></i>
            {{'USER_PROFILE.FIRMDATA' | translate}}
    </span>
</div>

<app-firm-data *ngIf="!userService.allow('CLUB-ADMIN') && firmData" [isAdmin]="false" [firmData]="firmData"></app-firm-data>
<app-firm-data *ngIf="userService.allow('CLUB-ADMIN') && firmData" [isAdmin]="true" [firmData]="firmData" (_save)="adminService.saveFirm(firmData)"></app-firm-data>

