import {Component, Input, OnInit} from '@angular/core';
import {IWorkItem} from '../../../work-data/interfaces/work-tree';
import {WorkService} from '../../../work-data/services/work.service';
import {IWorkDetails} from '../../../work-data/interfaces/work-details';
import {TranslateService} from '@ngx-translate/core';
import {CatPipe} from '../../pipes/cat.pipe';
import {Location} from '@angular/common';
import {UserService} from '../../services/user/user.service';
import {detailAnim} from '../../animations/general.animation';

@Component({
    selector: 'app-work-details',
    templateUrl: './work-details.component.html',
    styleUrls: ['./work-details.component.scss'],
    animations: [detailAnim]
})
export class WorkDetailsComponent implements OnInit {

    @Input() public service: any;

    public catPipe: CatPipe;
    public work: IWorkDetails[];
    public id: number;
    public workSet: IWorkDetails = {
        adcTypeId: 0,
        mdQualColId: 0,
        ltQualColId: 0,
        workId: 0,
        workText: 'x',
        mountPositionText: 'x',
        repairText: 'x',
        workTime: 0,
        mountPositionId: 0,
        itemQualifierText: 'x',
        itemQualifierId: 0,
        repairImage: 'x',
        posIdInCart: 0,
        workPositions: [0],
        optionalWorkPositions: [0],
        repairId: 0
    };
    public workSetItem: IWorkItem = {
        repairId: 0,
        ltQualColId: 0,
        mountPositionId: 0,
        repairText: 'x',
        mountPositionText: 'x',
        posIdInCart: 0,
        adcTypeId: 0
    };
    public buttonText: string = this.translate.instant('WORK.BUY');
    public toggleText: boolean;

    constructor(public workService: WorkService,
                private translate: TranslateService,
                public location: Location,
                public userService: UserService) {
        this.id = 0;
        this.workService.work = undefined;
        this.workService.setWorkExternal$.subscribe((data: IWorkDetails[]) => {
            if (data !== undefined) {
                this.workService.addWork(data);
                this.work = data;
                this.id = 0;
                this.checkWorkSet();
            }
        });
    }

    private checkWorkSet() {
        let i: number;
        i = 0;
        while (i > -1) {
            if (this.work[i].itemQualifierText === 'Standard') {
                this.workSet = this.work[i];
                i = -1;
            } else if (i < (this.work.length - 1)) {
                i++;
            } else if (i >= (this.work.length - 1)) {
                this.workSet = this.work[0];
                i = -1;
            }
        }
    }

    // ngOnDestroy() {
    //     this.workService.selectedArticle = undefined
    // }

    ngOnInit() {
    }

}
