import {Component, OnInit} from '@angular/core';
import {ConfigService} from '../../../../shared/services/config.service';
import {FirmAdminRestService} from '../../../../shared/services/firm-admin.rest.service';
import {Router} from '@angular/router';
import {AdminRestService} from '../../../../shared/services/admin.rest.service';
import {AdminService} from '../../../club/services/admin.service';
import {IPossibleExternalTool} from '../../../../shared/interfaces/possible-external-tool';
import {UserService} from '../../../../shared/services/user/user.service';
import {HelperService} from '../../../../shared/services/helper.service';
import {Location} from '@angular/common';

@Component({
    selector: 'app-edit-external-tool',
    templateUrl: './edit-external-tool.component.html',
    styleUrls: ['./edit-external-tool.component.scss']
})
export class EditExternalToolComponent implements OnInit {

    public possibleExternalTools: IPossibleExternalTool[];
    public isCreate: boolean;

    constructor(public configService: ConfigService,
                public location: Location,
                private helperService: HelperService,
                private firmAdminRest: FirmAdminRestService,
                private router: Router,
                private adminRestService: AdminRestService,
                private adminService: AdminService,
                private userService: UserService) {
    }

    ngOnInit() {
        this.isCreate = this.router.url.endsWith('/create');

        if (!this.configService.selectedExternalTool) {

            const request = this.configService.externalToolAsAdmin ?
                this.adminRestService.getPossibleExternalTools(this.adminService.selectedFirm.firmId)
                : this.firmAdminRest.getPossibleExternalTools();

            request.subscribe(
                (possibleExternalTools: IPossibleExternalTool[]) => {
                    this.possibleExternalTools = possibleExternalTools;
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET', 'error');
                }
            );
        }
    }

    public saveChanges() {

        const request = this.configService.externalToolAsAdmin ?
            this.adminRestService.updateExternalTool(this.configService.selectedExternalTool)
            : this.firmAdminRest.updateExternalTool(this.configService.selectedExternalTool);

        request.subscribe(
            (success) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_SAVE', 'success');
                this.back();
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_SAVE', 'error');
            });
    }

    public createNewTool() {
        const request = this.configService.externalToolAsAdmin ?
            this.adminRestService.createExternalTool(this.configService.selectedExternalTool)
            : this.firmAdminRest.createExternalTool(this.configService.selectedExternalTool);

        request.subscribe(
            (success) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_SAVE', 'success');

                this.back();
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_SAVE', 'error');
            });
    }

    public changeSelectedExternalTool(value: any) {
        this.configService.selectedExternalTool = {
            active: true,
            id: undefined,
            externalTool: value,
            firmId: this.configService.externalToolAsAdmin ? this.adminService.selectedFirm.firmId : this.userService.accountData.firmId,
            sortNo: undefined,
            customerNo: undefined,
            username: '',
            password: ''
        }
    }

    public getPossibleSupplier() {
        const possibleTools = [];

        for (const tool of this.possibleExternalTools) {
            possibleTools.push(tool.code);
        }

        return possibleTools;
    }

    public getNewExternalToolLink() {
        return this.configService.externalToolAsAdmin ? '/admin/tools' : '/company-admin/tools';
    }

    public back() {
        this.configService.selectedExternalTool = undefined;
        if (!this.configService.externalToolAsAdmin) {
            this.router.navigate(['/company-admin/tools'])
        } else {
            this.router.navigate(['/admin/tools']);
        }
    }
}
