import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {AutovistaRestService} from '../../../../graphical-part-search/services/autovista.rest.service';
import {CarService} from '../../../services/car/car.service';
import {HelperService} from '../../../services/helper.service';
import {CustomerDataService} from '../../../services/customerData.service';
import {UserService} from '../../../services/user/user.service';
import {IVinCarWrapper, IVinSearchResponse} from '../../../interfaces/DTO/car/vinSearchResponse';
import {Subscription} from 'rxjs';
import {CartService} from '../../../../cart/services/cart.service';
import {ConfigService} from '../../../services/config.service';
import {ISetting} from "../../../interfaces/clubSettings";
import {VinRestService} from "../../../services/vin-rest.service";

@Component({
    selector: 'app-car-by-autovista-vin',
    templateUrl: './car-by-autovista-vin.component.html'
})
export class CarByAutovistaVinComponent implements OnInit, OnDestroy, OnChanges {
    @Output() applyCars: EventEmitter<any> = new EventEmitter();
    @Output() selectVinCar: EventEmitter<IVinCarWrapper> = new EventEmitter();
    @Input() _vin: string;
    @Input() catalog: boolean = false;

    public text: any;
    public vinLoading: boolean = false;
    public loadingPreSearch: boolean = false;
    private vinCarSelection: Subscription;
    private natCode: string;
    private ikeCode: string;
    public vinCounter: number;
    public vinInfoText: string;
    public showVinCounter: boolean = false;
    public showVinInfoText: boolean = false;

    constructor(private autovistaRestService: AutovistaRestService,
                public carService: CarService,
                public helperService: HelperService,
                public userService: UserService,
                public configService: ConfigService,
                private customerDataService: CustomerDataService,
                public cartService: CartService,
                private vinRestService: VinRestService) {
    }

    ngOnInit() {
        this.vinCarSelection = this.carService.setSelectedVinCar.subscribe(
            (vinCar) => {
                if (vinCar && (vinCar.natCode || vinCar.typeETGCode)) {
                    this.natCode = vinCar.natCode || vinCar.typeETGCode;
                } else {
                    this.natCode = undefined;
                }

                if (vinCar && (vinCar.code || vinCar.ikeCode)) {
                    this.ikeCode = vinCar.code || vinCar.ikeCode;
                } else {
                    this.ikeCode = undefined;
                }

                this._vin = vinCar.vin;
                this.onVinSearch();
            });
        if (this.carService.getVin().length > 0) {
            this._vin = this.carService.getVin();
        } else {
            this._vin = this.customerDataService.getVin();
        }


        // get and set Vin Settings
        this.showVinInfoText = this.userService.getSubSettingForName('EXTENDED_SETTINGS', 'VIN_TEXT').active;
        this.showVinCounter = this.userService.getSubSettingForName('EXTENDED_SETTINGS', 'SHOW_VIN_COUNTER').active;
        this.vinRestService.getVinCounter().subscribe((counter: number) => this.vinCounter = counter);
        const vinTextSetting: ISetting = this.userService.getSubSettingForName('EXTENDED_SETTINGS', 'VIN_TEXT');
        if (vinTextSetting?.value?.length) {
            this.vinInfoText = vinTextSetting.value;
        }

        if (this._vin && !this.carService.modalHidden) {
            this.preSearchVin();
        }
    }

    ngOnDestroy(): void {
        this.vinCarSelection.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes._vin && changes._vin.currentValue !== undefined) {
            this.preSearchVin();
        }
    }

    private preSearchVin() {
        this.loadingPreSearch = true;
        this.getFreeVinCar();
    }

    public getFreeVinCar() {
        this.autovistaRestService.getAutovistaVinRest(this.carService.replaceVin(this._vin), false, this.natCode, this.ikeCode).subscribe(
            (vinCar: IVinSearchResponse) => {
                if (vinCar.errorCode === 0 && vinCar.carDtos.length < 6) {
                    this.carService.showPreSelection = true;
                    this.carService.preSelectedVinCar = vinCar.carDtos;
                } else if (vinCar.errorCode === 0 && vinCar.carDtos.length > 5) {
                    this.carService.preSelectedVinCar = vinCar.carDtos;
                    this.carService.showPreSelection = true;
                    this.carService.modalHidden = true;
                    this.applyCars.emit(vinCar);
                }
            },
            (error) => {
                if (error.status === 409) {
                    this.carService.handleCarConflict();
                }
            }
        );
        this.loadingPreSearch = true;
    }

    public checkSelection(vinCar: IVinSearchResponse): IVinCarWrapper {
        const vinCarWrapper: IVinCarWrapper = {
            vinCar: undefined,
            typeId: -1,
            vin: undefined,
            natCode: undefined,
            ikeCode: undefined
        };

        if (vinCar.carDtos && vinCar.carDtos.length > 0) {
            vinCarWrapper.vinCar = vinCar.carDtos;
            for (const car of vinCarWrapper.vinCar) {
                car.fromVIN = true;
            }
            vinCarWrapper.typeId = 0;
        } else if (vinCar.vinSelection
            && vinCar.vinSelection.specification
            && vinCar.vinSelection.specification.vehicles
            && vinCar.vinSelection.specification.vehicles.length > 1) {
            if (vinCar.vinSelection.specification.vehicles.length === 1) {
                vinCar.vinSelection.specification.vehicles[0].vin = this._vin;
                vinCar.vinSelection.specification.vehicles[0].natCode = this.natCode;
                this.carService.setSelectedVinCar.emit(vinCar.vinSelection.specification.vehicles[0]);
                vinCarWrapper.typeId = 3;
            } else {
                vinCarWrapper.ikeCode = this.ikeCode;
                vinCarWrapper.vin = vinCar.vinSelection.vin;
                vinCarWrapper.vinCar = vinCar.vinSelection.specification.vehicles;
                vinCarWrapper.typeId = 2;
                vinCarWrapper.errorCode = vinCar.errorCode;
            }

        } else if (vinCar.vinSelection
            && vinCar.vinSelection.repair
            && vinCar.vinSelection.repair.headmodels
            && vinCar.vinSelection.repair.headmodels.length > 0) {
            if (!this.natCode && vinCar.vinSelection.specification && vinCar.vinSelection.specification.vehicles.length > 0) {
                this.natCode = vinCar.vinSelection.specification.vehicles[0].typeETGCode;
            }
            if (vinCar.vinSelection.repair.headmodels.length === 1) {
                vinCar.vinSelection.repair.headmodels[0].vin = this._vin;

                vinCar.vinSelection.repair.headmodels[0].natCode = this.natCode;
                this.carService.setSelectedVinCar.emit(vinCar.vinSelection.repair.headmodels[0]);
                vinCarWrapper.typeId = 3;
            } else {
                vinCarWrapper.natCode = this.natCode;
                vinCarWrapper.vin = vinCar.vinSelection.vin;
                vinCarWrapper.vinCar = vinCar.vinSelection.repair.headmodels;
                vinCarWrapper.typeId = 1;
                vinCarWrapper.errorCode = vinCar.errorCode;
            }
        }

        return vinCarWrapper;
    }


    public onVinSearch() {
        this.vinLoading = true;
        this.carService.showPreSelection = false;
        this.carService.preSelectedVinCar = undefined;
        this.carService.loadCar = 'AUTOVISTA-VIN';
        this.autovistaRestService.getAutovistaVinRest(this.carService.replaceVin(this._vin), true, this.natCode, this.ikeCode).subscribe(
            (vinCar: IVinSearchResponse) => {
                if (vinCar.errorCode !== 0 && vinCar.errorMessage !== null && this.userService.allow('GLOBAL-ADMIN')) {
                    this.helperService.showNotification(vinCar.errorMessage, 'error', ('Error: ' + vinCar.errorCode), false);
                }
                if (vinCar.errorCode < 0 && !vinCar.vinSelection) {
                    this.carService.fromDMS = false;
                    for (const car of vinCar.carDtos) {
                        car.fromVIN = true;
                    }
                    this.vinLoading = false;
                    this.carService.loadCar = undefined;
                    this.applyCars.emit(vinCar.carDtos);
                    return;
                }

               /*
                Folgende Enums welches die Status Codes definieren vom BE:
                    NO_AUTOVISTA_MATCH(-1, "No Autovista matches"),
                    NO_TECRMI_MATCH(-2, "No TecRMI or Autovista matches"),
                    NO_AUTOVISTA_RIGHTS(-3, "No autovista rights"),
                    NO_CREDENTIALS(-4, "Autovista Credentials missing"),
                    NO_AUTOVISTA_CHARGEABLE(-5, "user is not allow to search chargeable");
                */

                switch (vinCar.errorCode) {
                    case -1:
                    case -2:
                        this.helperService.showNotification('CAR_SELECTION.NO_AUTOVISTA_MATCH', 'error');
                        break;
                    case -3:
                    case -5:
                        this.helperService.showNotification('CAR_SELECTION.NO_AUTOVISTA_RIGHTS', 'error');
                        break;
                    case -4:
                        this.helperService.showNotification('CAR_SELECTION.NO_AUTOVISTA_CREDENTIALS', 'error');
                        break;
                }

                this.vinLoading = false;
                this.carService.loadCar = undefined;
                if (vinCar === null) {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_VIN_RESPONSE', 'error');
                } else {
                    this.carService.loadCar = undefined;
                    this.carService.fromDMS = true;
                    switch (this.checkSelection(vinCar).typeId) {
                        case 0:
                            this.applyCars.emit(vinCar.carDtos ? vinCar.carDtos : []);
                            break;
                        case 1:
                            this.selectVinCar.emit(this.checkSelection(vinCar));
                            break;
                        case 2:
                            this.selectVinCar.emit(this.checkSelection(vinCar));
                            break;
                        case -1:
                            this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                            break;
                        default:
                    }
                }
            },
            (error) => {
                this.vinLoading = false;
                this.carService.loadCar = undefined;

                if (error.status === 409) {
                    this.carService.handleCarConflict();
                } else if (error.status === 400) {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_NOT_A_VIN', 'error');
                } else {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                }
            });
    }
}
