<div class="global-notifications">
    <div class="heading blue">
        <span class="clickable" style="cursor: pointer;" routerLink="/global-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <span class="clickable">
            <i class="fa fa-caret-right"></i>
            {{'GLOBAL_ADMIN.NOTIFICATIONS.TITLE' | translate}}
        </span>
    </div>
    <div class="global-notifications-content panel-content center-content white-background">
        <h1>{{'CLUB_ADMIN.CREATE_NOTIFICATION' | translate}}</h1>

        <div class="create-new-notification">
            <div class="row">
                <div class="col-2">
                    <app-dropdown [items]="notificationTypes"
                                  (onItemSelect)="selectedNotificationType = $event"
                                  [translationPrefix]="'NOTIFICATION.TYPE'"
                                  [placeholderText]="'USER_PROFILE.PLEASE_SELECT' | translate">
                    </app-dropdown>
                </div>
                <div class="col-2">
                    <app-dropdown [items]="notificationCriteria"
                                  (onItemSelect)="selectedCriteria = $event"
                                  [placeholderText]="'USER_PROFILE.PLEASE_SELECT' | translate">
                    </app-dropdown>
                </div>
                <div class="col-6">
                    <app-material-form-input [label]="'NOTIFICATION.LABEL' | translate"
                                             (textChange)="notificationDescription = $event">
                    </app-material-form-input>
                </div>
                <div class="col-2 text-center">
                    <app-checkbox [isChecked]="showDirect"
                                  (onChange)="showDirect = $event"
                                  [labelText]="'NOTIFICATION.SHOW_DIRECT' | translate">
                    </app-checkbox>
                </div>
            </div>

            <div class="row date-row">
                <div class="col-1 text-center notification-label">
                    <span>{{'NOTIFICATION.START_DATE' | translate}}</span>
                </div>
                <div class="col-5">
                    <form class="form-inline">
                        <div class="form-group">
                            <div class="input-group">
                                <input class="form-control" autofocus placeholder="dd.mm.yyyy" name="dp1" [ngModel]="dateFrom"
                                       (ngModelChange)="dateFrom = $event"
                                       ngbDatepicker #d1="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-1 text-center notification-label">
                    <span>{{'NOTIFICATION.END_DATE' | translate}}</span>
                </div>
                <div class="col-5">
                    <form class="form-inline">
                        <div class="form-group">
                            <div class="input-group">
                                <input class="form-control" autofocus placeholder="dd.mm.yyyy" name="dp2" [ngModel]="dateTo"
                                       (ngModelChange)="dateTo = $event"
                                       ngbDatepicker #d2="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="row club-restriction">
                <div class="col-5">
                    <div class="dataSupplier">
                        <app-material-form-input [(text)]="localClubInput"
                                                 (textChange)="localClubsList = reduceList(possibleClubsArray, $event)"
                                                 [placeholder]="'TYRE.SEARCHTERM' | translate"></app-material-form-input>
                        <i class="fa fa-times" (click)="localClubsList = possibleClubsArray; localClubInput = ''"
                           *ngIf="localClubInput?.length > 0"></i>
                        <div class="dataSupplierBox">
                            <ng-container *ngIf="!loadPossibleClubs">
                                <ng-container *ngFor="let club of localClubsList">
                                    <div class="dataSupplierItem" *ngIf="!isInRestrictionList(club)"
                                         (click)="selectedClub = club"
                                         [ngClass]="{'selected': selectedClub && selectedClub === club}">
                                        <span>{{club?.name}}</span>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <app-default-loading-page class="modal-spinner" *ngIf="loadPossibleClubs"></app-default-loading-page>
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <button class="base-btn arrowButton" (click)="removeRestriction()"><i class="fa fa-angle-left"></i>
                    </button>
                    <button class="base-btn arrowButton float-right" (click)="addRestriction()"><i
                            class="fa fa-angle-right"></i></button>
                </div>
                <div class="col-5">
                    <div class="restriction">
                        <app-material-form-input [(text)]="restrictionClubInput"
                                                 (textChange)="localRestrictionList = reduceList(selectedClubs, $event)"
                                                 [placeholder]="'TYRE.SEARCHTERM' | translate"></app-material-form-input>
                        <i class="fa fa-times" (click)="localRestrictionList = selectedClubs; restrictionClubInput = ''"
                           *ngIf="restrictionClubInput?.length > 0"></i>
                        <div class="restrictionBox">
                            <div class="restrictionItem" *ngFor="let restriction of localRestrictionList"
                                 (click)="selectedRestriction = restriction"
                                 [ngClass]="{'selected': selectedRestriction && selectedRestriction === restriction}">
                                <span>{{restriction.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="notificationDescription?.length > 0 && selectedNotificationType">
            <div class="col">
                <button class="base-btn success" (click)="createNewNotification()">
                    <i class="fa fa-check mr-2"></i>
                    <span>{{'NOTIFICATION.ADD_NOTIFICATION' | translate}}</span>
                </button>
            </div>
        </div>

        <h1 class="mt-4">{{'CLUB_ADMIN.ACTIVE_NOTIFICATIONS' | translate}}</h1>

        <div class="active-global-notifications">
            <ng-container *ngIf="notificationHolder?.length > 0">
                <div class="row notification-row {{notification.type}}" *ngFor="let notification of notificationHolder">
                    <div class="col-6 notification-message">
                        <span>{{notification.description}}</span>
                    </div>
                    <div class="col-2">
                        <span>{{notification.criterion}}</span>
                    </div>
                    <div class="col-3 notification-clubs">
                        <ng-container *ngIf="notification?.clubRestriction?.length > 0">
                            <span>Clubs: </span>
                            <ng-container *ngIf="!loadPossibleClubs">
                                <span class="clubs"
                                      *ngFor="let club of notification?.clubRestriction">{{possibleClubs.get(club)}}</span>
                            </ng-container>
                            <ng-container *ngIf="loadPossibleClubs">
                                <i class="fa fa-spinner fa-pulse"></i>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!(notification?.clubRestriction?.length > 0)">
                            <span>Alle Clubs</span>
                        </ng-container>
                    </div>
                    <div class="col-1 notification-option text-center clickable"
                         (click)="deleteNotification(notification)">
                        <i class="fa fa-trash"></i>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!(notificationHolder?.length > 0)">
                <div class="alert alert-info text-center">
                    <i class="fa fa-info-circle mr-2"></i>
                    <span>{{'NOTIFICATION.NO_NOTIFICATION' | translate}}</span>
                </div>
            </ng-container>
        </div>
    </div>
</div>
