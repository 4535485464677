<div class="global-orderhistory vinlog">
    <div class="heading blue">
        <span class="clickable" style="cursor: pointer;" routerLink="/global-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <span class="clickable">
            <i class="fa fa-caret-right"></i>
            {{'GLOBAL_ADMIN.VIN_LOG.TITLE' | translate}}
        </span>
    </div>
    <div class="historySearchBar">
        <div class="row">
            <div class="col">
                <h1>{{'GLOBAL_ADMIN.VIN_LOG.TITLE' | translate}}</h1>
                <div class="row">
                    <div class="col-4">
                        <app-material-form-input [label]="'HOME.VIN' | translate"
                                                 [text]="globalAdminService.vinLogRequest"
                                                 (textChange)="globalAdminService.vinLogRequest = $event"
                                                 (keydown.enter)="getVinLog()"></app-material-form-input>
                    </div>
                    <div class="col-8">
                        <button class="base-btn success float-right" (click)="globalAdminService.orderHistoryRequest.page = 0; getVinLog()">
                            <i class="fa fa-search" *ngIf="!globalAdminService.loadingVinLog"></i>
                            <i class="fa fa-spinner" *ngIf="globalAdminService.loadingVinLog"
                               [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"></i>
                            <span>{{'HOME.SEARCH' | translate}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="panel-content center-content white-background margin" [ngClass]="{'hasResuls': globalAdminService?.orderList?.orders?.length > 0, 'showPagination': !userService?.enableInfiniteScroll }" appInfiniteScroller
         (onScrollEnd)="globalAdminService.loadNext($event, false)">
        <div class="row">
            <div class="col">
                <app-default-loading-page *ngIf="globalAdminService.loadingVinLog"></app-default-loading-page>

                <ul class="item-list">
                    <app-global-vinlog-row></app-global-vinlog-row>
                </ul>
                <app-no-results *ngIf="!globalAdminService?.vinLogResult"></app-no-results>
            </div>
        </div>
    </div>
</div>
