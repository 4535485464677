<div class="global-order-data">
  <div class="heading blue" >
    <span class="clickable" style="cursor: pointer;" (click)="globalAdminService.reset()" routerLink="/global-admin">
        <i class="fa fa-th" aria-hidden="true"></i>
        {{'ADMIN_PANEL.OVERVIEW' | translate}}
    </span>
    <span class="clickable" (click)="globalAdminService.reset()" routerLink="/global-admin/vinlog">
      <i class="fa fa-caret-right"></i>
      Vinlog
    </span>
    <span class="clickable">
      <i class="fa fa-caret-right"></i>
      <span>Vin: {{globalAdminService?.vinLog?.value?.vinResponseDocument?.vin}}</span>
    </span>
  </div>
  <div class="panel-content center-content">
    <div class="table-container big-header">
      <div class="list">
        <div class="row">
          <div class="col-2">
            <strong>Datum:</strong>
          </div>
          <div class="col-4">
            <span>{{globalAdminService?.vinLog?.value?.executionDate  | date : 'dd.MM.yyy'}}</span>
          </div>
          <div class="col-2">
            <strong>Uhrzeit:</strong>
          </div>
          <div class="col-4">
            <span>{{globalAdminService?.vinLog?.value?.executionDate | date : 'hh:mm:ss'}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <strong>Club ID:</strong>
          </div>
          <div class="col-4">
            <span>{{globalAdminService?.vinLog?.key}}</span>
          </div>
          <div class="col-2">
            <strong>Code:</strong>
          </div>
          <div class="col-4">
            <span>{{globalAdminService?.vinLog?.value?.vinResponseDocument?.code}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <strong>Firma:</strong>
          </div>
          <div class="col-10">
            <span>{{globalAdminService?.vinLog?.value?.firmId}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <strong>Not empty:</strong>
          </div>
          <div class="col-10">
            <span>{{ globalAdminService?.vinLog?.value.vinResponseDocument.notEmpty }}</span>
          </div>
        </div>
        <div class="row" *ngIf="globalAdminService?.vinLog?.value.vinResponseDocument.description">
          <div class="col-2">
            <strong>Description:</strong>
          </div>
          <div class="col-10">
            <span>{{ globalAdminService?.vinLog?.value.vinResponseDocument.description }}</span>
          </div>
        </div>
      </div>
      <br><br>
      <h4>Stream Documents:</h4>
      <ul class="item-list vinlog">
        <li *ngFor="let streamDocument of globalAdminService?.vinLog?.value?.streamDocuments | keyvalue">
          {{ ((streamDocument.value).length>200)? (streamDocument.value | slice:0:200)+ ' (...)' :(streamDocument.value) }}
          <i class="fa fa-copy" style="float: right" (click)="copy(streamDocument.value)"></i>
        </li>
      </ul>

      <br><br>
      <h4>Vin Response Documents:</h4>
      <ul class="item-list vinlog">
        <li *ngIf="globalAdminService?.vinLog?.value?.vinResponseDocument?.repair">
         {{(globalAdminService?.vinLog?.value?.vinResponseDocument?.repair | json) | slice:0:200}}
          <i class="fa fa-copy" style="float: right" (click)="copy(globalAdminService?.vinLog?.value?.vinResponseDocument?.repair)"></i>
        </li>
        <li *ngIf="globalAdminService?.vinLog?.value?.vinResponseDocument?.specification">
          {{(globalAdminService?.vinLog?.value?.vinResponseDocument?.specification | json) | slice:0:200}}
          <i class="fa fa-copy" style="float: right" (click)="copy(globalAdminService?.vinLog?.value?.vinResponseDocument?.specification)"></i>
        </li>
      </ul>


      <button class="base-btn back-btn" routerLink="/global-admin/vinlog">Zurück</button>

    </div>
  </div>
</div>
