<div class="bodygraphics">
    <div class="assembly-categories"  *ngIf="qualcols && qualcols.length > 0 && !qualcol">
        <div *ngFor="let qualcol of qualcols" class="assembly-category"
             (click)="selectQualcol(qualcol)">

            <div class="name-wrapper-no-image">
                {{ qualcol.displayName }}
            </div>

        </div>

    </div>

    <div *ngIf="qualcol">
        <app-default-loading-page *ngIf="!qualColUrl"></app-default-loading-page>
        <ng-container *ngIf="qualColUrl">
            <iframe width="100%" height="100%" [src]="qualColUrl | iFrame" id="tecrmi-manual" style="min-height: 600px;"></iframe>
        </ng-container>
    </div>


</div>

