<div class="row cart-position-row">
    <!-- Checkbox & icon -->
    <div class="col-1 text-center">
        <div class="custom-checkbox" *ngIf="checkOrderableCheckbox(position)">
            <label>
                <input type="checkbox" (click)="cartService.updateCartDeliveryCosts()" [(ngModel)]="position.selected"
                       (change)="cartService.updateCartPosCount()" name="position-checkbox"
                       *ngIf="!cartService?.history"/>
                <span class="lbl"></span>
            </label>
        </div>
        <i *ngIf="checkDonePreordering(position)" class="fa fa-check"></i>
        <i *ngIf="checkOrderable(position)" class="fa fa-exclamation-triangle red"></i>
        <i *ngIf="position.type == 'LABOUR'" class="cart-table-icon fa fa-clock-o"></i>
        <i *ngIf="canEditCheck(position)" class="cart-table-icon fa fa-pencil clickable"
           (click)="setEditPosition(position)"></i>
    </div>
    <div class="col-10">
        <div class="row article-description">
            <!-- Description, IPC (article) LABOUR -->
            <div class="col-4" *ngIf="position.type == 'LABOUR'">
                {{ position.description }}<br/>
                <small>{{ position.ipc }}</small>
                <div *ngIf="hasRawTool">
                    <i class="fa fa-wrench fa-sm"
                       tooltip tooltipPosition="right" tooltipContent="{{'CART.RAWTOOL_INFO' | translate}}"></i>
                </div>
            </div>
            <!-- Description, IPC (article) PLACEHOLDER edit -->
            <div class="col-4 brand_ipc brand_ipc_edit" *ngIf="canEditCheck(position) && editPosition === position">
                <app-material-form-input [label]="'CART.DESCRIPTION' | translate" [text]="position.description"
                                         (textChange)="position.description = $event"></app-material-form-input>
                <app-material-form-input [label]="'CART.ARTICLE_NUMBER' | translate" [text]="position.ipc"
                                         (textChange)="position.ipc = $event"></app-material-form-input>
                <button class="base-btn success float-right"
                        (click)="saveChanges(position)">{{'PROMPTS.BUTTONS.SAVE' | translate}}</button>
                <button class="base-btn float-right button-top-margin"
                        (click)="cancelEdit()">{{'PROMPTS.BUTTONS.CANCEL' | translate}}</button>
            </div>

            <!-- Description, IPC (article) PLACEHOLDER -->
            <div class="col-4 brand_ipc" [ngClass]="{'clickable': canEditCheck(position)}"
                 *ngIf="position.type != 'LABOUR' && editPosition !== position" (click)="setEditPosition(position)">
                <div class="brand" *ngIf="position.brandName">{{ position.brandName }} {{ position.description }}</div>
                <div class="description" *ngIf="position.description && !position.brandName">{{position.description}}</div>

                <ng-container *ngIf="cartService.isPreview()">
                    <span *ngIf="position && position.additionalName">{{position.additionalName}}</span>
                </ng-container>

                <ng-container *ngIf="!cartService.isPreview() && position.type === 'SPAREPART'">
                <span *ngIf="!showEditAdditionalName">{{position.additionalName}}</span>
                <span class="additional-description" *ngIf="!showEditAdditionalName && !position.additionalName">{{'CART.DESCRIPTION' | translate}}</span>
                <i class="fa fa-pencil ml-2 cursor-pointer" triggers="mouseenter:mouseleave"
                   [ngbPopover]="'CART.CHANGE_ADDITIONAL_NAME' | translate"
                   container="body" *ngIf="!showEditAdditionalName"
                   (click)="editAdditionalName()"></i>
                </ng-container>
                <div class="ipc">{{ position.ipc }}</div>
            </div>

            <!-- Quantity -->
            <div class="col-2">
                <div class="quantity-wrapper">
                    <div *ngIf="cartService?.history || cartService.isOrder()">
                        <span> {{position.quantity}} {{ 'CART.' + position.quantityUnit | translate }}</span>
                    </div>
                    <app-cart-amount-input
                            *ngIf="position.quantityUnit === 'PIECE' && !cartService?.history && !cartService.isOrder() && !cartService.isPreview()"
                            [deactivate]="(position.type === 'LABOUR' && !canChangeAWInFleetWK && (cartService?.currentCart?.calculationData !== null))"
                            [initialAmount]="position.quantity"
                            (amountChanged)="onAmountChange($event)"></app-cart-amount-input>

                    <div *ngIf="position.quantityUnit !== 'PIECE' && !cartService?.history && !cartService.isOrder() && !cartService.isPreview()">
                        <app-cart-amount-input [initialAmount]="position.quantity"
                                               [deactivate]="(position.type === 'LABOUR' && !canChangeAWInFleetWK && (cartService?.currentCart?.calculationData !== null))"
                                               (amountChanged)="onAmountChange($event)"
                                               [floatValue]="true"></app-cart-amount-input>

                        <div class="amount-unit">&nbsp;{{ 'CART.' + position.quantityUnit | translate }}</div>
                    </div>
                </div>

            </div>

            <!-- Price per piece -->
            <div class="col-3 price-per-piece text-right">
                <div class="sellout-price"
                     *ngIf="userService.sale && (!(cartService?.cartCount?.calculationData) || position.allowCalculatedPriceChange) && !cartService.isOrder() && !cartService.isPreview()">
                    <app-cart-amount-input style="display: flex;width:75px;margin-left: auto;"
                                           [initialAmount]="position.sellOutNetPerPiece"
                                           [showCurrency]="true"
                                           (amountChanged)="onPriceChange($event)"
                                           (onMouseClick)="onPriceChange($event)"
                                           [floatValue]="true"></app-cart-amount-input>
                </div>
                <div class="sellout-price"
                     *ngIf="(userService.sale && ((cartService?.cartCount?.calculationData && !position.allowCalculatedPriceChange) || cartService.isOrder() || cartService.isPreview()))">
                    <b>{{position.sellOutNetPerPieceFormatted}}</b>
                </div>
                <div class="sellin-price font-weight-bold" *ngIf="!userService.sale">
                    <ng-container *ngIf="position.sellInNetPerPiece > 0">{{ position.sellInNetPerPieceFormatted}}<br/>
                    </ng-container>
                    <ng-container *ngIf="position.sellInNetPerPiece === 0">-<br/></ng-container>
                    <small> {{ position.sellOutNetPerPieceFormatted }} </small>
                </div>
            </div>

            <!-- Total price -->
            <div class="col-3 total-price priceText text-right font-weight-bold">
                <div class="total-sellout-price" *ngIf="userService.sale">
                    <span>{{position.sellOutNetTotalFormatted}}</span>
                </div>
                <div class="total-sellin-price" *ngIf="!userService.sale">
                    <span *ngIf="position.sellInNetTotal > 0">{{ position.sellInNetTotalFormatted}}<br/></span>
                    <span *ngIf="position.sellInNetTotal === 0">-<br/></span>
                    <small>{{position.sellOutNetTotalFormatted}}</small>
                </div>
            </div>

        </div>
        <div class="row article-dealer" *ngIf="position.type !== 'LABOUR' && !canEditCheck(position)">
            <!-- Dealer -->
            <div class="col dealer">
                <div class="dealer-logo toolTipDiv clickable" *ngIf="position.supplier"
                     (click)="cartService.selectArticle(position)" triggers="mouseenter:mouseleave"
                     [ngbPopover]="'CART.CHANGE_DEALER' | translate" container="body">
                    <div class="dealer-image mr-2">
                        <img [supplier]="position.supplier" [logo-color]="true" title="{{position.supplierName}}"/>
                    </div>
                    <div class="edit-icon">
                        <i class="fa fa-pencil"
                           *ngIf="(position.type === 'SPAREPART' || position.type === 'OE_ARTICLE') && !cartService.isOrder() && !cartService.isPreview()"></i>
                    </div>
                </div>
                <div class="add-supplier clickable" *ngIf="!position.supplier && (position.type === 'SPAREPART'
                || position.type === 'OE_ARTICLE') && !cartService.isOrder() && !cartService.isPreview()"
                     (click)="cartService.selectArticle(position)">
                    <i class="fa fa-plus mr-2"></i>
                    <span>{{'CART.ADD_SUPPLIER' | translate}}</span>
                </div>
                <span class="ml-3 cursor-default"
                      *ngIf="position.dealerArticleNumber && position.supplier">{{position.dealerArticleNumber}}</span>
                <!-- Availability -->
                <div class="ml-3 availability-panel cursor-default">
                    <div *ngFor="let avail of position.availabilityDetails" [ngClass]="avail.availability"
                         style="line-height: 30px;">
                        <div>
                            <span class="icon mr-2"></span>
                            <span>{{getAvailabilityData(avail)}}</span>
                        </div>
                    </div>
                </div>
                <span class="already-ordered-count" *ngIf="position.preOrderInfos.length > 0">
                    <b>{{'CART.ORDERED' | translate}}:</b> {{position.preOrderedQuantity}}
                    / {{position.quantity}} {{'CART.PIECE' | translate}}
                </span>
            </div>
        </div>
        <div class="row preorder-wrapper"
             *ngIf="position.preOrderInfos.length > 0 && (position.type === 'SPAREPART' || position.type === 'OE_ARTICLE')">
            <div class="col dealer">
                <div class="position-wrapper" *ngIf="position.showMore">
                    <span class="already-ordered">{{'CART.ALREADY_ORDERED' | translate}}</span>
                    <div class="preorder-position row" *ngFor="let preorderPosition of position.preOrderInfos">
                        <div class="col">
                            <div class="dealer-logo" *ngIf="preorderPosition.supplier">
                                <div class="dealer-image mr-2">
                                    <img [supplier]="preorderPosition.supplier" [logo-color]="true" title="{{preorderPosition.supplier}}"/>
                                </div>
                            </div>
                            <span class="ml-3 cursor-default"
                                  *ngIf="position.dealerArticleNumber && position.supplier">{{position.dealerArticleNumber}}</span>
                            <span class="ml-3 cursor-default"
                                  *ngIf="preorderPosition.orderNo && preorderPosition.supplier"><b>{{'CART.ORDER_NUMBER' | translate}}
                                :</b> {{preorderPosition.orderNo}}</span>
                            <span class="ml-3 custom-default"><b>{{'CART.AMOUNT' | translate}}
                                :</b> {{preorderPosition.quantity}} {{'CART.PIECE' | translate}}</span>
                        </div>
                    </div>
                </div>

                <div class="show-more-button" (click)="position.showMore = !position.showMore">
                    <span *ngIf="!position.showMore">{{'CART.SHOW_ORDERED_ARTICLES' | translate}}</span>
                    <span *ngIf="position.showMore">{{'CART.HIDE_ORDERED_ARTICLES' | translate}}</span>
                </div>
            </div>
        </div>
    </div>
    <!-- Delete -->
    <div class="col-1 options print-hide text-center">
        <i class="cart-table-icon fa fa-trash-o" (click)="deletePosition(position.posId)"
           *ngIf="!cartService?.history && !cartService.isOrder() && !cartService.isPreview()">
        </i>
    </div>
</div>


<div class="close-cart-overlay fix-background" *ngIf="showEditAdditionalName"
     (click)="showEditAdditionalName = false">
    <div id="overlay"></div>
    <div class="center-wrapper" (click)="$event.stopPropagation()">
        <div class="header">
            <i class="fa fa-shopping-cart"></i>
            <span class="title">{{'CART.CHANGE_ADDITIONAL_NAME' | translate}}</span>
            <i class="fa fa-times" (click)="showEditAdditionalName = false"></i>
        </div>
        <div class="content text-center p-5">
            <div class="w-50">
            <app-material-form-input (textChange)="changeAdditionalName($event)"
                                     [placeholder]="'CART.CHANGE_ADDITIONAL_NAME_PLACEHOLDER' | translate"
                                     [text]="position.additionalName"></app-material-form-input>
            </div>
            <div class="button-row text-left">
                <button class="base-btn success continue mr-3"
                       (click)="saveAdditionalName()">{{'GENERAL.UPDATE' | translate}}</button>
                <button class="base-btn abort"
                        (click)="showEditAdditionalName = false">{{'GENERAL.ABORT' | translate}}</button>
            </div>
        </div>
    </div>
</div>
