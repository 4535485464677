import { Component, OnInit } from '@angular/core';
import {UserService} from "../../../../shared/services/user/user.service";
import {GlobalAdminService} from "../../services/global-admin.service";
import {HelperService} from '../../../../shared/services/helper.service';

@Component({
  selector: 'app-global-communications-list-row',
  templateUrl: './global-communications-list-row.component.html',
  styleUrls: ['./global-communications-list-row.component.scss']
})
export class GlobalCommunicationsListRowComponent implements OnInit {

  constructor(public userService: UserService,
              public globalAdminService: GlobalAdminService,
              public helperService: HelperService) { }

  ngOnInit() {
  }

  public onEnd() {
      if (this.globalAdminService.communicationLog && this.globalAdminService.communicationLog.totalElements > 0) {
          return this.userService.enableInfiniteScroll && (this.globalAdminService.communicationLog.totalElements / 20)
              <= (this.globalAdminService.communicationRequest.page + 1) && !this.globalAdminService.infinityLoading;
      } else {
          return false;
      }
  }
}
