<div class="row">
  <div class="col-3">
    <div class="left-label">
    </div>
  </div>
  <div class="col-9">
    <div class="row form-select-item">
      <div class="col-9">
        <app-material-form-input
                [label]="'HOME.VIN' | translate"
                [disabled]="false"
                [type]="text"
                [(text)]="_vin"
                [focus]="carService.selected === 'VIN'"
                (selected)="carService.selected = 'VIN'"
                (keydown.enter)="onVinSearch()">
        </app-material-form-input>
      </div>
      <div class="col-3">
        <button #vin class="btn-flat" [disabled]="!(carService.selected === 'VIN')"
                (click)="onVinSearch()">
          <i class="fa fa-search" *ngIf="!(carService?.loadCar === 'VIN')"></i>
          <i class="fa fa-spinner" *ngIf="carService?.loadCar === 'VIN'"
             [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"></i>
        </button>
      </div>
    </div>
  </div>
</div>
