<div class="edit-supplier">
  <div class="row">
    <div class="col" *ngIf="supplier.supplier">
      <h1>{{supplier.supplierName}}</h1>
    </div>
    <div class="col" *ngIf="!supplier.supplier">
      <label class="search-label">{{'SUPPLIERS.SUPPLIER' | translate}}</label>
      <app-dropdown placeholderText="{{'USER_PROFILE.PLEASE_SELECT' | translate}}" [items]="unassignedSupplier"
                    (onItemSelect)="selected = $event"></app-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-material-form-input [label]="'SUPPLIERS.CUSTOMERNR' | translate"
                               [(text)]="supplier.customerNo"></app-material-form-input>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-material-form-input [label]="'SUPPLIERS.USERNAME' | translate"
                               [(text)]="supplier.username"></app-material-form-input>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-material-form-input [label]="'SUPPLIERS.PASSWORD' | translate"
                               [(text)]="supplier.password"></app-material-form-input>
    </div>
  </div>
  <div class="row button">
    <div class="col">
      <button class="base-btn pull-right button-top-margin" (click)="back()">{{'PROMPTS.BUTTONS.GO_BACK' | translate}}</button>
      <button class="base-btn success" (click)="save(supplier)">{{'PROMPTS.BUTTONS.SAVE_CHANGES' | translate}}</button>
    </div>
  </div>
  <app-error-handler *ngIf="errorHandlerService.newError"></app-error-handler>
</div>

