import { Component, OnInit } from '@angular/core';
import {MaintenanceService} from "../../services/maintenance.service";
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {CartService} from '../../../cart/services/cart.service';

@Component({
  selector: 'app-maintenance-manufacturer-info',
  templateUrl: './maintenance-manufacturer-info.component.html',
  styleUrls: ['./maintenance-manufacturer-info.component.scss']
})
export class MaintenanceManufacturerInfoComponent implements OnInit {

  constructor(public maintenanceService: MaintenanceService,
              public location: Location,
              private router: Router,
              private cartService: CartService) { }

  ngOnInit() {
      if (this.location.path().indexOf('/maintenance') > -1) {
          this.maintenanceService.lastState = '/maintenance/info/manufacturer';
      } else if (this.location.path().indexOf('/cart') > -1 && !this.cartService.showDocument) {
          this.router.navigate(['/cart']);
      }
  }
    public printIframe(frameId: string) {
        const frame = window.frames[frameId];
        if (!frame) {
            return;
        }
        const frames: any = document.getElementById(frameId);
        frames.contentWindow.focus();
        frames.contentWindow.print();
    }
}
