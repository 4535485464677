import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ITransferLog} from '../../../interfaces/order';

@Component({
    selector: 'app-transferlog',
    templateUrl: './transferlog.component.html'
})
export class TransferlogComponent implements OnInit {

    @Input() transferLog: ITransferLog[];
    @Output() public closeTransferLog: EventEmitter<any> = new EventEmitter<any>();

    public title: string;

    constructor() {
    }

    ngOnInit() {
        this.title = this.transferLog[0].supplierName;
    }

    public onEvent(event: any) {
        event.stopPropagation();
    }

}
