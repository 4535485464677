import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ErrorHandlerService} from '../../../shared/components/error-handler/services/error-handler.service';
import {ISupplier, IUnassignedSuppliers} from '../../../shared/interfaces/supplier';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-firm-supplier',
  templateUrl: './firm-supplier.component.html'
})
export class FirmSupplierComponent implements OnInit {

    public supplierId = 0;
    public supplier: ISupplier;
  public selected: IUnassignedSuppliers;
  @Input() _supplier: ISupplier;
  @Input() unassignedSupplier: any;
  @Input() firmId: number;
  @Output() public _back: EventEmitter<any> = new EventEmitter();
  @Output() public _save: EventEmitter<ISupplier> = new EventEmitter();

  constructor(public errorHandlerService: ErrorHandlerService,
              public route: ActivatedRoute) {


  }

  ngOnInit() {
      if (this._supplier) {
          this.supplier = this._supplier;
      } else {
          this.supplier = {customerNo: '',supplierName: '', supplier: '', password: '', username: '', firmId: 0, id: '', sortNo: 0, active: true};
      }
      this.supplier.firmId = this.firmId;
  }

    public save(supplier: ISupplier) {
      if (this.selected) {
          supplier.supplier = this.selected.code;
      }
      this._save.emit(supplier);
  }

    public back() {
      this._back.emit();
  }



}
