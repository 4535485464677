import {AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {AutovistaService} from '../../services/autovista.service';
import {Content, IAutovistaDetails} from '../../../articles/interfaces/autovista';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../shared/services/user/user.service';
import {ArticlesService} from '../../../articles/services/articles.service';
import {SearchHistoryService} from '../../../shared/services/search-history.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs/Rx';
import {CartService} from '../../../cart/services/cart.service';
import {CartRestService} from '../../../cart/services/cart.rest.service';
import {WorkService} from '../../../work-data/services/work.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HelperService} from '../../../shared/services/helper.service';
import {itemSelected, submit, visible} from '../../../shared/animations/general.animation';
import {AutovistaRestService} from '../../services/autovista.rest.service';
import {CarService} from '../../../shared/services/car/car.service';
import {NativeEventSource, EventSourcePolyfill} from 'event-source-polyfill';
import {IAddOeArticlePositionRequest} from '../../../shared/interfaces/DTO/cart/addOeArticlePositionRequest';
import {ArticlesRestService} from '../../../articles/services/articles.rest.service';

const EventSource = NativeEventSource || EventSourcePolyfill;

@Component({
    selector: 'app-autovista-details',
    templateUrl: './autovista-details.component.html',
    styleUrls: ['./autovista-details.component.scss'],
    animations: [itemSelected, visible, submit],
    host: {
        '(document:click)': 'onOutsideClick($event)',
    }
})

export class AutovistaDetailsComponent implements OnInit, AfterViewInit {

    @ViewChild('autovistaTabSet', {static: false}) ngbTabSet;

    public selectedArticle: IAutovistaDetails = undefined;
    public selectedSide: Content;
    public selectedGroup: any;
    public selectedPart: any;
    private eventStream: Subscription;
    public requestedQuantity: number = 1;
    public _toCartPosition: any;
    public toCartLoading: number = 0;
    public _selectedSupplier: any;
    public sellOutGrossPerPiece: any;
    public loadAvailability: boolean = false;
    public loadAddToCart: boolean = false;
    private supplierList: any;

    constructor(public autovistaService: AutovistaService,
                private translateService: TranslateService,
                public userService: UserService,
                private articlesService: ArticlesService,
                private searchHistoryService: SearchHistoryService,
                private router: Router,
                private _eref: ElementRef,
                public cartRestService: CartRestService,
                public cartService: CartService,
                private workService: WorkService,
                private zone: NgZone,
                public helperService: HelperService,
                private autovistaRestService: AutovistaRestService,
                private carService: CarService,
                private articlesRestService: ArticlesRestService) {

        this.autovistaService.selectedArticleChange.subscribe((selectedArticle: IAutovistaDetails) => {
            this.selectedArticle = selectedArticle;
            this.selectedSide = this.selectedArticle.content[0];
            this.setSelectedGroups();
            if (this.ngbTabSet) {
                this.ngbTabSet.select(this.ngbTabSet.tabs.first.id);
            }
        });

    }

    ngOnInit() {
        this.selectedArticle = (this.autovistaService.selectedArticle ? this.autovistaService.selectedArticle : undefined);
        if (this.selectedArticle) {
            this.selectedSide = this.selectedArticle.content[0];
        }
        this.autovistaService.oeAvailability = [];
        this.setSelectedGroups();
        if (this.ngbTabSet) {
            this.ngbTabSet.select(this.ngbTabSet.tabs.first.id);
        }

        this.articlesRestService.getOeSuppliers().subscribe(
            (suppliers) => {
                this.supplierList = suppliers;
            });
    }

    ngAfterViewInit() {
        this.ngbTabSet.select(this.ngbTabSet.tabs.first.id);
    }

    public onOutsideClick(event: any) {
        if (!this._eref.nativeElement.contains(event.target) && this._toCartPosition) {
            this._toCartPosition.supplier = undefined;
        }
    }

    public selectPart(part: any) {
        if (!this.loadAvailability) {
            this.selectedPart = part;
            this.startAvailabailityFlux();
        }
    }

    public setRequestedQuantity(quantity: any) {
        if (quantity) {
            this.requestedQuantity = Number.parseInt(quantity);
        }
    }

    public getTotalPrice() {
        let price = undefined;
        if (this.autovistaService.oeAvailability && this.autovistaService.oeAvailability.length > 0) {
            this.autovistaService.oeAvailability.forEach((supplier) => {
                if (supplier.supplier === this._selectedSupplier) {
                    price = supplier.sellInNetTotalFormatted;
                }
            })
        }
        return price;
    }

    public setDetail(group: any) {
        this.selectedGroup = group;
        this.selectedPart = this.selectedGroup.parts[0];
        this.ngbTabSet.select(this.ngbTabSet.tabs.last.id);
        if (this.autovistaService.oeSuppliers && this.autovistaService.oeSuppliers.length > 0) {
            this.startAvailabailityFlux();
        }
    }

    public changeItem(item: any, supplier: any) {
        if (item.supplier !== supplier) {
            this.selectSupplier(item);
            this.toCartLoading = 0;
        }
    }

    private selectSupplier (item: any) {
        this.sellOutGrossPerPiece = item.sellOutGrossPerPiece;
        const quantity = (this.requestedQuantity > 0 ? this.requestedQuantity : 1);
        const selectedArticle: any = this.selectedPart;
        this._toCartPosition = {
                articleId: selectedArticle.id,
                brandName: selectedArticle.manufacturer,
                description: this.selectedGroup.name,
                quantity: quantity,
                uvp: selectedArticle.netPrice,
                genericArticleIds: this.selectedGroup.genArtIds,
                quantityUnit: 'PIECE',
                adcTypeId: this.carService.getAdcTypeId(),
                kTypeId: this.carService.getKTypeId(),
                supplier: item.supplier,
                additionalName: item.additionalName
            };

        selectedArticle.optionNames.forEach((name) => {
           this._toCartPosition.description += ' ' + name;
        });

        this._selectedSupplier = item.supplier;
    }

    public addToCart(_toCartPosition: IAddOeArticlePositionRequest, $event: any) {
        $event.preventDefault();
        $event.stopPropagation();

        _toCartPosition.quantity = this.requestedQuantity;
        _toCartPosition.itemMpId = this.selectedPart.itemMpId;
        _toCartPosition.vin = this.carService.getVin();
        _toCartPosition.producerName = this.carService.getCarProducerName();

        this.toCartLoading = 1;
        this.cartRestService.addOeArticleRest(_toCartPosition).subscribe(
            () => {
                this.cartService.updateCartCount();
                this.toCartLoading = 0;
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_ADD_TO_CART', 'success');
                }, (error) => {
                this.cartService.updateCartCount();
                this.toCartLoading = 2;
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_ADD_TO_CART', 'error');
            });
    }

    public addArticleWithoutSupplier() {
        this.loadAddToCart = true;
        const _toCartPosition: IAddOeArticlePositionRequest = {
            articleId: this.selectedPart.id,
            brandName: this.selectedPart.manufacturer,
            description: this.selectedGroup.name,
            quantity: (this.requestedQuantity > 0 ? this.requestedQuantity : 1),
            uvp: this.selectedPart.netPrice,
            genericArticleIds: this.selectedGroup.genArtIds,
            quantityUnit: 'PIECE',
            adcTypeId: this.carService.getAdcTypeId(),
            kTypeId: this.carService.getKTypeId(),
            itemMpId: this.selectedPart.itemMpId,
            vin: this.carService.getVin(),
            producerName: this.carService.getCarProducerName(),
            valid: true,
            supplier: null,
            additionalName: this.selectedPart.additionalName
        }
        this.cartRestService.addOeArticleRest(_toCartPosition).subscribe(
            (success) => {
                this.loadAddToCart = false;
                this.cartService.updateCartCount();
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_ADD_TO_CART', 'success');
            },
            (error) => {
                this.loadAddToCart = false;
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_ADD_TO_CART', 'error');
            });
    }

    // ##FLUX##
    private startAvailabailityFlux() {
        this.autovistaService.oeAvailability = this.helperService.recursiveDeepCopy(this.autovistaService.oeSuppliers);
        const observable = new Observable(observer => {
            const eventSource = new EventSource(environment.apiUrl +
                '/article/oeavailability?articleId=' + encodeURIComponent(this.selectedPart.id)
                + '&requestedQuantity=' + this.requestedQuantity + '&uvp=' + this.selectedPart.netPrice);
            this.loadAvailability = true;
            eventSource.onmessage = x => {
                observer.next(x.data);
            };
            eventSource.onerror = x => {
                eventSource.close();
                this.loadAvailability = false;
            };

            return () => {
                eventSource.close();
                this.loadAvailability = false;
            };
        });

        this.eventStream = observable.subscribe({
            next: guid => {
                this.zone.run(() => {
                    const temp = JSON.parse(guid.toString());
                    if (this.autovistaService.oeAvailability[this.autovistaService.oeSuppliers.findIndex(e => e.supplier === temp.supplier)] &&
                        this.autovistaService.oeAvailability[this.autovistaService.oeSuppliers.findIndex(e => e.supplier === temp.supplier)] !== temp) {
                        this.autovistaService.oeAvailability[this.autovistaService.oeSuppliers.findIndex(e => e.supplier === temp.supplier)] = temp;
                    }
                });
            },
            error: err => console.error(err)
        });
    }

    public setSelectedGroups() {
        if (this.selectedSide) {
            this.autovistaService.selectedGroups = undefined;
            if (this.hasAdditionalGroups()) {
                const convertedGroups = [];
                Object.keys(this.selectedSide.additionalGroups)
                    .filter(key => this.autovistaService.hasCriteriaFilter(key) || this.selectedSide.additionalGroups[key].matchesCriterias)
                    .forEach(key => {
                        convertedGroups.push(this.selectedSide.additionalGroups[key]);
                    });
                if (convertedGroups.length > 0) {
                    this.autovistaService.selectedGroups = convertedGroups;
                    return;
                } else if (this.selectedSide.standardGroup.length > 0) {
                    this.autovistaService.selectedGroups = this.selectedSide.standardGroup;
                    return;
                } else {
                    this.autovistaService.selectedGroups = [this.selectedSide.additionalGroups[Object.keys(this.selectedSide.additionalGroups)[0]]];
                    return;
                }
            } else if (this.selectedSide.standardGroup.length > 0) {
                this.autovistaService.selectedGroups = this.selectedSide.standardGroup;
            } else {
                this.autovistaService.selectedGroups = [this.selectedSide.additionalGroups[Object.keys(this.selectedSide.additionalGroups)[0]]];
            }
        } else {
            this.autovistaService.selectedGroups = undefined;
        }
    }

    public getSide(side: number) {
        return this.translateService.instant('AUTOVISTA.SIDE.' + side.toString());
    }

    public hasAdditionalGroups() {
        if (this.selectedSide) {
            return Object.keys(this.selectedSide.additionalGroups).length > 0
        } else {
            return false;
        }
    }

    public oeSearch(oeNumber: string, genArtNos: number[]) {
        this.searchHistoryService.addItem(oeNumber);
        const oeNumbers = [];
        oeNumbers.push(oeNumber);
        this.articlesService.supplierType = 'AFTER_MARKET'
        this.articlesService.directSearchValue = '';
        this.articlesService.updateArticlesByOeSearchString(oeNumbers, genArtNos);
        this.articlesService.selectedArticle = undefined;
        this.router.navigate(['/articles']);
    }

    public toggleWork(work: any) {

        work = this.workService.createLabourWorkPosition(work);

        if (!(work.posIdInCart > 0)) {
            this.cartRestService.addWorkToCart(work).subscribe(
                (success) => {
                    work.posIdInCart = success;
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_ADD_TO_CART', 'success');
                    this.cartService.updateCartCount(true);
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_ADD_TO_CART', 'error');
                });
        } else {
            this.cartRestService.deletePos(work.posIdInCart).subscribe(
                (success) => {
                    work.posIdInCart = null;
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_CART_POS', 'success');
                    this.cartService.updateCartCount(true);
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_CART_POS', 'error');
                });
        }
    }

    public findSupplierName(code: string) {
        const supp = this.supplierList.find(x => x.supplier === code)
        if (supp) {
            return supp.name;
        } else {
            return ''
        }
    }
}
