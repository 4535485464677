  <div class="card warning-recall-text" [ngClass]="{'active': carService.newRecallInformation}" (click)="carService.showCarInfo = !carService.showCarInfo; carService.newRecallInformation = false;  $event.stopPropagation()">
    <div class="recall-text warning" *ngIf="carService.selectedCar && carService.selectedCar.serviceInfoState === 'CURRENT'" >
      <i class="fa fa-warning"></i> {{ 'SPAREPARTS_VIEW.CAR_DETAILS.WARNING_RECALL' | translate }}
    </div>
    <div class="recall-text expired" *ngIf="carService.selectedCar && carService.selectedCar.serviceInfoState === 'EXPIRED'">
      <i class="fa fa-warning"></i> {{ 'SPAREPARTS_VIEW.CAR_DETAILS.EXPIRED_RECALL' | translate }}
    </div>
    <div class="clearfix"></div>
      <hr/>
    <div class="row">
      <div class="col-10">
          <strong>{{'GENERAL.SHOW_INFORMATION' | translate}}</strong>
      </div>
      <div class="col-2 text-right">
          <strong><i class="fa fa-angle-right"></i></strong>
      </div>
    </div>

  </div>

