import {Component, Input, OnInit} from '@angular/core';
import {ArticlesService} from '../../../articles/services/articles.service';
import {CartService} from '../../services/cart.service';
import {UserService} from '../../../shared/services/user/user.service';
import {HelperService} from '../../../shared/services/helper.service';
import {IPosition} from '../../../shared/interfaces/DTO/cart/position';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from "../../../shared/services/config.service";

@Component({
    selector: 'app-cart-row',
    templateUrl: 'cart-row.component.html',
    styleUrls: ['cart-row.component.scss']
})
export class CartRowComponent implements OnInit {
    @Input() public position: IPosition;
    @Input() public rawToolsPositions: number[];
    public hasRawTool: boolean = false;
    public editPosition: any;
    public showEditAdditionalName: boolean = false;
    public canChangeAWInFleetWK: boolean = false;

    constructor(
        public articlesService: ArticlesService,
        public cartService: CartService,
        public userService: UserService,
        private translate: TranslateService,
        private configService: ConfigService,
        public helperService: HelperService) {
    }

    ngOnInit() {
        this.hasRawTool = this.rawToolsPositions.some((pos:number) => pos === this.position.posId);
        this.canChangeAWInFleetWK = this.configService.getActiveForName('EXTENDED_SETTINGS', 'CHANGE_AW_FOR_FLEET');
    }

    public onAmountChange(amount: number) {
        if (this.position.type === 'SPAREPART' && amount < 1) {
            this.deletePosition(this.position.posId);
        } else {
            this.cartService.changePos(this.position.acquisitionType, this.position.posId, amount, undefined, undefined, undefined, this.position.supplier, undefined, undefined, this.position.additionalName, this.position.quantityUnit);
        }
    }

    public editAdditionalName() {
        this.showEditAdditionalName = true;
    }

    public changeAdditionalName(e: any) {
        this.position.additionalName = e;
    }

    public saveAdditionalName() {
        this.cartService.changePos(this.position.acquisitionType, this.position.posId, this.position.quantity,
            undefined, undefined, undefined, this.position.supplier, undefined, undefined, this.position.additionalName, this.position.quantityUnit);
        this.showEditAdditionalName = false;
    }

    public getAvailabilityData(avail: any) {
        let availString = '';
        if (avail.availability !== 'SPECIAL_ORDER') {
            if (avail.quantity > 0 && avail.quantity !== this.position.quantity) {
                availString += avail.quantity + ' ';
            }
            if (avail.depotName) {
                availString += avail.depotName + ' ';
            }
            if (avail.deliveryDate) {
                availString += avail.deliveryDate + ' ';
            }
            if (avail.deliveryTime) {
                availString += avail.deliveryTime + ' ';
            }
        }
        if (avail.availability === 'SPECIAL_ORDER') {
            this.translate.get('SUPPLIERS.SPECIAL_ORDER_INFO').subscribe((msg) => {
                availString += msg + ' ';
            });
        }
        if (!avail.depotName && avail.availability !== 'SPECIAL_ORDER') {
            this.translate.get('SUPPLIERS.' + avail.availability).subscribe((msg) => {
                availString += msg + ' ';
            });
        }

        return availString;
    }

    public onPriceChange(newPrice: number) {
        if (newPrice >= 0 && newPrice !== this.position.sellOutNetPerPiece) {
            this.position.sellOutNetPerPiece = newPrice;
            this.cartService.changePos(this.position.acquisitionType, this.position.posId, this.position.quantity, newPrice, undefined, undefined, this.position.supplier, undefined, undefined, undefined, this.position.quantityUnit);
        }
    }

    public deletePosition(positionId: number) {
        this.cartService.deletePos(positionId);
    }

    public setEditPosition(position: any) {
        if (this.canEditCheck(position)) {
            this.editPosition = position;
        }
    }

    public canEditCheck(position: IPosition) {
        return (position.type === 'PLACEHOLDER'
            || position.type === 'FREE_ARTICLE'
            || position.type === 'FREE_AW'
            || position.type === 'FREE_TEXT'
        ) && !this.cartService.isOrder() && !this.cartService.isPreview();
    }

    public checkOrderable(position: IPosition) {
        if (this.userService.getCatalogMode()) {
            return !position.orderable && position.supplier && position.preOrderedQuantity < position.quantity;
        }
        return !position.orderable && position.supplier;
    }

    public checkOrderableCheckbox(position: IPosition) {
        return position.orderable
            && !this.cartService.isOrder()
            && !this.cartService.isPreview()
            && !this.userService.sale;
    }

    public checkDonePreordering(position: IPosition) {
        return !position.orderable && position.supplier && position.preOrderedQuantity >= position.quantity && this.userService.getCatalogMode();
    }

    public cancelEdit() {
        this.editPosition = undefined;
        this.cartService.updateCart(false, true, false, false);
    }

    public saveChanges(position: any) {
        this.editPosition = undefined;
        this.cartService.changePos(this.position.acquisitionType, this.position.posId, this.position.quantity, this.position.sellOutNetPerPiece, this.position.description, this.position.ipc, undefined, undefined, undefined, undefined, this.position.quantityUnit)
    }
}
