import { Component, Output, EventEmitter, ContentChildren, QueryList, AfterContentInit, Input } from '@angular/core';
import {WizardStepComponent} from './wizard-step/wizard-step.component';
import {CarService} from '../../services/car/car.service';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements AfterContentInit {
    @ContentChildren(WizardStepComponent)
    wizardSteps: QueryList<WizardStepComponent>;
    @Input()
    txtBtnPrevious = 'Previous';

    @Input()
    txtBtnNext = 'Next';

    @Input()
    txtBtnDone = 'Done';

    private _steps: Array<WizardStepComponent> = [];
    private _isCompleted = false;

    @Output()
    onStepChanged: EventEmitter<WizardStepComponent> = new EventEmitter<WizardStepComponent>();

    constructor(private carService: CarService) {}

    ngAfterContentInit() {
        this.wizardSteps.forEach(step => this._steps.push(step));
        this.steps[0].isActive = true;
        this.steps[0].doNext.subscribe(
            (success) => {
                this.next();
            }
        );
        this.carService.selectedCarChanged$.subscribe(state => {
            this.goToStep(this.steps[0]);
        });
    }

    get steps(): Array<WizardStepComponent> {
        return this._steps.filter(step => !step.hidden);
    }

    get isCompleted(): boolean {
        return this._isCompleted;
    }

    get activeStep(): WizardStepComponent {
        return this.steps.find(step => step.isActive);
    }

    set activeStep(step: WizardStepComponent) {
        if (step !== this.activeStep && !step.isDisabled) {
            this.activeStep.isActive = false;
            step.isActive = true;
            this.onStepChanged.emit(step);
        }
    }

    public get activeStepIndex(): number {
        return this.steps.indexOf(this.activeStep);
    }

    get hasNextStep(): boolean {
        return this.activeStepIndex < this.steps.length - 1;
    }

    get hasPrevStep(): boolean {
        return this.activeStepIndex > 0;
    }

    public goToStep(step: WizardStepComponent): void {
        if (!this.isCompleted) {
            this.activeStep = step;
        }
    }

    public next(): void {
        if (this.hasNextStep) {
            // if (this.steps[this.activeStepIndex].doNext) {
            //     this.steps[this.activeStepIndex].doNext.unsubscribe();
            // }
            let nextStep: WizardStepComponent = this.steps[this.activeStepIndex + 1];
            this.activeStep.onNext.emit();
            nextStep.isDisabled = false;
            this.activeStep = nextStep;
            // if (this.steps[this.activeStepIndex + 1].doNext) {
            //     this.steps[this.activeStepIndex + 1].doNext.subscribe(
            //         (success) => {
            //             this.next();
            //         }
            //     );
            // }
        }
    }

    public previous(): void {
        if (this.hasPrevStep) {
            let prevStep: WizardStepComponent = this.steps[this.activeStepIndex - 1];
            this.activeStep.onPrev.emit();
            prevStep.isDisabled = false;
            // if (this.steps[this.activeStepIndex].doNext) {
            //     this.steps[this.activeStepIndex].doNext.unsubscribe();
            // }
            this.activeStep = prevStep;
            // if (this.steps[this.activeStepIndex - 1].doNext) {
            //     this.steps[this.activeStepIndex - 1].doNext.subscribe(
            //         (success) => {
            //             this.next();
            //         }
            //     );
            // }
        }
    }

    public complete(): void {
        // if (this.activeStep.doNext) {
        //     this.activeStep.doNext.unsubscribe();
        // }
        this.activeStep.onComplete.emit();
        this._isCompleted = true;
    }

}
