import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

  @Input() text: string;
  @Input() icon_value: string = 'fa-times';
  @Input() chipClass: string = '';
  @Output() onIconClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChipClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  public iconClick(event: any) {
    event.stopPropagation();
    this.onIconClick.emit();
  }

}
