<div class="car-details">
        <div class="assembly-categories"  [@catAnim]="''" *ngIf="!technicalDataService.isCarDetails && !technicalDataService.selectedCat && !technicalDataService.isDiagnosis">
            <ng-container *ngFor="let category of mainCat | cat">
                <div class="assembly-category"
                     *ngIf="category?.value?.data?.length === 1"
                     (click)="openCat(category.value); openCatData(category?.value?.data[0], category?.value?.name)"
                     [ngClass]="{'selected': category.value?.name === technicalDataService.selectedItem, 'notvisible': !category.value?.visible }">
                    <div class="name-wrapper-no-image">
                        {{'TECHNICALINFOS.NAV.' + category.value?.name | translate}}
                    </div>
                </div>
            </ng-container>
        </div>

    <!--    Table-->
        <div class="panel-content center-content print-content" *ngIf="technicalDataService.nxDetails && technicalDataService.selectedDataCat && technicalDataService.isCarDetails" [ngClass]="{ 'setting' : !technicalDataService.showPdf}">
            <div class="technical-data_title">
                <button class="base-btn pull-right" (click)="technicalDataService.printPdf()" *ngIf="technicalDataService.showPdf && !helperService.browserIsIE() && !helperService.browserIsEdge()">
                    <i class="fa fa-print"></i> {{ 'PRINT_VIEW.PRINT' | translate }}
                </button>

                <button class="base-btn pull-right" (click)="technicalDataService.downloadPdf()"  *ngIf="technicalDataService.showPdf">
                    <i class="fa fa-save"></i> {{ 'PRINT_VIEW.GENERATE_PDF' | translate }}
                </button>

                <h1 *ngIf="!technicalDataService.showPdf">
                    <strong >{{ 'TECHNICALINFOS.NAV.' + technicalDataService.selectedCat.name | translate }}</strong>
                    <span >{{'  ' + technicalDataService.nxDetailsTitle}}</span>
                </h1>
                <h1 *ngIf="technicalDataService.showPdf">
                    <strong >{{ 'TECHNICALINFOS.NAV.' + technicalDataService.selectedCat.name | translate }}</strong>
                    <span >{{'  ' + technicalDataService.nxDetailsTitle}}</span>
                </h1>
            </div>

            <div class="print-preview" *ngIf="technicalDataService.showPdf" style="top: 0!important;" >
                <app-default-loading-page *ngIf="pdfLoading"></app-default-loading-page>
                <pdf-viewer
                        [src]="this.technicalDataService.pdf"
                        [autoresize]="false"
                        [render-text]="false"
                        [show-all]="true"
                        style="display: block;"
                ></pdf-viewer>
            </div>

            <ng-container *ngIf="!technicalDataService.showPdf">
                <table *ngFor="let detail of technicalDataService.nxDetails" class="striped-table">
                    <tr class="headerType">
                        <span class="selection title">{{detail.name | translate}}</span>
                    </tr>
                    <tr *ngFor="let subItems of detail?.specItems">
                        <td>
                            {{subItems.mountPositionText | translate}}
                        </td>
                        <td>
                            <div *ngFor="let specValues of subItems.specValues">
                                {{specValues.valueText}} {{specValues.quantityText}} {{specValues.qualifierText}}
                                <span *ngIf="specValues.additionText">{{specValues.additionalText}}</span>
                            </div>
                        </td>
                    </tr>
                    <div class="spacer"></div>
                </table>
            </ng-container>
        </div>
</div>

