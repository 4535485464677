import {Injectable} from '@angular/core';
import {ArticlesRestService} from "./articles.rest.service";
import {IAssemblyCategory} from "../interfaces/assembly-category";
import {CarService} from '../../shared/services/car/car.service';
import {UserService} from '../../shared/services/user/user.service';
import {Router} from '@angular/router';

@Injectable()
export class AssemblyCategoriesService {
    public assemblyCategories: any;
    public selectedAssemblyCategory: IAssemblyCategory = null;

    public loadingAssemblyCategories: boolean;

    constructor(private articlesRestService: ArticlesRestService,
                private userService: UserService,
                private carService: CarService,
                private router: Router) {

        this.userService.accountData$.subscribe(
            (step) => {
                if (step) {
                    if (!this.loadingAssemblyCategories) {
                        if (this.router.url.indexOf('/article') > -1) {
                            this.updateAssemblyCategories(0);
                        }
                    }
                }
            }
        );
        this.carService.selectedCarChanged$.subscribe((car) => {
            if (car && car.kTypeId) {
                this.updateAssemblyCategories(car.kTypeId);
            } else {
                if (!car) {
                    this.updateAssemblyCategories(0);
                }
            }
        });
    }

    public updateAssemblyCategories(kTypeId: number) {
        this.loadingAssemblyCategories = true;
        this.articlesRestService.getAssemblyCategories(kTypeId).subscribe(
            (response) => {
                this.assemblyCategories = response;
                this.loadingAssemblyCategories = false;
            }
        );
    }

}
