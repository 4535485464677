import { Component, OnInit } from '@angular/core';
import {GlobalAdminService} from '../services/global-admin.service';
import {AdminService} from '../../club/services/admin.service';

@Component({
  selector: 'app-statistics-dashboard',
  templateUrl: './statistics-dashboard.component.html',
  styleUrls: ['./statistics-dashboard.component.scss']
})
export class StatisticsDashboardComponent implements OnInit {

  constructor(
      public adminService: AdminService,
      public globalAdminService: GlobalAdminService) { }

  ngOnInit() {
  }

}
