<div class="global-order-row">
  <ng-container>
    <ng-container *ngFor="let item of globalAdminService?.vinLogResult?.clubLogs | keyvalue">
      <li (click)="globalAdminService.setVinLog(item)"
          routerLink="/global-admin/vinlog/{{item.key}}">
        <div class="row">
          <div class="col-1 d-flex justify-content-center align-self-center">

          </div>
          <div class="col-11">
            <div class="row">
              <div class="col">
                <span class="order-name">{{item.value?.executionDate | date : 'dd.MM.yyy hh:mm:ss'}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Datum</span>
              </div>
              <div class="col-4">
                <span>{{item.value?.executionDate | date : 'dd.MM.yyy'}}</span>
              </div>
              <div class="col-2">
                <span>Uhrzeit</span>
              </div>
              <div class="col-4">
                <span>{{item.value?.executionDate | date : 'hh:mm:ss'}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Club ID</span>
              </div>
              <div class="col-4">
                <span>{{item.key}}</span>
              </div>
              <div class="col-2">
                <span>Code</span>
              </div>
              <div class="col-4">
                <span>{{item.value?.vinResponseDocument?.code}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Firma</span>
              </div>
              <div class="col-4">
                <span>{{item.value?.firmId}}</span>
              </div>
              <div class="col-2">
                <span>Not Empty</span>
              </div>
              <div class="col-4">
                <span>{{item.value?.vinResponseDocument.notEmpty}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Stream Documents</span>
              </div>
              <div class="col-10">
                <span>{{(item.value?.streamDocuments).length}}</span>
              </div>
            </div>
            <div class="row" *ngIf="item.value?.vinResponseDocument?.description">
              <div class="col-2">
                <span>Description</span>
              </div>
              <div class="col-10">
                <span>{{item.value?.vinResponseDocument?.description}}</span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ng-container>
    <div class="noHover row app-articles-row" *ngIf="globalAdminService.infinityLoading">
      <i class="centerItem fa fa-spinner" [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i>
    </div>
    <div class="noHover row app-articles-row" *ngIf="onEnd()">
      <span class="centerItem">{{'SPAREPARTS_VIEW.SEARCH_RESULTS.END' | translate}}</span>
    </div>
  </ng-container>
</div>

