import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ISetting} from '../../../../../shared/interfaces/DTO/club/setting';
import {ConfigService} from '../../../../../shared/services/config.service';
import {UserService} from '../../../../../shared/services/user/user.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {HelperService} from '../../../../../shared/services/helper.service';

@Component({
    selector: 'app-setting-list',
    templateUrl: './setting-list.component.html',
    styleUrls: ['./setting-list.component.scss']
})
export class SettingListComponent implements OnInit, OnDestroy, OnChanges {

    @Input() public settingList: ISetting[] = [];
    @Input() public settingName: string;
    public form: UntypedFormGroup;
    public imgUrl: any;

    private settingMap: Map<string, ISetting[]> = new Map<string, ISetting[]>();

    constructor(public configService: ConfigService,
                public userService: UserService,
                public helperService: HelperService,
                public fb: UntypedFormBuilder,
                private changeDetectionRef: ChangeDetectorRef) {
        this.createForm();
        this.configService.changeClubConfig.subscribe(() => {
            this.imgUrl = undefined;
        });
    }

    ngOnInit() {
        this.sortBySortId(this.settingList);
        this.createSettingMap();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.settingList) {
            this.settingMap = new Map<string, ISetting[]>();
            this.createSettingMap();
        }
    }

    ngOnDestroy() {
        if (this.changeDetectionRef) {
            this.changeDetectionRef.detach();
        }
    }

    public createForm() {
        this.form = this.fb.group({
            name: ['', Validators.required],
            avatar: null
        });
    }

    public changeActive(setting: ISetting, active: boolean) {
        setting.active = active;
        this.configService.addUnsavedSetting(this.settingName);
    }

    private createSettingMap() {
        if (this.getTabNames().length) {
            for (const tabName of this.getTabNames()) {
                const settingListByTabName = [];

                this.settingList.forEach((setting) => {
                    settingListByTabName.push(setting.clubSettingListDTOS[tabName]);
                });

                this.settingMap.set(tabName, this.sortBySortId(settingListByTabName));
            }
        }
    }

    public resetSettingDetailsSelection() {
        this.configService.selectedSetting = undefined;
    }

    public getSettingListByTabName(tabName: string) {
        return this.settingMap.get(tabName);
    }

    private sortBySortId(settingList: ISetting[]) {
        settingList.sort(function (a, b) {
            return a.sortId - b.sortId;
        });
        return settingList;
    }

    public getTabNames() {
        if (this.settingList.length && this.settingList[0].clubSettingListDTOS) {
            const temp = Object.keys(this.settingList[0].clubSettingListDTOS);
            temp.sort()
            const index = temp.indexOf('STANDARD')
            if (index > 0) {
                temp.splice(index, 1)
                return ['STANDARD'].concat(temp)
            } else {
                return temp
            }
        } else {
            return [];
        }
    }


    public isTabbedSetting() {
        if (this.settingList && this.settingList.length > 0 && this.settingList[0].clubSettingListDTOS) {
            return true;
        } else {
            this.sortBySortId(this.settingList);
            return false;
        }
    }

    public onFileChange(event) {
        this.imgUrl = '';

        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const fileTypes = ['jpg', 'jpeg', 'png'];
            const fileSize = file.size;
            const extension = file.name.split('.').pop().toLowerCase(),
                isSuccess = fileTypes.indexOf(extension) > -1;
            if (isSuccess) {
                if (fileSize < 1000000) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (e) => {
                        this.form.get('avatar').setValue({
                            filename: file.name,
                            filetype: file.type,
                            value: reader.result.toString().split(',')[1]
                        });
                        if (reader.result) {
                            this.imgUrl = reader.result;
                        }
                    };
                } else {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_FILE_TO_LARGE', 'error')
                }

            } else {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_INVALID_FILETYPE', 'error');
            }
        }
    }
}
