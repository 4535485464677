import { Component, OnInit } from '@angular/core';
import {GlobalAdminService} from '../../services/global-admin.service';
import {Router} from '@angular/router';
import {GlobalAdminRestService} from '../../services/global-admin.rest.service';
import {HelperService} from '../../../../shared/services/helper.service';
import {AdminRestService} from '../../../../shared/services/admin.rest.service';

@Component({
  selector: 'app-global-edit-country',
  templateUrl: './global-edit-country.component.html',
  styleUrls: ['./global-edit-country.component.scss']
})
export class GlobalEditCountryComponent implements OnInit {

  public languageList: string[];

  constructor(public globalAdminService: GlobalAdminService,
              private adminRestService: AdminRestService,
              private globalAdminRestService: GlobalAdminRestService,
              private helperService: HelperService,
              private router: Router) { }

  ngOnInit() {
    if (!this.globalAdminService.selectedCountry) {
      this.router.navigate(['/global-admin/country']);
    }

    this.globalAdminRestService.getLanguages().subscribe(
        (language: string[]) => {
          this.languageList = language;
        },
        (error) => {
            this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_LANGUAGES', 'error');
        }
    );
  }

  public showSaveButton() {
    return this.globalAdminService.selectedCountry.defaultLanguage
        && this.globalAdminService.selectedCountry.iso31661Numeric
        && this.globalAdminService.selectedCountry.iso31661Alpha3
        && this.globalAdminService.selectedCountry.iso31661Alpha2
        && this.globalAdminService.selectedCountry.vat
        && this.globalAdminService.selectedCountry.currency
        && this.globalAdminService.selectedCountry.name;
  }

  public save() {
    this.adminRestService.addOrUpdateCountry(this.globalAdminService.selectedCountry).subscribe(
        (success) => {
            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_SAVE_COUNTRY', 'success');
        },
        (error) => {
            this.helperService.showNotification('TOAST_MESSAGES.ERROR_SAVE_COUNTRY', 'error');
        });
  }

}
