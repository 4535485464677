<div class="external-firm-tools">
    <div class="heading blue">
        <span class="clickable" *ngIf="!location?.path()?.includes('/admin/tools')" routerLink="/company-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <span class="clickable" *ngIf="location?.path()?.includes('/admin/tools')" routerLink="/admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <span class="clickable" routerLink="/company-admin/profile">
            <i class="fa fa-caret-right"></i>
            {{'USER_PROFILE.EXTERNAL_TOOLS' | translate}}
        </span>
        <span class="clickable float-right" [routerLink]="configService.externalToolAsAdmin ? ['/admin/tools/create'] : ['/company-admin/tools/create']">
            <i class="fa fa-plus mr-2"></i>
            {{'EXTERNAL_TOOLS.ADD_EXTERNAL_TOOL' | translate}}
        </span>
    </div>

    <div class="panel-content center-content white-background">

        <div class="club-setting-title ml-2">
            <h1>
                <strong>
                    {{'USER_PROFILE.EXTERNAL_TOOLS' | translate}}
                </strong>
            </h1>
        </div>

        <div class="row tool-row" *ngFor="let tool of externalTools">
            <div class="col-4 tool-name">{{'EXTERNAL_TOOLS.' + tool.externalTool | translate}}</div>
            <div class="col-3 tool-customer">{{tool.customerNo}}</div>
            <div class="col-3 tool-username">{{tool.username}}</div>
            <div class="col-2 tool-options text-center">
                <i class="fa fa-pencil" (click)="editTool(tool)"></i>
                <i class="fa fa-trash" (click)="showDeleteTool = tool.externalTool"></i>
            </div>
            <div class="alert alert-danger" *ngIf="showDeleteTool === tool.externalTool">
                <span class="text-center d-block">{{'EXTERNAL_TOOLS.REALLY_DELETE_TEXT' | translate}}</span>
                <button class="base-btn delete" (click)="deleteTool(tool)">{{'GENERAL.REALLY_DELETE' | translate}}</button>
                <button class="base-btn float-right" (click)="showDeleteTool = undefined">{{'GENERAL.ABORT' | translate}}</button>
            </div>
        </div>

        <div class="alert alert-info text-center" *ngIf="!externalTools || externalTools?.length === 0">
            <i class="fa fa-info-circle mr-2"></i>
            <span>{{ 'CLUB_SETTINGS.EXTERNAL_TOOLS.NO_EXTERNAL_TOOLS' | translate }}</span>
        </div>
    </div>
</div>
