<div  class="car-info"
     [ngClass]="{'show': carService.showCarInfo}" appClickOutside (clickOutside)="carService.showCarInfo = $event">
    <div class="close-bar">
        <i class="fa fa-close"></i>
    </div>
        <div class="row" style="height: 100%;">
            <div class="service-info-indicator"
                 [ngClass]="{'col-6': carService.selectedCar?.serviceInfos.length && userService.allow('VEHICLE_CALLBACK'), 'col-12': !carService.selectedCar?.serviceInfos.length || !userService.allow('VEHICLE_CALLBACK')}">
                <div class="display-name">{{ carService?.selectedCar?.displayName | ellipsis:40 }}</div>
                <table>
                    <tr>
                        <td>{{ 'CAR_HISTORY.TOOLTIP.LABELS.PRODUCER' | translate }}</td>
                        <td>{{ carService.selectedCar.carProducerName }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'CAR_HISTORY.TOOLTIP.LABELS.MODEL' | translate }}</td>
                        <td>{{ carService.selectedCar.carModelName }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'CAR_HISTORY.TOOLTIP.LABELS.TYPE' | translate }}</td>
                        <td>{{ carService.selectedCar.carTypeName }}</td>
                    </tr>
                    <tr>
                        <td>{{ 'CAR_HISTORY.TOOLTIP.LABELS.CONSTRUCTION_YEAR' | translate }}</td>
                        <td>{{ carService.selectedCar.constructionFrom + ' - ' + (carService.selectedCar.constructionTo ? carService.selectedCar.constructionTo : ' ' + ['GENERAL.TODAY' | translate])}}</td>
                    </tr>

                </table>

                <app-external-tools-wrapper [position]="'car-infos'"></app-external-tools-wrapper>

                <div>
                    <div class="subtitle">{{ 'CAR_HISTORY.TOOLTIP.SUB_TITLES.TECHNICAL_DATA' | translate }}</div>

                    <table>
                        <tr>
                            <td>{{ 'CAR_HISTORY.TOOLTIP.LABELS.POWER' | translate }}</td>
                            <td>{{ carService.selectedCar.carTypePowerKW + ' kW (' + carService.selectedCar.carTypePowerPS + ' PS)' }}</td>
                        </tr>
                        <tr>
                            <td>{{ 'CAR_HISTORY.TOOLTIP.LABELS.FUEL_TYPE' | translate }}</td>
                            <td>{{ carService.selectedCar.fuelType }}</td>
                        </tr>
                        <tr>
                            <td>{{ 'CAR_HISTORY.TOOLTIP.LABELS.FUEL_PREP' | translate }}</td>
                            <td>{{ carService.selectedCar.fuelPreparation }}</td>
                        </tr>
                        <tr>
                            <td>{{ 'CAR_HISTORY.TOOLTIP.LABELS.CCM' | translate }}</td>
                            <td>{{ carService.selectedCar.ccm + ' ccm' }}</td>
                        </tr>
                        <tr>
                            <td>{{ 'CAR_HISTORY.TOOLTIP.LABELS.CONSTRUCTION_TYPE' | translate }}</td>
                            <td>{{ carService.selectedCar.bodyType }}</td>
                        </tr>
                        <tr>
                            <td>{{ 'CAR_HISTORY.TOOLTIP.LABELS.ENGINE_SIDE' | translate }}</td>
                            <td>{{ carService.selectedCar.driveType }}</td>
                        </tr>
                        <tr>
                            <td>{{ 'CAR_HISTORY.TOOLTIP.LABELS.CYLINDERS' | translate }}</td>
                            <td>{{ carService.selectedCar.cylinders }}</td>
                        </tr>
                        <tr>
                            <td>{{ 'CAR_HISTORY.TOOLTIP.LABELS.VALVES' | translate }}</td>
                            <td>{{ carService.selectedCar.valves }}</td>
                        </tr>
                        <tr>
                            <td>{{ 'CAR_HISTORY.TOOLTIP.LABELS.ENGINE_TYPE' | translate }}</td>
                            <td>{{ carService.selectedCar.engineType }}</td>
                        </tr>
                        <tr *ngIf="customerDataService?.getNumberplate() !== ''">
                            <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.NUMBER_PLATE' | translate }}</td>
                            <td> {{ customerDataService?.getNumberplate() }}</td>
                        </tr>
                        <tr *ngIf="customerDataService?.getMileage() > 0">
                            <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.MILEAGE' | translate }}</td>
                            <td> {{ customerDataService?.getMileage() }}</td>
                        </tr>
                        <tr *ngIf="customerDataService?.getYear()">
                            <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.YEAR' | translate }}</td>
                            <td> {{ customerDataService?.getYear() | date: 'd.M.yyyy' }}</td>
                        </tr>
                    </table>
                </div>

                <div>
                    <div class="subtitle">{{ 'CAR_HISTORY.TOOLTIP.SUB_TITLES.IDS' | translate }}</div>

                    <table>

                        <tr>
                            <td *ngIf="!carService.selectedCar.typeNo">{{ 'SPAREPARTS_VIEW.CAR_DETAILS.KBA_NUMBERS' | translate }}</td>
                            <td *ngIf="carService.selectedCar.typeNo">{{ 'SPAREPARTS_VIEW.CAR_DETAILS.TYPE_NUMBER' | translate }}</td>
                            <td>
                                <ng-container
                                        *ngIf="!carService.selectedCar.typeNo && !carService.getHsn() || !carService.getTsn || (!(carService?.getHsn() !== '') || !(carService?.getTsn() !== '')) && carService?.selectedCar?.kbaNumbers">
                        <span *ngFor="let kbaNumber of carService.selectedCar.kbaNumbers, let l = last">
						    {{ kbaNumber.replace('_', ' ') }}
                            <span *ngIf="!l">,&nbsp;</span>
					    </span>
                                </ng-container>
                                <ng-container *ngIf="carService.selectedCar.typeNo">
                                    <span> {{carService.selectedCar.typeNo}}</span>
                                </ng-container>
                            </td>
                        </tr>

                        <tr *ngIf="carService?.getVin()?.length > 0">
                            <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.VIN' | translate }}</td>
                            <td>{{ carService?.getVin()}}</td>
                        </tr>
                        <tr *ngIf="carService?.getVin()?.length === 0 && customerDataService?.getVin()?.length > 0">
                            <td>{{ 'SPAREPARTS_VIEW.CAR_DETAILS.VIN' | translate }}</td>
                            <td>{{ customerDataService?.getVin()}}</td>
                        </tr>
                        <tr>
                            <td>{{ 'CAR_HISTORY.TOOLTIP.LABELS.MOTOR_CODE' | translate }}</td>
                            <td>
                    <span *ngFor="let engineCode of carService.selectedCar.engineCodes, let l = last">
                        {{ engineCode }}
                        <span *ngIf="!l">, </span>
                    </span>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ 'CAR_HISTORY.TOOLTIP.LABELS.INTERNAL_NUMBER' | translate }}</td>
                            <td>{{ carService.getKTypeId() }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-6 service-info-indicator"
                 *ngIf="carService.selectedCar?.serviceInfos.length && userService.allow('VEHICLE_CALLBACK')">
                <div class="display-name">{{ 'CAR_HISTORY.WARNING.TITLE' | translate }}</div>
                <div *ngFor="let serviceInfo of carService.selectedCar?.serviceInfos">
                    <h5>{{serviceInfo.headline}}<br/>
                        <small class="classification {{serviceInfo.classification}}">{{'SERVICE_INFO_TYPES.' + serviceInfo.classification.toUpperCase() | translate}}</small>
                        <i class="valid-date ml-2 {{serviceInfo.classification}}">{{'CAR_HISTORY.WARNING.TIME_FROM_TO' | translate:{
                            'timeFrom': serviceInfo.validFrom,
                            'timeTo': serviceInfo.validTo
                        } }}</i>
                    </h5>
                    <div class="info-text">{{serviceInfo.text}}</div>
                    <hr/>
                </div>
            </div>

        </div>
    </div>