import {trigger, animate, transition, style, query, group, keyframes} from '@angular/animations';

export const fadeAnimation =

    trigger('fadeAnimation', [

        transition( '* <=> *', [
            style({position: 'absolute'}),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    left: 0,
                    width: '100%',
                    height: '100%'
                })], { optional: true }),
            group([
                query(':enter', [
                    style({ opacity: 0 }),
                    animate('0.35s', keyframes([
                        style({
                            offset: 0,
                            opacity: 0,
                        }),
                        style({
                            offset: 1,
                            opacity: 1,
                        })
                    ]))
                ], { optional: true }),
                query(':leave', [
                    style({ opacity: 1 }),
                    animate('0.35s', keyframes([
                        style({
                            offset: 0,
                            opacity: 1,
                        }),
                        style({
                            offset: 1,
                            opacity: 0,
                        })
                    ]))
                ], { optional: true }),
            ])
        ])

    ]);
