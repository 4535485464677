<div class="dropdown-container">

    <div *ngIf="labelText" class="label-text">
        {{ labelText }}
    </div>

    <!-- multidimensional array -->
    <div *ngIf="isMultidimensionalArray() && !isFilterDropdown && !isAmountDropdown" class="nx-dropdown"
         [style.float]="alignment" [ngClass]="{ 'disabled': dropdownDisabled }">
        <button class="nx-dropdown-button" (click)="toggleDropdown()"
                [ngClass]="{ 'with-label': labelText, 'invalid': fieldRequired && !selectedItem }"
                [style.width]="width">

            <span *ngIf="!selectedItem" class="placeholder-text" [innerHtml]="placeholderText"></span>


            <span *ngIf="selectedItem" class="selected">
               <span *ngIf="selectedItem.icon">
                    <i *ngIf="selectedItem.icon !== 'mekaniq-allseason'" class="fa {{ selectedItem.icon }}"
                       [style.color]="(selectedItem.iconColor ? selectedItem.iconColor : '')"></i>
                    <span *ngIf="selectedItem.icon === 'mekaniq-allseason'" class="mekaniq-allseason"><span
                            class="path1"></span><span class="path2"></span></span>
                </span>

                <span *ngIf="!maxChars">
                    <span *ngIf="translationPrefix"
                          [innerHtml]="translationPrefix + '.' + selectedItem.name | translate"></span>
                    <span *ngIf="!translationPrefix" [innerHtml]="selectedItem.name"></span>
                </span>

                <span *ngIf="maxChars">
                    <span *ngIf="translationPrefix"
                          [innerHtml]="translationPrefix + '.' + selectedItem.name | translate | ellipsis:maxChars"></span>
                    <span *ngIf="!translationPrefix" [innerHtml]="selectedItem.name | ellipsis:maxChars"></span>
                </span>
            </span>

            <i class="fa fa-caret-down"></i>

        </button>
        <div class="nx-dropdown-menu" *ngIf="showMenu">
            <ul [style.width]="width">
                <li *ngFor="let item of items" (click)="changeSelectedItem(item)">
                    <span *ngIf="item.icon">
                        <i *ngIf="item.icon !== 'mekaniq-allseason'" class="fa {{ item.icon }}"
                           [style.color]="(item.iconColor ? item.iconColor : '')"></i>
                        <span *ngIf="item.icon === 'mekaniq-allseason'" class="mekaniq-allseason"><span
                                class="path1"></span><span class="path2"></span></span>
                    </span>

                    <span *ngIf="translationPrefix"
                          [innerHtml]="translationPrefix + '.' + item.name | translate"></span>
                    <span *ngIf="!translationPrefix" [innerHtml]="item.name"></span>

                </li>
            </ul>
        </div>
    </div>


    <!-- flat array -->
    <div *ngIf="!isMultidimensionalArray() && !isFilterDropdown && !isAmountDropdown" class="nx-dropdown"
         [style.float]="alignment" [style.width]="width" [ngClass]="{ 'disabled': dropdownDisabled }">
        <button class="nx-dropdown-button" (click)="toggleDropdown()"
                [ngClass]="{ 'with-label': labelText, 'invalid': fieldRequired && !selectedItem }"
                [style.width]="width">

            <span *ngIf="!selectedItem" class="placeholder-text">
                {{ placeholderText }}
            </span>

            <span *ngIf="selectedItem" class="selected">
                <span *ngIf="!maxChars">
                    <span *ngIf="translationPrefix"
                          [innerHTML]="translationPrefix + '.' + selectedItem | translate"></span>
                    <span *ngIf="!translationPrefix" [innerHTML]="selectedItem"></span>
                </span>

                <span *ngIf="maxChars">
                    <span *ngIf="translationPrefix"
                          [innerHTML]="translationPrefix + '.' + selectedItem | translate | ellipsis:maxChars"></span>
                    <span *ngIf="!translationPrefix" [innerHTML]="selectedItem | ellipsis:maxChars"></span>
                </span>
            </span>

            <i class="fa fa-caret-down"></i>

        </button>
        <div class="nx-dropdown-menu" *ngIf="showMenu">
            <ul>
                <li *ngFor="let item of items" (click)="changeSelectedItem(item)">
                    <span *ngIf="translationPrefix">
                        {{ translationPrefix + "." + item | translate }}
                    </span>
                    <span *ngIf="!translationPrefix">
                        {{ item }}
                    </span>
                </li>
            </ul>
        </div>
    </div>

    <!-- Filter -->
    <div *ngIf="isFilterDropdown && !isAmountDropdown" class="nx-dropdown filter-dropdown" [style.float]="alignment"
         [style.width]="width" [ngClass]="{'disabled': dropdownDisabled}">
        <button class="nx-dropdown-button" (click)="toggleDropdown()"
                [ngClass]="{ 'with-label': labelText, 'invalid': fieldRequired && !selectedItem }"
                [style.width]="width">
                <span *ngIf="!selectedItem" class="placeholder-text">
                {{ placeholderText }}
            </span>

            <span *ngIf="selectedItem" class="selected">
                <span *ngIf="!maxChars">
                    <span [innerHTML]="selectedItem.displayValue"></span>
                </span>

                <span *ngIf="maxChars">
                    <span [innerHTML]="selectedItem.displayValue | ellipsis:maxChars"></span>
                </span>
            </span>

            <i class="fa fa-caret-down"></i>
        </button>
        <div class="nx-dropdown-menu" *ngIf="showMenu">
            <ul>
                <ng-container *ngIf="hasSearch">
                    <input type="text" class="w-auto form-control border-bottom my-2 ml-2"
                           [placeholder]="'HOME.SEARCH' | translate"
                           [(ngModel)]="searchValue"
                           (ngModelChange)="onSearchChange($event)">
                </ng-container>
                <li *ngFor="let item of (hasSearch? filteredItems : items)" (click)="changeSelectedItem(item)">
                    <span>
                       {{ item.displayValue }}
                    </span>
                </li>
            </ul>
        </div>
    </div>

    <!-- Amount -->
    <div class="nx-dropdown amount-dropdown" *ngIf="isAmountDropdown" [style.float]="alignment" [style.width]="width"
         [ngClass]="{'disabled': dropdownDisabled}">
        <button class="nx-dropdown-button" (click)="toggleDropdown()"
                [ngClass]="{ 'with-label': labelText, 'invalid': fieldRequired && !selectedItem }"
                [style.width]="width">
                <span *ngIf="!selectedItem" class="placeholder-text">
                {{ placeholderText }}
            </span>

            <span *ngIf="selectedItem" class="selected">
                <span>
                    {{ selectedItem.amount }}
                </span>
                <span>
                    <span *ngIf="selectedItem?.quantity?.length > 0">{{ selectedItem?.quantity }} {{ selectedItem?.qualifierText }}</span>
                    <span *ngIf="!(selectedItem?.quantity?.length > 0)">{{ 'CART.PIECE' | translate }}</span>
                </span>
            </span>

            <i class="fa fa-caret-down"></i>
        </button>
        <div class="nx-dropdown-menu" *ngIf="showMenu">
            <ul>
                <li *ngFor="let item of items" (click)="changeSelectedItem(item)">
                    <span>
                        {{ item.amount }}
                    </span>
                    <span>
                        <span *ngIf="item?.quantity?.length > 0">{{ item?.quantity }} {{ item?.qualifierText }}</span>
                        <span *ngIf="!(item?.quantity?.length > 0)">{{ 'CART.PIECE' | translate }}</span>
                    </span>
                </li>
            </ul>
        </div>
    </div>

</div>
