import {Component, OnDestroy, OnInit} from '@angular/core';
import {GlobalAdminRestService} from '../../../global/services/global-admin.rest.service';
import {AdminService} from '../../services/admin.service';
import {ConfigService} from '../../../../shared/services/config.service';
import {Observable, Subscriber, Subscription} from 'rxjs';
import {IClubSupplier} from '../../../../shared/interfaces/DTO/admin/clubSupplier';
import {HelperService} from '../../../../shared/services/helper.service';
import {UserService} from '../../../../shared/services/user/user.service';

@Component({
    selector: 'app-club-supplier-config',
    templateUrl: './club-supplier-config.component.html',
    styleUrls: ['./club-supplier-config.component.scss']
})
export class ClubSupplierConfigComponent implements OnInit, OnDestroy {

    public allSuppliers: IClubSupplier[] = [];
    public clubSuppliers: IClubSupplier[] = [];
    public allSuppliersList: IClubSupplier[] = [];
    public clubSuppliersList: IClubSupplier[] = [];
    public selectedSupplier: IClubSupplier;
    public selectedClubSupplier: IClubSupplier;
    public loadingSuppliers: boolean | false;
    public loadingSaveSuppliers: boolean | false;
    public allSuppliersInput: string | '';
    public clubSuppliersInput: string | '';
    private clubChangeSubscription: Subscription;

    constructor(private globalAdminRestService: GlobalAdminRestService,
                private adminService: AdminService,
                private userService: UserService,
                public configService: ConfigService,
                public helperService: HelperService) {

        this.clubChangeSubscription = this.adminService.onChangeClub.subscribe(() => {
            this.getClubSuppliers();
            this.getAllSuppliers();
        });
    }

    ngOnInit() {
        this.getAllSuppliers();
        this.getClubSuppliers();
    }

    ngOnDestroy() {
        if (this.clubChangeSubscription) {
            this.clubChangeSubscription.unsubscribe();
        }
    }

    public reset() {
        this.getClubSuppliers();
    }

    public isInClubSupplierList(supplier: IClubSupplier) {
        let isInList = false;

        if (this.clubSuppliers) {
            this.clubSuppliers.forEach((supp) => {
                if (supp.code === supplier.code) {
                    isInList = true;
                }
            });
        }

        return isInList;
    }


    public reduceList(list: IClubSupplier[], searchTerm: string) {
        if (list && searchTerm && searchTerm.length > 0) {
            return list.filter(supp => supp.name.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1 || supp.code.toString() === searchTerm);
        } else {
            return list;
        }
    }

    public removeSupplier() {
        if (this.selectedClubSupplier) {
            this.clubSuppliers.splice(this.clubSuppliers.indexOf(this.selectedClubSupplier), 1);
            this.clubSuppliersList = this.reduceList(this.clubSuppliers, this.clubSuppliersInput);
            this.selectedClubSupplier = undefined;
            this.configService.addUnsavedSetting('SUPPLIER');
        }
    }

    public addSupplier() {
        if (this.selectedSupplier) {
            this.clubSuppliers.push(this.selectedSupplier);
            this.clubSuppliersInput = '';
            this.clubSuppliersList = this.reduceList(this.clubSuppliers, this.allSuppliersInput);
            this.selectedSupplier = undefined;
            this.configService.addUnsavedSetting('SUPPLIER');
        }
    }

    public getSelectedDataSuppliers() {
        const setting = this.configService.getSubSettingByName('TECDOC', 'DATA_SUPPLIER');
        if (setting) {
            return setting.selected;
        }
    }

    private getAllSuppliers() {
        this.loadingSuppliers = true;
        this.adminService.getAllSuppliersRest().subscribe(
            (suppliers: IClubSupplier[]) => {
                this.loadingSuppliers = false;
                this.allSuppliers = suppliers;
                this.allSuppliersList = suppliers;
            },
            (error) => {
                this.loadingSuppliers = false;
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_OE_SUPPLIERS', 'error');
            });
    }

    private getClubSuppliers() {
        if (this.adminService.getClubId()) {
            this.loadingSuppliers = true;
            this.adminService.getClubSuppliersRest(this.adminService.getClubId()).subscribe(
                (suppliers: IClubSupplier[]) => {
                    this.loadingSuppliers = false;
                    this.clubSuppliers = suppliers;
                    this.clubSuppliersList = suppliers;
                    this.configService.removeUnsavedSetting('SUPPLIER');
                },
                (error) => {
                    this.loadingSuppliers = false;
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_OE_SUPPLIERS', 'error');
                });
        }
    }

    public updateClubSuppliers() {
        if (this.adminService.getClubId()) {
            this.loadingSaveSuppliers = true;
            this.adminService.updateClubSuppliersRest(this.adminService.getClubId(), this.clubSuppliers).subscribe(
                (success) => {
                    this.loadingSaveSuppliers = false;
                    this.configService.removeUnsavedSetting(this.configService.selectedSettingName);
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_SAVE_CLUB_SUPPLIERS', 'success');
                },
                (error) => {
                    this.loadingSaveSuppliers = false;
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_SAVE_CLUB_SUPPLIER', 'error');
                }
            );
        }
    }
}
