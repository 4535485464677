import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch';
import {Router} from '@angular/router';
import {Location} from "@angular/common";
import {MessageHandlerService} from './shared/services/messageHandler.service';
import {UserService} from './shared/services/user/user.service';
import {catchError, tap} from "rxjs/operators";
import {throwError} from "rxjs";

@Injectable()
export class XhrHttpInterceptor implements HttpInterceptor {

    constructor(private router: Router,
                private location: Location,
                private messageHandlerService: MessageHandlerService,
                private userService: UserService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

        let authReq = req.clone({headers: req.headers.set("withCredentials", "true")});
        authReq = authReq.clone({headers: req.headers.set("X-Requested-With", "XMLHttpRequest")});

        return next.handle(authReq)
            .pipe(tap(event => {
                if (req.url.includes('/tecrmi/')) {
                    /*
                    es kann vorkommen, dass wir durch bereits fehlende Werte,
                    Calls direkt vom BE mit leerem COntent zurüchbekommen,
                    deswegen explizite Prüfung hier auf einen 200er Status
                    */
                    if (event instanceof HttpResponse && event.status === 200) {
                        this.userService.setTecrmiError(false);
                    }
                }
            }), catchError(error => {
                if (this.router && this.location.path() !== "/login" && error.status === 401) {
                    void this.router.navigate(['/login']);
                }

                if (error.status === 504 || error.status === 502) {
                    void this.router.navigate(['/page-maintenance']);
                }
                if (error.status === 429 && error.url.indexOf('/tecrmi/') > -1) {
                    this.userService.setTecrmiError(true);
                }

                if (error.status !== 401 && error.status !== 424 && error.status !== 409) {
                    this.messageHandlerService.showErrorMessage(error.status, error);
                }

                return throwError(error);
            }));
    }
}
