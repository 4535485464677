import { Component, OnInit, Input } from '@angular/core';
import {HelperService} from '../../shared/services/helper.service';
import {CartService} from '../services/cart.service';
import {UserService} from '../../shared/services/user/user.service';
import {ISupplier} from '../../shared/interfaces/supplier';
import {CartRestService} from '../services/cart.rest.service';

@Component({
  selector: 'app-cart-supplier-change',
  templateUrl: './cart-supplier-change.component.html',
  styleUrls: ['./cart-supplier-change.component.scss']
})
export class CartSupplierChangeComponent implements OnInit {

  constructor(public helperService: HelperService,
              public cartService: CartService,
              public userService: UserService,
              public cartRestService: CartRestService) { }

  ngOnInit() {
  }

  public selectSupplierForArticle(supplier: string, dealerArticleNumber: string, dealerBrandIdentifier: string): void {
    if (this.cartService.selectedArticle) {
      this.cartService.selectedArticle.supplier = supplier;
      this.cartService.showSupplierSelection = false;

      this.cartRestService.changePos(this.cartService.selectedArticle.acquisitionType, this.cartService.selectedArticle.posId,
          this.cartService.selectedArticle.quantity, null,
          this.cartService.selectedArticle.description, this.cartService.selectedArticle.ipc,
          supplier, dealerArticleNumber, dealerBrandIdentifier, this.cartService.selectedArticle.additionalName, this.cartService.selectedArticle.quantityUnit).subscribe(
          (success) => {
            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_CHANGE_SUPPLIER', 'success');
            this.cartService.updateCart(false, false, false, false);
          },
          (error) => {
            this.helperService.showNotification('TOAST_MESSAGES.ERROR_CHANGE_SUPPLIER', 'error');
          });
    }
  }

  public setSupplier(supplier: ISupplier): void {
    if (supplier && supplier.supplier !== this.cartService.selectedSupplier) {
      this.cartService.selectedSupplier = supplier.supplier;
      this.cartService.dealerArticleNumber = supplier.dealerArticleNumber;
      this.cartService.dealerBrandIdentifier = supplier.dealerBrandIdentifier;
    }
  }

  public stopPropagation($event: any): void {
    $event.stopPropagation();
  }

}
