<div class="club-setting">
    <div class="content" *ngIf="configService?.clubConfig?.settings">
        <div class="heading blue">
        <span class="clickable">
            <i class="fa fa-th" aria-hidden="true"></i>
            <span>{{'CLUB_ADMIN.CONFIG' | translate}}</span>
        </span>
        </div>
        <div class="panel-content center-content">
            <div class="assembly-categories">
                <div class="assembly-category" *ngFor="let setting of configService?.clubConfig?.settings" routerLink="/admin/config/{{setting.first}}">
                    <div class="name-wrapper-no-image">
                        <span>{{'CLUB_ADMIN.SETTING_NAME.' + setting.first | translate}}</span>
                    </div>
                </div>
                <div class="assembly-category" routerLink="/admin/config/SUPPLIER">
                    <div class="name-wrapper-no-image">
                        <span>{{'CLUB_ADMIN.SETTING_NAME.SUPPLIER' | translate}}</span>
                    </div>
                </div>
                <div class="assembly-category" routerLink="/admin/config/COUNTRY">
                    <div class="name-wrapper-no-image">
                        <span>{{'CLUB_ADMIN.SETTING_NAME.COUNTRY' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

