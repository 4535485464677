import {Component, OnInit} from '@angular/core';
import {GlobalAdminService} from '../../services/global-admin.service';
import {UserService} from '../../../../shared/services/user/user.service';
import {PdfService} from '../../../../shared/services/pdf.service';
import {Router} from '@angular/router';
import {CartService} from '../../../../cart/services/cart.service';
import {OrderHistoryService} from '../../../company/order-history/services/order-history.service';
import {IOrderAdminMail, IOrderAdminMailContent} from '../../../../shared/interfaces/DTO/order/orderAdmin';
import {GlobalAdminRestService} from '../../services/global-admin.rest.service';
import { HelperService } from 'app/shared/services/helper.service';

@Component({
    selector: 'app-global-order-details',
    templateUrl: './global-order-details.component.html'
})
export class GlobalOrderDetailsComponent implements OnInit {

    constructor(public globalAdminService: GlobalAdminService,
                private globalAdminRestService: GlobalAdminRestService,
                public userService: UserService,
                public pdfService: PdfService,
                public router: Router,
                public cartService: CartService,
                private helperService: HelperService,
                public orderHistoryService: OrderHistoryService) {
    }

    ngOnInit() {
    }

    public externalIdsEmpty(): boolean {
        let externalIds = [];
        for (const [key, value] of (<any>Object).entries(this.globalAdminService.orderDetails.supplierInformation)) {
            if(key && value && value.externalId)
            externalIds.push(value.externalId);
        }
        return externalIds.length === 0;
    }

    public externalIdsNotEmpty(): boolean {
        return !this.externalIdsEmpty();
    }

    public openMail(mail: IOrderAdminMail) {
        this.globalAdminRestService.getOrderMailRest(this.globalAdminService.selectedOrderId, mail.id).subscribe(
            (mailContent: IOrderAdminMailContent) => {
                this.globalAdminService.orderMail = mailContent;
                this.globalAdminService.showOrderMail = true;
            },
            (error) => {
                this.helperService.showNotification('Beim Laden der Daten ist ein Fehler aufegetreten', 'error');
            });
    }
}
