import {Component, OnInit, ElementRef, EventEmitter} from '@angular/core';
import {ArticlesService} from '../../../articles/services/articles.service';
import {SearchHistoryService} from '../../../shared/services/search-history.service';
import {Router} from '@angular/router';
import { ThemeService } from 'app/shared/services/theme.service';
import { UserService } from 'app/shared/services/user/user.service';

@Component({
    selector: 'app-direct-search',
    templateUrl: './direct-search.component.html',
    host: {
        '(document:click)': 'onOutsideClick($event)'
    }
})
export class DirectSearchComponent implements OnInit {
    public showSearchHistory: boolean = false;
    public emitTypeChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private userService: UserService,
        private router: Router,
        public articles: ArticlesService,
        public searchHistoryService: SearchHistoryService,
        private _eref: ElementRef
    ) {
    }

    ngOnInit() {
    }

    public changeSelectedType(event: any) {
        if (this.articles.supplierType !== event) {
            this.articles.supplierType = event;
            this.articles.resetAll();
        }
    }

    public onDirectSearch() {
        this.articles.hidePartsListPseudo = false;
        this.articles.showPseudoArticle = false;
        if (this.articles.directSearchValue) {
            
            this.articles.resetToDefaultTree();
            this.searchHistoryService.addItem(this.articles.directSearchValue);

                this.articles.updateArticlesBySearchString(this.articles.directSearchValue, [], undefined, 1, this.articles.supplierType);
            this.showSearchHistory = false;
        }
        this.router.navigate(['/articles']);
    }

    public onHistoryArticleSearch(searchValue: string) {
        this.articles.hidePartsListPseudo = false;
        this.articles.resetToDefaultTree();

        this.articles.updateArticlesBySearchString(searchValue, [], undefined, 1, this.articles.supplierType);
        this.articles.directSearchValue = searchValue;
        this.showSearchHistory = false;
        this.router.navigate(['/articles']);
    }


    public onOutsideClick(event: any) {
        if (!this._eref.nativeElement.contains(event.target)) {
            this.showSearchHistory = false;
        }
    }
}
