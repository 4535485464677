import {Component, OnDestroy, OnInit} from '@angular/core';
import {AssortmentService} from '../services/assortment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AssortmentRestService} from '../services/assortment-rest.service';
import {DatamapperService} from '../services/datamapper.service';
import {IAssortment} from '../interfaces/assortment';

@Component({
    selector: 'app-assortment-specific-supplier',
    templateUrl: './assortment-specific-supplier.component.html'
})
export class AssortmentSpecificSupplierComponent implements OnInit, OnDestroy {

    public loading: boolean = true;
    public showNoResults: boolean = false;
    public supplierId: number;
    public clubAssortments: IAssortment[] = [];
    public firmAssortments: IAssortment[] = [];
    public state: string;

    constructor(public dataMapperService: DatamapperService,
                public assortmentService: AssortmentService,
                private assortmentRestService: AssortmentRestService,
                public router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.assortmentService.assortmentSubscriptions.push(this.assortmentService.stateClubOrFirm.subscribe(state => this.state = state));
        this.assortmentService.assortmentSubscriptions.push(this.route.params.subscribe(params => {
            this.supplierId = Number(params.id);
            this.assortmentService.getAssortments('brand', this.supplierId);
        }));
        this.assortmentService.assortmentSubscriptions.push(this.assortmentService.assortments.subscribe(
            items => {
                this.loading = true;
                this.firmAssortments = [];
                this.clubAssortments = [];
                setTimeout(() => {
                    if (items) {
                        if (this.state === 'club') {
                            this.clubAssortments = items;
                        }
                        if (this.state === 'firm' || this.state === 'ownfirm') {
                            items.forEach(item => {
                                if (item.firmId !== null) {
                                    this.firmAssortments.push(item);
                                } else {
                                    this.clubAssortments.push(item);
                                }
                            })
                        }
                        this.showNoResults = false;
                    }
                    if (items && items.length === 0) {
                        this.showNoResults = true;
                    }
                    this.loading = false;
                }, 300)
            }));
        this.assortmentService.assortmentSubscriptions.push(this.assortmentService.refreshBehavior.subscribe(bool => {
            if (bool === true) {
                setTimeout(() => {
                    this.assortmentService.getAssortments('brand', this.supplierId);
                }, 300)
                this.assortmentService.refreshBehavior.next(false);
            }
        }));
    }


    ngOnDestroy(): void {
        this.assortmentService.cleanSubscriptions();
    }

    public updatePriority(id: string, primaryPrio: boolean, value: number, overWrite: boolean) {
        this.assortmentService.updatePriority(id, primaryPrio, value, overWrite);
    }

    public updateAssortment(id: string, overWrite: boolean) {
        this.assortmentService.updateAssortment(id, overWrite);
    }


    public deleteAssortment(id: string) {
        this.assortmentService.deleteAssortment(id);

    }


    public modulus(value: number) {
        return this.assortmentService.modulus(value);
    }
}
