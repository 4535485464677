<div class="setting-external-tool">

  <div class="club-setting-title">
    <h1>
      <strong>
        {{'CLUB_ADMIN.SETTING_NAME.' + settingName | translate}}
      </strong>
    </h1>
  </div>

  <div class="list-wrapper drag-list" cdkDropList
       (cdkDropListDropped)="cdkDropListDropped($event); configService.addUnsavedSetting(settingName)">
    <div class="row drag-box" cdkDrag *ngFor="let setting of settingList">
      <div class="col-4">
        <span class="setting-name">{{'CLUB_SETTINGS.' + settingName + '.' + setting.name | translate}}</span>
      </div>
      <div class="col-2">
        <app-checkbox [isChecked]="setting.active"
                      (onChange)="changeActive(setting, $event)"
                      [labelText]="'GENERAL.ACTIVE' | translate">
        </app-checkbox>
      </div>
      <div class="col-2">
        <app-checkbox [isChecked]="setting.optional"
                      (onChange)="changeOptional(setting, $event)"
                      [labelText]="'CLUB_SETTINGS.EXTERNAL_TOOLS.OPTIONAL' | translate">
        </app-checkbox>
      </div>
      <div class="col-3">
        <app-checkbox [isChecked]="setting.showInDetails"
                      (onChange)="changeShowInDetails(setting, $event)"
                      [labelText]="'CLUB_SETTINGS.EXTERNAL_TOOLS.SHOW_IN_DETAILS' | translate">
        </app-checkbox>
      </div>
    </div>
  </div>

  <div class="button-row" *ngIf="configService.hasUnsavedSettings(settingName)">
    <button class="base-btn success" (click)="configService.saveClubConfig()">
      <i class="fa fa-check mr-2"></i>
      <span>{{'GENERAL.SAVE_CHANGES' | translate}}</span>
    </button>
  </div>

</div>
