import { Component, OnInit } from '@angular/core';
import {AdminService} from '../../../services/admin.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../../shared/services/user/user.service';
import {ISupplierLoginData} from '../../../../../shared/interfaces/DTO/firm/supplierLoginData';
import {ISupplier} from '../../../../../shared/interfaces/DTO/firm/supplier';

@Component({
  selector: 'app-edit-firm-supplier',
  templateUrl: './edit-firm-supplier.component.html'
})
export class EditFirmSupplierComponent implements OnInit {

  public unassignedSupplier:  ISupplier[];
  constructor(private router: Router,
              private route: ActivatedRoute,
              public userService: UserService,
              public adminService: AdminService) {
      this.route.params.subscribe(
          (params) => {
              if (!this.adminService.selectedFirm || this.adminService.selectedFirm.firmId !== params['id']) {
                  this.adminService.getFirmDataById(params['id']).subscribe(
                      (firm) => {
                          this.adminService.selectedFirm = firm;
                          this.updateSupplier(params['supplier']);
                      }
                  )
              } else {
                  this.updateSupplier(params['supplier']);
              }
          }
      );

  }

  ngOnInit() {
  }

  private updateSupplier(supp: any) {
      if (!supp) {
          this.adminService.supplier = {customerNo: '', supplier: '', password: '', username: '', firmId: 0, id: '', sortNo: 0, active: true};
          this.adminService.supplier.firmId = this.adminService.selectedFirm.firmId;
      } else {
          this.adminService.getSuppliersRest(this.adminService.selectedFirm.firmId).subscribe(
              (response) => {
                  for (const supplier of response) {
                      if (supplier.id === supp) {
                          this.adminService.supplier = supplier;
                      }
                  }
              });

      }
      this.adminService.getUnassignedsuppliers(this.adminService.selectedFirm.firmId).subscribe(
          (data) => {
              this.unassignedSupplier = data;
          }
      );
  }
  public save(supplier: ISupplierLoginData) {
      if (supplier.id.toString() === '') {
          this.adminService.createSupplier(supplier);
      } else {
          this.adminService.updateSupplier(supplier);
      }
  }
  public back() {
      this.router.navigate(['admin/suppliers', this.adminService.selectedFirm.firmId]);
  }
}
