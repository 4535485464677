import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ErrorHandlerService} from '../../../shared/components/error-handler/services/error-handler.service';
import {TranslateService} from '@ngx-translate/core';
import {IPassword} from '../../profile/interfaces/address';
import {FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../shared/services/user/user.service';

@Component({
    selector: 'app-user-password',
    templateUrl: './user-password.component.html'
})
export class UserPasswordComponent implements OnInit {

    @Output() public onPasswordChange: EventEmitter<any> = new EventEmitter();
    @Input() public userName;

    public newPassword: string;
    public repeatPass: string;
    public errorMessage: string;
    public errorMessage_password_wrong: string;
    public pw: IPassword = {
        'valid' : true,
        'newPassword' : ''
    };

    passwordForm = new UntypedFormGroup({
        // password: new FormControl('', Validators.required),
        // repeat: new FormControl('', Validators.required)
    });

    constructor(public errorHandlerService: ErrorHandlerService,
                public userService: UserService,
                private translateService: TranslateService) {
        this.translateService.get('USER_PROFILE.PASSWORD_ERROR_EMPTY').subscribe((msg: string) => this.errorMessage = msg);
        this.translateService.get('USER_PROFILE.PASSWORD_WRONG').subscribe((msg: string) => this.errorMessage_password_wrong = msg);
    }

    ngOnInit() {
    }

    public changeUserPW() {
        if (this.newPassword === '' || this.newPassword === undefined) {
            this.errorHandlerService.throwStringError(this.errorMessage, 17);
        } else {
            if (this.newPassword === this.repeatPass) {
                this.pw.newPassword = this.newPassword;
                this.onPasswordChange.emit(this.pw);
            } else {
                this.errorHandlerService.throwStringError(this.errorMessage_password_wrong, 19);
            }
        }
    }

}
