<div class="autovista-left-tree">
  <ngb-tabset #tabSet>
    <ngb-tab title="{{'SPAREPARTS_VIEW.ASSEMBLIES' | translate}}">
      <ng-template ngbTabContent>
        <!-- new images -->
        <ul class="custom-tree" *ngIf="autovistaService?.dsCodes?.length > 0">
          <li class="list" [ngClass]="{'active': dsCode.code === autovistaService?.selectedGroup}" *ngFor="let dsCode of autovistaService?.dsCodes" (click)="getZone(dsCode)">
            <i class="folder fa fa-circle"></i>
            <span>{{dsCode.name}}</span>
          </li>
        </ul>

        <!-- old images -->
        <ul class="custom-tree" *ngIf="autovistaService?.mcGroups?.length > 0">
          <li class="list" [ngClass]="{'active': autovistaService?.selectedGroup === mcGroup}" *ngFor="let mcGroup of autovistaService?.mcGroups" (click)="autovistaService.selectedGroup = mcGroup; autovistaService.selectedSubgroup = undefined; autovistaService.oldXML = undefined">
            <i class="folder fa fa-circle"></i>
            <span>{{mcGroup.name}}</span>
            <ul class="subCustomList" *ngIf="autovistaService?.selectedGroup === mcGroup">
              <li class="subList" [ngClass]="{'active': autovistaService?.selectedSubgroup === subGroup}" *ngFor="let subGroup of mcGroup.subGroups" (click)="getMcGroupZone($event, subGroup)">
                <i class="folder fa fa-circle"></i>
                <span>{{subGroup.name}}</span>
              </li>
            </ul>
          </li>
        </ul>
      </ng-template>
    </ngb-tab>
    <ngb-tab [disabled]="!(autovistaService?.hasfilter)">
      <ng-template ngbTabTitle>
        <span>{{ 'AUTOVISTA.EQUIPMENT' | translate }}</span>
      </ng-template>
      <ng-template ngbTabContent>
        <ul class="custom-tree">
          <div class="listContainer" *ngFor="let mapKey of getFilterKeys()">
            <li class="list" *ngFor="let filter of autovistaService?.filter[mapKey]">
              <app-checkbox [isChecked]="autovistaService?.selectedFilter?.get(mapKey) === filter.criteriaCode" [labelText]="filter.name"
                            (onChange)="toggleFilter(filter, mapKey, $event)"></app-checkbox>
            </li>
          </div>
        </ul>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>
