import {Component, OnInit, Injectable, OnDestroy} from '@angular/core';
import {CarService} from '../../services/car/car.service';
import {UserService} from '../../services/user/user.service';
import {Router} from '@angular/router';
import {CustomerDataService} from '../../services/customerData.service';
import {ConfigService} from '../../services/config.service';
import {IMainCat} from '../../../technical-data/interfaces/IMainCat';
import {PdfService} from '../../services/pdf.service';
import {TecRmiRestService} from '../../services/tecrmi/tecrmi.rest.service';
import {HelperService} from '../../services/helper.service';
import {TranslateService} from '@ngx-translate/core';
import {catAnimation, fadeOfferToggle, workListAnim} from '../../animations/general.animation';
import {AutovistaRestService} from '../../../graphical-part-search/services/autovista.rest.service';
import {CartService} from '../../../cart/services/cart.service';
import {TechnicalDataService} from '../../../technical-data/technical-data.service';

@Component({
    selector: 'app-car-details-equipment',
    templateUrl: './car-details-equipment.component.html',
    styleUrls: ['./car-details-equipment.component.scss'],
    animations: [catAnimation, workListAnim, fadeOfferToggle]
})
@Injectable({
    providedIn: 'root'
})
export class CarDetailsEquipmentComponent implements OnInit, OnDestroy {
    public mainCat: IMainCat;
    public autopdf: boolean = false;
    public nxDetails: any;
    public nxDetailsTitle: string;
    public manual: any;
    public selectedItem: any;
    public selectedChild: any;
    public selectedCat: any;
    public selectedCar: any;
    public selectedChildCat: any;
    public selectedDataCat: any;
    public hasData: boolean = false;
    public carDetails: any;
    public carDetailsTitle: any;
    public loadCarDetails: boolean =  false;
    public vinLoading: boolean = false;
    public pdfLoading: boolean = false;
    public pdf: any;
    public numberplate: string;
    public url: string;
    public location: string;
    public subscription: any;

    constructor(
        public userService: UserService,
        public carService: CarService,
        public pdfService: PdfService,
        private router: Router,
        private tecRmiRestService: TecRmiRestService,
        public helperService: HelperService,
        public configService: ConfigService,
        private translateService: TranslateService,
        private customerDataService: CustomerDataService,
        private autovistaRestService: AutovistaRestService,
        private cartService: CartService,
        public technicalDataService: TechnicalDataService
    ) {
        this.startupCheck();
    }

    ngOnInit() {
        this.getautoVistaResponse();
        this.mainCat = {
            carDetails: {
                selected: false,
                visible: false,
                name: 'CAR_DETAILS',
                value: 7,
            }
        };
        if (this.location === 'cart') {
            this.openCat(this.mainCat.carDetails);
        }
        this.technicalDataService.category = this.mainCat.carDetails;
        this.technicalDataService.openCarDetailsEquipmentEmitter.subscribe(() => {
            this.openCat(this.technicalDataService.category);
            this.openCatData(this.technicalDataService.category.data[0], this.technicalDataService.category.name)
        });
        this.technicalDataService.autoOpenDetailsEmitter.subscribe(() => {
            if (this.technicalDataService.autoOpenDetails === true && this.location === 'technical-data' && this.technicalDataService.category && this.technicalDataService.category.data) {
                this.openCat(this.technicalDataService.category);
                this.openCatData(this.technicalDataService.category.data[0], this.technicalDataService.category.name)
            } else if (!this.carService.autovistaVinResponse) {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_NO_AUTOVISTA', 'error');
            }
        })
    }
    ngOnDestroy() {
        this.technicalDataService.category = undefined;
        this.mainCat.carDetails = undefined;
    }

    private startupCheck() {
       this.url = this.router.url;
       switch (this.url) {
           case '/technical-data': {
               this.location = 'technical-data'; break;
           }
           case '/cart/cardetails': {
               this.location = 'cart'; break;
           }
           case 'cardetails': {
               this.location = 'panel'; break;
           }
       }
        if (this.location === 'cart') {
            this.selectedCar = this.cartService.showDocument.car;
        } else {
            this.selectedCar = this.carService.selectedCar;
        }
        this.nxDetails = false;
    }
    public getautoVistaResponse() {
        this.vinLoading = true;
        this.carService.loadCar = 'AUTOVISTA-VIN';
        this.autovistaRestService.getAutovistaVinRest(this.carService.replaceVin(this.selectedCar.vin), true).subscribe(
            (vinCar: any) => {
                this.vinLoading = false;
                this.carService.loadCar = undefined;
                if (vinCar === null) {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_VIN_RESPONSE', 'error');
                } else {
                    this.carService.loadCar = undefined;
                    this.carService.fromDMS = true;
                    if (vinCar['carDtos']['0']) {
                        const autovistaVinResponse = vinCar['carDtos']['0']['autovistaVinResponse'];
                        this.carService.autovistaVinResponse = autovistaVinResponse;
                        this.getCarDetails(autovistaVinResponse);
                    }
                }
            },
            (error) => {
                this.vinLoading = false;
                this.carService.loadCar = undefined;
                if (error.status === 400) {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_NOT_A_VIN', 'error');
                } else if (error.status === 409) {
                    this.carService.handleCarConflict();
                } else {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_CARDATA', 'error');
                }
            });
    }
    public printIframe() {
        const frame = window.frames['tecrmi-manual'];
        if (!frame) {
            return;
        }

        if (this.helperService.browserIsIE()) {
            frame.document.execCommand('print', false, null);
        } else {
            const frames: any = document.getElementById('tecrmi-manual');
            frames.contentWindow.focus();
            frames.contentWindow.print();
        }
    }


    public getCarDetails(autovistaVinResponse: any) {
        if (autovistaVinResponse != null && this.mainCat.carDetails) {
            const title = autovistaVinResponse.repair.headmodels[0];
            const name = title.make.name + ' ' + title.modelRangeName;
            this.carDetailsTitle = name + '\n' + title.technicalData;
            this.technicalDataService.nxDetailsTitle = name;
            this.loadCarDetails = true;
            let nodeCount = 0;
            let temp = [];
            let detailTitle;
            const details =  JSON.parse('[{"name":"' + name + '"}]');
            temp.push({
                'mountPositionText': title.technicalData,
                'mountPositionId': '',
                'specValues': [{
                    'valueText': '',
                    'quantityText': '',
                    'qualifierText': '',
                    'additionalText': '',
                }]
            });
            details[nodeCount].specItems = temp;
            nodeCount++;
            detailTitle = this.translateDetails('car_details.specification', 'title');
            details.push({'name': detailTitle});
            const specification = autovistaVinResponse.specification;
            temp = [];
            for (const prop in specification) {
                if (specification[prop] !== null
                    && prop !== 'vehicles'
                    && prop !== 'equipments'
                    && prop !== 'esacos') {
                    if (prop.indexOf('Date') !== -1) {
                        specification[prop] = this.convertDate(specification[prop]);
                    }
                    temp.push({
                        'mountPositionText': this.translateDetails('car_details.specification', prop),
                        'mountPositionId': prop,
                        'specValues': [{
                            'valueText': specification[prop],
                            'quantityText': '',
                            'qualifierText': '',
                            'additionalText': '',
                        }]
                    });
                }
            };
            details[nodeCount].specItems = temp;
            nodeCount++;
            detailTitle = this.translateDetails('car_details.vehicleSpecification', 'title');
            details.push({'name': detailTitle});
            temp = [];
            const vehicleSpec = autovistaVinResponse.specification.vehicles;
            for (const prop in vehicleSpec[0]) {
                if (vehicleSpec[0][prop] !== null
                    && prop !== 'periodOfBuildDetails') {
                    temp.push({
                        'mountPositionText': this.translateDetails('car_details.vehicle_specification', prop),
                        'mountPositionId': prop,
                        'specValues': [{
                            'valueText': vehicleSpec[0][prop],
                            'quantityText': '',
                            'qualifierText': '',
                            'additionalText': '',
                        }]
                    });
                }
            };
            details[nodeCount].specItems = temp;
            nodeCount++;
            detailTitle = this.translateDetails('car_details.equipment', 'title');
            details.push({'name': detailTitle});
            temp = [];
            let manufCode = '';
            const equipment = autovistaVinResponse.specification.equipments;
            for (const prop in equipment) {
                if (equipment[prop] !== null) {
                    if (equipment[prop]['manufCode']) {
                        manufCode = '(' + equipment[prop]['manufCode'] + ')';
                    }
                    temp.push({
                        'mountPositionText': equipment[prop]['esacos'][0]['desc'],
                        'mountPositionId': prop,
                        'specValues': [{
                            'valueText': equipment[prop]['description'],
                            'quantityText': manufCode,
                            'qualifierText': '',
                            'additionalText': '',
                        }]
                    });
                    manufCode = '';
                    for (const item in equipment[prop]['esacos']) {
                        if (parseFloat(item) > 0) {
                            temp.push({
                                'mountPositionText': equipment[prop]['esacos'][item]['desc'],
                                'mountPositionId': prop,
                                'specValues': [{
                                    'valueText': '',
                                    'quantityText': '',
                                    'qualifierText': '',
                                    'additionalText': '',
                                }]
                            })
                        }
                    }
                }
            };
            details[nodeCount].specItems = temp;
            nodeCount++;
            this.hasData = true;
            this.mainCat.carDetails.data = ' ';
            this.mainCat.carDetails.data = JSON.parse('[{"name":"' + name + '","id":99}]');
            this.mainCat.carDetails.visible = true;
            this.mainCat.carDetails.name = 'CAR_DETAILS';
            this.loadCarDetails = false;
            this.carDetails = details;
            if (this.location === 'cart') {
                this.openCatData(this.mainCat.carDetails.data[0], this.mainCat.carDetails.name);
            }
            this.technicalDataService.category.data = this.mainCat.carDetails.data;

            if (this.technicalDataService.autoOpenDetails) {
                this.technicalDataService.openCarDetailsEquipment();
                this.technicalDataService.autoOpenDetails = false;
            }
        }
    }

    public openCat(name: any) {
        this.getautoVistaResponse();

        if (name) {
            this.selectedCat = name;
            this.selectedChildCat = undefined;
            this.selectedDataCat = undefined;
            this.selectedChild = undefined;
            this.nxDetails = false;
            this.selectedItem = name.name;
            this.selectedChild = '';

            this.technicalDataService.isDiagnosis = false;
            this.technicalDataService.isCarDetails = true;
            this.technicalDataService.selectedCat = name;
            this.technicalDataService.nxDetails = false;
            this.technicalDataService.selectedChild = undefined;
            this.technicalDataService.selectedChildCat = undefined;
            this.technicalDataService.selectedItem = name.name;
            this.technicalDataService.selectedChild = '';
            this.technicalDataService.showPdf = false;
        }
    }

    public openCatData(data: any, name: string) {
        this.selectedDataCat = data;
        this.nxDetails = this.carDetails;
        this.autopdf = false;

        this.technicalDataService.selectedDataCat = data;
        this.technicalDataService.nxDetails = this.carDetails;
    }
    public translateDetails(category: string, key: string) {
        const toTransform = [category, key];
        let twoWords;
        let result = '';
        for (let i = 0; i < toTransform.length; i++) {
            twoWords = /[A-Z]/g.exec(toTransform[i]);
            if (twoWords) {
                result += toTransform[i].slice(0, twoWords.index).toUpperCase() + '_'
                    + toTransform[i].slice(twoWords.index, twoWords.index + toTransform[i].length).toUpperCase();
            } else {
                result += toTransform[i].toUpperCase();
            }
            if (i < toTransform.length - 1) {
                result += '.';
            }
        }
        return result;
    }
    public convertDate(date: string) {
        if (date.indexOf('.') === -1) {
            return date.substr(6, 2) + '.' + date.substr(4, 2) + '.' + date.substr(0, 4);
        } else {
            return date;
        }
    }

}
