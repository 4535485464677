import {Component, OnInit, Input, EventEmitter, Output, OnChanges} from '@angular/core';
import {HelperService} from '../../services/helper.service';

@Component({
    selector: 'app-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnChanges {
    @Input() public searchOpen: any;
    @Input() private searchIn: any;
    @Input() private searchString: any;
    @Input() public isWork: boolean = false;
    @Output() private searchOpenChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() private _onSearch: EventEmitter<any> = new EventEmitter<any>();

    public selectedName: string;
    public searchResult: any[];
    public active: any;

    constructor(private helperService: HelperService) {
    }

    ngOnChanges() {
        this.doSearch(this.searchString);
    }

    private selectResult(value) {
        this.active = value;
        this._onSearch.emit(value);
    }

    public selectResults(group: any, subGroup: any) {
        const value = {group, subGroup};
        this.active = value;
        this._onSearch.emit(value);
    }

    private doSearch(searchString: any) {
        const panelContent = document.getElementById('panelContent');
        if (panelContent && !this.helperService.browserIsEdge() && !this.helperService.browserIsIE()) {
            panelContent.scrollTo(0, 0)
        }
        let temp = this.helperService.recursiveDeepCopy(this.searchIn);
        this.selectedName = '';
        this.selectedName = searchString;
        if (this.selectedName && this.selectedName.length < 3) {
            this.searchResult = [];
        }
        if (this.selectedName && this.selectedName.length > 2) {
            const re = new RegExp(this.selectedName.toLowerCase().replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), 'g');
            this.searchResult = [];

            if (this.isWork) {
                let found = false;
                while (!found) {
                    found = true;
                    for(const tree of temp) {
                        for(const group of tree.groups) {
                            for(const subGroup of group.subGroups) {
                                for(let i = 0; i < subGroup.items.length; i++) {
                                    if (subGroup.items[i].mountPositionText.toLowerCase().search(re) === -1) {
                                        subGroup.items.splice(i, 1);
                                        found = false;
                                    }
                                }

                                if (subGroup.items.length === 0) {
                                    group.subGroups.splice(group.subGroups.indexOf(subGroup), 1);
                                    found = false;
                                }
                            }

                            if (group.subGroups.length === 0) {
                                tree.groups.splice(tree.groups.indexOf(group), 1);
                                found = false;
                            }
                        }
                    }
                }
                this.searchResult = temp;
            } else {
                for (const tree of this.searchIn) {
                    for (const subGroup of tree.children) {
                        if (subGroup.children && subGroup.children.length > 0) {
                            for (const item of subGroup.children) {
                                if (item.children && item.children.length > 0) {
                                    for (const item2 of item.children) {
                                        if (item2.name.toLowerCase().search(re) !== -1) {
                                            this.searchResult.push(item2);
                                        }
                                    }
                                } else {
                                    if (item.name.toLowerCase().search(re) !== -1) {
                                        this.searchResult.push(item);
                                    }
                                }
                            }
                        } else {
                            if (subGroup.name.toLowerCase().search(re) !== -1) {
                                this.searchResult.push(subGroup);
                            }
                        }
                    }
                }

                if (this.searchResult.length > 1) {
                    this.checkSearchResult();
                }
            }


            if (this.searchResult.length === 0) {
                this.searchResult = [];
            }
        }
    }

    private toggle(state) {
        this.searchOpen = state;
        this.searchOpenChange.emit(state);
    }

    // Sortiert das Ergebnis und prüft auf Dopplungen
    private checkSearchResult() {
        const temp = [];
        let found;

        for (const item of this.searchResult) {
            found = false;
            for (const tempItem of temp) {
                if (item.name === tempItem.name) {
                    found = true;
                }
            }
            if (!found) {
                temp.push(item);
            }
        }

        temp.sort(function(a, b) {
            if (a.name < b.name) {return -1;}
            if (a.name > b.name) {return 1;}
            return 0;
        });

        this.searchResult = temp;
    }

}
