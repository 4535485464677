<div class="login-wrapper">

    <h2>1. Allgemeine Information zur Erhebung personenbezogener Daten</h2>
    <p>Im Folgenden informieren wir Sie über die Erhebung personenbezogener Daten bei Nutzung unserer Website und
        wenn Sie Kontakt mit uns aufnehmen. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar
        sind, z. B. Name, Adresse, E-Mail-Adressen, Nut-zerverhalten.<br>
        Verantwortliche<br>
        Der Internetauftritt der N4 Group ist ein Angebot aller verbundenen Unternehmen. Gemein-sam Verantwortliche
        gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DS-GVO) sind</p>
    <p>netRapid GmbH &amp; Co. KG, vertreten durch netRapid Ge-schäftsführungs GmbH,</p>
    <p>n-Systems GmbH &amp; Co. KG, vertreten durch n-Systems Geschäftsführungs GmbH,</p>
    <p>nexnova Business GmbH &amp; Co. KG, vertreten durch nexnova Geschäftsführungs GmbH,</p>
    <p>nexnova Software GmbH &amp; Co. KG, vertreten durch nexnova Geschäftsführungs GmbH</p>
    <p>gesetzlicher Vertreter: Geschäftsführer jeweils Mesut Cengiz</p>
    <p>Gemeinsame Anschrift Kaiserstraße 101a<br>
        66133 Saarbrücken<br>
        E-Mail-Kontakt info@n4group.de</p>
    <h3>Datenschutzbeauftragter</h3>
    <p>RA Stefan Wiesen<br>
        datenschutz@ra-wiesen.de oder unter unserer Anschrift mit dem Zusatz „Der Datenschutzbeauftragte“</p>
    <h3>Einfache Kontaktaufnahme</h3>
    <p>Bei Ihrer Kontaktaufnahme mit uns per E-Mail, per Telefon, per Telefax, über ein Kontaktformular oder eine
        Rückrufbitte werden die von Ihnen mitgeteilten Daten (z.B. Ihre E-Mail-Adresse, Ihr Name, Ihre
        Telefonnummer) von uns gespeichert, um Ihre Fra-gen zu beantworten bzw. Ihr Anliegen zu bearbeiten.
        Rechtsgrundlagen hierfür sind Art. 6 Abs. 1 S. 1 lit. a, b und f DS-GVO, d.h. die Verarbeitung beruht auf
        Ihrer frei-willig erteilten Einwilligung oder ist erforderlich zur Durchführung vorvertraglicher Maßnah-men
        oder zur Wahrung von berechtigten Interessen. Die in diesem Zusammenhang anfal-lenden Daten löschen wir,
        nachdem die Speicherung nicht mehr erforderlich ist, oder schrän-ken die Verarbeitung ein, falls eine
        Löschung wegen bestehender Aufbewahrungspflichten noch nicht in Betracht kommt.</p>

    <p>Sicherheit
    </p>
    <p>Unser Internetauftritt nutzt aus Sicherheitsgründen und zum Schutz der Übertragung ver-traulicher Inhalte,
        die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
        erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem
        Schloss-Symbol in Ihrer Brow-serzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
        Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden. Wir weisen gleichwohl darauf hin,
        dass die Datenübertragung im Internet (insbesondere bei der Kommunikation per E-Mail) Sicherheitslücken
        aufweisen kann.</p>
    <h3>2. Ihre Rechte</h3>
    <p>Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezoge-nen Daten:<br>
        Recht auf Auskunft gemäß Art. 15 DSGVO<br>
        Sie haben insbesondere ein Recht auf Auskunft über Ihre von uns verarbeiteten personenbe-zogenen Daten, die
        Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezo-genen Daten, die Empfänger oder
        Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante
        Speicherdauer bzw. die Kriterien für die Fest-legung der Speicherdauer, das Bestehen eines Rechts auf
        Berichtigung, Löschung, Einschrän-kung der Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei
        einer Auf-sichtsbehörde, die Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wur-den, das
        Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftige
        Informationen über die involvierte Logik und die Sie betreffende Trag-weite und die angestrebten
        Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien gemäß Art. 46
        DSGVO bei Weiterleitung Ihrer Daten in Drittländer bestehen;</p>
    <h3>Recht auf Berichtigung oder Löschung gemäß Art. 16, 17 DSGVO</h3>
    <p>Sie haben ein Recht auf unverzügliche Berichtigung Sie betreffender unrichtiger Daten und/oder
        Vervollständigung Ihrer bei uns gespeicherten unvollständigen Daten, und Sie ha-ben das Recht, die Löschung
        Ihrer personenbezogenen Daten bei Vorliegen der Vorausset-zungen des Art. 17 Abs. 1 DSGVO zu verlangen.
        Dieses Recht besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur Ausübung des Rechts auf freie
        Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des
        öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
        erforderlich ist;</p>
    <h3>Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO</h3>
    <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange
        die von Ihnen bestrittene Richtigkeit Ihrer Daten überprüft wird, wenn Sie eine Löschung Ihrer Daten wegen
        unzulässiger Datenverarbeitung ablehnen und stattdessen die Einschränkung der Verarbeitung Ihrer Daten
        verlangen, wenn Sie Ihre Daten zur Gel-tendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
        benötigen, nachdem wir diese Daten nach Zweckerreichung nicht mehr benötigen oder wenn Sie Widerspruch aus
        Gründen Ihrer besonderen Situation eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten
        Gründe überwiegen;</p>
    <h3>Recht auf Widerspruch gegen die Verarbeitung</h3>
    <p>Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung stützen, können Sie
        Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur
        Erfüllung eines Vertrags mit Ihnen erforder-lich ist. Bei Ausübung eines solchen Widerspruchs bitten wir um
        Darlegung der Gründe, wes-halb wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten
        sollten. Im Falle Ihres begründeten Widerspruchs werden wir entweder die Datenverarbeitung ein-stellen bzw.
        anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung
        fortführen.</p>
    <p>Soweit wir uns bei der Verarbeitung ihrer personenbezogenen Daten auf Art.6 Abs.1 lit.f DSGVO als
        Rechtsgrundlage berufen, kommen insbesondere folgende berechtigte Interessen in Betracht:<br>
        Beantwortung von Anfragen, Durchführung von Direktmarketingmaßnahmen, die Bereitstel-lung von Diensten
        und/oder Informationen, die für Sie bestimmt sind, Verarbeitung und Übertragung von personenbezogenen Daten
        für interne bzw. administrative Zwecke, Betrieb und Verwaltung unserer Website, technischer Support der
        Nutzer, Vermeidung und Aufde-ckung von Betrugsfällen und Straftaten, Gewährleistung der Netzwerk- und
        Datensicherheit.<br>
        Selbstverständlich können Sie auch der Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und
        Datenanalyse jederzeit widersprechen. Über Ihren Wer-bewiderspruch können Sie uns unter den oben angegebenen
        Kontaktdaten informieren.<br>
        Recht auf Datenübertragbarkeit</p>
    <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
        automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, ma-schinenlesbaren Format
        aushändigen zu lassen. Sofern Sie die direkte Übertragung der Da-ten an einen anderen Verantwortlichen
        verlangen, erfolgt dies nur, soweit es technisch machbar ist.<br>
        Widerruf einer erteilten Einwilligung<br>
        Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese jederzeit
        widerrufen. Ein solcher Widerruf beeinflusst die Zulässigkeit der Verarbeitung Ihrer personenbezogenen
        Daten, nachdem Sie ihn gegenüber uns ausgesprochen haben.<br>
        Beschwerderecht<br>
        Schließlich haben Sie das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verar-beitung Ihrer
        personenbezogenen Daten durch uns zu beschweren. Die für uns zuständige Aufsichtsbehörde ist das Unabhängige
        Datenschutzzentrum Saarland, Fritz-Dobisch-Straße 12, 66111 Saarbrücken.</p>
    <h2>3. Erhebung von Daten bei Besuch unserer Website</h2>
    <h3>Technische Daten</h3>
    <p>Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren oder uns anderweitig
        Informationen übermitteln, erheben wir die personenbezogenen Da-ten, die Ihr Browser an unseren Server
        übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die folgenden Daten, die für uns
        technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu
        gewährleisten:<br>
        – IP-Adresse<br>
        – Datum und Uhrzeit der Anfrage<br>
        – Zeitzonendifferenz zur Greenwich Mean Time (GMT)<br>
        – Inhalt der Anforderung (konkrete Seite)<br>
        – Zugriffsstatus/HTTP-Statuscode<br>
        – jeweils übertragene Datenmenge<br>
        – Website, von der die Anforderung kommt<br>
        – Browser<br>
        – Betriebssystem und dessen Oberfläche<br>
        – Sprache und Version der Browsersoftware.<br>
        Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO.</p>
    <h3>Cookies</h3>
    <p>Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website <strong>Cookies</strong> auf
        Ihrem Rechner gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem
        von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie
        setzt (hier durch uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder
        Viren auf Ihren Computer über-tragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und
        effektiver zu machen.</p>
    <p>Einsatz von Cookies:</p>
    <p>a) Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im Folgenden erläutert
        werden:<br>
        – Transiente Cookies (dazu b)<br>
        – Persistente Cookies (dazu c).</p>
    <p>b) Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere
        die Session-Cookies. Diese speichern eine sogenannte Sessi-on-ID, mit welcher sich verschiedene Anfragen
        Ihres Browsers der gemeinsamen Sit-zung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn
        Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich aus-loggen oder den
        Browser schließen.</p>
    <p>c) Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie
        unterscheiden kann. Sie können die Cookies in den Sicherheits-einstellungen Ihres Browsers jederzeit
        löschen.</p>
    <p>d) Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme von
        Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle
        Funktionen dieser Website nutzen können.</p>
    <p>e) Ggf. setzen wir Cookies ein, um Sie für Folgebesuche identifizieren zu können, falls Sie über einen
        Account bei uns verfügen. Andernfalls müssten Sie sich für jeden Besuch erneut einloggen.]</p>
    <p>f) Die ggf. genutzten Flash-Cookies werden nicht durch Ihren Browser erfasst, sondern durch Ihr
        Flash-Plug-in. Weiterhin nutzen wir HTML5 storage objects, die auf Ihrem Endgerät abgelegt werden. Diese
        Objekte speichern die erforderlichen Daten unabhän-gig von Ihrem verwendeten Browser und haben kein
        automatisches Ablaufdatum. Wenn Sie keine Verarbeitung der Flash-Cookies wünschen, müssen Sie ein
        entspre-chendes Add-On installieren, z. B. „Better Privacy“ für Mozilla Firefox
        (https://addons.mozilla.org/de/firefox/addon/betterprivacy/) oder das Adobe-Flash-Killer-Cookie für Google
        Chrome. Die Nutzung von HTML5 storage objects können Sie verhindern, indem Sie in Ihrem Browser den privaten
        Modus einsetzen. Zudem emp-fehlen wir, regelmäßig Ihre Cookies und den Browser-Verlauf manuell zu
        löschen.</p>
    <h3>Erhebung von Daten durch Kontaktformular</h3>
    <p>Auf unserer Webseite ist ein Kontaktformular eingebunden. Machen Sie von dieser Möglichkeit Gebrauch, so
        werden die in der Eingabemaske eingegebenen Daten an uns übermittelt und gespeichert. Diese Daten sind:
        Name, Adresse, Mailadresse, Telefonnummer, etc. Im Zeitpunkt der Absendung der Nachricht werden zudem
        folgende Daten gespeichert: Die IP-Adresse, Datum und Uhrzeit. Für die weitere Verarbeitung der Daten wird
        beim Absenden Ihre Einwilligung eingeholt und auf diese Datenschutzerklärung verwiesen.</p>
    <p>Alternativ ist eine Kontaktaufnahme auch über die bereitgestellte E-Mail-Adresse möglich. In diesem Fall
        werden die übermittelten personenbezogenen Daten gespeichert. Es erfolgt in diesem Zusammenhang keine
        Weitergabe der Daten an Dritte. Die Daten werden aus-schließlich für die Verarbeitung der Konversation
        verwendet.</p>
    <p><strong>Rechtsgrundlage</strong> für die Verarbeitung von Daten, die per Kontaktformular oder E-Mail
        übermittelt werden ist Art.6 Abs.1 lit.f DSGVO bzw. Art.6 Abs.1 lit.b DSGVO, wenn die Kontaktaufnahme auf
        den Abschluss eines Vertrages abzielt.</p>
    <p>Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient uns allein zur Bearbeitung der
        Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte
        Interesse an der Verarbeitung der Daten. Die sonstigen ggf. während des Absendens verarbeiteten
        personenbezogenen Daten dienen dazu, einen Missbrauch des Kontaktformulars zu verhindern und die Sicherheit
        unserer informations-technischen Systeme sicherzustellen.</p>
    <p>Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich
        sind. Für die personenbezogenen Daten aus der Eingabemaske des Kon-taktformulars und diejenigen, die per
        E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist.
        Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene
        Sachverhalt abschließend geklärt ist. Die während des Absendens zusätzlich erhobenen personenbezogenen
        Da-ten werden spätestens nach einer Frist von sieben Tagen gelöscht.<br>
        Willigen Sie in die Weitergabe Ihrer Daten zum Zwecke der Betreuung und Werbung ein, haben Sie jederzeit die
        Möglichkeit, Ihre Einwilligung zu widerrufen.</p>
    <h2>4. Webanalyse und Online Werbung<p></p>
    </h2>
    <h3>Google Analytics</h3>
    <p>Diese Website benutzt <strong>Google Analytics</strong>, einen Webanalysedienst der Google Inc. („Google“).
        Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die
        eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen
        über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und
        dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse
        von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
        Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
        IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers
        dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports
        über die Website-Aktivitäten zusammenzustellen und um weitere mit der Website-Nutzung und der
        Internet-nutzung verbundene Dienstleistungen gegenüber dem Website-Betreiber zu erbringen.</p>
    <p>(2) Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten
        von Google zusammengeführt.</p>
    <p>(3) Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
        verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
        Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der
        durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
        Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link
        verfüg-bare Browser-Plug-in herunterladen und installieren: <a
                href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>
        .</p>
    <p>(4) Diese Website verwendet Google Analytics mit der Erweiterung „_anonymizeIp()“. Dadurch werden IP-Adressen
        gekürzt weiterverarbeitet, eine Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den über Sie
        erhobenen Daten ein Personenbezug zu-kommt, wird dieser also sofort ausgeschlossen und die personenbezogenen
        Daten damit umgehend gelöscht.</p>
    <p>(5) Wir nutzen Google Analytics, um die Nutzung unserer Website analysieren und regelmäßig verbessern zu
        können. Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer
        interessanter ausgestalten. Für die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen
        werden, hat sich Google dem EU-US Privacy Shield unterworfen, <a
                href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.
        Rechtsgrundlage für die Nutzung von Google Analytics ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO.</p>
    <p>(6) Informationen des Drittanbieters: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin
        4, Ireland, Fax: +353 (1) 436 1001. Nutzerbedingungen: <a
                href="http://www.google.com/analytics/terms/de.html">http://www.google.com/analytics/terms/de.html</a>
        , Übersicht zum Datenschutz: <a href="http://www.google.com/intl/de/analytics/learn/privacy.html">http://www.google.com/intl/de/analytics/learn/privacy.html</a>
        , sowie die Datenschutzerklärung: <a href="http://www.google.de/intl/de/policies/privacy">http://www.google.de/intl/de/policies/privacy</a>
        .</p>
    <p>(7) Diese Website verwendet Google Analytics zudem ggf. für eine geräteübergreifende Analyse von
        Besucherströmen, die über eine User-ID durchgeführt wird. Sie können in Ihrem Kundenkonto unter „Meine
        Daten“, „persönliche Daten“ die geräteübergreifende Analyse Ihrer Nutzung deaktivieren.</p>
    <h2>5. Weitere Tools</h2>
    <h3>Google Web Fonts</h3>
    <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google
        bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
        Browsercache, um Texte und Schriftarten korrekt anzuzeigen. Zu diesem Zweck muss der von Ihnen verwendete
        Browser Verbindung zu den Servern von Google auf-nehmen. Hierdurch erlangt Google Kenntnis darüber, dass
        über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse
        einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein be-rechtigtes
        Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Wenn Ihr Browser Web Fonts nicht unterstützt, wird
        eine Standardschrift von Ihrem Computer genutzt. Weitere In-formationen zu Google Web Fonts finden Sie unter
        <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> und in der
        Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.
    </p>
    <h3>Google Maps</h3>
    <p>Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc., 1600 Amphitheatre
        Parkway, Mountain View, CA 94043, USA. Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP
        Adresse zu speichern. Diese Informationen wer-den in der Regel an einen Server von Google in den USA
        übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung
        Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und
        an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes
        Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Mehr Informationen zum Umgang mit Nutzerdaten finden
        Sie in der Datenschutzerklärung von Google: <a href="https://www.google.de/intl/de/policies/privacy/">https://www.google.de/intl/de/policies/privacy/</a>.
    </p>
    <h3>6. Weitergabe von Daten</h3>
    <p>Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den vorstehend genann-ten Zwecken findet
        nicht statt. Wir geben – zusammenfassend – Ihre persönlichen Daten nur an Dritte weiter, wenn Sie hierzu
        nach Art. 6 Abs. 1 S. 1 lit. a DSGVO Ihre Einwilligung erteilt haben, dies nach Art. 6 Abs. 1 S. 1 lit. b
        DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist, dies zur Wahrung unserer
        berechtigten Interessen nach Art. 6 Abs. 1 S. 1 lit. f DSGVO (z.B. zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprü-chen oder im Rahmen von Werbemaßnahmen) erforderlich ist und kein Grund zur
        Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten
        haben oder wenn eine gesetzliche Verpflichtung nach Art. 6 Abs. 1 S. 1 lit. c DSGVO zur Weitergabe
        besteht.</p>
    <h3>7. Aktualität und Änderung dieser Datenschutzerklärung</h3>
    <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai 2018, sie unterliegt aber der stetigen
        Weiterentwicklung. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf unserem Internetauftritt
        abgerufen werden.</p>
</div>

<app-auth-footer></app-auth-footer>
