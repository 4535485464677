<div class="maintenance-data" [ngClass]="{'isPagination': !userService.enableInfiniteScroll}">
    <div class="heading blue">
        <span routerLink="/maintenance"
              (click)="maintenanceService.selectedArticle = undefined; maintenanceService.lastState = ''"
              class="clickable"><i class="fa fa-th" aria-hidden="true"></i></span>
        <span routerLink="/maintenance" class="clickable"
              (click)="maintenanceService.showDocumentWithLink = undefined">{{'CLUB_SETTINGS.NAVIGATION.MAINTENANCE' | translate}}</span>
        <span routerLink="/maintenance/plan" class="clickable"
              (click)="maintenanceService.showDocumentWithLink = undefined"><i
                class="fa fa-caret-right"></i>{{'MAINTENANCE.MAIN_WORK' | translate}}
        </span>

        <div class="articleSizeWrapper d-inline-block float-right" *ngIf="!maintenanceService.showAdditionalWork">
            <div class="articleSize" *ngIf="maintenanceService?.searchResult?.items"
                 (click)="showArticleSize = !showArticleSize">
                <i class="fa fa-list"></i>
                <span class="mr-2" *ngIf="articlesService.selectedSearchResultSize">
                                {{'GENERAL.' + articlesService.selectedSearchResultSize.toUpperCase() | translate}}
                            </span>
                <span clasS="mr-2" *ngIf="!articlesService.selectedSearchResultSize">
                                {{'GENERAL.PLEASE_SELECT' | translate | ellipsis: 10}}
                            </span>
            </div>
            <div class="dropdown-list" *ngIf="showArticleSize">
                <ul>
                    <li (click)="setArticleSize('big')">
                        <span>{{'GENERAL.BIG' | translate}}</span>
                    </li>
                    <li (click)="setArticleSize('normal')">
                        <span>{{'GENERAL.NORMAL' | translate}}</span>
                    </li>
                    <li (click)="setArticleSize('small')">
                        <span>{{'GENERAL.SMALL' | translate}}</span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="searchResultSupplierWrapper d-inline-block float-right"
             *ngIf="!maintenanceService.showAdditionalWork">
            <div class="searchResultSupplier"
                 *ngIf="userService?.suppliers?.length > 0 && articlesService.selectedSearchResultSupplier
                             && maintenanceService.searchResult?.items"
                 (click)="showSearchResultSupplier = !showSearchResultSupplier">
                <i class="fa fa-truck"></i>
                <!-- AFTER MARKET -->
                <span class="mr-2" *ngIf="maintenanceService.selectedSearchResultSupplier">
                               {{helperService.returnSupplierName(articlesService.selectedSearchResultSupplier, userService.getRegularSuppliersName(userService.suppliers)) | ellipsis: 10}}
                            </span>
                <span class="mr-2" *ngIf="!maintenanceService.selectedSearchResultSupplier">
                                {{'GENERAL.PLEASE_SELECT' | translate | ellipsis: 10}}
                            </span>
            </div>
            <div class="dropdown-list" *ngIf="showSearchResultSupplier">
                <!-- AFTER MARKET -->
                <ng-container *ngIf="articlesService.supplierType === 'AFTER_MARKET'">
                    <ul>
                        <ng-container *ngFor="let supp of userService.getRegularSuppliersName(userService.suppliers)">
                            <li *ngIf="supp !== maintenanceService.selectedSearchResultSupplier"
                                (click)="setSearchResultSupplier('AFTER_MARKET', supp.code)">
                                <span>{{supp.name}}</span>
                            </li>
                        </ng-container>
                    </ul>
                </ng-container>
            </div>
        </div>

        <span *ngIf="maintenanceService?.showDocumentWithLink">

                    <i class="fa fa-caret-right"></i>
                    <span>{{maintenanceService?.showDocumentWithLink?.text}}</span>

                    <button class="print-btn pull-right" (click)="printIframe('maintenance-manual')">
                        <i class="fa fa-print"></i>
                        <span>{{'PRINT_VIEW.PRINT' | translate}}</span>
                    </button>

                    <button class="print-btn pull-right" (click)="maintenanceService.showDocumentWithLink = undefined">
                        <i class="fa fa-times"></i>
                        <span>{{'GENERAL.BACK' | translate }}</span>
                    </button>
                </span>

    </div>

    <div class="panel-content center-content white-background" *ngIf="maintenanceService?.showDocumentWithLink">
        <iframe width="100%" onLoad="this.style.height=(this.contentDocument.body.scrollHeight + 30) + 'px';"
                [src]="maintenanceService?.showDocumentWithLink?.url | iFrame" id="maintenance-manual"></iframe>
    </div>

    <div class="panel-content center-content" id="articleSelection" *ngIf="!maintenanceService?.showDocumentWithLink"
         [ngClass]="{'articleSelectionContent': !maintenanceService.showAdditionalWork, 'print-content': maintenanceService?.selectedOption === 'MANUFACTURER_SPEC', 'setting' : !maintenanceService.showPdf}">
        <div class="flex-container" [ngClass]="{'browserIsIE': helperService.browserIsIE()}">
            <div class="static-content" [ngClass]="{'noScrollButton': !showScrollButton}">
                <app-maintenance-header
                        *ngIf="location.path().indexOf('/maintenance/plan') > -1"
                        [hideAdditionalMaintenance]="maintenanceService?.activeMaintenanceStep
                        && maintenanceService.showAdditionalWork">
                </app-maintenance-header>

                <ng-container
                        *ngIf="!maintenanceService.showAdditionalWork && maintenanceService?.activeMaintenanceStep">
                    <app-tabs (showScrollButtonEmitter)="showScrollButton = $event" [service]="maintenanceService"
                              [articleList]="maintenanceService.articleList"></app-tabs>
                    <app-filter [service]="maintenanceService"></app-filter>
                </ng-container>
            </div>
            <div *ngIf="!tabChanged" class="scroll-content" id="scroll-content" appInfiniteScroller
                 (onScrollEnd)="maintenanceService.loadNext($event)"
                 [ngClass]="{'articlesLoading': maintenanceService.articlesLoading}">
                <ng-container *ngIf="!showAdditionalWork && activeMaintenanceStep && selectedTab">
                    <app-default-loading-page class="inline-full-height"
                                              *ngIf="maintenanceService.articlesLoading"></app-default-loading-page>
                    <app-articles-search-result *ngIf="!maintenanceService.articlesLoading"
                                                [service]="maintenanceService"></app-articles-search-result>
                    <app-no-results
                            *ngIf="!maintenanceService?.articlesLoading && maintenanceService?.searchResult?.items?.length === 0"></app-no-results>
                </ng-container>

                <!-- Hauptarbeit MAIN -->
                <div class="work-item">
                    <h1 class="heading-margin left"
                        *ngIf="!maintenanceService?.maintenanceSteps && !maintenanceService?.activeMaintenanceStep">
                        <strong>{{'MAINTENANCE.MAIN_WORK' | translate}}</strong></h1>

                    <ul class="item-list"
                        *ngIf="!maintenanceService?.maintenanceSteps && !maintenanceService?.activeMaintenanceStep"
                        [@detailAnim]="maintenanceService?.maintenance?.mdItemKorWorksDTO">

                        <ng-container
                                *ngFor="let groups of maintenanceService?.maintenance?.mdItemKorWorksDTO">
                            <li *ngFor="let step of groups?.mdWorkPos"
                                [ngClass]="{'selected': maintenanceService?.maintenanceSteps === groups || maintenanceService?.selectedItem && maintenanceService?.selectedItem === step}"
                                (click)="maintenanceService.getMaintenanceDetails(step)"
                                class="clickable">
                                <div class="row">
                                    <div class="col-1 height-20px">
                                        <app-checkbox *ngIf="cartService?.isNewestCart()" #checkBox
                                                      [isDisabled]="cartService.isOrder()"
                                                      [isChecked]="!(maintenanceService.isChecked(step) < 0)"
                                                      (onChange)="maintenanceService.toggleActiveMaintenance(step)"></app-checkbox>
                                    </div>
                                    <div class="col">
                                        <span class="item-name">{{groups.itemMpText + ' ' + groups.korText}} <b
                                                *ngIf="step.qualColId != 0">{{step.qualColText}}</b></span>
                                    </div>

                                    <div class="col-2">
                                        <span class="item-name float-right">{{step.quantity}} {{'GENERAL.VALUES.' + step.quantityUnit | translate}}</span>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                </div>

                <div class="mainWork work-item" *ngIf="maintenanceService?.maintenanceSteps">
                    <div class="heading-margin right">
                        <button class="base-btn success float-right no-top-margin"
                                *ngIf="maintenanceService.showAdditionalWork && maintenanceService?.activeMaintenanceStep"
                                (click)="maintenanceService.showArticle(); maintenanceService.selectedItem = undefined;">
                            <i class="fa fa-check"></i>
                            <span>{{'MAINTENANCE.TO_ARTICLES' | translate}}</span>
                        </button>
                    </div>

                    <!-- Hauptarbeit -->
                    <div class="mb-5 mt-3" *ngIf="maintenanceService.hasSelectedMaintenance()">
                        <h1 class="heading-margin left"><strong>{{'MAINTENANCE.MAIN_WORK_TITLE' | translate}}</strong>
                        </h1>
                        <div *ngIf="maintenanceService.hasSelectedMaintenance()" class="row m-0 border-top border-bottom py-3">
                            <div class="col-1 height-20px">
                                <app-checkbox [isChecked]="true"
                                              (onChange)="maintenanceService.toggleActiveMaintenance(maintenanceService?.activeMaintenanceStep); maintenanceService.searchResult = undefined;"></app-checkbox>
                            </div>
                            <div class="col">
                                {{ (maintenanceService?.activeMaintenanceStep ? maintenanceService.activeMaintenanceStep.qualColText : '') }}
                                {{ (maintenanceService?.maintenanceSteps ? maintenanceService?.maintenanceSteps.itemMpText + ' ' + maintenanceService?.maintenanceSteps.korText : ' ') }}
                            </div>
                            <div class="col-2"
                                 *ngIf="maintenanceService?.activeMaintenanceStep && maintenanceService?.activeMaintenanceStep.quantity">
                                <span class="item-name float-right">{{maintenanceService?.activeMaintenanceStep.quantity}} {{'GENERAL.VALUES.' + maintenanceService.activeMaintenanceStep.quantityUnit | translate}}</span>
                            </div>
                        </div>
                    </div>

                    <!-- Zusatzarbeiten -->
                    <div *ngIf="maintenanceService?.activeMaintenanceStep && maintenanceService.showAdditionalWork">
                        <h1 class="heading-margin left"><strong>{{'MAINTENANCE.ADDITIONAL_WORK' | translate}}</strong>
                        </h1>
                        <ng-container *ngIf="this.maintenanceService?.maintenance?.mdAddItemMpKorWorksDTO.length > 0">
                            <div class="padding-top heading-margin left">{{'MAINTENANCE.AVAILABLE_ADDITIONAL_WORK' | translate}}</div>
                            <ul class="item-list">
                                <ng-container
                                        *ngFor="let korWork of maintenanceService?.maintenance?.mdAddItemMpKorWorksDTO">
                                    <ng-container *ngFor="let subWork of korWork?.mdWorkPos">
                                        <li [ngClass]="{'selected' : maintenanceService?.selectedItem && maintenanceService?.selectedItem === subWork}"
                                            class="clickable"
                                            (click)="maintenanceService.getMaintenanceDetails(subWork)">
                                            <div class="row">
                                                <div class="col-1 height-20px">
                                                    <app-checkbox *ngIf="cartService?.isNewestCart()" #checkBox
                                                                  [isDisabled]="cartService.isOrder()"
                                                                  [isChecked]="!(maintenanceService.isChecked(subWork) < 0)"
                                                                  (onChange)="toggleAdditionalWork(subWork, maintenanceService.isChecked(subWork) < 0)"></app-checkbox>
                                                </div>
                                                <div class="col">
                                                    <span class="item-name">{{subWork.itemMpText + (subWork.qualColId != 0 ? ' ' + subWork.qualColText : '') + ' ' + korWork.korText}}
                                                        <small *ngIf="korWork.addText"><br/>{{korWork.addText}}</small></span>
                                                    <ng-container *ngFor="let workText of korWork.addWorkTexts">
                                                        <br/><small>- {{workText}}</small>
                                                    </ng-container>
                                                </div>
                                                <div class="col-2">
                                                    <span class="item-name float-right">{{subWork.quantity}} {{'GENERAL.VALUES.' + subWork.quantityUnit | translate}}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ng-container>
                                </ng-container>
                            </ul>
                        </ng-container>
                    </div>
                    <div class="maintenance-button-row">
                        <button class="base-btn float-right margin-right"
                                (click)="cartService.backLink = '/maintenance/plan'; maintenanceService.selectedItem = undefined;"
                                routerLink="/cart/offer"
                                *ngIf="maintenanceService.showAdditionalWork && maintenanceService?.activeMaintenanceStep && configService.allowOffers()">
                            <i class="fa fa-file-pdf-o"></i>
                            <span>{{'PROMPTS.BUTTONS.TO_OFFER' | translate}}</span>
                        </button>
                        <button class="base-btn float-right margin-right" [routerLink]="['/maintenance/info/service']"
                                [queryParams]="{ fromPlan: true}"
                                (click)="maintenanceService.selectedItem = undefined;"
                                *ngIf="maintenanceService.showAdditionalWork && maintenanceService?.activeMaintenanceStep">
                            <i class="fa fa-scribd"></i>
                            <span>{{'PROMPTS.BUTTONS.TO_SERVICEPLAN' | translate}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
