import { Component, OnInit } from '@angular/core';
import {OrderHistoryService} from '../services/order-history.service';
import {Router} from '@angular/router';
import {ArticlesService} from '../../../../articles/services/articles.service';
import {UserService} from '../../../../shared/services/user/user.service';
import {HelperService} from '../../../../shared/services/helper.service';

@Component({
  selector: 'app-order-data',
  templateUrl: './order-data.component.html',
  styleUrls: ['./order-data.component.scss']
})
export class OrderDataComponent implements OnInit {

  constructor(public orderHistoryService: OrderHistoryService,
              private router: Router,
              public articlesService: ArticlesService,
              public userService: UserService,
              public helperService: HelperService) {

    let routerUrl: string = this.router.url;
    const cartId = routerUrl.slice(routerUrl.lastIndexOf('/') + 1);
    routerUrl = routerUrl.substring(0, routerUrl.lastIndexOf('/'));
    const history = routerUrl.slice(routerUrl.lastIndexOf('/') + 1);

      if (!this.orderHistoryService.selectedHistory) {
        this.orderHistoryService.setHistory(history, cartId);
      }
  }

  ngOnInit() {
  }

  public getDeliveryCostsKeys() {
    if (this.orderHistoryService.cartData && this.orderHistoryService.cartData.cartCostDTO
        && this.orderHistoryService.cartData.cartCostDTO.deliveryCosts && !this.userService.sale) {
      return Object.keys(this.orderHistoryService.cartData.cartCostDTO.deliveryCosts);
    } else {
      return [];
    }
  }

}
