import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ConfigService} from '../../../../shared/services/config.service';
import {UserService} from '../../../../shared/services/user/user.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {HelperService} from '../../../../shared/services/helper.service';

@Component({
  selector: 'app-clublogo-config',
  templateUrl: './clublogo-config.component.html'
})
export class ClublogoConfigComponent implements OnInit {

  public imgUrl: any;
  public form: UntypedFormGroup;

  @Input('src') public imageSource: string;
  @ViewChild('fileInput', {static: true}) public fileInput: ElementRef;
  @ViewChild('canvas', {static: true}) public canvasElement: ElementRef;
  constructor(public configService: ConfigService,
              public userService: UserService,
              private helperService: HelperService,
              public fb: UntypedFormBuilder) {
    this.createForm();
  }

  ngOnInit() {
  }

  public onFileChange(event) {
    this.imgUrl = '';


    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileTypes = ['jpg', 'jpeg', 'png'];
      const extension = file.name.split('.').pop().toLowerCase(),
          isSuccess = fileTypes.indexOf(extension) > -1;
      if (isSuccess) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.form.get('avatar').setValue({
            filename: file.name,
            filetype: file.type,
            value: reader.result.toString().split(',')[1]
          });
          if (reader.result) {
            this.imgUrl = reader.result;
        }};
      } else {
        this.helperService.showNotification('TOAST_MESSAGES.ERROR_INVALID_FILETYPE', 'error');
      }
    }
  }

  public createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      avatar: null
    });
  }


}
