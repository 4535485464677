import {Component, OnInit} from '@angular/core';
import {GlobalAdminService} from '../../../services/global-admin.service';

@Component({
    selector: 'app-transferlog-grip-supplier',
    templateUrl: './transferlog-grip-supplier.component.html',
    styleUrls: ['./transferlog-grip-supplier.component.scss']
})
export class TransferlogGripSupplierComponent implements OnInit {


    constructor(public globalAdminService: GlobalAdminService) {
    }

    ngOnInit() {
    }

    public onEvent(event: any) {
        event.stopPropagation();
    }
}
