<div class="heading blue">
    <span class="clickable" routerLink="/admin">
        <i class="fa fa-th" aria-hidden="true"></i>
         {{'ADMIN_PANEL.OVERVIEW' | translate}}
    </span>
    <span class="clickable" (click)="back()">
          <i class="fa fa-caret-right"></i>
          {{ 'USER_PROFILE.SUPPLIER_MANAGEMENT' | translate }}
      </span>
    <span *ngIf="adminService.supplier.supplier != '' ">
        <i class="fa fa-caret-right"></i>
        {{adminService.supplier.supplier}}
    </span>
</div>
<div class="panel-content center-content white-background">
  <div class="edituser">
      <app-firm-supplier
              [_supplier]="adminService.supplier"
              [unassignedSupplier]="unassignedSupplier"
              [firmId]="adminService.selectedFirm?.firmId"
              (_back)="back()"
              (_save)="save($event)"></app-firm-supplier>
  </div>
</div>
