import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IPartnerPageCollection} from '../interfaces/partnerPage';

@Injectable()
export class PartnerRestService {
    constructor(private http: HttpClient) {}

    public getPartner(): Observable<IPartnerPageCollection> {
        return this.http.get<IPartnerPageCollection>(
            `${environment.apiUrl}/partner`
        );
    }

    public getPartnerById(id: string, clubId: number): Observable<IPartnerPageCollection> {
        if (id.trim() !== '') {
            return this.http.get<IPartnerPageCollection>(
                `${environment.apiUrl}/admin/partner?clubId=${clubId}&partnerEntryId=${id}`
            );
        }

        if (id.trim() === '') {
            return this.http.get<IPartnerPageCollection>(
                `${environment.apiUrl}/admin/partner?clubId=${clubId}`
            );
        }
    }

    public changePartnerRest(partnerPage: IPartnerPageCollection) {
        return this.http.post(
            `${environment.apiUrl}/admin/partner/change`,
            partnerPage
        )
    }
}
