import {Component, Input, OnInit} from '@angular/core';
import {IArticlesWorkId} from "../../../../articles/interfaces/articlesWorkId";

@Component({
  selector: 'app-article-dashboard',
  templateUrl: './article-dashboard.component.html'
})
export class ArticleDashboardComponent implements OnInit {

    @Input() public service: any;
    public byWorkIdTimer: number;
    private articles: IArticlesWorkId = {adcTypeId: 0,  workId: 0, pageSize: 0, linkingTargetType: '' , itemMpId: 0, kTypeId: 0, page: 1};
  constructor() { }

  ngOnInit() {
  }
    public getArticles(articlesPos: any) {
        this.articles.kTypeId = this.service.kTypeId;
        this.articles.adcTypeId = this.service.adcTypeId;
        this.articles.itemMpId = articlesPos.mountPositionId;
        this.articles.linkingTargetType = 'P';
        this.articles.pageSize = 20;
        this.articles.page = 1;
        this.articles.workId = articlesPos.workId;

        const aktTime: number = new Date().getTime();
        if (!this.byWorkIdTimer || aktTime - this.byWorkIdTimer > 500) {
            this.byWorkIdTimer = aktTime;
            this.service.updateArticlesByWorkId(this.articles);
        }
        this.service.selectOption('ARTIKEL');
    }
}
