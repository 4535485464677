import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {IVinCarWrapper} from '../../../interfaces/DTO/car/vinSearchResponse';
import {UserService} from '../../../services/user/user.service';
import { CarService } from '../../../services/car/car.service';

@Component({
  selector: 'app-vin-search-wrapper',
  templateUrl: './vin-search-wrapper.component.html',
  styleUrls: ['./vin-search-wrapper.component.scss']
})
export class VinSearchWrapperComponent implements OnInit {

  @Output() applyCars: EventEmitter<any> = new EventEmitter();
  @Output() selectVinCar: EventEmitter<IVinCarWrapper> = new EventEmitter();
  @Input() _vin: any;
  @Input() catalog: boolean = false;

  constructor(public configService: ConfigService,
              public userService: UserService,
              public carService: CarService) { }

  ngOnInit() {
  }

  public isActiveVin(VINType: string) {
    const setting = this.configService.getSubSettingByName('CAR_SELECTION', 'VIN');

    if (setting) {
      if (this.carService.dmsNoCatalog) {
        return setting.clubSettingListDTOS['DMS'].selected === VINType;
      } else {
        return setting.clubSettingListDTOS['STANDARD'].selected === VINType;
      }
    }

    return false;
  }
}
