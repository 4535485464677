import { Component, OnInit } from '@angular/core';
import {CartService} from '../services/cart.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-custom-comment',
  templateUrl: './add-custom-comment.component.html',
  styleUrls: ['./add-custom-comment.component.scss']
})
export class AddCustomCommentComponent implements OnInit {

  public comment: string = '';
  public maxLength: number = 509;

  constructor(public cartService: CartService,
              private router: Router) { }

  ngOnInit() {
    if (this.cartService.currentCart) {
      this.comment = this.cartService.currentCart.comment;
    }
  }

  public save() {
    this.cartService.currentCart.comment = this.comment;
    this.cartService.setComment(this.comment);
    this.router.navigate(['/cart']);
  }

  public delete() {
    this.cartService.currentCart.comment = '';
    this.cartService.setComment('');
    this.router.navigate(['/cart']);
  }
}
