<div class="global-country-config">
    <div class="heading blue">
        <span class="clickable" style="cursor: pointer;" routerLink="/global-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <span class="clickable">
            <i class="fa fa-caret-right"></i>
            {{'GLOBAL_ADMIN.COUNTRY_CONFIG.TITLE' | translate}}
        </span>
        <span class="add-country clickable" routerLink="edit" (click)="createNewCountry()">
            <i class="fa fa-plus mr-2"></i>
            <span>{{'GLOBAL_ADMIN.COUNTRY_CONFIG.ADD_COUNTRY' | translate}}</span>
        </span>
    </div>
    <div class="global-notifications-content panel-content center-content white-background">
        <h1>{{'GLOBAL_ADMIN.COUNTRY_CONFIG.TITLE' | translate}}</h1>

        <div class="row country-row" *ngFor="let country of countryList">
            <div class="col-2">{{country.iso31661Numeric}}</div>
            <div class="col-3">{{country.name}}</div>
            <div class="col-3">{{'GLOBAL_ADMIN.COUNTRY_CONFIG.VAT' | translate}} {{country.vat}} %</div>
            <div class="col-2">{{country.currency}}</div>
            <div class="col-2 country-options text-center" (click)="selectCountry(country)"><i class="fa fa-pencil"></i></div>
        </div>
    </div>
</div>
