import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {CarService} from '../../../services/car/car.service';
import {UserService} from '../../../services/user/user.service';

@Component({
  selector: 'app-numberplate-search-wrapper',
  templateUrl: './numberplate-search-wrapper.component.html',
  styleUrls: ['./numberplate-search-wrapper.component.scss']
})
export class NumberplateSearchWrapperComponent implements OnInit {

  @Output() applyCars: EventEmitter<any> = new EventEmitter();
  @Input() numberplate: string;

  constructor(public configService: ConfigService,
              public userService: UserService,
              public carService: CarService) { }

  ngOnInit() {
  }

  public isActiveNumberplate(numberplateType: string) {
    const setting = this.configService.getSubSettingByName('CAR_SELECTION', 'NUMBERPLATE');

    if (setting) {
      if (this.carService.dmsNoCatalog) {
        return setting.clubSettingListDTOS['DMS'].selected === numberplateType;
      } else {
        return setting.clubSettingListDTOS['STANDARD'].selected === numberplateType;
      }
    }

    return false;
  }

}
