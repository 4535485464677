import {Component, OnInit} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {UserService} from '../../../../shared/services/user/user.service';
import {ConfigService} from '../../../../shared/services/config.service';
import {ICountry} from '../../../../shared/interfaces/DTO/firm/country';

@Component({
    selector: 'app-country-config',
    templateUrl: './country-config.component.html'
})
export class CountryConfigComponent implements OnInit {
    public possibleCountrys: any;
    public countrysLoaded = false;
    public defaultCountry: ICountry;
    public clubId: number;
    public newDefault: any;

    constructor(private adminService: AdminService,
                public configService: ConfigService,
                private userService: UserService) {
        this.defaultCountry = {} as ICountry;
    }

    ngOnInit() {
        this.userService.getCountries(this.adminService.getClubId()).subscribe(
            (response) => {
                this.possibleCountrys = response;
                this.countrysLoaded = true;
            }
        );

        this.userService.getDefaultCountry(this.adminService.getClubId()).subscribe(
            (response) => {
                this.defaultCountry = response;
            }
        );

    }

    public toggleDefault(id) {
        const countryId = parseInt(id);
        this.configService.addUnsavedSetting(this.configService.selectedSettingName);
        this.possibleCountrys.forEach((possible) => {
            if (possible.id === countryId) {
                this.newDefault = possible;
            }
        });
    }


}
