import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-default-loading-page',
  templateUrl: './default-loading-page.component.html'
})
export class DefaultLoadingPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
