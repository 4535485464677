

  <div class="assembly-categories">
      <ng-container *ngFor="let pos of service?.posByCar">

          <div class="assembly-category" *ngFor="let article of pos.partGroup" (click)="getArticles(article)">
              <div class="name-wrapper-no-image">
                  <span>{{article.mountPositionText}}</span>
              </div>
          </div>

      </ng-container>
  </div>
