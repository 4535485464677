import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
    selector: '[tooltip]',
})

export class TooltipDirective {

    @Input() tooltipContent: string;
    @Input() tooltipClass: string;
    @Input() tooltipPosition: string;

    private container: any;
    private tooltipText: any;

    constructor(private el: ElementRef,
                private renderer: Renderer2) {

    }

    @HostListener('mouseenter') onMouseEnter() {
        this.container = this.renderer.createElement('div');

        this.tooltipText = this.renderer.createElement('div');
        this.tooltipText.className = 'ui-tooltip-text';
        this.tooltipText.innerHTML = this.tooltipContent;

        this.container.appendChild(this.tooltipText);

        this.container.className = 'ui-tooltip';

        if (this.tooltipPosition) {
            this.container.className = 'ui-tooltip ' + this.tooltipPosition;
        } else {
            this.container.className = 'ui-tooltip center';
        }

        if (this.tooltipClass) {
            this.container.className = 'ui-tooltip ' + this.tooltipClass;
        }
        this.renderer.appendChild(this.el.nativeElement, this.container);
    }

    @HostListener('mouseleave') onMouseLeave() {
       this.renderer.removeChild(this.el.nativeElement, this.container);
    }
}
