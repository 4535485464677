import {Injectable} from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {PdfService} from './pdf.service';
import {UserService} from './user/user.service';
import {ITyreType} from "../../technical-data/interfaces/Tyre";
import {TranslateService} from '@ngx-translate/core';
import {IVehicleSpecSubGroup} from '../interfaces/DTO/car/vehicleSpecSubGroup';
import {StateService} from './state.service';
import {BehaviorSubject} from "rxjs";
import {TyreRestService} from "../rest-services/tyre.rest.service";
import {ITyre} from "../interfaces/DTO/tyre/tyre";
import {HelperService} from "./helper.service";
import {ICar} from "../interfaces/DTO/car/car";

@Injectable()
export class TyreService {

    public tyreTypes$: BehaviorSubject<ITyreType[]> = new BehaviorSubject<ITyreType[]>([]);
    public tyreSpecs$: BehaviorSubject<IVehicleSpecSubGroup[]> = new BehaviorSubject<IVehicleSpecSubGroup[]>([]);
    public selectedTyreType$: BehaviorSubject<ITyreType> = new BehaviorSubject<ITyreType>(undefined);
    public tyres$: BehaviorSubject<ITyre[]> = new BehaviorSubject<ITyre[]>([]);
    public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public pdf$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

    constructor(public pdfService: PdfService,
                private userService: UserService,
                private tyreRestService: TyreRestService,
                private stateService: StateService,
                private helperService: HelperService,
                private translate: TranslateService) {

        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }

    public resetTyres(): void {
        this.tyres$.next([]);
    }

    public resetSelectedTyreType(): void {
        this.selectedTyreType$.next(undefined);
    }

    public resetPDF(): void {
        this.pdf$.next(undefined);
    }

    public getTyreTypes(adcTypeId: number): void {
        this.tyreRestService.getTyreTypesRest(adcTypeId).subscribe({
            error: (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_TYRE_INFO', 'error');
                if (error.status === 502) {
                    this.stateService.changeTyreContent('LOCKED');
                }
            },
            next: (tyreTypes: ITyreType[]) => {
                this.tyreTypes$.next(tyreTypes);
            }
        });
    }

    public getTyres(adcTypeId: number): void {
        this.loading$.next(true);
        this.tyreRestService.getTyresRest(adcTypeId).subscribe({
            error: () => {
                this.loading$.next(false);
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_TYRE_INFO', 'error');
            },
            next: (tyres: ITyre[]) => {
                this.tyres$.next(tyres);
                this.loading$.next(false);
            }
        });
    }

    public getTyreSpecs(adcTypeId) {
        if (this.userService.allow('TYRE')) {
            this.loading$.next(true);
            this.tyreRestService.getTyreSpecsRest(adcTypeId).subscribe(
                (vehicleSpecSubGroup: IVehicleSpecSubGroup[]) => {
                    this.tyreSpecs$.next(vehicleSpecSubGroup);
                    this.loading$.next(false);
                },
                () => {
                    this.loading$.next(false);
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_TYRE_INFO', 'error');
                }
            );
        } else {
            this.tyreSpecs$.next([]);
        }
    }

    public generatePdf(tyreDetails: boolean, selectedTyreType: ITyreType, tyreSpecs: IVehicleSpecSubGroup[], selectedCar: ICar): Promise<any> {
        let docDefinition: any;

        if (!tyreDetails) {
            docDefinition = this.pdfService.buildDocDefinition(false, selectedCar);
            const details= tyreSpecs;
            let bodyElements: any[] = [];

            for (let i = 0; i < details.length; i++) {

                docDefinition.content.push(
                    {
                        text: details[i].name,
                        margin: [0, 10, 0, 10],
                        fontSize: 12
                    }
                );

                let spechText = '';
                for (let o = 0; o < details[i].specItems.length; o++) {

                    for (let county = 0; county < details[i].specItems[o].specValues.length; county++) {

                        spechText = spechText + details[i].specItems[o].specValues[county].valueText;
                        spechText = spechText + ' ' + details[i].specItems[o].specValues[county].quantityText;
                        spechText = spechText + ' ' + details[i].specItems[o].specValues[county].qualifierText;

                        if (details[i].specItems[o].specValues.length > county + 1) {
                            spechText = spechText + '\n ';
                        }
                    }

                    bodyElements.push([
                        {text: details[i].specItems[o].mountPositionText, alignment: 'left'},
                        {text: spechText, alignment: 'left'}
                    ]);
                    spechText = '';
                }

                docDefinition.content.push(
                    {
                        style: 'cartTable',
                        table: {
                            widths: ['*', '*'],
                            body: bodyElements
                        }
                    }
                );
                bodyElements = [];
            }
        } else {
            docDefinition = this.pdfService.buildDocDefinition(false, selectedCar, false, true);
            let bodyElements: any[] = [];

            docDefinition.content.push(
                {
                    text: 'ABE: ' + selectedTyreType.abe + ' | VSN: ' + selectedTyreType.vsn,
                    margin: [0, 10, 0, 10],
                    fontSize: 12,
                    bold: true
                }
            );

            let usage = this.translate.instant('TYRE.USAGE');

            let front = this.translate.instant('TYRE.RIMSIZE') + ' ('
                + this.translate.instant('TYRE.FRONT') + ')' + '\n'
                + this.translate.instant('TYRE.SIZE');

            let rear = this.translate.instant('TYRE.RIMSIZE') + ' ('
                + this.translate.instant('TYRE.BACK') + ')' + '\n'
                + this.translate.instant('TYRE.SIZE');

            let info = this.translate.instant('MAINTENANCE.INFO');

            bodyElements.push([
                {text: usage, alignment: 'left'},
                {text: front, alignment: 'left'},
                {text: rear, alignment: 'left'},
                {text: info, alignment: 'left'},
            ]);

            docDefinition.content.push({
                style: 'cartTable',
                table: {
                    widths: ['*', '*', '*', '*'],
                    body: bodyElements
                }
            });
            bodyElements = [];


            for (const tyre of selectedTyreType.details) {

                const front = tyre.frontRimSize + '\n' + tyre.frontTyreSize;
                const rear = tyre.rearRimSize + '\n' + tyre.rearTyreSize;

                bodyElements.push([
                    {text: tyre.type, alignment: 'left'},
                    {text: front, alignment: 'left'},
                    {text: rear, alignment: 'left'},
                    {text: tyre.infoText, alignment: 'left'}
                ]);
            }

            docDefinition.content.push({
                style: 'cartTable',
                table: {
                    widths: ['*', '*', '*', '*'],
                    body: bodyElements
                }
            });
        }

        return docDefinition;
    }

    public createPdf(details: boolean, selectedTyreType: ITyreType, tyreSpecs: IVehicleSpecSubGroup[], selectedCar: ICar) {
        this.loading$.next(true);
        this.getPdf(this.generatePdf(details, selectedTyreType, tyreSpecs, selectedCar)).then(
            (pdf: any) => {
                this.loading$.next(false);
                this.pdf$.next(pdf);
            },
            (error: any) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_GENERATE_PDF', 'error');
                this.pdf$.next(undefined);
                this.loading$.next(false);
            }
        );
    }

    public downloadPdf(details: boolean, selectedTyreType: ITyreType, tyreSpecs: IVehicleSpecSubGroup[], selectedCar: ICar) {
        return pdfMake.createPdf(this.generatePdf(details, selectedTyreType, tyreSpecs, selectedCar)).download();
    }

    public printPdf(details: boolean, selectedTyreType: ITyreType, tyreSpecs: IVehicleSpecSubGroup[], selectedCar: ICar) {
        return pdfMake.createPdf(this.generatePdf(details, selectedTyreType, tyreSpecs, selectedCar)).print();
    }

    public getPdf(docDefinition: any) {
        return <any>new Promise(function (resolve: any, reject: any) {
            pdfMake.createPdf(docDefinition, 'filename').getBase64(function (pdfBase64: any) {
                const raw = atob(pdfBase64);
                const uint8Array = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    uint8Array[i] = raw.charCodeAt(i);
                }
                resolve(uint8Array);
            });
        });
    }
}
