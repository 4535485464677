import { Component, OnInit } from '@angular/core';
import {MaintenanceService} from '../../services/maintenance.service';
import {fadeOfferToggle} from '../../../shared/animations/general.animation';
import {CarService} from '../../../shared/services/car/car.service';
import {ConfigService} from '../../../shared/services/config.service';

@Component({
  selector: 'app-maintenance-info-dashboard',
  templateUrl: './maintenance-info-dashboard.component.html',
  styleUrls: ['./maintenance-info-dashboard.component.scss'],
    animations: [fadeOfferToggle]
})
export class MaintenanceInfoDashboardComponent implements OnInit {

  constructor(public maintenanceService: MaintenanceService,
              public carService: CarService,
              public configService: ConfigService) { }

  ngOnInit() {
    this.maintenanceService.lastState = '/maintenance/info';
  }

}
