import {trigger, animate, transition, style, query, stagger, keyframes, state} from '@angular/animations';
declare var document: any;

export const itemAnimation =
        trigger('itemAnim', [
            transition('* => *', [ // each time the binding value changes
                query(':enter', [
                    style({ opacity: 0, transform: 'translateX(-30%)' }),
                    stagger('20ms', [
                        animate('100ms', style({ opacity: 0.2, transform: 'translateX(0)' }))
                    ]),
                    stagger('00ms', [
                        animate('100ms', style({ opacity: 1 }))
                    ])
                ], { optional: true }),
                query(':leave', [
                    stagger('10ms', [
                        animate('100ms', style({ opacity: 0, transform: 'translateX(-75%)' }))
                    ])
                ], { optional: true })
            ])
        ]);

export const itemSelected =
    trigger('selected', [
        transition('* => move',
            animate('300ms', keyframes([
                style({transform: 'scale(1)    ',   offset: 0}),
                style({transform: 'scale(1.025) translateX(1%) ',   offset: 0.2}),
                style({transform: 'scale(1) translateX(0)    ',   offset: 1})
            ])
        ))
    ]);

export const catAnimation =
    trigger('catAnim', [
        transition('* => *', [ // each time the binding value changes
            query(':enter', [
                style({opacity: 0, transform: 'scale(0.9)'}),
                stagger('30ms', [
                    animate('.15s ease-out', style({opacity: 1, transform: 'scale(1)'}))
                ])
            ], {optional: true})
        ])
    ]);

export const fadeOfferToggle =
    trigger('toggle', [
    state('true', style({
        'top': '-35%',
        'padding-bottom': '0.5rem'
    })),
        state('false', style({
            'top': '0px',
            'padding-bottom': '0'
        })),
    transition('false => true',
        animate('200ms ease')),
    transition('true => false',
        animate('200ms ease')),
    ]);

export const dialog =
    trigger('dialog', [
        transition('void => *', [
            query('*', [
                style({
                    opacity: '0',
                    'z-index': '2000'
                }),
                animate('0.25s ease-out', style('*'))
            ])
        ]),
        transition('* => void', [
            animate(100, style({ opacity: '0' }))
        ])
    ]);

export const detailAnim =
    trigger('detailAnim', [
        transition('* => *', [ // each time the binding value changes
            query(':enter', [
                style({ opacity: 0, transform: 'translateX(-30%)' }),
                stagger('50ms', [
                    animate('150ms', style({ opacity: 0.25, transform: 'translateX(-20%)' }))
                ]),
                stagger('100ms', [
                    animate('200ms', style({ opacity: 1, transform: 'translateX(0)' }))
                ])
            ], { optional: true })
        ])
    ]);

export const toggle =
    trigger('change', [
        transition('* => *',
            animate('150ms', keyframes([
                    style({transform: 'rotateX(-90deg)', offset:0}),
                    style({transform: 'rotateX(0deg)', offset:1}),
                ])
            ))
    ]);


export const visible =
    trigger( 'visible', [
        transition(':enter',
            animate('300ms', keyframes([
                    style({opacity: '0', transform: 'scale(1)    ',   offset: 0}),
                    style({opacity: '1', transform: 'scale(1.0375) translateX(1.5%) ',   offset: 0.2}),
                    style({transform: 'scale(1) translateX(0)    ',   offset: 1})
                ])
            ))
    ]);

export const submit =
    trigger('submit', [
        state('1', style({
            background: 'transparent',
            transform: 'scale(.9)',
            color: '#c0c0c0'
        })),
        state('2',   style({
            transform: 'scale(1)'
        })),
        state('3', style({
            background: '#ff0027',
            transform: 'scale(1)',
            color: '#c0c0c0'
        })),
        transition('0 => 1', animate('200ms ease-in')),
        transition('1 => 0', animate('400ms ease-out')),
        transition('1 => 2', animate('100ms ease-out')),
        transition('2 => 2', animate('100ms ease-in'))
    ]);

export const collapsibleOpen =
    trigger('open', [
        state('true', style({
            opacity: 0,
            display: 'none',
            height: 0
        })),
        state('false', style({
            opacity: 1,
            display: 'block',
            height: '*'
        })),
        transition('true => false', animate('250ms ease-in', keyframes([
            style({
                offset: 0,
                opacity: 0,
                display: 'block',
                height: 0}),
            style({
                offset: 1,
                opacity: 1,
                display: 'block',
                height: '*'})
        ]))),
        transition('false => true', animate('250ms ease-out', keyframes([
            style({
                offset: 0,
                opacity: 1,
                display: 'block',
                height: '*'}),
            style({
                offset: 1,
                opacity: 0,
                display: 'none',
                height: 0})
        ])))
    ]);

export const leftTreeAnimation =
    trigger('leftTreeAnim', [
        transition('* => *', [ // each time the binding value changes
            query(':enter', [
                style({ opacity: 0 }),
                stagger('50ms', [
                    animate('100ms', style({ opacity: 0.2 }))
                ]),
                stagger('100ms', [
                    animate('150ms', style({ opacity: 1 }))
                ])
            ], { optional: true })
        ])
    ]);

export const workListAnim =
    trigger('workListAnim', [
        transition('* => *', [ // each time the binding value changes
            query(':enter', [
                style({ opacity: 0, transform: 'translateX(-30%)' }),
                stagger('20ms', [
                    animate('50ms', style({ opacity: 0.5, transform: 'translateX(0)' }))
                ]),
                stagger('20ms', [
                    animate('50ms', style({ opacity: 1 }))
                ])
            ], { optional: true })

        ])
    ]);
