import {Component, OnInit} from '@angular/core';
import {AuthService} from "../auth/services/auth.service";
import {UserService} from "../shared/services/user/user.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-sso-error',
    templateUrl: './sso-error.component.html',
    styleUrls: ['./sso-error.component.scss']
})
export class SsoErrorComponent implements OnInit {
    public rediretUri: string

    constructor(private authService: AuthService,
                public userService: UserService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route
            .queryParams
            .subscribe(params => {
                if (params.redirect_uri) {
                    this.rediretUri = params.redirect_uri
                } else {
                    this.rediretUri = '/';
                }
            });
    }

}
