import { Component, OnInit } from '@angular/core';
import {GlobalAdminService} from '../../../global/services/global-admin.service';
import {ConfigService} from '../../../../shared/services/config.service';

@Component({
  selector: 'app-external-tools-details',
  templateUrl: './external-tools-details.component.html',
  styleUrls: ['./external-tools-details.component.scss']
})
export class ExternalToolsDetailsComponent implements OnInit {

  constructor(public globalAdminService: GlobalAdminService,
              private configService: ConfigService) { }

  ngOnInit() {
  }

  public changeShowInDetails(showInDetails: boolean) {
    this.globalAdminService.selectedExternalTool.showInDetails = showInDetails;
  }

}
