<div class="work-header">
  <div class="row">
    <div class="col-7" routerLink="/work/" *ngIf="workService?.articleCount">
      <span *ngFor="let pos of workService?.posByCar; index as i"><i class="fa fa-cogs"></i> {{pos.position.description}}<span *ngIf="workService?.posByCar?.length > 1 && (workService?.posByCar?.length -1) !== i">, </span></span>
      <b> | {{'CART.COMPOSITE_WORK_TIME' | translate}}: {{getAdditionalWorkTime()}} {{'GENERAL.VALUES.' + getQuantityUnit() | translate}}</b>
    </div>
    <div class="col-5 clickable" (click)="workService.selectedOption = ''" routerLink="/work/" *ngIf="workService?.articleCount && router.url.indexOf('/article') > 0">
      <span class="float-right"><em>{{'PROMPTS.BUTTONS.CHANGE' | translate}} <i class="fa fa-pencil"></i></em></span>
    </div>
    <div class="col-5" (click)="workService.selectedOption = 'ARTIKEL'; workService.selectedArticle = undefined;workService.showArticle();" routerLink="/work/article" *ngIf="workService?.articleCount && router.url.indexOf('/article') < 0">
      <button class="base-btn success no-top-margin">
        <i class="fa fa-check"></i>
        <span>{{'MAINTENANCE.TO_ARTICLES' | translate}} ({{workService?.articleCount}})</span>
      </button>
    </div>
  </div>
  <div class="row successRow" *ngIf="workService && workService.showModal">
    <div class="col-12">
      <div class="alert alert-success">
        <div class="row">
          <div class="col-6 text-center">
            <b>{{'PROMPTS.MESSAGE.ALL_ARTICLE' | translate}}</b><br/>
            <i *ngIf="location.path().indexOf('/work') > -1 && configService.allowOffers()">{{'PROMPTS.MESSAGE.ALL_ARTICLE_MORE_WORK' | translate}}</i>
          </div>
          <div class="col-6 buttonBar">
            <button class="base-btn" routerLink="/cart"><i class="fa fa-shopping-cart"></i>
              {{'PROMPTS.BUTTONS.TO_CART' | translate}}
            </button>
            <button class="base-btn" routerLink="/cart/offer" (click)="cartService.backLink = workService.getBacklink();" *ngIf="configService.allowOffers()">
              <i class="fa fa-file-pdf-o"></i> {{'PROMPTS.BUTTONS.TO_OFFER' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
