import {Component, OnDestroy, OnInit} from '@angular/core';
import {CarService} from '../../shared/services/car/car.service';
import {TecRmiRestService} from '../../shared/services/tecrmi/tecrmi.rest.service';
import {ArticlesService} from '../../articles/services/articles.service';
import {BodygraphicsService} from '../services/bodygraphics.service';
import {HelperService} from '../../shared/services/helper.service';

@Component({
    selector: 'app-bodygraphics',
    templateUrl: './bodygraphics.component.html',
    styleUrls: ['./bodygraphics.component.scss']
})
export class BodygraphicsComponent implements OnInit {
    public adcTypeId: number;
    public qualcols: any[];
    public qualcol: any = undefined;
    public qualColUrl: string = 'http://www.google.de';

    constructor(
        private carService: CarService,
        private articlesService: ArticlesService,
        private tecRmiRestService: TecRmiRestService,
        private bodygraphicsService: BodygraphicsService) {
        this.getBodygraphicsNavigation();

        this.bodygraphicsService.resetPartSearch.subscribe(() => {
           this.getBodygraphicsNavigation();
        });
    }

    ngOnInit() {
        this.getBodygraphicsNavigation();
        this.carService.selectedCarChanged$.subscribe(state => this.getBodygraphicsNavigation());
    }

    public getBodygraphicsNavigation() {
        this.resetQualcol();
        const selectedCar = this.carService.selectedCar,
            adcTypeId = (selectedCar && selectedCar.adcTypeId) ? selectedCar.adcTypeId : 0;
        this.adcTypeId = adcTypeId;
        this.tecRmiRestService.getBodygraphicsNavigation(this.adcTypeId).subscribe(
            (response) => {
                this.qualcols = response;
                if (this.qualcols.length === 1) {
                    this.selectQualcol(this.qualcols[0]);
                }
            }
        );
    }

    public selectQualcol(qualcol: any) {
        this.qualcol = qualcol;
        this.qualColUrl = this.tecRmiRestService.getBodygraphicsOverview(this.adcTypeId, qualcol);
    }

    public resetQualcol() {
        this.qualcol = undefined;
        this.qualColUrl = undefined;
    }

    private backInFrame(url: any) {
        var frame = window.frames['tecrmi-bodygraphics'];
        if (!frame) {
            return;
        }
        let frames: any = document.getElementById('tecrmi-manual');
        if (frames.contentWindow.location.href != frames.src) {
            frames.contentWindow.history.back();
        }
    }

    private printIframe() {
        var frame = window.frames['tecrmi-bodygraphics'];
        if (!frame) {
            return;
        }
        let frames: any = document.getElementById('tecrmi-manual');

        /*
         window.frames.focus();
         window.frames.print();
         */
        frames.contentWindow.focus();
        frames.contentWindow.print();

    };
}
