<div class="content" *ngIf="showContent === 'NO_DATA'">
  <div class="car-selection-wrapper no-float">
    <div class="panel-content center-content car-selection-area">
      <app-no-data></app-no-data>
    </div>
  </div>
</div>

<div class="content" *ngIf="showContent === 'TECHNICAL_ERROR'">
  <div class="car-selection-wrapper no-float">
    <div class="panel-content center-content car-selection-area">
      <div class="no-data">
        <div class="card">
          <div class="logo"></div>
          <div class="alert alert-danger text-center">
            <i>{{'LOCKED.TECHNICAL_ERROR' | translate}}</i>
            <div class="cardBody">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content" *ngIf="showContent === 'TECRMI_ERROR'">
  <div class="car-selection-wrapper no-float">
    <div class="panel-content center-content car-selection-area">
      <div class="no-data">
        <div class="card">
          <div class="logo"></div>
          <div class="alert alert-danger text-center">
            <i>{{'ERROR_MESSAGE.TECRMI_BANNER' | translate}}</i>
            <div class="cardBody">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content" *ngIf="showContent === 'EMPTY_VEHICLE_DATA'">
  <div class="car-selection-wrapper no-float">
    <div class="panel-content center-content car-selection-area">
      <div class="no-data">
        <div class="card">
          <div class="logo"></div>
          <div class="alert alert-danger text-center">
            <i>{{'CAR_SELECTION.NO_VEHICLE_DATA' | translate}}</i>
            <div class="cardBody">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content" *ngIf="showContent === 'LOCKED'">
  <div class="col-12 no-float">
    <div class="heading blue searchable" style="margin-left: 0px;">
      <div class="text">{{'LOCKED.TITLE' | translate}}</div>
    </div>
    <div class="panel-content center-content white-background">
      <div class="inner-content">
        <div class="row">
          <div class="col-3"></div>
          <div class="col-6">
            <app-locked></app-locked>
          </div>
          <div class="col-3"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content clean" *ngIf="showContent === 'CAR_SELECTION'">
  <div class="car-selection-wrapper no-float">
    <div class="panel-content center-content  car-selection-area">
      <app-car-selection [newRoute]="newRoute" [noCarSelected]="true"></app-car-selection>
    </div>
  </div>
</div>
