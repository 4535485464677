import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class ErrorHandlerService {

    public errorCode: number;
    public errorText: string;
    public newError: boolean;
    public hasError: boolean;

    constructor(private translateService: TranslateService) {
        this.errorCode = 0;
        this.newError = false;
        this.errorText = '';
    }

    public throwStringError(errorString: string, errorCode: number) {
        this.errorText = errorString;
        this.newError = true;
        this.errorCode = errorCode;
    }

    public throwError(errorCode: number) {
        this.errorCode = errorCode;
        this.newError = true;

        switch (errorCode) {
            case 400: this.translateService.get('ERROR.BADREQUEST').subscribe((msg: string) => {this.errorText = msg; });
                break;
            case 401: this.translateService.get('ERROR.UNAUTHORIZED').subscribe((msg: string) => {this.errorText = msg; });
                break;
            case 403: this.translateService.get('ERROR.FORBIDDEN').subscribe((msg: string) => {this.errorText = msg; });
                break;
            case 404: this.translateService.get('ERROR.NOTFOUND').subscribe((msg: string) => {this.errorText = msg; });
            break;
            case 409: this.translateService.get('ERROR.CONFLICT').subscribe((msg: string) => {this.errorText = msg; });
                break;
            default:
                this.translateService.get('ERROR.DEFAULT').subscribe((msg: string) => {this.errorText = msg; });
        }
    }
}
