import {EventEmitter, Injectable} from '@angular/core';
import {IData} from '../interfaces/data';
import {UserService} from './user/user.service';
import {CartRestService} from '../../cart/services/cart.rest.service';
import {IEndCustomerData} from '../../cart/interfaces/cart';
import {HelperService} from './helper.service';

@Injectable()
export class CustomerDataService {

    private dataHolder: IData = {
        mileage: undefined,
        numberplate: '',
        customerLastName: '',
        customerFirstName: '',
        customerAddress: '',
        customerCity: '',
        customerEmail: '',
        customerPhone: '',
        customerZip: '',
        orderNumber: '',
        year: undefined,
        hsn: '',
        tsn: '',
        vsn: '',
        vin: '',
        typenumber: '',
        natcode: '',
        kTypeId: undefined,
        serviceDate: '',
        entryPoint: ''
    };

    public endCustomerInformationChanged: EventEmitter<any> = new EventEmitter<any>();


    constructor(private userService: UserService,
                private cartRestService: CartRestService,
                private helperService: HelperService) {


        this.userService.accountData$.subscribe((step) => {
            if (step) {
                this.resetDataHolder();
            }
        });
    }

    public resetDataHolder() {
        this.dataHolder = {
            mileage: undefined,
            numberplate: '',
            customerLastName: '',
            customerFirstName: '',
            customerAddress: '',
            customerCity: '',
            customerEmail: '',
            customerPhone: '',
            customerZip: '',
            orderNumber: '',
            year: undefined,
            hsn: '',
            tsn: '',
            vsn: '',
            natcode: '',
            vin: '',
            serviceDate: '',
            typenumber: '',
            kTypeId: undefined,
            entryPoint: ''
        };
    }

    public getDataHolder() {
        return this.dataHolder;
    }

    public getMileage() {
        return this.dataHolder.mileage;
    }

    public getNumberplate() {
        return this.dataHolder.numberplate;
    }

    public getCustomerLastName() {
        return this.dataHolder.customerLastName;
    }

    public getCustomerFirstName() {
        return this.dataHolder.customerFirstName;
    }

    public getCustomerAddress() {
        return this.dataHolder.customerAddress;
    }

    public getCustomerCity() {
        return this.dataHolder.customerCity;
    }


    public getCustomerZip() {
        return this.dataHolder.customerZip;
    }

    public getCustomerPhone() {
        return this.dataHolder.customerPhone;
    }

    public getOrderNumber() {
        return this.dataHolder.orderNumber;
    }

    public getYear() {
        return this.dataHolder.year;
    }

    public getHsn() {
        return this.dataHolder.hsn;
    }

    public getTsn() {
        return this.dataHolder.tsn;
    }


    public getVin() {
        return this.dataHolder.vin;
    }

    public getKTypeId() {
        return this.dataHolder.kTypeId;
    }


    public getTypenumber() {
        return this.dataHolder.typenumber;
    }

    public getNatcode() {
        return this.dataHolder.natcode;
    }

    public getEntryPoint() {
        return this.dataHolder.entryPoint;
    }

    public setDataHolder(dataHolder: IData) {
        if (dataHolder && dataHolder !== this.dataHolder) {
            this.dataHolder = dataHolder;
            this.setEndCustomerInformation();
        }
    }

    public setMileage(mileage: number) {
        if (mileage && this.dataHolder.mileage !== mileage) {
            this.dataHolder.mileage = mileage;
        }
    }

    public setNumberplate(numberplate: string) {
        if (numberplate && this.dataHolder.numberplate !== numberplate) {
            this.dataHolder.numberplate = numberplate;
        }
    }

    public setCustomerLastName(customerLastName: string) {
        if (customerLastName && this.dataHolder.customerLastName !== customerLastName) {
            this.dataHolder.customerLastName = customerLastName;
        }
    }

    public setCustomerFirstName(customerFirstName: string) {
        if (customerFirstName && this.dataHolder.customerFirstName !== customerFirstName) {
            this.dataHolder.customerFirstName = customerFirstName;
        }
    }

    public setCustomerAddress(customerAddress: string) {
        if (customerAddress && this.dataHolder.customerAddress !== customerAddress) {
            this.dataHolder.customerAddress = customerAddress;
        }
    }

    public setCustomerCity(customerCity: string) {
        if (customerCity && this.dataHolder.customerCity !== customerCity) {
            this.dataHolder.customerCity = customerCity;
        }
    }

    public setCustomerEmail(customerEmail: string) {
        if (customerEmail && this.dataHolder.customerEmail !== customerEmail) {
            this.dataHolder.customerEmail = customerEmail;
        }
    }

    public setCustomerZip(customerZip: string) {
        if (customerZip && this.dataHolder.customerZip !== customerZip) {
            this.dataHolder.customerZip = customerZip;
        }
    }

    public setCustomerPhone(customerPhone: string) {
        if (customerPhone && this.dataHolder.customerPhone !== customerPhone) {
            this.dataHolder.customerPhone = customerPhone;
        }
    }

    public setOrderNumber(orderNumber: string) {
        if (orderNumber && this.dataHolder.orderNumber !== orderNumber) {
            this.dataHolder.orderNumber = orderNumber;
        }
    }

    public setYear(year: Date) {
        if (year && this.dataHolder.year !== year) {
            this.dataHolder.year = year;
        }
    }

    public setHsn(hsn: string) {
        if (hsn && this.dataHolder.hsn !== hsn) {
            this.dataHolder.hsn = hsn;
        }
    }

    public setTsn(tsn: string) {
        if (tsn && this.dataHolder.tsn !== tsn) {
            this.dataHolder.tsn = tsn;
        }
    }

    public setVsn(vsn: string) {
        if (vsn && this.dataHolder.vsn !== vsn) {
            this.dataHolder.vsn = vsn;
        }
    }

    public setVin(vin: string) {
        if (vin && this.dataHolder.vin !== vin) {
            this.dataHolder.vin = vin;
        }
    }

    public setTypenumber(typenumber: string) {
        if (typenumber && this.dataHolder.typenumber !== typenumber) {
            this.dataHolder.typenumber = typenumber;
        }
    }

    public setNatcode(natcode: string) {
        if (natcode && this.dataHolder.natcode !== natcode) {
            this.dataHolder.natcode = natcode;
        }
    }

    public setKTypeId(kTypeId: number) {
        if (kTypeId && this.dataHolder.kTypeId !== kTypeId) {
            this.dataHolder.kTypeId = kTypeId;
        }
    }

    public setServiceDate(serviceDate: string) {
        if (serviceDate && this.dataHolder.serviceDate !== serviceDate) {
            this.dataHolder.serviceDate = serviceDate;
        }
    }

    public setEntryPoint(entryPoint: string) {
        if (entryPoint && this.dataHolder.entryPoint !== entryPoint) {
            this.dataHolder.entryPoint = entryPoint;
        }
    }

    private setEndCustomerInformation() {
        const endCustomerInformation: IEndCustomerData = {
            firstName: this.dataHolder.customerFirstName,
            lastName: this.dataHolder.customerLastName,
            email: this.dataHolder.customerEmail,
            street: this.dataHolder.customerAddress,
            zip: this.dataHolder.customerZip,
            city: this.dataHolder.customerCity,
            phone: this.dataHolder.customerPhone,
            hsn: this.dataHolder.hsn,
            tsn: this.dataHolder.tsn,
            vin: this.dataHolder.vin,
            vsn: this.dataHolder.vsn,
            kTypeId: this.dataHolder.kTypeId,
            numberplate: this.dataHolder.numberplate,
            mileage: this.dataHolder.mileage,
            reference: this.dataHolder.orderNumber,
            registrationDate: this.dataHolder.year,
            serviceDate: this.dataHolder.serviceDate,
        };
        this.cartRestService.setEndCustomerInformation(endCustomerInformation).subscribe(
            (response) => {
                this.endCustomerInformationChanged.emit()
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_SAVE_ENDCUSTOMER_INFORMATION', 'error');
            })
    }

}
