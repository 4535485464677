import {Component} from '@angular/core';
import {AccountRestService} from '../services/account.rest.service';
import {UserService} from '../../../shared/services/user/user.service';
import {ErrorHandlerService} from '../../../shared/components/error-handler/services/error-handler.service';
import {IPassword} from '../interfaces/address';
import {HelperService} from '../../../shared/services/helper.service';
@Component({
    selector: 'app-userdata',
    templateUrl: './userdata.component.html'
})
export class UserdataComponent {

    constructor(private accountRestService: AccountRestService,
                public userService: UserService,
                private errorHandlerService: ErrorHandlerService,
                private helperService: HelperService) {
    }

    public changeUserPW(newPassword: IPassword ) {
        this.accountRestService.setNewPassword(newPassword).subscribe(
            (success) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                return true;
            }
        );
    }

}
