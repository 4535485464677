<div class="add-custom-comment">
  <div class="panel-content center-content">
    <div class="row comment-row">
      <div class="col-12">
        <h1 *ngIf="!(cartService?.currentCart?.comment?.length > 0)">{{'CART.COMMENT.ADD_COMMENT' | translate}}</h1>
        <h1 *ngIf="cartService?.currentCart?.comment?.length > 0">{{'CART.COMMENT.EDIT_COMMENT' | translate}}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <textarea class="textarea" id="textArea" [(ngModel)]="comment" [autofocus]="true" [maxLength]="maxLength"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button class="base-btn success" *ngIf="comment.length > 0" (click)="save()">
          <i class="fa fa-check mr-2"></i>
          <span>{{'PROMPTS.BUTTONS.SAVE_CHANGES' | translate}}</span>
        </button>
        <button class="base-btn ml-3" *ngIf="cartService?.currentCart?.comment?.length > 0" (click)="delete()">
          <i class="fa fa-trash mr-2"></i>
          <span>{{'CART.COMMENT.DELETE' | translate}}</span>
        </button>
        <button class="base-btn float-right back" routerLink="/cart">
          <span>{{'GENERAL.BACK' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
