import {Injectable} from '@angular/core';
import {INotification, INotificationWrapper} from '../interfaces/DTO/notification/notification';
import {UserService} from './user/user.service';
import {ExternalNotificationRestService} from './external-notification.rest.service';
import {HelperService} from './helper.service';
import {IAccountData} from './user/interfaces/account-data';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ExternalNotificationService {

    public notificationHolder: INotificationWrapper;
    public showNotifications: boolean = false;
    public hasNewNotifications: boolean = false;
    public loadOwnNotifications: boolean = false;
    public userData: IAccountData;

    constructor(private userService: UserService,
                private helperService: HelperService,
                private translate: TranslateService,
                private externalNotificationRestService: ExternalNotificationRestService) {

        this.userData = {} as IAccountData;
        this.userService.accountData$.subscribe((accountdata) => {
            if (accountdata) {
                this.userData = accountdata;
                this.notificationHolder = undefined;

            }
        });
    }

    public handleNewMessages(messages) {
        if (messages) {
            const messageHolder = JSON.parse(messages.body.toString());
            if (messageHolder) {
                this.notificationHolder = messageHolder;
                this.checkShowDirect();
            }
        }
    }

    public getNotificationType() {
        let selectedType: string = 'INFO';
        for (const notification of this.notificationHolder.list) {
            if (!this.isNotificationHidden(notification.id)) {
                if (notification.type === 'WARNING' && selectedType !== 'ERROR') {
                    selectedType = 'WARNING';
                }
                if (notification.type === 'ERROR') {
                    selectedType = 'ERROR';
                }
            }
        }

        return selectedType;
    }

    private checkShowDirect() {
        if (this.notificationHolder && this.notificationHolder.list) {
            for (const notification of this.notificationHolder.list) {
                if (!notification.showDirect && !this.showNotifications) {
                    this.hasNewNotifications = true;
                }
                if (notification.showDirect && !this.showNotifications) {
                    const localDirectShowNotifications = JSON.parse(localStorage.getItem('notifications-show-direct-' + this.userData.userName));

                    if (localDirectShowNotifications) {
                        let found = false;
                        for (const directShowNotification of localDirectShowNotifications) {
                            if (directShowNotification.id === notification.id) {
                                found = true;
                            }
                        }

                        if (!found) {
                            localDirectShowNotifications.push(notification);
                            const showDirectOptions = {
                                timeOut: 6000000,
                                pauseOnHover: false,
                                clickToClose: true,
                            }

                            if (this.userService && this.userService.accountData) {
                                localStorage.setItem('notifications-show-direct-' + this.userService.accountData.userName, JSON.stringify(localDirectShowNotifications));
                            }

                            switch (notification.type) {
                                case 'WARN':
                                    this.translate.get('NOTIFICATION.NOTIFICATION_TITLE').subscribe((msg) => {
                                        this.helperService.showNotification(notification.description, 'warning', msg, true, showDirectOptions);
                                    });
                                    break;
                                case 'WARNING':
                                    this.translate.get('NOTIFICATION.NOTIFICATION_TITLE').subscribe((msg) => {
                                        this.helperService.showNotification(notification.description, 'warning', msg, true, showDirectOptions);
                                    });
                                    break;
                                case 'INFO':
                                    this.translate.get('NOTIFICATION.NOTIFICATION_TITLE').subscribe((msg) => {
                                        this.helperService.showNotification(notification.description, 'info', msg, true, showDirectOptions);
                                    });
                                    break;
                                case 'ERROR':
                                    this.translate.get('NOTIFICATION.NOTIFICATION_TITLE').subscribe((msg) => {
                                        this.helperService.showNotification(notification.description, 'error', msg, true, showDirectOptions);
                                    });
                                    break;
                            }
                            localStorage.setItem('notifications-show-direct-' + this.userData.userName, JSON.stringify(localDirectShowNotifications));
                        }
                    } else {
                        localStorage.setItem('notifications-show-direct-' + this.userData.userName, JSON.stringify([notification]));
                    }
                }
            }
        }
    }

    public hideNotification(notification: INotification) {
        const localHolder = JSON.parse(localStorage.getItem('notifications-' + this.userData.userName));
        if (localHolder) {
            localHolder.push(notification);
            if (localHolder.length === this.notificationHolder.list.length) {
                this.showNotifications = false;
            }
            localStorage.setItem('notifications-' + this.userData.userName, JSON.stringify(localHolder));
        } else {
            if (this.notificationHolder.list.length === 1) {
                this.showNotifications = false;
            }
            localStorage.setItem('notifications-' + this.userData.userName, JSON.stringify([notification]));
        }
    }

    public showNotification(notification: INotification) {
        const localHolder = JSON.parse(localStorage.getItem('notifications-' + this.userData.userName));
        const newLocalHolder = [];
        if (localHolder) {
            for (const localNotification of localHolder) {
                if (localNotification.id !== notification.id) {
                    newLocalHolder.push(localNotification);
                }
            }
            localStorage.setItem('notifications-' + this.userData.userName, JSON.stringify(newLocalHolder));
        }
    }

    public getNotificationCount() {
        if (this.notificationHolder && this.notificationHolder.list) {
            const localHolder = JSON.parse(localStorage.getItem('notifications-' + this.userData.userName));
            if (localHolder) {
                return this.notificationHolder.list.length - localHolder.length;
            } else {
                return this.notificationHolder.list.length;
            }
        }
    }

    public isNotificationHidden(notificationId: number) {
        if (notificationId) {
            const localHolder = JSON.parse(localStorage.getItem('notifications-' + this.userData.userName));
            if (localHolder) {
                for (const notification of localHolder) {
                    if (notification.id === notificationId) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    public getAllOwnNotifications() {
        this.loadOwnNotifications = true;
        this.externalNotificationRestService.getAllOwnNotificationsRest().subscribe(
            (notifications: INotificationWrapper) => {
                if (!this.notificationHolder) {
                    this.notificationHolder = notifications;
                }
                const localHolder = JSON.parse(localStorage.getItem('notifications-' + this.userData.userName));
                const newLocalHolder = [];
                if (localHolder) {
                    for (const localNotification of localHolder) {
                        if (notifications.list.find(notification => notification.id === localNotification.id)) {
                            newLocalHolder.push(localNotification);
                        }
                    }

                    localStorage.setItem('notifications-' + this.userData.userName, JSON.stringify(newLocalHolder));
                }
                this.checkShowDirect();
                this.loadOwnNotifications = false;
            },
            (error) => {
                this.loadOwnNotifications = false;
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_NOTIFICATIONS', 'error');
            });
    }
}
