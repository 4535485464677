<div class="maintenance-data">
  <div class="heading blue" *ngIf="location.path().endsWith('/maintenance/info/specifications')">
    <span routerLink="/maintenance" class="clickable" (click)="maintenanceService.lastState = ''"><i class="fa fa-th" aria-hidden="true"></i></span>
    <span routerLink="/maintenance" class="clickable">{{'CLUB_SETTINGS.NAVIGATION.MAINTENANCE' | translate}}</span>
    <span routerLink="/maintenance/info" class="clickable"><i class="fa fa-caret-right"></i>{{'MAINTENANCE.INFO' | translate}}</span>
    <span routerLink="/maintenance/info/specifications" ><i class="fa fa-caret-right"></i>{{'MAINTENANCE.MANUFACTURER_SPEC' | translate}}</span>
    <span>
      <button class="print-btn pull-right" (click)="maintenanceService.createPdf(maintenanceService.maintenanceSpecs)" *ngIf="!maintenanceService.showPdf">
        <i class="fa fa-file-pdf-o"></i>
        <span>{{'PRINT_VIEW.TITLE' | translate}}</span>
      </button>
      <button class="print-btn pull-right" (click)="maintenanceService.showPdf = !maintenanceService.showPdf" *ngIf="maintenanceService.showPdf">
        <i class="fa fa-close"></i>
        <span>{{'PRINT_VIEW.CLOSE' | translate}}</span>
      </button>
    </span>
  </div>

</div>
<div class="panel-content center-content print-content"
     [ngClass]="{'setting' : !maintenanceService.showPdf}">
  <ng-container >
    <div class="maintenance-data_title">
      <button class="base-btn pull-right" (click)="maintenanceService.printPdf(maintenanceService.maintenanceSpecs)" *ngIf="maintenanceService.showPdf && !helperService.browserIsIE() && !helperService.browserIsEdge()">
        <i class="fa fa-print"></i> {{ 'PRINT_VIEW.PRINT' | translate }}
      </button>

      <button class="base-btn pull-right" (click)="maintenanceService.downloadPdf(maintenanceService.maintenanceSpecs)" *ngIf="maintenanceService.showPdf">
        <i class="fa fa-save"></i> {{ 'PRINT_VIEW.GENERATE_PDF' | translate }}
      </button>
      <h1 class="changeHeader" *ngIf="!maintenanceService.showPdf">
        <strong>{{ 'MAINTENANCE.MANUFACTURER_SPEC' | translate}}</strong>
      </h1>
      <h1 class="changeHeader" *ngIf="maintenanceService.showPdf">
        <strong>{{ 'MAINTENANCE.MANUFACTURER_SPEC' | translate }}</strong>
      </h1>
    </div>

    <div class="print-preview" *ngIf="maintenanceService.showPdf" style="top: 0!important;" >
      <app-default-loading-page *ngIf="maintenanceService.pdfLoading"></app-default-loading-page>
      <pdf-viewer
              [src]="maintenanceService.pdf"
              [autoresize]="false"
              [render-text]="false"
              [show-all]="true"
              style="display: block;"
      ></pdf-viewer>
    </div>
    <ng-container *ngIf="!maintenanceService.showPdf">

      <div class="row" *ngFor="let specs of maintenanceService?.maintenanceSpecs" class="striped-table">
        <div class="col">
          <div class="row">
            <div class="col headerType">
              <span class="selection title">{{specs.name}}</span>
            </div>
          </div>
          <div class="row" *ngFor="let subSpecs of specs.specItems">
            <div class="col">
              {{subSpecs.mountPositionText}}
            </div>
            <div class="col">
              <div *ngFor="let specValues of subSpecs.specValues">
                {{specValues.valueText}} {{specValues.quantityText}} {{specValues.qualifierText}}
                <span *ngIf="specValues.additionText">{{specValues.additionalText}}</span>
              </div>
            </div>
          </div>
          <div class="spacer"></div>
        </div>
      </div>

    </ng-container>

  </ng-container>
</div>
