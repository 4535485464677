import {Component, OnInit} from '@angular/core';
import {ConfigService} from '../../../../shared/services/config.service';
import {ITwoValueSetting} from '../../../../shared/interfaces/DTO/admin/twoValueSetting';

@Component({
    selector: 'app-tecdoc-config',
    templateUrl: './tecdoc-config.component.html'
})
export class TecdocConfigComponent implements OnInit {

    public localDataSuppliersList: ITwoValueSetting[];
    public localRestrictions: ITwoValueSetting[];
    public selectedDataSupplier: ITwoValueSetting;
    public selectedRestriction: ITwoValueSetting;
    public localRestrictionsInput: string;
    public localDataSuppliersInput: string;

    constructor(public configService: ConfigService) {
        this.configService.changeClubConfig.subscribe(() => {
            this.localRestrictions = this.getSelectedDataSuppliers();
            this.localDataSuppliersList = this.getDataSuppliers();
        });
    }

    ngOnInit() {
        this.localRestrictions = this.getSelectedDataSuppliers();
        this.localDataSuppliersList = this.getDataSuppliers();
    }

    public reduceList(list: ITwoValueSetting[], input: string) {
        if (list && input && input.length > 0) {
            return list.filter(supp => supp.second.toUpperCase().indexOf(input.toUpperCase()) > -1 || supp.second.toString() === input);
        } else {
            return list;
        }
    }

    public getUseOnlyArticlesWithCustomIdActive() {
        const setting = this.configService.getSubSettingByName('TECDOC', 'USE_ONLY_ARTICLES_WITH_CUSTOM_ID');

        if (setting) {
            return setting.active;
        }

        return undefined;
    }

    public setUseOnlyArticlesWithCustomIdActive(active: boolean) {
        const setting = this.configService.getSubSettingByName('TECDOC', 'USE_ONLY_ARTICLES_WITH_CUSTOM_ID');

        if (setting) {
            setting.active = active;
        }
    }

    public getSelectedListType() {
        const setting = this.configService.getSubSettingByName('TECDOC', 'LIST_TYPE');

        if (setting) {
            return setting.selected;
        }

        return undefined;
    }

    public getListTypes() {
        const setting = this.configService.getSubSettingByName('TECDOC', 'LIST_TYPE');

        if (setting) {
            return setting.selection;
        }

        return undefined;
    }

    public setListType(listType: boolean) {
        const setting = this.configService.getSubSettingByName('TECDOC', 'LIST_TYPE');
        if (setting) {
            if (listType) {
                setting.selected = 'BLACK_LIST';
            } else {
                setting.selected = 'WHITE_LIST';
            }
            this.configService.addUnsavedSetting(this.configService.selectedSettingName)
            this.setSelectedDataSuppliers([]);
            this.localRestrictions = [];
        }
    }

    public getDataSupplierActive() {
        const setting = this.configService.getSubSettingByName('TECDOC', 'DATA_SUPPLIER');
        if (setting) {
            return setting.active;
        }
    }

    public setDataSupplierActive(active: boolean) {
        const setting = this.configService.getSubSettingByName('TECDOC', 'DATA_SUPPLIER');
        if (setting) {
            this.configService.addUnsavedSetting(this.configService.selectedSettingName)
            setting.active = active;
        }
    }

    public getSelectedDataSuppliers() {
        const setting = this.configService.getSubSettingByName('TECDOC', 'DATA_SUPPLIER');

        if (setting) {
            return setting.selected;
        }

        return undefined;
    }

    public setSelectedDataSuppliers(suppliers: ITwoValueSetting[]) {
        const setting = this.configService.getSubSettingByName('TECDOC', 'DATA_SUPPLIER');

        if (setting) {
            setting.selected = suppliers;
        }
    }

    public getDataSuppliers() {
        const setting = this.configService.getSubSettingByName('TECDOC', 'DATA_SUPPLIER');

        if (setting) {
            return setting.selection;
        }

        return undefined;
    }

    public isInRestrictionList(restriction: any) {
        if (this.getSelectedDataSuppliers()) {
            for (const supplier of this.getSelectedDataSuppliers()) {
                if (supplier.first === restriction.first) {
                    return true;
                }
            }
            return false;
        }
        return false;
    }

    public addRestriction() {
        if (this.selectedDataSupplier) {
            this.getSelectedDataSuppliers().push(this.selectedDataSupplier);
            this.localRestrictionsInput = '';
            this.localRestrictions = this.reduceList(this.getSelectedDataSuppliers(), this.localRestrictionsInput);
            this.selectedDataSupplier = undefined;
            this.configService.addUnsavedSetting(this.configService.selectedSettingName);
        }
    }

    public removeRestriction() {
        if (this.selectedRestriction) {
            const dataSupplier = this.getSelectedDataSuppliers();
            for (let i = 0; i < dataSupplier.length; i++) {
                if (dataSupplier[i].first === this.selectedRestriction.first) {
                    this.getSelectedDataSuppliers().splice(i, 1);
                    this.localRestrictions = this.reduceList(this.getSelectedDataSuppliers(), this.localRestrictionsInput);
                    this.selectedRestriction = undefined;
                    this.configService.addUnsavedSetting(this.configService.selectedSettingName);
                    return;
                }
            }
        }
    }

    public reset() {
        this.configService.getClubConfig();
    }
}
