import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {IData} from '../interfaces/data';
import {IAssortment} from '../interfaces/assortment';

@Injectable({
    providedIn: 'root'
})
export class AssortmentRestService {

    constructor(private http: HttpClient) {
    }

    public getDataSuppliers(): Observable<IData[]> {
        return this.http.get<IData[]>(
            `${environment.dataUrl}/dataSuppliers`
        );
    }

    public getGenArts(clubId: number): Observable<IData[]> {
        return this.http.get<IData[]>(
            `${environment.dataUrl}/genArts/${clubId}`
        );
    }

    public createOrUpdateAssortment(assortment: IAssortment, clubId: number) {
        return this.http.post(
            `${environment.apiUrl}/assortment?clubId=${clubId}`,
            assortment
        );
    }


    public deleteAssortment(id: string) {
        return this.http.delete(
            `${environment.apiUrl}/assortment/${id}`
        );
    }


    public getAssortmentsByDlnr(id: number, clubId: number, firmId?: number): Observable<IAssortment[]> {
        if (firmId) {
            return this.http.get<IAssortment[]>(
                `${environment.apiUrl}/assortment/datasupplier/${id}?clubId=${clubId}&firmId=${firmId}`
            );
        } else {
            return this.http.get<IAssortment[]>(
                `${environment.apiUrl}/assortment/datasupplier/${id}?clubId=${clubId}`
            );
        }
    }

    public getNoGenArtAssortment(clubId: number, firmId?: number): Observable<IAssortment[]> {
        if (firmId) {
            return this.http.get<IAssortment[]>(
                `${environment.apiUrl}/assortment/nogenart?clubId=${clubId}&firmId=${firmId}`
            );
        } else {
            return this.http.get<IAssortment[]>(
                `${environment.apiUrl}/assortment/nogenart?clubId=${clubId}`
            );
        }
    }

    public getWithGenArtAssortment(clubId: number, firmId?: number): Observable<IAssortment[]> {
        if (firmId) {
            return this.http.get<IAssortment[]>(
                `${environment.apiUrl}/assortment/withgenart?clubId=${clubId}&firmId=${firmId}`
            );
        } else {
            return this.http.get<IAssortment[]>(
                `${environment.apiUrl}/assortment/withgenart?clubId=${clubId}`
            );
        }
    }

    public getAssortmentsByGenArt(id: number, clubId: number, firmId?: number): Observable<IAssortment[]> {
        if (firmId) {
            return this.http.get<IAssortment[]>(
                `${environment.apiUrl}/assortment/genart/${id}?clubId=${clubId}&firmId=${firmId}`
            );
        } else {
            return this.http.get<IAssortment[]>(
                `${environment.apiUrl}/assortment/genart/${id}?clubId=${clubId}`
            );
        }
    }

}