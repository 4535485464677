import {NgModule, LOCALE_ID} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthGuard} from './services/auth-guard.service';
import {AuthService} from './services/auth.service';
import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';
import {UserService} from '../shared/services/user/user.service';
import {MaterialFormInputComponent} from '../shared/components/material-form-input/material-form-input.component';
import {ThemeService} from '../shared/services/theme.service';
import {ResetPwComponent} from './reset-pw/reset-pw.component';
import {ConfigService} from '../shared/services/config.service';
import {AdminService} from '../admin/club/services/admin.service';
import {ImprintComponent} from './imprint/imprint.component';
import {FooterComponent} from './footer/footer.component';
import {DisclaimerComponent} from './disclaimer/disclaimer.component';
import {CarService} from '../shared/services/car/car.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        HttpClientModule,
        BrowserModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'de-DE'}, // set de-DE as default locale
        AuthService,
        AuthGuard,
        UserService,
        ThemeService,
        ConfigService,
        AdminService,
        CarService
    ],
    declarations: [
        AuthComponent,
        LoginComponent,
        MaterialFormInputComponent,
        ResetPwComponent,
        ImprintComponent,
        FooterComponent,
        DisclaimerComponent
    ],
    exports: [AuthComponent, MaterialFormInputComponent]
})
export class AuthModule {
}

