<div class="maintenance-details" [@detailAnim]="maintenanceService?.maintenanceSteps">
    <div class="content"
         *ngIf="maintenanceService?.maintenanceSteps">
        <div class="row">
            <div class="col">
                <h1><strong>{{maintenanceService.maintenanceSteps.itemMpText}}</strong>
                    {{maintenanceService.maintenanceSteps.korText}}</h1>
            </div>
        </div>
        <div class="row" *ngFor="let step of maintenanceService?.maintenanceSteps?.mdWorkPos">
            <div class="col-6 text-left">
                <app-checkbox *ngIf="cartService?.isNewestCart()"
                              [isChecked]="step === maintenanceService?.activeMaintenanceStep"
                              [labelText]="step.qualColText"
                              (onChange)="maintenanceService.toggleActiveMaintenance(step)"></app-checkbox>
            </div>
            <div class="col-6 price">
                <span [ngClass]="{'fat': step === maintenanceService?.activeMaintenanceStep}">{{step.quantity}} {{'GENERAL.VALUES.' + step.quantityUnit | translate}}</span>
            </div>
        </div>
        <ng-container>
            <div class="row">
                <div class="col">
                    <hr>
                    <h1 [ngClass]="{'disabled': !(maintenanceService?.activeMaintenanceStep && maintenanceService?.maintenanceSteps?.itemMpText === maintenanceService?.activeMaintenanceStep?.itemMpText)}">
                        {{'MAINTENANCE.ADDITIONAL_WORK' | translate}}</h1>
                </div>
            </div>
            <div class="row" *ngFor="let work of maintenanceService?.maintenance?.mdAddItemMpKorWorksDTO">
                <div class="col">
                    <div class="row" *ngFor="let subWork of work?.mdWorkPos">
                        <div class="col-8">
                            <app-checkbox
                                    [isDisabled]="!(maintenanceService?.activeMaintenanceStep && maintenanceService?.maintenanceSteps?.itemMpText === maintenanceService?.activeMaintenanceStep?.itemMpText)"
                                    [isChecked]="maintenanceService.isChecked(subWork) > -1"
                                    [labelText]="subWork.itemMpText + (subWork.qualColText != 'Standard' ? ' ' + subWork.qualColText : '')"
                                    (onChange)="toggleAdditionalWork(subWork, $event)"
                                    [type]="'checkbox'"
                                    *ngIf="maintenanceService?.activeMaintenance === maintenanceService?.maintenanceSteps && cartService?.isNewestCart()">
                            </app-checkbox>
                            <app-checkbox [isDisabled]="true"
                                          [type]="'checkbox'"
                                          [isChecked]="false"
                                          [labelText]="subWork.itemMpText + (subWork.qualColText != 'Standard' ? ' ' + subWork.qualColText : '')"
                                          *ngIf="maintenanceService?.activeMaintenance !== maintenanceService?.maintenanceSteps">
                            </app-checkbox>
                        </div>
                        <div class="col-4 price">
                            <span [ngClass]="{'fat': maintenanceService.isChecked(subWork) > -1, 'disabled': !(maintenanceService?.activeMaintenanceStep && maintenanceService?.maintenanceSteps?.itemMpText === maintenanceService?.activeMaintenanceStep?.itemMpText)}">{{subWork?.quantity}} {{'GENRAL.VALUES.' + subWork?.quantityUnit | translate}}</span>
                        </div>
                    </div>
                    <div class="spacer"></div>
                </div>
            </div>
        </ng-container>
        <div class="row">
            <div class="col">
                <app-article-toggle [text]="true"
                                    [service]="maintenanceService"
                                    *ngIf="(maintenanceService?.activeMaintenanceStep && maintenanceService?.maintenanceSteps?.itemMpText === maintenanceService?.activeMaintenanceStep?.itemMpText) && ((location.path().startsWith('/work') && userService.allow('WORK-DATA')) || (location.path().startsWith('/maintenance') && userService.allow('MAINTENANCE-DATA')))"></app-article-toggle>

            </div>
        </div>
    </div>
</div>
