import {Component, OnInit} from '@angular/core';
import {CartService} from '../services/cart.service';
import {UserService} from '../../shared/services/user/user.service';
import {ConfigService} from '../../shared/services/config.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-cart-table',
    templateUrl: './cart-table.component.html',
    styleUrls: ['./cart-table.component.scss']
})
export class CartTableComponent implements OnInit {

    constructor(
        public cartService: CartService,
        public userService: UserService,
        public configService: ConfigService,
        private router: Router) {
    }

    ngOnInit() {
    }

    public routeToArticleSelection() {
        this.router.navigate(['/cart/article-selection']);
        this.cartService.selectedArticle = undefined;
    }

    public delete() {
        this.cartService.currentCart.comment = '';
        this.cartService.setComment('');
    }
}
