import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UserService} from './user/user.service';
import {CarService} from './car/car.service';
import {ConfigService} from './config.service';

@Injectable({
    providedIn: 'root'
})
export class StateService {

    public tyreContent: BehaviorSubject<string> = new BehaviorSubject<string>('LOADING');
    public articleContent: BehaviorSubject<string> = new BehaviorSubject<string>('LOADING');
    public technicalDataContent: BehaviorSubject<string> = new BehaviorSubject<string>('LOADING');
    public workContent: BehaviorSubject<string> = new BehaviorSubject<string>('LOADING');
    public maintenanceContent: BehaviorSubject<string> = new BehaviorSubject<string>('LOADING');
    public graphicalPartSearchContent: BehaviorSubject<string> = new BehaviorSubject<string>('LOADING');
    public partnerContent: BehaviorSubject<string> = new BehaviorSubject<string>('LOADING');
    private tecrmiError: boolean = false;

    constructor(private userService: UserService,
                private carService: CarService,
                private configService: ConfigService) {

        this.userService.tecrmiError$.subscribe((status: boolean) => {
            this.tecrmiError = status;
            this.updateAll();
        });
        this.userService.accountData$.subscribe(() => {
            this.updateAll();
        });

        this.carService.selectedCarChanged$.subscribe(() => {
            this.updateAll();
        });
    }

    public updateAll(): void {
        this.updateTyreContent();
        this.updateArticleContent();
        this.updatePartnerContent();
        this.updateGraphicalPartSearchContent();
        this.updateMaintenanceContent();
        this.updateWorkContent();
        this.updateTechnicalDataContent();
    }

    private getAdcTypeId(): number {
        return (this.carService.selectedCar && this.carService.selectedCar.adcTypeId) ? this.carService.selectedCar.adcTypeId : 0;
    }

    // ---------------------------- Article ---------------------------- //

    public updateArticleContent(): void {
        if (!this.configService.canRootToPage('ARTICLE')) {
            this.articleContent.next('LOCKED');
            return;
        } else {
            this.articleContent.next('CONTENT');
            return;
        }
    }

    // ---------------------------- Technical Data ---------------------------- //

    public updateTechnicalDataContent(): void {
        if (!this.userService.allow('TECHNICAL-DATA') || !this.configService.canRootToPage('TECHNICAL_DATA')) {
            this.technicalDataContent.next('LOCKED');
            return;
        }

        if (this.tecrmiError) {
            this.technicalDataContent.next('TECRMI_ERROR');
            return;
        }

        if (!(this.getAdcTypeId() > 0)) {
            this.technicalDataContent.next('CAR_SELECTION');
            return;
        }

        if (this.getAdcTypeId() <= 0 && this.carService.selectedCar) {
            this.technicalDataContent.next('NO_DATA');
            return;
        }

        if (this.getAdcTypeId() > 0) {
            this.technicalDataContent.next('CONTENT');
            return;
        }
    }

    // ---------------------------- Work ---------------------------- //
    public changeWorkContent(type: string): void {
        this.workContent.next(type);
    }

    public updateWorkContent(): void {
        if (!this.userService.allow('WORK-DATA') || !this.configService.canRootToPage('WORKING_VALUES')) {
            this.workContent.next('LOCKED');
            return;
        }

        if (this.tecrmiError) {
            this.workContent.next('TECRMI_ERROR');
            return;
        }

        if (!this.carService.selectedCar) {
            this.workContent.next('CAR_SELECTION');
            return;
        }

        if (this.getAdcTypeId() <= 0 && this.carService.selectedCar) {
            this.workContent.next('NO_DATA');
            return;
        }

        if (this.getAdcTypeId() > 0) {
            this.workContent.next('CONTENT');
            return;
        }
    }

    // ---------------------------- Maintenance ---------------------------- //
    public changeMaintenanceContent(type: string): void {
        this.maintenanceContent.next(type);
    }

    public updateMaintenanceContent(): void {
        if (!this.userService.allow('MAINTENANCE-DATA') || !this.configService.canRootToPage('MAINTENANCE')) {
            this.maintenanceContent.next('LOCKED');
            return;
        }

        if (this.tecrmiError) {
            this.maintenanceContent.next('TECRMI_ERROR');
            return;
        }

        if (!this.carService.selectedCar) {
            this.maintenanceContent.next('CAR_SELECTION');
            return;
        }

        if (this.getAdcTypeId() <= 0 && this.carService.selectedCar) {
            this.maintenanceContent.next('NO_DATA');
            return;
        }

        if (this.getAdcTypeId() > 0) {
            this.maintenanceContent.next('CONTENT');
            return;
        }
    }

    // ---------------------------- Tyre ---------------------------- //
    public changeTyreContent(type: string): void {
        this.tyreContent.next(type);
    }

    public updateTyreContent(): void {
        if (!this.userService.allow('TYRE') || !this.configService.canRootToPage('WHEELS')) {
            this.tyreContent.next('LOCKED');
            return;
        }

        if (this.tecrmiError) {
            this.tyreContent.next('TECRMI_ERROR');
            return;
        }

        if (!this.carService.selectedCar) {
            this.tyreContent.next('CAR_SELECTION');
            return;
        }

        if (this.getAdcTypeId() <= 0 && this.carService.selectedCar) {
            this.tyreContent.next('NO_DATA');
            return;
        }

        if (this.getAdcTypeId() > 0) {
            this.tyreContent.next('CONTENT');
            return;
        }
    }

    // ---------------------------- Graphical Part Search ---------------------------- //
    public updateGraphicalPartSearchContent(): void {
        if (this.userService.bodygraphicsType === 'NONE' || !this.configService.canRootToPage('GRAPHICAL_PART_SEARCH')) {
            this.graphicalPartSearchContent.next('LOCKED');
            return;
        }

        if (!this.carService.selectedCar) {
            this.graphicalPartSearchContent.next('CAR_SELECTION');
            return;
        }

        if (this.carService.selectedCar) {
            this.graphicalPartSearchContent.next('CONTENT');
            return;
        }
    }

    // ---------------------------- Partner ---------------------------- //
    public updatePartnerContent(): void {
        if (!this.configService.canRootToPage('PARTNER')) {
            this.partnerContent.next('LOCKED');
            return;
        } else {
            this.partnerContent.next('CONTENT');
            return;
        }
    }
}
