import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IOrderAdminMailAttachmentSmall, IOrderAdminMailContent} from '../../../../shared/interfaces/DTO/order/orderAdmin';
import {GlobalAdminRestService} from '../../services/global-admin.rest.service';
import {GlobalAdminService} from '../../services/global-admin.service';

@Component({
  selector: 'app-global-ordermail',
  templateUrl: './global-ordermail.component.html'
})
export class GlobalOrdermailComponent implements OnInit {

  @Input() orderMail: IOrderAdminMailContent;
  @Output() public closeOrderMail: EventEmitter<any> = new EventEmitter<any>();

  constructor(private globalAdminRestService: GlobalAdminRestService,
              private globalAdminService: GlobalAdminService) { }

  ngOnInit(): void {
  }

  public onEvent(event: any) {
    event.stopPropagation();
  }

  public download(attachment: IOrderAdminMailAttachmentSmall) {
    window.open(this.globalAdminRestService.downloadmailattachment(this.globalAdminService.selectedOrderId, this.orderMail.id, attachment.id), '_blank');
  }
}
