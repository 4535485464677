import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../shared/services/user/user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ErrorHandlerService} from '../../../../shared/components/error-handler/services/error-handler.service';
import {ISupplierLoginData} from '../../../../shared/interfaces/DTO/firm/supplierLoginData';
import {ConfigService} from '../../../../shared/services/config.service';
import {HelperService} from '../../../../shared/services/helper.service';
import {CompanyAdminService} from '../../../company/services/company-admin.service';

@Component({
    selector: 'app-firm-club-supplier',
    templateUrl: './firm-supplier.component.html'
})
export class FirmSupplierClubComponent implements OnInit {
    public suppliers: ISupplierLoginData[];
    public selectedSupplier: ISupplierLoginData;
    public isSort: boolean = false;
    public supplierTypes = ['STANDARD', 'SPECIAL'];
    public standardSupp = []
    public specializedSupp = []
    public loadSupplier: boolean = false;

    constructor(private router: Router,
                public userService: UserService,
                private route: ActivatedRoute,
                private helperService: HelperService,
                public modalService: NgbModal,
                private companyAdminService: CompanyAdminService,
                public adminService: AdminService,
                public errorHandlerService: ErrorHandlerService,
                public changeDetectorRef: ChangeDetectorRef,
                public configService: ConfigService) { }

    ngOnInit() {
        this.route.params.subscribe(
            (params) => {
                if (!this.adminService.selectedFirm || this.adminService.selectedFirm.firmId !== params['id']) {
                    this.adminService.getFirmDataById(params['id']).subscribe(
                        (firm) => {
                            this.adminService.selectedFirm = firm;
                            this.updateSupplierList();
                        }
                    )
                } else {
                    this.updateSupplierList();
                }
            }
        );

        if (!this.adminService.selectedFirm) {
            this.router.navigate(['admin']);
        }
    }


    public updateSupplierList() {
        this.loadSupplier = true;
        this.adminService.getSuppliersRest(this.adminService.selectedFirm.firmId).subscribe(
        (response) => {
            if (response) {
                this.loadSupplier = false;
                this.standardSupp = [];
                this.specializedSupp = [];
                this.suppliers = response;
                for (let i = 0; i < this.suppliers.length; i++) {
                    if (!this.suppliers[i].specialized) {
                        this.standardSupp.push(this.suppliers[i])
                    } else {
                        this.specializedSupp.push(this.suppliers[i])
                    }
                }
                for (let i = 0; i < this.standardSupp.length; i++) {
                    this.standardSupp[i].sortNo = i;
                }

                for (let i = 0; i < this.specializedSupp.length; i++) {
                    this.specializedSupp[i].sortNo = i + this.standardSupp.length;
                }


                if (this.suppliers[0].sortNo === 0) {
                    for (let i = 0; i < this.suppliers.length; i++) {
                        this.suppliers[i].sortNo = i + 1;
                    }
                }
                this.changeDetectorRef.markForCheck();
            }
        }, (error) => {
            this.loadSupplier = false;
            this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_SUPPLIER', 'error');
            });
    }

    public swapSuppliers(event: any, typ: string) {
        if (event.container.id === event.previousContainer.connectedTo) {
            if (typ === 'STANDARD') {
                for (let i = 0; i < this.standardSupp.length; i++) {
                    this.standardSupp[i].specialized = false;
                }
            } else {
                for (let i = 0; i < this.specializedSupp.length; i++) {
                    this.specializedSupp[i].specialized = true;
                }
            }
        }
        this.suppliers = this.standardSupp.concat(this.specializedSupp);
        for (let i = 0; i < this.suppliers.length; i++) {
            this.suppliers[i].sortNo = i + 1;
        }
    }

    public saveSupplierList() {
        this.isSort = false;
        this.companyAdminService.updateAllSuppliersRest(this.suppliers).subscribe(
            (success) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_UPDATE', 'error');
            }
        );
    }

    public hasInactiveSupplier() {
        if (this.suppliers) {
            for (const supp of this.suppliers) {
                if (!supp.active) {
                    return true;
                }
            }
        }
    }

    public hasActiveSupplier() {
        if (this.suppliers) {
            for (const supp of this.suppliers) {
                if (supp.active) {
                    return true;
                }
            }
        }
    }

    public delete(deleteRequest, supp: any) {
        if (!deleteRequest) {
            supp.delete = true;
        } else {
            this.selectedSupplier = supp;
            this.adminService.deleteSupplierRest(supp).subscribe(
                (success) => {
                    if (this.adminService.selectedFirm) {
                        this.adminService.getFirmDataById(this.adminService.selectedFirm.firmId);
                    }
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                    this.updateSupplierList();
                    return true;
                },
                (error) => {
                    this.errorHandlerService.throwError(error.status);
                }
            );
        }
    }
    public createSupplier() {
        this.adminService.supplier = {customerNo: '', supplier: '', password: '', username: '', firmId: 0, id: '', sortNo: this.suppliers.length + 1, active: true};
        this.router.navigate(['admin/suppliers/supplier/', '0', this.adminService.selectedFirm.firmId]);
    }
    public reset() {
        this.router.navigate(['admin']);
    }
    public navigate(supp: ISupplierLoginData) {
        this.adminService.supplier = supp;
        this.router.navigate(['admin/suppliers/supplier/', supp.id, this.adminService.selectedFirm.firmId]);
    }
}
