import {EventEmitter, Injectable} from '@angular/core';
import {CarService} from '../../shared/services/car/car.service';
import {CartService} from '../../cart/services/cart.service';
import {CartRestService} from '../../cart/services/cart.rest.service';
import {TecRmiRestService} from '../../shared/services/tecrmi/tecrmi.rest.service';
import {IWorkItem} from '../interfaces/work-tree';
import {IWorkStep} from '../interfaces/work-step';
import {ArticlesRestService} from '../../articles/services/articles.rest.service';
import {ArticlesDetailsService} from '../../shared/services/articles-details.service';
import {UserService} from '../../shared/services/user/user.service';
import {CompanyAdminService} from '../../admin/company/services/company-admin.service';
import {IArticlesWorkId} from '../../articles/interfaces/articlesWorkId';
import {Router} from '@angular/router';
import {CustomerDataService} from '../../shared/services/customerData.service';
import {IWorkPositionContainer} from '../../shared/interfaces/DTO/work/workPositionContainer';
import {IAddWorkPositionRequest} from '../../shared/interfaces/DTO/cart/addWorkPositionRequest';
import {ICartLabourWithParts} from '../../shared/interfaces/DTO/cart/cartLabourWithParts';
import {ICalculationCost} from '../../shared/interfaces/DTO/calculationCost';
import {IItem} from '../../articles/interfaces/article';
import {HelperService} from '../../shared/services/helper.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ConfigService} from '../../shared/services/config.service';

@Injectable()
export class WorkService extends ArticlesDetailsService {

    // public
    public i18n_title = 'WORKING_VALUES';
    public work: any[];
    public posByCar: ICartLabourWithParts[];
    public selectedOption: any;
    public kTypeId: number;
    public setWorkExternal$: EventEmitter<any> = new EventEmitter<any>();
    public hasArticles = false;
    public articleCount = 0;
    public selectedWorkItems: any[] = [];
    public foldedCollapsePanel: string[] = [];
    public showTecRMILock: boolean = false;
    public workPos: IAddWorkPositionRequest = {
        adcTypeId: 0,
        description: 'x',
        kTypeId: 0,
        labourTime: 0,
        workId: 0,
        workType: 'x',
        itemMpId: 0,
        ltQualColId: 0,
        mdQualColId: 0,
        repairId: 0,
        vin: '',
        producerName: '',
        quantity: 0,
        quantityUnit: ''
    };
    public workSteps: IWorkStep[];
    public selectedItem: IWorkStep;
    public adcTypeId: number;
    public showGallery = false;
    public ltQualColId: number;
    public selectedItems: IWorkPositionContainer[];
    public loadWorkList = false;
    public calculationCostHolder: Map<number, ICalculationCost> = new Map<number, ICalculationCost>();
    public selectedAmount: any;

    private updateFilterTime: Date;

    // work-articles
    public articleList = [];
    public selectedChip: any;
    public articles: IArticlesWorkId = {
        adcTypeId: 0,
        workId: 0,
        pageSize: 0,
        linkingTargetType: '',
        itemMpId: 0,
        kTypeId: 0,
        page: 1,
        searchForAllArticles: false
    };
    public showButton = false;
    public showModal = false;
    public showDependencyError = false;
    public tempWork: any;
    public filterGroupNames: string[] = [];
    private onCartChangeTime: Date;
    private selectArticleTime: Date;
    private posByCartTime: Date;
    public onArticleSelect: EventEmitter<any> = new EventEmitter<any>();
    public updateFilterBar: EventEmitter<any> = new EventEmitter<any>();
    public updateCheckbox: EventEmitter<any> = new EventEmitter<any>();
    public selectFirstTabEmitter: EventEmitter<any> = new EventEmitter<any>();
    public showAdditionalWork = true;
    public selectedTab: any;
    public showDocumentWithLink: any;
    public byWorkIdTimer: number;

    public selectedPseudoArticle: IItem;
    public showPseudoArticle = false;


    constructor(public carService: CarService,
                private tecRmiRestService: TecRmiRestService,
                private toastr: ToastrService,
                private translate: TranslateService,
                public userService: UserService,
                private cartRestServoce: CartRestService,
                private cartService: CartService,
                private articlesRestService: ArticlesRestService,
                private helperService: HelperService,
                private companyAdmin: CompanyAdminService,
                public router: Router,
                private customerDataService: CustomerDataService,
                private config: ConfigService) {
        super(articlesRestService, carService, userService, companyAdmin, translate, toastr, config);

        this.userService.accountData$.subscribe((step) => {
            if (step) {
                this.reset();
            }
        });

        this.carService.selectedCarChanged$.subscribe(
            (step) => {
                this.calculationCostHolder.clear();
                if (this.userService.allow('WORK-DATA')) {
                    this.setCar();
                }
            }
        );

        this.cartService.onCartChange.subscribe((change) => {
            if (!this.onCartChangeTime) {
                this.onCartChangeTime = new Date();
                if (change) {
                    this.reset();
                } else {
                    this.showModal = false;
                    this.getPosByCart();
                }
            } else {
                const temp = new Date();
                if (temp.getTime() - this.onCartChangeTime.getTime() > 500) {
                    this.onCartChangeTime = temp;
                    if (change) {
                        this.reset();
                    } else {
                        this.showModal = false;
                        this.getPosByCart();
                    }
                }
            }

        });

        this.cartService.onItemRemove.subscribe((step) => {
            this.selectedWorkItems = [];
            if (this.userService.allow('WORK-DATA')) {
                this.getPosByCart()
            }
        });

        if (!(this.adcTypeId && this.adcTypeId > 0)) {
            this.setCar();
        }
    }

    public getBacklink() {
        return '/work/article';
    }

    public onMessageBtnClick(event: any) {
        switch (event.index) {
            // Abbrechen
            case 0: {
                this.showDependencyError = false;
                this.updateCheckbox.emit();
                break;
            }
            // Nein
            case 1: {
                this.showDependencyError = false;
                this.cartRestServoce.deletePos(this.tempWork, 'DELETE_WITHOUT_DEPENDENT').subscribe(
                    (success) => {
                        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_ADD_TO_CART', 'success');
                        this.tempWork = undefined;
                        this.getPosByCart();
                        this.cartService.updateCartCount();
                    });
                break;
            }
            // Ja
            case 2: {
                this.showDependencyError = false;
                this.cartRestServoce.deletePos(this.tempWork, 'DELETE_WITH_DEPENDENT').subscribe(
                    (success) => {
                        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_ADD_TO_CART', 'success');
                        this.tempWork = undefined;
                        this.getPosByCart();
                        this.cartService.updateCartCount();
                    });
                break;
            }
        }
    }

    public checkPos(pos) {
        if (!this.selectedChip) {
            return false;
        } else {
            return JSON.stringify(pos) === JSON.stringify(this.selectedChip);
        }
    }

    public hasAllArticle() {

        if (!this.showModal) {
            return false;
        }

        for (const article of this.articleList) {
            if (article.partSets[0] && article.partSets[0].parts[0].position === null && !article.disabled) {
                return false;
            }
        }

        return true;
    }

    public toggleState(article: any) {

        if (article.partSets[0] && article.partSets[0].parts[0].position !== null) {
            if (article.partSets[0].parts[0].position) {
                this.cartService.deletePos(article.partSets[0].parts[0].position.posId);
            }
            this.showModal = false;
        } else if (article.disabled) {
            article.disabled = false;
            this.selectArticle(article);
            this.showModal = false;
        } else if (!article.disabled && article.partSets[0].parts[0].position === null) {
            article.disabled = true;
            this.showArticle();
        }
    }

    public reaktivateChip(chip: any) {
        if (chip.disabled) {
            chip.disabled = false;
            this.showModal = false;
        }
    }

    public setPseudoArticle(article: IItem) {
        if (article && article.pseudoArticle) {
            this.showPseudoArticle = true;
            this.selectedPseudoArticle = article;
        }
    }

    public setChip(chip: any) {
        this.selectedChip = chip;
    }

    private selectArticleTimed(article: any) {
        if (article.disabled) {
            this.articleList[this.articleList.indexOf(article)].disabled = false;
        }
        this.onArticleSelect.emit();
        this.setChip(article);
        this.articles.kTypeId = this.kTypeId;
        this.articles.adcTypeId = this.adcTypeId;
        this.articles.itemMpId = article.mountPositionId;
        this.articles.linkingTargetType = 'P';
        this.articles.pageSize = 20;
        this.articles.page = 1;
        this.articles.workId = article.workId;
        this.articles.activeFilters = [];
        this.resultItems = [];
    }

    public updateArticle() {
        this.articles.kTypeId = this.kTypeId;
        this.articles.kTypeId = this.kTypeId;
        this.articles.adcTypeId = this.adcTypeId;
        this.articles.itemMpId = this.selectedTab.mountPositionId;
        this.articles.linkingTargetType = 'P';
        this.articles.pageSize = 20;
        this.articles.page = 1;
        this.articles.workId = this.selectedTab.workId;
        this.articles.activeFilters = [];
    }

    public selectArticle(article: any) {
        if (this.searchResult) {
            this.searchResult.activeFilters = [];
        }
        if (!this.selectArticleTime) {
            this.selectArticleTime = new Date();
            this.selectArticleTimed(article);
        } else {
            const temp = new Date();
            if (temp.getTime() - this.selectArticleTime.getTime() > 500) {
                this.selectArticleTime = temp;
                this.selectArticleTimed(article);
            }
        }
    }

    public checkArticleList() {
        if (this.articleList.length > 0) {
            let temp = false;
            for (const article of this.articleList) {
                if (article.partSets[0].parts[0].position === null && !article.disabled) {
                    this.selectArticle(article);
                    temp = true;
                    break;
                }
            }
            if (!temp) {
                if (this.router.url.indexOf('/work/article') > -1) {
                    this.showModal = true;
                    this.searchResult = undefined;
                    this.onArticleSelect.emit();
                }
            }
        } else {
            if (this.router.url.indexOf('/work/article') > -1) {
                this.showModal = true;
                this.searchResult = undefined;
                this.onArticleSelect.emit();
            }
        }
    }

    public showArticle() {
        this.showModal = false;
        const tempArticleList = this.articleList;
        this.articleList = [];
        if (this.posByCar) {
            for (const pos of this.posByCar) {
                for (const article of pos.partGroup) {
                    tempArticleList.forEach(function (element) {
                        if (element.mountPositionId === article.mountPositionId && element.workId === article.workId) {
                            if (element.disabled === true) {
                                article.disabled = true;
                            } else {
                                element.disabled = false;
                            }
                        }
                    });
                    this.articleList.push(article);
                }
            }
        }
        this.checkArticleList();
        this.showAdditionalWork = false;
    }

    public loadNext(event: any) {
        if (event !== 0 && !this.infinityLoading) {
            if (this.userService.enableInfiniteScroll && this.searchResult && (this.searchResult.count / 20) > this.searchResult.page) {
                this.updateArticleSearch(this.searchResult.page + 1, this.searchResult.activeFilters, this.searchResult.searchForAllArticles);
            }
        }

    }

    public getFilterGroupNames() {
        this.filterGroupNames = [];
        for (const filter of this.searchResult.activeFilters) {
            if (this.filterGroupNames.indexOf(filter.groupName) < 0) {
                this.filterGroupNames.push(filter.groupName);
            }
        }

        this.filterGroupNames.sort();
    }

    public toggleFolded(event: any, name: string) {
        if (!event) {
            if (this.foldedCollapsePanel.indexOf(name) < 0) {
                this.foldedCollapsePanel.push(name);
            }
        } else {
            this.foldedCollapsePanel.splice(this.foldedCollapsePanel.indexOf(name), 1);
        }
    }

    private setCar() {
        this.ltQualColId = 0;

        if (this.carService.selectedCar) {
            this.adcTypeId = this.carService.selectedCar.adcTypeId;
            this.kTypeId = this.carService.selectedCar.kTypeId;
        } else {
            this.adcTypeId = 0;
            this.kTypeId = 0;
        }

        if (this.adcTypeId && this.adcTypeId > 0) {
            this.getPosByCart();
        }
    }

    public showDetails() {
        return this.work;
    }

    public selectOption(option: any) {
        this.selectedOption = option;
    }

    public setSelectedItem(item: IWorkStep) {
        this.selectedItem = item;
        this.tecRmiRestService.getWorkManuals(this.selectedItem.adcTypeId, this.selectedItem.workId).subscribe((workManuals) => {
            this.selectedItem.documents = workManuals;
        });

    }

    public getSelectedItem() {
        return this.selectedItem;
    }

    public createLabourWorkPosition(work: any) {
        if (work) {
            work.adcTypeId = this.carService.getAdcTypeId();
            work.kTypeId = this.carService.getKTypeId();
            work.description = work.mountPositionText + ' ' + work.repairText;
            work.labourTime = work.workTime;
            work.workType = 'WORK';
            work.vin = this.carService.getVin();
            work.producerName = this.carService.getCarProducerName();
        }

        return work;
    }

    public addWorkToCart(step: any, item?: IWorkItem, checkbox?: any) {
        if (!step.posIdInCart || !(step.posIdInCart > 0)) {
            this.workPos = this.createLabourWorkPosition(step);

            this.ltQualColId = step.ltQualColId;

            this.workPos = step;
            const selectedCar = this.carService.selectedCar,
                kTypeId = (selectedCar && selectedCar.kTypeId) ? selectedCar.kTypeId : 0,
                adcTypeId = (selectedCar && selectedCar.adcTypeId) ? selectedCar.adcTypeId : 0;
            this.workPos.adcTypeId = adcTypeId;
            this.workPos.kTypeId = kTypeId;
            if (step.itemQualifierText !== 'Standard') {
                this.workPos.description = step.mountPositionText + ' ' + step.repairText + ' ' + step.itemQualifierText;
            } else {
                this.workPos.description = step.mountPositionText + ' ' + step.repairText;
            }
            this.workPos.labourTime = step.workTime;
            this.workPos.workId = step.workId;
            this.workPos.workType = 'WORK';
            this.cartRestServoce.addWorkToCart(this.workPos).subscribe(
                (success) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_ADD_TO_CART', 'success');
                    this.selectedWorkItems = [];
                    this.cartService.updateCartCount();
                    this.selectedItems.forEach(function (element) {
                        if (JSON.stringify(element) === JSON.stringify(step)) {
                            element.posIdInCart = parseInt(success);
                        }
                    });
                },
                (error) => {
                    checkbox.isChecked = false;
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_ADD_WORK_TO_CART', 'error');
                }
            );
        }
    }

    public removeWorkFromCart(step: any) {
        this.tempWork = step.posIdInCart;
        this.cartRestServoce.deletePos(step.posIdInCart, 'DELETE').subscribe(
            (success) => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_CART_POS', 'success');
                this.cartService.updateCartCount();
                this.showDependencyError = false;
                this.selectedItems.forEach(function (element) {
                    if (JSON.stringify(element) === JSON.stringify(step)) {
                        element.posIdInCart = 0;
                    }
                });
                this.getPosByCart();
            }, (error) => {
                if (error.status === 424) {
                    this.showDependencyError = true;
                }
            });
    }

    public addWork(work: any) {
        this.work = work;
    }

    public removeWork() {
        this.work = undefined;
    }

    public toggleActiveFilter(filter: any) {

        const found = this.findFilter(filter, false);

        if (found >= 0) {
            this.searchResult.activeFilters.splice(found, 1);
        } else {
            if (this.searchResult.activeFilters) {
                this.searchResult.activeFilters.push(filter);
            } else {
                this.searchResult.activeFilters = [];
                this.searchResult.activeFilters.push(filter);
            }
        }
    }

    public updateFilter() {
        const time: Date = new Date();
        if ((!this.updateFilterTime || time.getTime() - this.updateFilterTime.getTime() > 500) && !this.articlesLoading && !this.infinityLoading) {
            this.articles.kTypeId = this.kTypeId;
            this.articles.adcTypeId = this.adcTypeId;
            this.articles.itemMpId = this.selectedTab.mountPositionId;
            this.articles.linkingTargetType = 'P';
            this.articles.pageSize = 20;
            this.articles.page = 1;
            this.articles.workId = this.selectedChip.workId;
            this.articles.activeFilters = this.searchResult.activeFilters;
            this.articles.searchForAllArticles = this.searchResult.searchForAllArticles;

            const aktTime: number = new Date().getTime();
            if (!this.byWorkIdTimer || aktTime - this.byWorkIdTimer > 500) {
                this.byWorkIdTimer = aktTime;
                this.updateArticlesByWorkId(this.articles);
            }
        }
    }

    /*
    the display value is to translate the return value to a true / false value instead a number
    this is needed for the checkbox
     */
    public findFilter(filter: any, display: boolean) {

        let found: any = -1;

        if (this.searchResult.activeFilters && this.searchResult.activeFilters.length > 0) {
            for (let i = 0; i < this.searchResult.activeFilters.length; i++) {
                if (JSON.stringify(this.searchResult.activeFilters[i]) === JSON.stringify(filter)) {
                    found = i;
                }
            }
        } else {
            found = -1;
        }

        if (found === -1 && display) {
            found = false;
        } else if (found >= 0 && display) {
            found = true;
        }

        return found;
    }

    public resetFilter() {
        this.searchResult.activeFilters = [];
        this.updateArticleSearch(1, this.searchResult.activeFilters);
    }

    public showAllArticles() {
        if (this.activeSubscription) {
            this.activeSubscription.unsubscribe();
        }
        this.updateArticleSearch(1, this.searchResult.activeFilters, true);
    }

    public getPosByCart() {
        if (this.router.url.indexOf('/work') > -1 && this.adcTypeId) {

            this.tecRmiRestService.getCartLabourPositionByCar(this.adcTypeId, this.kTypeId, this.ltQualColId, this.carService.getVin()).subscribe(
                (response) => {
                    this.articleCount = 0;
                    if (this.posByCar !== response) {
                        this.posByCar = response;
                    }
                    this.showArticle();
                    if (this.hasArticles) {
                        this.hasArticles = false;
                    }
                    for (const pos of this.posByCar) {
                        if (!(this.selectedWorkItems.indexOf(pos.position.workId) >= 0)) {
                            this.selectedWorkItems.push(pos.position.workId);
                        }
                        if (pos.partGroup.length > 0) {
                            this.hasArticles = true;
                            this.articleCount = this.articleCount + pos.partGroup.length;
                        }
                    }
                }
            )
        }
    }
}
