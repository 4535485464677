import {Component, OnInit} from '@angular/core';
import {MaintenanceService} from "../services/maintenance.service";
import {CustomerDataService} from '../../shared/services/customerData.service';
import {HelperService} from "../../shared/services/helper.service";
import {UserService} from "../../shared/services/user/user.service";
import {Location} from "@angular/common";
import {CarService} from '../../shared/services/car/car.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigService} from '../../shared/services/config.service';
import {TecRmiRestService} from '../../shared/services/tecrmi/tecrmi.rest.service';
import {CartService} from '../../cart/services/cart.service';

@Component({
    selector: 'app-calculation',
    templateUrl: './calculation.component.html',
    styleUrls: ['./calculation.component.scss']
})
export class CalculationComponent implements OnInit {

    constructor(public maintenanceService: MaintenanceService,
                private helperService: HelperService,
                public dataService: CustomerDataService,
                public userService: UserService,
                public location: Location,
                public configService: ConfigService,
                private carService: CarService,
                private router: Router,
                private route: ActivatedRoute,
                private customerDataService: CustomerDataService,
                public tecRmiRestService: TecRmiRestService,
                public cartService: CartService) {
    }

    ngOnInit() {

        if (!this.maintenanceService.tecRMICalculationUrl && this.location.path().endsWith('/cart/calculation')) {
            this.router.navigate(['/cart'])
        }
        this.maintenanceService.localDataHolder = this.helperService.recursiveDeepCopy(this.dataService.getDataHolder());
    }


    public printIframe(frameId: string) {
        const frame = window.frames[frameId];
        if (!frame) {
            return;
        }

        if (this.helperService.browserIsIE()) {
            frame.document.execCommand('print', false, null);
        } else {
            const frames: any = document.getElementById(frameId);
            frames.contentWindow.focus();
            frames.contentWindow.print();
        }
    }
    public showTecRMICalculation() {
        if (this.location.path().indexOf('/calculation') > -1) {
            return this.maintenanceService.tecRMICalculationUrl;
        } else {
            return this.maintenanceService.tecRMICalculationUrl;
        }
    }
}
