<li (click)="orderHistoryService.setCart(item)"
    routerLink="/company-admin/orderhistory/{{orderHistoryService?.selectedHistory}}/{{item.id}}">
    <div class="row">
        <div class="col">
            <i class="fa fa-shopping-cart" aria-hidden="true"></i>
            <span class="item-name">{{item.name}} ({{ item.created | date:'dd.MM.yyyy' }})</span>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <span class="item-name"><b>{{'ORDER.ORDERNUMBER' | translate}}</b> {{item.platformOrderId}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col">
                  <span class="item-name"><b>{{'SUPPLIERS.USERNAME' | translate}}:</b> {{item.loginName}}
                      <span *ngIf="userService.allow('GLOBAL-ADMIN')"> (ID: {{item.loginId}})</span>
                  </span>
        </div>
    </div>
    <div class="row" *ngIf="item.reference">
        <div class="col">
                  <span class="item-name"><b>{{'CART.REFERENCE' | translate}}:</b> {{item.reference}}
                  </span>
        </div>
    </div>
</li>
