<div class="global-oe-supplier">
  <div class="heading blue">
        <span class="clickable" style="cursor: pointer;" routerLink="/global-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
          {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
    <span class="clickable" routerLink="/global-admin/import">
            <i class="fa fa-caret-right"></i>
      {{'GLOBAL_ADMIN.GLOBAL_MAPPING_IMPORT' | translate}}
        </span>
    <span class="clickable">
            <i class="fa fa-caret-right"></i>
      {{'GLOBAL_ADMIN.OE_SUPPLIER' | translate}}
        </span>
  </div>
  <div class="panel-content center-content white-background">
    <h1>{{'GLOBAL_ADMIN.OE_SUPPLIER' | translate}} - {{'CLUB_ADMIN.CONFIG' | translate}}</h1>

    <div class="row">
      <div class="col-6">
        <div class="custom-dropdown">
          <select [(ngModel)]="supplier">
            <option *ngFor="let _supplier of suppliers" [ngValue]="_supplier">{{_supplier}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="file">Choose File</label>
          <input type="file"
                 id="file"
                 (change)="handleFileInput($event.target.files)">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button class="base-btn success" [disabled]="!fileToUpload" (click)="handleSubmit($event, fileToUpload)">
          <i *ngIf="uploadingProgressing" class="fa fa-spinner" [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i>
          <span *ngIf="uploadingProgressing"> uploading <b>{{uploadProgress}}%</b> </span>
          <span *ngIf="!uploadingProgressing"> upload </span>
        </button>
      </div>
    </div>
    <div class="row" *ngIf="responseMSG">
      <div class="col">
        <span>Response:</span>
      </div>
    </div>
    <div class="row" *ngIf="responseMSG">
      <div class="col-12">
        {{responseMSG}}
      </div>
    </div>
  </div>
</div>
