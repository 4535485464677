import {Component, OnInit} from '@angular/core';
import {ConfigService} from '../../../shared/services/config.service';
import {UserService} from '../../../shared/services/user/user.service';

@Component({
  selector: 'app-club-setting',
  templateUrl: './club-setting.component.html'
})
export class ClubSettingComponent implements OnInit {

  constructor(public configService: ConfigService,
              public userService: UserService) {
  }

  ngOnInit() {}
}
