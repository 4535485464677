<div class="transferLog" style="overflow: auto;" (click)="globalAdminService.showTransferLogGrip =false">
    <div class="transferLog-center" (click)="onEvent($event)">
        <div class="row background header-title" style="padding-top: 10px">
            <div class="col-10" style="top: 2px;">
                {{globalAdminService.orderIdGrip}}
            </div>
            <div class="col-2">
                <button class="transferLog-button" (click)="globalAdminService.showTransferLogGrip =false">
                    <i class="fa fa-times"></i>
                </button>
            </div>
        </div>
        <div *ngIf="!globalAdminService.currentOrder.request" class="row background p-3">
            Kein Datensatz vorhanden.
        </div>
        <div *ngIf="globalAdminService.currentOrder as order" class="row background p-3">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <div class="request">
                                    <h1>Request</h1>
                                    <div class="row">
                                        <div class="col">
                                            <span>{{!!order.request ? order.request : 'Kein Datensatz vorhanden.'}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="response">
                                    <h1>Response</h1>
                                    <div class="row">
                                        <div class="col">
                                            <span>{{!!order.response ? order.response : 'Kein Datensatz vorhanden.'}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
