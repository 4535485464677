import {ChangeDetectorRef, Component} from '@angular/core';
import {fadeSmallAnimation} from "../../shared/animations/fadesmall.animation";
import {UserService} from "../../shared/services/user/user.service";
import {Location} from "@angular/common";
import {GlobalAdminService} from './services/global-admin.service';
import {AdminService} from '../club/services/admin.service';

@Component({
  selector: 'app-global',
  templateUrl: './global.component.html',
    animations: [fadeSmallAnimation]
})
export class GlobalComponent {

  constructor(public userService: UserService,
              public location: Location,
              public adminService: AdminService,
              public globalAdminService: GlobalAdminService,
              private cdRef:ChangeDetectorRef) {
      this.adminService.getClubs();
  }

  public getRouteAnimation(outlet) {
      return outlet.activatedRouteData.state;
  }

    ngAfterViewChecked()
    {
        this.cdRef.detectChanges();
    }

}
