<div class="login-wrapper" *ngIf="this.themeService?.auth?.authMethod !== 'SAML'">

    <div class="login">
        <h1><strong>{{'LOGIN.WELCOME' | translate}}</strong>{{'LOGIN.PLS_LOGIN' | translate}}</h1>

        <ngb-alert type="danger" [dismissible]="false" *ngIf="this.authService.error && this.authService.fromLoginComponent">{{'LOGIN.WRONG_DATA' | translate}}</ngb-alert>
        <form name="loginForm" [formGroup]="loginForm">
        <div>
            <app-material-form-input
                    [label]="'LOGIN.USERNAME' | translate"
                    [type]="'text'"
                    [(text)]="loginData.username"
                    [validationErrorMessage]="'VALIDATION.ERROR_LOGIN_USERNAME' | translate"
                    [showValidation]="true"
                    (keydown.enter)="tryLogin()">
            </app-material-form-input>
        </div>
        <div>
            <app-material-form-input
                    [label]="'LOGIN.PASSWORD' | translate"
                    [type]="'password'"
                    [(text)]="loginData.password"
                    [validationErrorMessage]="'VALIDATION.ERROR_LOGIN_PASSWORD' | translate"
                    [showValidation]="true"
                    (keydown.enter)="tryLogin()">
            </app-material-form-input>
        </div>
        </form>
        <div>
            <label class="label" for="checkbox-1-1">
                <input type="checkbox" id="checkbox-1-1" class="custom-checkbox" [(ngModel)]="loginData.stayLoggedIn"/>
                {{ 'LOGIN.STAY_LOGGED_IN' | translate }}
            </label>
        </div>
        <div>
            <button class="signin-btn" [disabled]="isDisabled()" (click)="tryLogin()">
                <i class="fa fa-spinner mr-2" *ngIf="authService.showLoginLoader"
                   [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"></i>
                {{ 'LOGIN.TITLE' | translate }}
            </button>
        </div>
        <hr>
            <a href="#/reset-pw" >
                {{ 'LOGIN.RESET_PASSWORD' | translate }}
            </a>
    </div>
</div>

<app-auth-footer></app-auth-footer>
