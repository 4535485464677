import { Component, OnInit } from '@angular/core';
import {IAdminFirmState} from "../../../../shared/interfaces/adminFirm";
import {IDateModel} from "../../../../shared/interfaces/dateModel";
import {ErrorHandlerService} from "../../../../shared/components/error-handler/services/error-handler.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AdminService} from "../../../club/services/admin.service";
import {CompanyAdminService} from "../../services/company-admin.service";
import {UserService} from "../../../../shared/services/user/user.service";

@Component({
  selector: 'app-wage-rate',
  templateUrl: './wage-rate.component.html',
  styleUrls: ['./wage-rate.component.scss']
})
export class WageRateComponent implements OnInit {

    public role: string;
    public firmData: any;
    public firmID: any;
    public isAdmin: boolean;
    public create: boolean;
    public states: IAdminFirmState;
    public roles: string[];
    public date: Date;
    public dateModel: IDateModel;

    constructor(public companyAdminService: CompanyAdminService,
                private route: ActivatedRoute,
                public userService: UserService,
                private adminService: AdminService,
                private router: Router,
                public errorService: ErrorHandlerService) {
        this.dateModel = {year: 0, month: 0, day: 0};
        this.firmData = {
            active: true,
            address: {
                address1: '',
                address2: '',
                city: '',
                id: 0,
                name1: '',
                name2: '',
                state: {
                    country: {
                        id: 0,
                        iso31661Alpha2: '',
                        iso31661Alpha3: '',
                        iso31661Numeric: '',
                        name: '',
                    },
                    id: 0,
                    iso31662: '',
                    name: '',
                },
                zip: '',
            },
            agreedAgb: true,
            agreedLicence: true,
            customerId: '',
            customerNo: '',
            customerPw: '',
            expirationDate: undefined,
            email: '',
            externalId: '',
            fax: '',
            firmId: 0,
            hourlyWageRate: 0,
            hourlyWageRateChassis: 0,
            hourlyWageRateElectronic: 0,
            hourlyWageRateMechanic: 0,
            loginList: [],
            name: '',
            phone: '',
            roles: [],
            settings: {},
            supplier: '',
            tags: [],
            uuid: '',
            valid: true,
            vat: 19,
        };
    }

    ngOnInit() {
        this.errorService.newError = false;
        this.route.params.subscribe(
            (params) => this.firmID = params['id']
        );

        if (this.firmID > 0 || this.firmID === 'create') {
            this.role = ' - Admin';
            this.isAdmin = true;
            this.adminService.getRolesRest().subscribe((roles: string[]) => this.roles = roles);

            if (this.firmID === 'create') {
                this.create = true;
                this.firmData.address.state.name = 'Bitte auswählen';

            } else {
                this.create = false;
                this.companyAdminService.getFirmDataById(this.firmID).subscribe(
                    (response) => {
                        this.firmData = response;
                    });
            }
        } else {
            this.isAdmin = false;
            this.companyAdminService.getFirmData().subscribe(
                (response) => {
                    this.firmData = response;
                });
        }
    }

    public toggleSelection(role: string) {

        const idx = this.firmData.roles.indexOf(role);
        if (idx > -1) {
            this.firmData.roles.splice(idx, 1);
        } else {
            this.firmData.roles.push(role);
        }

    }

    public createDate() {
        this.date = new Date();
        this.date.setMonth(this.date.getMonth() + 1);
        if (this.date.getMonth() === 0) {
            this.date.setMonth(1);
        }
        this.dateModel.day = this.date.getDate();
        this.dateModel.month = this.date.getMonth();
        this.dateModel.year = this.date.getFullYear();
        this.firmData.expirationDate = this.dateModel;
    }

    public reset() {
        if (this.firmID > 0) {
            this.router.navigate(['admin']);
        } else {
            this.router.navigate(['company-admin']);
        }
    }

    public resetDate() {
        this.dateModel = {year: 0, month: 0, day: 0};
        this.firmData.expirationDate = undefined;
    }

    public saveFirm() {
        if (this.isAdmin) {
            if (this.create) {
                if (this.firmData.address.state.name === 'Bitte auswählen') {
                    this.errorService.throwStringError('Bitte Region auswählen.', 12);
                } else {
                    this.firmData.expirationDate = undefined;
                    // this.firmData = this.adminService.createFirm(this.firmData);
                    if (!this.errorService.newError) {
                        this.router.navigate(['admin/firm/user', 'create', this.firmData.firmId]);
                    }
                }
            } else {
                this.firmData.expirationDate = undefined;
                this.adminService.saveFirm(this.firmData);
            }
        } else {
            this.firmData.expirationDate = undefined;
            this.companyAdminService.saveFirm(this.firmData);
        }
    }

    public showRoles() {
        if (this.adminService.isAdmin() && this.isAdmin) {
            return true;
        } else {
            return false;
        }
    }

    public checkShow() {
        if (this.userService.allow('FIRM-ADMIN') || this.userService.allow('CLUB-ADMIN')) {
            return true;
        } else {
            return false;
        }
    }

    public toggleActive() {
        this.firmData.active = !this.firmData.active;
    }

    public checkEnable() {
        if (this.userService.allow('CLUB-ADMIN') && this.role === 'admin') {
            return false;
        }
        return true;
    }
}
