import {Component, OnInit} from '@angular/core';
import {ExternalNotificationRestService} from '../../../shared/services/external-notification.rest.service';
import {IAllNotification, ICreateNotification} from '../../../shared/interfaces/DTO/notification/notification';
import {AdminService} from '../services/admin.service';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {Dateformat} from '../../../shared/dateformat';
import {HelperService} from '../../../shared/services/helper.service';

@Component({
    selector: 'app-club-notifications',
    templateUrl: './club-notifications.component.html',
    styleUrls: ['./club-notifications.component.scss'],
    providers: [{provide: NgbDateParserFormatter, useClass: Dateformat}]
})
export class ClubNotificationsComponent implements OnInit {

    public notificationHolder: IAllNotification[];
    public notificationTypes: string[];
    public selectedNotificationType: string;
    public notificationDescription: string;
    public showDirect: boolean = false;
    public dateTo: any;
    public dateFrom: any;

    constructor(private externalNotificationRestService: ExternalNotificationRestService,
                private helperService: HelperService,
                private adminService: AdminService) {
    }

    ngOnInit() {
        this.getAllMessages();

        this.externalNotificationRestService.getNotificationTypesRest().subscribe(
            (types: string[]) => {
                this.notificationTypes = types;
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_NOTIFICATION_TYPES', 'error');
            });
    }

    private getAllMessages() {
        this.externalNotificationRestService.getAllNotificationsRest().subscribe(
            (notifications: IAllNotification[]) => {
                this.notificationHolder = notifications;
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_NOTIFICATIONS', 'error');
            });
    }

    public getNotificationsForThisClub() {
        const notificationsForThisClub: IAllNotification[] = [];
        if (this.notificationHolder) {
            for (const notification of this.notificationHolder) {
                if (notification.clubRestriction && notification.clubRestriction.length > 0) {
                    if (notification.clubRestriction.includes(this.adminService.getClubId())) {
                        notificationsForThisClub.push(notification);
                    }
                } else {
                    notificationsForThisClub.push(notification);
                }
            }
        }

        return notificationsForThisClub;
    }

    public deleteNotification(notification: IAllNotification) {
        if (notification) {
            this.externalNotificationRestService.deleteNotificationRest(notification.id).subscribe(
                (success) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_NOTIFICATION', 'success');
                    this.getAllMessages();
                },
                (error) => {
                    this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_NOTIFICATION', 'error');
                });
        }
    }

    public getStartDate() {
        if (this.dateFrom && typeof this.dateFrom !== 'string' && this.dateFrom.day && this.dateFrom.month && this.dateFrom.year) {
            return this.dateFrom.year + '-' +
                (this.dateFrom.month < 10 ? '0' + this.dateFrom.month : this.dateFrom.month) + '-' +
                (this.dateFrom.day < 10 ? '0' + this.dateFrom.day : this.dateFrom.day);
        } else {
            return '';
        }
    }

    public getEndDate() {
        if (this.dateTo && typeof this.dateTo !== 'string' && this.dateTo.day && this.dateTo.month && this.dateTo.year) {
            return this.dateTo.year + '-' +
                (this.dateTo.month < 10 ? '0' + this.dateTo.month : this.dateTo.month) + '-' +
                (this.dateTo.day < 10 ? '0' + this.dateTo.day : this.dateTo.day);
        } else {
            return '';
        }
    }

    public createNewNotification() {
        const createNotification: ICreateNotification = {
            type: this.selectedNotificationType,
            description: this.notificationDescription,
            active: true,
            showDirect: this.showDirect,
            clubRestriction: [this.adminService.getClubId()],
            start: this.getStartDate(),
            expires: this.getEndDate()
        }
        this.externalNotificationRestService.createNotificationRest(createNotification).subscribe(
            (success) => {
                this.getAllMessages();
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_CREATE_NOTIFICATION', 'success');
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_CREATE_NOTIFICATION', 'error');
            });
    }
}
