import {Component, OnDestroy, OnInit} from '@angular/core';
import {TyreService} from '../../../shared/services/tyre.service';
import {Location} from '@angular/common';
import {Subscription} from "rxjs/Subscription";
import {IVehicleSpecSubGroup} from "../../../shared/interfaces/DTO/car/vehicleSpecSubGroup";
import {CarService} from "../../../shared/services/car/car.service";
import {ICar} from "../../../shared/interfaces/DTO/car/car";
import {ITyreType} from "../../../technical-data/interfaces/Tyre";

@Component({
  selector: 'app-tyre-information',
  styleUrls: ['./tyre-information.component.scss'],
  templateUrl: './tyre-information.component.html'
})
export class TyreInformationComponent implements OnInit, OnDestroy {

  public tyreSpecs: IVehicleSpecSubGroup[] = [];
  public pdf: any;
  public loading: boolean = false;
  public selectedCar: ICar;
  public selectedTyreType: ITyreType;

  private subscriptions: Subscription[] = [];

  constructor(
      public location: Location,
      public tyreService: TyreService,
      private carService: CarService,
  ) { }

  public ngOnInit() {
    this.subscriptions.push(this.tyreService.tyreSpecs$.subscribe(tyreSpecs => {
      this.tyreSpecs = tyreSpecs;
    }));

    this.subscriptions.push(this.tyreService.selectedTyreType$.subscribe(selectedTyreType => {
      this.selectedTyreType = selectedTyreType;
    }));

    this.subscriptions.push(this.tyreService.pdf$.subscribe(pdf => {
      this.pdf = pdf;
    }));

    this.subscriptions.push(this.tyreService.loading$.subscribe(loading => {
      this.loading = loading;
    }));

    this.subscriptions.push(this.carService.selectedCarChanged$.subscribe(selectedCar => {
      this.selectedCar = selectedCar;
    }));


    //TODO: remove after change selectedCarChanged$ to subject
    this.selectedCar = this.carService.selectedCar;
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => {sub.unsubscribe()});
  }
}
