<div class="dashboard">
    <div class="heading blue">
        <span *ngIf="router.url.includes('/company-admin/assortment')"
              class="clickable" routerLink="/company-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <span *ngIf="router.url.includes('/admin/firm/assortment')"
              class="clickable" routerLink="/admin/">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <span *ngIf="router.url.includes('/admin/assortment')"
              class="clickable" routerLink="/admin/assortment">
             <i class="fa fa-th" aria-hidden="true"></i>
        </span>
        <span *ngIf="router.url.includes('/company-admin/assortment')"
              class="clickable" routerLink="/company-admin/assortment">
            <i class="fa fa-caret-right"></i>
        </span>
        <span *ngIf="router.url.includes('/admin/firm/assortment')"
              class="clickable" routerLink="/admin/firm/assortment">
            <i class="fa fa-caret-right"></i>
        </span>
        <span *ngIf="router.url.includes('/admin/assortment')" class="clickable"
              routerLink="/admin/assortment">{{'CLUB_ADMIN.ASSORTMENT' | translate}}</span>
        <span *ngIf="router.url.includes('/company-admin/assortment')" class="clickable"
              routerLink="/company-admin/assortment">{{'CLUB_ADMIN.ASSORTMENT' | translate}}</span>
        <span *ngIf="router.url.includes('/admin/firm/assortment')" class="clickable"
              routerLink="/admin/firm/assortment">{{'CLUB_ADMIN.ASSORTMENT' | translate}}</span>
        <span *ngIf="router.url.includes('supplier')" class="clickable">
            <i class="fa fa-caret-right" aria-hidden="true"></i>
        </span>
        <span *ngIf="router.url.includes('/admin/assortment/supplier')" class="clickable"
              routerLink="/admin/assortment/supplier">{{'ASSORTMENT.SUPPLIER' | translate}}</span>
        <span *ngIf="router.url.includes('/company-admin/assortment/supplier')" class="clickable"
              routerLink="/company-admin/assortment/supplier">{{'ASSORTMENT.SUPPLIER' | translate}}</span>
        <span *ngIf="router.url.includes('/admin/firm/assortment/supplier')" class="clickable"
              routerLink="/admin/firm/assortment/supplier">{{'ASSORTMENT.SUPPLIER' | translate}}</span>
        <span *ngIf="router.url.includes('article')" class="clickable">
            <i class="fa fa-caret-right" aria-hidden="true"></i>
        </span>
        <span *ngIf="router.url.includes('/admin/assortment/article')" class="clickable"
              routerLink="/admin/assortment/article">{{'ASSORTMENT.ARTICLE' | translate}}</span>
        <span *ngIf="router.url.includes('/company-admin/assortment/article')" class="clickable"
              routerLink="/company-admin/assortment/article">{{'ASSORTMENT.ARTICLE' | translate}}</span>
        <span *ngIf="router.url.includes('/admin/firm/assortment/article')" class="clickable"
              routerLink="/admin/firm/assortment/article">{{'ASSORTMENT.ARTICLE' | translate}}</span>
        <span *ngIf="router.url.includes('article') && genArtId" class="clickable">
            <i class="fa fa-caret-right" aria-hidden="true"></i>
        </span>
        <span *ngIf="router.url.includes('article') && genArtId"> {{dataMapperService.mapGenArt(genArtId)}}</span>

        <span *ngIf="router.url.includes('supplier') && supplierId" class="clickable">
            <i class="fa fa-caret-right" aria-hidden="true"></i>
        </span>
        <span *ngIf="router.url.includes('supplier') && supplierId"> {{dataMapperService.mapDataSupplier(supplierId)}}</span>
    </div>
    <div class="panel-content center-content white-background">
        <div class="assortment">
            <ng-container
                    *ngIf="(dataMapperService.genArts.value && dataMapperService.genArts.value.length > 0) || ( dataMapperService.dataSuppliers.value && dataMapperService.dataSuppliers.value.length > 0)">
                <router-outlet></router-outlet>
            </ng-container>
            <ng-container *ngIf="dataMapperService.loadingAssortment | async">
                <app-default-loading-page></app-default-loading-page>
            </ng-container>
        </div>
    </div>
</div>
