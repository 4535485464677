<div class="gallery" (click)="closeGallery.emit()">
  <div class="gallery-center" (click)="onEvent($event)">
    <div class="row background header-title" style="padding: 10px 0px">
      <div class="col-10" style="top: 2px;">
        <span>{{title}}</span>
      </div>
      <div class="col-2">
        <button class="gallery-button" (click)="closeGallery.emit()">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    <div class="row background">
      <div class="col">
        <i class="fa fa-angle-left" *ngIf="documentList?.length > 1" (click)="getNextImage(true)"></i>
        <div class="gallery-helper">
          <img class="gallery-image" src="{{selectedImage?.url}}" *ngIf="selectedImage?.url">
          <img class="gallery-image" src="/assets/images/no-image.png" *ngIf="!selectedImage?.url">
        </div>
        <i class="fa fa-angle-right" *ngIf="documentList?.length > 1" (click)="getNextImage(false)"></i>
      </div>
    </div>
    <div class="row background" *ngIf="documentList?.length > 1">
      <div class="col">
        <div class="border-line"></div>
      </div>
    </div>
    <div class="row background" style="padding-bottom: 10px" *ngIf="documentList?.length > 1">
      <div class="col">
        <ng-container *ngFor="let image of documentList">
          <img (click)="setImage(image)" [ngClass]="{'active': image === selectedImage}" class="gallery-image-small" src="{{image.url}}">
        </ng-container>
      </div>
    </div>
  </div>
</div>
