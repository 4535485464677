import {Directive, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AutovistaService} from '../../services/autovista.service';
import {HelperService} from '../../../shared/services/helper.service';
import {Subscription} from 'rxjs';

@Directive({
    selector: '[appSVG]'
})
export class SvgDirective implements OnChanges, OnInit, OnDestroy {

    @Input() svgImage;

    private bboxBackup: any;
    private svgChangeSubscriber: Subscription;

    constructor(private autovistaService: AutovistaService,
                private helperService: HelperService) {
    }

    @HostListener('document:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
        const me = this;
        switch (event.code) {
            case 'ArrowLeft': me.updateSvg(-me.getMoveDelta(), 0, 0, 0); break;
            case 'ArrowRight': me.updateSvg(me.getMoveDelta(), 0, 0, 0); break;
            case 'ArrowUp': me.updateSvg(0, -me.getMoveDelta(), 0, 0); break;
            case 'ArrowDown': me.updateSvg(0, me.getMoveDelta(), 0, 0); break;
            case 'KeyX': if (me.bboxBackup) {
                me.autovistaService.svgDoc.setAttribute('viewBox', (me.bboxBackup.x) + ' ' + (me.bboxBackup.y) + ' ' +
                    (me.bboxBackup.width) + ' ' + (me.bboxBackup.height));
            } break;
            case 'NumpadAdd': me.updateSvg(25, 25, -50, -50); break;
            case 'NumpadSubtract': me.updateSvg(-25, -25, 50, 50); break;
        }
    }

    // -- use scroll to zoom svg -- //

    /*@HostListener('document:wheel', ['$event']) handleWheelEvent(event: WheelEvent) {
        const me = this;
        if (event.deltaY > 0) {
            me.updateSvg(-10, -10, 20, 20);
        } else {
            me.updateSvg(10, 10, -20, -20);
        }
    }*/

    // ---------------------------- //

    ngOnInit() {
        this.svgChangeSubscriber = this.autovistaService.svgChangeEmitter.subscribe(
            (changeEvent: string) => {
                switch (changeEvent) {
                    case 'left':
                        this.updateSvg(-this.getMoveDelta(), 0, 0, 0);
                        break;
                    case 'right':
                        this.updateSvg(this.getMoveDelta(), 0, 0, 0);
                        break;
                    case 'up':
                        this.updateSvg(0, -this.getMoveDelta(), 0, 0);
                        break;
                    case 'down':
                        this.updateSvg(0, this.getMoveDelta(), 0, 0);
                        break;
                    case 'plus':
                        this.updateSvg(25, 25, -50, -50);
                        break;
                    case 'minus':
                        this.updateSvg(-25, -25, 50, 50);
                        break;
                    case 'reset':
                        if (this.bboxBackup) {
                            this.autovistaService.svgDoc.setAttribute('viewBox', (this.bboxBackup.x) + ' ' + (this.bboxBackup.y) + ' ' +
                                (this.bboxBackup.width) + ' ' + (this.bboxBackup.height));
                        }
                        break;
                }
            });
    }

    ngOnDestroy() {
        this.svgChangeSubscriber.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.svgImage) {
            const autovista = document.getElementById('svgWrapper').children[0];
            if (autovista) {
                autovista.parentNode.removeChild(autovista);
            }

            setTimeout(() => {
                this.setNewSVG();
            }, 50);
        }
    }

    public setNewSVG() {
        if (this.svgImage) {
            let noCallOutShown = true;
            this.bboxBackup = undefined;
            document.getElementById('svgWrapper').insertAdjacentHTML('beforeend', this.svgImage.toString());
            this.autovistaService.svgDoc = document.getElementById('svgWrapper').children[0];
            this.autovistaService.svgDoc.setAttribute('id', 'svg');
            this.autovistaService.svgDoc.setAttribute('width', '100%');
            this.autovistaService.svgDoc.setAttribute('height', '100%');

            if (this.helperService.browserIsIE()) {
                this.autovistaService.svgDoc.setAttribute('style', 'margin-top: 35px; min-height: 75vh;');
            }

            // pictogram
            const pictograms_url = 'imgav/nav/pictograms.svgz';
            this.autovistaService.getImageXML(pictograms_url, true);

            if (this.autovistaService.pictogramXML && this.autovistaService.pictogramXML.length > 0) {
                const temp = document.createElement('div');
                temp.insertAdjacentHTML('beforeend', this.autovistaService.pictogramXML.toString());
                this.autovistaService.pictogram = temp.getElementsByTagName('g');
            }

            const gs = this.autovistaService.svgDoc.querySelectorAll('[objecttype="PS"]');
            const me = this;
            for (const g of gs) {
                if (g.id !== '' && g.id > 0 && g.hasAttribute('objecttype') && g.getAttribute('objecttype') === 'PS'
                    && this.autovistaService.svgHolder) {
                    g.setAttribute('visibility', 'hidden');
                    const selectedFilter = this.autovistaService.collectFilter();

                    Object.keys(this.autovistaService.svgHolder.detailImages).forEach((imageKey) => {
                        const image = this.autovistaService.svgHolder.detailImages[imageKey];
                        for (const callout of image.callouts) {
                            if (parseInt(callout.id) === parseInt(g.id)) {

                                let etg = this.autovistaService.svgDoc.getElementById(g.id + '-Graphic');
                                if (etg.hasAttribute('etg:pictogramid')) {
                                    const pictogram = etg.getAttribute('etg:pictogramid');
                                    this.addPictogram(callout.id, pictogram);
                                }
                                etg = undefined;
                                for (const criteriaCode of callout.showCriteriaCodes) {
                                    if (selectedFilter.indexOf(criteriaCode) > -1) {
                                        g.setAttribute('visibility', 'true');
                                        noCallOutShown = false;
                                    }
                                }
                                if (!callout.showCriteriaCodes || Object.keys(callout.showCriteriaCodes).length === 0) {
                                    g.setAttribute('visibility', 'true');
                                    noCallOutShown = false;
                                }
                                if (callout.materialCode) {
                                    const color = this.getMarterialColor(callout.materialCode);
                                    if (color) {
                                        const childs = g.getElementsByTagName('path');
                                        for (const child of childs) {
                                            if (child.getAttribute('fill') === '#ffffff') {
                                                child.setAttribute('fill', color);
                                            }
                                        }
                                        g.setAttribute('fill', color);
                                    }
                                }
                            }
                        }
                    });
                }
            }

            if (noCallOutShown && this.autovistaService.selectedImage) {
                if (this.autovistaService.selectedImage.filter && this.autovistaService.selectedImage.filter.NO_COMPAT) {
                    this.autovistaService.selectedFilter.set('NO_COMPAT', this.autovistaService.selectedImage.filter.NO_COMPAT[0].criteriaCode);
                }
            }

            const bbox = this.autovistaService.svgDoc.getBBox();

            if (!this.helperService.browserIsIE()) {
                const centerContent = document.getElementsByClassName('center-content')[0];
                const centerFactor = centerContent.clientWidth / centerContent.clientHeight;
                const bboxFactor = bbox.width / bbox.height;

                if (centerFactor > bboxFactor) {
                    const factorWidth = bbox.width * centerFactor;
                    bbox.width += factorWidth / 2;
                    bbox.x -= factorWidth / 4;
                }
            }

            this.autovistaService.svgDoc.setAttribute('viewBox', (bbox.x - 10) + ' ' + (bbox.y - 10)
                + ' ' + (bbox.width + 20) + ' ' + (bbox.height + 20));

            if (!this.helperService.browserIsIE()) {
                this.autovistaService.svgDoc.setAttribute('style', 'margin-top: 35px');
            }

            if (this.autovistaService.selectedFilter.size > 0) {
                this.autovistaService.changeCriteria();
            }

            if (this.helperService.browserIsEdge()) {
                this.autovistaService.svgDoc.innerHTML = this.autovistaService.svgDoc.innerHTML + ' ';
            }

            this.autovistaService.imageLoading = false;
        }
    }

    public updateSvg(moveX: number, moveY: number, changeWith: number, changeHeight: number) {
        const viewBox = this.autovistaService.svgDoc.getAttribute('viewBox').split(' ');
        const bbox = {x: parseInt(viewBox[0]), y: parseInt(viewBox[1]), height: parseInt(viewBox[3]), width: parseInt(viewBox[2])};
        if (bbox.height + changeHeight >= 0 && bbox.width + changeWith >= 0) {
            if (!this.bboxBackup) {
                this.bboxBackup = bbox;
            }
            if ((this.bboxBackup && this.bboxBackup.width >= bbox.width + changeWith) && (this.bboxBackup.height >= bbox.height + changeHeight)) {
                this.autovistaService.svgDoc = document.getElementById('svgWrapper').children[0];
                this.autovistaService.svgDoc.setAttribute('id', 'svg');
                this.autovistaService.svgDoc.setAttribute('width', '100%');
                this.autovistaService.svgDoc.setAttribute('height', '100%');

                if (this.helperService.browserIsIE()) {
                    this.autovistaService.svgDoc.setAttribute('style', 'margin-top: 35px; min-height: 75vh;');
                }
                if (!this.helperService.browserIsIE()) {
                    const centerContent = document.getElementsByClassName('center-content')[0];
                    const centerFactor = centerContent.clientWidth / centerContent.clientHeight;
                    const bboxFactor = bbox.width / bbox.height;

                    if (centerFactor > bboxFactor) {
                        const factorWidth = bbox.width * centerFactor;
                        bbox.width += factorWidth / 2;
                        bbox.x -= factorWidth / 4;
                    }
                }

                this.autovistaService.svgDoc.setAttribute('viewBox', (bbox.x + moveX) + ' ' + (bbox.y + moveY)
                    + ' ' + (bbox.width + changeWith) + ' ' + (bbox.height + changeHeight));

                if (!this.helperService.browserIsIE()) {
                    this.autovistaService.svgDoc.setAttribute('style', 'margin-top: 35px');
                }

                if (this.autovistaService.selectedFilter.size > 0) {
                    this.autovistaService.changeCriteria();
                }

                if (this.helperService.browserIsEdge()) {
                    this.autovistaService.svgDoc.innerHTML = this.autovistaService.svgDoc.innerHTML + ' ';
                }
            }
        }
    }

    public getMoveDelta() {
        if (this.bboxBackup) {
            const viewBox = this.autovistaService.svgDoc.getAttribute('viewBox').split(' ');
            const bbox = {x: parseInt(viewBox[0]), y: parseInt(viewBox[1]), height: parseInt(viewBox[3]), width: parseInt(viewBox[2])};

            const delta: number = 50 * (bbox.height / this.bboxBackup.height);

            if (delta > 50) {
                return 50;
            } else if (delta < 5) {
                return 5;

            } else {
                return delta;
            }

        } else {
            return 50;
        }
    }

    public addPictogram(id: string, pictogram: string) {

        if (pictogram === 'GEN015') {
            return;
        }
        for (let i = 0; i < this.autovistaService.pictogram.length; i++) {
            const _pictogram = this.autovistaService.pictogram[i];
            if (_pictogram instanceof Object && _pictogram.hasAttribute('etg:pictogramtemplateid')
                && _pictogram.getAttribute('etg:pictogramtemplateid') === pictogram) {
                this.autovistaService.svgDoc.getElementById(id + '-Graphic').appendChild(_pictogram);
                return;
            }
        }
    }

    public getMarterialColor(materia: string) {
        switch (materia) {
            case 'XX' :
                return '#dfe6ed';
            case 'AL' : // Aluminium
                return '#7ED3D1';
            case 'CF' : // Carbin / GFK
                return '#F37421';
            case 'EL' : // Kunststoff
                return '#F1E3BB';
            case 'GF' : // Glasfaser
                return '#7AB800';
            case 'KS' : // Kunststoff
                return '#F1E3BB';
            case 'ST' : // Stahl
                return '#5E6A71';
            case 'SI' : // Stahl innen
                return '#A5ACAF';
            case 'HS' : //  hochfestem Stahl
                return '#d2cbd3';
            case 'VS' : // sehr hochfestem Stahl
                return '#878392';
            case 'US' : // ultra hochfestem Stahl
                return '#a6b1ad';
            case '82AA8E' : // Textil
                return '#F1E3BB';
            case 'GL' : // Glas
                return '#0096C8';
            case 'MG' : // Magnesium
                return '#7ED3D1';
            case 'AW' : // Alufelgen
                return '#a6a6a6';
        }
    }

}
