<div class="global-order-row">
  <ng-container *ngIf="!userService.enableInfiniteScroll">
    <ng-container *ngFor="let order of globalAdminService?.orderList?.orders">
      <li (click)="globalAdminService.setOrder(order)"
          routerLink="/global-admin/orderhistory/{{order.id}}">
        <div class="row">
          <div class="col-1 d-flex justify-content-center align-self-center">
            <i class="fa fa-shopping-cart"></i>
          </div>
          <div class="col-11">
            <div class="row">
              <div class="col">
                <span class="order-name">{{order.name}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Datum</span>
              </div>
              <div class="col-4">
                <span>{{order.created | date: 'dd.MM.yyyy'}}</span>
              </div>
              <div class="col-1">
                <span>Uhrzeit</span>
              </div>
              <div class="col-5">
                <span>{{order.created | date: 'HH:mm'}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Club</span>
              </div>
              <div class="col-10">
                <span>{{order.clubName}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Firma</span>
              </div>
              <div class="col-10">
                <span>{{order.firmName}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Referenz</span>
              </div>
              <div class="col-10">
                <span *ngIf="order.reference">{{order.reference}}</span>
                <span *ngIf="!order.reference">{{order.name}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Bestellnummer</span>
              </div>
              <div class="col-10">
                <span>{{order.platformOrderId}}</span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="userService.enableInfiniteScroll">
    <ng-container *ngFor="let order of globalAdminService?.emitItems | async">
      <li (click)="globalAdminService.setOrder(order)"
          routerLink="/global-admin/orderhistory/{{order.id}}">
        <div class="row">
          <div class="col-1 d-flex justify-content-center align-self-center">
            <i class="fa fa-shopping-cart"></i>
          </div>
          <div class="col-11">
            <div class="row">
              <div class="col">
                <span class="order-name">{{order.name}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Datum</span>
              </div>
              <div class="col-4">
                <span>{{order.created | date: 'dd.MM.yyyy'}}</span>
              </div>
              <div class="col-1">
                <span>Uhrzeit</span>
              </div>
              <div class="col-5">
                <span>{{order.created | date: 'HH:mm'}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Club</span>
              </div>
              <div class="col-4">
                <span>{{order.clubName}}</span>
              </div>
              <div class="col-1">
                <span>Nutzer</span>
              </div>
              <div class="col-5">
                <span>{{order.loginName}} (ID: {{order.loginId}})</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Firma</span>
              </div>
              <div class="col-10">
                <span>{{order.firmName}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Referenz</span>
              </div>
              <div class="col-10">
                <span *ngIf="order.reference">{{order.reference}}</span>
                <span *ngIf="!order.reference">{{order.name}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <span>Bestellnummer</span>
              </div>
              <div class="col-10">
                <span>{{order.platformOrderId}}</span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ng-container>
    <div class="noHover row app-articles-row" *ngIf="globalAdminService.infinityLoading">
      <i class="centerItem fa fa-spinner" [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i>
    </div>
    <div class="noHover row app-articles-row" *ngIf="onEnd()">
      <span class="centerItem">{{'SPAREPARTS_VIEW.SEARCH_RESULTS.END' | translate}}</span>
    </div>
  </ng-container>
</div>

