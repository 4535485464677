<div class="order-details">
  <div class="row">
    <div class="col">
      <button class="base-btn float-right" *ngIf="!userService.sale" routerLink="orderhistory/{{orderHistoryService?.selectedHistory}}/{{orderHistoryService?.selectedCart?.id}}/order-print">
        <i class="fa fa-print"></i>
        <span>{{'PRINT_VIEW.PRINT' | translate}}</span>
      </button>
      <button class="base-btn float-right" *ngIf="userService.sale" (click)="pdfService.updateDmsData(); cartService.currentCart = orderHistoryService.cartData; cartService.backLink = router.url"
              routerLink="orderhistory/{{orderHistoryService?.selectedHistory}}/{{orderHistoryService?.selectedCart?.id}}/offer">
        <i class="fa fa-file-pdf-o"></i>
        <span>{{'PRINT_VIEW.PRINT_OFFER' | translate}}</span>
      </button>
    </div>
  </div>
  <div class="row padding-top">
    <div class="col">
      <app-collapsible-panel [panelTitle]="supplier.key" *ngFor="let supplier of orderHistoryService?.cartData?.externalIds | cat">
        <div class="row">
          <div class="col-5">
            <span>{{'CART.ORDER_NUMBER' | translate}}</span>
          </div>
          <div class="col-7">
            <span>{{supplier.value}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <span>{{'CART.ACQUISITION_TYPE' | translate}}</span>
          </div>
          <div class="col-7">
            <span>{{'CART.ACQUISITION_TYPES.' + orderHistoryService.cartData.selectedAcquisitionTypes[supplier.key] | translate}}</span>
          </div>
        </div>
      </app-collapsible-panel>
    </div>
  </div>
</div>
