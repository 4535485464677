import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PartnerRestService} from '../../../partner/services/partner.rest.service';
import {UserService} from '../user/user.service';
import {AdminService} from '../../../admin/club/services/admin.service';
import {IPartnerPage, IPartnerPageCollection} from '../../../partner/interfaces/partnerPage';
import {AuthService} from '../../../auth/services/auth.service';
import {HelperService} from '../helper.service';

@Injectable()
export class PartnerService {

    public running: boolean = false;
    public isCreate: boolean = false;
    public partnerPages: IPartnerPageCollection;
    public selectedPartnerIsURL: string = '';
    public selectedPartner: IPartnerPage = {logoUrl: '', position: 0, text: '', url: ''};
    public hasNewPartnerData: EventEmitter<any> = new EventEmitter<any>();

    constructor(private http: HttpClient,
                private partnerRestService: PartnerRestService,
                public userService: UserService,
                private helperService: HelperService,
                private adminService: AdminService,
                private authService: AuthService) {
        this.adminService.onChangeClub.subscribe(
            (step) => {
                this.getPartner();
            }
        );
        this.getPartner();
    }

    public getPartnerDisplayName() {

        let displayName = undefined;

        if (this.partnerPages) {
            displayName = this.partnerPages.displayName;
        } else if (this.userService.accountData) {
            displayName = this.userService.accountData.partnerPageCollection.displayName;
        }

        return displayName;
    }

    public getPartnerPageKeys() {
        if (this.partnerPages && this.partnerPages.partnerPages) {
            return Object.keys(this.partnerPages.partnerPages);
        }
    }

    public getPartner() {
        if (!this.running && this.authService.isLoggedIn) {
            this.running = true;
            if (!this.adminService || this.adminService.getClubId() === null || !this.userService.allow('CLUB-ADMIN')) {
                this.partnerRestService.getPartner().subscribe(
                    (partnerPages) => {
                        this.partnerPages = partnerPages;
                        this.hasNewPartnerData.emit();
                        this.sortPartner();
                        this.running = false;
                    },
                    (error) => {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET', 'error');
                    }
                );
            } else {
                this.partnerRestService.getPartnerById('', this.adminService.getClubId()).subscribe(
                    (partnerPages) => {
                        this.partnerPages = partnerPages;
                        this.hasNewPartnerData.emit();
                        this.sortPartner();
                        this.running = false;
                    },
                    (error) => {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET', 'error');
                    }
                );
            }
        }
    }

    public changePartner(partner: IPartnerPage, pageType: any) {
        if (partner && pageType) {

            if (pageType !== this.selectedPartnerIsURL) {
                this.partnerPages.partnerPages[this.selectedPartnerIsURL].splice(this.selectedPartner.position, 1);
                this.createPartner(partner, pageType);
            }

            if (pageType === this.selectedPartnerIsURL) {
                this.partnerPages.partnerPages[pageType][partner.position] = partner;
                this.savePartner();
            }
        }
    }

    public createPartner(partner: IPartnerPage, pageType: any) {
        if (partner && pageType) {
            if (this.partnerPages.partnerPages && !this.partnerPages.partnerPages[pageType]) {
                this.partnerPages.partnerPages[pageType] = [];
            }
            this.partnerPages.partnerPages[pageType].push(partner);
            this.savePartner();
        }
    }

    public savePartner() {
        this.partnerRestService.changePartnerRest(this.partnerPages).subscribe(() => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_SAVE', 'success');
                this.getPartner();
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_SAVE', 'error');
            });
    }

    public deletePartner(partner: IPartnerPage, pageType: any) {
        if (partner) {
            this.partnerPages.partnerPages[pageType].splice(partner.position, 1);
            this.savePartner();
        }
    }

    public sortPartner(save?: boolean) {
        let hasChanges = false;

        if (this.partnerPages.partnerPages) {
            for (const pageType of Object.keys(this.partnerPages.partnerPages)) {
                for (let index = 0; index < this.partnerPages.partnerPages[pageType].length; index++) {
                    if (this.partnerPages.partnerPages[pageType][index].position !== index) {
                        this.partnerPages.partnerPages[pageType][index].position = index;
                        hasChanges = true;
                    }
                }
            }
        }

        if (hasChanges || save) {
            this.savePartner();
        }
    }
}
