<div class="dynamic-setting-wrapper">
    <div class="heading blue">
    <span class="clickable" routerLink="/admin/config/">
      <i class="fa fa-th" aria-hidden="true"></i>
      <span>{{'CLUB_ADMIN.CONFIG' | translate}}</span>
    </span>
        <span *ngIf="configService.selectedSettingName?.length > 0">
      <i class="fa fa-caret-right"></i>
      <span>{{'CLUB_ADMIN.SETTING_NAME.' + configService.selectedSettingName | translate}}</span>
    </span>
    </div>
    <div class="panel-content center-content">
        <ng-container *ngIf="isDynamicSetting()">
            <app-setting-list *ngIf="getSettingDTOList()?.length > 0" [settingList]="getSettingDTOList()"
                              [settingName]="configService.selectedSettingName">
            </app-setting-list>
            <app-setting-external-tool *ngIf="getExternalToolDTOList()?.length > 0" [settingList]="getExternalToolDTOList()"
                                       [settingName]="configService.selectedSettingName">
            </app-setting-external-tool>

            <div class="alert alert-info text-center" *ngIf="getExternalToolDTOList()?.length === 0 && router.url.endsWith('/EXTERNAL_TOOLS')">
                <i class="fa fa-info-circle mr-2"></i>
                <span>{{ 'CLUB_SETTINGS.EXTERNAL_TOOLS.NO_EXTERNAL_TOOLS' | translate }}</span>
            </div>

        </ng-container>
        <ng-container *ngIf="!isDynamicSetting()">
            <app-tecdoc-config *ngIf="configService?.selectedSettingName === 'TECDOC'"></app-tecdoc-config>
            <app-language-config *ngIf="configService?.selectedSettingName === 'LANGUAGE'"></app-language-config>
            <app-cart-limit-config *ngIf="configService?.selectedSettingName === 'CART_LIMIT'"></app-cart-limit-config>
            <app-club-supplier-config *ngIf="configService?.selectedSettingName === 'SUPPLIER'"></app-club-supplier-config>
            <app-country-config *ngIf="configService?.selectedSettingName === 'COUNTRY'"></app-country-config>
            <app-clublogo-config *ngIf="configService?.selectedSettingName === 'GLOBAL_FIRM_LOGO'"></app-clublogo-config>
        </ng-container>
    </div>
</div>
