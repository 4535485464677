import {Component, OnInit} from '@angular/core';
import {MaintenanceService} from "../services/maintenance.service";
import {Location} from "@angular/common";
import {UserService} from "../../shared/services/user/user.service";
import {detailAnim} from '../../shared/animations/general.animation';
import {CartService} from '../../cart/services/cart.service';

@Component({
    selector: 'app-maintenance-details',
    templateUrl: './maintenance-details.component.html',
    styleUrls: ['./maintenance-details.component.scss'],
    animations: [detailAnim]
})
export class MaintenanceDetailsComponent implements OnInit {

    public additionalWork: any[];

    constructor(public maintenanceService: MaintenanceService,
                public location: Location,
                public userService: UserService,
                public cartService: CartService) {
        this.additionalWork = [];
    }

    ngOnInit() {
    }

    public toggleAdditionalWork(work: any, event: boolean) {
        if (event) {
            this.maintenanceService.addWorkToCart(work, true);
        } else {
            this.maintenanceService.removeWorkFromCart(this.maintenanceService.isChecked(work));
        }
    }
}
