<div class="maintenance-header" id="maintenanceHeader">
    <div class="row" *ngIf="maintenanceService.maintenanceHeaderOpen">
        <div class="col-9">
            <div>
                <strong>
                    <i class="fa fa-car"></i>
                </strong>
                {{carservice.selectedCar.carProducerName + ' ' + carservice.selectedCar.carModelName + ' ' + carservice.selectedCar.carTypeName}}
                <span *ngIf="dataService.getMileage() > 999"> | {{ dataService.getMileage() + ' ' + (userService.getMileageUnit() | translate)}} </span>
                <b *ngIf="dataService.getYear()"> | {{dataService.getYear() | date: 'd.M.yyyy' }} </b>
                <span *ngIf="maintenanceService.selectedGear"> | {{'MAINTENANCE.GEAR.' +  maintenanceService.selectedGear | translate}} </span>
                <span *ngIf="maintenanceService.activeCoo?.qualColText"> | {{maintenanceService.activeCoo.qualColText}} </span>
            </div>
            <div *ngIf="maintenanceService.hasSelectedAdditionalMaintenance()">
                <ng-container *ngFor="let korWork of maintenanceService?.maintenance?.mdAddItemMpKorWorksDTO">
                    <ng-container *ngFor="let subWork of korWork?.mdWorkPos">
                        <span class="comma" *ngIf="maintenanceService.isChecked(subWork) > -1">
                            <strong>
                                <i class="fa fa-gears"></i>
                            </strong>
                            {{ subWork.itemMpText + (subWork.qualColText != 'Standard' ? ' ' + subWork.qualColText : '')}} </span>
                    </ng-container>
                </ng-container>
                <b>| {{'CART.COMPOSITE_WORK_TIME' | translate}}: {{getAdditionalWorkTime()}} {{'GENERAL.VALUES.' + getAdditionalWorkUnit() | translate}}</b>
            </div>
        </div>
    </div>
    <div class="row successRow" *ngIf="maintenanceService.maintenanceHeaderOpen && maintenanceService.showModal">
        <div class="col-12 p-0">
            <div class="alert alert-success">
                <div class="row">
                    <div class="col-6 text-center">
                        <b>{{'PROMPTS.MESSAGE.ALL_ARTICLE' | translate}}</b><br/>
                        <i *ngIf="configService.allowOffers()">{{'PROMPTS.MESSAGE.ALL_ARTICLE_MORE' | translate}}</i>
                        <i *ngIf="!configService.allowOffers()">{{'PROMPTS.MESSAGE.ALL_ARTICLE_NO_OFFER' | translate}}</i>
                    </div>
                    <div class="col-6 buttonBar">
                        <button class="base-btn"  [routerLink]="['/maintenance/info/service']" [queryParams]="{ fromPlan: true}" *ngIf="location.path().indexOf('maintenance') > 0">
                            <i class="fa fa-scribd"></i>
                            {{'PROMPTS.BUTTONS.TO_SERVICEPLAN' | translate}}
                        </button>
                        <button class="base-btn" routerLink="/cart/offer" (click)="cartService.backLink = maintenanceService.getBacklink();" *ngIf="configService.allowOffers()">
                            <i class="fa fa-file-pdf-o"></i> {{'PROMPTS.BUTTONS.TO_OFFER' | translate}}
                        </button>
                        <button class="base-btn" routerLink="/cart"><i class="fa fa-shopping-cart"></i>
                            {{'PROMPTS.BUTTONS.TO_CART' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row articleSelection" *ngIf="!maintenanceService.maintenanceHeaderOpen">
        <div class="col-11">
            <strong>
                <i class="fa fa-car"></i>
            </strong>
            <span>{{carservice.selectedCar.carProducerName + ' ' + carservice.selectedCar.carModelName + ' ' + carservice.selectedCar.carTypeName}}</span>

            <ng-container *ngIf="maintenanceService.hasSelectedMaintenance()">
                <strong>
                    <i class="fa fa-wrench"></i>
                </strong>
                <span>
                    {{ ( maintenanceService?.activeMaintenanceStep ? maintenanceService.activeMaintenanceStep.qualColText : '' ) }}
                    {{ ( maintenanceService?.maintenanceSteps ? maintenanceService?.maintenanceSteps.itemMpText + ' ' + maintenanceService?.maintenanceSteps.korText: ' ' ) }}
                    <b *ngIf="maintenanceService?.activeMaintenanceStep && maintenanceService?.activeMaintenanceStep.quantity">
                        {{ maintenanceService?.activeMaintenanceStep.quantity }} {{'GENERAL.VALUES.' + maintenanceService.activeMaintenanceStep.quantityUnit | translate}}
                    </b>
                </span>
            </ng-container>
        </div>
        <div class="col-1" *ngIf="!maintenanceService.showAdditionalWork">
            <i class="fa fa-angle-down" *ngIf="!maintenanceService.maintenanceHeaderOpen"
               (click)="maintenanceService.maintenanceHeaderOpen = !maintenanceService.maintenanceHeaderOpen"></i>
        </div>
    </div>
</div>
