import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';
import {CarService} from "../services/car/car.service";

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

  constructor(private elementRef: ElementRef, private carService: CarService) { }
  @Output() clickOutside = new EventEmitter<boolean>();

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement && this.carService.showCarInfo) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit(false);
    }
    else {
      this.clickOutside.emit(true);
    }
  }

}
