import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AssortmentRestService} from './assortment-rest.service';
import {IAssortment} from '../interfaces/assortment';
import {UserService} from '../../../shared/services/user/user.service';
import {AdminService} from '../../club/services/admin.service';
import {ActivatedRoute} from '@angular/router';
import {HelperService} from '../../../shared/services/helper.service';


@Injectable({
    providedIn: 'root'
})
export class AssortmentService {

    public stateClubOrFirm: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    public assortmentSubscriptions: Subscription[] = [];
    public assortments: BehaviorSubject<IAssortment[]> = new BehaviorSubject<IAssortment[]>(undefined);
    public refreshBehavior: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private firmId: number;


    constructor(private assortmentRestService: AssortmentRestService,
                private route: ActivatedRoute,
                private userService: UserService,
                private helperService: HelperService,
                private adminService: AdminService) {

        this.stateClubOrFirm.subscribe(state => {
            if (state) {
                switch (state) {
                    case "firm":
                        this.firmId = this.adminService.selectedFirm.firmId;
                        break;
                    case "ownfirm":
                        this.firmId = this.userService.accountData.firmId;
                        break;
                    case "club":
                        this.firmId = null;
                        break;
                }
            }
        });
    }


    public cleanSubscriptions() {
        if (this.assortmentSubscriptions) {
            this.assortmentSubscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }


    public emptyAssortments() {
        this.assortments.next([]);
    }


    public getAssortments(filter: string, id?: number) {
        this.assortments.next(null);
        switch (filter) {
            case 'brand':
                this.assortmentRestService.getAssortmentsByDlnr(id, this.adminService.getClubId(), (this.firmId !== -1) ? this.firmId : null).subscribe((response) => {
                    response ? this.assortments.next(response.sort((a, b) => a.prio - b.prio)) : this.emptyAssortments();
                });
                break;
            case 'nogenart':
                this.assortmentRestService.getNoGenArtAssortment(this.adminService.getClubId(), (this.firmId !== -1) ? this.firmId : null).subscribe((response) => {
                    response ? this.assortments.next(response.sort((a, b) => a.prio - b.prio)) : this.emptyAssortments();
                });
                break;
            case'withGenart':
                this.assortmentRestService.getWithGenArtAssortment(this.adminService.getClubId(), (this.firmId !== -1) ? this.firmId : null).subscribe((response) => {
                    response ? this.assortments.next(response.sort((a, b) => a.prio - b.prio)) : this.emptyAssortments();
                });
                break;
            case 'genart':
                if (id) {
                    this.assortmentRestService.getAssortmentsByGenArt(id, this.adminService.getClubId(), (this.firmId !== -1) ? this.firmId : null).subscribe((response) => {
                        response ? this.assortments.next(response.sort((a, b) => a.prio - b.prio)) : this.emptyAssortments();
                    })
                }
                break;
        }
    }


    public updateAssortment(id: string, overWrite: boolean) {
        const assortment = this.assortments.value.find(elem => elem.id === id);
        if (overWrite) {
            assortment.firmId = this.firmId;
            assortment.id = null;
        }

        this.assortmentRestService.createOrUpdateAssortment(assortment, this.adminService.getClubId()).subscribe(() => {
            if(overWrite) {
                this.refreshBehavior.next(true);
            }
            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
        }, () => {
            this.helperService.showNotification('TOAST_MESSAGES.ERROR_UPDATE', 'error');
        });
    }


    public createAssortment(assortment: any) {
        if (this.firmId !== null) {
            assortment.firmId = this.firmId;
        }

        this.assortmentRestService.createOrUpdateAssortment(assortment, this.adminService.getClubId()).subscribe(response => {
            this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_SAVE', 'success');
        }, error => {
            if (error.status === 304) {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_DOUBLE_ENTRY_ASSORTMENT', 'error');
            } else {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_SAVE', 'error');
            }
        });
        this.refreshBehavior.next(true);
    }


    public updatePriority(id: string, primaryPrio: boolean, value: number, overWrite: boolean) {
        const assortment = this.assortments.value.find(elem => elem.id === id);
        const priority = assortment.prio;
        if (primaryPrio) {
            assortment.prio = (this.modulus(priority) + value);
        } else {
            assortment.prio = (priority - this.modulus(priority) + value);
        }

        if (overWrite) {
            assortment.firmId = this.firmId;
            assortment.id = null;
        }

        this.assortmentRestService.createOrUpdateAssortment(assortment, this.adminService.getClubId()).subscribe(
            () => {
                if(overWrite) {
                    this.refreshBehavior.next(true);
                }
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
            }, () => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_UPDATE', 'error');
            }
        );
    }

    public deleteAssortment(id: string) {
        this.assortmentRestService.deleteAssortment(id).subscribe(response => {
                this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_DELETE_ENTRY', 'success');
            }, () => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_DELETE_ENTRY', 'error');
            }
        );
        this.refreshBehavior.next(true);
    }

    public modulus(value: number) {
        const mod = 100;
        return value % mod;
    }

    public checkRoute(route: string): string {
        if (route.includes('/company-admin/assortment')) {
            return 'company-admin';
        }
        if (route.includes('/admin/assortment')) {
            return 'admin';
        }
        if (route.includes('/admin/firm/assortment')) {
            return 'admin/firm';
        }
    }

    public existsAlreadyInFirm(assortment: IAssortment, firmAssortments: IAssortment[], type: string): boolean {
        if (firmAssortments && firmAssortments.length > 0) {
            switch (type) {
                case 'dataSupplierNo':
                    return (!firmAssortments.find(item => (item.dataSupplierNo === assortment.dataSupplierNo && item.active)));
                case 'genartId':
                    return (!firmAssortments.find(item => (item.genArtId === assortment.genArtId && item.active)));
                case 'genartId&dataSupplierNo':
                    return (!firmAssortments.find(item => (item.genArtId === assortment.genArtId && item.dataSupplierNo === assortment.dataSupplierNo && item.active)));
            }
        }
        return true;
    }

}
