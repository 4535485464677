<div class="panel-content center-content white-background">
    <div class="cart-table">
        <div class="alert alert-warning" *ngIf="cartService.checkAndRetry">
            {{ 'CART.PRICES_CHANGED' | translate }}
        </div>

        <div class="alert alert-danger text-center" *ngIf="cartService.hasDeliveryCostsError()">
            <i class="fa fa-info-circle"></i>
            <span>{{ 'CART.DELIVERY_COSTS_ALERT' | translate }}</span>
            <button class="base-btn float-right" (click)="cartService.updateCart(false, true, true)">
                <i class="fa fa-refresh"></i>
                <span>{{ 'CART.REFRESH' | translate }}</span>
            </button>
        </div>

        <div class="alert alert-warning text-center not-orderable" *ngIf="cartService.showNotOrderableBannerBool">
            <i class="fa fa-exclamation-triangle mr-2"></i>
            <span><b>{{ 'CART.NOT_ORDERABLE_BANNER_FIRST' | translate }}</b> {{ 'CART.NOT_ORDERABLE_BANNER_SECOND' | translate }}</span>
        </div>

        <ng-container *ngIf="cartService?.currentCart?.requiredItems?.length > 0">
            <div class="alert alert-info text-center" *ngIf="cartService.getRequiredItemCount() > 0">
                <i class="fa fa-info-circle mr-2"></i>
                <span>{{ 'CART.ARTICLE_SELECTION_TEXT' | translate:{'count': cartService.getRequiredItemCount()} }}</span>
                <button class="base-btn float-right" (click)="routeToArticleSelection()">
                    <span>{{ 'CART.TO_ARTICLE_SELECTION' | translate }}</span>
                </button>
            </div>

            <div class="alert alert-success text-center" *ngIf="cartService.getRequiredItemCount() === 0">
                <i class="fa fa-check mr-2"></i>
                <span>{{ 'CART.ALL_ARTICLES_SELECTED' | translate }}</span>
                <button class="base-btn float-right" (click)="routeToArticleSelection()">
                    <span>{{ 'CART.TO_ARTICLE_SELECTION' | translate }}</span>
                </button>
            </div>
        </ng-container>


        <div class="inner-content" ng-class="{ 'top-message-active': cartService.checkAndRetry }">

            <ng-container *ngIf="cartService?.history && !cartService?.currentCart && !cartService?.activeHistory">
                <div class="assembly-categories">
                    <div class="assembly-category" (click)="cartService.updateOrderList('Last7')">
                        <div class="name-wrapper-no-image">
                            <span>{{ 'CART.HISTORY.LAST7' | translate }}</span>
                        </div>
                    </div>
                    <div class="assembly-category" (click)="cartService.updateOrderList('Last14')">
                        <div class="name-wrapper-no-image">
                            <span>{{ 'CART.HISTORY.LAST14' | translate }}</span>
                        </div>
                    </div>
                    <div class="assembly-category" (click)="cartService.updateOrderList('Last30')">
                        <div class="name-wrapper-no-image">
                            <span>{{ 'CART.HISTORY.LAST30' | translate }}</span>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                    *ngIf="cartService.activeHistory && cartService.history && !cartService.currentCart && !cartService.orderListLoading">
                <div class="assembly-categories" *ngIf="cartService.orderList.length > 0">
                    <div class="assembly-category" *ngFor="let cart of cartService.orderList"
                         (click)="cartService.selectOrder(cart)">
                        <div class="name-wrapper-no-image">
                            <span>{{ cart.name }}</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="cartService.orderList.length === 0 && !cartService.orderListLoading">
                    <app-no-results></app-no-results>
                </div>
            </ng-container>
            <app-default-loading-page *ngIf="cartService.cartLoading"></app-default-loading-page>

            <div *ngIf="cartService.currentCart && !cartService.cartLoading"
                 id="cart-table-print-section" class="table-container row">

                <app-check-genart style="width: 100%;"></app-check-genart>

                <div class="row cart-positions-row">
                    <div class="col cart-positions-col">
                        <!-- header -->
                        <div class="row header-row">
                            <div class="col-1"></div>
                            <div class="col-10">
                                <div class="row">
                                    <div class="col-4">
                                        <span>{{ 'GENERAL.ARTICLE' | translate }}</span>
                                    </div>
                                    <div class="col-2">
                                        <span class="ml-4">{{ 'CART.AMOUNT' | translate }}</span>
                                    </div>
                                    <div class="col-3 text-right">
                                        <div>
                                            <b>{{ userService.sale ? ('CART.SINGLE_PRICE_SALE' | translate) : ('CART.SINGLE_PRICE' | translate) }}</b><small
                                                *ngIf="!userService.sale && !(cartService?.cartCount?.calculationData)">
                                            <br>{{ 'SPAREPARTS_VIEW.SEARCH_RESULTS.RECOMMENDED_PRICE' | translate }}
                                        </small>
                                        </div>
                                        <div *ngIf="cartService.showSellinPrices">({{ 'CART.SELLIN_PRICE' | translate }}
                                            )
                                        </div>
                                    </div>
                                    <div class="col-3 text-right">
                                        <b>
                                            {{ userService.sale ? ('CART.TOTAL_PRICE_SALE' | translate) : ('CART.TOTAL_PRICE' | translate) }}
                                        </b>
                                        <small *ngIf="!userService.sale && !(cartService?.cartCount?.calculationData)">
                                            <br/>{{ 'SPAREPARTS_VIEW.SEARCH_RESULTS.RECOMMENDED_PRICE' | translate }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1 text-center">
                                <span><i class="fa fa-trash-o"></i></span>
                            </div>
                        </div>
                        <!-- body -->
                        <ng-container *ngIf="!cartService.cartEmpty || cartService.isPreview()">
                            <ng-container
                                    *ngFor="let position of (cartService.previewCart ? cartService.previewCart.positions : cartService.currentCart.positions)">
                                <app-cart-row [position]="position"
                                              [rawToolsPositions]="cartService.previewCart ? cartService.previewCart.positionsWithRawTools : cartService.currentCart.positionsWithRawTools"></app-cart-row>
                            </ng-container>
                        </ng-container>
                        <div class="row cart-comment" *ngIf="cartService.currentCart.comment?.length > 0">
                            <div class="col-1">
                                <i class="cart-table-icon fa fa-pencil clickable" routerLink="/cart/comment"
                                   *ngIf="!cartService?.history && !cartService.isOrder() && !cartService.isPreview()">
                                </i>
                            </div>
                            <div class="col-10">
                                <span>{{ cartService.currentCart?.comment }}</span>
                            </div>
                            <div class="col-1">
                                <i class="cart-table-icon fa fa-trash-o" (click)="cartService.currentCart.comment = ''"
                                   *ngIf="!cartService?.history && !cartService.isOrder() && !cartService.isPreview()">
                                </i>
                            </div>
                        </div>
                        <ng-container
                                *ngIf="configService.getActiveForName('EXTENDED_SETTINGS', 'FREE_ARTICLES') && !cartService.isOrder() && !cartService.isPreview()">
                            <div class="row addNewArticle" *ngIf="!cartService?.history && cartService?.isNewestCart()">
                                <div class="col" routerLink="/cart/article">
                                    <div class="mt-3">
                                        <i class="fa fa-plus mr-2"></i>
                                        <span>{{ 'CART.ADD_ARTICLE_POPUP.TITLE' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row addNewArticle" *ngIf="cartService?.history">
                                <div class="col">
                                    <div class="mt-3">
                                        <span>{{ 'CART.ADD_ARTICLE_NO' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="row addNewArticle"
                             *ngIf="cartService.currentCart.comment?.length === 0 && !cartService?.history && cartService?.isNewestCart()
                             && !cartService.isOrder() && !cartService.isPreview()">
                            <div class="col" routerLink="/cart/comment">
                                <div class="mt-3">
                                    <i class="fa fa-plus mr-2"></i>
                                    <span>{{ 'CART.COMMENT.ADD_COMMENT' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




