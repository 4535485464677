<div class="work-item">
    <ul class="item-list"
        [@workListAnim]="(items ? items.length : 0)"
        *ngIf="getHighlighted().length > 0"
        style="margin-bottom: 2rem;">
        <li *ngFor="let item of getHighlighted()"
            (click)="selectWorkItem(item); "
            [ngClass]="{'active' : checkItem(item) !== 0, 'highlight': item.highlight, 'selected' : item === workService.getSelectedItem()}">
            <div class="d-flex">
                <div class="mr-3">
                    <app-checkbox *ngIf="cartService?.isNewestCart()" #checkBox [isChecked]="checkItem(item) !== 0"
                                  [isDisabled]="cartService.isOrder()"
                                  (onChange)="toggleWorkItem(item, checkBox)"></app-checkbox>
                </div>
                <div class="ml-3"><span class="item-name">{{item.mountPositionText}}</span>
                    <span class="item-name" *ngIf="item.itemQualifierText !== 'Standard'">
                            <i class="fa fa-caret-right m-2"></i>  <b>{{item.itemQualifierText}}</b></span>
                    <span *ngIf="item.repairText"> ({{item.repairText}}) </span>
                </div>
                <div class="ml-auto"><span class="item-name">{{item.quantity}} {{'GENERAL.VALUES.' + item.quantityUnit | translate}}</span>
                </div>
            </div>
        </li>
    </ul>


    <ul class="item-list" [@workListAnim]="(items ? items.length : 0)" *ngIf="getHighlighted().length === 0">
        <ng-container *ngFor="let item of items">
            <li *ngIf="!item.highlight"
                (click)="selectWorkItem(item)"
                [ngClass]="{'active' : checkItem(item) !== 0,'selected' : item === workService.getSelectedItem()}">
                <div class="d-flex">
                    <div class="mr-3">
                        <app-checkbox *ngIf="cartService?.isNewestCart()" #checkBox [isChecked]="checkItem(item) !== 0"
                                      [isDisabled]="cartService.isOrder()"
                                      (onChange)="toggleWorkItem(item, checkBox)"></app-checkbox>
                    </div>
                    <div class="ml-3"><span class="item-name">{{item.mountPositionText}}</span>
                        <span class="item-name" *ngIf="item.itemQualifierText !== 'Standard'">
                            <i class="fa fa-caret-right m-2"></i>  <b>{{item.itemQualifierText}}</b></span>
                        <span *ngIf="item.repairText"> ({{item.repairText}}) </span>
                    </div>
                    <div class="ml-auto"><span class="item-name">{{item.quantity}} {{'GENERAL.VALUES.' + item.quantityUnit | translate}}</span>
                    </div>
                </div>

            </li>
        </ng-container>
    </ul>
</div>
