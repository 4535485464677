<div class="row">
  <div class="col-12 col-sm-6">
    <div class="row">
      <div class="col-9">
        <label class="headerLabel">{{'USER_PROFILE.USER_MANAGEMENT.USERDATA' | translate}}</label>
      </div>
    </div>
    <form name="userForm" [formGroup]="userForm">
    <div class="row">
      <div class="col">
        <app-material-form-input
                [disabled]="(userService.authMethode$ | async) === 'N4SSO'"
                [label]="'USER_PROFILE.USER_MANAGEMENT.USERNAME' | translate"
                [type]="'text'"
                [showValidation]="true"
                [validationErrorMessage]="('USER_PANEL.ERROR_USERNAME.SECOND' | translate) + ' ' + ('USER_PANEL.REGEX' | translate)"
                [showError]="userForm?.get('username')?.errors?.pattern"
                [(text)]="userData.userName">
        </app-material-form-input>
      </div>
    </div>
      <div class="row">
        <div class="col">
          <app-material-form-input
                  [disabled]="(userService.authMethode$ | async) === 'N4SSO'"
                  [label]="'USER_PROFILE.USER_MANAGEMENT.DISPLAY_NAME' | translate"
                  [type]="'text'"
                  [(text)]="userData.displayName">
          </app-material-form-input>
        </div>
      </div>
    <div class="row">
      <div class="col">
        <app-material-form-input
                [disabled]="(userService.authMethode$ | async) === 'N4SSO'"
                [label]="'USER_PROFILE.USER_MANAGEMENT.EMAIL' | translate"
                [type]="'email'"
                [showValidation]="true"
                [validationErrorMessage]="'VALIDATION.ERROR_EMAIL' | translate"
                [showError]="userForm?.get('email')?.errors?.email"
                [(text)]="userData.email">
        </app-material-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-material-form-input
                [disabled]="(userService.authMethode$ | async) === 'N4SSO'"
                [label]="'USER_PROFILE.USER_MANAGEMENT.PASSWORD' | translate"
                [type]="'password'"
                [showValidation]="true"
                [validationErrorMessage]="('USER_PANEL.ERROR_PASSWORD.SECOND' | translate) + ' ' + ('USER_PANEL.REGEX' | translate)"
                [showError]="userForm?.get('password')?.errors?.pattern"
                [(text)]="userData.password">
        </app-material-form-input>
      </div>
    </div>
    </form>
    <div class="row">
      <div class="col">
        <label class="rolesLabel">{{'USER_PROFILE.USER_MANAGEMENT.LANGUAGE' | translate}}</label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="custom-dropdown" >
          <select (change)="toggleLanguage($event)">
            <option *ngFor="let state of states" [value]="state.language" [selected]="state.language === userData.language">{{('GENERAL.LANGUAGES.'+ (state.language | uppercase) | translate)}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-6">
    <div class="row">
      <div class="col">
        <label class="rolesLabel">{{'USER_PROFILE.USER_MANAGEMENT.ROLES' | translate}}</label>
      </div>
    </div>
    <ng-container *ngFor="let role of firmData?.roles">
      <div class="row checkbox"  *ngIf="!licenseRoles.includes(role)">
        <div class="col">
          <label class="checkboxLabel">{{'ADMIN.ROLES.' + role | translate}}</label>
          <input type="checkbox" [checked]="userData?.roles.indexOf(role) > -1" (click)="toggleSelection(role)"/>
        </div>
      </div>
    </ng-container>

    <div class="row">
      <div class="col">
        <label class="rolesLabel">{{'ADMIN.LICENSE' | translate}}</label>
      </div>
    </div>
    <ng-container *ngFor="let role of firmData?.roles">
      <div class="row checkbox"  *ngIf="licenseRoles.includes(role)">
        <div class="col">
          <label class="checkboxLabel">{{'ADMIN.ROLES.' + role | translate}}</label>
          <input type="checkbox" [checked]="userData?.roles.indexOf(role) > -1" (click)="toggleSelection(role)" [disabled]="(userService.authMethode$ | async) === 'N4SSO'"/>
        </div>
      </div>
    </ng-container>
    <div class="row" *ngIf="this.userData?.userSettings">
      <div class="col">
        <label class="rolesLabel">{{'USER_PROFILE.USER_MANAGEMENT.RIGHTS' | translate}}</label>
      </div>
    </div>
    <div class="row checkbox" *ngIf="this.userData?.userSettings">
      <div class="col">
        <app-checkbox [isChecked]="userSetting.active" [labelText]="'USER_PROFILE.USER_MANAGEMENT.' + userSetting.setting | translate"
                      (onChange)="userSetting.active = $event" *ngFor="let userSetting of this.userData.userSettings">
        </app-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="checkboxLabel">{{'ADMIN_PANEL.ACTIVE' | translate}}</label>
        <input type="checkbox" #active [ngModel]="userData?.active" (ngModelChange)="userData ? userData.active = $event : null">
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="isGlobalAdmin()" >
  <div class="col button" *ngIf="userData.delete && userData?.id > 0">
    <div class="alert alert-danger">
      <b>{{userData.userName}}</b> {{'GENERAL.DELETE' | translate}} <br/><br/>
      <button class="base-btn " (click)="userData.delete = false;">{{'PROMPTS.BUTTONS.CANCEL' | translate}}</button>
      <button class="base-btn error pull-right no-top-margin" (click)="deleteLogin(userData, true,'GLOBAL-ADMIN')"> {{'PROMPTS.BUTTONS.DELETE' | translate}} </button>
    </div>
  </div>
</div>
<div class="row" *ngIf="isClubAdmin()&& !isGlobalAdmin()" >
  <div class="col button" *ngIf="userData.delete && userData?.id > 0">
    <div class="alert alert-danger">
      <b>{{userData.userName}}</b> {{'GENERAL.DELETE' | translate}} <br/><br/>
      <button class="base-btn " (click)="userData.delete = false;">{{'PROMPTS.BUTTONS.CANCEL' | translate}}</button>
      <button class="base-btn error pull-right no-top-margin" (click)="deleteLogin(userData, true,'CLUB-ADMIN')"> {{'PROMPTS.BUTTONS.DELETE' | translate}} </button>
    </div>
  </div>
</div>
<div class="row" *ngIf="isFirmAdmin() && !isClubAdmin()" >
  <div class="col button" *ngIf="userData.delete && userData?.id > 0">
    <div class="alert alert-danger">
      <b>{{userData.userName}}</b> {{'GENERAL.DELETE' | translate}} <br/><br/>
      <button class="base-btn " (click)="userData.delete = false;">{{'PROMPTS.BUTTONS.CANCEL' | translate}}</button>
      <button class="base-btn error pull-right no-top-margin" (click)="deleteLogin(userData, true, 'FIRM-ADMIN')"> {{'PROMPTS.BUTTONS.DELETE' | translate}} </button>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="buttons">
      <button class="base-btn pull-right mr-3 button-top-margin" (click)="reset()" >{{'PROMPTS.BUTTONS.GO_BACK' | translate}}</button>
      <button class="base-btn error pull-right mr-3" (click)="userData.delete = true" *ngIf="(isGlobalAdmin() || isClubAdmin() || isFirmAdmin() )&& userData?.id > 0">{{'PROMPTS.BUTTONS.DELETE' | translate}}</button>
      <button class="base-btn success mr-3" (click)="update()">{{'PROMPTS.BUTTONS.SAVE' | translate}}</button>
      <button class="base-btn success mr-3" (click)="updateReset()">{{'PROMPTS.BUTTONS.SAVE_BACK' | translate}}</button>
    </div>
  </div>
</div>
<app-error-handler *ngIf="errorHandlerService.newError"></app-error-handler>
