<div class="row form-select-group">
  <div class="col-3">
    <div class="left-label">
      {{ 'CAR_SELECTION.TITLE' | translate }}
    </div>
    <div class="vehicle-type-button" [ngClass]="{ 'active' : vehicleType === 'PC'}"
         (click)="onVehicleTypeChange('PC')">
      <i class="fa fa-car"></i>
    </div>

    <div class="vehicle-type-button" [ngClass]="{ 'active' : vehicleType === 'MOTORCYCLE'}"
         (click)="onVehicleTypeChange('MOTORCYCLE')">
      <i class="fa fa-motorcycle"></i>
    </div>

    <div class="vehicle-type-button" [ngClass]="{ 'active' : vehicleType === 'CV'}"
         (click)="onVehicleTypeChange('CV')">
      <i class="fa fa-truck"></i>
    </div>
  </div>
  <div class="col-9">
    <div class="row">
      <div class="col-9 col-offset-3">
        <div class="custom-dropdown">
          <select [(ngModel)]="carSearchFields.producer"
                  (change)="onCarProducerSelect()">
            <option disabled selected value="null">{{ 'HOME.MANUFACTURER' | translate }}</option>
            <option *ngFor="let producer of carProducers" [value]="producer.id">{{ producer.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-9 col-offset-3">
        <div class="custom-dropdown" ng-class="{disabled: !carModels?.length > 0}">
          <select [disabled]="!(carModels?.length > 0)"
                  [(ngModel)]="carSearchFields.model"
                  (change)="onCarModelSelect()">
            <option value="null" disabled selected>{{ 'HOME.MODEL' | translate }}</option>
            <option *ngFor="let model of carModels" [value]="model.id">{{ (model.name + ' (' +
              model.modelYearFrom +' '+['GENERAL.TO' | translate]+' '+ (model.modelYearTo ? model.modelYearTo:' '+['GENERAL.TODAY' | translate]) +
              ')') }}
            </option>
          </select>
        </div>
      </div>


      <div class="col-9 col-offset-3">
        <div class="custom-dropdown" ng-class="{disabled: !carTypes?.length > 0}">
          <select ng-options="producer as  for producer in carTypes"
                  [disabled]="!(carTypes?.length > 0)"
                  [(ngModel)]="carSearchFields.type"
                  (change)="onCarTypeSelect()">
            <option value="null" disabled selected>{{ 'HOME.TYPE' | translate }}</option>
            <option *ngFor="let type of carTypes" [value]="type.kTypeId">
              {{ (type.typeName + ' ' + type.powerKW+'KW / ' + type.powerPS+'PS (' +
              type.constructionFrom +' '+['GENERAL.TO' | translate]+' '+ (type.constructionTo ? type.constructionTo:' '+['GENERAL.TODAY' | translate])
              +
              ')' + (type.engineCodes?.length > 0 ? ' (' + type.engineCodes.toString() + ')' :
              '')) }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
