import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {IOrderSearchRequest} from '../../../shared/interfaces/DTO/order/orderSearchRequest';
import {Observable} from 'rxjs';
import {IOrderAdminResponse} from '../../../shared/interfaces/DTO/order/orderAdminResponse';
import {IOrderAdmin, IOrderAdminMailContent} from '../../../shared/interfaces/DTO/order/orderAdmin';
import {IOrderLog} from '../../../shared/interfaces/DTO/order/orderLog';
import {ICreateClub} from '../../../shared/interfaces/DTO/club/createClub';
import {IActivatedStatistic, IActivatedStatisticRequest} from '../interfaces/activatedStatistic';
import {IClubSupplier} from '../../../shared/interfaces/DTO/admin/clubSupplier';
import {IVinLog} from '../../../shared/interfaces/DTO/autovista/vinLog';
import {ICountry} from '../../../shared/interfaces/DTO/firm/country';
import {IClubSetting} from '../../../shared/interfaces/DTO/club/clubSetting';
import {IClubDataSupplierRestrictions, IDataSupplier} from '../../../shared/interfaces/DTO/club/clubDataSupplierRestrictions';
import {ISupplierCommunicationSearchRequest} from '../interfaces/supplierCommunication';

@Injectable()
export class GlobalAdminRestService {
    constructor(private http: HttpClient) {
    }

    public saveClubConfig(clubSettings: IClubSetting): Observable<IClubSetting> {
        return this.http.put<IClubSetting>(
            `${environment.apiUrl}/globaladmin/club/settings`,
            clubSettings
        );
    }

    public getClubConfigById(clubId: number): Observable<IClubSetting> {
        return this.http.get<IClubSetting>(
            `${environment.apiUrl}/globaladmin/club/settings/${clubId}`
        );
    }

    public getRestrictionForClubRest(clubId: number): Observable<IClubDataSupplierRestrictions> {
        return this.http.get<IClubDataSupplierRestrictions>(
            `${environment.apiUrl}/globaladmin/club/settings/restrictions/${clubId}`
        );
    }

    public getDataSuppliers(): Observable<IDataSupplier[]> {
        return this.http.get<IDataSupplier[]>(
            `${environment.apiUrl}/globaladmin/club/settings/restrictions/datasuppliers`
        );
    }

    public putRestrictionUpdate(restriction: any): Observable<IClubDataSupplierRestrictions> {
        return this.http.put<IClubDataSupplierRestrictions>(
            `${environment.apiUrl}/globaladmin/club/settings/restrictions`,
            restriction
        );
    }

    public putLanguageUpdate(clubId: number, possibleLanguage: any): Observable<any> {
        return this.http.put(
            `${environment.apiUrl}/globaladmin/club/update/languages/${clubId}`,
            possibleLanguage
        );
    }

    public putCountryUpdate(clubId: number, country: ICountry): Observable<any> {
        return this.http.put(
            `${environment.apiUrl}/admin/defaultcountry/${clubId}`,
            country
        );
    }

    public deleteRestrictionForClub(clubId: number) {
        return this.http.delete(
            `${environment.apiUrl}/globaladmin/club/settings/restrictions/${clubId}`
        );
    }

    public getFreeMonthStatisticRest(activatedStatisticRequest: IActivatedStatisticRequest): Observable<IActivatedStatistic> {
        return this.http.post<IActivatedStatistic>(
            `${environment.apiUrl}/globaladmin/club/statistic/activated`,
            activatedStatisticRequest
        );
    }

    public getPlacedOrdersRest(orderlist: IOrderSearchRequest): Observable<IOrderAdminResponse> {
        return this.http.post<IOrderAdminResponse>(
            `${environment.apiUrl}/globaladmin/orderlist`,
            orderlist
        );
    }
    public createClubRest(clubName: string, testOnly: boolean, possibleLanguages: any, possibleCountries: any, defaultCountry: string, defaultLanguage: string, theme: string): Observable<ICreateClub> {
        const clubRequest: ICreateClub = {clubName: clubName, testOnly: testOnly, possibleLanguages: possibleLanguages, possibleCountries: possibleCountries, defaultCountry: defaultCountry, defaultLanguage: defaultLanguage, theme: theme};
        return this.http.post<ICreateClub>(
            `${environment.apiUrl}/globaladmin/club/create`,
            clubRequest
        );
    }

    public getPlacedGripOrdersRest(orderlist: any): Observable<any> {
        return this.http.post<any>(
            `${environment.apiUrl}/globaladmin/gripsorderlist`,
            orderlist
        );
    }

    public getOrderDetailsRest(orderId: string): Observable<IOrderAdmin> {
        return this.http.get<IOrderAdmin>(
            `${environment.apiUrl}/globaladmin/orderdetails?orderId=${orderId}`
        );
    }

    public getOrderTransferLogRest(supplier: string, orderId: string): Observable<IOrderLog[]> {
        return this.http.get<IOrderLog[]>(
            `${environment.apiUrl}/globaladmin/ordertransferlog?orderId=${orderId}&supplier=${supplier}`
        );
    }

    public getVinLog(vin: string): Observable<IVinLog[]> {
        return this.http.get<IVinLog[]>(
            `${environment.apiUrl}/globaladmin/autovista/log/${vin}`
        );
    }

    public getLanguages(): Observable<string[]> {
        return this.http.get<string[]>(
            `${environment.apiUrl}/globaladmin/languages`
        );
    }

    public getSupplierCommunicationListRest(request: ISupplierCommunicationSearchRequest) {
        return this.http.post<any>(
            `${environment.apiUrl}/globaladmin/suppliers/communicationlist`,
            request
        );
    }

    public getOrderMailRest(orderId: string, mailId: number) {
        return this.http.get<IOrderAdminMailContent>(
            `${environment.apiUrl}/globaladmin/ordermail?orderId=${orderId}&mailId=${mailId}`
        );
    }

    public downloadmailattachment(orderId: string, mailId: number, mailAttachment: number) {
        return `${environment.apiUrl}/globaladmin/downloadmailattachment?orderId=${orderId}&mailId=${mailId}&mailAttachmentId=${mailAttachment}`;
    }
}
