<div class="global-mapping-import">
    <div class="heading blue">
        <span class="clickable" style="cursor: pointer;" routerLink="/global-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <i class="fa fa-caret-right"></i>
        <span>{{'GLOBAL_ADMIN.GLOBAL_MAPPING_IMPORT' | translate}}</span>
    </div>
    <div class="panel-content center-content white-background">
        <div class="assembly-categories">
            <div class="assembly-category" routerLink="/global-admin/import/supplier">
                <div class="name-wrapper-no-image">
                    <span>{{'GLOBAL_ADMIN.SUPPLIER' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" routerLink="/global-admin/import/oesupplier">
                <div class="name-wrapper-no-image">
                    <span>{{'GLOBAL_ADMIN.OE_SUPPLIER' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" routerLink="/global-admin/import/customid">
                <div class="name-wrapper-no-image">
                    <span>{{'GLOBAL_ADMIN.CUSTOMID' | translate}}</span>
                </div>
            </div>
            <div class="assembly-category" routerLink="/global-admin/import/adcTypeMapping">
                <div class="name-wrapper-no-image">
                    <span>{{'GLOBAL_ADMIN.ADCTYPE_MAPPING' | translate}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
