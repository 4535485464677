import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {IFirm} from '../../../shared/interfaces/DTO/firm/firm';
import {IAddress} from '../../../shared/interfaces/DTO/firm/adress';
import {IUpdatePasswordRequest} from '../../../shared/interfaces/DTO/updatePasswordRequest';
import {IClubSetting} from '../../../shared/interfaces/DTO/club/clubSetting';
import {IUpdateEmailRequest} from '../../../shared/interfaces/DTO/updateEmailRequest';

@Injectable()
export class AccountRestService {

    constructor(private http: HttpClient) {
    }

    public getFirmData(): Observable<IFirm> {
        return this.http.get<IFirm>(
            `${environment.apiUrl}/session/firmdetails`
        );
    }

    public getAddress(): Observable<IAddress> {
        return this.http.get<IAddress>(
            `${environment.apiUrl}/session/address`
        );
    }

    public setNewPassword(password: IUpdatePasswordRequest): Observable<any> {
        return this.http.post(
            `${environment.apiUrl}/session/password`,
            password );
    }

    public getClubConfig(): Observable<IClubSetting> {
        return this.http.get<IClubSetting>(
            `${environment.apiUrl}/session/club/settings`
        );
    }

    public setNewEmail(email: IUpdateEmailRequest): Observable<any> {
        return this.http.post(
            `${environment.apiUrl}/session/email`,
            email
        );
    }

}
