<div class="pagination" *ngIf="count > 0">
  <div class="description">
    <span class="page-counts">{{'PAGEINATION.PAGE' | translate}} {{ page }} {{'PAGEINATION.OF' | translate}} {{ totalPages() }}</span>
  </div>
  <div class="numbers">
    <ng-container  *ngIf="totalPages() > 1">
      <button class="link" (click)="onPage(1)" [disabled]="page == 1 || loading" [ngClass]="{ 'disabled': page == 1 || loading }">
        <i class="fa fa-angle-double-left clickable"></i>
      </button>
      <button class="link" (click)="previousPage()" [disabled]="page == 1 || loading" [ngClass]="{ 'disabled': page == 1 || loading }">
        <i class="fa fa-angle-left clickable"></i>
      </button>

      <button
              *ngFor="let pageNum of pages"
              (click)="onPage(pageNum)"
              [ngClass]="{'active': pageNum == page, 'disabled': loading}">{{ pageNum }}</button>

      <button class="link" (click)="nextPage()" [disabled]="lastPage() || loading" [ngClass]="{ 'disabled': lastPage() || loading }">
        <i class="fa fa-angle-right clickable"></i>
      </button>
      <button class="link" (click)="onPage(totalPages())" [disabled]="lastPage() || loading" [ngClass]="{ 'disabled': lastPage() || loading }">
        <i class="fa fa-angle-double-right clickable"></i>
      </button>
    </ng-container>
  </div>
  <span class="page-totals">{{count}} {{'PAGEINATION.POSTS' | translate}}</span>
</div>
