<div class="edit-external-tool">
    <div class="heading blue">
        <span class="clickable" *ngIf="!location?.path()?.includes('/admin/tools/edit')" routerLink="/company-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <span class="clickable" *ngIf="location?.path()?.includes('/admin/tools/edit')" routerLink="/company-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <i class="fa fa-caret-right"></i>
        <span class="clickable" routerLink="{{getNewExternalToolLink()}}">{{'USER_PROFILE.EXTERNAL_TOOLS' | translate}}</span>
        <span>
      <i class="fa fa-caret-right"></i>
      <span *ngIf="configService?.selectedExternalTool">{{'EXTERNAL_TOOLS.' + configService?.selectedExternalTool?.externalTool | translate}}</span>
    </span>
    </div>

    <div class="panel-content center-content white-background">

        <div class="club-setting-title ml-2" *ngIf="configService?.selectedExternalTool">
            <h1>
                <strong>
                    {{'EXTERNAL_TOOLS.' + configService?.selectedExternalTool?.externalTool | translate}}
                </strong>
            </h1>
        </div>

        <ng-container *ngIf="isCreate && possibleExternalTools">
            <app-dropdown placeholderText="{{'USER_PROFILE.PLEASE_SELECT' | translate}}" [items]="getPossibleSupplier()"
                          [translationPrefix]="'EXTERNAL_TOOLS'"
                          (onItemSelect)="changeSelectedExternalTool($event)"></app-dropdown>
        </ng-container>

        <ng-container *ngIf="configService.selectedExternalTool">
            <div class="row">
                <div class="col">
                    <app-material-form-input [(text)]="configService.selectedExternalTool.customerNo"
                                             [label]="'customerNo'"></app-material-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-material-form-input [(text)]="configService.selectedExternalTool.username"
                                             [label]="'username'"></app-material-form-input>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-material-form-input [(text)]="configService.selectedExternalTool.password"
                                             [label]="'pass'"></app-material-form-input>
                </div>
            </div>
        </ng-container>
            <div class="row">
                <div class="col">
                    <button class="base-btn success" *ngIf="!isCreate && configService.selectedExternalTool"
                            (click)="saveChanges()">{{'GENERAL.SAVE_CHANGES' | translate}}</button>

                    <button class="base-btn success" *ngIf="isCreate && configService.selectedExternalTool"
                            (click)="createNewTool()">{{'GENERAL.SAVE_CHANGES' | translate}}</button>
                    <button class="base-btn back float-right" (click)="back()">{{'GENERAL.BACK' | translate}}</button>
                </div>
            </div>
    </div>
</div>
