import {Component, EventEmitter, Input, OnInit, Output, OnChanges} from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
    public pages: number[];
    public change: boolean = true;

    @Input() public page: number;
    @Input() public count: number;
    @Input() public perPage: number;
    @Input() public pagesToShow: number;
    @Input() public loading: boolean;


    @Output() private goPage:  EventEmitter<number> = new EventEmitter<number>();

    constructor() { }

    ngOnInit() {
        this.getPages();
    }

    ngOnChanges(changes: any) {
        this.getPages();
    }


    public onPage(n: number) {
        if (n !== this.page) {
            this.goPage.emit(n);
            this.change = true;
        }
    }

    public previousPage() {
        this.onPage(Number.parseInt(this.page.toString()) - 1);
    }

    public nextPage() {
        this.onPage(Number.parseInt(this.page.toString()) + 1);
    }

    public totalPages() {
        return Math.ceil(this.count / this.perPage) || 0;
    }

    public lastPage() {
        return this.perPage * this.page > this.count;
    }

    public onChange() {
        if (this.change) {
            this.change = false;
            this.getPages();
        }

        return this.pages;
    }

    public getPages() {
        this.pages = [];

        if (this.page === 0) {
            this.page = 1;
        } else if(!this.page) {
          this.page = 1;
        }

        const maxPages = Math.ceil(this.count / this.perPage);
        const range = Math.ceil((this.pagesToShow - 1) / 2);

        let startPage: number, endPage: number;
        if (maxPages <= this.pagesToShow) {
            startPage = 1;
            endPage = maxPages;
        } else {
            if ((Number.parseInt(this.page.toString()) + range) >= maxPages) {
                startPage = maxPages - (2 * range);
                endPage = maxPages;
            } else if ((Number.parseInt(this.page.toString()) - range) <= 0) {
                startPage = 1;
                endPage = this.pagesToShow;
            } else {
                startPage = Number.parseInt(this.page.toString()) - range;
                endPage = Number.parseInt(this.page.toString()) + range;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            this.pages.push(i);
        }
    }

}
