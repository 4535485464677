import { Component, OnInit } from '@angular/core';
import {AutovistaService} from '../../services/autovista.service';

@Component({
  selector: 'app-autovista-left-tree',
  templateUrl: './autovista-left-tree.component.html',
  styleUrls: ['./autovista-left-tree.component.scss']
})
export class AutovistaLeftTreeComponent implements OnInit {

  constructor(public autovistaService: AutovistaService) { }

  ngOnInit() {
  }

  public getZone(dsCode: any) {
    if (this.autovistaService.mainGroup !== dsCode.code) {
        this.autovistaService.selectMainGroup(dsCode.code);
    }
  }

  public getMcGroupZone($event: any, mcGroup: any) {
    $event.stopPropagation();
    this.autovistaService.oldXML = undefined;
    this.autovistaService.selectedSubgroup = mcGroup;
    this.autovistaService.getSubGroupDetails();
  }

  public getFilterKeys() {
    return Object.keys(this.autovistaService.filter);
  }

  public toggleFilter(filter: any, mapKey: string, $event: any) {
    if ($event) {
      this.autovistaService.selectedFilter.set(mapKey, filter.criteriaCode);
    } else {
        this.autovistaService.selectedFilter.delete(mapKey);
    }

    this.autovistaService.selectedAutovistaDetails = undefined;
    this.autovistaService.changeCriteria();
  }
}
