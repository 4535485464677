<div class="club-setting-advanced">
  <div class="content">
      <div class="panel-content white-background">
          <div class="club-setting-title">
              <h1>
                  <strong>
                      {{'USER_PROFILE.LOGO_SETTINGS' | translate}}
                  </strong>
              </h1>
          </div>
          <div class="logo-check" *ngIf="configService.getSubSettingByName('GLOBAL_FIRM_LOGO', 'GLOBAL_FIRM_LOGO')">
              <ng-container *ngIf="userService.allow('CLUB-ADMIN')">
                  <app-checkbox [labelText]="'CLUB_ADMIN.FEATURES.CHANGE_LOGO' | translate"
                                [isChecked]="configService.getActiveForName('GLOBAL_FIRM_LOGO', 'GLOBAL_FIRM_LOGO')"
                                (onChange)="configService.setActiveForName('GLOBAL_FIRM_LOGO', 'GLOBAL_FIRM_LOGO', $event)"></app-checkbox>
              </ng-container>
              <div class="row" >
                  <div class="col-12">

                  </div>
              </div>
              <div class="row spacer" *ngIf="configService.getActiveForName('GLOBAL_FIRM_LOGO', 'GLOBAL_FIRM_LOGO')">
                  <div class="col-12">
                      <div class="row">
                          <div class="col" style="padding: 0 30px 0 30px">
                              <app-image-cropper *ngIf="imgUrl" [src]="imgUrl" [parent]="'config'"></app-image-cropper>
                          </div>

                      </div>
                  </div>
              </div>
              <div class="row" style="margin: 0">
                  <div class="col-6" *ngIf="configService.getActiveForName('GLOBAL_FIRM_LOGO', 'GLOBAL_FIRM_LOGO')">
                      <form [formGroup]="form" (ngSubmit)="configService.saveClubConfig()">
                          <div class="form-group">
                              <input type="file" id="avatar" accept="image/png, image/jpeg" (change)="onFileChange($event)" class="file-input" #fileInput>
                          </div>
                          {{'USER_PROFILE.LOGO_SIZE' | translate : {width: 200, height: 100, size: 1.5} }} <br/><br/>
                      </form>
                  </div>
                  <div class="col-6" *ngIf="!imgUrl && configService.getActiveForName('GLOBAL_FIRM_LOGO', 'GLOBAL_FIRM_LOGO')">
                      <img id="currentLogo" [src]="configService.getValueForName('GLOBAL_FIRM_LOGO', 'GLOBAL_FIRM_LOGO')">
                  </div>
              </div>
          </div>



          <button class="base-btn success float-right" (click)="configService.saveClubConfig()" *ngIf="configService.hasUnsavedSettings('GLOBAL_FIRM_LOGO')">
        <i class="fa fa-check"></i>
        {{'CLUB_ADMIN.PARTNER_MANAGEMENT.SAVE' | translate}}
      </button>
      <br><br><br>

    </div>
  </div>
</div>
