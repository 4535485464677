import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../shared/services/user/user.service';
import {CarService} from '../shared/services/car/car.service';
import {TecRmiRestService} from '../shared/services/tecrmi/tecrmi.rest.service';
import {Subscription} from 'rxjs/Subscription';
import {PdfService} from '../shared/services/pdf.service';
import {catAnimation, fadeOfferToggle, workListAnim} from '../shared/animations/general.animation';
import {Router} from '@angular/router';
import {HelperService} from '../shared/services/helper.service';
import {ConfigService} from '../shared/services/config.service';
import {TechnicalDataService} from '../technical-data/technical-data.service';
import {StateService} from '../shared/services/state.service';
import {fadeAnimation} from '../shared/animations/fade.animation';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-technical-data',
    templateUrl: './technical-data.component.html',
    styleUrls: ['./technical-data.component.scss'],
    animations: [catAnimation, workListAnim, fadeOfferToggle, fadeAnimation]
})
export class TechnicalDataComponent implements OnInit, OnDestroy {
    nxDiag: any;
    selectedsubChild: any;
    public manualList: any;
    public nxDetails: any;
    public nxDetailsTitle: string;
    public manual: any;
    public nxManualUrl: any;
    public selectedItem: any;
    public selectedChild: any;
    public selectedCat: any = {};
    public selectedCar: any;
    public selectedChildCat: any;
    public selectedDataCat: any;
    public isDiagnosis: boolean = false;
    public nxDiagSub: any;
    public nxDiagFolder: any;
    public selectedDiagPDF: any;

    public categories: string[] = [];
    public categoryContent: Map<string, any> = new Map<string, any>();
    private eventStream: Subscription;

    public showPdf: boolean = false;
    public pdfLoading: boolean = false;
    public pdf: any;

    private carSubscription: Subscription;
    private pdfResultSubscription: Subscription

    constructor(public userService: UserService,
                public carService: CarService,
                public pdfService: PdfService,
                private changeDetectorRef: ChangeDetectorRef,
                private router: Router,
                private zone: NgZone,
                private tecRmiRestService: TecRmiRestService,
                public helperService: HelperService,
                public configService: ConfigService,
                public stateService: StateService,
                public technicalDataService: TechnicalDataService) {

        this.startupCheck();
        this.stateService.updateTechnicalDataContent();
    }


    ngOnInit() {
        this.carSubscription = this.carService.selectedCarChanged$.subscribe((next) => {
            this.startupCheck()
        });
        this.pdfResultSubscription = this.technicalDataService.pdfResult.subscribe((value) => {
            if (value.pdfShow !== undefined) {
                this.showPdf = value.pdfShow
            }
            if (value.pdfLoading !== undefined) {
                this.pdfLoading = value.pdfLoading
            }
            if (value.pdf !== undefined) {
                this.pdf = value.pdf
            }
            this.getTechnicalDetailsCategories();
        });
    }


    ngOnDestroy() {
        if (this.carSubscription) {
            this.carSubscription.unsubscribe();
        }

        if (this.pdfResultSubscription) {
            this.pdfResultSubscription.unsubscribe();
        }

        if (this.changeDetectorRef) {
            this.changeDetectorRef.detach();
        }
    }


    public getTechnicalDetailsCategories() {
        this.tecRmiRestService.getTechnicalDataCategoriesRest().subscribe(
            (categories: string[]) => {
                categories.forEach((category: string) => {
                    switch (category) {
                        case 'VEHICLE_SPEC':
                            this.categories[0] = category;
                            break;
                        case 'FUSE_RELAY':
                            this.categories[1] = category;
                            break;
                        case 'COMPONENT_LOCATION':
                            this.categories[2] = category;
                            break;
                        case 'WIRING':
                            this.categories[3] = category;
                            break;
                        case 'COMFORT_WIRING':
                            this.categories[4] = category;
                            break;
                        case 'TECHNICAL':
                            this.categories[5] = category;
                            break;
                        case 'MAINTENANCE':
                            this.categories[6] = category;
                            break;
                        case 'DIAGNOSTIC':
                            this.categories[7] = category;
                            break;
                    }
                    this.categoryContent.set(category, {});
                    if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
                        this.changeDetectorRef.detectChanges();
                    }
                });
                if (this.carService.getAdcTypeId()) {
                    this.getCategoryContentFlux();
                }
            },
            (error) => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_TECHNICAL_DATA_CATEGORIES', 'error');
            });
    }

    public getCategoryContentFlux() {
        const observable = new Observable((observer) => {
            const eventSource = new EventSource(environment.apiUrl + '/tecrmi/technical-details/trees?adcTypeId=' + this.carService.getAdcTypeId());
            eventSource.onmessage = x => {
                observer.next(x.data);
            };
            eventSource.onerror = (e: any) => {
            //-> ERROR is thrown after flux stream break up by server EventSource.CONNECTING = 0 EventSource.OPEN = 1 EventSource.CLOSED = 2
                if (e?.currentTarget?.readyState === 0) {
                    eventSource.close();
                } else {
                    eventSource.close();
                    this.stateService.technicalDataContent.next('TECHNICAL_ERROR');
                }
            };

            return () => {
                eventSource.close();
            };
        });

        this.eventStream = observable.subscribe({
            next: guid => {
                this.zone.run(() => {
                    if (typeof guid === 'string') {
                        const temp = JSON.parse(guid);
                        if (temp) {
                            this.categoryContent.set(temp.qualifier, temp);
                            if (this.changeDetectorRef && !this.changeDetectorRef['destroyed']) {
                                this.changeDetectorRef.detectChanges();
                            }
                        }
                    }
                });
            },
            error: err => {
                if (err.status === 500) {
                    this.stateService.technicalDataContent.next('TECHNICAL_ERROR');
                }
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_LOAD_TECHNICAL_DATA_CATEGORIES', 'error');
            },
        });
    }

    private startupCheck() {
        this.openCat('');
        this.selectedCar = this.carService.selectedCar;
        this.nxDetails = false;
        this.technicalDataService.nxDetails = false;
        this.getTechnicalDetailsCategories();
    }

    public backInFrame() {
        const frame = window.frames['tecrmi-manual'];
        if (!frame) {
            return;
        }
        const frames: any = document.getElementById('tecrmi-manual');
        if (frames.contentWindow.location.href !== frames.src) {
            frames.contentWindow.history.back();
        }
    }

    public printIframe() {
        const frame = window.frames['tecrmi-manual'];
        if (!frame) {
            return;
        }

        if (this.helperService.browserIsIE()) {
            frame.document.execCommand('print', false, null);
        } else {
            const frames: any = document.getElementById('tecrmi-manual');
            frames.contentWindow.focus();
            frames.contentWindow.print();
        }
    }

    public getTechnicalData(mainGroupId: number, mainGroupName: string) {
        if (this.carService.getAdcTypeId() > 0) {
            this.tecRmiRestService.getTechnicalData(this.carService.getAdcTypeId(), mainGroupId).subscribe(
                (response) => {
                    this.nxDetails = response;
                    this.technicalDataService.nxDetails = response;
                    this.nxManualUrl = false;
                    this.technicalDataService.nxManualUrl = false;
                    this.nxDetailsTitle = mainGroupName;
                    this.technicalDataService.nxDetailsTitle = mainGroupName;
                },
                error => {
                    if (error.status === 500) {
                        this.stateService.technicalDataContent.next('TECHNICAL_ERROR');
                    }
                }
            );
        }
    }


    public openDiagnostic(data: any) {
        return this.tecRmiRestService.getDiagnosticTree(this.carService.getAdcTypeId(), data.id).subscribe(
            (response) => {
                data.subGroups = response[0].subGroups;
            },
            error => {
                if (error.status === 500) {
                    this.stateService.technicalDataContent.next('TECHNICAL_ERROR');
                }
            }
        );
    }

    private resetSelectedCategory() {
        this.nxDetails = false;
        this.nxManualUrl = false;
        this.selectedCat = undefined;
        this.selectedItem = false;
        this.selectedChildCat = undefined;
        this.selectedDataCat = undefined;
        this.isDiagnosis = false;

        this.technicalDataService.nxDetails = false;
        this.technicalDataService.nxManualUrl = false;
        this.technicalDataService.isCarDetails = false;
        this.technicalDataService.selectedItem = false;
        this.technicalDataService.selectedCat = undefined;
        this.technicalDataService.isDiagnosis = false;
        this.technicalDataService.selectedChildCat = undefined;
        this.technicalDataService.selectedDataCat = undefined;
    }


    private toggleItem(data: any, main: boolean = false) {
        if (!data) {
            return;
        }

        if (main) {
            if (data === this.selectedItem) {
                this.selectedItem = false;
            } else {
                this.selectedItem = data;
            }
        }

        if (typeof data !== 'object') {
            return;
        }
        if (!data['selected']) {
            data['selected'] = true;
        } else {
            data['selected'] = false;
        }
    }

    public openCat(name: any) {
        this.selectedCat = name;
        this.selectedChildCat = undefined;
        this.selectedDataCat = undefined;
        this.selectedChild = undefined;
        this.nxDetails = false;
        this.nxManualUrl = false;
        this.selectedItem = name.qualifier;
        this.selectedChild = '';
        this.isDiagnosis = false;

        this.technicalDataService.selectedChildCat = undefined;
        this.technicalDataService.selectedDataCat = undefined;
        this.technicalDataService.nxDetails = false;
        this.technicalDataService.nxManualUrl = false;
        this.technicalDataService.selectedItem = name.qualifier;
        this.technicalDataService.selectedChild = '';
        this.technicalDataService.isDiagnosis = false;
        this.technicalDataService.selectedCat = name;
        this.technicalDataService.isCarDetails = false;
    }

    public openCatChild(data: any) {
        this.selectedDataCat = undefined;
        this.selectedChildCat = data;
        this.nxDetails = false;
        this.nxManualUrl = false;
        this.selectedChild = data.name;
        this.isDiagnosis = false;
    }

    public openDiagnosticCat(data: any, name: string) {
        this.technicalDataService.isCarDetails = false;
        this.isDiagnosis = true;
        this.technicalDataService.isDiagnosis = true;
        this.nxDetails = false;
        this.technicalDataService.nxDetails = false;
        this.nxManualUrl = false;
        this.technicalDataService.nxManualUrl = false;
        this.selectedChild = data.name;
        this.technicalDataService.selectedChild = data.name;
        this.selectedCat = undefined;
        this.technicalDataService.selectedCat = undefined;
        this.selectedDataCat = undefined;
        this.technicalDataService.selectedDataCat = undefined;
        this.selectedsubChild = undefined;
        this.technicalDataService.selectedsubChild = undefined;
        this.selectedChildCat = undefined;
        this.technicalDataService.selectedChildCat = undefined;
        this.nxDiag = data;
        this.technicalDataService.nxDiag = data;
        this.nxDiagFolder = undefined;
        this.technicalDataService.nxDiagFolder = undefined;
        this.tecRmiRestService.getDiagnosticTree(this.carService.getAdcTypeId(), data.componentTypeId).subscribe(
            (response) => {
                data.subGroups = response;
            }
        );
        this.technicalDataService.isDiagnosis = true;
    }

    public openDiagSub(data: any) {
        this.selectedDiagPDF = data.text;
        this.nxManualUrl = this.tecRmiRestService.getDiagnosticManual(this.carService.getAdcTypeId(), data.manuals[0]);
        this.technicalDataService.nxManualUrl = this.tecRmiRestService.getDiagnosticManual(this.carService.getAdcTypeId(), data.manuals[0]);
    }

    public openCatData(data: any, name: string) {

        this.technicalDataService.isCarDetails = false;
        this.showPdf = false;
        this.technicalDataService.showPdf = false;
        if (data.subGroups) {
            this.selectedChildCat = data;
            this.technicalDataService.selectedChildCat = data;
            this.selectedDataCat = undefined;
            this.technicalDataService.selectedDataCat = undefined;
            this.nxDetails = false;
            this.technicalDataService.nxDetails = false;
            this.nxManualUrl = false;
            this.technicalDataService.nxManualUrl = false;
            this.selectedChild = data.name;
            this.technicalDataService.selectedChild = data.name;
        } else {
            if (!data.manuals && !data.manualId) {
                this.selectedDataCat = data;
                this.technicalDataService.selectedDataCat = data;
            }
            if (data.manuals) {
                name = this.selectedCat.qualifier;
                name = this.technicalDataService.selectedCat.qualifier;
                data = data.manuals[0];
            } else {
                name = this.selectedCat.qualifier;
                name = this.technicalDataService.selectedCat.qualifier;
            }

            switch (name) {
                case 'VEHICLE_SPEC':
                    const mainGroupId = data.id;
                    this.getTechnicalData(mainGroupId, data.name);
                    break;
                case 'FUSE_RELAY':
                    const componentTypeId = data.id;
                    this.nxManualUrl = this.tecRmiRestService.getFuseOverview(this.carService.getAdcTypeId(), componentTypeId);
                    this.technicalDataService.nxManualUrl = this.tecRmiRestService.getFuseOverview(this.carService.getAdcTypeId(), componentTypeId);
                    break;
                case 'WIRING':
                    const manual = data.manualId;
                    this.nxManualUrl = this.tecRmiRestService.getWiringData(this.carService.getAdcTypeId(), manual);
                    this.technicalDataService.nxManualUrl = this.tecRmiRestService.getWiringData(this.carService.getAdcTypeId(), manual);
                    break;
                case 'COMFORT_WIRING':
                    const comfortWiringManual = data.manualId;
                    this.nxManualUrl = this.tecRmiRestService.getComfortWiringData(this.carService.getAdcTypeId(), comfortWiringManual);
                    this.technicalDataService.nxManualUrl = this.tecRmiRestService.getComfortWiringData(this.carService.getAdcTypeId(), comfortWiringManual);
                    break;
                case 'DIAGNOSTIC':
                    if (data.componentTypeId && data.mountPositionId) {
                        this.nxManualUrl = this.tecRmiRestService.getDiagnosticManual(this.carService.getAdcTypeId(), data);
                        this.technicalDataService.nxManualUrl = this.tecRmiRestService.getDiagnosticManual(this.carService.getAdcTypeId(), data);
                    } else {
                        this.openDiagnostic(data);
                        this.selectedItem = data.name;
                        this.technicalDataService.selectedItem = data.name;
                        this.selectedChildCat = data;
                        this.technicalDataService.selectedChildCat = data;
                        this.selectedDataCat = undefined;
                        this.technicalDataService.selectedDataCat = undefined;
                        this.nxDetails = false;
                        this.technicalDataService.nxDetails = false;
                        this.nxManualUrl = false;
                        this.technicalDataService.nxManualUrl = false;
                        this.selectedChild = data.name;
                        this.technicalDataService.selectedChild = data.name;
                    }
                    break;
                case 'MAINTENANCE':
                    const maintenance = data.manualId;
                    this.nxManualUrl = this.tecRmiRestService.getTechnicalcManual(this.carService.getAdcTypeId(), maintenance);
                    this.technicalDataService.nxManualUrl = this.tecRmiRestService.getTechnicalcManual(this.carService.getAdcTypeId(), maintenance);
                    break;
                case 'TECHNICAL':
                    const manualId = data.manualId;
                    this.nxManualUrl = this.tecRmiRestService.getTechnicalcManual(this.carService.getAdcTypeId(), manualId);
                    this.technicalDataService.nxManualUrl = this.tecRmiRestService.getTechnicalcManual(this.carService.getAdcTypeId(), manualId);
                    break;
                case 'COMPONENT_LOCATION':
                    const componentlocation = data.manualId;
                    this.nxManualUrl = this.tecRmiRestService.getComponentLocation(this.carService.getAdcTypeId(), componentlocation);
                    this.technicalDataService.nxManualUrl = this.tecRmiRestService.getComponentLocation(this.carService.getAdcTypeId(), componentlocation);
                    break;
            }
        }
    }
}
