<app-message
        *ngIf="cartService.showCartMessage"
        [textFirst]="'PROMPTS.MESSAGE.NEW_CART.NEW_CART_TEXT' | translate"
        [textSecond]=""
        [hasNameInput]="true"
        [btn]="['PROMPTS.BUTTONS.CREATE_NEW' | translate, 'PROMPTS.BUTTONS.KEEP' | translate]"
        [title]="'PROMPTS.MESSAGE.NEW_CART.TITLE' | translate"
        (nameOutput)="cartService.cartName = $event"
        (onModalClose)="cartService.cartMessageHandler($event)"
        (onBtnClick)="cartService.cartMessageHandler($event)">
</app-message>

<div class="roleError" *ngIf="userService.showRoleError">
    <div class="centerCard">
        <div class="alert alert-danger text-center">
            <span><b>{{'LOGIN.NO_ROLES_HEADER' | translate}}</b></span><br />
            <span>{{'LOGIN.NO_ROLES_TEXT' | translate}}</span>
        </div>
        <button class="base-btn" (click)="logout()">
            <i class="fa fa-sign-out mr-1"></i>
            <span>{{'HEADER.LOGOUT' | translate}}</span>
        </button>
    </div>
</div>

<router-outlet></router-outlet>
