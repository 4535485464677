<div class="global-ordermail" (click)="closeOrderMail.emit()">
    <div class="global-ordermail-center" (click)="onEvent($event)">
        <div class="row background header-title">
            <div class="col-10">
                <span>Mail - {{orderMail.subject}}</span>
            </div>
            <div class="col-2">
                <button class="global-ordermail-button" (click)="closeOrderMail.emit()">
                    <i class="fa fa-times"></i>
                </button>
            </div>
        </div>
        <div class="global-ordermail-content">
            <div class="row mail-header">
                <div class="col-3 mail-header-padding-left">Absender</div>
                <div class="col-9">{{orderMail.from}}</div>
            </div>
            <div class="row mail-header">
                <div class="col-3 mail-header-padding-left">Empfänger</div>
                <div class="col-9">{{orderMail.to}}</div>
            </div>
            <div class="row mail-header">
                <div class="col-3 mail-header-padding-left">Datum</div>
                <div class="col-9">{{orderMail.created | date}}</div>
            </div>
            <div class="row mail-header">
                <div class="col-3 mail-header-padding-left">Status</div>
                <div class="col-9">
                    <span *ngIf="orderMail.sent" class="font-color-green">Gesendet, {{orderMail.sent | date}}</span>
                    <span *ngIf="!orderMail.sent" class="font-color-red">Noch nicht gesendet</span>
                </div>
            </div>
            <div class="mail-content-wrapper">
                <div class="mail-content" [innerHTML]="orderMail.body"></div>
            </div>
            <div class="row mail-attachments">
                <button class="base-btn"
                        (click)="download(attachment)"
                        *ngFor="let attachment of orderMail.attachments">{{attachment.fileName}}
                </button>
            </div>
        </div>
    </div>
</div>
