<div class="club-supplier-config">

    <div class="club-setting-title">
        <h1>
            <strong>
                {{'CLUB_ADMIN.SUPPLIER.TITLE' | translate}}
            </strong>
        </h1>
    </div>
    <div class="loading-suppliers" *ngIf="loadingSuppliers">
        <app-default-loading-page></app-default-loading-page>
    </div>

    <div class="alert alert-info text-center" *ngIf="!loadingSuppliers && !(allSuppliers.length > 0)">
        <i class="fa fa-info-circle mr-2"></i>
        <span>{{'CLUB_ADMIN.SUPPLIER.NO_SUPPLIERS' | translate}}</span>
    </div>

    <ng-container *ngIf="allSuppliers && clubSuppliers">
        <div class="row">
            <div class="col-7">
                <button class="base-btn resetButton" (click)="reset()"><span><i
                        class="fa fa-history"></i>{{'LOGIN.RESET' | translate}}</span></button>
            </div>
        </div>
        <div class="row">
            <div class="col-5">
                <div class="allSupplier">
                    <app-material-form-input [(text)]="allSuppliersInput"
                                             (textChange)="allSuppliersList = reduceList(allSuppliers, $event)"
                                             [placeholder]="'TYRE.SEARCHTERM' | translate"></app-material-form-input>
                    <i class="fa fa-times" (click)="allSuppliersList = allSuppliers; allSuppliersInput = ''"
                       *ngIf="allSuppliersInput?.length > 0"></i>
                    <div class="allSupplierBox">
                        <ng-container *ngFor="let supplier of allSuppliersList">
                            <div class="allSupplierItem" *ngIf="!isInClubSupplierList(supplier)"
                                 (click)="selectedSupplier = supplier"
                                 [ngClass]="{'selected': selectedSupplier && selectedSupplier === supplier}">
                                <span>{{supplier?.name}}</span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <button class="base-btn arrowButton" (click)="removeSupplier()"><i class="fa fa-angle-left"></i>
                </button>
                <button class="base-btn arrowButton float-right" (click)="addSupplier()"><i
                        class="fa fa-angle-right"></i></button>
            </div>
            <div class="col-5">
                <div class="clubSupplier">
                    <app-material-form-input [(text)]="clubSuppliersInput"
                                             (textChange)="clubSuppliersList = reduceList(clubSuppliers, $event)"
                                             [placeholder]="'TYRE.SEARCHTERM' | translate"></app-material-form-input>
                    <i class="fa fa-times" (click)="clubSuppliersList = clubSuppliers; clubSuppliersInput = ''"
                       *ngIf="clubSuppliersInput?.length > 0"></i>
                    <div class="clubSupplierBox">
                        <div class="clubSupplierItem" *ngFor="let clubSupplier of clubSuppliersList"
                             (click)="selectedClubSupplier = clubSupplier"
                             [ngClass]="{'selected': selectedClubSupplier && selectedClubSupplier === clubSupplier}">
                            <span>{{clubSupplier.name}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <button class="base-btn success float-right" (click)="updateClubSuppliers()"
            *ngIf="configService.hasUnsavedSettings('SUPPLIER')">
        <i class="fa fa-check" *ngIf="!loadingSaveSuppliers"></i>
        <i class="fa fa-spinner" *ngIf="loadingSaveSuppliers"
           [ngClass]="{'fa-pulse': !helperService.browserIsEdge() && !helperService.browserIsIE()}"></i>
        {{'CLUB_ADMIN.PARTNER_MANAGEMENT.SAVE' | translate}}
    </button>
</div>
