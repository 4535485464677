<div class="global-customid">
    <div class="heading blue">
        <span class="clickable" style="cursor: pointer;" routerLink="/global-admin">
            <i class="fa fa-th" aria-hidden="true"></i>
            {{'ADMIN_PANEL.OVERVIEW' | translate}}
        </span>
        <span class="clickable" routerLink="/global-admin/import">
            <i class="fa fa-caret-right"></i>
            {{'GLOBAL_ADMIN.GLOBAL_MAPPING_IMPORT' | translate}}
        </span>
        <span class="clickable">
            <i class="fa fa-caret-right"></i>
            {{'GLOBAL_ADMIN.CUSTOMID' | translate}}
        </span>
    </div>
    <div class="panel-content center-content white-background">
        <div class="row mb-4">
            <div class="col">
                <h1>OE Import</h1>
                <label class="mr-4" for="oe">Choose File</label>
                <input type="file"
                       accept="text/*"
                       (change)="handleOeImportFileInput($event.target.files)"
                       multiple
                       id="oe">

                <button (click)="uploadOe()" *ngIf="oeImportFile" class="base-btn ml-4">
                    <i *ngIf="oeUploadingProgressing" class="fa fa-spinner" [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i>
                    <span *ngIf="oeUploadingProgressing"> uploading <b>{{oeUploadProgress}}%</b> </span>
                    <span *ngIf="!oeUploadingProgressing"> upload </span>
                </button>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col">
                <h1>Article Import</h1>
                <label class="mr-4" for="article">Choose File</label>
                <input type="file"
                       accept="text/*"
                       (change)="handleArticleImportFileInput($event.target.files)"
                       multiple
                       id="article">
                <button (click)="uploadArticle()" *ngIf="articleImportFile" class="base-btn ml-4">upload</button>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col">
                <h1>Work Import</h1>
                <label class="mr-4" for="work_itemMpIds">Choose itemMpIds</label>
                <input type="file"
                       accept="text/*"
                       (change)="handleWorkImportItemMpIdsFileInput($event.target.files)"
                       multiple
                       id="work_itemMpIds"><br><br>
                <label class="mr-4" for="work">Choose positionGroups</label>
                <input type="file"
                       accept="text/*"
                       (change)="handleWorkImportPositionGroupsFileInput($event.target.files)"
                       multiple
                       id="work">
                <button (click)="uploadWork()" *ngIf="itemMpIdsFile && positionGroupsFile" class="base-btn ml-4">upload</button>
            </div>
        </div>
    </div>
</div>
