<div class="content clean" *ngIf="userService.allow('GLOBAL-ADMIN')">
    <div class="left-panel no-float">
        <div class="panel-content left-sidebar">
            <ul class="custom-tree">
                <li class="list">
                    <div class="clickable">
                        <i class="folder fa fa-circle" routerLink="/global-admin" routerLinkActive="open"></i>
                    </div>
                    <span routerLink="/global-admin">{{ 'GLOBAL_ADMIN.TITLE' | translate }}</span>
                    <ul class="custom-tree">
                        <li class="list">
                            <div class="clickable">
                                <i class="folder fa fa-circle" routerLink="/global-admin/import" routerLinkActive="open"></i>
                            </div>
                            <span routerLink="/global-admin/import">{{'GLOBAL_ADMIN.GLOBAL_MAPPING_IMPORT' | translate}}</span>
                            <ul class="custom-tree">
                                <li class="list">
                                    <div class="clickable">
                                        <i class="folder fa fa-circle" routerLink="/global-admin/import/supplier"
                                           routerLinkActive="open"></i>
                                    </div>
                                    <span routerLink="/global-admin/import/supplier">{{'GLOBAL_ADMIN.SUPPLIER' | translate}}</span>
                                </li>
                                <li class="list">
                                    <div class="clickable">
                                        <i class="folder fa fa-circle" routerLink="/global-admin/import/oesupplier"
                                           routerLinkActive="open"></i>
                                    </div>
                                    <span routerLink="/global-admin/import/oesupplier">{{'GLOBAL_ADMIN.OE_SUPPLIER' | translate}}</span>
                                </li>
                                <li class="list">
                                    <div class="clickable">
                                        <i class="folder fa fa-circle" routerLink="/global-admin/import/customid"
                                           routerLinkActive="open"></i>
                                    </div>
                                    <span routerLink="/global-admin/import/customid">{{'GLOBAL_ADMIN.CUSTOMID' | translate}}</span>
                                </li>
                                <li class="list">
                                    <div class="clickable">
                                        <i class="folder fa fa-circle" routerLink="/global-admin/import/adcTypeMapping"
                                           routerLinkActive="open"></i>
                                    </div>
                                    <span routerLink="/global-admin/import/adcTypeMapping">{{'GLOBAL_ADMIN.ADCTYPE_MAPPING' | translate}}</span>
                                </li>
                            </ul>
                        </li>
                        <li class="list">
                            <div class="clickable">
                                <i class="folder fa fa-circle" routerLink="/global-admin/orderhistory"
                                   routerLinkActive="open"></i>
                            </div>
                            <span routerLink="/global-admin/orderhistory">{{'GLOBAL_ADMIN.GLOBAL_ORDERHISTORY.TITLE' | translate}}</span>
                        </li>

                        <li class="list">
                            <div class="clickable">
                                <i class="folder fa fa-circle" routerLink="/global-admin/griphistory"
                                   routerLinkActive="open"></i>
                            </div>
                            <span routerLink="/global-admin/griphistory">{{'GLOBAL_ADMIN.GLOBAL_GRIPHISTORY' | translate}}</span>
                        </li>

                        <li class="list">
                            <div class="clickable">
                                <i class="folder fa fa-circle" routerLink="/global-admin/country"
                                   routerLinkActive="open"></i>
                            </div>
                            <span routerLink="/global-admin/country">{{'GLOBAL_ADMIN.COUNTRY_CONFIG.TITLE' | translate}}</span>
                        </li>

                        <li class="list">
                            <div class="clickable">
                                <i class="folder fa fa-circle" routerLink="/global-admin/notifications"
                                   routerLinkActive="open"></i>
                            </div>
                            <span routerLink="/global-admin/notifications">{{'GLOBAL_ADMIN.NOTIFICATIONS.TITLE' | translate}}</span>
                        </li>

                        <li class="list">
                            <div class="clickable">
                                <i class="folder fa fa-circle" routerLink="/global-admin/club"
                                   routerLinkActive="open"></i>
                            </div>
                            <span routerLink="/global-admin/club">{{'GLOBAL_ADMIN.GLOBAL_CLUB.TITLE' | translate}}</span>
                        </li>
                        <li class="list">
                            <div class="clickable">
                                <i class="folder fa fa-circle" routerLink="/global-admin/statistics"
                                   routerLinkActive="open"></i>
                            </div>
                            <span routerLink="/global-admin/statistics">{{'GLOBAL_ADMIN.STATISTICS' | translate}}</span>
                            <ul class="custom-tree">
                                <li class="list" *ngFor="let club of adminService.clubs">
                                    <div class="clickable">
                                        <i class="folder fa fa-circle"
                                           routerLink="/global-admin/statistics/{{club.clubId}}"
                                           [ngClass]="{'open': club.clubId === globalAdminService?.selectedClubId}"></i>
                                    </div>
                                    <span routerLink="/global-admin/statistics/{{club.clubId}}">{{club.name}}</span>
                                </li>
                            </ul>
                        </li>
                        <li class="list">
                            <div class="clickable">
                                <i class="folder fa fa-circle" routerLink="/global-admin/vinlog"
                                   routerLinkActive="open"></i>
                            </div>
                            <span routerLink="/global-admin/vinlog">{{'GLOBAL_ADMIN.VIN_LOG.TITLE' | translate}}</span>
                        </li>
                        <li class="list">
                            <div class="clickable">
                                <i class="folder fa fa-circle" routerLink="/global-admin/communicationslist"
                                   routerLinkActive="open"></i>
                            </div>
                            <span routerLink="/global-admin/communicationslist">{{'GLOBAL_ADMIN.SUPPLIER_COMMUNICATION.TITLE' | translate}}</span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    <div [@fadeSmallAnimation]="getRouteAnimation(route)" class="center-panel no-float">
        <router-outlet #route="outlet"></router-outlet>
    </div>
    <div class="right-panel no-float">
        <div class="panel-content right-sidebar">
            <app-global-order-details
                    *ngIf="location.path().endsWith(globalAdminService?.orderDetails?.id)"></app-global-order-details>
            <app-grip-order-details *ngIf="location.path().endsWith('details')"></app-grip-order-details>
        </div>
    </div>
    <ng-container *ngIf="globalAdminService.showTransferLog">
        <app-transferlog [transferLog]="globalAdminService.transferLog"
                         (closeTransferLog)="globalAdminService.showTransferLog = false">
        </app-transferlog>
    </ng-container>
    <ng-container *ngIf="globalAdminService.showOrderMail">
        <app-global-ordermail [orderMail]="globalAdminService.orderMail"
                       (closeOrderMail)="globalAdminService.showOrderMail = false">
        </app-global-ordermail>
    </ng-container>
    <ng-container *ngIf="globalAdminService.showTransferLogGrip">
        <app-transferlog-grip></app-transferlog-grip>
    </ng-container>
    <ng-container *ngIf="globalAdminService.showTransferLogGripSupplier">
        <app-transferlog-grip-supplier></app-transferlog-grip-supplier>
    </ng-container>

</div>
