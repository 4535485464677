import {EventEmitter, Injectable} from '@angular/core';
import {GlobalAdminRestService} from './global-admin.rest.service';
import {TranslateService} from '@ngx-translate/core';
import {IOrder, IOrderList, ITransferLog} from '../interfaces/order';
import {Subscription} from 'rxjs/Rx';
import {UserService} from '../../../shared/services/user/user.service';
import {IOrderHistoryRequest} from '../interfaces/orderHistoryRequest';
import {IOrderAdmin, IOrderAdminMailContent} from '../../../shared/interfaces/DTO/order/orderAdmin';
import {IMonthlyRequests, IVinResponses, IVinStatisticRequest, IVinStatisticResponse} from '../interfaces/vinStatistic';
import {ICreateClub} from '../interfaces/createClub';
import {IVinLog} from '../../../shared/interfaces/DTO/autovista/vinLog';
import {ICountry} from '../../../shared/interfaces/DTO/firm/country';
import {AutovistaRestService} from '../../../graphical-part-search/services/autovista.rest.service';
import {ISupplierCommunicationSearchRequest} from '../interfaces/supplierCommunication';
import {ISetting} from '../../../shared/interfaces/DTO/club/setting';
import {HelperService} from '../../../shared/services/helper.service';

@Injectable()
export class GlobalAdminService {

    public orderList: IOrderList;
    public gripsOrderList: IOrderList;
    public orderDetails: IOrderAdmin;
    public currentOrder: IOrder;
    public newDate: Date;
    public transferLog: ITransferLog[];
    public showTransferLog: boolean = false;
    public showTransferLogGrip: boolean = false;
    public showTransferLogGripSupplier: boolean = false;
    public orderIdGrip: string;
    public selectedClubId: number;
    public orderHistoryRequest: IOrderHistoryRequest;
    public club: ICreateClub;
    public gripHistoryRequest: any;
    public communicationRequest: ISupplierCommunicationSearchRequest;
    public communicationLog: any;
    public communicationResult: any;
    public loadingPlacedOrders: boolean = false;
    public vinStatistics: IVinStatisticResponse;
    public loadStatistics: boolean = false;
    public vinCount: number = 0;
    public vinLogRequest: string;
    public vinLog: any;
    public loadingVinLog: boolean = false;
    public loadingCommunicationsList: boolean = false;
    public selectedCountry: ICountry;
    public selectedExternalTool: ISetting;
    public selectedOrderId: string;
    public showOrderMail: boolean = false;
    public orderMail: IOrderAdminMailContent;

    // infiniteScroll
    public infinityLoading: boolean = false;
    private activeSubscription: Subscription;
    public emitItems: EventEmitter<any> = new EventEmitter<any>();
    public emitLogs: EventEmitter<any> = new EventEmitter<any>();
    public resultItems: any = [];
    public vinLogResult: any = [];
    public resultGripItems: any = [];

    constructor(private globalAdminRestService: GlobalAdminRestService,
                private helperService: HelperService,
                private translate: TranslateService,
                private autovistaRestService: AutovistaRestService,
                private userService: UserService) {
        this.orderHistoryRequest = {
            clubId: null,
            firmName: '',
            name: '',
            id: '',
            reference: '',
            startDate: '',
            platformOrderId: '',
            endDate: '',
            page: 0,
            pageSize: 20
        }

        this.gripHistoryRequest = {
            clubId: null,
            firmName: '',
            name: '',
            id: '',
            reference: '',
            startDate: '',
            endDate: '',
            page: 0,
            pageSize: 20,
            externalId: null
        }

        this.communicationRequest = {
            firmName: '',
            supplier: '',
            startDate: '',
            endDate: '',
            page: 0,
            pageSize: 20
        }

    }

    public loadNext(event: any, loadGripsOrders: boolean) {
        if (event !== 0 && !this.infinityLoading) {
            if (this.activeSubscription) {
                this.activeSubscription.unsubscribe();
            }

            if (this.userService.enableInfiniteScroll) {
                if (loadGripsOrders && this.gripsOrderList && (this.gripsOrderList.totalElements / this.gripsOrderList.pageSize) > (this.gripsOrderList.page + 1)) {
                    this.infinityLoading = true;
                    this.gripHistoryRequest.page += 1;
                    this.getPlacedGripOrders();

                } else if (!loadGripsOrders && this.orderList && (this.orderList.totalElements / this.orderList.pageSize) > (this.orderList.page + 1)) {
                    this.infinityLoading = true;
                    this.orderHistoryRequest.page += 1;
                    this.getPlacedOrders();

                } else if (this.communicationRequest && (this.communicationResult.totalElements / this.communicationResult.pageSize) > (this.communicationResult.page + 1)) {
                    this.infinityLoading = true;
                    this.communicationRequest.page += 1;
                    this.getCommunicationsList();
                }
            }
        }
    }

    public getPlacedOrders() {
        this.loadingPlacedOrders = true;
        this.globalAdminRestService.getPlacedOrdersRest(this.orderHistoryRequest).subscribe(
            (orderList) => {
                this.loadingPlacedOrders = false;
                this.orderList = orderList;
                if (this.userService.enableInfiniteScroll) {

                    for (const item of orderList.orders) {
                        this.resultItems.push(item);
                    }

                    this.emitItems.emit(this.resultItems);
                    this.infinityLoading = false;
                } else {
                    this.resultItems = [];
                }
        },
            (error) => {
                this.loadingPlacedOrders = false;
                this.helperService.showNotification('Fehler beim Abrufen der Bestellhistorie.', 'error');
            });
    }

    public getVinLog() {
        this.globalAdminRestService.getVinLog(this.vinLogRequest).subscribe(
            (log) => {
                this.loadingVinLog = false;
                this.vinLogResult = log;
                if (!Object.keys(this.vinLogResult.clubLogs)[0]) {
                    this.helperService.showNotification('Für die VIN: ' +  this.vinLogRequest + ' wurde kein Eintrag gefunden', 'info');
                }
                if (this.userService.enableInfiniteScroll) {
                    this.emitLogs.emit(this.vinLogResult);
                    this.infinityLoading = false;
                } else {
                    this.vinLogResult = log;
                }
            },
            (error) => {
                this.loadingVinLog = false;
                this.helperService.showNotification('Fehler beim Abrufen der Vin Logs', 'error');
            });
    }


    public getCommunicationsList() {
        this.loadingCommunicationsList = true;

        this.globalAdminRestService.getSupplierCommunicationListRest(this.communicationRequest).subscribe(
            (data) => {
                if (this.userService.enableInfiniteScroll && this.communicationRequest.page > 0) {
                    for (const log of data.logs) {
                        this.communicationResult.logs.push(log)
                    }
                    this.infinityLoading = false;
                } else {
                    this.communicationResult = data;
                }
                this.loadingCommunicationsList = false;
            }, (error) => {
                this.loadingCommunicationsList = false;
                this.helperService.showNotification('Fehler beim Abrufen der Communication Logs', 'error');
            });
    }


    public getPlacedGripOrders() {
        this.loadingPlacedOrders = true;
        this.globalAdminRestService.getPlacedGripOrdersRest(this.gripHistoryRequest).subscribe(
            (gripsOrderList) => {
                this.loadingPlacedOrders = false;
                this.gripsOrderList = gripsOrderList;
                if (this.userService.enableInfiniteScroll) {

                    for (const item of gripsOrderList.orders) {
                        this.resultGripItems.push(item);
                    }

                    this.emitItems.emit(this.resultGripItems);
                    this.infinityLoading = false;
                } else {
                    this.resultGripItems = [];
                }
            },
            (error) => {
                this.loadingPlacedOrders = false;
                this.helperService.showNotification('Fehler beim abrufen der Bestellhistorie.', 'error');
            });
    }


    public getOrderDetails(orderId: string) {
        this.globalAdminRestService.getOrderDetailsRest(orderId).subscribe(
            (orderDetails) => {
                this.orderDetails = orderDetails;
                this.selectedOrderId = orderId;
        },
            (error) => {
                this.helperService.showNotification('Fehler beim Abrufen der Bestelldetails.', 'error');
            });
    }

    public getAutovistaVinStatistic(statistic: IVinStatisticRequest) {
        this.loadStatistics = true;
        this.autovistaRestService.getAutovistaVinStatisticRest(statistic).subscribe(
            (statistics) => {
                this.vinStatistics = statistics;
                if (this.vinStatistics && this.vinStatistics.responses) {
                    this.vinCount = 0;
                    this.vinStatistics.responses.forEach((vinFirm: IVinResponses) => {
                        vinFirm.monthlyRequests.forEach((month: IMonthlyRequests) => {
                            this.vinCount += month.count;
                        })
                    });
                }
                this.loadStatistics = false;
            },
            (error) => {
                this.loadStatistics = false;
            });
    }

    public getOrderTransferLog(supplier: string, supplierName: string) {
        this.globalAdminRestService.getOrderTransferLogRest(supplier, this.orderDetails.id).subscribe(
            (transferLog) => {
                if (transferLog && transferLog.length > 0) {
                    this.transferLog = transferLog;
                } else {
                    this.transferLog = [{id: '', response: '', request: '', supplier: '', supplierName, orderId: ''}];
                    this.transferLog[0].supplier = supplier;
                    this.transferLog[0].supplierName = supplierName;
                    this.transferLog[0].request = 'Kein Datensatz vorhanden.';
                    this.transferLog[0].response = 'Kein Datensatz vorhanden.';
                    this.transferLog[0].id = this.orderDetails.id;
                }
                this.showTransferLog = true;
            },
            (error) => {
                this.helperService.showNotification('Fehler beim Abrufen des Transferlogs.', 'error');
            }
        );
    }

    public setOrder(order: IOrder) {
        this.currentOrder = order;
    }

    public setVinLog(log: IVinLog) {
        this.vinLog = log;
    }

    public setCommunicationLog(log: any) {
        this.communicationLog = log;
    }


    public reset() {

    }
}
