import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DmsCarService} from '../../services/dms-car.service';
import {HelperService} from '../../services/helper.service';
import {CarService} from '../../services/car/car.service';
import {UserService} from '../../services/user/user.service';
import {ICar} from '../../interfaces/DTO/car/car';
import {CartService} from '../../../cart/services/cart.service';
import {DmsDataService} from '../../services/dms-data.service';
import {ConfigService} from '../../services/config.service';
import {ISetting} from "../../interfaces/clubSettings";

@Component({
    selector: 'app-dms-car-selection',
    templateUrl: './dms-car-selection.component.html',
    styleUrls: ['./dms-car-selection.component.scss']
})
export class DmsCarSelectionComponent implements OnInit{

    @Output() applyCars: EventEmitter<any> = new EventEmitter<any>();

    public vinInfoText: string;
    public showVinInfoText: boolean = false;
    public numberOfVehiclesDisplayed: number = 5;

  constructor(public dmsCarService: DmsCarService,
              public dmsDataService: DmsDataService,
              public helperService: HelperService,
              public carService: CarService,
              public userService: UserService,
              public configService: ConfigService,
              public cartService: CartService) {
    this.dmsCarService.directAcceptCar.subscribe((type: string) => {
      this.setCar(type);
    });
  }


  ngOnInit():void {
      // get and set Vin Settings
      this.showVinInfoText = this.userService.getSubSettingForName('EXTENDED_SETTINGS', 'VIN_TEXT').active;
      const vinTextSetting: ISetting = this.userService.getSubSettingForName('EXTENDED_SETTINGS', 'VIN_TEXT');
      if (vinTextSetting?.value?.length) {
          this.vinInfoText = vinTextSetting.value;
      }

  }

    public setCar(type: string) {
        if (type === 'NONE' && this.carService.selectedCar) {
            this.carService.ejectCar();
        } else {
            this.dmsCarService.lastSelectionType = type;
            const cars = this.dmsCarService.carHolder.get(type) ? this.dmsCarService.carHolder.get(type).cars : [];
            this.applyCars.emit(cars);
        }
    }

    public setCarDirect(car: ICar, type: string) {
        if (car) {
            this.dmsCarService.lastSelectionType = type;
            this.applyCars.emit([car]);
        }
    }

    public getCarsForType(type: string) {
        if (type) {
            return this.dmsCarService.getCarForType(type) ? this.dmsCarService.getCarForType(type).cars : [];
        }
    }

    public getDisplayName(type: string) {
        if (type) {
            return this.dmsCarService.getCarForType(type)
            && this.dmsCarService.getCarForType(type).cars
            && this.dmsCarService.getCarForType(type).cars.length > 0
            && this.dmsCarService.getCarForType(type).cars[0].displayName
                ? this.dmsCarService.getCarForType(type).cars[0].displayName : '';
        }
    }

    public cartHasCar() {
      return this.cartService.currentCart && this.cartService.currentCart.cars.length > 0;
    }

    public selectCarFromCart() {
      this.cartService.selectCarFromCart();
    }

    public getCarDisplayName(car: ICar): string {
      return car.displayName + (car.engineCodes ? ' (' + car.engineCodes + ') ' : '');
    }
}
