import {EventEmitter, Injectable, Output} from '@angular/core';
import {IArticleTreeItem} from '../interfaces/article-tree-item';
import {ArticlesRestService} from '../services/articles.rest.service';
import {CarService} from '../../shared/services/car/car.service';
import {IAssemblyCategory} from '../interfaces/assembly-category';
import {UserService} from '../../shared/services/user/user.service';
import {ArticlesDetailsService} from '../../shared/services/articles-details.service';
import {CompanyAdminService} from '../../admin/company/services/company-admin.service';
import {Router} from '@angular/router';
import {IItem} from '../interfaces/article';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ConfigService} from '../../shared/services/config.service';
import {BehaviorSubject} from "rxjs";


@Injectable()
export class ArticlesService extends ArticlesDetailsService {

    public filterChanged: boolean;
    public articleTree: IArticleTreeItem[];
    public articlesSearchResult: any;
    public treeFilter: any;
    public bodygraphics: boolean;
    public additionalArticleDetails: any;
    public showSellinPrices: boolean = false;
    public loadCars: boolean = false;
    public sellOutGrossPerPiece: any;
    public foldedCollapsePanel: string[] = [];
    public scrollTop: number;
    public searchOpen: boolean;
    public directSearchValue: string;
    public searchTypes: string[] = ['OE', 'AFTER_MARKET'];

    public selectedSearchResultSize: string = 'normal';

    public showPseudoArticle: boolean = false;
    public selectedPseudoArticle: IItem;
    public hidePartsListPseudo: boolean = false;

    public filterGroupNames: string[] = [];

    // loading indicators
    public treeLoading: boolean = false;
    public additionalDetailsLoading: boolean = false;

    public treeStateChanged$: EventEmitter<boolean> = new EventEmitter<boolean>(); // true -> expand, false -> collapse
    public treeCategoryChanged$: EventEmitter<IAssemblyCategory> = new EventEmitter<IAssemblyCategory>();
    public emitItem: EventEmitter<any> = new EventEmitter<any>();
    private updateFilterTime: Date;
    public showSortLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isInPseudoArticleView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public currentPseudoArticle$: BehaviorSubject<IItem> = new BehaviorSubject<IItem>(undefined);


    public resortEmitterNewPageNeeded: EventEmitter<boolean> = new EventEmitter<boolean>();
    public scrollEndEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Output() onReset: EventEmitter<any> = new EventEmitter();

    constructor(private carService: CarService,
                private userService: UserService,
                private articleRestService: ArticlesRestService,
                private companyAdmin: CompanyAdminService,
                private translate: TranslateService,
                private toastr: ToastrService,
                public router: Router,
                private config: ConfigService) {
        super(articleRestService, carService, userService, companyAdmin, translate, toastr, config);
        if (localStorage.getItem('searchResultSize') && localStorage.getItem('searchResultSize') !== null) {
            this.selectedSearchResultSize = localStorage.getItem('searchResultSize');
        }

        this.carService.selectedCarChanged$.subscribe((car) => {
            this.treeFilter = undefined;
            this.onReset.emit('newCar');
            this.resetToDefaultTree();
            this.selectedArticle = undefined;
        });
    }

    public setSelectedSearchResultSize(size: string): void {
        if (size) {
            localStorage.setItem('searchResultSize', size);
            this.selectedSearchResultSize = size;
        }
    }

    public setPseudoArticle(article: any, partlist?: any): void {
        if (article && article.pseudoArticle) {
            this.showPseudoArticle = true;
            this.selectedPseudoArticle = article;
        }
        if ((article && article.partList?.length > 1) || partlist && partlist.length) {
            this.showPseudoArticle = true;
            this.userService.enableInfiniteScroll = false;
        }
    }

    public getFilterGroupNames(): void {
        this.filterGroupNames = [];
        for (const filter of this.searchResult.activeFilters) {
            if (this.filterGroupNames.indexOf(filter.groupName) < 0) {
                this.filterGroupNames.push(filter.groupName);
            }
        }

        this.filterGroupNames.sort();
    }


    public updateArticleTree(): void {
        let currentCar = this.carService.selectedCar,
            carId: number,
            adcId: number;

        carId = (currentCar && currentCar.kTypeId ? currentCar.kTypeId : 0); // 0 returns universal parts
        adcId = (this.selectedCategory && this.selectedCategory.adcTypeId ?
            this.selectedCategory.adcTypeId :
            (currentCar && currentCar.adcTypeId ? currentCar.adcTypeId : 0));
        this.treeLoading = true;
        this.articleRestService.getTreeData(carId, adcId).subscribe(
            (response) => {
                this.articleTree = response;
                this.treeLoading = false;
                this.onReset.emit('search');
            }
        );
    }

    public resetToDefaultTree(): void {
        this.selectedCategory = undefined;
        this.selectedPseudoArticle = undefined;
        this.showPseudoArticle = false;
        this.setTreeState(false);
        this.resetAll();
        this.resetArticles();
        if (this.activeSubscription) {
            this.activeSubscription.unsubscribe();
        }
        this.updateArticleTree();
    }

    public selectTreeItem(item: IArticleTreeItem): void {
        this.emitItem.emit(item);
        this.resetArticles();
        this.selectedCategory = item;
        if (this.activeSubscription) {
            this.activeSubscription.unsubscribe();
        }
        this.directSearchValue = '';
        this.supplierType = 'AFTER_MARKET';
        this.updateTreeBySelectedCategory();
    }

    public setTreeState(expanded: boolean): void {
        this.treeStateChanged$.emit(expanded);
    }

    public setTreeCategory(category: IAssemblyCategory): void {
        this.directSearchValue = '';
        this.supplierType = 'AFTER_MARKET';
        this.treeCategoryChanged$.emit(category);
    }

    public resetAll(): void {
        this.expanded_right = false;
        this.searchResult = undefined;
        this.resetArticles();
    }

    public toggleFolded(event: any, name: string): void {
        if (!event) {
            if (this.foldedCollapsePanel.indexOf(name) < 0) {
                this.foldedCollapsePanel.push(name);
            }
        } else {
            this.foldedCollapsePanel.splice(this.foldedCollapsePanel.indexOf(name), 1);
        }
    }

    public toggleActiveFilter(filter: any): void {
        this.filterChanged = true;
        const found = this.findFilter(filter, false);

        if (found >= 0) {
            this.searchResult.activeFilters.splice(found, 1);
        } else {
            if (this.searchResult.activeFilters) {
                this.searchResult.activeFilters.push(filter);
            } else {
                this.searchResult.activeFilters = [];
                this.searchResult.activeFilters.push(filter);
            }
        }
    }

    public updateFilter(): void {
        this.filterChanged = false;
        const time: Date = new Date();
        if ((!this.updateFilterTime || time.getTime() - this.updateFilterTime.getTime() > 500) && !this.articlesLoading && !this.infinityLoading) {
            this.updateFilterTime = time;
            if (this.searchResult.searchForAllArticles) {
                this.updateArticleSearch(1, this.searchResult.activeFilters, this.searchResult.searchForAllArticles);
            } else {
                this.updateArticleSearch(1, this.searchResult.activeFilters);
            }
        }
    }

    /*
    the display value is to translate the return value to a true / false value instead a number
    this is needed for the checkbox
     */
    public findFilter(filter: any, display: boolean) {
        let found: any = -1;
        if (this.searchResult.activeFilters && this.searchResult.activeFilters.length > 0) {
            for (let i = 0; i < this.searchResult.activeFilters.length; i++) {
                if (this.searchResult.activeFilters[i].value === filter.value && this.searchResult.activeFilters[i].groupId === filter.groupId) {
                    found = i;
                }
            }
        } else {
            found = -1;
        }

        if (found === -1 && display) {
            found = false;
        } else if (found >= 0 && display) {
            found = true;
        }

        return found;
    }

    public checkFilter(filter: any) {
        let temp = false;
        for (const filters of this.searchResult.activeFilters) {
            if (filters.value === filter.value && filters.groupId === filter.groupId) {
                temp = true;
            }
        }

        return temp;
    }

    public getIndexForFilterByGroupId(id: number): number {
        return this.searchResult.possibleFilters.findIndex((item: any) => parseInt(item.groupId) === id);
    }

    public resetFilter(): void {
        this.filterChanged = false;
        this.searchResult.activeFilters = [];
        this.foldedCollapsePanel = [];
        if (this.activeSubscription) {
            this.activeSubscription.unsubscribe();
        }
        this.updateArticleSearch(1, this.searchResult.activeFilters);
    }

    public showAllArticles(): void {
        this.filterChanged = false;
        this.searchResult.searchForAllArticles = true;
        if (this.activeSubscription) {
            this.activeSubscription.unsubscribe();
        }
        this.updateArticleSearch(1, this.searchResult.activeFilters, this.searchResult.searchForAllArticles);
    }

    public loadNext(event: any): void {
        if (!this.searchOpen) {
            if (event !== 0 && !this.infinityLoading) {
                this.scrollTop = event;
                if (this.activeSubscription) {
                    this.activeSubscription.unsubscribe();
                }
                if (this.userService.enableInfiniteScroll && this.searchResult && (this.searchResult.count / 20) > this.searchResult.page) {
                    this.updateArticleSearch(this.searchResult.page + 1, this.searchResult.activeFilters, this.searchResult.searchForAllArticles, this.supplierType);
                }
            }
        }
    }

    public resetArticles(): void {
        this.selectedCategory = undefined;
        this.selectedArticle = undefined;
        this.additionalArticleDetails = undefined;
        this.lastSearchParams = undefined;
        this.selectedArticleAvailability = undefined;
        this.resultItems = [];
        this.infinityLoading = false;
    }

}
