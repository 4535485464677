<div class="tyre-details">
    <div class="maintenance-data_title">
        <button class="base-btn pull-right"
                (click)="tyreService.printPdf(true, selectedTyreType, tyreSpecs, selectedCar)"
                *ngIf="pdf">
            <i class="fa fa-print"></i> {{ 'PRINT_VIEW.PRINT' | translate }}
        </button>

        <button class="base-btn pull-right"
                (click)="tyreService.downloadPdf(true, selectedTyreType, tyreSpecs, selectedCar)"
                *ngIf="pdf">
            <i class="fa fa-save"></i> {{ 'PRINT_VIEW.GENERATE_PDF' | translate }}
        </button>
        <h1 *ngIf="!pdf">
            <strong>{{ 'MAINTENANCE.INFO' | translate}}</strong>
        </h1>
        <h1 *ngIf="pdf">
            <strong>{{ 'MAINTENANCE.INFO' | translate }}</strong>
        </h1>
    </div>

    <app-default-loading-page *ngIf="loading"></app-default-loading-page>

    <div class="print-preview" *ngIf="pdf" style="top: 0!important">
        <app-default-loading-page *ngIf="loading"></app-default-loading-page>
        <pdf-viewer
                [src]="pdf"
                [autoresize]="false"
                [render-text]="false"
                [show-all]="true"
                style="display: block;"
        ></pdf-viewer>
    </div>

    <ng-container *ngIf="!pdf">
        <table class="simple-table">
            <thead>
            <tr>
                <td class="text-center">{{'TYRE.USAGE' | translate}}</td>
                <td>{{'TYRE.RIMSIZE' | translate}} ({{'TYRE.FRONT' | translate}})<br/>
                    {{'TYRE.SIZE' | translate}}
                </td>
                <td>{{'TYRE.RIMSIZE' | translate}} ({{'TYRE.BACK' | translate}})<br/>
                    {{'TYRE.SIZE' | translate}}
                </td>
                <td class="text-center"><i class="fa fa-info"></i></td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let tyre of selectedTyreType.details">
                <td class="text-center">{{tyre.type}}</td>
                <td>{{tyre.frontRimSize}}<br/>
                    {{tyre.frontTyreSize}}
                </td>
                <td>{{tyre.rearRimSize}}<br/>
                    {{tyre.rearTyreSize}}
                </td>
                <td class="text-center">{{tyre.infoText}}</td>
            </tr>
            </tbody>
        </table>
    </ng-container>
</div>
