import {Routes} from '@angular/router';
import {RootComponent} from './root/root.component';
import {LoginComponent} from './auth/login/login.component';
import {HomeComponent} from './home/home.component';
import {CartComponent} from './cart/cart.component';
import {ArticlesComponent} from './articles/articles.component';
import {TechnicalDataComponent} from './technical-data/technical-data.component';
import {WorkDataComponent} from './work-data/work-data.component';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {AdministrationComponent} from './admin/club/administration.component';
import {CompanyAdminComponent} from './admin/company/company-admin';
import {FirmDashboardComponent} from './admin/company/dashboard/dashboard.component';
import {FirmdataComponent} from './admin/company/firmdata/firmdata.component';
import {UsermanagementComponent} from './admin/company/usermanagement/usermanagement.component';
import {EdituserComponent} from './admin/company/usermanagement/edituser/edituser.component';
import {AdminDashboardComponent} from './admin/club/dashboard/dashboard.component';
import {CompanymanagementComponent} from './admin/club/companymanagement/companymanagement.component';
import {EditcompanyComponent} from './admin/club/companymanagement/editcompany/editcompany.component';
import {UserdataComponent} from './admin/profile/userdata/userdata.component';
import {SuppliersComponent} from './admin/company/suppliers/suppliers.component';
import {EditsuppliersComponent} from './admin/company/suppliers/editsuppliers/editsuppliers.component';
import {WageRateComponent} from './admin/company/settings/wage-rate/wage-rate.component';
import {SettingsComponent} from './admin/company/settings/settings.component';
import {GlobalComponent} from './admin/global/global.component';
import {CheckoutComponent} from './checkout/checkout.component';
import {ResetPwComponent} from './auth/reset-pw/reset-pw.component';
import {PartnerComponent} from './partner/partner.component';
import {PartnermanagementComponent} from './admin/club/partnermanagement/partnermanagement.component';
import {EditpartnerComponent} from './admin/club/partnermanagement/editpartner/editpartner.component';
import {SuccessComponent} from './checkout/response/success/success.component';
import {TyreComponent} from './tyre/tyre.component';
import {CartTableComponent} from './cart/cart-table/cart-table.component';
import {AddCustomArticleComponent} from './cart/add-custom-article/add-custom-article.component';
import {OfferComponent} from './cart/offer/offer.component';
import {LogoComponent} from './admin/company/settings/logo/logo.component';
import {OrderPrintComponent} from './admin/company/order-history/order-print/order-print.component';
import {MaintenanceDashboardComponent} from './maintenance/dashboard/maintenance-dashboard.component';
import {MaintenanceInfoDashboardComponent} from './maintenance/information/dashboard/maintenance-info-dashboard.component';
import {MaintenanceManufacturerInfoComponent} from './maintenance/information/maintenance-manufacturer-info/maintenance-manufacturer-info.component';
import {CalculationComponent} from './maintenance/calculation/calculation.component';
import {MaintenanceServiceInfoComponent} from './maintenance/information/maintenance-service-info/maintenance-service-info.component';
import {MaintenanceServiceSpecsComponent} from './maintenance/information/maintenance-service-specs/maintenance-service-specs.component';
import {CarDetailsEquipmentComponent} from './shared/components/car-details-equipment/car-details-equipment.component';
import {MaintenanceDataComponent} from './maintenance/maintenance-data/maintenance-data.component';
import {MaintenanceCarWarningsComponent} from './maintenance/information/maintenance-car-warnings/maintenance-car-warnings.component';
import {FirmUserComponent} from './admin/club/companymanagement/firm-user/firm-user.component';
import {EditFirmUserComponent} from './admin/club/companymanagement/firm-user/edit-firm-user/edit-firm-user.component';
import {FirmSupplierClubComponent} from './admin/club/companymanagement/firm-supplier/firm-supplier.component';
import {EditFirmSupplierComponent} from './admin/club/companymanagement/firm-supplier/edit-firm-supplier/edit-firm-supplier.component';
import {CreateCompanyComponent} from './admin/club/companymanagement/create-company/create-company.component';
import {ClubSettingComponent} from './admin/club/club-setting/club-setting.component';
import {AdvancedSettingsComponent} from './admin/company/settings/advanced-settings/advanced-settings.component';
import {GlobalSupplierComponent} from './admin/global/global-mapping-import/global-supplier/global-supplier.component';
import {ImprintComponent} from './auth/imprint/imprint.component';
import {DisclaimerComponent} from './auth/disclaimer/disclaimer.component';
import {OrderHistoryComponent} from './admin/company/order-history/order-history.component';
import {OrderDataComponent} from './admin/company/order-history/order-data/order-data.component';
import {MaintenanceWizardComponent} from './maintenance/maintenance-data/maintenance-wizard/maintenance-wizard.component';
import {HintsComponent} from './maintenance/hints/hints.component';
import {GlobalOrderhistoryComponent} from './admin/global/global-orderhistory/global-orderhistory.component';
import {GlobalVinlogComponent} from './admin/global/global-vinlog/global-vinlog.component';
import {GlobalCommunicationsListComponent} from './admin/global/global-communications-list/global-communications-list.component';
import {GlobalClubComponent} from './admin/global/global-club/global-club.component';
import {GlobalOrderDataComponent} from './admin/global/global-orderhistory/global-order-data/global-order-data.component';
import {GlobalDashboardComponent} from './admin/global/global-dashboard/global-dashboard.component';
import {MaintenanceChangeIntervalComponent} from './maintenance/information/maintenance-change-interval/maintenance-change-interval.component';
import {TemplateComponent} from './admin/club/companymanagement/template/template.component';
import {CreateTemplateComponent} from './admin/club/companymanagement/template/create-template/create-template.component';
import {LogComponent} from './admin/club/companymanagement/log/log.component';
import {GraphicalPartSearchComponent} from './graphical-part-search/graphical-part-search.component';
import {TyreInformationComponent} from './tyre/information/tyre-information/tyre-information.component';
import {StatisticsDashboardComponent} from './admin/global/statistics-dashboard/statistics-dashboard.component';
import {StatisticsClubComponent} from './admin/global/statistics-club/statistics-club.component';
import {TecdocConfigComponent} from './admin/club/club-setting/tecdoc-config/tecdoc-config.component';
import {LanguageConfigComponent} from './admin/club/club-setting/language-config/language-config.component';
import {GlobalGriphistoryComponent} from './admin/global/global-griphistory/global-griphistory.component';
import {GripOrderDetailsComponent} from './admin/global/global-griphistory/grip-order-details/grip-order-details.component';
import {EmailComponent} from './admin/profile/email/email.component';
import {GlobalMappingImportComponent} from './admin/global/global-mapping-import/global-mapping-import.component';
import {GlobalCustomidComponent} from './admin/global/global-mapping-import/global-customId/global-customid.component';
import {GlobalAdcTypeMappingComponent} from './admin/global/global-mapping-import/global-adcTypeMapping/global-adcTypeMapping.component';
import {PageMaintenanceComponent} from './shared/components/page-maintenance/page-maintenance.component';
import {GlobalVinlogDataComponent} from './admin/global/global-vinlog/global-vinlog-row/global-vinlog-data/global-vinlog-data.component';
import {AssortmentComponent} from './admin/assortment/assortment.component';
import {AssortmentViewComponent} from './admin/assortment/assortment-view/assortment-view.component';
import {AssortmentArticleComponent} from './admin/assortment/assortment-article/assortment-article.component';
import {AssortmentSupplierComponent} from './admin/assortment/assortment-supplier/assortment-supplier.component';
import {AssortmentSpecificSupplierComponent} from "./admin/assortment/assortment-specific-supplier/assortment-specific-supplier.component";
import {UserNotifcationsComponent} from './admin/profile/user-notifcations/user-notifcations.component';
import {ClubNotificationsComponent} from './admin/club/club-notifications/club-notifications.component';
import {GlobalNotificationsComponent} from './admin/global/global-notifications/global-notifications.component';
import {AddCustomCommentComponent} from './cart/add-custom-comment/add-custom-comment.component';
import {ExternalToolsComponent} from './admin/club/external-tools/external-tools.component';
import {GlobalCountryConfigComponent} from './admin/global/global-country-config/global-country-config.component';
import {GlobalEditCountryComponent} from './admin/global/global-country-config/global-edit-country/global-edit-country.component';
import {GlobalCommunicationsListDataComponent} from './admin/global/global-communications-list/global-communications-list-row/global-communications-list-data/global-communications-list-data.component';
import {DynamicSettingWrapperComponent} from './admin/club/club-setting/dynamic-setting-wrapper/dynamic-setting-wrapper.component';
import {EditExternalToolComponent} from './admin/company/external-firm-tools/edit-external-tool/edit-external-tool.component';
import {ExternalFirmToolsComponent} from './admin/company/external-firm-tools/external-firm-tools.component';
import {CountryConfigComponent} from "./admin/club/club-setting/country-config/country-config.component";
import {ArticleSelectionComponent} from './cart/article-selection/article-selection.component';
import {AssortmentSpecificArticleComponent} from "./admin/assortment/assortment-specific-article/assortment-specific-article.component";
import {GlobalOeSupplierComponent} from "./admin/global/global-mapping-import/global-oe-supplier/global-oe-supplier.component";
import {SsoErrorComponent} from "./sso-error/sso-error.component";
import {RawToolsComponent} from "./cart/raw-tools/raw-tools.component";
import {SupplierBlacklistComponent} from "./admin/company/settings/supplier-blacklist/supplier-blacklist.component";

export const appRoutes: Routes = [
    {
        path: '',
        data: {state: 'root'},
        component: RootComponent,
        children: [
            {
                path: '',
                data: {state: 'empty_path_first', animation: 'fadeAnimation'},
                component: HomeComponent
            },
            {
                path: 'page-maintenance',
                data: {state: 'page-maintenance', animation: 'fadeAnimation'},
                component: PageMaintenanceComponent
            },
            {
                path: 'cart',
                data: {state: 'cart', animation: 'fadeAnimation'},
                component: CartComponent,
                children: [
                    {
                        path: '',
                        component: CartTableComponent
                    },
                    {
                        path: 'comment',
                        data: {state: 'comment'},
                        component: AddCustomCommentComponent
                    },
                    {
                        path: 'raw-tools',
                        data: {state: 'raw-tools'},
                        component: RawToolsComponent
                    },
                    {
                        path: 'article',
                        data: {state: 'article'},
                        component: AddCustomArticleComponent
                    },
                    {
                        path: 'offer',
                        data: {state: 'offer'},
                        component: OfferComponent
                    },
                    {
                        path: 'order-history-print',
                        data: {state: 'order-history-print'},
                        component: OrderPrintComponent
                    },
                    {
                        path: 'service',
                        data: {state: 'service'},
                        component: MaintenanceServiceInfoComponent
                    },
                    {
                        path: 'specs',
                        data: {state: 'specs'},
                        component: MaintenanceServiceSpecsComponent
                    },
                    {
                        path: 'cardetails',
                        data: {state: 'cardetails'},
                        component: CarDetailsEquipmentComponent
                    },
                    {
                        path: 'info',
                        data: {state: 'info'},
                        component: MaintenanceManufacturerInfoComponent
                    },
                    {
                        path: 'calculation',
                        data: {state: 'calculation'},
                        component: CalculationComponent
                    },
                    {
                        path: 'change',
                        data: {state: 'change'},
                        component: MaintenanceChangeIntervalComponent
                    },
                    {
                        path: 'recall',
                        data: {state: 'recall'},
                        component: MaintenanceCarWarningsComponent
                    },
                    {
                        path: 'tyre',
                        data: {state: 'tyre'},
                        component: TyreInformationComponent
                    },
                    {
                        path: 'article-selection',
                        data: {state: 'article-selection'},
                        component: ArticleSelectionComponent
                    }
                ]
            },
            {
                path: 'checkout',
                data: {state: 'checkout', animation: 'fadeAnimation'},
                children: [
                    {
                        path: '',
                        data: {state: 'empty_profile'},
                        component: CheckoutComponent
                    },
                    {
                        path: 'success',
                        component: SuccessComponent
                    }
                ]
            },
            {
                path: 'articles',
                data: {state: 'articles', animation: 'fadeAnimation'},
                component: ArticlesComponent
            },
            {
                path: 'technical-data',
                data: {state: 'technical-data', animation: 'fadeAnimation'},
                component: TechnicalDataComponent
            },
            {
                path: 'work',
                data: {state: 'work', animation: 'fadeAnimation'},
                children: [
                    {
                        path: '',
                        component: WorkDataComponent
                    },
                    {
                        path: 'article',
                        data: {state: 'article'},
                        component: WorkDataComponent
                    }
                ]
            },
            {
                path: 'tyre',
                data: {state: 'tyre', animation: 'fadeAnimation'},
                component: TyreComponent
            },
            {
                path: 'maintenance',
                component: MaintenanceComponent,
                children: [
                    {
                        path: '',
                        data: {state: 'car', animation: 'fadeAnimation'},
                        component: MaintenanceDashboardComponent,

                    },
                    {
                        path: 'info',
                        data: {state: 'car', animation: 'fadeAnimation'},
                        children: [
                            {
                                path: '',
                                data: {state: 'car'},
                                component: MaintenanceInfoDashboardComponent
                            },
                            {
                                path: 'manufacturer',
                                data: {state: 'car'},
                                component: MaintenanceManufacturerInfoComponent
                            },
                            {
                                path: 'service',
                                data: {state: 'car'},
                                component: MaintenanceServiceInfoComponent
                            },
                            {
                                path: 'specifications',
                                data: {state: 'car'},
                                component: MaintenanceServiceSpecsComponent
                            },
                            {
                                path: 'warnings',
                                data: {state: 'car'},
                                component: MaintenanceCarWarningsComponent
                            },
                            {
                                path: 'change-interval',
                                data: {state: 'car'},
                                component: MaintenanceChangeIntervalComponent
                            }
                        ]
                    },
                    {
                        path: 'plan',
                        data: {state: 'plan', animation: 'fadeAnimation'},
                        component: MaintenanceDataComponent
                    },
                    {
                        path: 'suggestion',
                        data: {state: 'maintenance', animation: 'fadeAnimation'},
                        component: MaintenanceWizardComponent
                    },
                    {
                        path: 'hints',
                        children: [
                            {
                                path: '',
                                data: {state: 'hints', animation: 'fadeAnimation'},
                                component: HintsComponent
                            },
                            {
                                path: ':id',
                                data: {state: 'hints_id', animation: 'fadeAnimation'},
                                component: HintsComponent
                            }
                        ]
                    }
                ]
            },
            {
                path: 'graphical-part-search',
                data: {state: 'gps', animation: 'fadeAnimation'},
                component: GraphicalPartSearchComponent
            },
            {
                path: 'partner',
                data: {state: 'partner', animation: 'fadeAnimation'},
                component: PartnerComponent
            },
            {
                path: 'admin',
                data: {state: 'admin', animation: 'fadeAnimation'},
                component: AdministrationComponent,
                children: [
                    {
                        path: '',
                        data: {state: 'empty'},
                        component: AdminDashboardComponent
                    },
                    {
                        path: 'tools',
                        data: {state: 'tools'},
                        children: [
                            {
                                path: '',
                                data: {state: 'tools-dash'},
                                component: ExternalFirmToolsComponent
                            },
                            {
                                path: 'edit',
                                data: {state: 'edit-tools'},
                                component: EditExternalToolComponent
                            },
                            {
                                path: 'create',
                                data: {state: 'create-tools'},
                                component: EditExternalToolComponent
                            }
                        ]
                    },
                    {
                        path: 'template',
                        children: [
                            {
                                path: '',
                                data: {state: 'template'},
                                component: TemplateComponent
                            },
                            {
                                path: 'create',
                                children: [
                                    {
                                        path: ':templateType',
                                        children: [
                                            {
                                                path: '',
                                                data: {state: 'create-template'},
                                                component: CreateTemplateComponent
                                            },
                                            {
                                                path: ':clubId',
                                                data: {state: 'create-template-id'},
                                                component: CreateTemplateComponent
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'edit',
                                children: [
                                    {
                                        path: ':templateId',
                                        data: {state: 'edit-template'},
                                        component: CreateTemplateComponent
                                    }
                                ]
                            },
                            {
                                path: 'all',
                                data: {state: 'template'},
                                component: TemplateComponent
                            },
                            {
                                path: 'article',
                                data: {state: 'template'},
                                component: TemplateComponent
                            },
                            {
                                path: 'work',
                                data: {state: 'template'},
                                component: TemplateComponent
                            },
                            {
                                path: 'text',
                                data: {state: 'template'},
                                component: TemplateComponent
                            }
                        ]
                    },
                    {
                        path: 'create',
                        component: CreateCompanyComponent
                    },
                    {
                        path: 'firm',
                        data: {state: 'firm'},
                        component: CompanymanagementComponent,
                        children: [
                            {
                                path: 'log',
                                children: [
                                    {
                                        path: ':id',
                                        data: {state: 'log'},
                                        component: LogComponent
                                    }
                                ]
                            },
                            {
                                path: 'advanced',
                                children: [
                                    {
                                        path: ':id',
                                        component: AdvancedSettingsComponent
                                    }
                                ]
                            },
                            {
                                path: 'supplier-blacklist',
                                children: [
                                    {
                                        path: ':id',
                                        component: SupplierBlacklistComponent
                                    }
                                ]
                            },
                            {
                                path: 'assortment',
                                data: {state: 'firm_assortment'},
                                component: AssortmentComponent,
                                children: [
                                    {
                                        path: '',
                                        data: {state: 'firm_assortment'},
                                        component: AssortmentViewComponent
                                    },
                                    {
                                        path: 'article',
                                        data: {state: 'firm_assortment'},
                                        component: AssortmentArticleComponent
                                    },
                                    {
                                        path: 'article/:id',
                                        data: {state: 'firm_assortment'},
                                        component: AssortmentSpecificArticleComponent
                                    },
                                    {
                                        path: 'supplier',
                                        data: {state: 'firm_assortment'},
                                        component: AssortmentSupplierComponent
                                    },
                                    {
                                        path: 'supplier/:id',
                                        data: {state: 'firm_assortment'},
                                        component: AssortmentSpecificSupplierComponent
                                    }
                                ]
                            },
                            {
                                path: ':id',
                                children: [
                                    {
                                        path: '',
                                        component: FirmUserComponent,
                                    }
                                ]
                            },
                            {
                                path: 'logo',
                                children: [
                                    {
                                        path: ':id',
                                        component: LogoComponent
                                    }
                                ]
                            },
                            {
                                path: 'user',
                                children: [
                                    {
                                        path: ':userId',
                                        children: [
                                            {
                                                path: ':id',
                                                component: EditFirmUserComponent
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'suppliers',
                        component: CompanymanagementComponent,
                        children: [
                            {
                                path: ':id',
                                component: FirmSupplierClubComponent
                            },
                            {
                                path: 'supplier',
                                children: [
                                    {
                                        path: ':global-supplier',
                                        children: [
                                            {
                                                path: ':id',
                                                component: EditFirmSupplierComponent
                                            }
                                        ]
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        path: 'assortment',
                        data: {state: 'admin_assortment'},
                        component: AssortmentComponent,
                        children: [
                            {
                                path: '',
                                data: {state: 'admin_assortment'},
                                component: AssortmentViewComponent
                            },
                            {
                                path: 'article',
                                data: {state: 'admin_assortment'},
                                component: AssortmentArticleComponent
                            },
                            {
                                path: 'article/:id',
                                data: {state: 'admin_assortment'},
                                component: AssortmentSpecificArticleComponent
                            },
                            {
                                path: 'supplier',
                                data: {state: 'admin_assortment'},
                                component: AssortmentSupplierComponent
                            },
                            {
                                path: 'supplier/:id',
                                data: {state: 'admin_assortment'},
                                component: AssortmentSpecificSupplierComponent
                            }
                        ]
                    },
                    {
                        path: 'partner',
                        data: {state: 'admin_partner'},
                        children: [
                            {
                                path: '',
                                data: {state: 'admin_partner'},
                                component: PartnermanagementComponent
                            },
                            {
                                path: 'config',
                                data: {state: 'admin_partner_config'},
                                component: EditpartnerComponent
                            }
                        ]
                    },
                    {
                        path: 'notifications',
                        data: {state: 'admin_notifications'},
                        component: ClubNotificationsComponent
                    },
                    {
                        path: 'external-tools',
                        data: {state: 'external-tools'},
                        component: ExternalToolsComponent
                    },
                    {
                        path: 'config',
                        children: [
                            {
                                path: '',
                                data: {state: 'config'},
                                component: ClubSettingComponent
                            },
                            {
                                path: ':setting',
                                data: {state: ''},
                                component: DynamicSettingWrapperComponent
                            },
                            {
                                path: 'country',
                                data: {state: 'config-country'},
                                component: CountryConfigComponent
                            }
                        ]
                    },
                    {
                        path: ':id',
                        component: EditcompanyComponent
                    }
                ]
            },
            {
                path: 'global-admin',
                component: GlobalComponent,
                children: [
                    {
                        path: '',
                        data: {state: 'global-dashboard', animation: 'fadeAnimation'},
                        component: GlobalDashboardComponent
                    },
                    {
                        path: 'import',
                        children: [
                            {
                                path: '',
                                data: {state: 'global-mapping-import', animation: 'fadeAnimation'},
                                component: GlobalMappingImportComponent
                            },
                            {
                                path: 'supplier',
                                data: {state: 'global-supplier', animation: 'fadeAnimation'},
                                component: GlobalSupplierComponent
                            },
                            {
                                path: 'oesupplier',
                                data: {state: 'global-oe-supplier'},
                                component: GlobalOeSupplierComponent
                            },
                            {
                                path: 'customid',
                                data: {state: 'global-customid', animation: 'fadeAnimation'},
                                component: GlobalCustomidComponent
                            },
                            {
                                path: 'adcTypeMapping',
                                data: {state: 'global-adcTypeMapping'},
                                component: GlobalAdcTypeMappingComponent
                            }
                        ]
                    },
                    {
                        path: 'statistics',
                        children: [
                            {
                                path: '',
                                component: StatisticsDashboardComponent
                            },
                            {
                                path: ':id',
                                children: [
                                    {
                                        path: '',
                                        data: {state: 'global-statistic', animation: 'fadeAnimation'},
                                        component: StatisticsClubComponent
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        path: 'griphistory',
                        data: {state: 'global-griphistory', animation: 'fadeAnimation'},
                        component: GlobalGriphistoryComponent,
                        children: [
                            {
                                path: 'details',
                                data: {state: 'global-griphistory-details'},
                                component: GripOrderDetailsComponent
                            }
                        ]
                    },
                    {
                        path: 'orderhistory',
                        children: [
                            {
                                path: '',
                                data: {state: 'global-orderhistory', animation: 'fadeAnimation'},
                                component: GlobalOrderhistoryComponent
                            },
                            {
                                path: ':id',
                                children: [
                                    {
                                        path: '',
                                        data: {state: 'global-order-details', animation: 'fadeAnimation'},
                                        component: GlobalOrderDataComponent
                                    },
                                    {
                                        path: 'offer',
                                        data: {state: 'offer', animation: 'fadeAnimation'},
                                        component: OfferComponent
                                    },
                                    {
                                        path: 'order-print',
                                        data: {state: 'order-print', animation: 'fadeAnimation'},
                                        component: OrderPrintComponent
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'country',
                        data: {state: 'global-country', animation: 'fadeAnimation'},
                        children: [
                            {
                                path: '',
                                data: {state: 'global-country-config'},
                                component: GlobalCountryConfigComponent
                            },
                            {
                                path: 'edit',
                                data: {state: 'global-country-edit'},
                                component: GlobalEditCountryComponent
                            }
                        ]
                    },
                    {
                        path: 'notifications',
                        data: {state: 'global-notifications', animation: 'fadeAnimation'},
                        component: GlobalNotificationsComponent
                    },
                    {
                        path: 'vinlog',
                        children: [
                            {
                                path: '',
                                data: {state: 'global-orderhistory', animation: 'fadeAnimation'},
                                component: GlobalVinlogComponent,
                            },
                            {
                                path: ':id',
                                children: [
                                    {
                                        path: '',
                                        data: {state: 'global-vinlog-data', animation: 'fadeAnimation'},
                                        component: GlobalVinlogDataComponent,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'communicationslist',
                        children: [
                            {
                                path:   '',
                                data: {state: 'global-orderhistory', animation: 'fadeAnimation'},
                                component: GlobalCommunicationsListComponent,
                            },
                            {
                                path: ':id',
                                children: [
                                    {
                                        path: '',
                                        data: {state: 'global-communicationslist-data', animation: 'fadeAnimation'},
                                        component: GlobalCommunicationsListDataComponent,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'club',
                        children: [
                            {
                                path: '',
                                data: {state: 'global-club', animation: 'fadeAnimation'},
                                component: GlobalClubComponent
                            },
                            {
                                path: ':id',
                                children: [
                                    {
                                        path: '',
                                        data: {state: 'global-order-details', animation: 'fadeAnimation'},
                                        component: GlobalOrderDataComponent
                                    },
                                    {
                                        path: 'offer',
                                        data: {state: 'offer', animation: 'fadeAnimation'},
                                        component: OfferComponent
                                    },
                                    {
                                        path: 'order-print',
                                        data: {state: 'order-print', animation: 'fadeAnimation'},
                                        component: OrderPrintComponent
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: 'company-admin',
                data: {state: 'company-admin', animation: 'fadeAnimation'},
                component: CompanyAdminComponent,
                children: [
                    {
                        path: '',
                        data: {state: 'empty_company'},
                        component: FirmDashboardComponent
                    },
                    {
                        path: 'firm',
                        data: {state: 'firm_company'},
                        component: FirmdataComponent
                    },
                    {
                        path: 'user',
                        data: {state: 'company_admin_user'},
                        children: [
                            {
                                path: '',
                                data: {state: 'empty_user'},
                                component: UsermanagementComponent
                            },
                            {
                                path: ':id',
                                data: {state: 'id_company'},
                                component: EdituserComponent
                            }
                        ]
                    },
                    {
                        path: 'suppliers',
                        children: [
                            {
                                path: '',
                                data: {state: 'empty_supplier'},
                                component: SuppliersComponent,
                            },
                            {
                                path: ':id',
                                data: {state: 'supplier_id'},
                                component: EditsuppliersComponent
                            }
                        ]
                    },
                    {
                        path: 'tools',
                        children: [
                            {
                                path: '',
                                data: {state: 'tools'},
                                component: ExternalFirmToolsComponent,
                            },
                            {
                                path: 'edit',
                                data: {state: 'tools-edit'},
                                component: EditExternalToolComponent,
                            },
                            {
                                path: 'create',
                                data: {state: 'tools-create'},
                                component: EditExternalToolComponent,
                            }
                        ]
                    },
                    {
                        path: 'settings',
                        children: [
                            {
                                path: '',
                                data: {state: 'empty_settings'},
                                component: SettingsComponent
                            },
                            {
                                path: 'wage-rate',
                                component: WageRateComponent
                            },
                            {
                                path: 'logo',
                                component: LogoComponent
                            },
                            {
                                path: 'advanced',
                                component: AdvancedSettingsComponent
                            },
                            {
                                path: 'supplier-blacklist',
                                component: SupplierBlacklistComponent
                            }
                        ]
                    },
                    {
                        path: 'orderhistory',
                        children: [
                            {
                                path: '',
                                data: {state: 'empty-order-history'},
                                component: OrderHistoryComponent
                            },
                            {
                                path: ':last',
                                data: {state: 'last'},
                                children: [
                                    {
                                        path: '',
                                        data: {state: 'last'},
                                        component: OrderHistoryComponent
                                    },
                                    {
                                        path: ':id',
                                        data: {state: 'last_id'},
                                        children: [
                                            {
                                                path: '',
                                                data: {state: 'last_ide'},
                                                component: OrderDataComponent
                                            },
                                            {
                                                path: 'offer',
                                                data: {state: 'offer'},
                                                component: OfferComponent
                                            },
                                            {
                                                path: 'order-print',
                                                data: {state: 'order-print'},
                                                component: OrderPrintComponent
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'profile',
                        data: {state: 'profile', animation: 'fadeAnimation'},
                        component: UserdataComponent
                    },
                    {
                        path: 'email',
                        data: {state: 'email', animation: 'fadeAnimation'},
                        component: EmailComponent
                    },
                    {
                        path: 'notifications',
                        data: {state: 'notifications', animation: 'fadeAnimation'},
                        component: UserNotifcationsComponent
                    },
                    {
                        path: 'assortment',
                        data: {state: 'admin_assortment', animation: 'fadeAnimation'},
                        component: AssortmentComponent,
                        children: [
                            {
                                path: '',
                                data: {state: 'admin_assortment'},
                                component: AssortmentViewComponent
                            },
                            {
                                path: 'article',
                                data: {state: 'admin_assortment'},
                                component: AssortmentArticleComponent
                            },
                            {
                                path: 'article/:id',
                                data: {state: 'admin_assortment'},
                                component: AssortmentSpecificArticleComponent
                            },
                            {
                                path: 'supplier',
                                data: {state: 'admin_assortment'},
                                component: AssortmentSupplierComponent
                            },
                            {
                                path: 'supplier/:id',
                                data: {state: 'admin_assortment'},
                                component: AssortmentSpecificSupplierComponent
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        path: 'login',
        data: {state: 'login'},
        component: LoginComponent
    },
    {
        path: 'reset-pw',
        data: {state: 'login'},
        component: ResetPwComponent
    },
    {
        path: 'imprint',
        data: {state: 'imprint'},
        component: ImprintComponent
    },
    {
        path: 'disclaimer',
        data: {state: 'disclaimer'},
        component: DisclaimerComponent
    },
    {
        path: 'n4sso/error',
        data: {state: 'sso-error'},
        component: SsoErrorComponent
    }
];
