<div class="club-setting-language">

    <div class="club-setting-title">
      <h1>
        <strong>
          {{'CLUB_ADMIN.LANGUAGE.TITLE' | translate}}
        </strong>
      </h1>
    </div>

    <!-- Possible Languages -->
    <div class="row" *ngIf="languageConfig">
      <div class="col-12 col-sm-6" >
        <div class="row">
          <div class="col headerType">
            <h1 class="selection title">{{'CLUB_ADMIN.LANGUAGE.POSSIBLE_LANGUAGES' | translate}}</h1>
          </div>
        </div>
        <div class="row rowhead" *ngFor="let _language of languageConfig?.selection">
          <div class="col">
            <input type="checkbox" [checked]="isCheckedLanguage(_language.first)"
                   (change)="togglePossible(_language.first, $event)"/>
            <label class="checkboxLabel">{{('GENERAL.LANGUAGES.'+ (_language.first | uppercase) | translate)}}</label>
          </div>
        </div>
      </div>

      <!-- Default Language -->
      <div class="col-12 col-sm-6" *ngIf="languageConfig">
        <div class="row">
          <div class="col headerType">
            <h1 class="selection title">{{'CLUB_ADMIN.LANGUAGE.DEFAULT_LANGUAGE' | translate}}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label class="dropdown-label">{{'CLUB_ADMIN.LANGUAGE.DEFAULT_LANGUAGE' | translate}}</label>
            <div class="custom-dropdown">
              <select [(ngModel)]="defaultLanguage">
                <option *ngFor="let _language of languageConfig.selected" [ngValue]="_language.first"
                        [selected]="_language.second">{{('GENERAL.LANGUAGES.'+ (_language.first | uppercase) | translate)}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>


    <button class="base-btn success left" (click)="configService.saveClubConfig()"
            *ngIf="configService.hasUnsavedSettings(configService.selectedSettingName)">
      <i class="fa fa-check"></i>
      {{'CLUB_ADMIN.PARTNER_MANAGEMENT.SAVE' | translate}}
    </button>

  </div>
