import { Component, OnInit } from '@angular/core';
import {DatePipe} from "@angular/common";
import {CarService} from "../../../shared/services/car/car.service";
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {PdfService} from "../../../shared/services/pdf.service";
import {MaintenanceService} from '../../services/maintenance.service';
import {HelperService} from '../../../shared/services/helper.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {IServiceInfo} from '../../../shared/interfaces/service-info';

@Component({
  selector: 'app-maintenance-car-warnings',
  templateUrl: './maintenance-car-warnings.component.html',
  styleUrls: ['./maintenance-car-warnings.component.scss']
})
export class MaintenanceCarWarningsComponent implements OnInit {

  constructor(public carService: CarService,
              private pdfService: PdfService,
              private datePipe: DatePipe,
              public maintenanceService: MaintenanceService,
              public helperService: HelperService,
              public location: Location,
              private router: Router) {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  ngOnInit() {
      if (this.location.path().indexOf('/maintenance') > -1) {
          this.maintenanceService.lastState = '/maintenance/info/warnings';
      } else if (this.location.path().indexOf('/cart') > -1 && !this.maintenanceService.selectedCarInfos) {
          this.router.navigate(['/cart']);
      }
  }


    public generatePdf() {

        let details = this.carService.selectedCar,
            bodyElements: any[] = [],
            docDefinition: any = this.pdfService.buildDocDefinition(false, this.carService.selectedCar);

        for (let detail of details.serviceInfos) {
            docDefinition.content.push(
                {
                    text: detail.headline,
                    margin: [0,10,0,0],
                    fontSize: 12
                }
            );
            docDefinition.content.push(
                {
                    text: detail.validFrom + ' - ' + detail.validTo,
                    margin: [0,0,0,10],
                    fontSize: 8
                }
            );
            bodyElements.push([
                { text: detail.text , alignment: 'left' }
            ]);
            docDefinition.content.push(
                {
                    style: 'cartTable',
                    table: {
                        widths: [ '*'],
                        body: bodyElements
                    }
                }
            );
            bodyElements = [];
        }


        return docDefinition;
    }

    public createPdf() {
        this.maintenanceService.showPdf = true;
        this.maintenanceService.pdfLoading = true;
        this.getPdf(this.generatePdf()).then(
            (pdf: any) => {
                this.maintenanceService.pdfLoading = false;
                this.maintenanceService.pdf = pdf;
            },
            (error: any) => {
                console.warn(error);
                this.maintenanceService.pdfLoading = false;
            }
        );
    }

    public downloadPdf() {
        return pdfMake.createPdf(this.generatePdf()).download();
    }

    public printPdf() {
        return pdfMake.createPdf(this.generatePdf()).print();
    }

    public getPdf(docDefinition: any) {
        return <any>new Promise(function (resolve: any, reject: any) {
            pdfMake.createPdf(docDefinition, 'filename').getBase64(function (pdfBase64: any) {
                var raw = atob(pdfBase64);
                var uint8Array = new Uint8Array(raw.length);
                for (var i = 0; i < raw.length; i++) {
                    uint8Array[i] = raw.charCodeAt(i);
                }
                resolve(uint8Array);
            });
        });
    }
}
