import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpEventType, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HelperService} from '../../../../shared/services/helper.service';

@Component({
  selector: 'app-global-customid',
  templateUrl: './global-customid.component.html',
  styleUrls: ['./global-customid.component.scss']
})
export class GlobalCustomidComponent implements OnInit {

  public oeImportFile: any;
  public articleImportFile: any;
  public itemMpIdsFile: any;
  public positionGroupsFile: any;

  private oeUploadingProgressing: any;
  private oeUploadProgress: any;
  private articleUploadingProgressing: any;
  private articleUploadProgress: any;
  private workUploadingProgressing: any;
  private workUploadProgress: any;

  constructor(private http: HttpClient,
              public helperService: HelperService) { }

  ngOnInit() {
  }

  public handleOeImportFileInput(files: any) {
    this.oeImportFile = files.item(0);
  }

  public handleArticleImportFileInput(files: any) {
    this.articleImportFile = files.item(0);
  }

  public handleWorkImportItemMpIdsFileInput(files: any) {
    this.itemMpIdsFile = files.item(0);
  }

  public handleWorkImportPositionGroupsFileInput(files: any) {
    this.positionGroupsFile = files.item(0);
  }

  public uploadOe() {
    this.createOePostFile().subscribe(
        event => this.handleProgress(event, this.oeUploadingProgressing, this.oeUploadProgress),
        error => {
          this.oeUploadingProgressing = false;
          this.helperService.showNotification(error.statusText, 'eror', 'OE Import');
        },
        () => {
          this.oeImportFile = undefined;
          this.oeUploadingProgressing = false;
          this.helperService.showNotification('Upload erfolgreich!', 'success', 'OE Import');
        }
    )
  }

  private createOePostFile(): Observable<any> {
    const oeEndpoint = '/api/grips/mapping/oearticle';
    const oeFormData: FormData = new FormData();
    oeFormData.append('file', this.oeImportFile, this.oeImportFile.name);
    const oeReq = new HttpRequest('POST', oeEndpoint, oeFormData, {
      reportProgress: true // for progress data
    });

    return this.http.request(oeReq);
  }

  public uploadArticle() {
    this.createArticlePostFile().subscribe(
        event => this.handleProgress(event, this.articleUploadingProgressing, this.articleUploadProgress),
        error => {
          this.articleUploadingProgressing = false;
          this.helperService.showNotification(error.statusText, 'error', 'Article Import');
        },
        () => {
          this.articleImportFile = undefined;
          this.articleUploadingProgressing = false;
          this.helperService.showNotification('Upload erfolgreich!', 'success', 'Article Import');
        }
    )
  }

  private createArticlePostFile(): Observable<any> {
    const articleEndpoint = '/api/grips/mapping/article';
    const articleFormData: FormData = new FormData();
    articleFormData.append('file', this.articleImportFile, this.articleImportFile.name);
    const articleReq = new HttpRequest('POST', articleEndpoint, articleFormData, {
      reportProgress: true // for progress data
    });

    return this.http.request(articleReq);
  }

  public uploadWork() {
    this.createWorkPostFile().subscribe(
        event => this.handleProgress(event, this.workUploadingProgressing, this.workUploadProgress),
        error => {
          this.workUploadingProgressing = false;
          this.helperService.showNotification(error.statusText, 'error', 'Wort Import');
        },
        () => {
          this.itemMpIdsFile = undefined;
          this.positionGroupsFile = undefined;
          this.workUploadingProgressing = false;
          this.helperService.showNotification('Upload erfolgreich!', 'success', 'Work Import');
        }
    )
  }

  private createWorkPostFile(): Observable<any> {
    const workEndpoint = '/api/grips/mapping/work';
    const workFormData: FormData = new FormData();
    workFormData.append('itemMpIds', this.itemMpIdsFile, this.itemMpIdsFile.name);
    workFormData.append('positionGroups', this.positionGroupsFile, this.positionGroupsFile.name);
    const workReq = new HttpRequest('POST', workEndpoint, workFormData, {
      reportProgress: true // for progress data
    });

    return this.http.request(workReq);
  }

  private handleProgress(event: any, uploadingProgressing: any, uploadProgress: any) {

    uploadingProgressing = false;

    if (event.type === HttpEventType.DownloadProgress) {
      uploadingProgressing = true;
      uploadProgress = Math.round(100 * event.loaded / event.total)
    }

    if (event.type === HttpEventType.UploadProgress) {
      uploadingProgressing = true;
      uploadProgress = Math.round(100 * event.loaded / event.total)
    }

    if (event.type === HttpEventType.Response) {
      uploadingProgressing = false;
    }
  }

}
