<div class="tabs" [ngClass]="{'browserIsIE': helperService.browserIsIE()}">
    <button *ngIf="showScrollButton()" id="scrollButton" class="tabSetScrollButton left" (click)="getNextTab(false)">
        <i class="fa fa-angle-left"></i>
    </button>
    <div class="viewport" [ngClass]="{'align-left': !showScrollButton()}" #viewport>
        <ul class="tabList" id="tabList" #tabList>
            <li class="listItem"
                [ngClass]="{'selected': selectedTab?.mountPositionId === element?.mountPositionId, 'finished': element?.partSets[0]?.parts[0]?.position !== null}"
                (click)="setTab(element, tabList.childNodes[i], element.partSets[0].parts[0].partAmounts[0]); checkSortBtn()"
                *ngFor="let element of service?.articleList; index as i">
                <span>
                    <span *ngIf="!(element?.partSets[0]?.parts[0]?.partAmounts[0]?.quantity?.length > 0) && element.partSets[0].parts[0].partAmounts[0].amount > 1">
                        {{element.partSets[0].parts[0].partAmounts[0].amount + 'x '}}
                    </span>
                    {{element.mountPositionText}}
                </span>
            </li>
        </ul>
    </div>
    <button *ngIf="showScrollButton()" class="tabSetScrollButton right" (click)="getNextTab(true)">
        <i class="fa fa-angle-right"></i>
    </button>
</div>
<div class="tabContent">
    <div class="innerTabContent">
        <span><b>{{'ARTICLE_SELECTION.SELECTED_ARTICLE' | translate}}</b></span>

        <div class="row">
            <div class="col-5">
                <ng-container *ngIf="service.selectedTab?.partSets[0]?.parts[0]?.position === null">
                    <span>{{'ARTICLE_SELECTION.NO_SELECTED_ARTICLE' | translate}}</span>
                    <ng-container *ngIf="service.selectedTab?.partSets[0]?.parts[0]?.partAmounts?.length === 1">
                        <br/>
                        <b> {{service.selectedTab?.partSets[0]?.parts[0]?.partAmounts[0]?.amount}}
                            <span *ngIf="service.selectedTab?.partSets[0]?.parts[0]?.partAmounts[0]?.quantity?.length > 0">
                            {{service.selectedTab?.partSets[0]?.parts[0]?.partAmounts[0]?.quantity}}
                        </span>
                            <span *ngIf="!(service.selectedTab?.partSets[0]?.parts[0]?.partAmounts[0]?.quantity?.length > 0)">
                            {{'CART.PIECE' | translate}}
                        </span>
                            {{'ARTICLE_SELECTION.NEEDED_SMALL'| translate}}
                        </b>
                    </ng-container>
                    <ng-container *ngIf="service.selectedTab?.partSets[0]?.parts[0]?.partAmounts?.length > 1">
                        <app-dropdown [items]="service.selectedTab?.partSets[0]?.parts[0]?.partAmounts"
                                      [preSelectedItem]="service.selectedAmount"
                                      (onItemSelect)="service.selectedAmount = $event"
                                      [isAmountDropdown]="true">
                        </app-dropdown>
                    </ng-container>
                </ng-container>

                <div class="row" *ngIf="service.showRetryButton">
                    <div class="col">
                        <button class="base-btn" (click)="searchArticle(requestedQuantity)">
                            <i class="fa fa-refresh mr-2"></i>
                            <span>{{'MAINTENANCE.RETRY_SEARCH' | translate}}</span>
                        </button>
                    </div>
                </div>

                <ng-container *ngIf="service.selectedTab?.partSets[0]?.parts[0]?.position !== null">
                    <div class="row">
                        <div class="col-5">
                            <span>{{'CART.MANUFACTURER' | translate}}</span>
                        </div>
                        <div class="col-7">
                            <span>{{getSelectedPosition().brandName}}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                            <span>{{'CART.DESCRIPTION' | translate}}</span>
                        </div>
                        <div class="col-7">
                            <span>{{getSelectedPosition().description}}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-5">
                            <span>{{'CART.ARTICLE_NUMBER' | translate}}</span>
                        </div>
                        <div class="col-7">
                            <span>{{getSelectedPosition().articleId}}</span>
                        </div>
                    </div>
                </ng-container>

            </div>
            <div class="col-7">
                <div class="row">
                    <div class="col-4">
                        <ng-container *ngIf="service.selectedTab?.partSets[0]?.parts[0]?.position !== null">
                            <div class="row">
                                <div class="col-6">
                                    <span>{{'TEMPLATE.TABLE.QUANTITY' | translate}}</span>
                                </div>
                                <div class="col-6">
                                    <span>{{getSelectedPosition().quantity}}</span>
                                    <span> {{'GENERAL.UNITS.' + getSelectedPosition().quantityUnit | translate}}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <span>{{ userService.sale? ('CART.SINGLE_PRICE_SALE' | translate ): ('CART.SINGLE_PRICE' | translate) }}</span>
                                </div>
                                <div class="col-6">
                                    <span *ngIf="userService.sale">{{getSelectedPosition().sellInNetPerPieceFormatted}}</span>
                                    <span *ngIf="!userService.sale">{{getSelectedPosition().sellOutNetPerPieceFormatted}}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <span>{{'ARTICLE_SELECTION.NEEDED' | translate}}</span>
                                </div>
                                <div class="col-6">
                                    <span>
                                        {{service.selectedTab?.partSets[0]?.parts[0].partAmounts[0].amount}}
                                        <span *ngIf="service.selectedTab?.partSets[0]?.parts[0]?.partAmounts[0]?.quantity?.length > 0">
                                            {{service.selectedTab?.partSets[0]?.parts[0]?.partAmounts[0]?.quantity}}
                                        </span>
                                        <span *ngIf="!(service.selectedTab?.partSets[0]?.parts[0]?.partAmounts[0]?.quantity?.length > 0)">
                                            {{'CART.PIECE' | translate}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-8">
                        <div class="row">
                            <div class="col">
                                <button *ngIf="((!getSelectedPosition() && !cartService?.cartCount?.calculationData) && !cartService.isOrder() || (getSelectedPosition()?.type !== 'PLACEHOLDER' && !cartService?.cartCount?.calculationData)) && cartService?.isNewestCart() && !cartService.isOrder()"
                                        class="base-btn placeholder-button" (click)="addPlaceholderToCart()">
                                    <span *ngIf="!placeholderLoading">{{'ARTICLE_SELECTION.PLACEHOLDER_BUTTON' | translate}}</span>
                                    <i class="fa fa-spinner"
                                       [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"
                                       *ngIf="placeholderLoading"></i>
                                </button>
                                <!-- has calculation in cart -->
                                <span class="calculation-price" *ngIf="((!getSelectedPosition() && cartService?.cartCount?.calculationData)
                                || (getSelectedPosition()?.type !== 'PLACEHOLDER' && cartService?.cartCount?.calculationData))
                                && showPlaceholderCalculation(service?.selectedTab)">
                                    <span class="mr-2">{{ userService.sale? ('CART.SINGLE_PRICE_SALE' | translate ): ('CART.SINGLE_PRICE' | translate) }}</span>
                                    <b>{{service?.calculationCostHolder.get(selectedTab?.mountPositionId)?.formattedPrice}}</b>
                                </span>
                                <button *ngIf="((!getSelectedPosition() && cartService?.cartCount?.calculationData && !cartService.isOrder()) || (getSelectedPosition()?.type !== 'PLACEHOLDER'
                                        && cartService?.cartCount?.calculationData)) && cartService?.isNewestCart() && !cartService.isOrder()"
                                        [ngbPopover]="'CART.CALCULATION_TOOLTIP' | translate" container="body"
                                        triggers="mouseenter:mouseleave"
                                        [disablePopover]="placeholderLoading || loadingCalculationData || showPlaceholderCalculation(service?.selectedTab)"
                                        [popoverClass]="'calculation-tooltip'"
                                        class="base-btn placeholder-button" (click)="addPlaceholderToCart()"
                                        [ngClass]="{'disabled': placeholderLoading || loadingCalculationData || !showPlaceholderCalculation(service?.selectedTab)}">
                                    <span>{{'CART.CALCULATION_PLACEHOLDER' | translate}}</span>
                                    <i class="fa fa-spinner"
                                       [ngClass]="{'fa-pulse': !helperService.browserIsIE() && !helperService.browserIsEdge()}"
                                       *ngIf="placeholderLoading || loadingCalculationData"></i>
                                </button>
                            </div>
                            <div class="col d-flex justify-content-center align-items-start" *ngIf="userService.enableInfiniteScroll">
                                <div triggers="mouseenter:mouseleave"
                                     class="toolTipDiv"
                                     placement="top-right"
                                     [ngbPopover]="popContent" container="body">
                                    <button [disabled]="(countSmallerThreshold || !scrollEnd) && (!countSmallerThreshold)"
                                            (click)="articlesService.resortEmitterNewPageNeeded.emit(!scrollEnd)"
                                            class="base-btn success resort-btn mt-0">
                                        {{ 'SPAREPARTS_VIEW.SEARCH_RESULTS.RESORT' | translate }}
                                    </button>
                                    <ng-template #popContent>
                                        <div>
                                            {{ countSmallerThreshold ? ('ARTICLE_SELECTION.INFO_THRESHOLD' | translate) : ('ARTICLE_SELECTION.INFO_THRESHOLD_DISABLED' | translate) }}
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                             *ngIf="service.selectedTab?.partSets[0]?.parts[0]?.position !== null && getSelectedPosition().supplier">
                            <div class="col">
                                <img class="supplier_logos" [supplier]="getSelectedPosition().supplier"
                                     [logo-color]="true"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pseudo-article" *ngIf="articlesService.isInPseudoArticleView$ | async">
        <div class="row wrapper-row">
            <div class="col-4">
                <img class="image" src="{{currentPseudoarticle?.previewImageUrl}}">
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col">
                        <span class="description">{{currentPseudoarticle?.description}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span class="brand">{{currentPseudoarticle?.brandName}} {{(articlesService.currentPseudoArticle$ | async)?.ipc}}</span>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <button class="base-btn"
                                (click)="showPseudoArticleDetails(currentPseudoarticle)">{{'ARTICLE_SELECTION.SHOW_DETAILS' | translate}}</button>
                    </div>
                </div>
                <div class="row spacer">
                    <div class="col-12">
                        <button class="base-btn"
                                (click)="goBackToSearch(); articlesService.isInPseudoArticleView$.next(false); articlesService.currentPseudoArticle$.next(undefined)">{{'ARTICLE_SELECTION.BACK_TO_SEARCH' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
