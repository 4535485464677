<div class="supplier-blacklist">
    <div class="heading blue">
    <span class="clickable" *ngIf="!location?.path()?.includes('/admin/firm/supplier-blacklist/')"
          routerLink="/company-admin">
        <i class="fa fa-th" aria-hidden="true"></i>
        {{ 'ADMIN_PANEL.OVERVIEW' | translate }}
    </span>
        <span class="clickable" *ngIf="location?.path()?.includes('/admin/firm/supplier-blacklist/')"
              routerLink="/admin">
        <i class="fa fa-th" aria-hidden="true"></i>
            {{ 'ADMIN_PANEL.OVERVIEW' | translate }}
    </span>
        <span *ngIf="!location?.path()?.includes('/admin/firm/supplier-blacklist/')" class="clickable"
              routerLink="/company-admin/settings">
        <i class="fa fa-caret-right"></i>
            {{ 'USER_PROFILE.SETTINGS' | translate }}
    </span>
        <span>
        <i class="fa fa-caret-right"></i>
            {{ 'USER_PROFILE.SUPPLIER_BLACKLIST' | translate }}
    </span>
    </div>

    <div class="panel-content center-content white-background">
        <app-default-loading-page *ngIf="loading"></app-default-loading-page>
        <ng-container *ngIf="!loading">
            <div class="row">
                <div class="col headerType">
                    <h1>
                        {{ 'USER_PROFILE.SUPPLIER_BLACKLIST' | translate }}
                    </h1>
                </div>
            </div>
            <div class="restriction">
                <div class="mb-5 w-25">
                    <app-material-form-input [(text)]="search"
                                             (textChange)="(reduceList = reduceSuppliers($event))"
                                             [placeholder]="'HOME.SEARCH' | translate">
                    </app-material-form-input>
                </div>
                <div class="list-wrapper drag-list">
                    <div class="row drag-box" (click)="allChecked= !allChecked; changeAllSuppliers(allChecked)">
                        <div class="col cursor-default">
                            {{ 'HOME.SELECT_ALL' | translate }}
                        </div>
                        <div class="col-2 cursor-pointer" (click)="$event.stopPropagation()">
                            <app-checkbox
                                    [isChecked]="allChecked"
                                    (onChange)="changeAllSuppliers($event)">
                            </app-checkbox>
                        </div>
                    </div>
                    <div class="row drag-box cursor-default"
                         [ngClass]="{'border-bottom-0': l}"
                         *ngFor="let supp of reduceList; let l = last"
                         (click)="onRowClick(supp)">
                        <div class="col cursor-default">
                            {{ getSupplierNameById(supp) }}
                        </div>
                        <div class="col-2 cursor-pointer" (click)="$event.stopPropagation()">
                            <app-checkbox [isChecked]="supplierIsSelected(supp)"
                                          (onChange)="changeSupplier($event, supp)">
                            </app-checkbox>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col" *ngIf="companyAdminService.hasChangesBlacklist">
                        <button class="base-btn success" (click)="saveChanges()"
                                *ngIf="companyAdminService.hasChangesBlacklist">
                            <i class="fa fa-check"></i>
                            {{ 'PROMPTS.BUTTONS.SAVE_CHANGES' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
