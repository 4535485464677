import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import {HelperService} from '../../../../shared/services/helper.service';

@Component({
  selector: 'app-adcTypeMapping',
  templateUrl: './global-adcTypeMapping.component.html'
})
export class GlobalAdcTypeMappingComponent implements OnInit {
  public fileToUpload: any;
  public uploadingProgressing: boolean;
  public uploadProgress: number;
  public cleanArticleNo: boolean = true;
  public responseMSG: any;
  public skipLines: number = 1;

  constructor(private http: HttpClient,
              public helperService: HelperService) {

  }

  ngOnInit() {
  }

  public handleFileInput(files: any) {
      this.fileToUpload = files.item(0);
  }

  public handleSubmit(event:any, fileToUpload) {
      const fileUploadSub = this.postFile(fileToUpload).subscribe(
          event => this.handleProgress(event),
          error => {
              this.fileToUpload = null;
              this.uploadingProgressing = false;
              this.responseMSG = error.statusText;
          },
          () => {
              this.responseMSG = 'Upload erfolgreich!';
              this.fileToUpload = null;
              this.uploadingProgressing = false;
          });

}

  public postFile(fileToUpload: File): Observable<any> {
      const apiCreateEndpoint = '/api/admin/adcTypeIdImport/';
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      formData.append('skipLines', this.skipLines.toString());

      const req = new HttpRequest('POST', apiCreateEndpoint, formData, {
          reportProgress: true // for progress data
      });

      return this.http.request(req);
  }

  private handleProgress(event) {

      this.uploadingProgressing = false;

      if (event.type === HttpEventType.DownloadProgress) {
          this.uploadingProgressing = true;
          this.uploadProgress = Math.round(100 * event.loaded / event.total)
      }

      if (event.type === HttpEventType.UploadProgress) {
          this.uploadingProgressing = true;
          this.uploadProgress = Math.round(100 * event.loaded / event.total)
      }

      if (event.type === HttpEventType.Response) {
          this.uploadingProgressing = false;
      }
  }
}
